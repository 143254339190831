<template>
  <el-drawer
    title="Developer Console"
    :visible.sync="$store.state.showDeveloperConsole"
    :modal="true"
    :wrapperClosable="true"
    size="100%"
    direction="ltr"
  >
    <div slot="title">
      <h3>Developer Console</h3>
      <el-cascader
        style="padding-left: 16px"
        size="small"
        v-model="selectedOptions"
        :options="options"
        placeholder="Select Action"
        @change="handleChange"
      />
    </div>

    <el-container>
      <el-main>
        <el-tabs value="status" lazy @tab-click="handleTabClick">
          <el-tab-pane label="Status" name="status" :lazy="true">
            <vue-json-pretty :data="status" />
            <Billing />
          </el-tab-pane>
          <el-tab-pane label="Bot JSON" name="bot" :lazy="true">
            <el-slider
              label="Folding depth"
              v-model="deep"
              :step="1"
              :min="1"
              :max="10"
              show-input
              input-size="small"
            ></el-slider>

            <vue-json-pretty
              style="max-height: 700px; overflow: auto"
              :data="bot"
              :deep="deep"
              :showLength="true"
              :highlightMouseoverNode="true"
            />
          </el-tab-pane>
          <el-tab-pane label="Bot Snapshots" name="botSnapshots" :lazy="true">
            <el-table
              :data="tempBotSnapshots"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" min-width="55" />
              <el-table-column prop="name" label="Name" min-width="190" />
              <el-table-column
                prop="createdAt"
                label="Updated At"
                min-width="180"
              />
            </el-table>
            <el-pagination
              layout="total, sizes, prev, pager, next"
              :page-sizes="[5, 10, 20, 50, 100]"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="noOfItems"
              @prev-click="togglePage"
              @next-click="togglePage"
              @current-change="togglePage"
              @size-change="handleSizeChange"
            />
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            ></div>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
              "
            >
              <el-button
                v-loading="loading && !!botMultipleSelection.length"
                @click="handleDeleteBotsClick"
                :disabled="!botMultipleSelection.length"
                >Delete Selected Bots</el-button
              >
              <el-input-number
                style="margin-top: 10px"
                v-model="botsToDeleteCount"
                :min="1"
                :max="100"
                :disabled="!!botMultipleSelection.length"
              />
              <el-button
                v-loading="loading && !botMultipleSelection.length"
                style="margin-top: 10px"
                @click="handleDeleteBotsClick"
                :disabled="!!botMultipleSelection.length"
                >Delete Last {{ botsToDeleteCount }} Bots</el-button
              >
            </div>

            <p>
              "Delete Last n Bots" will delete the oldest n number of bots. It
              will retain bots from the last 30 days, and 1 bot a day from the
              31st day onwards.
            </p>
            <p>
              **Please do bot deletion during low traffic times as it consumes a
              lot of database server resources. Try deleting 1 - 5 oldest bots
              before doing more to estimate the time it will take.
            </p>
          </el-tab-pane>
          <el-tab-pane label="FAQ Snapshots" name="faqSnapshots" :lazy="true">
            <el-table
              :data="tempFAQSnapshots"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" min-width="55" />
              <el-table-column prop="name" label="Name" min-width="180" />
              <el-table-column
                prop="lastModified"
                label="Last Modified"
                min-width="180"
              />
              <el-table-column prop="size" label="Size" min-width="100" />
              <el-table-column label="Operations" min-width="300">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handlePromote(scope.$index, tempFAQSnapshots)"
                    >Promote</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, tempFAQSnapshots)"
                    >Delete</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              layout="total, sizes, prev, pager, next"
              :page-sizes="[5, 10, 20, 50, 100]"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="noOfItems"
              @prev-click="togglePage"
              @next-click="togglePage"
              @current-change="togglePage"
              @size-change="handleSizeChange"
            />
          </el-tab-pane>
          <el-tab-pane
            label="Livechat Resolver"
            name="livechatResolver"
            :lazy="true"
          >
            <JSONEditor ref="jsonEditor" v-model="livechatResolverPayload" />
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
  </el-drawer>
</template>

<script>
import Billing from "./DeveloperConsole/Billing";
import VueJsonPretty from "vue-json-pretty";
import gql from "graphql-tag";
import _ from "lodash";
import JSONEditor from "@/components/JSONEditor";

export default {
  components: {
    VueJsonPretty,
    Billing,
    JSONEditor,
  },
  data() {
    return {
      deep: 1,
      currentPage: 1, // Used to track the current page in pagination
      selectedOptions: "",
      tempBotSnapshots: [],
      tempFAQSnapshots: [],
      showSnapshotTable: false,
      showFAQSnapshotTable: false,
      botMultipleSelection: [],
      pageSize: 5,
      botsToDeleteCount: 10,
      loading: false,
      livechatResolverPayload: {
        list: [],
        partitionKey: "",
      },
    };
  },
  apollo: {
    bot() {
      return {
        query: gql`
          query {
            Bot {
              rawBotJSON
            }
          }
        `,
        fetchPolicy: "cache-and-network",
        update: (data) => {
          const bot = _.get(data, "Bot.rawBotJSON");
          return bot;
        },
      };
    },
  },
  computed: {
    noOfItems() {
      var sum = 0;
      if (this.showSnapshotTable) {
        if (this.$store.state.botSnapshots) {
          Object.values(this.$store.state.botSnapshots).forEach((arr) => {
            sum += arr.length;
          });
        }
      } else {
        if (this.$store.state.faqSnapshots) {
          Object.values(this.$store.state.faqSnapshots).forEach((arr) => {
            sum += arr.length;
          });
        }
      }

      return sum;
    },
    botSnapshots() {
      var arr = [];
      const botSnapshotsFromState = _.get(
        this.$store,
        "state.botSnapshots",
        {}
      );
      if (this.$store.state.botSnapshots) {
        Object.values(this.$store.state.botSnapshots).forEach((snapshotArr) => {
          snapshotArr.forEach((obj) => {
            var temp = _.cloneDeep(obj);
            let filesize = "";
            const sizes = ["Bytes", "KB", "MB", "GB"];
            if (temp.contentLength === 0) {
              filesize = "0 Byte";
            } else {
              const k = 1024;
              const dm = 2;
              const sizes = ["Bytes", "KB", "MB", "GB"];
              const i = Math.floor(Math.log(temp.contentLength) / Math.log(k));
              filesize =
                parseFloat((temp.contentLength / Math.pow(k, i)).toFixed(dm)) +
                " " +
                sizes[i];
            }
            temp.size = filesize;
            temp.name = `${temp.name} (${temp.timeAgo})`;
            temp.createdAt = `${temp.date}, ${temp.time}`;
            arr.push(temp);
          });
        });
      }
      return arr;
    },
    faqSnapshots() {
      var arr = [];
      if (this.$store.state.faqSnapshots) {
        Object.values(this.$store.state.faqSnapshots).forEach((snapshotArr) => {
          snapshotArr.forEach((obj) => {
            var temp = _.cloneDeep(obj);
            let filesize = "";
            const sizes = ["Bytes", "KB", "MB", "GB"];
            if (temp.contentLength === 0) {
              filesize = "0 Byte";
            } else {
              const k = 1024;
              const dm = 2;
              const sizes = ["Bytes", "KB", "MB", "GB"];
              const i = Math.floor(Math.log(temp.contentLength) / Math.log(k));
              filesize =
                parseFloat((temp.contentLength / Math.pow(k, i)).toFixed(dm)) +
                " " +
                sizes[i];
            }
            temp.size = filesize;
            temp.name = `${temp.name} (${temp.timeAgo})`;
            arr.push(temp);
          });
        });
      }
      return arr;
    },
    status() {
      return this.$store.state.serverStatus;
    },
    options() {
      return [
        {
          value: "actions",
          label: "Actions",
          children: [
            {
              value: "login",
              label: "Login",
            },
            {
              value: "blueprint",
              label: "Bot Blueprint",
              children: [
                {
                  value: "listFAQSnapshots",
                  label: "List FAQ Snapshots",
                },
                {
                  value: "deleteFAQSnapshots",
                  label: "Delete old FAQ snapshots",
                },
              ],
            },
            {
              value: "massLivechatResolve",
              label: "Mass Livechat Resolve",
            },
            {
              value: "stateResetScheduler",
              label: "State Reset Scheduler",
            },
          ],
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch("FETCH_SERVER_STATUS");
  },
  methods: {
    handleChange(value) {
      switch (_.last(value)) {
        case "listSnapshots":
          this.showFAQSnapshotTable = false;
          this.showSnapshotTable = true;
          this.refreshTable();
          break;
        case "listFAQSnapshots":
          // this.showFAQSnapshotTable = true;
          // this.refreshTable();
          this.showSnapshotTable = false;
          this.showFAQSnapshotTable = true;
          this.$store
            .dispatch("FETCH_FAQ_SNAPSHOTS", {
              brain: this.$store.state.brain,
            })
            .then(() => {
              this.refreshTable();
            });
          break;
        case "deleteFAQSnapshots":
          this.$prompt(
            "Snapshots older than the specified number will be deleted (in months, default 6)",
            "Delete FAQ Snapshots",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              inputPattern: /^$|^(0|[1-9][0-9]*)$/g,
              inputErrorMessage: "Please specify a valid number!",
            }
          )
            .then((result) => {
              if (!result.value) {
                result.value = 6;
              }
              var msg = "";
              if (result.value == 0) {
                msg = "This will permanently delete ALL snapshots. Continue?";
              } else {
                msg = `This will permanently delete snapshots that are ${result.value} months and older. Continue?`;
              }
              this.$confirm(msg, "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
              })
                .then(() => {
                  this.$store
                    .dispatch("DELETE_FAQ_SNAPSHOTS", {
                      brain: this.$store.state.brain,
                      limit: result.value,
                    })
                    .then((res) => {
                      this.$message({
                        type: "success",
                        message: `Deletion complete. Expected: ${res.data.deleteFAQSnapshots.expected}, Actual: ${res.data.deleteFAQSnapshots.result.length}`,
                      });
                    })
                    .catch(() => {
                      this.$message({
                        type: "error",
                        message: "Delete failed",
                      });
                    });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "Delete canceled",
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Delete cancelled",
              });
            });
          break;
        case "massLivechatResolve":
          if (!this.livechatResolverPayload.partitionKey) {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Partition Key could not be empty",
            });
            return;
          }
          this.$rest("post", "mass_resolve", this.livechatResolverPayload)
            .then(() => {
              this.$notify({
                title: "Success",
                type: "success",
                position: "bottom-right",
                message: "Successfully mass livechat resolved.",
              });
              this.livechatResolverPayload = {
                list: [],
                partitionKey: "",
              };
            })
            .catch((err) => {
              const message = _.get(
                err,
                "response.data.message",
                "Failed to mass resolve livechat."
              );

              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message,
              });
            });
          break;
        case "stateResetScheduler":
          this.$rest("get", "schedule_reset")
            .then(() => {
              this.$notify({
                title: "Success",
                type: "success",
                position: "bottom-right",
                message: "Successfully resetted.",
              });
            })
            .catch(() => {
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: "Failed to reset.",
              });
            });
          break;
        default:
      }
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.refreshTable();
    },
    refreshTable() {
      if (this.showSnapshotTable) {
        this.tempBotSnapshots = this.botSnapshots.slice(
          (this.currentPage - 1) * this.pageSize,
          this.botSnapshots.length >= this.currentPage * this.pageSize
            ? this.currentPage * this.pageSize
            : this.botSnapshots.length
        );
      } else {
        this.tempFAQSnapshots = this.faqSnapshots.slice(
          (this.currentPage - 1) * this.pageSize,
          this.faqSnapshots.length >= this.currentPage * this.pageSize
            ? this.currentPage * this.pageSize
            : this.faqSnapshots.length
        );
      }
    },
    handleSelectionChange(val) {
      this.botMultipleSelection = val;
    },
    async handleTabClick(tab, event) {
      if (tab.name === "botSnapshots") {
        this.showSnapshotTable = true;
        if (this.$store.state.botSnapshots) {
          this.refreshTable();
        } else {
          await this.$store.dispatch("FETCH_BOT_SNAPSHOTS", {
            brain: this.$store.state.brain,
          });
          this.refreshTable();
        }
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.refreshTable();
    },
    async handleDeleteBotsClick() {
      await this.$confirm(
        "This action will permenently delete bots. Are you sure you want to delete these bots?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        const data = {
          brain: this.$store.state.brain,
          dateTimes: this.botMultipleSelection.map((bot) => bot.snapshot),
          count: this.botsToDeleteCount,
        };
        this.loading = true;

        await this.$store.dispatch("DESTROY_BOTS", data);
        this.loading = false;
        await this.$message({
          type: "success",
          message: "Deletion complete.",
        });
      } catch (err) {
        await this.$message({
          type: "error",
          message: "Delete failed",
        });
      }
    },
  },
  watch: {
    "$store.state.botSnapshots"() {
      this.refreshTable();
    },
    tempBotSnapshots() {
      // Handle when last page is empty after deleting snapshots
      if (!this.tempBotSnapshots.length) {
        this.togglePage(this.currentPage - 1);
      }
    },
  },
};
</script>

<style scoped>
.el-drawer__wrapper {
  height: 100%;
  width: 40%;
  top: 0;
  left: 0;
}
</style>

<template>
  <div>
    <template v-if="handoverEnabled">
      <el-table
        border
        :show-header="false"
        :data="emailRecipients"
        :row-class-name="tableRowClassName"
        size="mini"
        style="width: 100%"
      >
        <el-table-column prop="recipientType" label="Type" width="130" />
        <el-table-column prop="email" label="Email" />
      </el-table>
    </template>

    <template v-else>The handover module is not enabled. Escalation of email will not proceed.</template>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["question"],
  computed: {
    handoverModule() {
      return this.$store.state.modules["handover"];
    },
    handoverEnabled() {
      return this.handoverModule.enabled;
    },
    emailArray() {
      const email = this.handoverModule.email;
      if (typeof email === "string") {
        return email.split(";");
      } else {
        return [];
      }
    },
    bccArray() {
      const bcc = this.handoverModule.bcc;
      if (typeof bcc === "string") {
        return bcc.split(";");
      } else {
        return [];
      }
    },
    emailRecipients() {
      const formatEmail = emailAddress => {
        const result = {
          recipientType: "send to",
          email: emailAddress
        };
        if (bcc.includes(emailAddress)) {
          result.recipientType = "bcc";
        }
        return result;
      };
      const email = this.emailArray;
      const bcc = this.bccArray;
      const total = _.chain(_.concat(email, bcc))
        .map(formatEmail)
        .value();
      return total;
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.recipientType === "send to") {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

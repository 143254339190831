<template>
  <el-row type="flex" style="margin-top: 30px;">
    <MenuEditor />
    <GreetingEditor />
  </el-row>
</template>

<script>
import GreetingEditor from "./GreetingEditor";
import MenuEditor from "./MenuEditor";
export default {
  name: "FacebookAdditionalContentEditor",
  components: {
    GreetingEditor,
    MenuEditor,
  },
};
</script>

<template>
  <el-row>
    <el-col>
      <el-autocomplete
        style="min-width: 600px; margin-bottom: 20px;"
        v-model="activeFlow.endContentNode.id"
        :fetch-suggestions="getContentNodes"
      >
        <div slot="prepend">End Flow Content Node</div>
      </el-autocomplete>
    </el-col>
    <template v-if="selectedContentNode">
      <el-col>
        <Bubble v-model="selectedContentNode.content" />
      </el-col>

      <el-col>
        <QuickReplies v-model="selectedContentNode.quickReplies" />
      </el-col>
    </template>
  </el-row>
</template>

<script>
import Bubble from "@/components/Bubble";
import QuickReplies from "@/components/QuickReplies";

export default {
  components: {
    Bubble,
    QuickReplies
  },
  props: ["activeFlow"],
  data() {
    return {
      previewFlowEndContentNode: {}
    };
  },
  computed: {
    selectedContentNode() {
      const contentNodeId = _.get(this.activeFlow, "endContentNode.id", null);
      const contentNode = _.get(
        this.$store.state,
        `nodes.content[${contentNodeId}]`,
        null
      );
      return contentNode;
    }
  },
  methods: {
    getContentNodes(value, cb) {
      cb(this.$store.state.search.content.search(value || ""));
    }
  }
};
</script>
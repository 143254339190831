<template>
  <div
    v-loading="
      $apollo.queries.logDates.loading || $apollo.queries.allActiveUsers.loading
    "
    class="loader-target"
    style="height:100%; margin-top:50px;"
  >
    <el-row>
      <el-col :sm="24">
        <el-card>
          <div slot="header" class="clearfix">Active Users</div>
          <div>
            <el-col :sm="24" :xs="24">
              <el-select v-model="selectedDate" style="flex: 1;" placeholder="Select month of log" filterable>
                <el-option
                  v-for="logDate in myLogDates"
                  :key="logDate.logDate"
                  multiple="false"
                  :label="logDate.name"
                  :value="logDate.logDate"
                />
              </el-select>
              <el-button
                style="margin-left: 10px;"
                type="primary"
                round
                @click="exportCSV"
              >Export Data</el-button>
            </el-col>
            <!-- <BatchTestResults :batchTestResult="batchTestResult"/> -->
            <el-table
              :data="myActiveUsers"
              height="350px"
              style="width: 100%"
              :row-class-name="activeStatus"
            >
              <el-table-column prop="email" label="Email" min-width="130" />
              <el-table-column prop="userId" label="User Id" min-width="130" />
              <!-- <el-table-column
                prop="authType"
                label="Auth Type"
                min-width="130"
              ></el-table-column>-->
              <el-table-column prop="lastLogin" label="Last Login" min-width="100" />
              <el-table-column prop="status" label="Status" min-width="90" />
              <el-table-column prop="roles" label="Roles" min-width="130" />
              <!-- <el-table-column prop="intent1.label" label="Intent 1" min-width="170"></el-table-column>
                <el-table-column prop="intent2.label" label="Intent 2" min-width="170"></el-table-column>
              <el-table-column prop="formattedEntities" label="Entities" min-width="170"></el-table-column>-->
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import XLSX from "xlsx";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import gql from "graphql-tag";

export default Vue.extend({
  data() {
    return {
      selectedDate: "",
      myLogDates: [],
      activeUsers: []
    };
  },
  computed: {
    myActiveUsers() {
      return _.map(this.activeUsers, user => {
        return {
          email: user.email,
          authType: user.authType,
          userId: user.userId.replace("auth0|", ""),
          status: user.status,
          lastLogin: moment(user.lastLogin).format("YYYY-MM-DD HH:mm:ss"),
          roles: user.roles.join(", ")
        };
      });
    }
  },
  methods: {
    activeStatus({ row }) {
      if (row.status.toLowerCase().trim() === "active") {
        return "success-row";
      }
      return "warning-row";
    },
    getBilledDate(date) {
      return moment(date).subtract(1, "days");
    },
    exportCSV() {
      if (this.selectedDate === "") {
        this.$message.error("Select a date to export!");
        return;
      }
      const ws_data = [];
      ws_data.push(["DASHBOARD USERS"]);
      ws_data.push([]);
      ws_data.push([
        "authType",
        "userId",
        "email",
        "lastLogin",
        "lastIp",
        "status",
        "roles",
        "departments",
        "loginCount",
        "emailVerified",
        "userCreationDate",
        "userLastUpdate",
        "nickname"
      ]);

      this.activeUsers.forEach(user => {
        const ws_row = [
          user.authType,
          user.userId,
          user.email,
          user.lastLogin,
          user.lastIp,
          user.status,
          user.roles.join(","),
          user.departments.join(","),
          user.loginCount,
          user.emailVerified,
          user.userCreationDate,
          user.userLastUpdate,
          user.nickname
        ];
        ws_data.push(ws_row);
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        this.getBilledDate(this.selectedDate).format("YYYY MMM")
      );

      XLSX.writeFile(
        wb,
        `Dashboard_Users_${this.getBilledDate(this.selectedDate).format(
          "YYYY_MMM"
        )}.xlsx`,
        {}
      );
    }
  },
  apollo: {
    logDates: {
      query: gql`
        query {
          accesscontrolAPI {
            logDates
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update(data) {
        if (!data.accesscontrolAPI) return "";
        this.myLogDates = data.accesscontrolAPI.logDates.map(ele => ({
          logDate: ele.logDate,
          name: this.getBilledDate(ele.logDate).format("YYYY MMM")
        }));
      }
    },
    allActiveUsers: {
      query: gql`
        query($logDate: String) {
          accesscontrolAPI {
            activeUsers(logDate: $logDate)
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          logDate: this.selectedDate
        };
      },
      update(data) {
        if ("accesscontrolAPI" in data) {
          this.activeUsers = data.accesscontrolAPI.activeUsers;
        }
      }
    }
  }
});
</script>
<style scoped>
.el-upload {
  width: auto !important;
}
.el-upload__tip {
  padding-left: 20px;
}
.el-table .warning-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

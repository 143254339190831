<template>
  <el-card>
    <div slot="header">
      <h2 class="keyreply-page-title">Prism</h2>
    </div>
    <div>
      <el-row>
        <el-card>
          <el-form ref="prismForm" class="prismForm">
            <el-form-item prop="inputText">
              <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }" v-model="inputText"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" :disabled="loading" @click="addSentences()">Add Sentences</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>

      <el-row>
        <el-table
          :data="sentences"
          max-height="400px"
          style="width: 100%"
          :cell-style="backgroundAsTSNEHexCode"
        >
          <el-table-column prop="text" label="Text" min-width="170" />
          <el-table-column prop="embedded" label="Embedded" min-width="80">
            <template slot-scope="props">
              <span>
                <i
                  v-if="props.row.embedded"
                  class="el-icon-success list-icon"
                  style="color: #67c23a;"
                />
                <i v-else class="el-icon-warning list-icon" style="color: #e4392b;" />
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="hexCode" label="Color Hex Code" min-width="80" />
        </el-table>
      </el-row>

      <el-row>
        <el-button
          type="primary"
          size="mini"
          :disabled="loading || embedDisabled"
          :loading="loading"
          @click="embed()"
        >Embed</el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="loading || tsneDisabled"
          @click="sortByHue()"
        >Sort by Hue</el-button>
        <el-button type="primary" size="mini" :disabled="loading" @click="clearSentences()">Clear</el-button>
      </el-row>

      <el-row>
        <el-button
          type="primary"
          size="mini"
          :disabled="loading || tsneDisabled"
          :loading="loading"
          @click="runtSNE()"
        >Run t-SNE</el-button>
        <span class="perplexity-input">
          <span class="perplexity-label">Perplexity</span>
          <el-input-number
            label="Perplexity"
            size="mini"
            controls-position="right"
            v-model="perplexity"
            :min="1"
            :max="100"
          ></el-input-number>
          <el-tooltip
            effect="dark"
            content="Higher number results in denser data points"
            placement="right"
          >
            <i class="el-icon-question tooltip-icon" />
          </el-tooltip>
        </span>
      </el-row>
    </div>
    <div />
  </el-card>
</template>
<script>
import _ from "lodash";
import gql from "graphql-tag";

export default {
  data() {
    return {
      loading: false,
      inputText: "",
      embedded: "",
      tsneValues: "",
      bgc: {
        backgroundColor: "white"
      },
      sentences: [],
      perplexity: 10
    };
  },

  computed: {
    embedDisabled() {
      return _.isEmpty(this.sentences);
    },

    tsneDisabled() {
      let noEmbeddings = true;
      _.forEach(this.sentences, sentence => {
        const embeddingFound = !_.isEmpty(sentence.embedding);
        if (embeddingFound) noEmbeddings = false;
      });
      return _.isEmpty(this.sentences) || noEmbeddings;
    },

    sortDisabled() {
      let missingRGB = false;
      _.forEach(this.sentences, sentence => {
        const rgbValue = _.get(sentence, "rgbValue");
        if (!rgbValue) missingRGB = true;
      });
      return _.isEmpty(this.sentences) || missingRGB;
    }
  },

  methods: {
    embed() {
      this.loading = true;
      const nonEmbeddedSentences = _.pickBy(this.sentences, sentence => {
        return !sentence.embedded;
      });
      const nonEmbeddedKeys = _.map(_.keys(nonEmbeddedSentences), Number);
      const nonEmbeddedTexts = _.map(nonEmbeddedSentences, sentence => {
        return sentence.text;
      });

      if (_.isEmpty(nonEmbeddedTexts)) {
        this.$notify.info({
          title: "Info",
          message: "All texts are already embedded.",
          showClose: true,
          position: "bottom-right"
        })
        return;
      };

      this.$store
        .dispatch("EMBED_SENTENCES", {
          sentences: nonEmbeddedTexts
        })
        .then(data => {
          const embeddings = _.get(data, "embeddings");
          if (embeddings.length !== nonEmbeddedKeys.length) {
            throw new Error("Mismatched unembedded indices and embeddings");
          }

          for (let i = 0; i < embeddings.length; i++) {
            const originalIndex = nonEmbeddedKeys[i];
            this.sentences[originalIndex].embedding = embeddings[i];
            this.sentences[originalIndex].embedded = true;
          }

          this.loading = false;
          this.$notify.success({
            title: "Success",
            position: "bottom-right",
            message: "Sentences embedded"
          });
        })
        .catch(e => {
          console.log(e);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Error embedding sentences"
          });
        });
    },

    runtSNE() {
      this.loading = true;
      const embeddings = _.map(this.sentences, sentence => {
        return sentence.embedding;
      });
      this.$store
        .dispatch("RUN_TSNE", {
          embeddings,
          perplexity: this.perplexity
        })
        .then(data => {
          const output = _.get(data, "scaledOutput");
          for (let i = 0; i < output.length; i++) {
            const rgbValue = this.scaleToRGB(output[i]);
            this.sentences[i].rgbValue = rgbValue;
            this.sentences[i].hexCode = this.rgbToHex(rgbValue);
          }

          this.loading = false;
          this.$notify.success({
            title: "Success",
            position: "bottom-right",
            message: "t-SNE executed"
          });
        })
        .catch(e => {
          console.log(e);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Error when running t-SNE"
          });
        });
    },

    scaleToRGB(scaledOutput) {
      return scaledOutput.map(x => {
        x = x + 1;
        x = x / 2;
        x = x * 255;
        return Math.floor(x);
      });
    },

    componentToHex(c) {
      const hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },

    rgbToHex(rgb) {
      return (
        "#" +
        this.componentToHex(rgb[0]) +
        this.componentToHex(rgb[1]) +
        this.componentToHex(rgb[2])
      );
    },

    rgbToHsl(rgb) {
      const r = rgb[0] / 255;
      const g = rgb[1] / 255;
      const b = rgb[2] / 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        const difference = max - min;
        s = l > 0.5 ? difference / (2 - max - min) : difference / (max + min);
        switch (max) {
          case r:
            h = (g - b) / difference + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / difference + 2;
            break;
          case b:
            h = (r - g) / difference + 4;
            break;
        }
        h /= 6;
      }
      return new Array(h * 360, s * 100, l * 100);
    },

    addSentences() {
      const noInput = !this.inputText || this.inputText.trim().length <= 0;
      if (noInput) return;

      const lines = this.inputText.split(/\n/);
      _.forEach(lines, line => {
        line = line.trim();
        if (line) {
          this.sentences.push({
            text: line,
            embedding: [],
            embedded: false,
            rgbValue: [],
            hexCode: ""
          });
        }
      });
    },

    clearSentences() {
      this.sentences = [];
    },

    sortByHue() {
      const clonedSentences = _.cloneDeep(this.sentences);
      const sortedSentences = clonedSentences
        .map((sentence, index) => {
          const hsl = this.rgbToHsl(sentence.rgbValue);
          return { hsl, index };
        })
        .sort((c1, c2) => {
          const orderByAscendingHue = c1.hsl[0] - c2.hsl[0];
          return orderByAscendingHue;
        })
        .map(data => {
          return clonedSentences[data.index];
        });
      this.sentences = sortedSentences;
    },

    backgroundAsTSNEHexCode({ row, column, rowIndex, columnIndex }) {
      if (columnIndex !== 2) return;
      if (_.isEmpty(row.hexCode)) return "background-color:grey";
      return `background-color:${row.hexCode}`;
    }
  }
};
</script>

<style scoped>
.perplexity-input {
  padding: 10px;
  align-items: center;
}
.tooltip-icon {
  margin-left: 0.5em;
  color: #909399;
}
.perplexity-label {
  color: #909399;
  font-size: 14px;
  padding-right: 10px;
}
</style>

<template>
  <el-container style="height: 100%">
    <el-header style="padding: 0" height="auto">
      <el-row :gutter="10">
        <el-col :xs="16" :sm="20">
          <el-input
            prefix-icon="el-icon-search"
            v-model="query"
            placeholder="Search module name"
          />
        </el-col>

        <el-col :xs="8" :sm="4">
          <el-button
            style="width: 100%"
            type="primary"
            :loading="saving"
            :disabled="!valid"
            @click="saveChanges"
            >Save</el-button
          >
        </el-col>
      </el-row>
    </el-header>

    <el-main style="padding: 10px 0 0 0; overflow-x: hidden">
      <el-tabs
        :tab-position="tabPosition"
        :value="active"
        :stretch="true"
        style="height: 100%"
      >
        <el-tab-pane
          :lazy="true"
          v-for="(config, module_id) in filteredList"
          :key="module_id"
          :name="module_id"
        >
          <div slot="label">
            <span
              style="
                line-height: 9px;
                vertical-align: middle;
                margin-right: 10px;
              "
              >{{ module_id.toUpperCase().replace("_", " ") }}</span
            >
            <el-switch
              v-model="modules[module_id].enabled"
              active-color="#13ce66"
            ></el-switch>
          </div>

          <el-container>
            <el-header>
              <el-checkbox
                v-model="modules[module_id].enabled"
                :label="module_id.toUpperCase()"
                border
              />
            </el-header>

            <el-main
              v-if="modules[module_id] && modules[module_id].enabled"
              style="max-height: 85vh; overflow: auto"
            >
              <div
                v-for="(setting, key) in config"
                :key="key"
                :required="setting.required"
                style="margin: 8px"
              >
                <component
                  v-model="modules[module_id]"
                  :name="key"
                  :setting="setting"
                  :is="toComponent(setting)"
                  :modules="modules"
                ></component>
              </div>
            </el-main>
          </el-container>
        </el-tab-pane>
      </el-tabs>

      <JSONEditor
        v-if="$store.state.showAdvanced"
        v-model="modules"
        @valid="valid = true"
        @invalid="valid = false"
      />
    </el-main>
  </el-container>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { tabSettingValidation } from "@/helperMethods/modules";

// Components
import JSONEditor from "@/components/JSONEditor";
import SmartFieldString from "./SmartFields/String";
import SmartFieldNumber from "./SmartFields/Number";
import SmartFieldAutocomplete from "./SmartFields/Autocomplete";
import SmartFieldObject from "./SmartFields/Object";
import SmartFieldTimerange from "./SmartFields/Timerange";
import SmartFieldTag from "./SmartFields/Tag";
import SmartFieldBoolean from "./SmartFields/Boolean";
import SmartFieldColor from "./SmartFields/Color";
import SmartFieldSelect from "./SmartFields/Select";
import SmartFieldMultiselect from "./SmartFields/Multiselect";
import SmartFieldTextarea from "./SmartFields/Textarea";
import SmartFieldJson from "./SmartFields/Json";
import SmartFieldYaml from "./SmartFields/Yaml";
import SmartFieldButton from "./SmartFields/Button";
import SmartFieldTransfer from "./SmartFields/Transfer";
import SmartFieldCascader from "./SmartFields/Cascader";
import SmartFieldCascaderwithheaders from "./SmartFields/CascaderWithHeaders";
// import SmartFieldHandoverroutingskillslibrary from "./SmartFields/Custom/HandoverRoutingSkillsLibrary";
import SmartFieldInactivitymanager from "./SmartFields/Custom/InactivityManager/Index";
import SmartFieldFacebookadditionalcontenteditor from "./SmartFields/Custom/FacebookAdditionalContentEditor/Index";
import SmartFieldLivechathelperactions from "./SmartFields/Custom/LiveChatHelperActions/Index";

import Webchat from "./BaseObjects/Webchat.json";
import Facebook from "./BaseObjects/Facebook.json";
import Telegram from "./BaseObjects/Telegram.json";
import Line from "./BaseObjects/Line.json";
import Wechat from "./BaseObjects/Wechat.json";
import Twitter from "./BaseObjects/Twitter.json";
import Cention from "./BaseObjects/Cention.json";
import Uccx from "./BaseObjects/Uccx.json";
import Teams from "./BaseObjects/Teams.json";
import Mobidesk from "./BaseObjects/Mobidesk.json";
import Twilio from "./BaseObjects/Twilio.json";
import Faq from "./BaseObjects/Faq.json";
import System from "./BaseObjects/System.json";
import Evaluation from "./BaseObjects/Evaluation.json";
import Analytics from "./BaseObjects/Analytics.json";
import FlowEditor from "./BaseObjects/FlowEditor.json";
import Pii from "./BaseObjects/Pii.json";
import Ameyo from "./BaseObjects/Ameyo.json";
import Article from "./BaseObjects/Article.json";
import Subscription from "./BaseObjects/Subscription.json";
import Broadcast from "./BaseObjects/Broadcast.json";
import Miniapps from "./BaseObjects/Miniapps.json";
import Infermedica from "./BaseObjects/Infermedica.json";
import Wolfram from "./BaseObjects/Wolfram.json";
import GoogleMaps from "./BaseObjects/GoogleMaps.json";
import Search from "./BaseObjects/Search.json";
import Voice from "./BaseObjects/Voice.json";
import Email from "./BaseObjects/Email.json";
import Workplace from "./BaseObjects/Workplace.json";
import Rating from "./BaseObjects/Rating.json";
import MakerChecker from "./BaseObjects/MakerChecker.json";
import TaskManagement from "./BaseObjects/TaskManagement.json";
import Alerts from "./BaseObjects/Alerts.json";
import Transactions from "./BaseObjects/Transactions.json";
import Visitors from "./BaseObjects/Visitors.json";
import PretrainedSkills from "./BaseObjects/PretrainedSkills.json";
import Whatsapp from "./BaseObjects/Whatsapp.json";
import Prism from "./BaseObjects/Prism.json";
import WebPageEditor from "./BaseObjects/WebpageEditor.json";
import Viber from "./BaseObjects/Viber.json";
import CiscoECE from "./BaseObjects/CiscoECE.json";
import Genesys from "./BaseObjects/Genesys.json";
import Infobip from "./BaseObjects/Infobip.json";
import ImagePurge from "./BaseObjects/ImagePurge.json";
import Bookworm from "./BaseObjects/Bookworm.json";
import FilePurge from "./BaseObjects/FilePurge.json";

// Mixins
import languagesMixin from "@/mixins/languages.ts";
import HandoverFormMixin from "./Mixins/Handover";
import TranslateFormMixin from "./Mixins/Translate";
import ZendeskFormMixin from "./Mixins/Zendesk";
import FallbackFormMixin from "./Mixins/Fallback";
import CoreFormMixin from "./Mixins/Core";
import FaqFormMixin from "./Mixins/Faq";
import CentionFormMixin from "./Mixins/Cention";
import ModuleEditorUtil from "./util";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default {
  components: {
    JSONEditor,
    SmartFieldTimerange,
    SmartFieldString,
    SmartFieldNumber,
    SmartFieldAutocomplete,
    SmartFieldObject,
    SmartFieldTag,
    SmartFieldBoolean,
    SmartFieldColor,
    SmartFieldSelect,
    SmartFieldMultiselect,
    SmartFieldTextarea,
    SmartFieldJson,
    SmartFieldYaml,
    SmartFieldButton,
    SmartFieldTransfer,
    SmartFieldCascader,
    SmartFieldCascaderwithheaders,
    // SmartFieldHandoverroutingskillslibrary,
    SmartFieldInactivitymanager,
    SmartFieldFacebookadditionalcontenteditor,
    SmartFieldLivechathelperactions,
  },
  mixins: [
    languagesMixin,
    HandoverFormMixin,
    TranslateFormMixin,
    ZendeskFormMixin,
    FallbackFormMixin,
    CoreFormMixin,
    FaqFormMixin,
    CentionFormMixin,
  ],
  data() {
    return {
      saving: false,
      valid: true,
      query: "",
      tabPosition: window.innerWidth <= 768 ? "top" : "left",
      departments: [],
    };
  },
  computed: {
    contentNodeList() {
      return Object.keys(this.contentNodes).map((contentName) => {
        return {
          label: contentName,
          value: contentName,
        };
      });
    },
    form() {
      const apis = _.chain(this)
        .get("$store.state.apieditor.mappings", [])
        .map((mapping, mappingName) => ({
          key: mappingName,
          value: mappingName,
        }))
        .value();

      return {
        // Channels
        system: System,
        webchat: Webchat,
        facebook: Facebook,
        telegram: Telegram,
        line: Line,
        wechat: Wechat,
        whatsapp: Whatsapp,
        zendesk: this.zendeskFormMixin,
        twitter: Twitter,
        cention: this.centionFormMixin,
        uccx: Uccx,
        teams: Teams,
        moobidesk: Mobidesk,
        twilio: Twilio,
        faq: this.faqFormMixin,
        viber: Viber,
        infobip: Infobip,
        // Core System
        evaluation: Evaluation,
        ciscoEce: CiscoECE,
        genesys: Genesys,
        access_control: {},
        analytics: Analytics,
        floweditor: FlowEditor,
        webpage_editor: WebPageEditor,
        pii: Pii,
        ameyo: Ameyo,
        imagePurge: ImagePurge,
        filePurge: FilePurge,
        // Escalation
        handover: this.handoverFormMixin,
        article: Article,
        subscription: Subscription,
        broadcast: Broadcast,
        core: this.coreFormMixin,
        miniapps: Miniapps,
        infermedica: Infermedica,
        fallback: this.fallbackFormMixin(apis),
        google_maps: GoogleMaps,
        search: Search,
        voice: Voice,
        email: Email,
        translate: this.translateFormMixin,
        wolfram: Wolfram,
        workplace: Workplace,
        rating: Rating,
        makerChecker: MakerChecker,
        task_management: TaskManagement,
        alerts: Alerts,
        transactions: Transactions,
        visitors: Visitors,
        bookworm: Bookworm,
        prism: Prism,
        pretrained_skills: PretrainedSkills,
        menu: true,
        tnc: false,
      };
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    active() {
      return Object.keys(this.form).find((key) => {
        const module = this.$store.state.modules[key];
        let containsTerm = key.includes(this.query);
        if (!containsTerm && module.enabled) {
          const value = this.form[key];
          const withinObjectContainsTerm = ModuleEditorUtil.anyObjectContainsTerm(
            value,
            this.query
          );
          if (withinObjectContainsTerm) {
            containsTerm = true;
          }
        }
        return containsTerm;
      });
    },
    filteredList() {
      return ModuleEditorUtil.getFilteredList(
        this.form,
        this.$store.state.modules,
        this.query
      );
    },
    contentNodes() {
      return this.$store.state.nodes.content;
    },
  },
  mounted() {
    if (checkRoles(getPermittedRoles("agents"))) {
      this.$store.dispatch("FETCH_AGENT_DEPARTMENTS").then((departments) => {
        this.departments = departments;
      });
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    getContentNodeSuggestions(queryString, cb) {
      const contentNodeNames = Object.keys(this.contentNodes);
      const filteredContentNodeNames = _.chain(contentNodeNames)
        .filter((contentName) => contentName.includes(queryString))
        .map((contentName) => {
          return { value: contentName };
        })
        .value();
      cb(filteredContentNodeNames);
    },
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const isComponentExist = _.has(this.$options.components, componentName);
      return isComponentExist ? componentName : null;
    },
    resize() {
      this.tabPosition = window.innerWidth <= 768 ? "top" : "left";
    },
    search(query, cb) {
      let suggestions = Object.keys(this.form)
        .filter((name) => name.includes(this.query))
        .map((name) => ({ value: name }));
      cb(suggestions);
    },
    saveChanges(event) {
      console.log("modules:", this.modules);
      const tabSetting = _.get(this.modules, "webchat.tabs", []);
      if (!tabSettingValidation(tabSetting)) {
        this.$message({
          type: "error",
          message: "Error saving tab config",
        });
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Config Saved",
            });
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
  },
};
</script>

<template>
  <div>
    <el-row v-if="$store.state.showAdvanced">
      <el-col>
        <el-button :loading="testLoading" @click="testIntents">Evaluate Intents</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="5" type="flex">
      <SearchBar placeholder="Search for an intent or example here..." />
      <el-select
        v-model="selectedTags"
        class="controll-margin"
        style="width: 100%; max-width: 300px"
        collapse-tags
        multiple
        filterable
        placeholder="Filter by tags"
        @change="$set($store.state.pages.faq, 'selectedIntentId', null)"
      >
        <el-option v-for="tag in listTags" :key="tag" :label="tag" :value="tag" />
      </el-select>
      <el-select
        v-model="selectedLanguages"
        class="controll-margin"
        style="width: 100%; max-width: 200px"
        multiple
        filterable
        collapse-tags
        placeholder="Filter by language"
        @change="$set($store.state.pages.faq, 'selectedIntentId', null)"
      >
        <el-option
          v-for="(lang, index) in supportedLanguages"
          :key="index"
          :label="lang.name"
          :value="lang.code"
        />
      </el-select>
      <el-tooltip content="Import as CSV" placement="top">
        <el-button
          id="importBtn"
          class="controll-margin importBtn importExportBtn"
          plain
          @click="dialogUploadVisible = true"
        >
          <img class="importBtnIcon" src="img/icons/imp-exp/imp2.png" />
        </el-button>
      </el-tooltip>
      <el-tooltip content="Export as CSV" placement="top">
        <el-button class="exportAddNewBtn importExportBtn" plain @click="exportQnA">
          <img class="importBtnIcon" src="img/icons/imp-exp/exp2.png" />
        </el-button>
      </el-tooltip>
    </el-row>

    <br />

    <el-dialog
      @close="
        () => {
          uploadShouldUploadAll = false;
          uploadShouldUploadAllConfirm = null;
        }
      "
      class="import_popup"
      title="Import as CSV"
      :visible.sync="dialogUploadVisible"
      :before-close="handleClose"
      :close-on-press-escape="false"
      width="50%"
    >
      <el-row :gutter="10">
        <el-col :md="16">
          <el-input size="mini" v-model="crawlTarget" placeholder="https://..." />
        </el-col>
        <el-col :md="4">
          <el-button
            :loading="crawling"
            type="plain"
            class="load-faq-btn"
            @click="crawl"
            size="mini"
          >Load FAQ from web page</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col>
          <el-upload
            drag
            class="upload-demo"
            action="#"
            :before-upload="beforeUpload"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :file-list="fileList"
          >
            <div class="el-upload__text file-upload-inner-text">Drag file / click to upload</div>
          </el-upload>
        </el-col>
      </el-row>
      <div style="display: flex; margin-bottom: 15px">
        <el-checkbox v-model="uploadShouldUploadAll">Replace All</el-checkbox>
        <el-input
          v-if="uploadShouldUploadAll"
          placeholder="Please type 'confirm' to replace all faq..."
          style="margin-left: 15px"
          v-model="uploadShouldUploadAllConfirm"
          size="mini"
        ></el-input>
      </div>
      <el-row>
        <el-col>
          <el-button
            size="mini"
            style="width: 30%"
            plain
            type="primary"
            @click="downloadTemplate"
          >Download CSV Template</el-button>
          <el-button size="mini" style="width: 15%" @click="handleClose(false)">Cancel</el-button>
          <el-tooltip class="item" effect="dark" content placement="top-start">
            <div slot="content">
              Q: What happens if I import more than one answer for one question?
              <br />A: Only the first one will be taken and it will OVERWRITE
              the existing answer for that FAQ.
              <br />
              <br />Q: What if the same ID already exist in FAQ?
              <br />A: Your
              questions will be added on as examples without replacing the
              current FAQ entirely.
              <br />
              <br />Q: What if there are other columns, do I need to remove them
              before uploading?
              <br />A: No, they will be automatically ignored,
              only text under the Id, Question, and Answer (case-sensitive) will
              be processed.
              <br />
              <br />Q: What if I import question(s) and/or example(s) that
              already exist for that FAQ?
              <br />A: Currently, they will just be
              added into the example list (duplicate checking is still WIP).
              <br />
              <br />Q: I am already uploading a .csv file, but it doesn't
              recognize it as so. Why?
              <br />A: Check whether the first column
              of the file is ID instead of Id, this is an issue that will detect
              the file as SYLK instead of .csv.
              <br />
              <br />Q: Must the column headers be on the first row?
              <br />A:
              Yes.
            </div>
            <el-button size="mini" style="color: #707070; width: 15%">Help</el-button>
          </el-tooltip>

          <el-tooltip
            v-if="
              uploadedContentList.length === 0 && uploadedContentMap.size === 0
            "
            content="Did not detect any content from uploaded file. Please check file again"
            placement="top"
          >
            <el-button
              style="color: white; background: #e4392b; width: 30%"
              :disabled="
                !(
                  uploadedContentMap.size > 0 || uploadedContentList.length > 0
                ) || saving
              "
              size="mini"
              :loading="saving"
              @click="upload"
            >Upload</el-button>
          </el-tooltip>
          <el-button
            v-else
            style="color: white; background: #e4392b; width: 30%"
            size="mini"
            :disabled="
              !(
                uploadedContentMap.size > 0 || uploadedContentList.length > 0
              ) || saving
            "
            @click="upload"
            :loading="saving"
          >Upload</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- Advance Mode -->
    <!-- Commented out as there is no use for it -->
    <!-- <AdvanceMode v-if="$store.state.showAdvanced" :dataset="dataset"/> -->

    <!-- Single FAQ Training Dataset -->
    <el-row>
      <el-col :md="24" :lg="12" class="first-section-content">
        <template v-if="isFaqGroupByTopicEnabled">
          <!-- FAQ questions and grouped by topic enableb -->
          <FaqTrainingDatasetListByGroup
            v-loading="isDatasetLoading"
            :visible-dataset="visibleDataset"
            :expanded-rows="expandedRows"
            :badge-for-qustions="badgeForQustions"
            :save="save"
            :selected-languages="selectedLanguages"
            :handle-blur="handleBlur"
            :is-dirty="isDirty"
            @save="save"
            @clearIntentView="clearIntentView"
            @handleBlur="handleBlur"
            @handleIntentEdit="handleIntentEdit"
            @isDirty="isDirty"
            @onSortChange="onSortChange"
          />
        </template>
        <template v-else>
          <!-- FAQ questions and Enable -->
          <FaqTrainingDatasetList
            v-loading="isDatasetLoading"
            :visible-dataset="visibleDataset"
            :expanded-rows="expandedRows"
            :badge-for-qustions="badgeForQustions"
            :save="save"
            :selected-languages="selectedLanguages"
            :handle-blur="handleBlur"
            :is-dirty="isDirty"
            @save="save"
            @clearIntentView="clearIntentView"
            @handleBlur="handleBlur"
            @handleIntentEdit="handleIntentEdit"
            @isDirty="isDirty"
            @onSortChange="onSortChange"
          />
        </template>
      </el-col>

      <el-col :md="24" :lg="12" class="detail-section-content">
        <!-- Default page with icon -->
        <DefaultPageWithIcon
          v-if="!$store.state.pages.faq.selectedIntentId"
          :visible-dataset="visibleDataset"
        />

        <!-- FAQ Single Question details -->
        <SingleFaqQuestionDetail
          v-else
          style="height: 81vh; overflow: auto"
          :badge-for-qustions="badgeForQustions"
          :selected-languages="selectedLanguages"
          :is-dirty="isDirty"
          :save="save"
          :dataset="datasetFromState"
          :handle-blur="handleBlur"
          :save-variation="saveVariation"
          :delete-variation="deleteVariation"
          :dirty="dirty"
          :new-variation="newVariation"
          @save="save"
          @isDirty="isDirty"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import XLSX from "xlsx";
import { v4 as uuid } from "uuid";
import moment from "moment";
import SearchBar from "@/components/Faq/searchBar.vue";
import gql from "graphql-tag";
import {
  setActionData,
  formatEntities,
  findIntentIndex,
  faqExportedWorksheetData
} from "@/helperMethods/faq";
import languagesMixin from "@/mixins/languages.ts";
import { faqDatasetMixin } from "@/mixins/faq";

// First column
import FaqTrainingDatasetListByGroup from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/FaqTrainingDatasetListByTopic/Index.vue";

import FaqTrainingDatasetList from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/FaqTrainingDatasetList.vue";

// Second column
import DefaultPageWithIcon from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/DefaultPageWithIcon.vue";

// Third column
import SingleFaqQuestionDetail from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/SingleFaqQuestionDetail.vue";

export default Vue.extend({
  mixins: [languagesMixin, faqDatasetMixin],
  components: {
    SearchBar,
    DefaultPageWithIcon,
    SingleFaqQuestionDetail,
    FaqTrainingDatasetListByGroup,
    FaqTrainingDatasetList
  },
  props: [
    "expandedRows",
    "save",
    "newVariation",
    "openDialog",
    "dirty",
    "isDirty",
    "isDatasetLoading"
  ],
  data() {
    return {
      crawlTarget: "",
      badgeForQustions: {},
      crawling: false,
      selectedLanguage: [],
      fileList: [],
      selectedTags: [],

      uploadedContentList: [],
      uploadedContentMap: new Map(),
      dialogTableVisible: false,
      dialogUploadVisible: false,
      dataset: [],
      searchText: "",
      selectedLanguages: [],
      testLoading: false,

      currentSort: null,
      uploadShouldUploadAll: false,
      uploadShouldUploadAllConfirm: null,
      saving: false
    };
  },
  computed: {
    isFaqGroupByTopicEnabled() {
      return this.$store.state.modules.faq.groupByTopic;
    },
    topics() {
      return _.chain(this.datasetFromState)
        .map("topics")
        .flatten()
        .uniq()
        .value();
    },
    search() {
      return _.get(this, "$store.state.faq.intentSearch", "");
    },
    listTags() {
      return _.chain(this.datasetFromState)
        .map("tags")
        .flatten()
        .uniq()
        .sort()
        .value();
    },
    visibleDataset() {
      let filteredObj = _.chain(this.datasetFromState)
        .filter(pair => {
          // Filter by tags
          if (this.selectedTags.length > 0) {
            if (_.intersection(this.selectedTags, pair.tags).length === 0) {
              return false;
            }
          }
          return true;
        })
        .filter(pair => {
          // Filter by language
          if (_.isEmpty(this.selectedLanguages)) {
            return true;
          }
          const containLanguage = pair.variations.find(variation =>
            _.includes(this.selectedLanguages, variation.language)
          );
          return !!containLanguage;
        })
        .filter(pair => {
          // Filter by search
          let search = this.search.trim().toLowerCase();
          if (search) {
            // Search only by what is visible to user
            const variationText = _.get(pair, "variations", []).map(
              variation => {
                return {
                  text: variation.text,
                  language: variation.language
                };
              }
            );
            const intentAndExamples = {
              intentName: pair.intentName,
              variations: variationText,
              action: pair.action
            };
            let isMatch = JSON.stringify(intentAndExamples)
              .trim()
              .toLowerCase()
              .includes(search);
            return isMatch;
          } else {
            return true;
          }
        })
        .value();

      if (this.currentSort) {
        const _columnName = _.get(this.currentSort, "column.columnKey", "");
        const isDescending = this.currentSort.order === "descending";
        switch (_columnName) {
          case "intentName":
            if (isDescending) {
              filteredObj.sort((a, b) =>
                a.intentName > b.intentName ? -1 : 1
              );
            } else {
              filteredObj.sort((a, b) =>
                a.intentName > b.intentName ? 1 : -1
              );
            }
            break;
          case "examples":
            if (isDescending) {
              filteredObj.sort((a, b) =>
                a.variations.length > b.variations.length ? -1 : 1
              );
            } else {
              filteredObj.sort((a, b) =>
                a.variations.length > b.variations.length ? 1 : -1
              );
            }
            break;
          default:
        }
      }

      return filteredObj;
    },
    supportedLanguages() {
      // ISO 639-1 codes
      // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
      const hasSupportedLanguages = !_.isEmpty(
        this.$store.state.training.supportedLanguages
      );

      if (hasSupportedLanguages) {
        return _.sortBy(this.$store.state.training.supportedLanguages, "name");
      } else {
        return _.sortBy(this.languages, "name");
      }
    }
  },
  watch: {
    topics: {
      deep: true,
      handler(updatedTopics, previousTopics) {
        if (updatedTopics !== previousTopics) {
          this.$store.commit("SET_TOPICS", updatedTopics);
        }
      }
    }
  },
  methods: {
    setTrainingInProgress(inProgress) {
      this.$store.commit("SET_TRAINING_IN_PROGRESS", inProgress);
    },
    onSortChange(event) {
      if (event.order === null) {
        this.currentSort = null;
      } else {
        this.currentSort = event;
      }
    },
    testIntents() {
      this.testLoading = true;
      this.setTrainingInProgress(true);

      const data = this.$store.state.training.dataset;
      const chunks = _.chunk(data, 100);

      chunks.map(intents => {
        // latestModelId is only for train and deconflicting
        this.$store
          .dispatch("FAQ_TEST_TRAINING_DATA", {
            intents,
            latestModelId: "",
            isIgnoreFallbackOthersIntent: false
          })
          .then(updatedDataset => {
            if (updatedDataset) {
              this.$notify.success({
                title: "Evaulation Success",
                position: "bottom-right",
                message: `Successfully evaluated all FAQ intents`
              });
            }
          })
          .catch(err => {
            this.$notify.error({
              title: "Evaluation Error",
              position: "bottom-right",
              message: "Encountered error testing FAQ."
            });
          });
      });

      this.testLoading = false;
      this.setTrainingInProgress(false);
    },
    clearIntentView() {
      this.$set(this.$store.state.pages.faq, "selectedIntentId", null);
    },

    handleIntentEdit(row) {
      // FIXME: change the actions to switch
      if (row) {
        row.edit = true;
        this.$set(this.$store.state.pages.faq, "selectedIntentId", row.id);

        //change active node id on click of question
        let id = "";

        if (row.action && typeof row.action.data === "string") {
          id = row.action.data || "conversation_start";
        } else if (row.action && typeof row.action.data === "object") {
          // Capture event
          id = row.action.data.next || "conversation_start";
        } else {
          id = row.id;
        }
        if (id) {
          this.$store.dispatch("SELECT_NODE", { type: "content", id });
        }
      }
    },

    async handleBlur(row, isNewIntent) {
      row.intentName = row.intentName.trim();
      // // Here I clear the selected row data to unmount NodePreview component
      let newIntent;
      if (isNewIntent && row.intentName) {
        newIntent = {
          id: "INTENT_" + Date.now(),
          intentName: row.intentName.toUpperCase(),
          edit: true,
          language: "en",
          action: { event: "", data: "" },
          variations: [],
          enabled: false,
          tagInputValue: "",
          tagInputVisible: false,
          tags: [],
          topics: row.topics,

          //Backward compatible
          question: row.intentName.toUpperCase(),
          answer: ""
        };

        // Add in a new question
        this.$store.dispatch("FAQ_ADD_NEW_INTENT", {
          intent: newIntent
        });
        this.badgeForQustions[newIntent.id] = true;
        row.edit = false;
      }

      this.$nextTick(() => {
        if (this.dirty) {
          if (row.action) {
            const { next, key, value } = row.action.data;

            switch (row.action.event) {
              case "goto":
                this.$store.dispatch("SELECT_NODE", {
                  type: "content",
                  id: row.action.data // Content node ID
                });
                break;
              case "capture":
                this.$store.dispatch("SELECT_NODE", {
                  type: "content",
                  id: next // Content node ID
                });
                break;
              default:
                this.$store.dispatch("SELECT_NODE", {
                  type: "",
                  id: ""
                });
                break;
            }
          }
        }
      });
    },
    deleteVariation({ id = "" }, text, selectedIntent = null) {
      const { index: pairIndex, pair } = this.getIntentByIdentity(id);
      if (pair) {
        const { index: variationIndex } = this.getIntentVariation({
          pair,
          text,
        });
        pair.variations.splice(variationIndex, 1);
        this.$store.state.training.dataset[pairIndex] = pair;

        // Update the variable with latest data
        this.$set(
          this.$store.state.pages.faq,
          "selectedIntentId",
          !selectedIntent ? pair.id : selectedIntent
        );
      }
    },

    saveVariation({ id }, variationObject) {
      const { id: variationObjectId, text: variationObjectText } = variationObject;
      const { pair } = this.getIntentByIdentity(id);
      const { index: variationIndex } = this.getIntentVariation({
        pair,
        id: variationObjectId,
        text: variationObjectText,
      });
      if (variationIndex !== -1) {
        // replace the variation
        pair.variations[variationIndex] = variationObject;
      } else {
        // Just add in if cannot find in existing intent
        pair.variations.unshift(variationObject);
      }
    },
    crawl() {
      if (this.crawlTarget) {
        this.crawling = true;
        this.$rest("post", "faq_crawl", {
          url: this.crawlTarget
        })
          .then(data => {
            this.crawling = false;
            if (data.question_answer_pairs) {
              // console.log(data.question_answer_pairs.length, "Questions");  -> Unexpected console statement

              let list = data.question_answer_pairs.map(pair => {
                let cleanQuestion = pair.question.replace(/^\d+\\?\.? ?/, "");
                return {
                  id: pair.label,
                  question: cleanQuestion,
                  answer: pair.answer,
                  variations: [],
                  enabled: true
                };
              });
              const newDataset = this.datasetFromState.concat(list);
              if (newDataset) {
                this.$store.state.training.dataset = newDataset;
              }
            }
          })
          .catch(e => {
            // console.error(e);  -> Unexpected console statement
          });
      }
    },
    downloadTemplate() {
      var link = document.createElement("a");
      link.download = "FAQ_template.csv";
      link.href = "/template/FAQ_template.csv";
      link.click();
    },

    beforeUpload(file) {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (isCSV) {
        this.handleSuccess("", file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },

    handleError(err) {
      // console.log("An error occurred while importing file!"); -> Unexpected console statement
      // console.log(err);  -> Unexpected console statement
    },

    handleRemove() {
      this.fileList = [];
      this.uploadedContentList = [];
    },

    /**
     * language is default to en if it is given as undefined
     */
    createNewIntent(newIntentPayload) {
      const {
        id,
        example,
        language = "en",
        action,
        tags = [],
        enabled,
        entities,
        topics
      } = newIntentPayload;

      return {
        id,
        edit: true,
        enabled: enabled || false,
        language,
        action,
        variations: [],
        tags,
        tagInputValue: "",
        tagInputVisible: false,
        intentName: id,
        entities: entities || [],
        topics: topics || [],

        // Backward compatible
        question: id == null ? "" : id,
        answer: _.get(action, "data", "")
      };
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },

    handleClose(done) {
      this.dialogUploadVisible = false;
      this.handleRemove();

      if (done) done();
    },

    handleSuccess(res, file) {
      this.uploadedContentMap = new Map();
      this.uploadedContentList = [];

      const reader = new FileReader();
      reader.onload = (vuecomponent => {
        return function(e) {
          const data = e.target.result;
          const wb = XLSX.read(data, {
            type: "binary",
            raw: true
          });
          const firstSheetName = wb.SheetNames[0];
          const firstSheet = wb.Sheets[firstSheetName];
          const uploadedData = XLSX.utils.sheet_to_json(firstSheet); // Only get the first sheet
          uploadedData.forEach((rawRow, index) => {
            const row = vuecomponent.transformObjectToLowerCase(rawRow, [
              "data",
              "example",
              "entities",
            ]);

            const {
              example,
              intent: intentId,
              language,
              topics,
              entities
            } = row;
            if (example != null || intentId != null) {
              const enabled = /true/i.test(row.enabled);
              const suggestion = /true/i.test(row.suggest);
              let intent = vuecomponent.uploadedContentMap.get(intentId);

              if (!intent) {
                // New Intent
                const newIntentPayload = {
                  id: intentId,
                  example,
                  language
                };
                intent = vuecomponent.createNewIntent(newIntentPayload);

                vuecomponent.uploadedContentMap.set(intentId, intent);
              }

              // Update Intent
              intent.variations.push({
                id: uuid(),
                text: example,
                language,
                is_suggestion: suggestion
              });

              // TODO: once we have to add more variables to be updated, we will refactor
              let newAction = {
                event: row.event,
                data: row.data
              };

              if (row.event === "callapi") {
                newAction = {
                  event: "callApi",
                  data: {
                    mappingName: row.data
                  }
                };
              }

              const updatedIntent = Object.assign({}, intent, {
                enabled,
                entities: formatEntities(entities),
                topics: formatEntities(topics),
                action: newAction
              });
              vuecomponent.uploadedContentMap.set(intentId, updatedIntent);
            }
          });

          vuecomponent.uploadedContentList = Array.from(
            vuecomponent.uploadedContentMap.values()
          );
        };
      })(this);
      reader.readAsText(file);

      this.$forceUpdate();
      this.fileList = [...this.fileList, file];
    },

    async upload() {
      const isAllowToSave =
        !this.uploadShouldUploadAll ||
        (this.uploadShouldUploadAll &&
          this.uploadShouldUploadAllConfirm === "confirm");

      if (this.saving || !isAllowToSave) {
        return;
      }

      try {
        this.saving = true;
        let datasetToSave = [];
        if (!this.uploadShouldUploadAll) {
          datasetToSave = _.cloneDeep(this.$store.state.training.dataset);
          console.log(this.uploadedContentList);
          this.uploadedContentList.forEach(newFaqRow => {
            const intentFound = _.find(datasetToSave, oldFAQ => {
              if (
                oldFAQ.intentName.toUpperCase() ===
                newFaqRow.intentName.toUpperCase()
              ) {
                // Remove General topic if it exist.
                if (!_.isEmpty(oldFAQ.topics)) {
                  oldFAQ.topics = _.without(oldFAQ.topics, "general"); //FIXME: Uppercased?
                }

                return true;
              }
            });

            if (intentFound) {
              // Updating enabled, action & entities
              const faqWithUpdates = Object.assign({}, intentFound, {
                enabled: newFaqRow.enabled,
                entities: newFaqRow.entities,
                action: newFaqRow.action
              });

              newFaqRow.variations.forEach(variation => {
                variation.id = uuid();
                intentFound.variations.push(variation);
              });

              const intentIndex = findIntentIndex(
                intentFound.id,
                intentFound.intentName
              );
              this.$store.state.training.dataset[intentIndex] = faqWithUpdates;
            } else {
              this.badgeForQustions[newFaqRow.id] = true;

              newFaqRow.intentName = newFaqRow.intentName.toUpperCase();
              datasetToSave.unshift(newFaqRow);
              this.$store.state.training.dataset.unshift(newFaqRow);
            }
          });
        } else {
          datasetToSave = this.uploadedContentList.map(newFaqRow => {
            newFaqRow.intentName = newFaqRow.intentName.toUpperCase();
            let intentExists = false;
            const validTopics = newFaqRow.topics && _.isArray(newFaqRow.topics);
            if (validTopics) {
              const generalTopicIndex = newFaqRow.topics.findIndex(
                topic => topic.toLowerCase() === "general"
              );

              const foundGeneralTopic = generalTopicIndex > -1;
              if (foundGeneralTopic) {
                newFaqRow.topics.splice(generalTopicIndex, 1);
              }
            }

            // Generate UUID for variations
            newFaqRow.variations.forEach(variation => {
              variation.id = uuid();
            });

            return newFaqRow;
          });
          this.$store.state.training.dataset = datasetToSave;
        }

        await this.save();
        this.uploadShouldUploadAll = false;
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "Save Error",
          position: "bottom-right",
          message: "Failed to save FAQ dataset."
        });
      } finally {
        this.$set(this.$store.state.pages.faq, "selectedIntentId", null);
        this.$emit("togglePendingSave", true);
        this.handleClose(false);
        this.saving = false;
      }
    },
    extractContentNodeID(action) {
      if (typeof action === "string") {
        // legacy code where answer is just a string
        return action;
      } else if (typeof action === "object") {
        // new code where answer is {event, data}
        if (action.data && typeof action.data === "string") {
          // goto
          return action.data;
        } else if (action.data && typeof action.data === "object") {
          // capture
          return _.get(action, "data.next", "");
        } else {
          // default
          return "";
        }
      } else {
        // default
        return "";
      }
    },
    extractActionEvent(actionObject) {
      if (typeof actionObject === "object") {
        // new code where action is {event, data}
        return _.get(actionObject, "event", "");
      } else {
        // default
        return "";
      }
    },
    exportQnA() {
      const ws_data = faqExportedWorksheetData(this.datasetFromState);
      const dataLength = this.datasetFromState.length;

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      const sheetName = `${dataLength} FAQs`;
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      const filename = this.$exportFilename("FAQ", "csv");
      XLSX.writeFile(wb, filename, {});

      this.$notify.success({
        title: "Export Success",
        message: `Exported ${dataLength} FAQ intents successfully`,
        position: "bottom-right"
      });
    },
    transformObjectToLowerCase(obj, exclude = []) {
      return _.transform(obj, (result, value, _key) => {
        const key = _key.toLowerCase();
        result[key] = exclude.includes(key) ? value : value.toLowerCase();
      });
    },
  },
});
</script>

<style scoped>
.controll-margin {
  margin-right: 8px;
}

.importBtn {
  background-color: #e4392b;
  /* padding: 10px; */
  margin-right: -4px;
}

#importBtn:hover {
  background-color: #e4392b;
  border: solid 1px #d90909;
}

.importExportBtn {
  position: relative;
  padding: 9px;
}

.importBtnIcon {
  height: 18px;
  width: 18px;
}

.import_popup {
  padding: 20px;
}

.file-upload-inner-text {
  font-size: 20px;
  display: block;
  height: 50px;
  text-align: center;
  line-height: 160px;
  color: #888787;
}

.exportAddNewBtn {
  padding: 10px;
}
.importBtnIcon {
  height: 18px;
  width: 18px;
}

.first-section-content {
  height: 81vh;
  overflow-y: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>

import _ from 'lodash';
import * as Sentry from "@sentry/browser";

const XlsxPopulate = require('xlsx-populate');

function convertObjectTo2DArray(obj: any[]): string[][] {
  if(!Array.isArray(obj) || obj.length === 0){
    return [];
  }
  const result: string[][] = [[]];
  _.forOwn(obj[0], (_, key) => { 
    result[0].push(key);
  });

  _.forEach(obj, (value, idx) => {
    result[idx + 1] = [];
    _.forEach(result[0], (key)=>{
      result[idx + 1].push(value[key]);
    });
  });
  return result;
}

export const exportToXLSX = (data: any[], fileName: string = "", password: string) => {
  if(!Array.isArray(data) || data.length === 0){
    return;
  }
  const convertedData = convertObjectTo2DArray(data);
  generateBlob(convertedData, fileName, password);
};

function generateBlob(data: string[][], fileName: string, password: string) {
  return XlsxPopulate.fromBlankAsync().then((workbook: any) => {
    workbook.sheet(0).cell("A1").value(data);
    return workbook.outputAsync({ password: password });
  }).then((blob: any)  =>{
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
          }
      })
      .catch((err: any) => {
      Sentry.captureMessage(
        `Encountered error on generateBlob: ${err.message || err}`
      );
    });
}

<template>
  <el-popover
    placement="top-end"
    title="Call action to this conversation"
    trigger="manual"
    width="350"
    v-model="isVisible"
  >
    <div v-loading="isBusy" style="max-height: 350px; overflow: auto">
      <el-button
        @click="onSend(action)"
        v-for="(action, index) in actions"
        type="primary"
        size="mini"
        style="width: 100%; margin: 0 0 8px 0"
        :key="`livechataction-${index}`"
        :disabled="isBusy"
        plain
      >
        {{ action.label }}
      </el-button>
    </div>
    <el-button
      slot="reference"
      icon="el-icon-caret-top"
      type="primary"
      :disabled="isBusy"
      style="
        height: 100%;
        border-left-color: #e4392b;
        border-right-color: #e4392b;
      "
      plain
      @click="isVisible = !isVisible"
    />
  </el-popover>
</template>

<script>
import gql from "graphql-tag";
import _ from "lodash";
export default {
  props: ["selectedChatUserId", "selectedChatPartitionKey"],
  data() {
    return {
      isVisible: false,
      isBusy: false,
    };
  },
  computed: {
    actions() {
      return (
        _.get(
          this,
          "$store.state.modules.handover.liveChatHelperActions.actions"
        ) || []
      );
    },
  },
  methods: {
    async onSend(action) {
      if (!this.isBusy) {
        try {
          const confirmed = await this.$confirm(
            `Are you sure to trigger action ${action.label} (type: ${action.event}, data: ${action.data})?`,
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
            }
          );
          if (!confirmed) return;
          this.isBusy = true;
          await this.$apollo.mutate({
            mutation: gql`
              mutation(
                $RowKey: String!
                $PartitionKey: String!
                $action: JSON!
              ) {
                livechatAPI {
                  sendQuickReply(
                    RowKey: $RowKey
                    PartitionKey: $PartitionKey
                    action: $action
                  )
                }
              }
            `,
            variables: {
              RowKey: this.selectedChatUserId,
              PartitionKey: this.selectedChatPartitionKey,
              action: {
                event: action.event,
                data: action.data,
              },
            },
          });
        } catch (error) {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error sending content node message.`,
          });
        } finally {
          this.isVisible = false;
          this.isBusy = false;
        }
      }
    },
  },
};
</script>
<template>
  <div>
    <SearchChatFilters tab="queue" />
    <el-row>
      <el-col
        id="queueChatLeftPane"
        class="leftPane"
        v-if="!isAdaptiveView || !closed"
        :xl="6"
        :lg="6"
        :md="8"
        :sm="24"
      >
        <el-card class="box-card" style="margin: 0 5px">
          <div slot="header" class="clearfix">
            <span>Live Chats</span>
            <el-button
              v-if="isNotRoutingAssignmentMode"
              type="success"
              style="float: right"
              :disabled="!isAgentOnline"
              @click.native="assignNewChat"
              size="mini"
              plain
            >
              Next Chat
              <i class="el-icon-arrow-right el-icon-right" />
            </el-button>
          </div>

          <!-- Assigned chats -->
          <p
            style="
              color: #bdbdbd;
              font-style: italic;
              font-size: 10px;
              text-decoration: underline;
            "
          >
            ON-GOING CHATS
          </p>

          <QueueChatsList
            :chats-list="queueAssignedChatsArray"
            :selected-index.sync="selectedIndex"
            :closed.sync="closed"
          />

          <template
            v-if="
              isNotRoutingAssignmentMode ||
              $store.state.modules.handover.show_users_waiting_for_assignment
            "
          >
            <!-- Unassigned chats -->
            <p
              style="
                color: #bdbdbd;
                font-style: italic;
                font-size: 10px;
                text-decoration: underline;
              "
            >
              ALL OTHER CHATS
            </p>

            <QueueChatsList
              name="unassignedChatList"
              :chats-list="queueUnassignedChatsArray"
              :selected-index.sync="selectedIndex"
              :closed.sync="closed"
            />
          </template>
        </el-card>
      </el-col>

      <el-col
        v-show="!isAdaptiveView || closed"
        class="rightPane"
        :xl="18"
        :lg="18"
        :md="16"
        :sm="24"
      >
        <ChatsInteractions
          v-if="selectedChat"
          ref="interactionsPanel"
          :chats-list="queueChatsArray"
          :selected-chat="selectedRowKeyChat"
          :selected-index.sync="selectedIndex"
          :is-loading="fetchQueueChatLoading"
          :closed.sync="closed"
        />

        <el-row v-if="$store.state.showAdvanced">
          <JSONEditor v-model="selectedChat" />
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SearchChatFilters from "@/components/LiveChat/SearchChatFilters";
import QueueChatsList from "@/components/LiveChat/QueueChatsList";
import ChatsInteractions from "@/components/ChatInteractions/Index";
import JSONEditor from "@/components/JSONEditor";

import { doesChatBelongsToCurrentAgent } from "@/helperMethods/livechat/isAgentInChat";
import { getAvatar, getDisplayId } from "@/helperMethods/livechat/util";
import { simplifyMessage } from "@/helperMethods/livechat/simplifyMessage";
import { notifyWebsocketReconnected } from "@/helperMethods/notifyError";
import { pushNotification } from "@/helperMethods/pushNotification";
import { listenGraphQLWebsocket, GRAPHQL_WEBSOCKET_STATE } from "@/store/api";
import _ from "lodash";
import LocalStorageManager from "../../localStorageManager";
import gql from "graphql-tag";
import Vue from "vue";
import { mapGetters } from "vuex";
import { isFunction } from "@/helperMethods/util";

export default {
  name: "ChatsTab",
  components: {
    SearchChatFilters,
    QueueChatsList,
    ChatsInteractions,
    JSONEditor,
  },

  data() {
    return {
      selectedTab: "queue",
      selectedIndex: 0,
      closed: false,
      type: { name: "queue", isSearchDateDisabled: true },
    };
  },
  mounted() {
    this.fetchQueueChats();
    this.unsubcribleWSReconnectedEvent = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.RECONNECTED,
      () => {
        notifyWebsocketReconnected();
        this.fetchQueueChats();
      }
    );
    window.addEventListener("online", this.fetchQueueChats);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.fetchQueueChats);
    isFunction(this.unsubcribleWSReconnectedEvent) &&
      this.unsubcribleWSReconnectedEvent();
  },
  computed: {
    ...mapGetters([
      "fetchQueueChatLoading",
      "queueChatsArray",
      "queueAssignedChatsArray",
      "queueUnassignedChatsArray",
      "selectedChatId",
      "getRoutingMode",
      "isAgentOnline",
    ]),

    selectedChat: {
      get() {
        return this.$store.getters.selectedChat;
      },
      set(selectedChat) {
        if (selectedChat && _.has(selectedChat, "RowKey")) {
          this.$store.commit("SET_SELECTED_CHAT_ID", selectedChat.RowKey);
        } else {
          this.$store.commit("SET_SELECTED_CHAT_ID", null);
        }
      },
    },

    isNotRoutingAssignmentMode() {
      return this.getRoutingMode !== "assignment";
    },

    selectedRowKeyChat() {
      if (_.isEmpty(this.selectedChatId)) {
        return null;
      }

      const selectedchat = this.selectedChat;

      const unreadMessageList = LocalStorageManager.getUnreadMessageList();
      const targetUnreadMessageChat = _.find(unreadMessageList, {
        chatId: this.selectedChatId,
      });
      if (targetUnreadMessageChat) {
        targetUnreadMessageChat.unreadMessage = 0;
      } else {
        unreadMessageList.push({
          chatId: this.selectedChatId,
          unreadMessage: 0,
        });
      }

      LocalStorageManager.setUnreadMessageList(unreadMessageList);

      const chatWithMessageSorted = Object.assign({}, selectedchat, {
        reply: "",
        unreadMessage: 0,
      });
      return chatWithMessageSorted;
    },
    isAdaptiveView() {
      return screen.width <= 1024;
    },
  },
  methods: {
    getAvatar,
    //FIXME(Brian): reduce duplicated between several components
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
        position: "bottom-right",
      });
    },
    closeMobileChatView() {
      this.closed = false;
    },
    assignNewChat() {
      this.$apollo
        .query({
          query: gql`
            query {
              livechatAPI {
                getNextLivechat
              }
            }
          `,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const error = _.get(
            response.data,
            "livechatAPI.getNextLivechat.error"
          );
          if (error) {
            this.notifyError(error);
          } else {
            const nextChat = _.get(
              response.data,
              "livechatAPI.getNextLivechat.nextLivechat"
            );

            if (nextChat) {
              const selectedChatId = nextChat.RowKey;
              Vue.set(
                this.$store.state.livechat,
                "selectedChatId",
                selectedChatId
              );
              this.$store.commit("SELECT_LIVE_CHAT", {
                chatId: selectedChatId,
                type: "queue",
              });
              this.joinChat(nextChat);
            }
          }
        })
        .catch((err) => {
          this.notifyError(err);
        });
    },
    joinChat(chat) {
      this.selectedChat = chat;
      this.$nextTick(() => {
        this.$refs.interactionsPanel.joinLivechat(chat);
      });
    },
    fetchQueueChats() {
      return this.$store.dispatch("FETCH_ALL_QUEUE_CHATS_FOR_MONITORING");
    },
  },
  apollo: {
    $subscribe: {
      livechatInviteAgent: {
        query: gql`
          subscription {
            livechatInviteAgent
          }
        `,
        result({ data }) {
          const inviteFrom = data.livechatInviteAgent.inviter;
          const memo = data.livechatInviteAgent.memoComment || "";
          const chat = data.livechatInviteAgent.chat;
          let confirmationMessage = `${inviteFrom} has invited you to participate a livechat session. `;
          if (memo) {
            confirmationMessage += `<br/><br/><strong>Notes:</strong><br/>"${memo}"<br/><br/>`;
          }
          confirmationMessage += `Do you want to accept this invitation?`;

          this.$confirm(confirmationMessage, "Invitation", {
            confirmButtonText: "Join chat",
            cancelButtonText: "Cancel",
            dangerouslyUseHTMLString: true,
          })
            .then(() => {
              const email = _.get(this, "$store.state.profile.email", "");
              const name = _.get(this, "$store.state.profile.name", "");
              if (email) {
                this.$store
                  .dispatch("LIVECHAT_ACCEPT_INVITATION", {
                    chat,
                  })
                  .then((acceptedInvitation) => {
                    if (acceptedInvitation) {
                      this.$notify.success({
                        title: "Success",
                        message: "Successfully accepted chat invitation",
                        position: "bottom-right",
                      });
                    }
                  })
                  .catch((err) => {
                    this.notifyError(
                      "Encountered error accepting live chat invitation"
                    );
                  });
              }
            })
            .catch((action) => {
              this.$notify.info({
                title: "Info",
                message: "You dismissed the invitation.",
                position: "bottom-right",
              });
            });
        },
      },
      livechatSessionLeave: {
        query: gql`
          subscription {
            livechatSessionLeave
          }
        `,
        result({ data }) {
          // When another agent leave the chat:
          // - Events: On escalation and normal chat leaving
          // - Result: On escalation new chat will be created
          // data = { previousChat, newChat }
          // Note: `newChat` only available for on escalation
          const updatedLivechat = _.get(data, "livechatSessionLeave");
          const previousChat = _.get(updatedLivechat, "previousChat");
          const newChat = _.get(updatedLivechat, "newChat");

          // NOTE: if using REMOVE_QUEUE_CHAT removed in mem, after refresh still exist on normal leave
          // UPDATE_QUEUE_CHAT mean having duplicated chat session
          // Visibility in this case depends on chat.agents logic
          if (previousChat.RowKey) {
            this.$store.commit("UPDATE_QUEUE_CHAT", {
              updatedLivechat: previousChat,
            });
          }

          if (newChat?.RowKey) {
            this.$store.commit("UPDATE_QUEUE_CHAT", {
              updatedLivechat: newChat,
            });
          }
        },
      },
      livechatSessionUpdated: {
        query: gql`
          subscription {
            livechatSessionUpdated
          }
        `,
        result({ data }) {
          const updatedLivechat = _.get(data, "livechatSessionUpdated");
          const updatedChatAgentList = _.get(updatedLivechat, "agents", []);
          const oldChatAgentList = _.get(this.selectedchat, "agents", []);
          const agentJoinedChat =
            updatedChatAgentList.length > oldChatAgentList.length;

          const ownAgentEmail = _.get(this.$store.state, "profile.email");
          const joinedAgentNotSelf = !updatedChatAgentList.includes(
            ownAgentEmail
          );
          const selectedChatIsUpdated =
            this.selectedChatId === updatedLivechat.RowKey;
          if (agentJoinedChat && joinedAgentNotSelf && selectedChatIsUpdated) {
            // this.$store.commit("SET_SELECTED_CHAT_ID", "");

            const h = this.$createElement;
            const message = `Chat has been taken by ${updatedChatAgentList[0]}. Please select another chat.`;
            const finalizedMessage = h("i", { style: "color: teal" }, message);
            this.$notify.warning({
              title: "Chat is taken",
              message: finalizedMessage,
              position: "bottom-right",
            });

            // close selected chat if chat is already taken
            this.$store.commit("SET_SELECTED_CHAT_ID", null);
            this.$store.commit("SELECT_MONITOR_CHAT", {
              partitionKey: null,
              rowKey: null,
              type: "monitor",
            });
          }

          this.$store.commit("UPDATE_QUEUE_CHAT", {
            updatedLivechat,
          });
        },
      },
      newLivechatMessage: {
        query: gql`
          subscription {
            newLivechatMessage
          }
        `,
        // Result hook
        result({ data }) {
          const newMessage = _.get(data, "newLivechatMessage");

          this.$store
            .dispatch("HANDLE_NEW_MESSAGE", {
              newMessage,
            })
            .then((chat) => {
              if (_.isEmpty(chat)) {
                return;
              }

              const simplifiedMessage = simplifyMessage(newMessage.message) || {
                text: "",
              };

              // Browser notification
              const agentIsOnline = this.isAgentOnline;
              const isUserMessage =
                newMessage.message.type !== "reply" &&
                newMessage.message.type !== "agent";

              const agentEmail = _.get(this.$store, "state.profile.email");
              const chatUserId = newMessage.user_id;
              const chatBelongsToCurrentAgent = doesChatBelongsToCurrentAgent(
                agentEmail,
                chatUserId
              );

              if (isUserMessage && agentIsOnline && chatBelongsToCurrentAgent) {
                const displayIdPayload = {
                  ...chat.stateVariables,
                  chatId: chat.RowKey,
                  user_id: chat.user_id,
                };
                const displayId = getDisplayId(displayIdPayload);
                const pushNotifMessage = {
                  title: `New message from user: ${displayId}`,
                  body: simplifiedMessage.text,
                  icon: "./img/icons/mstile-150x150.png",
                };
                pushNotification(pushNotifMessage, 2000, () => {
                  window.focus();
                  if (this.$route.path !== "/livechat") {
                    this.$router.push("/livechat").catch(() => {});
                  }
                });
                this.$nextTick(() => {
                  const selectedChatRowKey = _.get(
                    this.selectedChat,
                    "RowKey",
                    ""
                  );
                  const isCurrentSelectedChatMessage =
                    chat.RowKey === selectedChatRowKey;
                  if (
                    this.$refs["interactionsPanel"] &&
                    isCurrentSelectedChatMessage
                  ) {
                    _.delay(() => {
                      this.$refs["interactionsPanel"] &&
                        this.$refs["interactionsPanel"].scrollToBottom();
                    }, 100);
                  }
                });
              }
            });
        },
      },
      livechatResolved: {
        query: gql`
          subscription {
            livechatResolved
          }
        `,
        result({ data }) {
          const resolvedChatId = _.get(data, "livechatResolved.chatId");
          const resolvedChat = _.get(data, "livechatResolved");
          const agentProfileEmail = _.get(this.$store.state, "profile.email");
          const isAgentInvolvedChat = _.includes(
            resolvedChat.chat.agents,
            agentProfileEmail
          );
          // to close mobile and display queue
          this.closeMobileChatView();

          if (isAgentInvolvedChat) {
            this.$notify.success({
              title: "Success",
              message: `Chat ${resolvedChatId} has been resolved, you can still view it under the 'Resolved' tab.`,
              position: "bottom-right",
            });
          }

          const resolvedChatisSelected = this.selectedChatId === resolvedChatId;
          if (resolvedChatisSelected) {
            this.$store.commit("SET_SELECTED_CHAT_ID", "");
          }
          this.$store.commit("ADD_RESOLVED_CHAT", {
            resolvedChat,
          });

          LocalStorageManager.removeUnreadRecordUsingChatId(resolvedChatId);
          this.$store.commit("REMOVE_QUEUE_CHAT", { resolvedChatId });
        },
      },
      livechatJoinQueue: {
        query: gql`
          subscription {
            newChat: livechatJoinQueue
          }
        `,
        async result({ data }) {
          const newChat = _.get(data, "newChat");
          const email = this.$store.state.profile.email;
          const isInsideOrAssignedChat = _.get(newChat, "agents", []).includes(
            email
          );

          newChat.typingIndicatorStatus = false;
          newChat.unreadMessage = 0;
          this.$store.commit("ADD_QUEUE_CHAT", { newChat });

          if (isInsideOrAssignedChat) {
            await this.fetchQueueChats();
          }

          // Filtered agents by department still receive chat from server
          // Check department to show notification
          const isCheckingWithDepartment = _.get(
            this,
            "this.$store.state.modules.handover.handoverRouting.byDepartment",
            false
          );
          let isAgentEligibleForLivechatDepartment = true;
          if (isCheckingWithDepartment && newChat.department) {
            const departments = _.get(
              this,
              "$store.state.department.departments",
              []
            );
            const agentDepartments = _.get(
              this,
              "$store.state.profile.departments",
              []
            );
            const departmentOfLivechat = _.find(
              departments,
              (department) => department.id === newChat.department
            );
            isAgentEligibleForLivechatDepartment =
              departmentOfLivechat &&
              _.indexOf(agentDepartments, departmentOfLivechat.name) !== -1;
          }

          const isBroadcastRoutingMode = this.getRoutingMode === "broadcast";
          if (
            (isInsideOrAssignedChat || isBroadcastRoutingMode) &&
            isAgentEligibleForLivechatDepartment
          ) {
            const displayIdPayload = {
              ...newChat.stateVariables,
              chatId: newChat.RowKey,
              user_id: newChat.user_id,
            };
            const displayId = getDisplayId(displayIdPayload);

            const pushNotifMessage = {
              title: displayId + " joined chat",
              icon: "./img/icons/mstile-150x150.png",
            };
            pushNotification(pushNotifMessage, 5000, () => {
              window.focus();
            });
          }
        },
      },
      livechatTypingIndicator: {
        query: gql`
          subscription {
            livechatTypingIndicatorStatus
          }
        `,
        // Result hook
        result({ data }) {
          setTimeout(() => {
            const { user_id, status: isTyping } = _.get(
              data,
              "livechatTypingIndicatorStatus",
              { user_id: "", isTyping: false }
            );
            this.$store.dispatch("SET_TYPING_INDICATOR_FOR_CHAT", {
              user_id,
              isTyping,
            });
          });
        },
      },
      livechatAcceptedInvitation: {
        query: gql`
          subscription {
            chatInvite: livechatAcceptedInvitation
          }
        `,
        result({ data }) {
          const chatInvite = _.get(data, "chatInvite");
          const targetChat = this.queueChatsArray.find(
            (chat) => chat.RowKey === chatInvite.RowKey
          );
          if (targetChat) {
            this.$store.commit("UPDATE_QUEUE_CHAT", {
              updatedLivechat: chatInvite,
            });
          } else {
            this.$store.commit("ADD_QUEUE_CHAT", { newChat: chatInvite });
          }
        },
      },
    },
  },
};
</script>

<style scoped>
.leftPane,
.leftPane .box-card {
  height: 100%;
}

.box-card {
  border-color: transparent;
  height: 100%;
}

.leftPane .el-tab-pane {
  padding: 0;
}

.leftPane,
.rightPane {
  height: 73vh;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1024px) {
  .leftPane,
  .rightPane {
    height: 76vh;
  }
}

@media only screen and (min-width: 1280px) {
  .leftPane,
  .rightPane {
    height: 79vh;
  }
}

@media only screen and (min-width: 1440px) {
  .leftPane,
  .rightPane {
    height: 81vh;
  }
}

.leftPane .el-card__body {
  height: 90%;
  overflow: auto;
}

.mark {
  margin-top: 8px;
  margin-left: 3px;
  line-height: 1;
  float: right;
}

.listTitle {
  border-bottom: 1px solid #ebeef5;
  padding-bottom: 5px;
}
</style>

<style>
#queueChatLeftPane .el-card__body {
  height: 80%;
  overflow: auto;
}
</style>

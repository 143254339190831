<template>
  <div>
    <el-radio-group v-model="auth.type" size="small">
      <el-radio-button
        v-for="method in authMethods"
        :key="method"
        :label="method"
        >{{ method }}</el-radio-button
      >
    </el-radio-group>

    <br />
    <br />

    <!-- Basic -->
    <template v-if="auth.type === 'Basic'">
      <el-form-item label="Username" style="margin-bottom: 5px">
        <el-input
          size="mini"
          placeholder="Enter username"
          style="width: 50%"
          v-model="auth.username"
        ></el-input>
      </el-form-item>
      <el-form-item label="Password" style="margin-bottom: 5px">
        <el-input
          size="mini"
          style="width: 50%"
          type="password"
          placeholder="Enter password"
          v-model="auth.password"
        ></el-input>
      </el-form-item>
    </template>

    <!-- Header -->
    <template v-else-if="auth.type === 'Header'">
      <el-form-item label="Token" style="margin-bottom: 5px">
        <el-input
          size="mini"
          style="width: 50%"
          placeholder="Enter token"
          v-model="auth.token"
        ></el-input>
      </el-form-item>
    </template>

    <template v-else-if="auth.type === 'Bearer Token'">
      <el-form-item label="Token" style="margin-bottom: 5px">
        <el-input
          size="mini"
          style="width: 50%"
          placeholder="Enter token"
          v-model="auth.token"
        ></el-input>
      </el-form-item>
      <el-form-item label="Prefix" style="margin-bottom: 5px">
        <el-input
          style="width: 50%"
          size="mini"
          placeholder="Enter prefix"
          v-model="auth.prefix"
        ></el-input>
      </el-form-item>
    </template>

    <!-- Basic -->
    <template v-if="auth.type !== 'Basic'">
      <el-form-item label="Access Token data path" style="margin-bottom: 5px">
        <el-input
          style="width: 50%"
          size="mini"
          placeholder="Enter access token path"
          v-model="auth.access.tokenPath"
        ></el-input>
      </el-form-item>
      <el-form-item label="Access Token URL" style="margin-bottom: 5px">
        <el-input
          size="mini"
          style="width: 50%"
          placeholder="Enter access token url"
          v-model="auth.access.url"
        ></el-input>
      </el-form-item>
      <el-form-item label="Access Token data" style="margin-bottom: 5px">
        <JSONEditor
          style="width: 50%"
          placeholder="Enter access token data"
          v-model="auth.access.data"
        />
      </el-form-item>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import JSONEditor from "@/components/JSONEditor";

export default {
  components: { JSONEditor },
  props: ["auth"],
  data() {
    return {};
  },
  computed: {
    authMethods() {
      return ["Basic", "Header", "Bearer Token"];
    },
  },
  beforeMount() {
    this.auth.type = _.capitalize(this.auth.type);
  },
  methods: {
    addHeader() {
      this.$emit("addHeader");
    },
    removeHeader(index) {
      this.$emit("removeHeader", index);
    },
  },
};
</script>

<style></style>

<template>
  <div style="display: inline-block;">
    <el-checkbox
      v-model="value[name]"
      :label="setting.label || name"
      border
      style="color:#909399; margin-bottom:1em; margin-right: 5px;"
    />
    <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="right">
      <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
    </el-tooltip>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
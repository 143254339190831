<template>
  <div v-if="groupId && reportList.length > 0">
    <el-tabs v-model="currentReport" @tab-click="selectReport">
      <el-tab-pane
        v-for="report in reportList"
        :lazy="true"
        :key="report.title"
        :label="report.title"
      ></el-tab-pane>
    </el-tabs>

    <div ref="embedContainer" style="height: 90vh; width: 100%"></div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import * as client from "powerbi-client";

export default {
  props: ["userProfileDepartmentIds"],
  data() {
    return {
      currentReport: "",
      reportURL: "",
    };
  },
  computed: {
    isHandoverByDepartmentEnabled() {
      return _.get(
        this.$store,
        "state.modules.handover.handoverRouting.byDepartment",
        false
      );
    },
    groupId() {
      return _.get(this, "$store.state.modules.analytics.powerBI.groupId");
    },
    oldReportId() {
      // TODO: For backward compatible. Remove in November
      return _.get(this, "$store.state.modules.analytics.powerBI.reportId");
    },
    reportList() {
      const groupId = this.groupId;
      const {
        reportId1,
        reportId2,
        reportId3,
        reportId4,
        reportId5,
        reportId6,
        reportId7,
        reportId8,
      } = this.$store.state.modules.analytics.powerBI;
      const {
        reportTitle1,
        reportTitle2,
        reportTitle3,
        reportTitle4,
        reportTitle5,
        reportTitle6,
        reportTitle7,
        reportTitle8,
      } = this.$store.state.modules.analytics.powerBI;

      const {
        reportDepartmentId1,
        reportDepartmentId2,
        reportDepartmentId3,
        reportDepartmentId4,
        reportDepartmentId5,
        reportDepartmentId6,
        reportDepartmentId7,
        reportDepartmentId8,
      } = this.$store.state.modules.analytics.powerBI;

      const reports = _.zipWith(
        [reportTitle1, reportTitle2, reportTitle3, reportTitle4, reportTitle5, reportTitle6, reportTitle7, reportTitle8],
        [reportId1, reportId2, reportId3, reportId4, reportId5, reportId6, reportId7, reportId8],
        [
          reportDepartmentId1,
          reportDepartmentId2,
          reportDepartmentId3,
          reportDepartmentId4,
          reportDepartmentId5,
          reportDepartmentId6,
          reportDepartmentId7,
          reportDepartmentId8,
        ],
        (title, reportId, departmentId) => ({
          title,
          groupId,
          reportId,
          departmentId,
        })
      )
        .filter((report) => report.reportId)
        .filter((report) => {
          if (!this.isHandoverByDepartmentEnabled) {
            return true;
          }

          const userNoDept = _.isEmpty(this.userProfileDepartmentIds);
          const departmentIdNotSet = !report.departmentId;
          const userBelongToDept = _.includes(
            this.userProfileDepartmentIds,
            report.departmentId
          );
          return userNoDept || departmentIdNotSet || userBelongToDept;
        });

      if (reports.length === 0 && this.oldReportId) {
        reports.push({
          title: "Chatbot Analytics",
          groupId,
          reportId: this.oldReportId,
        });
      }

      return reports;
    },
  },
  mounted() {
    if (this.reportList.length > 0) {
      this.loadReport(this.reportList[0]);
    }
  },
  watch: {
    reportList: (newList, oldList) => {
      if (newList !== oldList) {
        this.loadReport(newList[0]);
      }
    },
  },
  methods: {
    selectReport(tab) {
      this.loadReport(this.reportList[tab.index]);
    },

    loadReport({ title = "Analytics Report", groupId, reportId }) {
      if (groupId && reportId) {
        const notification = this.$notify.warning({
          title: "Loading",
          message: `Loading ${title}...`,
          position: "bottom-right",
          duration: 0,
        });

        this.$rest(
          "get",
          `get_powerBI_access_token?groupId=${groupId}&reportId=${reportId}`,
          {
            groupId,
            reportId,
          }
        )
          .then(({ accessToken }) => {
            const config = {
              id: reportId,
              accessToken,
              type: "report",
              tokenType: client.models.TokenType.Embed,
              permissions: client.models.Permissions.Read,
              embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: true,
              },
            };

            const report = window.powerbi.embed(
              this.$refs.embedContainer,
              config
            );

            this.$refs.embedContainer.firstElementChild.setAttribute(
              "frameborder",
              0
            );
          })
          .catch((error) => {
            this.$notify.error({
              title: "Error",
              message: `Unable to load ${title} report, please check Power BI Setting!`,
              position: "bottom-right",
            });
          })
          .finally(() => {
            notification.close();
          });
      } else {
        if (this.reportURL) {
          const { groupId, reportId } = this.$store.commit(
            "SET_ANALYTICS_URL",
            this.reportURL
          );
          this.reportURL = "";

          if (groupId && reportId) {
            this.loadReport({ groupId, reportId });
          }
        } else {
          // console.warn("Missing Report Id");
        }
      }
    },
  },
};
</script>

<template>
  <el-card
    style="overflow: auto"
    class="box-card"
    body-style="padding: 0;height: 80.5vh; overflow:auto"
  >
    <div slot="header">
      <el-row
        type="flex"
        align="middle"
        justify="space-between"
        style="margin-bottom: 5px"
      >
        <h4 style="margin: 0">Web Pages</h4>
        <WebPageEditorAddButton
          @onSelect="onSelect($event)"
          :web-pages="webPages"
        />
      </el-row>
      <el-input
        v-model="search"
        placeholder="Search web page..."
        size="mini"
        class="input-with-select"
        prefix-icon="el-icon-search"
        :clearable="true"
        @keyup.native="onSearch"
        @clear="onSearch"
      >
      </el-input>
    </div>

    <el-table
      height="100%"
      highlight-current-row
      ref="web-pages-table"
      size="mini"
      style="width: 100%"
      :cell-style="{ cursor: 'pointer' }"
      :border="true"
      :show-header="true"
      :data="webPages"
    >
      <el-table-column width="100px" label="Status" prop="status">
        <template slot-scope="scope">
          <div @click.prevent="onSelect(scope)">
            <el-tag size="mini" :type="statusColor(scope.row.status)">
              <i v-if="scope.row.status === 'editing'" class="el-icon-edit"></i>
              <i
                v-if="scope.row.status === 'published'"
                class="el-icon-check"
              ></i>
              <i
                v-if="scope.row.status === 'approved'"
                class="el-icon-date"
              ></i>
              {{ scope.row.status }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Title">
        <template slot-scope="scope">
          <div @click.prevent="onSelect(scope)">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="85px" label="Type" prop="type">
        <template slot-scope="scope">
          <div @click.prevent="onSelect(scope)">
            <el-tag size="mini" type="info">
              <i v-if="scope.row.type === 'pdf'" class="el-icon-document"></i>
              {{ scope.row.type || "web" }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import WebPageEditorAddButton from "./Add.vue";
import _ from "lodash";

export default {
  name: "WebPageEditorList",
  data() {
    return {
      search: null,
    };
  },
  props: {
    webPages: {},
    selectedWebPage: {},
  },
  components: {
    WebPageEditorAddButton,
  },
  methods: {
    /**
     * On search keyword changed
     *
     * @return {void}
     */
    onSearch: _.debounce(function ($event) {
      this.$emit("onSearch", this.search);
    }, 500),

    /**
     * @description On web page selected
     * @return {void}
     */
    onSelect(scope) {
      this.$emit("onSelect", scope.$index);
      this.$refs["web-pages-table"].setCurrentRow(scope.row);
    },

    /**
     * Get status color
     *
     * @return {string}
     */
    statusColor(status) {
      const map = {
        editting: "info",
        approved: "warning",
        published: "success",
      };

      return map[status];
    },
  },
};
</script>

<style scoped>
>>> .el-card__header {
  padding: 10px 15px;
}
</style>

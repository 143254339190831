<template>
  <el-container style="padding:0 20px">
    <el-row style="padding-right:12px">
      <el-button :loading="loadBilling" @click="downloadBilling">Download billing</el-button>
    </el-row>
    <br />
    <el-row>
      <el-date-picker
        v-model="selectedDate"
        format="MM-yyyy"
        type="month"
        placeholder="Pick a month"
      ></el-date-picker>
    </el-row>
  </el-container>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "Billing",
  data() {
    return { loadBilling: false, selectedDate: new Date() };
  },
  methods: {
    downloadBilling() {
      this.loadBilling = true;
      const selectedDate = moment(this.selectedDate).format("MM-YYYY");
      this.$rest("get", `billing?date=${selectedDate}`)
        .then((response) => {
          downloadBillingAsExcel(response, this);
          this.$notify.success({
            title: "Success",
            position: "bottom-right",
            message: `Download billing excel successfully.`,
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: error.message,
          });
        })
        .finally(() => {
          this.loadBilling = false;
        });
    },
  },
};

function downloadBillingAsExcel(response, vue) {
  try {
    const wb = XLSX.utils.book_new();

    const ws_data = [];
    const projectName = response.project;
    Object.keys(response).forEach((key) => {
      if (typeof response[key] === "object") {
        const nestedObject = response[key];

        if (Array.isArray(nestedObject)) {
          ws_data.push([key, nestedObject.join(",")]);
        } else {
          Object.keys(nestedObject).forEach((nestedObjectKey) => {
            ws_data.push([
              `${key}:${nestedObjectKey}`,
              nestedObject[nestedObjectKey],
            ]);
          });
        }
      } else {
        ws_data.push([key, response[key]]);
      }
    });
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    const filename = vue.$exportFilename(``, "xlsx");
    XLSX.utils.book_append_sheet(wb, ws, projectName);
    XLSX.writeFile(wb, filename, {});
  } catch (err) {
    throw err;
  }
}
</script>
import store from "@/store";
import _ from "lodash";
import { ContentNodeObject } from ".";
import { v4 as uuid } from "uuid";
import { version } from "punycode";
export function isSelf(email: string): boolean {
  const userEmail = _.get(store, "state.profile.email");
  return userEmail === email;
}

export function formatNotificationMessage(
  contentNodeObjects: ContentNodeObject[]
): string {
  const onlyOneContentNodde = contentNodeObjects.length === 1;
  if (onlyOneContentNodde) {
    const contentNodeObject = contentNodeObjects[0];
    const id = _.get(contentNodeObject, "id");
    const author = _.get(contentNodeObject, "author");

    let message = `Content node "${id}" has been edited.`;
    if (author) {
      message = `${author} edited content node "${id}"`;
    }

    return message;
  }

  const author = _.get(contentNodeObjects[0], "author");
  const length = _.get(contentNodeObjects, "length");
  let message = `${length} content nodes have been edited.`;
  if (author) {
    message = `${author} edited ${length} content nodes`;
  }

  return message;
}

function processActionUpload(actions: [string, string, string][]) {
  return _.compact(
    _.map(actions, (actionsEventDataArr) => {
      const [text, event, data] = actionsEventDataArr;
      if (text && event && data) {
        return {
          text,
          event,
          data,
          type: "event",
        };
      }
    })
  );
}

function processConditionsUpload(conditions: [string, string, string][]) {
  return _.compact(
    _.map(conditions, (conditionArr) => {
      const [property, operator, value] = conditionArr;
      const upperCasedOperator = operator.toUpperCase();
      const isUnaryCondition =
        property &&
        (upperCasedOperator === "EXISTS" ||
          upperCasedOperator === "NOT EXISTS");
      const isBinaryCondition = property && upperCasedOperator && value;
      if (isUnaryCondition || isBinaryCondition) {
        return {
          property,
          operator: upperCasedOperator,
          value,
        };
      }
    })
  );
}

function processNextUpload(next: [string, string][]) {
  const [event, data] = _.get(next, "[0]", []);

  if (!event && !data) {
    return;
  }
  return {
    type: "event",
    event,
    data,
  };
}
export function processContentUpload(
  data: { [column: string]: string }[],
  existingContent: { [contentId: string]: any }
) {
  const answerRegex = /answer\d+/i;
  const nextEventRegex = /nextEvent/i;
  const nextDataRegex = /nextData/i;
  const buttonTextRegex = /button\d+Text/i;
  const buttonEventRegex = /button\d+Event/i;
  const buttonDataRegex = /button\d+Data/i;
  const quickReplyTextRegex = /quickReply\d+Text/i;
  const quickReplyEventRegex = /quickReply\d+Event/i;
  const quickReplyDataRegex = /quickReply\d+Data/i;
  const conditionPropertyRegex = /condition\d+Property/i;
  const conditionOperatorRegex = /condition\d+Operator/i;
  const conditionValueRegex = /condition\d+Value/i;

  const camelcasedData = data.map((obj) => {
    return _.mapKeys(obj, (val, key) => _.camelCase(key.toLowerCase()));
  });
  const filteredContent = _.filter(
    camelcasedData,
    (content) => !!content.contentId
  );
  const uploadedContentList: any[] = [];
  for (const obj of filteredContent) {
    const { contentId, versionId } = obj;
    const combinedContentId = versionId
      ? contentId + ":" + versionId
      : contentId;

    // Destructure and zip various data columns
    const answers = _.filter(
      obj,
      (data, key) => !!(answerRegex.test(key) && data)
    );
    const nextEvent = _.filter(obj, (_, key) => nextEventRegex.test(key));
    const nextData = _.filter(obj, (_, key) => nextDataRegex.test(key));
    const buttonTexts = _.filter(obj, (_, key) => buttonTextRegex.test(key));
    const buttonEvents = _.filter(obj, (_, key) => buttonEventRegex.test(key));
    const buttonData = _.filter(obj, (_, key) => buttonDataRegex.test(key));
    const quickReplyTexts = _.filter(obj, (_, key) =>
      quickReplyTextRegex.test(key)
    );
    const quickReplyEvents = _.filter(obj, (_, key) =>
      quickReplyEventRegex.test(key)
    );
    const quickReplyData = _.filter(obj, (_, key) =>
      quickReplyDataRegex.test(key)
    );
    const conditionProperties = _.filter(obj, (_, key) =>
      conditionPropertyRegex.test(key)
    );
    const conditionOperators = _.filter(obj, (_, key) =>
      conditionOperatorRegex.test(key)
    );
    const conditionValues = _.filter(obj, (_, key) =>
      conditionValueRegex.test(key)
    );
    const delay = obj.delay;

    const next = _.zip(nextEvent, nextData);
    const buttons = _.zip(buttonTexts, buttonEvents, buttonData);
    const quickReplies = _.zip(
      quickReplyTexts,
      quickReplyEvents,
      quickReplyData
    );
    const conditions = _.zip(
      conditionProperties,
      conditionOperators,
      conditionValues
    );

    if (contentId && answers.length > 0) {
      const trimmedContentBaseId = contentId.trim();
      const trimmedVersionId = versionId.trim();

      const processedButtons = processActionUpload(buttons as any);
      const processedQuickReplies = processActionUpload(quickReplies as any);
      const processedConditions = processConditionsUpload(conditions as any);
      const processedNext = processNextUpload(next as any);

      const nodePayload = {
        baseId: trimmedContentBaseId,
        versionId: trimmedVersionId,
        answers,
        buttons: processedButtons,
        quickReplies: processedQuickReplies,
        conditions: processedConditions,
        department: ["general"],
      };

      if (delay) {
        Object.assign(nodePayload, {
          delay,
        });
      }

      if (processedNext) {
        Object.assign(nodePayload, {
          next: processedNext,
        });
      }

      Object.assign(nodePayload, {
        duplicate: existingContent[combinedContentId] ? true : false,
        confirmed: existingContent[combinedContentId] ? false : true,
        new: existingContent[combinedContentId] ? false : true,
      });
      uploadedContentList.push(nodePayload);
    }
  }
  return uploadedContentList;
}

export function processTriggerUpload(
  data: { [column: string]: string }[],
  existingTriggers: { [contentId: string]: any }
) {
  const uploadedTriggersList: any[] = [];
  const triggersTrimmed = data.map((obj) => {
    return _.mapKeys(obj, (val, key) => _.camelCase(key.toLowerCase()));
  });

  const filteredTriggers = _.filter(
    triggersTrimmed,
    (trigger) => !!trigger.triggerId
  );
  const transformTest = (testStrings: string) => {
    const tests = testStrings ? testStrings.split(",") : [];
    return _.map(tests, (test) => {
      return { text: test };
    });
  };

  for (const trigger of filteredTriggers) {
    let { triggerId, positiveTest, negativeTest } = trigger;
    const tests = {
      positiveTest: transformTest(positiveTest),
      negativeTest: transformTest(negativeTest),
    };
    const node = {
      ...trigger,
      ...tests,
      type: trigger.type ? trigger.type : "text",
      duplicate: existingTriggers[triggerId] ? true : false,
      confirmed: existingTriggers[triggerId] ? false : true,
    };
    uploadedTriggersList.push(node);
  }
  return uploadedTriggersList;
}

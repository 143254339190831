<template>
  <div>
    <span
      style="margin-left:1.5em;margin-right: 3em;color:#909399; fontSize: 14px; margin-bottom:1em"
    >{{setting.label || name}}</span>
    <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="right">
      <i class="el-icon-question" style="margin-left:0.5em; color: #909399"></i>
    </el-tooltip>
    <el-transfer
      style="display: inline-block;margin-left:1.5em;"
      v-model="value[name]"
      :titles="setting.titles"
      :data="setting.data"
      target-order="push"
      :filterable="true"
    ></el-transfer>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
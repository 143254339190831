<template>
  <el-popover
    placement="bottom"
    title="Create New Folder"
    width="300"
    trigger="click"
    v-model="isCreatePopoverOpen"
  >
    <div>
      <el-input
        v-model="temporaryFolderName"
        placeholder="Please input folder name..."
        @keyup.enter.native="onAdd"
      >
        <template slot="append">
          <el-button
            id="webpage-add-button"
            size="small"
            :disabled="!isValidFolderName"
            @click.prevent="onAdd"
          >
            <i class="el-icon-check"></i>
          </el-button>
        </template>
      </el-input>
      <small style="color: #e4392b" v-if="!isValidFolderName">
        Folder name has been used.
      </small>
    </div>
    <el-button
      slot="reference"
      type="text"
      style="margin-left: 10px"
      icon="el-icon-folder-add"
    >
    </el-button>
  </el-popover>
</template>

<script>
import _ from "lodash";

export default {
  props: ["folders"],
  data() {
    return {
      temporaryFolderName: null,
      isCreatePopoverOpen: false,
      errorMessage: null,
    };
  },
  computed: {
    /**
     * Check if folder name has been taken
     *
     * @return {boolean}
     */
    isValidFolderName() {
      if (!this.temporaryFolderName) {
        return true;
      }

      const folderNames = Object.keys(this.folders).map((folder) =>
        folder.toLowerCase()
      );

      return !_.includes(folderNames, this.temporaryFolderName);
    },
  },
  methods: {
    /**
     * Add new folder
     *
     * @return {void}
     */
    onAdd() {
      if (!this.isValidFolderName) {
        return;
      }

      this.$set(this.folders, this.temporaryFolderName, {});
      this.$nextTick(() => {
        this.temporaryFolderName = null;
        this.isCreatePopoverOpen = false;
      });
    },
  },
};
</script>

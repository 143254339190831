<template>
  <el-row type="flex" style="flex-flow: row wrap; align-items: start;">
    <el-checkbox v-model="field.visible" label="Visible" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.editable" label="Editable" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.multiline" label="Multiline" :disabled="!canEdit"></el-checkbox>
  </el-row>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["field", "canEdit"],
});
</script>


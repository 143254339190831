import { MutationTree } from "vuex/types";

import { TransactionState } from "./types";
import { RootState } from "@/store/types";
import Vue from "vue";
import _ from "lodash";

const mutations: MutationTree<TransactionState> = {
  SET_TRASACTIONS(state, payload) {
    state.transactions = payload;
  },
  SET_TRASACTIONS_META(state, payload) {
    state.transactionsMeta = payload;
  },
  SET_CURRENT_TYPE(state, payload) {
    state.currentType = payload;
  },
  SET_CURRENT_ROW(state, payload) {
    state.currentRow = payload;
  },
  APPEND_TRANSACTION_META(state, payload) {
    const _transactionsMeta = _.cloneDeep(state.transactionsMeta);
    _transactionsMeta.push(payload);
    Vue.set(state, "transactionsMeta", _transactionsMeta);
  },
  REPLACE_TRANSACTION_META(state, payload) {
    const _transactionsMeta = _.cloneDeep(state.transactionsMeta);
    const existingIndex: number = _transactionsMeta.findIndex(
      (transactionMeta) => transactionMeta.id === payload.id
    );
    if (existingIndex > -1) {
      _transactionsMeta[existingIndex] = payload;
      Vue.set(state, "transactionsMeta", _transactionsMeta);
    }
  },
  DELETE_TRANSACTION_META(state, payload) {
    const _transactionsMeta = _.cloneDeep(state.transactionsMeta);
    const existingIndex: number = _transactionsMeta.findIndex(
      (transactionMeta) => transactionMeta.id === payload.id
    );
    if (existingIndex > -1) {
      _transactionsMeta.splice(existingIndex, 1);
      Vue.set(state, "transactionsMeta", _transactionsMeta);
    }
  },
};

export default mutations;

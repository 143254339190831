<template>
  <div style="margin-right: 4px">
    <avatar
      v-if="profilePictureExists(chat)"
      :username="getChatName(chat)"
      :src="getAvatar(chat.RowKey, get(chat, 'stateVariables.profile_pic', ''))"
      :size="40"
      :customStyle="{ display: 'inline-block' }"
    ></avatar>
    <avatar
      v-else
      :username="getChatName(chat)"
      :size="40"
      :customStyle="{ display: 'inline-block' }"
    ></avatar>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import get from "lodash/get";
import { getDisplayId, getAvatar } from "@/helperMethods/livechat/util";
import { getChatName } from "@/filters/index";

export default {
  props: { chat: Object },
  components: {
    Avatar,
  },
  methods: {
    getAvatar,
    getDisplayId,
    getChatName,
    get,
    profilePictureExists(chat) {
      return !!getAvatar(
        chat.RowKey,
        get(chat, "stateVariables.profile_pic", "")
      );
    },
  },
};
</script>

<style></style>

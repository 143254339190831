import { graph } from "@/store/api";
import _ from "lodash";
import gql from "graphql-tag";
import { IFaq, IExample } from "../faqType";

export function updateApolloDataset(newTrainingDataset: IFaq[]): boolean {
  graph.writeQuery({
    query: gql`
      query {
        faqAPI {
          dataset: getDataset
        }
      }
    `,
    data: {
      faqAPI: {
        dataset: newTrainingDataset,
      },
    },
  });

  return true;
}

export function prepareDatasetToSave(dataset: IFaq[]): IFaq[] {
  const datasetToSave: IFaq[] = _.cloneDeep(dataset);
  datasetToSave.forEach((row) => {
    const distinctList: string[] = [];
    row.text = "";

    // Reduce in reverse direction to keep the latest version.
    row.variations = row.variations
      .reduceRight<IExample[]>((memo, variation) => {
        if (variation?.text) {
          const exist = distinctList.includes(variation.text.toLowerCase());
          if (!exist) {
            distinctList.push(variation.text.toLowerCase());
            memo.push(variation);
          }
        }
        return memo;
      }, [])
      .reverse();
  });

  return datasetToSave;
}

export function modelUnevaluated(row: any) {
  const invalidConfidence = !row.confidence || row.confidence === 0;
  const noBatchTestTimeStamp = !row.batchTestTimestamp;
  return invalidConfidence && noBatchTestTimeStamp;
}

export function modelConfidenceBelowThreshold(
  row: any,
  classifierThreshold: number
) {
  const modelConfidence = row.confidence || 0;
  return classifierThreshold > modelConfidence;
}

export function modelConfidenceBelowPublished(
  row: any,
  models: {
    modelId: string;
    confidence: number;
    timestamp: string;
  }[],
  publishedModelId: string
) {
  const modelConfidence = row.confidence || 0;
  const publishedModelIndex = _.findIndex(
    models,
    (model) => model.modelId === publishedModelId
  );

  if (publishedModelIndex !== -1) {
    const publishedModel = models[publishedModelIndex];
    const publishedConfidence = publishedModel.confidence;
    return publishedConfidence > modelConfidence;
  } else {
    return false;
  }
}

export function formatConfidence(confidence: string | number) {
  let formattedConfidence = confidence;
  if (typeof confidence === "string") {
    formattedConfidence = parseInt(confidence).toFixed(2);
  } else if (typeof confidence === "number") {
    // Make it a percentage with one decimal
    formattedConfidence = (confidence * 100).toFixed(1);
  }

  return formattedConfidence + "%";
}

export const allowFeedbackOnContentNode = (node, feedbackCollectionOptions): boolean => {
  const mode = _.get(node, "options.feedbackCollectionMode", "default");
  const enabledForContentNodeOptions = _.get(
    feedbackCollectionOptions,
    "enabledForContentNode",
    false
  );
  const defaultContentNodeFeedback = _.get(
    feedbackCollectionOptions,
    "defaultContentNodeFeedback",
    false
  );
  return (
    enabledForContentNodeOptions &&
    ((mode === "default" && defaultContentNodeFeedback) || mode === "allow")
  );
};

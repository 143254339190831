<template>
  <el-container style="height:100%;">
    <el-main>
      <span style="font-size:24px; font-weight: light;">
        <b>Create Group</b>
      </span>
      <br />
      <br />
      <el-input
        v-model="subject"
        placeholder="Type subject here.."
        style="width: 300px"
        clearable
      />
      <el-button
        :loading="createLoading"
        style="margin-left: 20px"
        type="danger"
        @click="createGroup"
      >
        Create
      </el-button>
      <el-dialog title="Manage Chat" :visible.sync="manageChatDialogVisible">
        <el-table
          :data="availbleParticipantsToManage"
          @select-all="handleDeleteSelectAll"
          @select="handleDeleteSelect"
        >
          <el-table-column type="selection" width="150" />
          <el-table-column label="WhatsApp ID">
            <template slot-scope="scope">
              <span>{{ scope.row.participant }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Permission">
            <template slot-scope="scope">
              <span>{{ scope.row.admin ? "Admin" : "Participant" }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column property="participants" label="Participants" width="150"></el-table-column> -->
        </el-table>
        <br />
        <el-button
          :disabled="participantsToManage.length === 0"
          :loading="deleteLoading"
          type="danger"
          @click="deleteParticipants"
        >
          Delete
        </el-button>
        <el-button
          :disabled="participantsToManage.length === 0"
          :loading="promoteLoading"
          type="danger"
          @click="promoteToAdmin"
        >
          Promote to Admin
        </el-button>
        <el-button
          :disabled="participantsToManage.length === 0"
          :loading="removeLoading"
          type="danger"
          @click="removeAsAdmin"
        >
          Remove as Admin
        </el-button>
      </el-dialog>
      <el-table :data="groups" @cell-click="cellClickHandler">
        <el-table-column label="Group ID" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Subject" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.subject }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Icon" width="100">
          <template slot-scope="scope">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :before-upload="beforeUpload"
            >
              <div style="float:left;">
                <img
                  v-if="
                    scope.row.icon &&
                      scope.row.icon.icon_type &&
                      scope.row.icon.icon
                  "
                  width="50"
                  height="50"
                  :src="
                    `data:${scope.row.icon.icon_type};base64,${
                      scope.row.icon.icon
                    }`
                  "
                />
                <span v-else>&lt;No Icon&gt;</span>
              </div>
            </el-upload>
          </template>
        </el-table-column>

        <el-table-column label="Admins" width="180">
          <template slot-scope="scope">
            <el-dropdown :hide-on-click="false">
              <span class="el-dropdown-link">
                View More <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="id in scope.row.admins" :key="id">
                  {{ id }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column label="Created" width="180">
          <template slot-scope="scope">
            <span>{{ formatCreated(scope.row.creation_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Creator" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.creator }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Participants" width="180">
          <template slot-scope="scope">
            <el-dropdown
              v-if="
                scope.row.participants.filter(p => p !== scope.row.creator)
                  .length > 0
              "
              :hide-on-click="false"
            >
              <span class="el-dropdown-link">
                View More <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="id in scope.row.participants.filter(
                    p => p !== scope.row.creator
                  )"
                  :key="id"
                >
                  {{ id }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="Operations" width="350">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleGenerateInviteLink(scope.$index, scope.row)"
            >
              Generate Invite Link
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteInviteLink(scope.$index, scope.row)"
            >
              Delete Invite Link
            </el-button>
            <br />
            <el-button
              style="margin-top:10px"
              size="mini"
              type="danger"
              @click="handleLeave(scope.$index, scope.row)"
            >
              Leave Group
            </el-button>
            <el-button
              style="margin-top:10px"
              size="mini"
              type="danger"
              @click="handleManage(scope.$index, scope.row)"
            >
              Manage
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size="currentTablePageSize"
        :current-page.sync="currentTablePage"
        :total="groups.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <br />
    </el-main>
  </el-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import * as sanitizeHtml from "sanitize-html";
export default {
  name: "GroupsTab",
  components: {},
  props: {
    groups: Array,
    wa_endpoint: String
  },
  data() {
    return {
      currentTablePage: 1,
      currentTablePageSize: 10,
      subject: "",
      manageChatDialogVisible: false,
      groupToManage: {},
      availbleParticipantsToManage: [],
      participantsToManage: [],
      deleteLoading: false,
      promoteLoading: false,
      removeLoading: false,
      createLoading: false,
      lastClickedRow: {}
    };
  },
  computed: {},
  mounted() {},
  methods: {
    cellClickHandler(row, column, cell, event) {
      this.$data.lastClickedRow = row;
    },
    beforeUpload(file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file.size <= 5242880) {
          // Check if file size is below 5MB
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            var img = new Image();
            img.onload = () => {
              if (img.naturalWidth < 192 || img.naturalHeight < 192) {
                this.$message({
                  type: "error",
                  message:
                    "Failed to upload! Dimensions did not meet the minimum requirement of 192 x 192!"
                });
              } else {
                if (!this.$data.lastClickedRow.icon) {
                  this.$data.lastClickedRow.icon = {};
                  this.$data.lastClickedRow.icon.icon = null;
                  this.$data.lastClickedRow.icon_type = null;
                }
                const originalIcon = this.$data.lastClickedRow.icon.icon;
                const originalIconType = this.$data.lastClickedRow.icon
                  .icon_type;
                const splitBase64String = reader.result.split(",");
                this.$data.lastClickedRow.icon.icon = splitBase64String[1];
                this.$data.lastClickedRow.icon.icon_type = file.type;
                this.$confirm(
                  `<div style="text-align:center"><b>You are about replace Group ${sanitizeHtml(
                    this.$data.lastClickedRow.id,
                    { allowedTags: [], allowedAttributes: {} }
                  )}'s icon, are you sure?</b><div>
                  <img src='${sanitizeHtml(reader.result, {
                    allowedTags: [],
                    allowedAttributes: {}
                  })}' style="display:block;margin:auto;width:100%;max-width:300px">`,
                  "Warning",
                  {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                    center: true
                  }
                )
                  .then(() => {
                    this.$store
                      .dispatch("WHATSAPP_UPDATE_GROUP_ICON", {
                        data: {
                          icon: this.$data.lastClickedRow.icon.icon,
                          icon_type: this.$data.lastClickedRow.icon.icon_type,
                          group_id: this.$data.lastClickedRow.id,
                          wa_endpoint: this.wa_endpoint
                        }
                      })
                      .then(res => {
                        this.$message({
                          type: "success",
                          message: `Successfully updated group icon!`
                        });
                      })
                      .catch(err => {
                        this.$message({
                          type: "error",
                          message: `An error occurred while trying to update group icon!`
                        });
                        this.$data.lastClickedRow.icon.icon = originalIcon;
                        this.$data.lastClickedRow.icon.icon_type = originalIconType;
                      });
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "Group icon upload cancelled!"
                    });
                    this.$data.lastClickedRow.icon.icon = originalIcon;
                    this.$data.lastClickedRow.icon.icon_type = originalIconType;
                  });
              }
            };
            img.src = reader.result;
          };
        } else {
          this.$message({
            type: "error",
            message: "Failed to upload! File size exceeded 5MB!"
          });
        }
      } else {
        this.$message({
          type: "error",
          message: "Failed to upload! Only JPG or PNG files are accepted!"
        });
      }
    },
    createGroup() {
      this.createLoading = true;
      this.$store
        .dispatch("WHATSAPP_CREATE_GROUP", {
          data: {
            wa_endpoint: this.wa_endpoint,
            subject: this.$data.subject
          }
        })
        .then(res => {
          this.$message({
            type: "success",
            message: `Successfully created group!`
          });
          this.createLoading = false;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: `An error occurred while trying to create the group!`
          });
          this.createLoading = false;
        });
    },
    formatCreated(created) {
      return moment(created).format("MMM Do YYYY h:mm A");
    },
    handleGenerateInviteLink(index, row) {
      this.$store
        .dispatch("WHATSAPP_GENERATE_INVITE_LINK", {
          data: {
            wa_endpoint: this.wa_endpoint,
            group_id: row.id
          }
        })
        .then(res => {
          this.$message({
            type: "success",
            message: `Created link at: ${res.link}`
          });
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: `An error occurred while trying to create a link!`
          });
        });
    },
    handleDeleteInviteLink(index, row) {
      this.$store
        .dispatch("WHATSAPP_DELETE_INVITE_LINK", {
          data: {
            wa_endpoint: this.wa_endpoint,
            group_id: row.id
          }
        })
        .then(res => {
          this.$message({
            type: "success",
            message: `Successfully deleted an active link!`
          });
        })
        .catch(err => {
          this.$message({
            type: "warning",
            message: `There was no active link to delete!`
          });
        });
    },
    handleLeave(index, row) {
      this.$confirm(
        `You are about to leave group: ${row.id}, are you sure?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.$store
            .dispatch("WHATSAPP_LEAVE_GROUP", {
              data: {
                wa_endpoint: this.wa_endpoint,
                group_id: row.id
              }
            })
            .then(res => {
              this.$message({
                type: "success",
                message: `Successfully left group ${row.id}!`
              });
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: `An error occurred while trying to leave group: ${
                  row.id
                }`
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Leaave cancelled"
          });
        });
    },
    deleteParticipants() {
      this.$confirm(
        `You are about to delete ${
          this.$data.participantsToManage.length
        } participant(s), are you sure?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.deleteLoading = true;
          this.$store
            .dispatch("WHATSAPP_DELETE_GROUP_PARTICIPANTS", {
              data: {
                wa_endpoint: this.wa_endpoint,
                group_id: this.$data.groupToManage.id,
                participants: this.$data.participantsToManage.map(
                  obj => obj.participant
                )
              }
            })
            .then(res => {
              this.$message({
                type: "success",
                message: `Successfully deleted participants from group: ${
                  this.$data.groupToManage.id
                }`
              });
              this.deleteLoading = false;
              const idsDeleted = this.$data.participantsToManage.map(
                obj => obj.participant
              );
              this.$data.availbleParticipantsToManage = _.filter(
                this.$data.availbleParticipantsToManage,
                obj => !idsDeleted.includes(obj.participant)
              );
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: `An error occurred while trying to delete participants from group: ${
                  this.$data.groupToManage.id
                }`
              });
              this.deleteLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled"
          });
        });
    },
    promoteToAdmin() {
      this.promoteLoading = true;
      this.$store
        .dispatch("WHATSAPP_ADD_GROUP_ADMINS", {
          data: {
            wa_endpoint: this.wa_endpoint,
            group_id: this.$data.groupToManage.id,
            participants: this.$data.participantsToManage.map(
              obj => obj.participant
            )
          }
        })
        .then(res => {
          this.$message({
            type: "success",
            message: `Successfully promoted participant(s) to admin!`
          });
          const idsPromoted = this.$data.participantsToManage.map(
            obj => obj.participant
          );
          this.$data.availbleParticipantsToManage = _.map(
            this.$data.availbleParticipantsToManage,
            obj => {
              return {
                admin: idsPromoted.includes(obj.participant) ? true : false,
                participant: obj.participant
              };
            }
          );
          this.promoteLoading = false;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: `An error occurred while promoting participant(s) to admin!`
          });
          this.promoteLoading = false;
        });
    },
    removeAsAdmin() {
      this.removeLoading = true;
      this.$store
        .dispatch("WHATSAPP_REMOVE_GROUP_ADMINS", {
          data: {
            wa_endpoint: this.wa_endpoint,
            group_id: this.$data.groupToManage.id,
            participants: this.$data.participantsToManage.map(
              obj => obj.participant
            )
          }
        })
        .then(res => {
          this.$message({
            type: "success",
            message: `Successfully removed as admin!`
          });
          const idsPromoted = this.$data.participantsToManage.map(
            obj => obj.participant
          );
          this.$data.availbleParticipantsToManage = _.map(
            this.$data.availbleParticipantsToManage,
            obj => {
              return {
                admin: idsPromoted.includes(obj.participant) ? false : true,
                participant: obj.participant
              };
            }
          );
          this.removeLoading = false;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: `An error occurred while removing as admin!`
          });
          this.removeLoading = false;
        });
    },
    handleManage(index, row) {
      this.$data.groupToManage = row;
      this.$data.participantsToManage = [];
      this.$data.availbleParticipantsToManage = _.filter(
        row.participants,
        wa_id => wa_id !== row.creator
      ).map(wa_id => {
        return {
          admin: row.admins.includes(wa_id),
          participant: wa_id
        };
      });
      this.$data.manageChatDialogVisible = true;
    },
    handleDeleteSelectAll(selection) {
      this.$data.participantsToManage = selection;
    },
    handleDeleteSelect(selection, row) {
      this.$data.participantsToManage = selection;
    },
    handleSizeChange(val) {
      this.$data.currentTablePageSize = val;
    },
    handleCurrentChange(newPg) {},

    handleTableDelete(index, row) {}
  }
};
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>

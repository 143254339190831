<template>
  <div class="message-wrapper">
    <div
      style="font-size: 0.875em; display: inline-block"
      slot="reference"
      :class="{
        'message-bubble-left': isUserMessage,
        'message-bubble-right': !isUserMessage,
      }"
    >
      <div
        v-if="message.data.content[0].images"
        v-viewer="$options.viewerOptions"
        style="cursor: pointer"
      >
        <img v-for="(item, index) in fileUrls" :key="index" :src="item" />
      </div>
      <div
        v-else-if="message.data.content[0].files"
        v-viewer="$options.viewerOptions"
      >
        <div v-for="(item, index) in fileUrls" :key="index">
          <a :href="item" target="_blank" style="text-decoration: none">
            <i class="el-icon-document" style="font-size: 40px" />
            {{ fileNameFromURL(item) }}
          </a>
        </div>
      </div>
      <div
        v-else-if="message.data.content[0].videos"
        v-viewer="$options.viewerOptions"
        style="cursor: pointer"
      >
        <div v-for="(item, index) in fileUrls" :key="index">
          <a :href="item" target="_blank" style="text-decoration: none">
            <i class="el-icon-video-camera" style="font-size: 40px" />
            {{ fileNameFromURL(item) }}
          </a>
        </div>
      </div>

      <div style="text-align: right">
        <i style="font-size: 9px">{{
          messageTimestamp(message.Timestamp || message.date_created)
        }}</i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import "viewerjs/dist/viewer.css";
import Vue from "vue";

export default {
  name: "File",
  props: ["isUserMessage", "message", "buttonOutline"],
  viewerOptions: {
    inline: false,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: false,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: false,
    fullscreen: false,
    keyboard: true,
  },
  data() {
    return {
      left: 0,
      active: false,
      fileUrls: [],
    };
  },
  mounted() {
    // fetch file url from webchat_request_file_agent endpoint
    // Reason: Allow viewing file for agents and sender only
    this.fetchFileUrl(this.message);
  },
  methods: {
    messageTimestamp(timestamp) {
      return moment(timestamp).local().calendar(moment(), {
        sameDay: "[Today at] h:mm A",
        nextDay: "[Tomorrow at] h:mm A",
        nextWeek: "[Next] dddd [at] h:mm A",
        lastDay: "[Yesterday at] h:mm A",
        lastWeek: "[Last] dddd [at] h:mm A",
        sameElse: "DD/MM/YYYY [at] h:mm A",
      });
    },
    fileNameFromURL(item) {
      if (item) {
        return item.slice(item.lastIndexOf("/") + 1, item.lastIndexOf("?"));
      } else {
        return "";
      }
    },
    async fetchFileUrl(message) {
      const { files, videos, images } = _.get(message, "data.content[0]", {});
      const urls = files || videos || images;
      try {
        const transformedUrls = _.map(urls, (item) => {
          const { url = item, mimeType = "", caption = "" } = item;
          return url;
        });

        this.fileUrls = await this.$store.dispatch(
          "FETCH_MULTIPLE_FILE",
          transformedUrls
        );
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Error fetching image/file",
          message: "Please inform adminstrator of this error.",
        });
      }
    },
  },
};
</script>
<style>
.keyreply-bubble-content {
  word-wrap: break-word;
  text-align: left;
  overflow-y: auto;
  max-height: 400px;
}

.keyreply-bubble {
  position: relative;
  max-width: 300px;
  min-width: 150px;
  padding: 0 8px;
  margin: 8px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
  font-size: 14px;
  padding: 8px;
  display: block;
  overflow: visible;
  word-wrap: break-word;
}

.keyreply-bubble.left {
  float: left;
  /* overflow-y: scroll; */
  /* max-height: 400px; */
}

.keyreply-bubble.right {
  float: right;
  /* overflow-y: scroll; */
  /* max-height: 400px; */
}

.keyreply-bubble:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  bottom: auto;
  border: 11px solid;
}

.keyreply-bubble.right:before {
  right: -8px;
  left: auto;
  border-color: inherit;
}

.keyreply-bubble.left:before {
  left: -8px;
  right: auto;
  border-color: inherit;
}

/* Facebook Messenger */

.keyreply-bubble.left.facebook {
  background: #0084ff;
}

.keyreply-bubble.right.facebook {
  background: #f1f0f0;
  color: black;
}
</style>

<template>
  <div style="margin-top: 10px; display:flex; flex-direction: column;">
    <el-button
      plain
      v-for="(button, index) in content"
      v-text="getText(button)"
      :key="index"
      :type="buttonOutline"
      :disabled="!isPreview"
      style="margin: 0 5px 5px 0;"
      rel="noopener"
      size="mini"
      @click="onClick(button)"
    />
  </div>
</template>

<script>
export default {
  props: ["content", "buttonOutline", "isPreview"],
  methods: {
    getText(button) {
      const buttonText = _.get(button, "text", button.title); // Default is 'text', else facebook is 'title'
      return buttonText;
    },
    onClick(button) {
      const isUpload =
        button.text.toLowerCase().indexOf("upload") > -1 &&
        button.text.toLowerCase() !== "upload documents";
      if (isUpload) {
        this.$root.$emit("upload", button);
      } else {
        this.$emit("sendPostback", button);
      }
    }
  }
};
</script>

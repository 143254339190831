<template>
  <el-card>
    <!-- DIALOG -->
    <el-dialog
      title="Entity Form"
      :visible.sync="showForm"
      :before-close="handleDialogClose"
    >
      <el-form
        ref="entityForm"
        :model="entityForm"
        :rules="formRules"
        :disabled="entityForm.type === 'function'"
      >
        <el-form-item label="Entity name" prop="name">
          <el-input size="mini" v-model="entityForm.name" />
        </el-form-item>
        <el-form-item label="Replace text*" prop="replaceText">
          <el-switch
            v-model="entityForm.replaceText"
            :disabled="entityForm.type === 'list'"
            size="mini"
          />
          <span
            v-if="entityForm.type !== 'function'"
            style="font-style: italic"
          >
            <br />
            * All detected entities will
            {{ entityForm.replaceText ? "" : "not" }} be replaced with
            <strong>{{ entityForm.name || `entity name` }}</strong
            >.
          </span>
        </el-form-item>
        <el-form-item label="Type" prop="type">
          <el-select
            size="mini"
            @change="onTypeChange"
            v-model="entityForm.type"
          >
            <el-option value="regex">Pattern/Regex</el-option>
            <el-option value="list">List/Synonyms</el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="entityForm.type === 'regex'"
          label="Pattern/Regex"
          prop="pattern"
        >
          <el-input
            size="mini"
            v-model="entityForm.pattern"
            placeholder="/test/i"
          />
        </el-form-item>

        <el-form-item
          v-if="entityForm.type === 'list'"
          label="List of synonyms"
          prop="list"
        >
          <table class="el-table keyreply-hover-table">
            <tbody>
              <tr
                v-for="(synonyms, index) in entityForm.list"
                :key="synonyms.name"
              >
                <td style="font-weight: bold; font-size: 1.2em; width: 125px">
                  {{ synonyms.name }}
                </td>
                <td>
                  <el-tag
                    v-for="tag in synonyms.tags"
                    :key="tag"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(synonyms, tag)"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    v-if="synonyms.inputVisible"
                    v-model="synonyms.inputValue"
                    class="input-new-tag"
                    size="mini"
                    @keyup.enter.native="handleInputConfirm(synonyms)"
                    @blur="handleInputConfirm(synonyms)"
                  />
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="medium"
                    @click="showInput(synonyms)"
                    >+ New Synonym</el-button
                  >
                </td>
                <td
                  class="keyreply-hover-delete-col"
                  @click="deleteFromList(index)"
                >
                  <el-button type="primary" size="mini">
                    <i class="el-icon-delete" />
                  </el-button>
                </td>
              </tr>
            </tbody>
          </table>

          <el-input
            v-model="entityForm.newListName"
            placeholder="New List"
            type="textarea"
            size="mini"
            @keyup.enter.native="newList"
          ></el-input>
          <el-button @click="newList">Add</el-button>
        </el-form-item>
        <el-form-item
          v-if="entityForm.type === 'function'"
          label="Description"
          prop="function"
        >
          <div style="font-weight: bold">
            <br />
            {{ entityForm.description }}
          </div>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="submitForm"
            >Submit</el-button
          >
          <el-button size="mini" @click="showForm = false">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- /DIALOG -->
    <div slot="header">
      <h2 class="keyreply-page-title">Entities</h2>
      <p class="keyreply-page-subtitle">
        Teach your bot to detect special keywords!
      </p>
    </div>
    <div>
      <el-row>
        <div class="keyreply-float-top-right">
          <el-input
            v-model="search"
            placeholder="Search"
            size="mini"
            prefix-icon="el-icon-search"
          />
        </div>
        <div class="keyreply-float-top-right">
          <el-button size="mini" type="primary" @click="select()"
            >Add Entity</el-button
          >
          <Export :entities="entities" style="margin-left: 10px" />
        </div>
      </el-row>
      <el-row>
        <el-card>
          <table
            size="mini"
            class="el-table keyreply-hover-table keyreply-hover-color"
          >
            <thead>
              <th>Name</th>
              <th>Type</th>
              <th>
                Priority
                <el-tooltip
                  placement="top"
                  content="Sequence the entities so that higher priority ones are executed before the lower priority ones. Can be less than 0."
                >
                  <i class="el-icon-info" />
                </el-tooltip>
              </th>
              <th>
                Replace text
                <el-tooltip
                  placement="top"
                  content="Replace text so that FAQ engines such as Alexandria can recognise it easily. Does not affect Triggers."
                >
                  <i class="el-icon-info" />
                </el-tooltip>
              </th>
              <th>enabled</th>
            </thead>
            <tbody>
              <tr
                v-for="entity in entities"
                :key="entity.name"
                @click="select(entity)"
              >
                <td>{{ entity.name }}</td>
                <td>{{ entity.type }}</td>
                <td>
                  <el-input-number
                    v-model="entity.priority"
                    controls-position="right"
                    size="mini"
                    @click.native="stopPropagation($event)"
                    @change="saveChanges(entity)"
                  />
                </td>
                <td>
                  <el-switch
                    size="mini"
                    v-model="entity.replaceText"
                    :disabled="entity.type === 'list'"
                    @click.native="replaceTextChange($event, entity)"
                  />
                </td>
                <td>
                  <el-switch
                    v-model="entity.enabled"
                    size="mini"
                    @click.native="entityStatusChange($event, entity)"
                  />
                </td>
                <td
                  class="keyreply-hover-delete-col"
                  @click="deleteEntity($event, entity)"
                >
                  <el-button size="mini" type="text">
                    <i class="el-icon-delete" />
                  </el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-card>
      </el-row>
    </div>

    <!-- <el-alert type="warning" title="This section is currently under development. Changes will not be saved."></el-alert> -->
    <br />
    <br />

    <div />
  </el-card>
</template>
<script>
import _ from "lodash";
import errorHandlerMixin from "@/mixins/errorHandler";
import Export from "./Export";

export default {
  mixins: [errorHandlerMixin],
  components: { Export },
  data() {
    const checkListSize = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error("Add at least one list"));
      } else {
        callback();
      }
    };
    const checkSynonyms = (rule, value, callback) => {
      if (_.some(value, (synonym) => synonym.tags.length === 0)) {
        callback(new Error("Lists cannot have no synonyms"));
      } else {
        callback();
      }
    };
    return {
      search: "",
      showForm: false,
      entityForm: {
        list: [],
      },
      formRules: {
        name: [
          {
            required: true,
            message: "Entity name is required",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Entity type is required",
            trigger: "change",
          },
        ],
        pattern: [
          {
            required: true,
            message: "Pattern is required",
            trigger: "change",
          },
        ],
        list: [
          {
            validator: checkListSize,
            trigger: "blur",
          },
          {
            validator: checkSynonyms,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    entities() {
      return _.chain(this.$store.state.nodes.entity)
        .filter((entity) => {
          const entityLowerCased = _.chain(entity)
            .cloneDeep(entity)
            .assign(entityLowerCased, {
              name: entity.name.toLowerCase().trim(),
            });
          return JSON.stringify(entityLowerCased)
            .toLowerCase()
            .includes(this.search.toLowerCase().trim());
        })
        .sortBy((entity) => -entity.priority)
        .sortBy((entity) => !entity.enabled)
        .value();
    },
  },
  methods: {
    onTypeChange() {
      if (this.entityForm.type === "list") {
        this.entityForm.replaceText = false;
      }
    },
    handleDialogClose(done) {
      if (this.entityForm.type && this.entityForm.type !== "function") {
        this.$confirm("Are you sure to close this dialog?")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      } else {
        done();
      }
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    entityStatusChange(event, entity) {
      event.stopPropagation();

      // save entity;
      this.saveChanges(entity);
    },
    replaceTextChange(event, entity) {
      event.stopPropagation();

      // save entity;
      this.saveChanges(entity);
    },
    select(entity) {
      if (entity) {
        this.entityForm = _.cloneDeep(entity);
        // convert list for UI purposes
        this.entityForm.list = _.map(this.entityForm.list, (value, key) => {
          const tags = _.map(value, (tag) => {
            if (/\\/g.test(tag)) {
              tag = tag.replace(/\\/g, "");
            }
            return tag;
          });
          return {
            name: key,
            tags,
            inputVisible: false,
            inputValue: "",
          };
        });
      } else {
        this.entityForm = {
          name: "",
          enabled: true,
          priority: 0,
          replaceText: true,
          type: "",
          pattern: "",
          list: [],
        };
      }
      this.showForm = true;
    },
    deleteFromList(index) {
      this.$confirm("Delete from the list?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.entityForm.list.splice(index, 1);
      });
    },
    deleteEntity(e, entity) {
      e.stopPropagation();
      this.$confirm(
        "This will permanently delete the entity. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        return this.$store
          .dispatch("DELETE_NODE", { type: "entity", id: entity.name })
          .then(() => {
            this.$message({
              type: "success",
              message: "Entity deleted",
            });
          })
          .catch((e) => {
            console.log(e);
            this.$message({
              type: "error",
              message: "Failed to delete entity from server",
            });
          });
      });
    },
    newList() {
      const newName = this.entityForm.newListName;
      if (!newName) {
        this.$message({
          type: "error",
          message: "Please enter a name",
        });
        return;
      }
      const newListInput = _.chain(newName)
        .split("\n")
        .reject((line) => /^\s*$/.test(line)) // Any non-whitespace character
        .map((line) => {
          const synonyms = _.chain(line)
            .split("\t")
            .map((synonym) => synonym.trim())
            .filter((synonym) => synonym.length > 0)
            .value();

          return {
            name: _.first(synonyms),
            tags: synonyms,
            inputVisible: false,
            inputValue: "",
          };
        })
        .value();

      this.entityForm.list.push(...newListInput);
      // ---------------------------- end of section
      this.entityForm.newListName = "";
    },
    handleClose(synonyms, tag) {
      synonyms.tags.splice(synonyms.tags.indexOf(tag), 1);
      this.$forceUpdate();
    },

    showInput(synonyms) {
      synonyms.inputVisible = true;
      this.$forceUpdate();
    },

    handleInputConfirm(synonyms) {
      let inputValue = synonyms.inputValue;
      if (inputValue) {
        synonyms.tags.push(inputValue);
      }
      synonyms.inputVisible = false;
      synonyms.inputValue = "";
      this.$forceUpdate();
    },
    submitForm() {
      // console.log("submitForm clicked");
      // console.log(this.entityForm);
      // validate
      this.$refs["entityForm"].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          // convert back to save form
          const toSave = {
            name: this.entityForm.name,
            enabled: this.entityForm.enabled,
            priority: this.entityForm.priority,
            type: this.entityForm.type,
            replaceText: this.entityForm.replaceText,
          };
          if (this.entityForm.type === "list") {
            toSave.list = _.chain(this.entityForm.list)
              .keyBy((list) => list.name)
              .mapValues((values) => values.tags)
              .value();
          } else if (this.entityForm.type === "regex") {
            const pattern = this.entityForm.pattern;
            if (pattern instanceof RegExp) {
              toSave.pattern = pattern.toString();
            } else {
              toSave.pattern = pattern;
            }
          }
          // console.log("toSave", toSave);
          this.saveChanges(toSave);
        }
      });
    },
    saveChanges(toSave) {
      // dispatch save
      this.$store
        .dispatch("EDIT_NODE", {
          type: "entity",
          id: toSave.name,
          node: toSave,
        })
        .then(() => {
          this.showForm = false;
          this.$message({
            type: "success",
            message: "Saved entity",
          });
          const entityForm = this.$refs["entityForm"];
          if (entityForm) {
            entityForm.resetFields();
          }
        })
        .catch((e) => {
          const errMessage = this.graphQLError(e, "Error saving entity");
          this.$message({
            type: "error",
            message: errMessage,
          });
        });
    },
  },
};
</script>
<style scoped>
.keyreply-page-title {
  display: inline;
}
.keyreply-page-subtitle {
  display: inline;
  font-style: italic;
  margin-left: 5px;
}

.keyreply-float-top-right {
  float: right;
  margin: 0px 10px 10px 10px;
}

.keyreply-hover-table {
  border-collapse: collapse;
}
.keyreply-hover-table.keyreply-hover-color tbody tr:hover {
  background: rgb(252, 235, 234);
  cursor: pointer;
}

.keyreply-hover-delete-col {
  width: 100px;
  opacity: 0;
}
.keyreply-hover-table tr:hover .keyreply-hover-delete-col {
  opacity: 1;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>

<template>
  <el-card style="box-shadow: none;" shadow="never">
    <div slot="header">
      <el-row
        type="flex"
        style="margin-bottom: 0;align-items:center; justify-content: space-between;"
      >
        <span>Livechat</span>
        <el-switch v-model="value.inactivityManager.livechat.enabled"></el-switch>
      </el-row>
    </div>

    <div>
      <div>
        <small
          style="color: #BDBDBD; font-style: italic;"
        >Prompts user for a reply when user's inactive time exceeds inactivity threshold during a livechat.</small>
      </div>
      <br />
      <div ref="livechat-inactivity" v-if="value.inactivityManager.livechat.enabled">
        <el-input
          placeholder="Please input"
          style="margin-bottom: 15px;"
          v-model="value.inactivityManager.livechat.threshold"
        >
          <template slot="prepend">
            Threshold
            <el-tooltip
              effect="dark"
              content="The number of seconds before the bot prompts for a reply."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left:0.5em;" />
            </el-tooltip>
          </template>
        </el-input>

        <el-input
          style="margin-bottom: 15px;"
          v-model="value.inactivityManager.livechat.contentNode"
        >
          <template slot="prepend">
            Content Node
            <el-tooltip
              effect="dark"
              content="Content node show to user when inactivity threshold met."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left:0.5em;" />
            </el-tooltip>
          </template>
        </el-input>

        <el-checkbox
          v-model="value.inactivityManager.livechat.autoResolve"
          label="Auto Resolve After Prompt"
          border
          style="margin-bottom: 15px;"
        ></el-checkbox>
      </div>

      <el-divider>Prompt Agent Inactive</el-divider>

      <el-checkbox
        v-model="value.inactivityManager.livechatAgent.enabled"
        label="Enable"
        border
        style="margin-bottom: 15px;"
      ></el-checkbox>

      <div ref="livechat-inactivity-agent" v-if="value.inactivityManager.livechatAgent.enabled">
        <el-input
          placeholder="Please input"
          style="margin-bottom: 15px;"
          v-model="value.inactivityManager.livechatAgent.threshold"
        >
          <template slot="prepend">
            Threshold
            <el-tooltip
              effect="dark"
              content="The number of seconds before the bot prompts for a reply on agent inactivity."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left:0.5em;" />
            </el-tooltip>
          </template>
        </el-input>

        <el-input
          style="margin-bottom: 15px;"
          v-model="value.inactivityManager.livechatAgent.contentNode"
        >
          <template slot="prepend">
            Content Node
            <el-tooltip
              effect="dark"
              content="Content node show to user when inactivity threshold met on agent inactivity."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left:0.5em;" />
            </el-tooltip>
          </template>
        </el-input>
      </div>

      <!-- <el-checkbox
          v-model="value.inactivityManager.livechat.ignoreRatingContent"
          label="Ignore Rating Content"
          border
          style="margin-bottom: 15px;"
      ></el-checkbox>-->
    </div>
  </el-card>
</template>

<script>
export default {
  name: "InactivityManagerLivechat",
  props: ["value"]
};
</script>
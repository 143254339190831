<template>
  <div>
    <el-radio-group
      style="margin-bottom:4px;"
      size="mini"
      v-model="question.afterAction.api.methodType"
    >
      <el-radio-button label="GET">GET</el-radio-button>
      <el-radio-button label="POST">POST</el-radio-button>
    </el-radio-group>

    <el-input
      v-model="question.afterAction.api.url"
      placeholder="Enter URL here"
      size="mini"
    >
    </el-input>

    <ul style="padding-left:0;">
      <li style="list-style-type:none;">
        <el-button type="text" size="mini" @click="addRequestHeader"
          >Add Request Header</el-button
        >
      </li>
      <li
        v-for="(requestHeader, requestHeaderIndex) in question.afterAction.api
          .data.requestHeaders"
        :key="requestHeaderIndex"
        style="list-style-type:none;"
      >
        <el-input
          v-model="requestHeader.key"
          type="text"
          placeholder="Header key"
          size="mini"
          style="width:42%;"
        />
        <el-input
          v-model="requestHeader.value"
          type="text"
          placeholder="Header value"
          size="mini"
          style="width:42%;margin-left: 5px;"
        />
        <el-button
          class="el-icon-delete"
          size="mini"
          style="margin-left: 5px;"
          type="text"
          @click="
            question.action.api.data.requestHeaders.splice(
              requestHeaderIndex,
              1
            )
          "
        />
      </li>
    </ul>

    <ul style="padding-left: 0;">
      <li style="list-style-type:none;">
        <el-button type="text" size="mini" @click.prevent="addParameter"
          >Add Parameter</el-button
        >
      </li>
      <li
        v-for="(parameter, parameterIndex) in question.afterAction.api.data
          .parameters"
        :key="parameterIndex"
        style="list-style-type:none;"
      >
        <el-input
          v-model="parameter.key"
          type="text"
          placeholder="Parameter key"
          style="width:42%;"
          size="mini"
        />
        <el-input
          v-model="parameter.value"
          type="text"
          placeholder="Parameter value"
          style="width:42%;margin-left: 5px;"
          size="mini"
        />
        <el-button
          style="margin-left: 5px;"
          class="el-icon-delete"
          type="text"
          size="mini"
          @click.prevent="
            question.action.api.data.parameters.splice(parameterIndex, 1)
          "
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["question", "textSuccessOptions"],
  computed: {
    selected() {
      return this.textSuccessOptions.find(
        option => option.value === this.question.action.success
      );
    }
  },
  methods: {
    addRequestHeader() {
      this.question.afterAction.api.data.requestHeaders.push({
        key: "",
        value: ""
      });
    },
    addParameter() {
      this.question.afterAction.api.data.parameters.push({
        key: "",
        value: ""
      });
    }
  }
};
</script>

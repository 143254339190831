<template>
  <section style="position:relative;">
    <ul style="margin:0;padding:0;">
      <li style="list-style-type:none;">
        <el-button plain type="primary" size="mini" @click.prevent="onAddFallback">Add Fallback</el-button>
      </li>
      <li
        v-for="(fallbackSet, index) in activeFlow.fallbacks"
        :key="index"
        style="list-style-type:none;"
      >
        <el-input
          size="mini"
          v-model="fallbackSet.keywords"
          class="keywords input"
          type="text"
          placeholder="eg. keyword1, keyword2"
        />
        <el-select size="mini" v-model="fallbackSet.goto" class="goto input">
          <el-option
            v-for="(option, key) in contentNodes"
            :key="key"
            :label="option.label"
            :value="option.value"
          />
        </el-select>

        <el-button
          type="text"
          id="remove-button"
          class="el-icon-delete"
          style="margin-left: 5px;"
          size="mini"
          @click="removeFallbackKeywords(index)"
        />
      </li>
    </ul>
  </section>
</template>

<script>
import _ from "lodash";

export default {
  props: ["activeFlow"],
  computed: {
    contentNodes() {
      const existingContentNodes = this.$store.state.nodes.content;
      const validContentNodes = [];
      Object.keys(existingContentNodes).forEach(key => {
        if (key && existingContentNodes[key].content.text) {
          validContentNodes.push({ value: key, label: key });
        }
      });

      const sortedValidContentNodes = _.sortBy(validContentNodes, ["value"]);
      return sortedValidContentNodes;
    }
  },
  methods: {
    onAddFallback() {
      const tempFallbackSet = {
        keywords: "",
        goto: ""
      };
      this.activeFlow.fallbacks.push(tempFallbackSet);
    },
    removeFallbackKeywords(index) {
      this.$emit("removeFallbackKeywords", index);
    }
  }
};
</script>

<style>
.keywords.input {
  float: left;
  width: 250px;
}

.goto.input {
  float: left;
  width: 250px;
  padding-left: 5px;
}
</style>

<template>
  <div style="display: flex; align-items: center">
    <span style="margin-right: 5px"
      >Current Status: {{ currentChatStatusText }}</span
    >
    <el-dropdown v-if="dataCollectionFormEnabled" @command="selectStatus">
      <el-button size="mini" style="margin-right: 10px" class="button">
        {{ chatStatus.text }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(status, index) in chatStatusesArray"
          :key="index"
          :command="status"
          >{{ status.text }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <el-button
      v-if="dataCollectionFormEnabled"
      type="danger"
      size="mini"
      style="margin-right: 10px"
      :disabled="!selectedChatStatus"
      @click.native="openUpdateChatStatusDialog"
      >{{ pendingOrAttendingStatus ? "End Chat" : "Update Status" }}</el-button
    >

    <el-dialog
      id="updateChatStatusDialog"
      :visible="updateChatStatusDialogVisible"
      title="Update Chat Status"
      width="30%"
      :show-close="false"
    >
      <div width="20%">
        <el-row>
          <span style="padding: 10px">
            Update chat status to:
            <br />
            <b style="padding: 20px">
              {{ chatStatus ? chatStatus.text : "" }}
            </b>
          </span>
        </el-row>
        <br />
        <el-row>
          <span style="padding: 10px">Will end the chat for user as well.</span>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="isBusy"
          :loading="isBusy"
          type="primary"
          @click="updateChatStatus"
          >Confirm</el-button
        >
        <el-button
          :disabled="isBusy"
          @click="updateChatStatusDialogVisible = false"
          >Cancel</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      isBusy: false,
      updateChatStatusDialogVisible: false,
      selectedChatStatus: undefined,
      chatStatuses: {
        attending: { type: "attending", text: "Select Chat Status" },
        resolved: { type: "resolved", text: "Resolved", sendSurvey: true },
        surveynotsent: {
          type: "surveynotsent",
          text: "Resolved and do not send post-chat survey",
          sendSurvey: false,
        },
        escalated: { type: "escalated", text: "Escalated", sendSurvey: false },
      },
    };
  },
  computed: {
    ...mapGetters(["dataCollectionFormEnabled", "selectedChat"]),
    currentChatStatusText() {
      return _.get(
        this.chatStatuses,
        `${this.selectedChat.status}.text`,
        "attending"
      );
    },
    chatStatusesArray() {
      return Object.values(this.chatStatuses);
    },
    pendingOrAttendingStatus() {
      const selectChatStatus = this.selectedChat.status;
      return !_.includes(
        ["escalated", "surveynotsent", "resolved"],
        selectChatStatus
      );
    },
    chatStatus() {
      if (!this.selectedChatStatus) {
        const selectChatStatus = this.selectedChat.status;
        this.selectedChatStatus = this.chatStatuses[selectChatStatus];
      }
      return this.selectedChatStatus || this.chatStatuses.attending;
    },
  },
  methods: {
    selectStatus(statusObject) {
      this.selectedChatStatus = statusObject;
    },
    openUpdateChatStatusDialog() {
      if (
        this.selectedChatStatus &&
        this.selectedChatStatus.type === "attending"
      ) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: "Please select a status",
        });
      } else {
        this.updateChatStatusDialogVisible = true;
      }
    },
    updateChatStatus() {
      if (this.isBusy) {
        return;
      }
      const sendPostChatSurvey = this.chatStatus.sendSurvey;
      const existingStatus = _.clone(this.selectedChat.status);
      const status = _.get(this.selectedChatStatus, "type");
      if (!status) {
        this.updateChatStatusDialogVisible = false;
        this.$notify.error({
          title: "Error",
          message: "No status was selected. Please select a status.",
          position: "bottom-right",
        });
        return;
      }
      this.isBusy = true;
      this.$store
        .dispatch("UPDATE_LIVECHAT_STATUS", { chat: this.selectedChat, status })
        .then((response) => {
          const isUpdated = _.get(
            response,
            "data.livechatAPI.updateSessionStatus",
            false
          );
          this.$nextTick(() => {
            const shouldEndSession = _.includes(
              ["pending", "attending"],
              existingStatus
            );
            if (isUpdated && shouldEndSession) {
              this.$store
                .dispatch("END_SESSION_FOR_USER", {
                  chat: this.selectedChat,
                  sendPostChatSurvey,
                })
                .then(() => {
                  this.updateChatStatusDialogVisible = false;
                  this.$notify.success({
                    title: "Success",
                    message: "Successfully ended session on user side",
                    position: "bottom-right",
                  });
                })
                .catch((err) => {
                  this.updateChatStatusDialogVisible = false;
                  this.$notify.error({
                    title: "Error",
                    message: "Encountered error ending session for user side",
                    position: "bottom-right",
                  });
                })
                .finally(() => {
                  this.isBusy = false;
                });
            } else if (isUpdated) {
              this.$notify.success({
                title: "Success",
                message: "Status updated successfully.",
                position: "bottom-right",
              });
              this.isBusy = false;
              this.updateChatStatusDialogVisible = false;
            }
          });
        })
        .catch((err) => {
          this.updateChatStatusDialogVisible = false;
          this.$notify.error({
            title: "Error",
            message: "Encountered error updating livechat status",
            position: "bottom-right",
          });
          this.isBusy = false;
        });
    },
  },
};
</script>

<style scoped>
.button {
  text-align: center;
  background: #409eff !important;
  color: white !important;
  border-color: #409eff !important;
}

.button:hover {
  background: white !important;
  color: #409eff !important;
  border-color: #409eff !important;
}
</style>

<template>
  <el-main>
    <el-header>
      <h4>
        Upload a mini app to your chatbot
        <span style="float: right; right: 50px"
          >Current Storage: Azure Storage</span
        >
      </h4>
    </el-header>

    <el-row style="margin-bottom: 0px">
      <el-col :sm="12" :xs="24">
        <el-select
          filterable
          @change="changeFolder"
          v-model="name"
          placeholder="Choose a folder"
        >
          <el-option
            v-for="item in folders"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-tooltip
          content="Refresh and fetch latest stored files"
          placement="top"
        >
          <el-button
            :loading="fetchingFiles"
            @click="getAllFolders"
            style="margin-left: 10px"
            plain
            circle
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>
        <el-popover
          v-model="showNewFolderPopover"
          placement="bottom"
          title="Add new folder"
          width="500"
          trigger="click"
          content="this is content, this is content, this is content"
        >
          <el-tooltip
            slot="reference"
            content="Add a new folder"
            placement="top"
          >
            <el-button
              style="margin-left: 10px"
              plain
              circle
              icon="el-icon-plus"
            ></el-button>
          </el-tooltip>
          <el-input
            v-model="newFolderName"
            @keyup.enter.native="createFolder"
            @keyup.esc.native="showNewFolderPopover = false"
          >
            <template slot="prepend">Folder Name</template>
          </el-input>
          <strong>
            At least one file must be uploaded or the folder will not be
            created!
          </strong>
        </el-popover>

        <el-tooltip content="Delete a folder" placement="top">
          <el-button
            @click="deleteFolder"
            style="margin-left: 10px"
            plain
            circle
            icon="el-icon-close"
          ></el-button>
        </el-tooltip>
        <div class="fieldWarning">
          <span v-if="!name">
            <i class="el-icon-warning" />
            <label style="margin-left: 5px"
              >Please select a folder before proceeding!</label
            >
          </span>
        </div>
      </el-col>
    </el-row>

    <br />
    <div>
      <div>
        <el-radio v-model="uploadMode" :disabled="isDisabled" border label="csv"
          >Upload csv file</el-radio
        >
        <el-radio
          v-model="uploadMode"
          :disabled="isDisabled"
          border
          label="image"
          >Upload media files (png, jpg, gif)</el-radio
        >
        <el-radio
          v-model="uploadMode"
          :disabled="isDisabled"
          border
          label="html"
          >Upload a single HTML file</el-radio
        >
        <el-radio v-model="uploadMode" :disabled="isDisabled" border label="zip"
          >Upload a bundle (.zip)</el-radio
        >
        <el-radio v-model="uploadMode" :disabled="isDisabled" border label="doc"
          >Upload a document (pdf)</el-radio
        >
      </div>

      <br />

      <el-card v-if="uploadMode === 'html'">
        <codemirror ref="jsonEditor" v-model="code" :options="editorOptions" />
        <br />
        <el-button type="success" icon="el-icon-upload" @click="upload"
          >Save</el-button
        >
      </el-card>

      <el-card v-else-if="uploadMode === 'zip'">
        <div
          class="upload-demo"
          @dragenter="preventDefault"
          @dragstart="preventDefault"
          @dragend="preventDefault"
          @dragleave="preventDefault"
          @dragover="preventDefault"
          @drag="preventDefault"
          @drop="uploadOnChange"
          @click="$refs.zip.click()"
        >
          <div tabindex="0" class="el-upload el-upload--picture">
            <div action="#" class="el-upload-dragger">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" :disabled="isDisabled">
                Drop file here or
                <em>click to upload</em>
              </div>
            </div>
            <input
              ref="zip"
              type="file"
              name="file"
              multiple="multiple"
              class="el-upload__input"
              accept="application/zip, application/x-zip-compressed"
              @change="uploadOnChange"
            />
          </div>
          <ul class="el-upload-list el-upload-list--text">
            <li
              v-for="(file, index) in fileList"
              :key="file.name"
              tabindex="0"
              class="el-upload-list__item is-success"
            >
              <a class="el-upload-list__item-name">
                <i class="el-icon-document" />
                {{ file.name }}
              </a>
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-circle-check" />
              </label>
              <i class="el-icon-close" @click="uploadOnDelete(index)" />
              <i class="el-icon-close-tip">press delete to remove</i>
            </li>
          </ul>
        </div>
        <el-button type="success" icon="el-icon-upload" @click="upload"
          >Save</el-button
        >
      </el-card>

      <el-card v-else-if="uploadMode === 'image'">
        <div
          class="upload-demo"
          @dragenter="preventDefault"
          @dragstart="preventDefault"
          @dragend="preventDefault"
          @dragleave="preventDefault"
          @dragover="preventDefault"
          @drag="preventDefault"
          @drop="uploadOnChange"
          @click="$refs.image.click()"
        >
          <div tabindex="0" class="el-upload el-upload--picture">
            <div action="#" class="el-upload-dragger">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" :disabled="isDisabled">
                Drop file here or
                <em>click to upload</em>
              </div>
            </div>
            <input
              ref="image"
              :disabled="isDisabled"
              type="file"
              name="file"
              multiple="multiple"
              class="el-upload__input"
              accept="image/jpeg, image/png, image/gif"
              @change="uploadOnChange"
            />
          </div>
          <ul class="el-upload-list el-upload-list--picture">
            <li
              v-for="(file, index) in fileList"
              :key="file.name"
              tabindex="0"
              class="el-upload-list__item is-success"
            >
              <img
                :src="file.localURL"
                alt
                class="el-upload-list__item-thumbnail"
              />
              <a class="el-upload-list__item-name">
                <i class="el-icon-document" />
                {{ file.name }}
              </a>
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check" />
              </label>
              <i class="el-icon-close" @click="uploadOnDelete(index)" />
              <i class="el-icon-close-tip">press delete to remove</i>
            </li>
          </ul>
        </div>
        <el-button
          :disabled="isDisabled"
          type="success"
          icon="el-icon-upload"
          @click="upload"
          >Save</el-button
        >
      </el-card>

      <el-card v-else-if="uploadMode === 'doc'">
        <div
          class="upload-demo"
          @dragenter="preventDefault"
          @dragstart="preventDefault"
          @dragend="preventDefault"
          @dragleave="preventDefault"
          @dragover="preventDefault"
          @drag="preventDefault"
          @drop="uploadOnChange"
          @click="$refs.doc.click()"
        >
          <div tabindex="0" class="el-upload el-upload--picture">
            <div action="#" class="el-upload-dragger">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" :disabled="isDisabled">
                Drop file here or
                <em>click to upload</em>
              </div>
            </div>
            <input
              ref="doc"
              type="file"
              name="file"
              multiple="multiple"
              class="el-upload__input"
              accept="application/pdf"
              @change="uploadOnChange"
            />
          </div>
          <ul class="el-upload-list el-upload-list--text">
            <li
              v-for="(file, index) in fileList"
              :key="file.name"
              tabindex="0"
              class="el-upload-list__item is-success"
            >
              <a class="el-upload-list__item-name">
                <i class="el-icon-document" />
                {{ file.name }}
              </a>
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check" />
              </label>
              <i class="el-icon-close" @click="uploadOnDelete(index)" />
              <i class="el-icon-close-tip">press delete to remove</i>
            </li>
          </ul>
        </div>
        <el-button type="success" icon="el-icon-upload" @click="upload"
          >Save</el-button
        >
      </el-card>

      <el-card v-else-if="uploadMode === 'csv'">
        <el-switch
          v-model="csvValidated"
          active-text="With Validation"
          inactive-text="Non-Validation"
          style="margin-bottom: 10px"
          disabled
        ></el-switch>
        <template v-if="csvValidated">
          <p>Column references:</p>
          <div style="display: flex; margin-bottom: 10px">
            <div
              style="
                padding: 10px 15px;
                border-style: solid;
                border-right-style: none;
                border-left-style: none;
                border-color: #d3d3d3;
                border-width: 1px;
              "
              v-for="(column, index) in validatedColumns"
              :key="index"
            >
              {{ column }}
            </div>
          </div>
        </template>
        <div
          class="upload-demo"
          @dragenter="preventDefault"
          @dragstart="preventDefault"
          @dragend="preventDefault"
          @dragleave="preventDefault"
          @dragover="preventDefault"
          @drag="preventDefault"
          @drop="uploadOnChangeCsv"
          @click="$refs.csv.click()"
        >
          <div tabindex="0" class="el-upload el-upload--picture">
            <div action="#" class="el-upload-dragger">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" :disabled="isDisabled">
                Drop file here or
                <em>click to upload</em>
              </div>
            </div>
            <input
              ref="csv"
              type="file"
              name="file"
              :multiple="!csvValidated"
              class="el-upload__input"
              accept=".csv"
              @change="uploadOnChangeCsv"
            />
          </div>
          <ul class="el-upload-list el-upload-list--text">
            <li
              v-for="(file, index) in fileList"
              :key="file.name"
              tabindex="0"
              class="el-upload-list__item is-success"
            >
              <a class="el-upload-list__item-name">
                <i class="el-icon-document" />
                {{ file.name }}
              </a>
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check" />
              </label>
              <i class="el-icon-close" @click="uploadOnDelete(index)" />
              <i class="el-icon-close-tip">press delete to remove</i>
            </li>
          </ul>
        </div>
        <el-button
          type="success"
          :disabled="fileList.length === 0"
          icon="el-icon-upload"
          @click="upload"
          >Save</el-button
        >
      </el-card>

      <el-card>
        <div>
          <el-table ref="fileTableData" :data="files" style="width: 100%">
            <el-table-column
              prop="thumbnail"
              label="Thumbnail"
              :width="columnWidth"
            >
              <template slot-scope="scope">
                <img
                  v-if="scope.row.mimetype.includes('image')"
                  :src="scope.row.url"
                  style="max-height: 50px; max-width 50px;"
                />
                <i v-else class="el-icon-document" />
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="File Name"
              :width="columnWidth"
              sortable
            />
            <el-table-column
              prop="mimetype"
              label="Type"
              :width="columnWidth"
              sortable
            />
            <el-table-column
              prop="size"
              label="Size"
              :width="columnWidth"
              sortable
            />
            <el-table-column
              prop="url"
              label="URL"
              :width="columnWidth"
              sortable
            >
              <template slot-scope="scope">
                <a
                  target="_blank"
                  :href="scope.row.url"
                  :style="{
                    color: 'blue',
                    'text-decoration': 'underline',
                  }"
                  >{{ scope.row.url }}</a
                >
              </template>
            </el-table-column>
            <el-table-column label="Operations" :width="operationsWidth">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="copyURL(scope.$index, files)"
                  >Copy URL</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDownload(scope.$index, files)"
                  >Download</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, files)"
                  >Delete</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-pagination
        ref="fileTable"
        layout="prev, pager, next"
        :page-size="10"
        :current-page="currentPage"
        :total="noOfItems"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
      />
    </div>
  </el-main>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import qs from "query-string";
import * as Papa from "papaparse";
import tableValidations from "@/components/Miniapps/customCsvValidation.json";
export default {
  data() {
    return {
      isMounted: false,
      name: "",
      uploadMode: "image",
      code: "",
      editorOptions: {
        mode: "text/html",
        theme: "monokai",
      },
      currentPage: 1, // Used to track the current page in pagination
      fileList: [], // Used for uploads
      files: [], // Used for display in pagination
      htmlFiles: [], // Used to store all html files after retrieving from getAllFolders()
      zipFiles: [], // Used to store all zip files after retrieving from getAllFolders()
      imageFiles: [], // Used to store all image files after retrieving from getAllFolders()
      docFiles: [], // Used to store all image files after retrieving from getAllFolders()
      csvFiles: [],
      noOfItems: 0, // Initialize no of items to be used in pagination
      folders: [],
      fetchedFiles: [],
      fetchingFiles: false,
      newFolderName: "",
      showNewFolderPopover: false,
      tableValidations,
    };
  },
  computed: {
    ...mapGetters(["storageType"]),
    csvValidated() {
      const validFolders = _.get(
        this,
        `tableValidations[${this.$store.state.account}]`,
        {}
      );
      const validFoldersKeys = Object.keys(validFolders);
      return _.includes(validFoldersKeys, this.name);
    },
    validatedColumns() {
      const validationRef = _.get(
        this,
        `tableValidations[${this.$store.state.account}][${this.name}]`
      );

      return validationRef;
    },
    columnWidth() {
      if (this.isMounted) {
        return `${
          (this.$refs.fileTableData.$el.getBoundingClientRect().width * 0.7) / 5
        }`;
      }
      return "";
    },
    operationsWidth() {
      if (this.isMounted) {
        return `${
          this.$refs.fileTableData.$el.getBoundingClientRect().width * 0.3
        }`;
      }
      return "";
    },
    isDisabled: {
      get() {
        return this.name === "";
      },
      set(val) {
        this.isDisabled = val;
      },
    },
    url() {
      var slug = name
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");

      return `https://${this.$store.state.server}/static/${slug}/`;
    },
  },
  watch: {
    uploadMode: function (newMode, oldMode) {
      if (newMode !== oldMode) {
        this.fileList = [];
      }
      if (this.uploadMode === "html") {
        this.noOfItems = this.htmlFiles.length;
        this.files = this.htmlFiles.slice(
          0,
          this.htmlFiles.length >= 10 ? 10 : this.htmlFiles.length
        );
      } else if (this.uploadMode === "zip") {
        this.noOfItems = this.zipFiles.length;
        this.files = this.zipFiles.slice(
          0,
          this.zipFiles.length >= 10 ? 10 : this.zipFiles.length
        );
      } else if (this.uploadMode === "image") {
        this.noOfItems = this.imageFiles.length;
        this.files = this.imageFiles.slice(
          0,
          this.imageFiles.length >= 10 ? 10 : this.imageFiles.length
        );
      } else if (this.uploadMode === "doc") {
        this.noOfItems = this.docFiles.length;
        this.files = this.docFiles.slice(
          0,
          this.docFiles.length >= 10 ? 10 : this.docFiles.length
        );
      } else if (this.uploadMode === "csv") {
        this.noOfItems = this.csvFiles.length;
        this.files = this.csvFiles.slice(
          0,
          this.csvFiles.length >= 10 ? 10 : this.csvFiles.length
        );
      }
    },
    name: function (newFolderName, oldFolderName) {
      const folderSelectionChanged = newFolderName !== oldFolderName;
      const uploadingCurrentModeIsCsv = this.uploadMode === "csv";

      if (folderSelectionChanged && uploadingCurrentModeIsCsv) {
        this.fileList = [];
      }
    },
  },
  mounted() {
    // this.download();
    this.getAllFolders(false);
    this.isMounted = true;
  },
  methods: {
    async deleteCsvFiles() {
      const filenames = this.csvFiles
        .filter((file) => file.folder === this.name)
        .map((file) => file.filepath);
      if (filenames.length === 0) {
        return;
      }
      const filenamesText = filenames.join("|");
      let form = new FormData();
      form.append("filenames", filenamesText);
      await this.$rest("post", "deleteFiles", form).then((val) => {
        this.$notify({
          title: "Success",
          message: "Successfully deleted current CSV files",
          type: "success",
        });
      });
    },
    deleteFolder() {
      this.$confirm(
        `You are about to delete ${this.name}. Continue?`,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          let form = new FormData();
          form.append(
            "filename",
            `miniapp/${this.$store.state.brain}/${this.name}`
          );
          this.$rest("post", "deleteFolder", form).then((val) => {
            const noSuccessful = _.filter(val, (obj) => obj.success).length;
            const noFailed = _.filter(val, (obj) => !obj.success).length;
            if (noFailed === 0) {
              setTimeout(() => {
                this.getAllFolders(false);
                this.$notify({
                  message: `Successfully deleted ${noSuccessful} file(s) from the folder ${this.name}!`,
                  type: "success",
                });
                this.name = "";
              }, 1000);
            } else {
              this.$notify({
                message: `Successfully deleted ${noSuccessful} file(s) with ${noFailed} failures from the folder ${this.name}!`,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$notify({
            type: "info",
            message: "Delete cancelled",
          });
        });
    },
    createFolder() {
      if (
        !_.find(
          this.folders,
          (folderObj) => folderObj.value === this.newFolderName
        )
      ) {
        this.folders.push({
          label: this.newFolderName,
          value: this.newFolderName,
        });
        this.$notify.success({
          title: "Success",
          message: `Successfully added new folder!`,
          type: "success",
          postion: "bottom-right",
        });
        this.name = this.newFolderName;
      } else {
        this.$notify.warning({
          title: "Same Name Warning",
          message: `A folder with an identical name already exists!`,
          type: "warning",
          postion: "bottom-right",
        });
      }
      this.newFolderName = "";
      this.showNewFolderPopover = false;
    },
    changeFolder(val) {
      this.htmlFiles = this.fetchedFiles.filter((obj) => {
        return obj.mimetype === "text/html" && obj.folder === this.name;
      });
      this.zipFiles = this.fetchedFiles.filter((obj) => {
        return (
          (obj.mimetype === "application/zip" ||
            obj.mimetype === "application/x-zip-compressed" ||
            obj.mimetype === "multipart/x-zip" ||
            obj.mimetype === "application/x-rar-compressed") &&
          obj.folder === this.name
        );
      });
      this.imageFiles = this.fetchedFiles.filter((obj) => {
        return (
          (obj.mimetype === "image/png" ||
            obj.mimetype === "image/jpeg" ||
            obj.mimetype === "image/jpg" ||
            obj.mimetype === "image/gif") &&
          obj.folder === this.name
        );
      });
      this.docFiles = this.fetchedFiles.filter((obj) => {
        return obj.mimetype === "application/pdf" && obj.folder === this.name;
      });
      this.csvFiles = this.fetchedFiles.filter((obj) => {
        return obj.mimetype === "text/csv" && obj.folder === this.name;
      });

      switch (this.uploadMode) {
        case "html":
          this.noOfItems = this.htmlFiles.length;
          break;
        case "zip":
          this.noOfItems = this.zipFiles.length;
          break;
        case "image":
          this.noOfItems = this.imageFiles.length;
          break;
        case "doc":
          this.noOfItems = this.docFiles.length;
          break;
      }
      // if (isUpload) {
      //   self.currentPage = Math.ceil(self.noOfItems / 10.0);
      // }
      this.refreshTable();
    },
    uploadOnDelete(index) {
      this.fileList.splice(index, 1);
    },
    preventDefault(event) {
      console.log(event);
      event.preventDefault();
      event.stopPropagation();
    },
    uploadOnChange(event) {
      let files;
      if (event.dataTransfer) {
        files = event.dataTransfer.files; // For drag and drop
      } else {
        files = event.target.files; // For click to upload
      }
      let noFailed = 0;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        file.localURL = URL.createObjectURL(file);
        if (this.uploadMode === "html" && file.type === "text/html") {
          // Uploading HTML file
        } else if (
          this.uploadMode === "zip" &&
          (file.type === "application/zip" ||
            file.type === "application/x-zip-compressed" ||
            file.type === "multipart/x-zip" ||
            file.type === "application/x-rar-compressed")
        ) {
          this.fileList.push(file);
        } else if (
          this.uploadMode === "image" &&
          (file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/gif")
        ) {
          this.fileList.push(file);
        } else if (
          this.uploadMode === "doc" &&
          file.type === "application/pdf"
        ) {
          this.fileList.push(file);
        } else {
          noFailed++;
        }
      }
      if (noFailed === 0) {
        this.$notify({
          message: `${
            files.length - noFailed
          } file(s) have been successfully uploaded!`,
          type: "success",
        });
      } else {
        this.$notify({
          message: `${noFailed} file(s) failed to upload, please ensure you're uploading ${this.uploadMode} file(s) only!`,
          type: "error",
        });
      }
      event.target.value = "";
      this.preventDefault(event);
    },
    uploadOnChangeCsv(event) {
      const draggable = Boolean(event.dataTransfer);
      const files = draggable
        ? _.get(event, "dataTransfer.files", [])
        : _.get(event, "target.files", []);
      const fileCount = files.length;

      let isCsvFile = true;
      files.forEach((file) => {
        const filename = file.name;
        const fileNameSplit = filename.split(".");
        const lastIndexOfFileNameSplit = fileNameSplit.length - 1;
        const extension = fileNameSplit[lastIndexOfFileNameSplit];

        isCsvFile =
          file.type === "text/csv" ||
          file.type === "application/csv" ||
          file.type === ".csv" ||
          file.type === "application/vnd.ms-excel" || // in windows detected
          extension === "csv";
      });

      if (!isCsvFile) {
        return this.$notify({
          title: "Error",
          message: `Only csv file will be uploaded!`,
          type: "error",
        });
      }

      if (!this.csvValidated) {
        this.fileList.push(...files);
        return this.$notify({
          title: "Success",
          position: "buttom-right",
          message: `Non-validation csv uploaded!`,
          type: "success",
        });
      }

      if (fileCount > 1) {
        this.$notify({
          title: "Error",
          message: `You may upload 1 csv file only!`,
          type: "error",
        });
        return;
      }
      const file = files[0];

      if (!file) {
        this.$notify({
          title: "Error",
          message: `No file attached!`,
          type: "error",
        });
        return;
      }

      const reader = new FileReader();

      reader.readAsText(file);
      reader.onload = (e) => {
        Papa.parse(e.target.result, {
          header: true,
          complete: ({ data }) => {
            const dataIsEmpty = data.length === 0;

            /** ========================
             * Error messages:
             *  ========================
             */
            if (dataIsEmpty) {
              return this.$notify({
                title: "Error",
                message: `No data found in the file! Please update with the other one!`,
                type: "error",
              });
            }

            const firstData = data[0];
            let keysOfFirstData = Object.keys(firstData);
            keysOfFirstData = _.map(keysOfFirstData, (item) => {
              return _.trim(item);
            });
            const validationRef = _.get(
              this,
              `tableValidations[${this.$store.state.account}][${this.name}]`
            );

            if (!validationRef) {
              return this.$notify({
                title: "Error",
                message: `No validation reference found in the ${this.$store.state.brain} system!`,
                type: "error",
              });
            }

            let csvValidated = true;
            keysOfFirstData.forEach((item) => {
              const isValid = _.includes(validationRef, item);

              if (!isValid) {
                csvValidated = false;
              }
            });

            if (csvValidated) {
              validationRef.forEach((item) => {
                const isValid = _.includes(keysOfFirstData, item);

                if (!isValid) {
                  csvValidated = false;
                }
              });
            }

            if (!csvValidated) {
              this.$notify({
                title: "Error",
                message: `Your csv file is not valid for this table headers!`,
                type: "error",
              });
            }
            if (csvValidated && this.fileList.length > 0) {
              this.$notify({
                title: "Error",
                message: `You may upload 1 csv file only!`,
                type: "error",
              });
            }

            /** ========================
             * Success:
             *  ========================
             */
            if (csvValidated && this.fileList.length === 0) {
              this.fileList.push(file);
              this.$notify({
                title: "Success",
                position: "buttom-right",
                message: `Validated csv file has been successfully attached!`,
                type: "success",
              });
            }
          },
        });
      };
      this.preventDefault(event);
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.refreshTable();
    },
    refreshTable() {
      const firstIndexOfFile = (this.currentPage - 1) * 10;
      let lastIndexOfFile;

      if (this.uploadMode === "html") {
        lastIndexOfFile =
          this.htmlFiles.length >= this.currentPage * 10
            ? this.currentPage * 10
            : this.htmlFiles.length;

        this.files = this.htmlFiles.slice(firstIndexOfFile, lastIndexOfFile);
      } else if (this.uploadMode === "zip") {
        lastIndexOfFile =
          this.zipFiles.length >= this.currentPage * 10
            ? this.currentPage * 10
            : this.zipFiles.length;

        this.files = this.zipFiles.slice(firstIndexOfFile, lastIndexOfFile);
      } else if (this.uploadMode === "image") {
        lastIndexOfFile =
          this.imageFiles.length >= this.currentPage * 10
            ? this.currentPage * 10
            : this.imageFiles.length;

        this.files = this.imageFiles.slice(firstIndexOfFile, lastIndexOfFile);
      } else if (this.uploadMode === "doc") {
        lastIndexOfFile =
          this.docFiles.length >= this.currentPage * 10
            ? this.currentPage * 10
            : this.docFiles.length;

        this.files = this.docFiles.slice(firstIndexOfFile, lastIndexOfFile);
      } else if (this.uploadMode === "csv") {
        lastIndexOfFile =
          this.csvFiles.length >= this.currentPage * 10
            ? this.currentPage * 10
            : this.csvFiles.length;

        this.files = this.csvFiles.slice(firstIndexOfFile, lastIndexOfFile);
      }
    },
    handleDownload(index, rows) {
      let form = new FormData();
      form.append("filepath", rows[index].filepath);
      this.$rest("post", "downloadFile", form).then((val) => {
        var link = document.createElement("a");
        link.download = rows[index].name;
        link.href = `data:${rows[index].mimetype};base64,` + val;
        link.click();
        link.remove();
      });
    },
    copyURL(index, rows) {
      var copyText = rows[index].url;
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.setAttribute("value", copyText);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.$notify({
        message: "Successfully copied URL to the clipboard!",
        type: "success",
      });
    },
    handleDelete(index, rows) {
      this.$confirm(
        "This will permanently delete the file. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          let form = new FormData();
          form.append("filename", rows[index].filepath);
          this.$rest("post", "deleteFile", form).then((val) => {
            if (val.isSuccessful) {
              setTimeout(() => {
                this.getAllFolders(false);
                this.$notify({
                  message: "Successfully deleted file from storage!",
                  type: "success",
                });
              }, 1000);
            } else {
              this.$notify({
                message: "Error occurred while trying to delete!",
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$notify({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    download() {
      if (this.name) {
        this.$rest("post", "miniapp_download", {
          name: this.name,
        }).then((text) => {
          this.code = text;
        });
      }
    },
    async upload() {
      if (this.uploadMode === "html") {
        let form = new FormData();
        form.append("brain", this.$store.state.brain);
        form.append("name", this.name);
        form.append(
          "single",
          new File([this.code], "index.html", {
            type: "text/html",
            encoding: "utf8",
          })
        );
        this.$rest("post", "miniapp_upload", form)
          .then((val) => {
            this.$notify({
              message: "Successfully uploaded file to storage!",
              type: "success",
            });
            setTimeout(() => {
              this.getAllFolders(true);
            }, 1000);
          })
          .catch((err) => {
            this.$notify({
              message: "Error occurred while uploading to storage!",
              type: "error",
            });
          });
      } else if (this.uploadMode === "zip") {
        let form = new FormData();
        form.append("brain", this.$store.state.brain);
        form.append("name", this.name);
        this.fileList.forEach((file) => {
          form.append("zips", file);
        });
        this.$rest("post", "miniapp_zips_upload", form)
          .then((val) => {
            this.$notify({
              message: "Successfully uploaded file to storage!",
              type: "success",
            });
            setTimeout(() => {
              this.getAllFolders(true);
            }, 1000);
          })
          .catch((err) => {
            this.$notify({
              message: "Error occurred while uploading to storage!",
              type: "error",
            });
          });
        // const input = this.$refs.zip;
        // input.type = "text";
        // input.type = "file";
      } else if (this.uploadMode === "image") {
        let form = new FormData();
        form.append("brain", this.$store.state.brain);
        form.append("name", this.name);
        this.fileList.forEach((file) => {
          form.append("images", file);
        });
        this.$rest("post", "miniapp_images_upload", form)
          .then((val) => {
            this.$notify.success({
              title: "Upload Success",
              message: "Successfully uploaded file into storage",
              position: "bottom-right",
            });
            setTimeout(() => {
              this.getAllFolders(true);
            }, 1000);
          })
          .catch((err) => {
            this.$notify.error({
              title: "Upload Error",
              message: "Encountered error uploading file to storage",
              position: "bottom-right",
            });
          });
      } else if (this.uploadMode === "doc") {
        let form = new FormData();
        form.append("brain", this.$store.state.brain);
        form.append("name", this.name);
        this.fileList.forEach((file) => {
          form.append("documents", file);
        });
        this.$rest("post", "miniapp_documents_upload", form)
          .then((val) => {
            this.$notify({
              message: "Successfully uploaded file to storage!",
              type: "success",
            });
            setTimeout(() => {
              this.getAllFolders(true);
            }, 1000);
          })
          .catch((err) => {
            this.$notify({
              message: "Error occurred while uploading to storage!",
              type: "error",
            });
          });
      } else if (this.uploadMode === "csv") {
        let form = new FormData();
        form.append("brain", this.$store.state.brain);
        form.append("name", this.name);
        this.fileList.forEach((file) => {
          form.append("documents", file);
        });

        if (this.csvValidated) {
          // delete current CSV files since only one file is required each folder
          await this.deleteCsvFiles();

          form.append("csvValidated", this.csvValidated);
        }

        this.$rest("post", "miniapp_documents_upload", form)
          .then((val) => {
            this.$notify({
              message: "Successfully uploaded file to storage!",
              type: "success",
            });
            this.fileList = [];
            setTimeout(() => {
              this.getAllFolders(true);
            }, 1000);
          })
          .catch((err) => {
            this.$notify({
              message: "Error occurred while uploading to storage!",
              type: "error",
            });
          });
      }
    },
    getAllFolders(isUpload) {
      this.fetchingFiles = true;
      const self = this;
      const query = qs.stringify({ brain: this.$store.state.brain });
      this.$rest("get", `getAllFolders?${query}`)
        .then((val) => {
          this.fetchingFiles = false;
          this.$notify.success({
            title: "Fetch Success",
            message: `Successfully fetched latest files!`,
            type: "success",
            position: "bottom-right",
          });
          this.fetchedFiles = val;
          self.folders = _.chain(val)
            .uniqBy((obj) => obj.folder)
            .map((obj) => {
              return { label: obj.folder, value: obj.folder };
            })
            .value();
          self.htmlFiles = val.filter((obj) => {
            return obj.mimetype === "text/html" && obj.folder === this.name;
          });
          self.zipFiles = val.filter((obj) => {
            return (
              (obj.mimetype === "application/zip" ||
                obj.mimetype === "application/x-zip-compressed" ||
                obj.mimetype === "multipart/x-zip" ||
                obj.mimetype === "application/x-rar-compressed") &&
              obj.folder === this.name
            );
          });
          self.imageFiles = val.filter((obj) => {
            return (
              (obj.mimetype === "image/png" ||
                obj.mimetype === "image/jpeg" ||
                obj.mimetype === "image/jpg" ||
                obj.mimetype === "image/gif") &&
              obj.folder === this.name
            );
          });
          self.docFiles = val.filter((obj) => {
            return (
              obj.mimetype === "application/pdf" && obj.folder === this.name
            );
          });
          self.csvFiles = val.filter((obj) => {
            return obj.mimetype === "text/csv" && obj.folder === this.name;
          });

          switch (self.uploadMode) {
            case "html":
              self.noOfItems = self.htmlFiles.length;
              break;
            case "zip":
              self.noOfItems = self.zipFiles.length;
              break;
            case "image":
              self.noOfItems = self.imageFiles.length;
              break;
            case "doc":
              self.noOfItems = self.docFiles.length;
              break;
            case "csv":
              self.noOfItems = self.csvFiles.length;
              break;
          }
          // if (isUpload) {
          //   self.currentPage = Math.ceil(self.noOfItems / 10.0);
          // }
          self.refreshTable();
        })
        .catch((err) => {
          this.fetchingFiles = false;
          this.isDisabled = true;
          this.$notify({
            title: "Error",
            type: "error",
            message: _.get(
              err,
              "response.data.error.message",
              "Encountered an error setting up miniapps"
            ),
            duration: 0,
          });
        });
    },
  },
};
</script>
<style scoped>
.apps-container {
  height: 100%;
}
.fieldWarning {
  margin: 5px 0 0 0;
  font-size: 13px;
  color: #e4392b;
}
</style>

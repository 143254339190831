<template>
  <el-row type="flex" style="flex-flow: row wrap; align-items: start;">
    <el-input style="margin-bottom: 10px;" size="mini" v-model="field.prefix" :disabled="!canEdit">
      <template slot="prepend">Prefix</template>
    </el-input>

    <el-input style="margin-bottom: 10px;" size="mini" v-model="field.aggregator" :disabled="!canEdit">
      <template slot="prepend">Aggregator</template>
    </el-input>

    <span style="padding: 10px 15px 0 0;">
      <p style="line-height: 10px; margin: 0;">Ceiling</p>
      <el-input-number size="mini" v-model="field.ceiling" :disabled="!canEdit"></el-input-number>
    </span>

    <el-checkbox v-model="field.visible" label="Visible" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.editable" label="Editable" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.multiline" label="Multiline" :disabled="!canEdit"></el-checkbox>
  </el-row>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["field", "canEdit"],
});
</script>


<template>
  <el-menu style="height: 100%" collapse>
    <router-link to="/">
      <el-menu-item class="sidenav-logo" index="logo">
        <img :src="require('../assets/logo.png')" />
      </el-menu-item>
    </router-link>

    <router-link to="/">
      <el-menu-item index="home" class="sidenav-item">
        <i class="ion ion-ios-home" />
        <small>Home</small>
      </el-menu-item>
    </router-link>

    <router-link v-if="checkRoles(getPermittedRoles('editor'))" to="/editor">
      <el-menu-item class="sidenav-item" index="editor">
        <i class="el-icon-edit-outline" />
        <small>Editor</small>
      </el-menu-item>
    </router-link>

    <!-- Start FAQ -->
    <router-link
      v-if="
        showModule('faq', 'WatsonAssistant') &&
        checkRoles(getPermittedRoles('faqw'))
      "
      to="/faqw"
    >
      <el-menu-item class="sidenav-item" index="faqw">
        <i class="ion ion-ios-help-outline" />
        <small>FAQs</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        showModule('faq', 'Alexandria') && checkRoles(getPermittedRoles('faq'))
      "
      to="/faq"
    >
      <el-menu-item class="sidenav-item" index="faq">
        <i class="ion ion-ios-help-outline" />
        <small>FAQs</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        showModule('faq', ['Rasa', 'Elsa']) &&
        checkRoles(getPermittedRoles('faq'))
      "
      to="/faq2"
    >
      <el-menu-item class="sidenav-item" index="faq2">
        <i class="ion ion-ios-help-outline" />
        <small>FAQs</small>
      </el-menu-item>
    </router-link>
    <!-- End FAQ -->

    <router-link
      to="/transaction"
      v-if="
        showModule('transactions') &&
        checkRoles(getPermittedRoles('transactions'))
      "
    >
      <el-menu-item class="sidenav-item" index="batch_test">
        <i class="ion ion-ios-filing-outline" />
        <small>{{ transactionsLabel }}</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="checkRoles(getPermittedRoles('livechat'))"
      to="/livechat"
    >
      <el-menu-item class="sidenav-item" index="livechat">
        <el-badge :is-dot="queueBadgeNumber > 0">
          <i class="el-icon-chat-dot-round" />
        </el-badge>

        <small>Live Chat </small>
      </el-menu-item>
    </router-link>

    <router-link v-if="checkRoles(getPermittedRoles('faq'))" to="/entities">
      <el-menu-item class="sidenav-item" index="entities">
        <i class="el-icon-discount" />
        <small>Entities</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        checkRoles(getPermittedRoles('whatsapp')) &&
        $store.state.modules.whatsapp.enabled
      "
      to="/whatsapp"
    >
      <el-menu-item class="sidenav-item" index="whatsapp">
        <i class="ion ion-social-whatsapp" />
        <small>WhatsApp</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="showModule('webchat') && checkRoles(getPermittedRoles('webchat'))"
      to="/widget"
    >
      <el-menu-item class="sidenav-item" index="webchat">
        <i class="ion ion-android-laptop" />
        <small>Widget</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        showModule('analytics') && checkRoles(getPermittedRoles('analytics'))
      "
      to="/analytics"
    >
      <el-menu-item class="sidenav-item" index="analytics">
        <i class="el-icon-data-line" />
        <small>Analytics</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="checkRoles(getPermittedRoles('supervisor'))"
      to="/supervisor"
    >
      <el-menu-item class="sidenav-item" index="supervisor">
        <i class="el-icon-view" />
        <small>Supervisor</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        showModule('miniapps') && checkRoles(getPermittedRoles('file-storage'))
      "
      to="/file-storage"
    >
      <el-menu-item class="sidenav-item" index="file-storage">
        <i class="el-icon-folder-opened" />
        <small>Files</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="showModule('visitors') && checkRoles(getPermittedRoles('visitors'))"
      to="/visitors"
    >
      <el-menu-item class="sidenav-item" index="visitors">
        <i class="el-icon-user" />
        <small>Visitors</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="checkRoles(getPermittedRoles('settings'))"
      to="/settings"
    >
      <el-menu-item class="sidenav-item" index="settings">
        <i class="el-icon-set-up" />
        <small>Settings</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        showModule('broadcast') && checkRoles(getPermittedRoles('broadcast'))
      "
      to="/broadcast"
    >
      <el-menu-item class="sidenav-item" index="broadcast">
        <i class="ion ion-social-rss" />
        <small>Broadcasting</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        showModule('subscription') &&
        checkRoles(getPermittedRoles('subscription'))
      "
      to="/subs"
    >
      <el-menu-item class="sidenav-item" index="subscription">
        <i class="ion ion-ios-paper-outline" />
        <small>Subscriptions</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        (showModule('webchat', 'showPreChatForm') ||
          showModule('webchat', 'showPostChatForm')) &&
        checkRoles(getPermittedRoles('chat_form'))
      "
      to="/chat-form"
    >
      <el-menu-item class="sidenav-item" index="chat_form">
        <i class="el-icon-document" />
        <small>Pre/Post<br />Chat Form</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        showModule('access_control') &&
        checkRoles(getPermittedRoles('access_control'))
      "
      to="/access-control"
    >
      <el-menu-item class="sidenav-item" index="access_control">
        <i class="el-icon-warning-outline" />
        <small>Access<br />Control</small>
      </el-menu-item>
    </router-link>
    <router-link
      v-if="checkRoles(getPermittedRoles('import_export'))"
      to="/import_export"
    >
      <el-menu-item class="sidenav-item" index="import_export">
        <i class="ion ion-ios-cloud-download" />
        <small>Import<br />Export</small>
      </el-menu-item>
    </router-link>

    <router-link v-if="checkRoles(getPermittedRoles('audit'))" to="/audit">
      <el-menu-item class="sidenav-item" index="audit">
        <i class="el-icon-tickets" />
        <small>Audit</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="showModule('prism') && checkRoles(getPermittedRoles('prism'))"
      to="/prism"
    >
      <el-menu-item class="sidenav-item" index="prism">
        <i class="el-icon-house" />
        <small>Prism</small>
      </el-menu-item>
    </router-link>

    <a href="https://www.keyreply.com/en/knowledge" target="_blank">
      <el-menu-item class="sidenav-item" index="manual">
        <i class="el-icon-reading" />
        <small>Manual</small>
      </el-menu-item>
    </a>
    <a
      v-if="
        isKeycloakAuth &&
        $store.state.modules.system.enableKeycloakChangePassword
      "
      :href="getKeycloakChangePasswordURL"
      target="_blank"
    >
      <el-menu-item class="sidenav-item" index="change_password">
        <i class="el-icon-unlock" />
        <small>Change<br />Password</small>
      </el-menu-item>
    </a>

    <a @click.prevent="loggingOut">
      <el-menu-item class="sidenav-item" index="logout">
        <i class="ion ion-log-out" />
        <small>Log out</small>
      </el-menu-item>
    </a>

    <router-link v-if="checkRoles(getPermittedRoles('labs'))" to="/labs">
      <el-menu-item class="sidenav-item" index="labs">
        <i class="ion ion-ionic" />
        <small>Labs</small>
      </el-menu-item>
    </router-link>

    <router-link
      v-if="
        $store.state.showAdvanced && checkRoles(getPermittedRoles('settings'))
      "
      to="/superadmin"
    >
      <el-menu-item class="sidenav-item" index="superadmin">
        <i class="ion ion-alert-circled" />
        <small>Admin</small>
      </el-menu-item>
    </router-link>
  </el-menu>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { logout } from "@/helperMethods/auth";
import { getAuthType, AuthProvider } from "@/auth";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default {
  data() {
    return {
      collapse: true,
    };
  },
  computed: {
    ...mapGetters(["queueBadgeNumber"]),
    transactionsLabel() {
      return this.$store.state.modules["transactions"].label;
    },
    isKeycloakAuth() {
      const authType = getAuthType();
      if (authType === AuthProvider.KEYCLOAK) {
        return true;
      }
      return false;
    },
    getKeycloakChangePasswordURL() {
      const keycloakClient = _.get(this, "$auth.keycloakClient");
      const realmName = _.get(keycloakClient, "realm", "master");
      const authServerUrl = _.get(
        keycloakClient,
        "authServerUrl",
        `${new URL(window.location.origin)}/auth}`
      );
      const url = `${authServerUrl}/realms/${realmName}/account/password`;
      const urlString = url.toString();
      return urlString;
    },
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    showModule(moduleId, subModuleId) {
      const isMainModuleEnabled = _.get(
        this,
        `$store.state.modules.${moduleId}.enabled`,
        false
      );
      if (!subModuleId) {
        return isMainModuleEnabled;
      } else {
        if (Array.isArray(subModuleId)) {
          return _.some(subModuleId, (subModule) => {
            const isSubModuleEnabled = _.get(
              this,
              `$store.state.modules.${moduleId}.${subModule}.enabled`,
              false
            );
            return isSubModuleEnabled;
          });
        } else {
          const isSubModuleEnabled = _.get(
            this,
            `$store.state.modules.${moduleId}.${subModuleId}.enabled`,
            false
          );
          return isSubModuleEnabled;
        }
      }
    },
    loggingOut() {
      this.$store.commit("SET_CURRENT_TAB_LOGGED_OUT", true);
      logout();
    },
  },
};
</script>

<style scoped>
.sidenav-item {
  display: flex;
  flex-direction: column;
  line-height: 1;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.sidenav-logo {
  padding: 0 10px !important;
  cursor: default;
}

.sidenav-logo img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.sidenav-item i {
  font-size: 20px;
  margin-bottom: 3px;
  margin-right: 0;
}

.sidenav-item small {
  font-size: 9px;
}

.router-link-exact-active .sidenav-item {
  background-color: rgb(252, 235, 234);
  color: #e4392b;
}

.router-link-exact-active .sidenav-item i {
  color: #e4392b;
}
</style>

<template>
  <el-row type="flex" style="flex-flow: row wrap; align-items: start;">
    <div style="padding: 10px 15px 0 0; width: 100%;">
      <p style="line-height: 10px; margin: 0;">List Options</p>
      <el-select
        v-model="selected"
        style="width: 100%;"
        multiple
        filterable
        allow-create
        size="mini"
        placeholder="Add more item"
        :disabled="!canEdit"
      >
        <el-option v-for="item in []" :key="item" :label="item" :value="item"></el-option>
      </el-select>
    </div>

    <el-checkbox v-model="field.visible" label="Visible" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.editable" label="Editable" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.multiselect" label="Multiselect" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.closable" label="Closable" :disabled="!canEdit"></el-checkbox>
  </el-row>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["field", "canEdit"],
  data() {
    return {
      selected: []
    };
  },
  mounted() {
    this.selected = _.cloneDeep(this.field.listValues);
  },
});
</script>


import * as Sentry from "@sentry/browser";
import { Message } from "element-ui";
import _ from "lodash";
import { isGraphQLWebsocketConnected } from "@/store/api";

const CHECK_STATUS_INTERVAL_TIME = 1000;
const WEBSOCKET_RECONNECTING_MSG = "Attempt reconnection...";
const WEBSOCKET_CONNECTED_MSG = "Connected";

let isShowMessageBox = false;
let reconnectingMessageBox: any = null;
let connectedMessageBox: any = null;
let checkingStatusInterval: any = null;
let errorMessageDelayTimeout: any = null;

export const notifyWebsocketError = (error: string | Error) => {
  if (!isShowMessageBox) {
    if (errorMessageDelayTimeout) {
      clearTimeout(errorMessageDelayTimeout);
    }

    errorMessageDelayTimeout = setTimeout(() => {
      if (!isGraphQLWebsocketConnected()) {
        reconnectingMessageBox = Message({
          message: WEBSOCKET_RECONNECTING_MSG,
          type: "warning",
          showClose: false,
          duration: 0,
        });
        isShowMessageBox = true;
      }
    }, 5000);
  }

  if (checkingStatusInterval) {
    clearInterval(checkingStatusInterval);
  }

  checkingStatusInterval = setInterval(() => {
    if (isGraphQLWebsocketConnected()) {
      notifyWebsocketReconnected();
    }
  }, CHECK_STATUS_INTERVAL_TIME);

  Sentry.captureException(error);
};

export const notifyWebsocketReconnected = () => {
  if (isShowMessageBox) {
    reconnectingMessageBox.close();
    isShowMessageBox = false;
    connectedMessageBox = Message({
      message: WEBSOCKET_CONNECTED_MSG,
      type: "success",
    });
  }

  if (checkingStatusInterval) {
    clearInterval(checkingStatusInterval);
  }
};

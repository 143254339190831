import _ from "lodash";
import * as Sentry from "@sentry/browser";
import store from "@/store";
import LocalStorageManager from "@/localStorageManager";

// Get list of roles that can access 'module' parameter
export function checkRoles(
  permittedRoles: string[],
  checkForModuleSettings: boolean = true
): boolean {
  if (checkForModuleSettings) {
    const isAccessControlModuleEnabled = _.get(
      store,
      "state.modules.access_control.enabled"
    );
    if (!isAccessControlModuleEnabled) {
      // if access_control not enabled, default allow all
      return true;
    }
  }

  const app_metadata = store.state.profile.app_metadata;
  const hasRole =
    app_metadata &&
    _.intersection(app_metadata.roles, permittedRoles).length > 0;

  return hasRole;
}

// Get list of roles that can access 'module' parameter
export function getPermittedRoles(module: string): string[] {
  const roles = store.state.modules["access_control"].roles;
  return _.chain(roles)
    .map((role) => {
      return role.access[module] ? role.name : null;
    })
    .compact()
    .value();
}

export function showModule(moduleId: string): boolean {
  return _.get(store, `state.modules[${moduleId}].enabled`, false);
}

export function logout() {
  store.dispatch("LOGOUT").then((_) => {});
  Sentry.configureScope((scope) => scope.setUser(null));
}

export function shouldLogOut() {
  const hasSavedProfile = LocalStorageManager.getItem("profile");
  return !hasSavedProfile;
}

<template>
  <el-row type="flex" :gutter="0" justify="space-between" align="middle">
    <div style="display:flex;align-items:center;width:100%;margin-right: 15px;">
      <p style="white-space:nowrap;margin-right: 15px;">Select Flow:</p>
      <el-cascader
        @change="onFilterChanged"
        style="width: 100%;"
        ref="cascader"
        placeholder="Try searching a flow..."
        v-model="filtered"
        :filterable="true"
        :props="{ checkStrictly: true }"
        :options="options"
        :disabled="$store.getters.isFetchingFlows || isBusy || parentIsBusy"
      ></el-cascader>
    </div>
    <div style="display:flex; flex-direction:row">
      <el-tooltip content="Create a new workflow" placement="top">
        <el-button
          icon="el-icon-plus"
          type="primary"
          :plain="true"
          :disabled="$store.getters.isFetchingFlows || isBusy || parentIsBusy"
          @click="addNewFlow"
        >Add Flow</el-button>
      </el-tooltip>

      <template v-if="$store.getters.isShowAdvanced">
        <el-button
          :loading="isBusy"
          :disabled="$store.getters.isFetchingFlows || isBusy || parentIsBusy"
          type="primary"
          icon="el-icon-download"
          @click="showDrawer=true"
        >Download Results</el-button>
        <el-button
          :loading="isBusy"
          :disabled="$store.getters.isFetchingFlows || isBusy || parentIsBusy"
          type="primary"
          icon="el-icon-download"
          @click="showDrawer=true"
        >Download Flow Templates</el-button>
      </template>
      <DownloadFlowDrawer
        :show-drawer="showDrawer"
        :parent-is-busy="parentIsBusy"
        :array-flows="arrayFlows"
        @close="showDrawer = false"
      />
    </div>
  </el-row>
</template>

<script>
import XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";
import DownloadFlowDrawer from "./DownloadFlowDrawer";

export default {
  components: { DownloadFlowDrawer },
  props: ["flows", "selectedFlow", "arrayFlows", "parentIsBusy"],
  data() {
    return {
      isBusy: false,
      showDrawer: false,
      filtered: null,
    };
  },
  mounted() {
    this.$eventBus.$on("setHeaderFilter", (data) => {
      if (this.$refs.cascader) {
        this.$refs.cascader.activePath = [];
      }
      this.filtered = data;
    });

    this.$eventBus.$on("duplicateFlow", (data) => {
      this.addNewFlow(data);
    });
  },
  computed: {
    options() {
      return this.arrayFlows.map((flow) => {
        return {
          label: `flow - ${flow.title.toLowerCase()}`,
          value: flow.flowId,
          children: [
            {
              label: "config - general",
              value: "config-default",
            },
            ...flow.sections.map((section) => {
              return {
                label: `section - ${section.title.toLowerCase()}`,
                value: section.sectionId,
                children: section.questions.map((question) => {
                  return {
                    label: `question - ${question.name.toLowerCase()}`,
                    value: question.questionId,
                  };
                }),
              };
            }),
            {
              label: "config - end flow",
              value: "config-end-flow",
            },
          ],
        };
      });
    },
  },
  methods: {
    onFilterChanged(event) {
      if (!_.isEmpty(event)) {
        this.$emit("setSelectedFlow", event[0]);

        if (event.length > 1) {
          this.$nextTick(() => {
            if (_.includes(event[1], "config")) {
              this.$eventBus.$emit("setSelectedSection", event[1]);
            } else {
              this.$eventBus.$emit("setSelectedSection", `section-${event[1]}`);
            }
          });
        }

        if (event.length > 2) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$eventBus.$emit("setSelectedQuestion", event[2]);
            }, 300);
          });
        }
      }
    },
    addNewFlow(duplicateFlow = null) {
      this.isBusy = true;
      const tempFlowId = uuid();
      const sectionId = uuid();

      // Create a standard flow
      this.$store
        .dispatch("CREATE_FLOW")
        .then((newStandardFlow) => {
          this.$nextTick(() => {
            if (newStandardFlow.flowId) {
              if (duplicateFlow) {
                delete duplicateFlow.flow.flowId;
                newStandardFlow = _.assign(newStandardFlow, duplicateFlow.flow);
                newStandardFlow.title = duplicateFlow.flowName;

                newStandardFlow.sections.forEach((section) => {
                  section.flowId = newStandardFlow.flowId;
                  section.questions.forEach((question) => {
                    question.flowId = newStandardFlow.flowId;
                  });
                });

                this.$notify.success({
                  type: "success",
                  title: "Success",
                  message: "Flow duplicated, please press save to continue",
                  position: "bottom-right",
                });
              } else {
                this.$notify.success({
                  type: "success",
                  title: "Success",
                  message: "Successfully created a flow",
                  position: "bottom-right",
                });
              }

              this.$nextTick(() => {
                this.filtered = [newStandardFlow.id];
                this.$emit("setSelectedFlow", newStandardFlow);
              });
            }
          });
        })
        .catch((error) => {
          console.log("Encountered error creating standard flow.", error);
          this.$notify.error({
            type: "error",
            title: "Error",
            message: "Encountered error creating standard flow.",
            position: "bottom-right",
          });
        })
        .finally(() => (this.isBusy = false));
    },

    // olddownloadFlowsResults() {
    //   this.isBusy = true;
    //   this.$store
    //     .dispatch("FETCH_ALL_FLOWS_RESULTS")
    //     .then(flowsResult => {
    //       const wb = XLSX.utils.book_new();
    //       flowsResult.forEach((flowResult, flowIndex) => {
    //         const { title, headers, userData } = flowResult;
    //         const ws_data = [];
    //         ws_data.push([`Flow Title: ${title}`], []);
    //         ws_data.push(["User", "Timestamp", ...headers]);

    //         userData.map((user_row_id, user) => {
    //           const { date_created } = user;
    //           const currentRow = [];
    //           currentRow.push(`User #${user_row_id.substring(0, 25)}`);
    //           currentRow.push(moment(date_created).format("LLL"));
    //           headers.forEach(field => {
    //             currentRow.push(user[field]);
    //           });
    //           ws_data.push(currentRow);
    //         });

    //         ws_data.push([], [], [`---- End of results for ${title}----`]);

    //         const ws = XLSX.utils.aoa_to_sheet(ws_data);
    //         const sheetTitle = `#${flowIndex + 1}. ${title}`;
    //         let trancatedSheetTitle = sheetTitle;
    //         if (trancatedSheetTitle.length > 31) {
    //           trancatedSheetTitle = trancatedSheetTitle.substring(0, 31);
    //         }
    //         XLSX.utils.book_append_sheet(wb, ws, trancatedSheetTitle);
    //       });

    //       const filename = this.$exportFilename("form_results", "xlsx");
    //       XLSX.writeFile(wb, filename, {});
    //     })
    //     .catch(() => {
    //       this.$notify({
    //         type: "error",
    //         message: "Encountered error fetching flow results.",
    //         title: "Error",
    //         position: "bottom-right"
    //       });
    //     })
    //     .finally(() => {
    //       this.isBusy = false;
    //     });
    // }
  },
};
</script>
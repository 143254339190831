<template>
  <div>
    <!-- SLOT NODE -DIALOGS -->
    <el-dialog :visible.sync="slotDialog" width="50%">
      <span slot="title" class="dialog-heading">Configure Slot {{ slotIndex }}</span>
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="sub-title node-sub-heading">Check for:</div>
          <el-input v-model.trim="slotForm.checkFor" style="margin-bottom:5px;" />
        </el-col>
        <el-col :span="12">
          <div class="sub-title node-sub-heading">Save it as:</div>
          <el-input v-model.trim="slotForm.variable" style="margin-bottom:5px;" />
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <div
            class="sub-title node-sub-heading"
          >If {{ slotForm.variable }} is not present then ask:</div>
          <el-input v-model.trim="slotForm.notPresent" style="margin-bottom:5px;" />
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <div class="node-sub-heading">When user responds, if {{ slotForm.checkFor }} is...</div>
        <el-col :span="24">
          <div class="sub-title">Found:</div>
          <el-input
            v-model.trim="slotForm.filled"
            placeholder="Enter a response"
            style="margin-bottom:5px;"
          />
        </el-col>
        <el-col :span="24">
          <div class="sub-title">Not found:</div>
          <el-input
            v-model.trim="slotForm.noMatch"
            placeholder="Enter a response"
            style="margin-bottom:5px;"
          />
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="slotDialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="
            slotDialog = false;
            updateSlotResponse(slotForm, slotIndex);
          "
        >Confirm</el-button>
      </span>
    </el-dialog>

    <div class="node-sub-heading">Then check for:</div>

    <el-form-item style="border-bottom: 1px solid #ddd;">
      <el-col :sm="1" :xs="1">No</el-col>
      <el-col :sm="5" :xs="5">Check For</el-col>
      <el-col :sm="5" :xs="5">Save it as</el-col>
      <el-col :sm="8" :xs="8">If not present, ask</el-col>
      <el-col :sm="3" :xs="3">Type</el-col>
      <el-col :sm="1" :xs="1" />
      <el-col :sm="1" :xs="1" />
    </el-form-item>

    <el-form-item v-for="(slot, index) in slot_nodes" :key="index">
      <el-col :span="1">{{ index + 1 }}</el-col>
      <el-col :span="5">
        <el-input
          v-model.trim="slot.checkFor"
          :disabled="!authorized"
          placeholder="Enter a entity or intent"
          style="margin-bottom:5px;"
          @change="setEdited(`slot.checkFor`, true)"
          @input="setEdited(`slot.checkFor`, true)"
          @blur="slot.dialog_node !== '' && updateSlotContext(slot, index + 1)"
        />
      </el-col>
      <el-col :span="5">
        <el-input
          v-model.trim="slot.variable"
          :disabled="!authorized"
          placeholder="Enter content node id"
          style="margin-bottom:5px;"
          @change="setEdited(`slot.variable`, true)"
          @blur="slot.dialog_node !== '' && updateSlotContext(slot, index + 1)"
        />
      </el-col>
      <el-col :span="8">
        <el-input
          v-model.trim="slot.notPresent"
          :disabled="!authorized"
          placeholder="Enter a prompt"
          style="margin-bottom:5px;"
          @change="setEdited(`slot.notPresent`, true)"
          @blur="slot.dialog_node !== '' && updateSlotOutput(slot, index + 1)"
        />
      </el-col>
      <el-col :sm="3" :xs="3">
        <span style="margin-bottom:5px;">{{ slot.required }}</span>
      </el-col>
      <!-- <el-col :span="1">
        <i
          @click="authorized && openSlot(slot, index + 1)"
          class="el-icon-setting"
          style="font-size: 18px"
        ></i>
      </el-col>-->
      <el-col :span="1">
        <i
          class="el-icon-delete"
          style="font-size: 18px"
          @click="authorized && setEdited(`deleteSlotCondition`, true);deleteNode(slot, index)"
        />
      </el-col>
    </el-form-item>

    <div style="padding:0 0 20px 0;">
      <span @click="addSlot(nodeForm)">
        <el-button :disabled="!authorized" circle icon="el-icon-plus" />Add Slot
      </span>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";

export default {
  name: "SlotsConditions",
  props: {
    nodeForm: Object,
    authorized: Boolean,
    slotNodes: Array,
    eventNodes: Object,
    notifyError: Function,
    savedSuccess: Function,
    getDialogNodes: Function,
  },
  data() {
    return {
      slot_nodes: [],
      slotDialog: false,
      slotForm: {},
      slotIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["watsonDialogNodes", "watsonisEditedObject"]),
  },
  watch: {
    nodeForm: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.slot_nodes = [];
        this.formatSlot();
      }
    },
  },
  mounted() {
    this.slot_nodes = [];
    this.formatSlot();
  },
  methods: {
    checkNothingChanged() {
      const nothingChanged =
        Object.values(this.watsonisEditedObject).length === 0;
      if (nothingChanged) {
        this.$notify.info({
          title: "No Change Detected",
          message: "No update to watson is performed",
          position: "bottom-right",
        });
      }
      return nothingChanged;
    },
    setEdited(nodeVariableType, isEdited) {
      this.$set(this.$store.state.watson, "isEditedObject", {
        [nodeVariableType]: isEdited,
      });
    },
    // update slot context
    updateSlotContext(slot, slotIndex) {
      const self = this;
      let payload = slot;

      self.watsonDialogNodes.forEach((dialog, index) => {
        if (dialog.dialog_node == slot.dialog_node) {
          payload["old_values"] = dialog;
        }
      });

      payload.description = self.nodeForm.description.join(",");
      payload["old_values"].description = self.nodeForm.description.join(",");
      payload.slot_number = slotIndex;
      payload["old_values"].slot_number = slotIndex;
      Object.assign(payload, {
        parent_title: null,
        title: self.nodeForm.title,
      });
      Object.assign(payload.old_values, {
        parent_title: null,
        title: self.nodeForm.title,
      });

      const nothingChanged = this.checkNothingChanged();
      if (nothingChanged) {
        return;
      }

      self.$store
        .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
        .then(() => {
          self.eventNodes[slot.dialog_node].forEach((evt) => {
            if (evt.event_name == "input") {
              payload = JSON.parse(JSON.stringify(evt));
              payload.context = {
                [slot.variable]: slot.checkFor && slot.checkFor.trim(),
              };
              payload.conditions = slot.checkFor;

              self.watsonDialogNodes.forEach((dialog, index) => {
                if (dialog.dialog_node == evt.dialog_node) {
                  payload["old_values"] = dialog;
                }
              });

              payload.description = self.nodeForm.description.join(",");
              payload[
                "old_values"
              ].description = self.nodeForm.description.join(",");
              payload.slot_number = slotIndex;
              payload["old_values"].slot_number = slotIndex;
              Object.assign(payload, {
                parent_title: null,
                title: self.nodeForm.title,
              });
              Object.assign(payload.old_values, {
                parent_title: null,
                title: self.nodeForm.title,
              });

              const nothingChanged = this.checkNothingChanged();
              if (nothingChanged) {
                return;
              }

              self.$store
                .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
                .then(() => {
                  this.$set(this.$store.state.watson, "isEditedObject", {});
                  self.savedSuccess();
                  self.getDialogNodes();
                })
                .catch((e) =>
                  self.notifyError(
                    "Encountered error updating watson dialog node"
                  )
                );
            }
          });
        })
        .catch((e) =>
          self.notifyError("Encountered error updating watson dialog node")
        );
    },
    // update slot output
    updateSlotOutput(slot, slotIndex) {
      const self = this;
      self.eventNodes[slot.dialog_node].forEach((evt) => {
        if (evt.event_name == "focus") {
          let payload = JSON.parse(JSON.stringify(evt));

          self.watsonDialogNodes.forEach((dialog, index) => {
            if (dialog.dialog_node == evt.dialog_node) {
              payload["old_values"] = dialog;
            }
          });

          payload.output = { text: slot.notPresent };
          payload.description = self.nodeForm.description.join(",");
          payload.slot_number = slotIndex;
          payload["old_values"].slot_number = slotIndex;
          payload["old_values"].description = self.nodeForm.description.join(
            ","
          );
          Object.assign(payload, {
            parent_title: null,
            title: self.nodeForm.title,
          });
          Object.assign(payload.old_values, {
            parent_title: null,
            title: self.nodeForm.title,
          });

          const nothingChanged = this.checkNothingChanged();
          if (nothingChanged) {
            return;
          }

          self.$store
            .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
            .then(() => {
              this.$set(this.$store.state.watson, "isEditedObject", {});
              this.savedSuccess();
              if (slot.notPresent === "") {
                slot.required = "Optional";
              } else {
                slot.required = "Required";
              }
              this.getDialogNodes();
            })
            .catch((e) =>
              this.notifyError("Encountered error updating watson dialog node")
            );
        }
      });
    },
    openSlot(slot, index) {
      this.slotDialog = true;
      this.slotIndex = index;
      this.slotForm = slot;
    },
    addSlot(node) {
      this.$emit("addSlot-Click", node);
    },
    updateSlotResponse(slot, slotIndex) {
      const self = this;
      let payload = slot;
      let filled = false;
      let nomatch = false;
      let handler = null;
      let handler2 = null;

      self.watsonDialogNodes.forEach((dialog, index) => {
        if (dialog.dialog_node == slot.dialog_node) {
          payload["old_values"] = dialog;
        }
      });

      payload.description = self.nodeForm.description.join(",");
      payload["old_values"].description = self.nodeForm.description.join(",");
      payload.slot_number = slotIndex;
      payload["old_values"].slot_number = slotIndex;
      Object.assign(payload, {
        parent_title: null,
        title: self.nodeForm.title,
      });
      Object.assign(payload.old_values, {
        parent_title: null,
        title: self.nodeForm.title,
      });

      const nothingChanged = this.checkNothingChanged();
      if (nothingChanged) {
        return;
      }

      self.$store
        .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
        .then(() => {
          this.$set(this.$store.state.watson, "isEditedObject", {});
          self.eventNodes[slot.dialog_node].forEach((evt) => {
            if (evt.event_name == "input") {
              payload = JSON.parse(JSON.stringify(evt));
              payload.context = {
                [slot.variable]: slot.checkFor && slot.checkFor.trim(),
              };
              payload.conditions = slot.checkFor;

              self.watsonDialogNodes.forEach((dialog, index) => {
                if (dialog.dialog_node == evt.dialog_node) {
                  payload["old_values"] = dialog;
                }
              });

              payload.description = self.nodeForm.description.join(",");
              payload[
                "old_values"
              ].description = self.nodeForm.description.join(",");
              payload.slot_number = slotIndex;
              payload["old_values"].slot_number = slotIndex;
              Object.assign(payload, {
                parent_title: null,
                title: self.nodeForm.title,
              });
              Object.assign(payload.old_values, {
                parent_title: null,
                title: self.nodeForm.title,
              });

              const nothingChanged = this.checkNothingChanged();
              if (nothingChanged) {
                return;
              }

              self.$store
                .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
                .then(() => {
                  this.$set(this.$store.state.watson, "isEditedObject", {});
                  self.savedSuccess();
                  self.getDialogNodes();
                })
                .catch((e) =>
                  self.notifyError(
                    "Encountered error updating watson dialog node"
                  )
                );
            }
            if (evt.event_name == "focus") {
              handler = evt.dialog_node;
              payload = JSON.parse(JSON.stringify(evt));
              payload.output = { text: slot.notPresent };

              self.watsonDialogNodes.forEach((dialog, index) => {
                if (dialog.dialog_node == evt.dialog_node) {
                  payload["old_values"] = dialog;
                }
              });

              payload.description = self.nodeForm.description.join(",");
              payload[
                "old_values"
              ].description = self.nodeForm.description.join(",");
              payload.slot_number = slotIndex;
              payload["old_values"].slot_number = slotIndex;
              Object.assign(payload, {
                parent_title: null,
                title: self.nodeForm.title,
              });
              Object.assign(payload.old_values, {
                parent_title: null,
                title: self.nodeForm.title,
              });

              const nothingChanged = this.checkNothingChanged();
              if (nothingChanged) {
                return;
              }

              self.$store
                .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
                .then(() => {
                  this.$set(this.$store.state.watson, "isEditedObject", {});
                  self.savedSuccess();
                  if (slot.notPresent === "") {
                    slot.required = "Optional";
                  } else {
                    slot.required = "Required";
                  }
                  self.getDialogNodes();
                })
                .catch((e) =>
                  self.notifyError(
                    "Encountered error updating watson dialog node"
                  )
                );
            }
            if (evt.event_name == "filled") {
              handler2 = evt.dialog_node;
              filled = true;
              payload = JSON.parse(JSON.stringify(evt));
              payload.output = { text: slot.filled };

              self.watsonDialogNodes.forEach((dialog, index) => {
                if (dialog.dialog_node == evt.dialog_node) {
                  payload["old_values"] = dialog;
                }
              });

              payload.description = self.nodeForm.description.join(",");
              payload[
                "old_values"
              ].description = self.nodeForm.description.join(",");
              payload.slot_number = slotIndex;
              payload["old_values"].slot_number = slotIndex;
              Object.assign(payload, {
                parent_title: null,
                title: self.nodeForm.title,
              });
              Object.assign(payload.old_values, {
                parent_title: null,
                title: self.nodeForm.title,
              });

              const nothingChanged = this.checkNothingChanged();
              if (nothingChanged) {
                return;
              }

              self.$store
                .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
                .then(() => {
                  this.$set(this.$store.state.watson, "isEditedObject", {});
                  this.savedSuccess();
                  if (slot.notPresent === "") {
                    slot.required = "Optional";
                  } else {
                    slot.required = "Required";
                  }
                  self.getDialogNodes();
                })
                .catch((e) =>
                  self.notifyError(
                    "Encountered error updating watson dialog node"
                  )
                );
            }
            if (evt.event_name == "nomatch") {
              nomatch = true;
              payload = JSON.parse(JSON.stringify(evt));
              payload.output = { text: slot.noMatch };

              self.watsonDialogNodes.forEach((dialog, index) => {
                if (dialog.dialog_node == evt.dialog_node) {
                  payload["old_values"] = dialog;
                }
              });

              payload.description = self.nodeForm.description.join(",");
              payload[
                "old_values"
              ].description = self.nodeForm.description.join(",");
              payload.slot_number = slotIndex;
              payload["old_values"].slot_number = slotIndex;
              Object.assign(payload, {
                parent_title: null,
                title: self.nodeForm.title,
              });
              Object.assign(payload.old_values, {
                parent_title: null,
                title: self.nodeForm.title,
              });

              const nothingChanged = this.checkNothingChanged();
              if (nothingChanged) {
                return;
              }

              self.$store
                .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
                .then(() => {
                  this.$set(this.$store.state.watson, "isEditedObject", {});
                  self.savedSuccess();
                  if (slot.notPresent === "") {
                    slot.required = "Optional";
                  } else {
                    slot.required = "Required";
                  }
                  self.getDialogNodes();
                })
                .catch((e) =>
                  self.notifyError(
                    "Encountered error updating watson dialog node"
                  )
                );
            }
          });
          if (!filled && slot.filled !== "") {
            handler2 = "handler_" + uuid();
            payload = {
              description: self.nodeForm.description.join(","),
              title: self.nodeForm.title,
              slot_number: slotIndex,
              type: "event_handler",
              dialog_node: handler2,
              event_name: "filled",
              metadata: {},
              output: { text: slot.filled },
              parent: slot.dialog_node,
              previous_sibling: handler,
            };
            // self.addWatsonDialog(payload);
            self.$emit("addWatsonDialog-click", payload);
          }
          if (!nomatch && slot.noMatch !== "") {
            payload = {
              description: self.nodeForm.description.join(","),
              title: self.nodeForm.title,
              slot_number: slotIndex,
              type: "event_handler",
              dialog_node: "handler_" + uuid(),
              event_name: "nomatch",
              metadata: {},
              output: { text: slot.noMatch },
              parent: slot.dialog_node,
              previous_sibling: handler2 || handler,
            };
            // self.addWatsonDialog(payload);
            self.$emit("addWatsonDialog-click", payload);
          }
        });
    },
    deleteNode(node, index) {
      this.$emit("deleteNode-click", node, index);
    },
    formatSlot() {
      const self = this;
      // if (
      //   self.nodeForm.type === "frame" &&
      //   self.slotNodes[self.nodeForm.dialog_node]
      // ) {
      self.slot_nodes = self.slotNodes;
      if (!self.slot_nodes) return;
      self.slot_nodes.forEach((slot, index) => {
        if (self.eventNodes[slot.dialog_node]) {
          self.eventNodes[slot.dialog_node].forEach((evt, index2) => {
            if (
              evt.event_name == "focus" &&
              evt.output &&
              Object.keys(evt.output).length > 0
            ) {
              self.$set(slot, "notPresent", evt.output.text);
              if (evt.output.text === "") {
                self.$set(slot, "required", "Optional");
              } else {
                self.$set(slot, "required", "Required");
              }
            } else if (evt.conditions && evt.event_name == "input") {
              self.$set(slot, "checkFor", evt.conditions);
            } else if (
              evt.event_name == "filled" &&
              Object.keys(evt.output).length > 0
            ) {
              self.$set(slot, "filled", evt.output.text);
            } else if (
              evt.event_name == "nomatch" &&
              Object.keys(evt.output).length > 0
            ) {
              self.$set(slot, "noMatch", evt.output.text);
            }
          });
        }
        if (!slot.required) {
          self.$set(slot, "required", "Optional");
        }
      });
    },
    // }
  },
};
</script>

import { RootState } from "./types";
import _ from "lodash";
// only AUTH0
export function getAgentSOEId(state: RootState): string {
  const agentSoeId = _.get(state, "profile.app_metadata.SOE_ID", "");
  return agentSoeId;
}
// only AUTH0
export function getAgentName(state: RootState): string {
  const agentName = _.get(state, "profile.name", "");
  return agentName;
}

export function isShowAdvanced(state: RootState): boolean {
  return _.get(state, "showAdvanced", false);
}

export function isCyngusAccount(state: RootState): boolean {
  return state.account.includes("CYNGUS");
}

export function contentNodeNames(state: RootState): string[] {
  return (state.nodes.content && Object.keys(state.nodes.content)) || [];
}

export function releaseId(
  state: RootState
): { dashboard: string; server: string } {
  return state.releaseId;
}

import { MutationTree } from "vuex/types";

import { PrismState } from "./types";
import Vue from "vue";
import store from "@/store";
import _ from "lodash";

const mutations: MutationTree<PrismState> = {};

export default mutations;

<template>
  <el-select
    filterable
    v-model="$store.state.brain"
    placeholder="Select Bot"
    style="margin-right: 10px"
    :loading="reloading"
    :disabled="bots.length === 1"
    @change="switchBot"
    size="small"
  >
    <el-option
      v-for="name in bots"
      :key="name"
      :label="name"
      :value="name"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "EditorBotSelector",
  props: ["reloading", "setStatus", "switchBot"],
  computed: {
    bots() {
      const account = this.$store.state.account;
      const brain = this.$store.state.brain;
      if (
        this.$store.getters.isShowAdvanced ||
        this.$store.getters.isCyngusAccount
      ) {
        // FILTER
        return this.$store.state.bots;
      } else {
        return [brain];
      }
    },
  },
};
</script>

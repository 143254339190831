import _ from "lodash";
import store from "@/store";
import moment from "moment";
import { isLinkFound } from "../util";
import { getUserPermissions } from "../index";

import { ButtonType, ContentNodeType, CardType } from "@/store/types";
import { validateButtonOrQuickReply } from "@/components/Buttons/Validations";

export function validateContent(id: string): boolean {
  const contentNode: ContentNodeType = store.state.nodes.content[id];
  const contentText = _.get(contentNode, "content.text");
  const textIsEmpty = isTextEmpty(contentText);
  const isInvalid = textIsEmpty;
  return isInvalid;
}

function isTextEmpty(contentText: string | string[]): boolean {
  if (contentText && typeof contentText !== "string") {
    _.forEach(contentText, (text) => {
      if (!text || text === "") {
        return true;
      }
    });
  } else if (typeof contentText == "string") {
    if (contentText === "") {
      return true;
    }
  }
  return false;
}

export function validateButton(id: string): boolean {
  const contentNode: ContentNodeType = store.state.nodes.content[id];
  const content = _.get(contentNode, "content");
  const isContentArray = Array.isArray(content);

  let isInvalid = validateSingleCardButton(content as CardType);
  if (isContentArray) {
    isInvalid = validateMultipleCardButtons(content as CardType[]);
  }

  return isInvalid;
}

function validateMultipleCardButtons(contentArray: CardType[]): boolean {
  const isInvalid = _.some(contentArray, (content) => {
    return validateSingleCardButton(content);
  });
  return isInvalid;
}

function validateSingleCardButton(content: CardType): boolean {
  let isInvalid = false;
  const buttons = _.get(content, "buttons") as ButtonType[];

  if (buttons) {
    isInvalid = _.some(buttons, (button) => {
      return validateButtonOrQuickReply(button);
    });
  }
  return isInvalid;
}

export function validateQuickReplies(id: string): boolean {
  const contentNode: ContentNodeType = store.state.nodes.content[id] || {};
  let isInvalid = false;
  if (contentNode.quickReplies) {
    isInvalid = _.some(contentNode.quickReplies, (reply) => {
      return validateButtonOrQuickReply(reply);
    });
  }
  return isInvalid;
}

export function validateLinks(id: string): boolean {
  const contentNode = store.state.nodes.content[id];
  let isInvalid = false;
  if (!contentNode) {
    return isInvalid;
  }
  if (contentNode.next) {
    const linkNotFound = !isLinkFound(contentNode.next);
    isInvalid = linkNotFound;
  }
  return isInvalid;
}

export function checkHasLinks(id: string): boolean {
  const contentNode = store.state.nodes.content[id];
  if (!contentNode.next) {
    return false;
  }
  const hasLink = isLinkFound(contentNode.next);
  return hasLink;
}

export function getContentNodeWithMissingDetails() {
  // call graphql function
  const missingContentExpiry = _.get(
    store,
    "state.missingContentExpiry",
    moment().subtract(1, "m").format("YYYY-MM-DD HH:mm:ss")
  );
  const currTime = moment();
  if (currTime.isBefore(moment(missingContentExpiry))) {
    store.commit("SET_MISSING_DETAILS", { missingDetailAPI: [] });
    return [];
  }

  const userHasEditorPermissions = getUserPermissions("editor");

  if (userHasEditorPermissions) {
    store
      .dispatch("GET_MISSING_DETAILS")
      .then((missingContent) => {
        if (missingContent) {
          return missingContent;
        }
      })
      .catch((err) => {});
  }
  return {};
}

<template>
  <el-popover v-model="isOpen" placement="left" width="344">
    <!-- What will be shown in the popover -->
    <el-input
      autofocus
      :disabled="isBusy"
      v-model="newApiName"
      @keyup.enter.native="addNewApi"
    >
      <template slot="prepend">API name</template>
      <el-button slot="append" icon="el-icon-check" @click="addNewApi" />
    </el-input>

    <!-- Add new content -->
    <el-tooltip
      slot="reference"
      class="item"
      placement="bottom"
      content="Add new api"
    >
      <el-button
        :disabled="isBusy"
        :loading="isBusy"
        size="mini"
        type="primary"
        plain
        icon="el-icon-plus"
        >New API</el-button
      >
    </el-tooltip>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      newApiName: null,
      isBusy: false,
      isOpen: false,
    };
  },
  methods: {
    addNewApi() {
      if (!this.newApiName) return;
      this.newApiName = this.newApiName.toLowerCase().replace(/ /g, "_");
      this.isBusy = true;
      this.$store
        .dispatch("CREATE_API", { apiName: this.newApiName })
        .then((newApi) => {
          if (newApi.name) {
            this.$emit("onAdd", newApi);
            this.$message({
              type: "success",
              message: "Successfully created API",
            });
            this.$nextTick(() => {
              this.newApiName = "";
              this.isOpen = false;
            });
          }
        })
        .catch((err) => {
          if (err.message.includes("API already exists")) {
            this.$message({
              type: "error",
              message:
                "API with same name already exists. Provide a different name and try again.",
            });
          } else {
            this.$message({
              type: "error",
              message: "Encountered error creating api.",
            });
          }
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <p style="line-height: 10px; margin: 15px 0 10px;">
      Table Headers
      <el-popover
        v-model="addNewTableHeaderPopover"
        placement="top"
        title="Select field type to create."
        trigger="click"
      >
        <el-radio-group @change="onAddTableHeader" v-model="temporaryTableHeaderType" size="mini">
          <el-radio-button
            v-for="(fieldTypeOption, index) in fieldTypeOptions"
            :disabled="!canEdit"
            :key="`data-type-option-${index}`"
            :value="fieldTypeOption"
            :label="fieldTypeOption"
          ></el-radio-button>
        </el-radio-group>
        <el-button
          plain
          circle
          slot="reference"
          style="margin-left: 15px;"
          size="mini"
          icon="el-icon-plus"
          :disabled="!canEdit"
        ></el-button>
      </el-popover>
    </p>

    <ArrayTableHeader
      v-for="(header, headerIndex) in field.tableHeaders"
      :key="`array-field-table-header-${headerIndex}`"
      :header="header"
      :canEdit="canEdit"
      @onTableHeaderDisplayValueChanged="onTableHeaderDisplayValueChanged(headerIndex)"
      @onDeleteTableHeader="onDeleteTableHeader(headerIndex)"
    />

    <p style="line-height: 10px; margin: 25px 0 10px;">
      Table Aggregations
      <el-popover
        v-model="addNewTableAggregationPopover"
        placement="top"
        title="Select field type to create."
        trigger="click"
      >
        <el-radio-group
          @change="onAddTableAggregation"
          v-model="temporaryTableAggregationType"
          size="mini"
        >
          <el-radio-button
            v-for="(fieldTypeOption, index) in fieldTypeOptions"
            :key="`data-type-option-${index}`"
            :value="fieldTypeOption"
            :label="fieldTypeOption"
            :disabled="!canEdit"
          ></el-radio-button>
        </el-radio-group>
        <el-button
          plain
          circle
          slot="reference"
          style="margin-left: 15px;"
          size="mini"
          icon="el-icon-plus"
          :disabled="!canEdit"
        ></el-button>
      </el-popover>
    </p>

    <ArrayTableAggregation
      v-for="(aggregation, aggregationIndex) in field.tableAggregations"
      :key="`array-field-table-aggregation-${aggregationIndex}`"
      :aggregation="aggregation"
      :canEdit="canEdit"
      @onTableAggregationDisplayValueChanged="onTableAggregationDisplayValueChanged(aggregationIndex)"
      @onDeleteTableAggregation="onDeleteTableAggregation(aggregationIndex)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { v4 as uuid } from "uuid";
import ArrayTableHeader from "./TableHeader";
import ArrayTableAggregation from "./TableAggregation";
import { toCamelCase } from "@/filters/index";
import {
  TYPES,
  TYPE_STRING,
  TYPE_NUMBER,
  TYPE_DATETIME,
  TYPE_LIST,
  TYPE_CURRENCY,
} from "../../Field";

export default Vue.extend({
  props: ["field", "canEdit"],
  computed: {
    /**
     * @description Get existing tableAggregations key
     * @description Validate for unique key only
     * @return {Array<string>}
     */
    existingAggregationKeys() {
      return _.keys(this.field.tableAggregations);
    },

    /**
     * @description Get existing tableHeaders key
     * @description Validate for unique key only
     * @return {Array<string>}
     */
    existingHeaderKeys() {
      return _.keys(this.field.tableHeaders);
    },
  },
  data() {
    return {
      temporaryTableHeaderType: null,
      temporaryTableAggregationType: null,
      addNewTableHeaderPopover: false,
      addNewTableAggregationPopover: false,
      fieldTypeOptions: TYPES.filter((type) => type !== "array"), // Remove array option.
    };
  },
  methods: {
    /**
     * @description Handle delete table header event
     * @return {void}
     */
    onDeleteTableHeader(index) {
      this.$confirm("Are you sure to delete this item from table header?", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then(() => {
        delete this.field.tableHeaders[index];
        this.$forceUpdate();
      });
    },

    /**
     * @description To update UUID object key to camel cased 'Display Value'
     * @description Handle for tableHeaders
     * @return {void}
     */
    onTableHeaderDisplayValueChanged(index) {
      const _camelCased = toCamelCase(
        this.field.tableHeaders[index].displayValue
      );
      this.field.tableHeaders[_camelCased] = this.field.tableHeaders[index];
      delete this.field.tableHeaders[index];
    },

    /**
     * @description To update UUID object key to camel cased 'Display Value'
     * @description Handle for tableAggregations
     * @return {void}
     */
    onTableAggregationDisplayValueChanged(index) {
      const _camelCased = toCamelCase(
        this.field.tableAggregations[index].displayValue
      );
      this.field.tableAggregations[_camelCased] = this.field.tableAggregations[
        index
      ];
      delete this.field.tableAggregations[index];
    },

    /**
     * @description Handle delete table aggregation event
     * @return {void}
     */
    onDeleteTableAggregation(index) {
      this.$confirm(
        "Are you sure to delete this item from table aggregation?",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
        }
      ).then(() => {
        delete this.field.tableAggregations[index];
        this.$forceUpdate();
      });
    },

    /**
     * @description Handle adding new field to meta
     * @return {void}
     */
    onAddTableHeader() {
      switch (this.temporaryTableHeaderType) {
        case "number":
          {
            const _typeNumber = _.cloneDeep(TYPE_NUMBER);
            this.field.tableHeaders[uuid()] = _typeNumber;
          }
          break;

        case "datetime":
          {
            const _typeDatetime = _.cloneDeep(TYPE_DATETIME);
            this.field.tableHeaders[uuid()] = _typeDatetime;
          }
          break;

        case "list":
          {
            const _typeList = _.cloneDeep(TYPE_LIST);
            this.field.tableHeaders[uuid()] = _typeList;
          }
          break;

        case "string":
          {
            const _typeString = _.cloneDeep(TYPE_STRING);
            this.field.tableHeaders[uuid()] = _typeString;
          }
          break;

        case "currency":
          {
            const _typeCurrency = _.cloneDeep(TYPE_CURRENCY);
            this.field.tableAggregations[uuid()] = _typeCurrency;
          }
          break;

        default:
          return;
      }

      this.$nextTick(() => {
        this.temporaryTableHeaderType = null;
        this.addNewTableHeaderPopover = false;
        this.$forceUpdate();
      });
    },

    /**
     * @description Handle adding new field to meta
     * @return {void}
     */
    onAddTableAggregation() {
      switch (this.temporaryTableAggregationType) {
        case "number":
          {
            const _typeNumber = _.cloneDeep(TYPE_NUMBER);
            this.field.tableAggregations[uuid()] = _typeNumber;
          }
          break;

        case "datetime":
          {
            const _typeDatetime = _.cloneDeep(TYPE_DATETIME);
            this.field.tableAggregations[uuid()] = _typeDatetime;
          }
          break;

        case "list":
          {
            const _typeList = _.cloneDeep(TYPE_LIST);
            this.field.tableAggregations[uuid()] = _typeList;
          }
          break;

        case "string":
          {
            const _typeString = _.cloneDeep(TYPE_STRING);
            this.field.tableAggregations[uuid()] = _typeString;
          }
          break;

        case "currency":
          {
            const _typeCurrency = _.cloneDeep(TYPE_CURRENCY);
            this.field.tableAggregations[uuid()] = _typeCurrency;
          }
          break;

        default:
          return;
      }

      this.$nextTick(() => {
        this.temporaryTableAggregationType = null;
        this.addNewTableAggregationPopover = false;
        this.$forceUpdate();
      });
    },
  },
  components: {
    ArrayTableHeader,
    ArrayTableAggregation,
  },
});
</script>


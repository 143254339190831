var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center"}},[_c('el-input',{staticStyle:{"margin-bottom":"10px","width":"60%"},attrs:{"size":"mini","placeholder":"key"},model:{value:(_vm.sideEffect.data.key),callback:function ($$v) {_vm.$set(_vm.sideEffect.data, "key", $$v)},expression:"sideEffect.data.key"}},[_c('template',{slot:"prepend"},[_vm._v("Key")])],2),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('el-radio-group',{attrs:{"size":"mini"},on:{"change":_vm.updateDataType},model:{value:(_vm.sideEffect.data.type),callback:function ($$v) {_vm.$set(_vm.sideEffect.data, "type", $$v)},expression:"sideEffect.data.type"}},[_c('el-radio-button',{attrs:{"label":"string","border":""}},[_vm._v("Text")]),_c('el-radio-button',{attrs:{"label":"number","border":""}},[_vm._v("Number")]),_c('el-radio-button',{attrs:{"label":"boolean","border":""}},[_vm._v("True/False")]),_c('el-radio-button',{attrs:{"label":"null","border":""}},[_vm._v("Value from user")])],1)],1),(
      _vm.sideEffect.data.type === 'string' &&
      typeof _vm.sideEffect.data.value === 'string'
    )?_c('div',{staticClass:"el-input el-input--mini el-input-group el-input-group--prepend",staticStyle:{"width":"60%","margin-bottom":"10px"}},[_c('div',{staticClass:"el-input-group__prepend"},[_vm._v("Value")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value"}],staticClass:"el-input__inner",attrs:{"autocomplete":"off","placeholder":"Input value"},domProps:{"value":(_vm.sideEffect.data.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sideEffect.data, "value", $event.target.value)}}})]):_vm._e(),(
      _vm.sideEffect.data.type === 'number' &&
      typeof _vm.sideEffect.data.value === 'number'
    )?_c('div',{staticClass:"el-input el-input--mini el-input-group el-input-group--prepend",staticStyle:{"width":"60%","margin-bottom":"10px"}},[_c('div',{staticClass:"el-input-group__prepend"},[_vm._v("Value")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value",modifiers:{"number":true}}],staticClass:"el-input__inner",attrs:{"type":"number","autocomplete":"off","placeholder":"Input value"},domProps:{"value":(_vm.sideEffect.data.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sideEffect.data, "value", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),(
      _vm.sideEffect.data.type === 'boolean' &&
      typeof _vm.sideEffect.data.value === 'boolean'
    )?_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value"}],attrs:{"type":"radio","name":"type-boolean"},domProps:{"value":true,"checked":_vm._q(_vm.sideEffect.data.value,true)},on:{"change":function($event){return _vm.$set(_vm.sideEffect.data, "value", true)}}}),_c('label',{staticStyle:{"margin-right":"20px"}},[_vm._v("True")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value"}],attrs:{"type":"radio","name":"type-boolean"},domProps:{"value":false,"checked":_vm._q(_vm.sideEffect.data.value,false)},on:{"change":function($event){return _vm.$set(_vm.sideEffect.data, "value", false)}}}),_c('label',[_vm._v("False")]),_c('br')]):_vm._e(),_c('el-autocomplete',{staticStyle:{"width":"60%"},attrs:{"fetch-suggestions":_vm.getContentNodes,"placeholder":"Select target node","size":"mini"},model:{value:(_vm.sideEffect.data.next),callback:function ($$v) {_vm.$set(_vm.sideEffect.data, "next", $$v)},expression:"sideEffect.data.next"}},[_c('div',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("Next")]),_c('el-button',{attrs:{"slot":"append","icon":"el-icon-d-arrow-right"},on:{"click":function($event){return _vm.selectNode({ type: 'content', id: _vm.valueData.next })}},slot:"append"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { EditorState } from "./types";
import _ from "lodash";
import { flowSectionsTitle, formatFlowSection } from "@/helperMethods/editor";

const getters: GetterTree<EditorState, RootState> = {
  updateContentNode(state, getters, rootState) {
    return state.updateContentNode;
  },
  refetchContentNodes(state, getters, rootState) {
    return state.refetchContentNodes;
  },
  flowSectionsTitle(state, getters, rootState) {
    return flowSectionsTitle(rootState.floweditor.flows);
  },
  flowSectionsByFirstQuestionId(state, getters, rootState) {
    const flowSections = flowSectionsTitle(rootState.floweditor.flows);
    const formattedToQuestionIdArray = formatFlowSection(flowSections);
    return formattedToQuestionIdArray;
  },
  triggerDiff(state, getters, rootState) {
    return state.triggerDiff;
  },
};

export default getters;

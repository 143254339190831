export const TYPES = [
  "string",
  "number",
  "datetime",
  "list",
  "array",
  "currency",
];

export const EXTRAS = ["filters", "bulkupdate_fields", "authorization"];

export const META = {
  title: null,
  type: null,
  description: null,
  fields: [],
  extras: {},
};

export const TYPE_CURRENCY = {
  dataType: "currency",
  displayValue: null,
  stateRefKey: null,
  columnWidth: "115px",
  visible: false,
  editable: false,
  multiline: false,
  ceiling: 0,
  aggregator: null,
  prefix: null,
};

export const TYPE_ARRAY = {
  dataType: "array",
  displayValue: null,
  stateRefKey: null,
  tableHeaders: {},
  tableAggregations: {},
};

export const TYPE_STRING = {
  dataType: "string",
  displayValue: null,
  stateRefKey: null,
  columnWidth: "115px",
  visible: false,
  editable: false,
  multiline: false,
};

export const TYPE_LIST = {
  dataType: "list",
  displayValue: null,
  stateRefKey: null,
  columnWidth: "115px",
  visible: false,
  editable: false,
  multiselect: false,
  closable: false,
  listValues: [],
};

export const TYPE_NUMBER = {
  dataType: "number",
  displayValue: null,
  stateRefKey: null,
  visible: false,
  editable: false,
  columnWidth: "115px",
  min: 0,
  max: 1,
};

export const TYPE_DATETIME = {
  dataType: "datetime",
  displayValue: null,
  stateRefKey: null,
  format: "dd/mm/yyyy",
  columnWidth: "115px",
  visible: false,
  editable: false,
};

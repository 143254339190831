import _ from "lodash";
import { mapGetters } from "vuex";

export const faqDatasetMixin = {
  computed: {
    datasetFromState() {
      const component = this as any;
      const _dataset = _.get(component.$store.state, "training.dataset", []);
      _.forEach(_dataset, (intent) => {
        const topics = _.get(intent, "topics", ["general"]);

        if (_.isEmpty(topics)) {
          topics.push("general");
        }

        _.assign(intent, {
          topics,
        });
      });
      return _dataset;
    },
  },
  methods: {
    getIntentByIdentity(identity) {
      const component = this as any;
      const datasets = component.datasetFromState;
      const index = datasets.findIndex(
        (intent) => intent.id === identity || intent.intentName === identity.toUpperCase()
      );
      return {
        index,
        pair: datasets[index],
      };
    },
    getIntentVariation({ pair: intentObj, intentIdentity, id, text }) {
      const component = this as any;
      if (!intentObj) {
        ({ pair: intentObj } = component.getIntentByIdentity(intentIdentity));
      }
      const variations = _.get(intentObj, "variations", []);
      const index = variations.findIndex((variation) => {
        if (id) {
          return variation.id === id;
        }
        if (text) {
          return variation.text === text;
        }
      });
      return {
        index,
        pair: variations[index],
      };
    },
  },
};

export const faqConflictedMixin = {
  computed: {
    ...mapGetters(["getConflictDifferenceThreshold"]),
  },
  methods: {
    isPotentiallyConflicted(intent_ranking) {
      const component = this as any;
      const firstIntentConfidence = intent_ranking[0].confidence;
      const secondIntentName = _.get(intent_ranking, "[1].name", "");
      if (!secondIntentName) {
        return false;
      }
      const secondIntentConfidence = intent_ranking[1].confidence;
      const differentialConfidence =
        (firstIntentConfidence - secondIntentConfidence) / firstIntentConfidence;
      // confidence difference between first and second intent is greater than 50%: safe
      if (differentialConfidence > component.getConflictDifferenceThreshold) {
        return false;
      } else {
        return true;
      }
    },
    isConflicted(intent) {
      const component = this as any;
      const intentName = _.get(intent, "intentName", "");
      const variations = _.get(intent, "variations", []);
      let conflicted = false;
      if (variations && variations.length === 0) {
        // if there is no variation, it is NOT conflicted. i.e false
        return false;
      }

      variations.map((variation) => {
        const intentRanking = _.get(variation, "intent_ranking", "");
        const noIntentRanking = !intentRanking || intentRanking.length <= 0;
        if (noIntentRanking) {
          return;
        }

        // Variations should have intent_rankings else its technically not a conflict. It is just not tested.
        const firstIntentName = _.get(intentRanking, "[0].name", "");
        const intentIsNotFirstIntentRank =
          firstIntentName.toUpperCase() !== intentName.toUpperCase();
        if (intentIsNotFirstIntentRank) {
          conflicted = true;
          return;
        }

        const firstIntentConfidence = _.get(intentRanking, "[0].confidence", 0);
        const secondIntentName = _.get(intentRanking, "[1].name", "");
        if (!secondIntentName) {
          return;
        }

        const secondIntentConfidence = _.get(intentRanking, "[1].confidence", 0);

        const differentialConfidence =
          (firstIntentConfidence - secondIntentConfidence) / firstIntentConfidence;
        const confidenceTooClose =
          differentialConfidence < component.getConflictDifferenceThreshold;
        if (confidenceTooClose) {
          // difference in score for first intent & second intent < 0.5 (Intent is too close)
          conflicted = true;
        }
      });
      return conflicted;
    },
  },
};

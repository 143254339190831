<template>
  <el-card style="height: 80vh; overflow: auto" class="api-detail-editor-panel">
    <template v-if="api">
      <!-- Flow Header -->
      <div
        slot="header"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <strong>{{ api.name }}</strong>

        <div>
          <el-button
            icon="el-icon-check"
            size="mini"
            type="success"
            plain
            :loading="saving"
            @click="saveApiChanges(activeApi)"
            >Save Changes</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-delete"
            @click="deleteApi"
            >Delete</el-button
          >
        </div>
      </div>

      <!-- Default settings -->
      <el-form
        ref="apiDetails"
        :rules="rules"
        label-position="left"
        :model="api"
      >
        <!-- API URL and Method Input Section -->

        <el-form-item
          show-message
          :error="oldSyntaxErrorMessage"
          style="margin-bottom: 30px"
        >
          <el-input
            placeholder="Type in url"
            v-model="api.url"
            class="input-with-select"
            size="mini"
          >
            <el-button
              @click="onBeforePreview"
              size="mini"
              style="background-color: #409eff; color: white"
              slot="append"
              :loading="saving"
              >Send</el-button
            >

            <el-select
              size="mini"
              v-model="api.method"
              slot="prepend"
              placeholder="Select"
              style="width: 100px"
            >
              <el-option label="GET" value="GET"></el-option>
              <el-option label="POST" value="POST"></el-option>
              <el-option label="PUT" value="PUT"></el-option>
              <el-option label="DELETE" value="DELETE"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-tabs type="border-card" :span="24">
          <el-tab-pane label="Authorization" :lazy="true">
            <!-- Authorization tab -->
            <RequestAuthorization :auth="api.auth" />
          </el-tab-pane>

          <el-tab-pane label="Headers" :lazy="true">
            <!-- Headers tab -->
            <RequestHeaders
              :headers="api.headers"
              @addHeader="addHeader"
              @removeHeader="removeHeader"
            />
          </el-tab-pane>

          <el-tab-pane label="Body" :lazy="true">
            <el-form-item>
              <el-input
                type="textarea"
                :rows="5"
                placeholder="Input body"
                v-model="api.body"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <br />

        <el-tabs>
          <el-tab-pane label="Fields" :lazy="true">
            <label
              style="line-height: 1; margin-bottom: 15px; text-align: left"
            >
              Data Transformation<br />
              <small
                >Learn more about how data transformation happened here
                <a
                  style="color: #e4392b"
                  target="_blank"
                  href="https://jmespath.org/examples.html"
                  >https://jmespath.org/examples.html</a
                ></small
              >
            </label>
            <el-input
              style="margin-bottom: 20px; margin-top: 10px"
              placeholder="Input search expression here"
              v-model="api.rootPath"
              :span="12"
              size="mini"
              type="textarea"
              autosize=""
            >
            </el-input>
            <!-- <el-form-item>
              <div
                slot="label"
                style="line-height: 1; margin-bottom: 15px; text-align: left"
              >
                Data Transformation<br />
                <small
                  >Learn more about the how data transformation happened here
                  <a href="https://jmespath.org/examples.html">https://jmespath.org/examples.html</a></small
                >
              </div>
              
            </el-form-item> -->
            <APIFields
              :fields="api.fields"
              @addField="addField"
              @removeField="removeField"
            />
            <br />
            <APIErrorFields
              :fields="api.errorFields"
              v-on:add-error-field="addErrorField"
              v-on:remove-error-field="removeErrorField"
            />
          </el-tab-pane>

          <el-tab-pane label="Web Scraper" :lazy="true">
            <a href="https://www.npmjs.com/package/scrape-it" target="_blank">
              <el-tag size="small" type="info">Web Scraping Guide</el-tag>
            </a>
            <br />
            <JSONEditor v-model="api.scrapeOpts" />
          </el-tab-pane>

          <el-tab-pane label="Pagination" :lazy="true">
            <el-row :gutter="20" style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item>
                  <el-input
                    placeholder="Input the page total"
                    v-model="api.pageTotal"
                    label="Page Total"
                    size="mini"
                    :span="12"
                  >
                    <div slot="prepend">Page Total</div>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Page Start">
                  <el-input-number
                    v-model="api.pageStart"
                    :min="0"
                    :max="1"
                    size="mini"
                    :span="6"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Page Size">
                  <el-input-number
                    v-model="api.pageSize"
                    :min="1"
                    size="mini"
                    :max="10000"
                    :span="12"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Page Increment">
                  <el-input-number
                    v-model="api.pageIncrement"
                    :min="1"
                    :max="10000"
                    size="mini"
                    :span="6"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="Pagination Node Language">
                  <el-select
                    size="mini"
                    v-model="api.lang"
                    placeholder="Select language"
                  >
                    <el-option label="English" value="en"></el-option>
                    <el-option label="Bahasa Indonesia" value="id"></el-option>
                    <el-option label="Bahasa Melayu" value="my"></el-option>
                    <el-option
                      label="Chinese (Simplified)"
                      value="ch"
                    ></el-option>
                    <el-option
                      label="Bahasa Indonesia (ERHA Version)"
                      value="erha_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane label="Mappings">
            <Mappings :api="api" />
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <el-dialog
        title="Data Preview"
        :visible.sync="previewDialogVisible"
        width="70%"
        center
      >
        <PreviewModalContent :previewData="apiPreviewData" />
        <span slot="footer" class="dialog-footer">
          <el-button @click="previewDialogVisible = false">Close</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="Test Data"
        :visible.sync="tokenExtractorVisible"
        width="70%"
        center
      >
        <span v-if="!tokenExtractorInJsonMode">
          <TokenExtractor :tokens="tokensMap" />
        </span>
        <JSONEditor v-else v-model="tokensMapJson" />
        <div style="text-align: center; margin-top: 20px">
          <el-checkbox v-model="tokenExtractorInJsonMode"
            >JSON Mode</el-checkbox
          >
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="generateUrlForPreview"
            >OK</el-button
          >
          <el-button @click="tokenExtractorVisible = false" size="mini"
            >Cancel</el-button
          >
        </span>
      </el-dialog>
    </template>
    <template v-else>
      <h1 style="text-align: center">
        Select an API setting to begin
        <br />
        <i
          class="el-icon-tickets"
          style="margin-top: 20px; font-size: 200px; color: #e6ebf5"
        />
      </h1>
    </template>
  </el-card>
</template>
<script>
import APIFields from "./Fields/Output";
import APIErrorFields from "./Fields/Error";
import TokenExtractor from "./TokenExtractor";
import JSONEditor from "@/components/JSONEditor";
import Mappings from "./Mappings";
import RequestHeaders from "./Request/Headers";
import RequestAuthorization from "./Request/Authorization";
import PreviewModalContent from "./PreviewModalContent";
import { mapTemplateVariablesIntoObj } from "./helpers";
export default {
  components: {
    RequestHeaders,
    RequestAuthorization,
    PreviewModalContent,
    APIFields,
    APIErrorFields,
    JSONEditor,
    TokenExtractor,
    Mappings,
  },
  props: ["api"],
  data() {
    return {
      tokenExtractorInJsonMode: false,
      saving: false,
      deleting: false,
      valid: true,
      tokensMap: {},
      tokensMapJson: {},
      apiPreviewData: {},
      previewDialogVisible: false,
      tokenExtractorVisible: false,
      rules: {
        url: [
          {
            required: true,
            message: "Please input Url",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    oldSyntaxErrorMessage() {
      const oldSyntax = _.includes(this.api.url + this.api.body, "${");
      return oldSyntax
        ? "Please update all templating format to {{ variable }}. Format ${state.variable} or ${ variable } no longer supported in this version."
        : null;
    },
  },
  methods: {
    addHeader() {
      const headerSet = {
        key: "",
        value: "",
      };
      if (!this.api.headers) {
        this.$set(this.api, "headers", []);
      }
      this.api.headers.push(headerSet);
    },
    removeHeader(index) {
      this.api.headers.splice(index, 1);
    },
    addField() {
      const fieldSet = {
        key: "",
        value: "",
      };
      if (!this.api.fields) {
        this.$set(this.api, "fields", []);
      }
      this.api.fields.push(fieldSet);
    },
    removeField(index) {
      this.api.fields.splice(index, 1);
    },
    addErrorField() {
      const fieldSet = {
        key: "",
        value: "",
      };
      if (!this.api.errorFields) {
        this.$set(this.api, "errorFields", []);
      }
      this.api.errorFields.push(fieldSet);
    },
    removeErrorField(index) {
      this.api.errorFields.splice(index, 1);
    },
    generateUrlForPreview() {
      let urlWithLatestToken = this.api.url;
      let bodyWithLatestToken = this.api.body;
      this.tokenExtractorVisible = false;
      this.showDataPreview(urlWithLatestToken, bodyWithLatestToken);
    },
    onBeforePreview() {
      if (this.oldSyntaxErrorMessage) {
        return;
      }
      const url = this.api.url;
      const body = this.api.body;

      if (!url) {
        this.$message({
          type: "info",
          message: "Api url must be specified!",
        });
        return;
      }

      this.tokensMap = mapTemplateVariablesIntoObj(
        this.api.url + this.api.body
      );

      if (!_.isEmpty(this.tokensMap)) {
        this.tokenExtractorVisible = true;
        return;
      }
      this.showDataPreview(url, body);
    },
    deleteApi() {
      this.$confirm(`Are you sure to delete this api?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      })
        .then(() => {
          this.deleting = true;
          this.$store
            .dispatch("DELETE_API", {
              apiName: this.api.name,
            })
            .then(() => {
              this.$notify.success({
                title: "Success",
                position: "bottom-right",
                message: `Successfully deleted api.`,
              });
              this.$emit("unselect");
              this.deleting = false;
            })
            .catch((err) => {
              console.log(err);
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: `Failed to delete api`,
              });
              this.deleting = false;
            });
        })
        .catch(() => {});
    },
    saveApiChanges() {
      this.saving = true;
      if (_.isEmpty(this.api)) {
        this.saving = false;
        return;
      }

      this.$store
        .dispatch("SAVE_API", {
          apiName: this.api.name,
          apiObject: this.api,
        })
        .then((saved) => {
          this.saving = false;

          if (saved) {
            this.$message({
              type: "success",
              message: "API saved!",
            });
          } else {
            this.$message({
              type: "error",
              message: "Encountered error saving API!",
            });
          }
        })
        .catch(() => {
          this.saving = false;

          this.$message({
            type: "error",
            message: "Encountered error saving API!",
          });
        });
    },
    showDataPreview(url, body) {
      const apiName = this.api.name;
      const testData = this.tokenExtractorInJsonMode
        ? this.tokensMapJson
        : this.tokensMap;
      this.saving = true;
      this.$store
        .dispatch("PREVIEW_API_DATA", {
          apiName: apiName,
          apiUrl: url,
          body: body,
          testData,
        })
        .then((data) => {
          this.saving = false;
          this.apiPreviewData = data;
          this.previewDialogVisible = true;
        })
        .catch(() => {
          this.saving = false;
          this.apiPreviewData = {};
          this.$message({
            type: "error",
            message: "Encountered error while previewing data!",
          });
        });
    },
  },
};
</script>

<template>
  <div class="quick-replies-wrapper">
    <el-popover
      :key="index"
      :disabled="!item.contentNode"
      v-for="(item, index) in quickReplies"
      placement="top"
      trigger="hover"
      width="250"
    >
      <Bubble
        v-if="item.contentNode"
        :quick-replies="item.contentNode.quickReplies || []"
        :value="getValue(item)"
      />
      <button slot="reference" @click="onClick(item)" class="quick-reply">
        {{ getText(item) }}
      </button>
    </el-popover>
  </div>
</template>

<script>
import _ from "lodash";
import gql from "graphql-tag";
import Bubble from "@/components/Bubble.vue";

export default {
  props: ["chatInteractions", "selectedChatUserId", "selectedChatPartitionKey"],

  components: { Bubble },

  data() {
    return {
      isBusy: false,
      lastQuery: null,
      quickReplies: [],
    };
  },

  computed: {
    /**
     * Check if watson is enabled in faq.
     *
     */
    isWatsonEnabled() {
      return (
        _.get(this.$store, "state.modules.faq.WatsonAssistant.enabled") || false
      );
    },

    /**
     * Get user last message
     *
     */
    lastUserMessage() {
      return _.get(
        _.last(
          this.chatInteractions.filter(
            (interaction) => interaction.type === "message"
          )
        ),
        "data.content[0].text"
      );
    },

    /**
     * Check if should call next query for quick replies
     *
     */
    shouldQuery() {
      return (
        !this.isBusy &&
        this.lastUserMessage &&
        this.lastUserMessage.length > 9 &&
        this.lastQuery !== this.lastUserMessage
      );
    },
  },
  watch: {
    lastUserMessage: {
      deep: true,
      handler() {
        this.onQuery();
      },
    },
  },
  methods: {
    getValue(item) {
      return Array.isArray(item.contentNode.content)
        ? item.contentNode.content[0]
        : item.contentNode.content;
    },

    /**
     * Get label for content name
     *
     */
    getText(item) {
      let text = "no preview";
      switch (item.action.event) {
        case "goto":
          if (item.contentNode) {
            text = _.get(
              item,
              "contentNode.content.text",
              _.get(item, "contentNode.content[0].text")
            );
            text = Array.isArray(text) ? _.first(text) : text;
          }
          break;

        case "callApi":
          text = `${item.action.event}: ${_.get(
            item,
            "action.data.mappingName",
            ""
          )}`;
          break;

        default:
          text = item.action.event;
      }

      return text;
    },

    /**
     * On quick reply click event
     *
     */
    async onClick(item) {
      if (!this.isBusy) {
        try {
          this.isBusy = true;
          await this.$apollo.mutate({
            mutation: gql`
              mutation (
                $RowKey: String!
                $PartitionKey: String!
                $headerNode: String!
                $action: JSON!
              ) {
                livechatAPI {
                  sendQuickReply(
                    RowKey: $RowKey
                    PartitionKey: $PartitionKey
                    headerNode: $headerNode
                    action: $action
                  )
                }
              }
            `,
            variables: {
              RowKey: this.selectedChatUserId,
              PartitionKey: this.selectedChatPartitionKey,
              action: item.action,
              headerNode:
                _.get(
                  this.$store.state,
                  "modules.system.livechatQuickReplies.responseNodeHeaderNode"
                ) || "",
            },
          });
        } catch (error) {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error sending quick reply message.`,
          });
        } finally {
          this.isBusy = false;
        }
      }
    },

    /**
     * Query for possible quick replies
     *
     */
    async onQuery() {
      try {
        if (this.shouldQuery) {
          this.quickReplies = [];
          this.isBusy = true;
          this.lastQuery = this.lastUserMessage;
          const { data } = await this.$apollo.query({
            query: gql`
              query (
                $RowKey: String!
                $PartitionKey: String!
                $question: String
              ) {
                livechatAPI {
                  getQuickReplies(
                    RowKey: $RowKey
                    PartitionKey: $PartitionKey
                    question: $question
                  )
                }
              }
            `,
            variables: {
              RowKey: this.selectedChatUserId,
              PartitionKey: this.selectedChatPartitionKey,
              question: this.lastUserMessage,
            },
            fetchPolicy: "no-cache",
          });

          this.afterQuery(_.get(data, "livechatAPI.getQuickReplies") || []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    /**
     * After query for suggestion
     *
     */
    afterQuery(response) {
      if (this.isWatsonEnabled) {
        this.quickReplies = (response || []).map((item) => {
          const contentNode = this.$store.state.nodes.content[item.data];
          return {
            action: item,
            contentNode,
          };
        });
      } else {
        this.quickReplies = (response || []).map((item) => {
          let selectedContentNode;
          const actionEvent = _.get(item, "action.event");
          switch (actionEvent) {
            case "goto":
              {
                const contentNodeID = _.get(item, "action.data");
                selectedContentNode =
                  this.$store.state.nodes.content[contentNodeID];
              }
              break;
            case "capture":
              {
                const { next } = _.get(item, "action.data", {});
                if (next && next.constructor === Object) {
                  const { event, data } = next;
                  if (event === "goto" && data) {
                    selectedContentNode = this.$store.state.nodes.content[data];
                  }
                } else if (next) {
                  selectedContentNode = this.$store.state.nodes.content[next];
                }
              }
              break;
            // The other events do not need to show the content node.
          }
          return {
            ...item,
            contentNode: selectedContentNode || null,
          };
        });
      }
    },
  },
};
</script>

<style scoped>
.quick-replies-wrapper {
  position: absolute;
  display: flex;
  top: -50%;
  width: 100%;
  justify-content: center;
}
.quick-reply {
  cursor: pointer;
  z-index: 999;
  margin: 0 5px 0 0;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

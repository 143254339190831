<template>
  <el-popover
    placement="bottom"
    title="Add New Question"
    width="400"
    trigger="click"
    v-model="isCreatePopoverOpen"
  >
    <el-button-group>
      <el-button plain size="mini" @click.prevent="onAdd('string')">Short Answer</el-button>
      <el-button plain size="mini" @click.prevent="onAdd('email')">Email</el-button>
      <el-button plain size="mini" @click.prevent="onAdd('phone')">Phone</el-button>
      <el-button plain size="mini" @click.prevent="onAdd('multipleChoice')">Multiple Choice</el-button>
    </el-button-group>
    <el-button
      plain
      style="margin-left: 15px;"
      size="mini"
      type="primary"
      icon="el-icon-plus"
      slot="reference"
    >Add Question</el-button>
  </el-popover>
</template>

<script>
export default {
  props: ["activeFlow", "section"],
  data() {
    return {
      isCreatePopoverOpen: false
    };
  },
  methods: {
    async onAdd(type) {
      try {
        const question = await this.$store.dispatch("CREATE_NEW_QUESTION", {
          section: this.section,
          newQuestionType: type
        });
        this.section.questions.push(question);
        this.$nextTick(() => {
          this.isCreatePopoverOpen = false;
          this.$eventBus.$emit("flowEditorEdit", this.section);
        });
      } catch (error) {
        this.$message({
          type: "error",
          message: "Encountered error creating new question."
        });
      }
    }
  }
};
</script>

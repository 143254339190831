<template>
  <div>
    <el-card class="right-pane-card">
      <el-row>
        <el-col>
          <el-button
            v-if="$store.state.faq.arrayOfSelectedMessages.length > 0"
            size="mini"
            icon="el-icon-delete"
            circle
            type="danger"
            style="margin-left: 16px"
            v-loading="archiveLoading"
            @click="archiveSelectedMessages"
          />

          <span v-if="!isPredicted">
            TOTAL:
            <el-badge :value="totalData" class="badge-def" :max="99" />
          </span>
          <el-button
            v-loading="evaluatingAIModule"
            type="primary"
            plain
            size="mini"
            style="float: right"
            @click="autoClassify"
            >Evaluate with latest version</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-select v-model="filterSource" placeholder="Filter by Source" @change="finalPagedData">
          <el-option v-for="source in listSources" :key="source.key" :label="source.label" :value="source.value" />
        </el-select>
      </el-row>
      <el-row>
        <el-col>
          <el-table
            @selection-change="handleSelectionChange"
            v-loading="backendSearchLoading"
            :data="pagedData"
            size="mini"
            style="margin-bottom: 20px"
            :height="500"
            :default-sort="{ prop: 'RowKey', order: 'descending' }"
            empty-text="Select a intent on the left to start."
            row-key="RowKey"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true"></el-table-column>
            <el-table-column min-width="180" label="Unclassified messages" header-align="center">
              <template slot-scope="scope">
                <el-tooltip>
                  <template slot="content">
                    {{ scope.row.RowKey }} |
                    {{ formatDate(scope.row.createdDate) }}
                  </template>
                  <span style="word-break: normal; white-space: normal; height: auto">{{ scope.row.question }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column min-width="150" label="Language" header-align="center" align="center">
              <template slot-scope="scope">
                <span style="word-break: normal; white-space: normal; height: auto">{{
                  scope.row.formatted_detected_language || ""
                }}</span>
              </template>
            </el-table-column>

            <template v-if="isPredicted">
              <!-- Prediction -->
              <el-table-column
                min-width="230"
                label="Prediction"
                prop="prediction"
                header-align="center"
                align="center"
                v-if="!isTrainingDatasetPage"
              >
                <template slot-scope="scope">
                  <el-tag
                    v-if="previewAutoClassify[scope.$index]"
                    style="word-break: normal; white-space: normal; height: auto"
                  >
                    {{ findQuestion(previewAutoClassify[scope.$index].prediction) }}
                  </el-tag>

                  <el-tag v-else style="word-break: normal; white-space: normal; height: auto">{{
                    findQuestion(scope.row.prediction)
                  }}</el-tag>
                </template>
              </el-table-column>

              <el-table-column min-width="100" label="Confidence" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-tag v-if="previewAutoClassify[scope.$index]" :type="classificationClassType(scope.$index)">{{
                    classificationScore(scope.$index)
                  }}</el-tag>
                  <el-tag
                    v-else-if="scope.row.probability"
                    :type="defaultClassificationClassType(scope.row.probability)"
                  >
                    {{ defaultClassificationScore(scope.row.probability) }}
                  </el-tag>
                </template>
              </el-table-column>
            </template>

            <el-table-column
              min-width="100"
              label="Source"
              header-align="center"
              align="center"
              prop="appSource"
              sortable
            >
            </el-table-column>
            <!-- Conversation Data variations Actions column -->
            <el-table-column label="Actions" min-width="250" header-align="center" align="center">
              <template slot-scope="scope">
                <div v-if="enableClassification === scope.row.RowKey" style="margin-top: 4px">
                  <el-autocomplete
                    :fetch-suggestions="querySearch"
                    v-model="chosenQuestion"
                    @input="clearChosenQuestion"
                    @select="chooseQuestion"
                    prefix-icon="el-icon-search"
                    placeholder="Classify as FAQ"
                  >
                    <el-button
                      v-if="chosenQuestionId"
                      @click="confirmIntent"
                      icon="el-icon-check"
                      slot="append"
                    ></el-button>
                    <el-button v-else @click="enableClassification = ''" icon="el-icon-close" slot="append"></el-button>
                  </el-autocomplete>
                </div>
                <div v-else>
                  <el-button-group>
                    <el-tooltip v-if="isPredicted" content="Accept" placement="top">
                      <el-button icon="el-icon-check" size="mini" @click="markAsCorrect(scope)"> </el-button>
                    </el-tooltip>

                    <el-tooltip content="Reject" placement="top">
                      <el-button icon="el-icon-close" size="mini" @click="markAsDeleted(scope.row)"> </el-button>
                    </el-tooltip>

                    <el-tooltip content="Classify" placement="top">
                      <el-button
                        icon="el-icon-refresh"
                        size="mini"
                        @click="
                          enableClassification = scope.row.RowKey;
                          candidate = scope.row;
                        "
                      >
                      </el-button>
                    </el-tooltip>

                    <el-tooltip content="Add As New Intent" placement="top">
                      <el-button icon="el-icon-plus" size="mini" @click="createNewFAQ(scope.row)"> </el-button>
                    </el-tooltip>
                  </el-button-group>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <!-- Pagination -->
      <el-row :gutter="10">
        <el-pagination
          background
          small
          layout="prev, pager, next"
          :pageSize="pageSize"
          :current-page.sync="currentPage"
          :total="totalData"
          @prev-click="togglePage"
          @next-click="togglePage"
          @current-change="togglePage"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-row>
      <el-row v-if="$store.state.showAdvanced">
        <el-col>
          <JSONEditor v-model="filteredUnclassifiedTrainingData" />
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";
import JSONEditor from "@/components/JSONEditor";

export default Vue.extend({
  components: { JSONEditor },
  props: [
    "filteredUnclassifiedTrainingData",
    "autoClassifyThreshold",
    "dataset",
    "isTrainingDatasetPage",
    "isPredicted",
  ],
  data() {
    return {
      enableClassification: false,
      chosenQuestionId: "",
      chosenQuestion: "",
      candidate: {},
      evaluatingAIModule: false,
      archiveLoading: false,
      // Pagination
      pageSize: 15,
      currentPage: 1,
      filterSource: "",
      pagedData: [],
      totalData: 0,
    };
  },
  mounted() {
    this.pagedData = this.slicingData();
    this.totalData = this.filteredUnclassifiedTrainingData.length;
  },
  computed: {
    backendSearchLoading() {
      return _.get(this, "$store.state.faq.backendSearchLoading", false);
    },
    previewAutoClassify: {
      get() {
        return _.get(this, "$store.state.faq.previewAutoClassify", []);
      },
      set(value) {
        this.$set(this.$store.state.faq, "previewAutoClassify", value);
      },
    },
    listSources() {
      return _.chain(this.filteredUnclassifiedTrainingData)
        .uniqBy("appSource")
        .map((obj, i) => {
          return {
            key: i + 1,
            label: !obj.appSource ? "All" : obj.appSource,
            value: !obj.appSource ? "" : obj.appSource,
          };
        })
        .value();
    },
  },
  methods: {
    markAsDeleted(row) {
      this.archiveLoading = true;
      this.$store.dispatch("ARCHIVE_TRAINING_RECORD", row).then(() => {
        this.archiveLoading = false;
        this.$message({
          type: "success",
          message: "Successfully archived training records",
        });
      });
    },
    archiveSelectedMessages() {
      const arrayOfMessages = _.get(this, "$store.state.faq.arrayOfSelectedMessages", []);
      this.$store.dispatch("ARCHIVE_ARRAY_TRAINING_RECORD", arrayOfMessages).then(() => {
        this.$message({
          type: "success",
          message: "Successfully archived training records",
        });
      });
    },
    handleSelectionChange(newArray) {
      this.$set(this.$store.state.faq, "arrayOfSelectedMessages", newArray);
    },
    executeAutoClassify() {
      const previewAutoClassifyHasMessages = this.previewAutoClassify.length > 0;
      if (previewAutoClassifyHasMessages) {
        let confident = 0;
        let notConfident = 0;

        let toDelete = [];
        this.previewAutoClassify.forEach((row, index) => {
          if (row.probability > this.autoClassifyThreshold) {
            confident++;

            toDelete.push(this.filteredUnclassifiedTrainingData[index]);
            this.newVariation({ id: row.prediction, text: row.question }, false);
          } else {
            notConfident++;
          }
        });

        toDelete.forEach((item) => this.markAsDeleted(item));
      } else {
        this.$message({
          type: "warning",
          message: "No utterance to add",
        });
      }
    },
    autoClassify() {
      //add loader after click on evaluate AI button
      this.evaluatingAIModule = true;
      let rows = this.filteredUnclassifiedTrainingData;
      if (rows.length === 0) {
        this.$message({
          type: "warning",
          message: "No utterance to evaluate",
        });
        this.evaluatingAIModule = false;
      } else {
        let intents = rows.map((intent) => intent.question);
        this.$store
          .dispatch("TEST_FAQ", { questions: intents })
          .then((response) => {
            const previewAutoClassify = response.map((row) => {
              if (row.predictions && row.predictions.length > 0) {
                // Alexandria
                row.prediction = row.predictions[0].label;
                row.probability = row.predictions[0].confidence;
              } else if (row.predicted && row.confidence) {
                // Rasa
                row.prediction = row.predicted;
                row.probability = row.confidence;
              } else {
                row.prediction = "";
                row.probability = 0;
              }

              return row;
            });

            this.$set(this.$store.state.faq, "previewAutoClassify", previewAutoClassify);

            //stop loader of evaluate AI button
            this.evaluatingAIModule = false;
            this.$message({
              type: "success",
              message: "Successfully tested FAQ",
            });
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: "Encountered error trying to test FAQ",
            });
            if (this.$store.state.showAdvanced) {
              this.$notify({
                type: "error",
                message: err.message,
              });
            }
            this.evaluatingAIModule = false;
          });
      }
    },
    setSelectedIntent(intentName) {
      this.$store.commit("SET_ACTIVE_TAB_INDEX", "1");
    },
    findQuestion(id) {
      // Search by id or intentName
      if (!id) {
        return;
      }
      let pair = _.find(this.dataset, { id: id }) || _.find(this.dataset, { intentName: id.toUpperCase() });

      if (pair) {
        return pair.intentName;
      } else {
        return id;
      }
    },
    markAsCorrect(scope) {
      const { $index: trainingRowIndex, row: trainingRow } = scope;
      const predictionTemp = this.previewAutoClassify[trainingRowIndex];
      let prediction = (predictionTemp && predictionTemp.prediction) || trainingRow.prediction;
      if (!(predictionTemp || prediction) || !trainingRow.prediction) {
        this.$message({
          type: "warning",
          message: "Please evaluate the question with the latest AI model first.",
        });
        return;
      }
      this.candidate = trainingRow;
      this.chosenQuestionId = prediction;

      this.confirmIntent();
    },
    confirmIntent() {
      // Candidate is training row.
      this.$emit("newVariation", {
        id: this.chosenQuestionId,
        text: this.candidate.question,
      });

      this.$store
        .dispatch("ARCHIVE_TRAINING_RECORD", this.candidate)
        .then(() => {
          this.enableClassification = false;

          this.expandedRows = [this.chosenQuestionId];
          this.chosenQuestionId = "";
          this.chosenQuestion = "";
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error archiving training records",
          });
        });
    },
    chooseQuestion(item) {
      this.chosenQuestionId = item.id;
      this.chosenQuestion = item.value;
    },
    clearChosenQuestion() {
      this.chosenQuestionId = "";
    },
    querySearch(queryString, cb) {
      let results = [];

      if (queryString) {
        results = this.dataset.filter((data) => {
          const intentName = _.get(data, "intentName", "");
          return intentName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0;
        });
      } else {
        results = this.dataset;
      }

      cb(
        results.map((data) => {
          return {
            value: data.intentName,
            id: data.id,
          };
        })
      );
    },
    defaultClassificationClassType(probability = 0) {
      if (probability > this.autoClassifyThreshold) {
        return "success";
      } else {
        return "danger";
      }
    },
    classificationClassType(index) {
      if (this.previewAutoClassify[index] && this.previewAutoClassify[index].probability > this.autoClassifyThreshold) {
        return "success";
      } else {
        return "danger";
      }
    },
    defaultClassificationScore(probability = "0") {
      if (probability) {
        let content = probability > this.autoClassifyThreshold ? "Yes" : "No";
        content += ` (${Math.min(probability.toFixed(2), 1)})`;
        return content;
      }
    },
    classificationScore(index) {
      let content = this.previewAutoClassify[index].probability > this.autoClassifyThreshold ? "Yes" : "No";
      content += `(${Math.min(this.previewAutoClassify[index].probability.toFixed(2), 1)})`;
      return content;
    },
    formatDate(timestamp) {
      return moment(timestamp).local().format("DD-MM-YYYY HH:mm:ss");
    },
    createNewFAQ(row) {
      this.$prompt("", "What is the new Intent Name?", {
        inputPattern: /^[A-Z0-9_-]+$/,
        inputErrorMessage: "Invalid Intent Name. Only UPPERCASE letters, numbers, and underscores are allowed.",
        inputPlaceholder: "eg. CHECK_ACCOUNT_BALANCE",
      })
        .then(({ value }) => {
          value = value.trim();
          console.log("Creating Intent: " + value);
          if (value.length === 0) {
            this.$notify.warn({
              title: "Cannot create intent",
              message: "Invalid Intent Name",
              position: "bottom-right",
            });
            return;
          }

          const newLabel = {
            id: uuid(),
            intentName: value.toUpperCase(),
            question: value.toUpperCase(),
            intent: value.toUpperCase(),
            action: { data: "", event: "" },
            variations: [
              {
                text: row.question,
                language: "en",
              },
            ],
          };

          this.$store.dispatch("ADD_FAQ_LABEL", newLabel).then(() => {
            this.$notify.success({
              title: "Created new intent",
              message: `Success! Message added as new Intent: ${value}`,
              position: "bottom-right",
            });
          });

          if (row) {
            this.$store.dispatch("ARCHIVE_TRAINING_RECORD", row);
          }
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            message: `Error creating new Intent ${error.message}`,
            position: "bottom-right",
          });
        });
    },
    // Pagination
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.finalPagedData();
    },
    handleSizeChange(size) {
      this.pageSize = size;
    },
    finalPagedData() {
      if (this.filterSource !== "") {
        const filtered = this.filteredUnclassifiedTrainingData.filter((msg) => msg.appSource === this.filterSource);
        this.pagedData = this.slicingData(filtered);
      } else {
        this.pagedData = this.slicingData();
      }
    },
    slicingData(resultData = null) {
      // filteredUnclassifiedTrainingData - Paged
      const startingIndex = (this.currentPage - 1) * this.pageSize;
      const currentIndex = this.currentPage * this.pageSize;

      if (_.isNull(resultData)) {
        ({ filteredUnclassifiedTrainingData: resultData } = this);
      }
      this.totalData = resultData.length;
      if (this.totalData === 0) {
        return [];
      }

      let endingIndex = this.totalData;
      if (this.totalData >= currentIndex) {
        endingIndex = currentIndex;
      }

      const res = resultData.slice(startingIndex, endingIndex);
      return res;
    },
  },
  watch: {
    filteredUnclassifiedTrainingData() {
      this.finalPagedData();
    },
  },
});
</script>

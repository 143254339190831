var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"el-fade-in"}},[_c('div',{class:{
      'has-text-left': _vm.isUserMessage,
      'has-text-right': !_vm.isUserMessage,
    }},[(_vm.isTextMessage(_vm.message) && !_vm.isMultipleTextMessage(_vm.message))?[(
          _vm.getTextFromMessage(_vm.message) &&
          _vm.getTextFromMessage(_vm.message).length > 0
        )?_c('MessageBubble',{attrs:{"message":_vm.message,"is-user-message":_vm.isUserMessage,"button-outline":_vm.buttonOutline,"content":_vm.getTextFromMessage(_vm.message),"buttons":_vm.getButtonsFromMessage(_vm.message),"has-more-than-one-agent-in-chat":_vm.hasMoreThanOneAgentInChat,"is-preview":_vm.isPreview},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}}):_vm._e()]:(
        _vm.message.data.content &&
        _vm.message.data.content.length === 1 &&
        _vm.isImageOrFileOrVideoMessage(_vm.message)
      )?_c('MessageFile',{attrs:{"message":_vm.message,"is-user-message":_vm.isUserMessage,"button-outline":_vm.buttonOutline},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}}):(_vm.message.data.listStyle !== 'compact')?_c('MessageCarousel',{attrs:{"content":_vm.getMultipleTextMessageContent(_vm.message),"is-preview":_vm.isPreview},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}}):_c('MessageCompact',{attrs:{"content":_vm.getMultipleTextMessageContent(_vm.message)},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}}),(
        _vm.getQuickRepliesFromMessage(_vm.message) &&
        _vm.getQuickRepliesFromMessage(_vm.message).length > 0
      )?_c('MessageQuickReplies',{attrs:{"content":_vm.getQuickRepliesFromMessage(_vm.message),"button-outline":_vm.buttonOutline,"is-preview":_vm.isPreview},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div style="height: 100%">
    <!-- Conversational Data row of buttons -->
    <el-row :gutter="4">
      <el-col :span="10">
        <SearchMessagesBar
          placeholder="Search through all unclassified messages"
        />
      </el-col>
      <el-col :span="4">
        <el-tooltip content="Export as CSV" placement="top">
          <el-button
            class="exportAddNewBtn importExportBtn"
            plain
            @click="exportConversationalData"
          >
            <img class="importBtnIcon" src="img/icons/imp-exp/exp2.png" />
          </el-button>
        </el-tooltip>
      </el-col>
    </el-row>

    <br />

    <el-row v-loading="isBusy">
      <el-col>
        <!-- Conversational Data Message Confident Action column -->
        <SelectedConversationalData
          :filtered-unclassified-training-data="
            filteredUnclassifiedTrainingData
          "
          :auto-classify-threshold="autoClassifyThreshold"
          :dataset="dataset"
          @newVariation="newVariation"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import XLSX from "xlsx";
import SearchMessagesBar from "@/components/Faq/FaqConversationalData/SearchMessagesBar.vue";
import SelectedConversationalData from "@/components/Faq/SelectedConversationalData.vue";

export default Vue.extend({
  components: {
    SearchMessagesBar,
    // QuestionsList,
    SelectedConversationalData,
  },
  props: ["newVariation", "isBusy"],
  data() {
    return {
      autoClassifyThreshold: 0.75,
    };
  },
  computed: {
    dataset() {
      return _.get(this.$store.state, "training.dataset", []);
    },
    filterQuestion: {
      get() {
        const selectedIntent = _.get(
          this,
          "$store.state.faq.selectedConversationalDataIntent",
          ""
        );
        return selectedIntent;
      },
      set(value) {
        this.$set(
          this.$store.state.faq,
          "selectedConversationalDataIntent",
          value
        );
      },
    },
    previewAutoClassify: {
      get() {
        const previewAutoClassify = _.get(
          this,
          "$store.state.faq.previewAutoClassify",
          []
        );
        return previewAutoClassify;
      },
      set(value) {
        this.$set(this.$store.state.faq, "previewAutoClassify", value);
      },
    },
    filteredUnclassifiedTrainingData() {
      // Filter down to the training data that are related to the selected intent
      if (this.$store.state.training.all) {
        const filterQuestion = this.filterQuestion;

        const unclassifiedTrainingData = _.chain(
          this.$store.getters.currentTrainingData
        )
          .filter((q) => q.archived == false)
          .filter((q) => {
            if (filterQuestion && q.prediction) {
              return filterQuestion === q.prediction;
            } else {
              return true;
            }
          })
          .filter((q) => {
            return q.probability < this.autoClassifyThreshold
          })
          .value();
        return unclassifiedTrainingData;
      } else {
        return [];
      }
    },
  },
  methods: {
    exportConversationalData() {
      const data = this.$store.state.training.all;
      let toExport = [];
      toExport.push([
        "question",
        "language",
        "prediction",
        "confidence",
        "appSource",
      ]);
      data.sort((a, b) => {
        if (a.prediction < b.prediction) return -1;
        if (a.prediction > b.prediction) return 1;
        return 0;
      });
      data.forEach((row) => {
        const rowInput = [
          row.question,
          row.detected_language,
          row.prediction,
          row.probability,
          row.appSource,
        ];
        toExport.push(rowInput);
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(toExport);
      XLSX.utils.book_append_sheet(wb, ws, "conversation");

      const filename = this.$exportFilename("FAQ_conversation", "csv");
      XLSX.writeFile(wb, filename, {});
    },

    handleConversationDataClick(row) {
      this.filterQuestion = row[0];
      this.previewAutoClassify = [];
    },
  },
});
</script>

<style scoped>
.custom-spinner .el-loading-spinner {
  top: 6px;
  margin-top: inherit;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 15px;
  right: 0;
  bottom: 0;
  margin: auto;
}
.importBtnIcon {
  height: 18px;
  width: 18px;
}

.importExportBtn {
  position: relative;
}
.exportAddNewBtn {
  padding: 10px;
  vertical-align: middle;
}
</style>

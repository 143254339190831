<template>
  <div
    v-loading="
      $apollo.queries.logDates.loading ||
      $apollo.queries.getActiveIntents.loading
    "
    class="loader-target"
    style="height: 100%"
  >
    <el-row>
      <el-col :sm="24">
        <el-card>
          <div slot="header" class="clearfix">Active Intents</div>
          <div>
            <el-col :sm="24" :xs="24">
              <el-select
                v-model="selectedDate"
                filterable
                style="flex: 1"
                size="mini"
                placeholder="Select month of log"
              >
                <el-option
                  v-for="logDate in logDates"
                  :key="logDate.logDate"
                  multiple="false"
                  :label="logDate.name"
                  :value="logDate.logDate"
                />
              </el-select>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="mini"
                round
                icon="el-icon-download"
                @click="exportCSV"
                >Export Data</el-button
              >
              <span v-if="selectedDate !== ''" style="float: right"
                >Total Intents: {{ activeIntents.length }}</span
              >
            </el-col>
            <!-- <BatchTestResults :batchTestResult="batchTestResult"/> -->
            <el-table
              size="mini"
              :data="activeIntents"
              style="width: 100%; min-height: 350px"
            >
              <el-table-column
                prop="intentName"
                label="Intent"
                min-width="230"
              />
              <el-table-column
                prop="department"
                label="Department"
                min-width="230"
              />
              <!-- <el-table-column
                prop="logDate"
                label="Log Month"
                min-width="200"
              ></el-table-column>-->
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import Vue from "vue";
import _, { get, sortBy, map, isEmpty } from "lodash";
import moment from "moment";
import gql from "graphql-tag";
import XLSX from "xlsx";
import ActiveIntentsQuery from "@/graphql/ActiveIntents.gql";

export default Vue.extend({
  data() {
    return {
      selectedDate: "",
      activeIntents: [],
      logDates: [],
    };
  },
  methods: {
    activeStatus({ row, column, rowIndex, columnIndex }) {
      if (row.status.toLowerCase().trim() === "active") {
        return "success-row";
      }
      return "warning-row";
    },
    getBilledDate(date) {
      return moment(date).subtract(1, "days");
    },
    exportCSV() {
      if (this.selectedDate === "") {
        this.$message.error("Select a date to export!");
        return;
      }

      const ws_data = [];
      ws_data.push(["ACTIVE INTENTS"]);
      ws_data.push([]);
      ws_data.push([
        this.getBilledDate(this.selectedDate).format("YYYY-MM-DD"),
      ]);
      ws_data.push([]);
      ws_data.push(["intentName", "department"]);
      this.activeIntents.forEach((user) => {
        const ws_row = [user.intentName, user.department];
        ws_data.push(ws_row);
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        this.getBilledDate(this.selectedDate).format("YYYY MMM")
      );

      XLSX.writeFile(
        wb,
        `Watson_Active_Intents_${this.getBilledDate(this.selectedDate).format(
          "YYYY_MMM"
        )}.xlsx`,
        {}
      );
    },
  },
  apollo: {
    getActiveIntents: {
      query: ActiveIntentsQuery,
      variables() {
        return { logDate: this.selectedDate };
      },
      update(data) {
        if (!isEmpty(data) && data.watsonAPI && data.watsonAPI.activeIntents) {
          this.activeIntents = data.watsonAPI.activeIntents;
        }
      },
    },
    logDates() {
      return {
        query: gql`
          query {
            watsonAPI {
              logDates
            }
          }
        `,
        fetchPolicy: "cache-and-network",
        update: (data) => {
          if (!data.watsonAPI || !data.watsonAPI.logDates) return "";
          const dates = [];
          data.watsonAPI.logDates.forEach((ele) => {
            dates.push({
              logDate: ele.logDate,
              name: this.getBilledDate(ele.logDate).format("YYYY MMM"),
            });
          });
          return dates;
        },
      };
    },
  },
});
</script>
<style scoped>
.el-upload {
  width: auto !important;
}
.el-upload__tip {
  padding-left: 20px;
}
.el-table .warning-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

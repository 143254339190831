<template>
  <div>
    <el-row
      v-for="(condition, index) in conditions"
      :key="index"
      type="flex"
      style="align-items: center; flex-direction: row"
    >
      <el-select
        v-if="conditionPropertyOptionsDynamic.length > 0"
        v-model="condition.property"
        filterable
        allow-create
        default-first-option
        size="mini"
        :placeholder="propertyPlaceHolder"
        class="property-field"
      >
        <!-- this options list from contentNode.ts // contentNodeOptionsMixin -->
        <el-option-group
          v-for="(
            conditionPropertyOption, index
          ) in conditionPropertyOptionsDynamic"
          :key="`condition-property-group-option-${index}`"
          :label="conditionPropertyOption.name"
        >
          <el-option
            v-for="(property, propertyIndex) in conditionPropertyOption.options"
            :key="`condition-property-option-${propertyIndex}`"
            :label="property.name"
            :value="property.value"
          ></el-option>
        </el-option-group>
      </el-select>
      <el-input
        v-model="condition.property"
        size="mini"
        :placeholder="propertyPlaceHolder"
        class="property-field"
        v-else
      ></el-input>
      <el-select
        filterable
        v-model="condition.operator"
        size="mini"
        style="width: 10%; margin-right: 5px"
      >
        <el-option
          :key="`condition-operator-option-${index}`"
          v-for="(conditionOperatorOption, index) in conditionOperatorOptions"
          :value="conditionOperatorOption.value"
          :label="conditionOperatorOption.name"
        />
      </el-select>
      <el-autocomplete
        style="width: 35%; margin-right: 5px"
        v-if="!['EXISTS', 'NOT EXISTS'].includes(condition.operator)"
        v-model="condition.value"
        size="mini"
        :fetch-suggestions="querySearch(condition)"
        :placeholder="valuePlaceHolder"
      />
      <el-button
        type="text"
        icon="el-icon-delete"
        size="mini"
        circle
        @click="conditions.splice(index, 1)"
      />
    </el-row>

    <el-button
      @click.prevent="onAddMoreCondition"
      size="mini"
      type="primary"
      plain
    >
      <i class="el-icon-plus"></i>
      Entity
    </el-button>
  </div>
</template>

<script>
import { contentNodeConditionOptionsComputedMixin } from "@/mixins/contentNode";
export default {
  name: "EntityConditionEditor",
  props: {
    propertyPlaceHolder: {
      type: String,
      default: "Choose a property",
    },
    valuePlaceHolder: {
      type: String,
      default: "Specify a value",
    },
    conditions: {
      type: Array,
    },
    conditionPropertyOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    conditionOperatorOptions: {
      type: Array,
      default: function () {
        return [
          {
            name: "<",
            value: "<",
          },
          {
            name: "=",
            value: "=",
          },
          {
            name: ">",
            value: ">",
          },
          {
            name: "not",
            value: "NOT",
          },
          {
            name: "includes",
            value: "INCLUDES",
          },
          {
            name: "not includes",
            value: "NOT INCLUDES",
          },
          {
            name: "included in",
            value: "INCLUDED IN",
          },
          {
            name: "exists",
            value: "EXISTS",
          },
          {
            name: "not exists",
            value: "NOT EXISTS",
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  mixins: [contentNodeConditionOptionsComputedMixin],
  methods: {
    createFilter(queryString) {
      return (keyword) => {
        // this is default return condition from framework (below in the comment)
        // return keyword.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        return new RegExp(queryString).test(keyword);
      };
    },
    querySearch(condition) {
      return (queryString, cb) => {
        const { property } = condition;
        const entityProperty = property.split(".").slice(-1)[0];
        const { entity } = this;

        const keywords =
          property && entity[entityProperty] && entity[entityProperty].list
            ? Object.keys(entity[entityProperty].list)
            : [];
        const results = queryString
          ? keywords.filter(this.createFilter(queryString))
          : keywords;
        // call callback function to return suggestions
        cb(results.map((item) => ({ value: item, link: item })));
      };
    },
    onAddMoreCondition() {
      this.conditions.push({
        property: null,
        operator: null,
        value: null,
      });
    },
    onDeleteCondition(index) {
      this.$confirm("Are you sure to delete this item?", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then(() => this.conditions.splice(index, 1));
    },
  },
};
</script>
<style scoped>
>>> .conditions-input-wrapper > div {
  display: flex;
  align-items: center;
}

>>> .conditions-input-wrapper .el-input-group__prepend {
  width: 50%;
}

>>> .conditions-input-wrapper .el-input-group__prepend .property-selector {
  margin-right: 0;
  width: 70%;
}

>>> .conditions-input-wrapper .el-input-group__prepend .operator-selector {
  margin-left: 0;
  border-left: 1px solid #d8dce5;
}

.property-field {
  width: 35%;
  margin-right: 5px;
}
</style>

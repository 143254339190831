<template>
  <el-autocomplete
    :disabled="disableField"
    v-model="localState"
    :fetch-suggestions="querySearch"
    filterable
  ></el-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "FieldAutocomplete",
  props: {
    fieldName: String,
    value: String,
  },
  computed: {
    ...mapGetters(["fieldDataForAutoFormPopulation", "selectedChat"]),
    disableField() {
      return this.selectedChat.resolved;
    },
    localState: {
      get() {
        return this.value;
      },
      set(localState) {
        this.$emit("input", localState);
      },
    },
  },
  methods: {
    querySearch(queryString, cb) {
      let fieldDataArray = _.get(
        this.fieldDataForAutoFormPopulation,
        this.fieldName,
        [{ value: "No options found" }]
      );
      let results = queryString
        ? fieldDataArray.filter(this.createFilter(queryString))
        : fieldDataArray;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (field) => {
        return (
          field.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
  },
};
</script>

<template>
  <el-container>
    <el-main>
      <el-row :gutter="20" style="display: flex">
        <div>
          <div>
            <el-select
              v-model="selectedMessageTemplate"
              placeholder="Select a message template"
              @change="handleViewMessageTemplateChange"
              filterable
            >
              <el-option
                v-for="item in uniqueMessageTemplates"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
            <el-select
              style="margin-left: 5px"
              ref="availableMessageTemplateLanguagesDropdown"
              v-model="selectedMessageTemplateLanguage"
              placeholder="Select a language"
              @change="handleViewMessageTemplateLanguageChange"
              filterable
            >
              <el-option
                v-for="language in availableMessageTemplateLanguages"
                :key="language"
                :label="language"
                :value="language"
              />
            </el-select>
            <el-button
              style="margin-left: 10px"
              type="primary"
              icon="el-icon-plus"
              @click="addNewTemplate"
              >New Template</el-button
            >
            <el-button
              type="primary"
              v-if="selectedMessageTemplate && selectedMessageTemplateLanguage"
              icon="el-icon-plus"
              @click="addNewLanguage"
              >New Language</el-button
            >
          </div>
          <div
            style="position: relative; display: flex; justify-content: center"
          ></div>
        </div>
      </el-row>
      <el-row :gutter="20">
        <div>
          <el-dialog
            title="Preview"
            :visible.sync="previewDialogVisible"
            width="30%"
            center
          >
            <div
              style="position: relative; display: flex; justify-content: center"
            >
              <WhatsAppMessageTemplatePreview
                :header="previewHeader"
                :body="previewBody"
                :footer="previewFooter"
                :buttons="previewButtons"
                :button-type="previewButtonType"
              />
            </div>
          </el-dialog>
          <MessageTemplateForm
            :name="name"
            :language="language"
            :category="category"
            :header="header"
            :body="body"
            :footer="footer"
            :buttons="buttons"
            :button-type="buttonType"
            :status="status"
            :read-only="readOnly"
            ref="form"
          >
            <template #footer="slotProps">
              <el-button
                type="primary"
                :loading="addingMessageTemplate"
                @click="addMessageTemplate(slotProps)"
                v-if="!readOnly"
                >Add Message Template</el-button
              >
              <el-button
                type="primary"
                :loading="deletingMessageTemplate"
                @click="deleteMessageTemplate(slotProps)"
                v-if="readOnly"
                >Delete Message Template</el-button
              >
              <el-button type="primary" @click="preview(slotProps)"
                >Preview</el-button
              >
            </template>
          </MessageTemplateForm>
        </div>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import _ from "lodash";
import WhatsAppMessageTemplatePreview from "./WhatsAppMessageTemplatePreview";
import MessageTemplateForm from "./MessageTemplateForm";

export default {
  name: "MessageTemplateTab",
  components: {
    WhatsAppMessageTemplatePreview,
    MessageTemplateForm,
  },
  props: {
    messageTemplates: Array,
    wa_endpoint: String,
  },
  data() {
    return {
      name: "",
      language: "",
      category: "",
      header: {},
      body: {},
      footer: {},
      buttons: [],
      buttonType: "",
      status: "",

      previewDialogVisible: false,
      addingMessageTemplate: false,
      previewHeader: {},
      previewBody: {},
      previewFooter: {},
      previewButtons: [],
      previewButtonType: "",

      deletingMessageTemplate: false,
      selectedMessageTemplateLanguage: "",
      selectedMessageTemplate: "",
      readOnly: false,
    };
  },
  computed: {
    uniqueMessageTemplates() {
      return _.sortedUniqBy(this.messageTemplates, (o) => o.name) || [];
    },
    availableMessageTemplateLanguages() {
      return (
        _.map(
          _.filter(
            this.messageTemplates,
            (o) => o.name === this.$data.selectedMessageTemplate
          ),
          (o) => o.language
        ) || []
      );
    },
  },
  mounted() {},
  methods: {
    addNewTemplate() {
      this.selectedMessageTemplate = "";
      this.selectedMessageTemplateLanguage = "";
      this.clearCurrentMessageTemplateForm();
      this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
    },
    addNewLanguage() {
      this.selectedMessageTemplate = "";
      this.selectedMessageTemplateLanguage = "";
      this.language = "";
      this.header = {};
      this.body = {};
      this.footer = {};
      this.buttons = [];
      this.buttonType = "";
      this.readOnly = false;
      this.status = "";
      this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
    },
    clearCurrentMessageTemplateForm() {
      this.name = "";
      this.language = "";
      this.category = "";
      this.header = {};
      this.body = {};
      this.footer = {};
      this.buttons = [];
      this.buttonType = "";
      this.readOnly = false;
      this.status = "";
    },
    deleteMessageTemplate() {
      this.$confirm(
        `You are about to delete message template ${this.selectedMessageTemplate}, including all language(s) that has been created, are you sure?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deletingMessageTemplate = true;
          this.$store
            .dispatch("DELETE_WHATSAPP_MESSAGE_TEMPLATES", {
              data: {
                name: this.selectedMessageTemplate,
                wa_endpoint: this.wa_endpoint,
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: `Message template successfully deleted!`,
              });
              this.clearCurrentMessageTemplateForm();
              this.deletingMessageTemplate = false;
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: `Failed to delete message template!`,
              });
              this.deletingMessageTemplate = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled!",
          });
        });
    },
    changeMessageTemplate() {
      this.clearCurrentMessageTemplateForm();
      const messageTemplate = _.find(
        this.messageTemplates,
        (o) =>
          o.language === this.$data.selectedMessageTemplateLanguage &&
          o.name === this.$data.selectedMessageTemplate
      );
      if (messageTemplate) {
        // Track unmodified data to be used in update WHERE clause
        this.readOnly = true;
        const {
          name,
          category,
          language,
          status,
          components,
        } = messageTemplate;
        const header = _.find(
          components,
          (component) => component.type === "HEADER"
        );
        const body = _.find(
          components,
          (component) => component.type === "BODY"
        );
        const footer = _.find(
          components,
          (component) => component.type === "FOOTER"
        );

        const buttons = _.find(
          components,
          (component) => component.type === "BUTTONS"
        );

        this.name = name;
        this.category = category;
        this.language = language;
        if (header) {
          if (header.format === "TEXT") {
            this.header = {
              type: "TEXT",
              text: header.text,
            };
          } else {
            this.header = {
              type: "MEDIA",
              mediaType: header.format,
            };
          }
        }

        if (body) {
          this.body = {
            text: body.text,
          };
        }

        if (footer) {
          this.footer = {
            text: footer.text,
          };
        }

        if (buttons) {
          const buttonsArr = buttons.buttons;
          const isQuickReplies = _.every(
            buttonsArr,
            (button) => button.type === "QUICK_REPLY"
          );
          if (isQuickReplies) {
            this.buttonType = "QUICK_REPLY";
          } else {
            this.buttonType = "CALL_TO_ACTION";
          }
          this.buttons = buttonsArr;
        }

        if (status) {
          this.status = status;
        }
      }
    },
    handleViewMessageTemplateChange(val) {
      this.selectedMessageTemplateLanguage = "";
      this.changeMessageTemplate();
    },
    handleViewMessageTemplateLanguageChange(val) {
      this.changeMessageTemplate();
    },

    getPreviewButtonType(messageTemplate) {
      if (messageTemplate.buttons.length === 0) return undefined;

      const quickReplyButtons = _.find(
        messageTemplate.buttons,
        (button) => button.type === "QUICK_REPLY"
      );
      if (quickReplyButtons) {
        return "QUICK_REPLY";
      }
      const callToActionButtons = _.find(
        messageTemplate.buttons,
        (button) => button.type === "PHONE_NUMBER" || button.type === "URL"
      );
      if (callToActionButtons) {
        return "CALL_TO_ACTION";
      }

      return undefined;
    },
    preview(messageTemplate) {
      this.previewButtonType = this.getPreviewButtonType(messageTemplate);
      this.previewButtons = messageTemplate.buttons;
      this.previewBody = messageTemplate.body;
      this.previewFooter = messageTemplate.footer;
      this.previewHeader = {
        format:
          messageTemplate.header.type === "TEXT"
            ? messageTemplate.header.type
            : messageTemplate.header.mediaType,
        text: messageTemplate.header.text,
      };
      this.previewDialogVisible = true;
    },
    addMessageTemplate(messageTemplate) {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.submitStatus = "ERROR";
      // } else {
      // do your submit logic here
      this.addingMessageTemplate = true;
      const messageTemplatePayload = {};

      if (messageTemplate.header) {
        if (messageTemplate.header.type === "TEXT") {
          messageTemplatePayload.header = {
            mediaType: messageTemplate.header.type,
            text: messageTemplate.header.text,
          };
        } else {
          messageTemplatePayload.header = {
            mediaType: messageTemplate.header.mediaType,
          };
        }
      }

      if (messageTemplate.body && messageTemplate.body.text) {
        messageTemplatePayload.body = messageTemplate.body;
      }

      if (messageTemplate.footer && messageTemplate.footer.text) {
        messageTemplatePayload.footer = messageTemplate.footer;
      }

      if (
        Array.isArray(messageTemplate.buttons) &&
        messageTemplate.buttons.length > 0
      ) {
        messageTemplatePayload.buttons = messageTemplate.buttons.map(
          (button) => {
            if (button.type === "PHONE_NUMBER") {
              const phoneButton = {
                ...button,
                phone_number: `${button.country_code}${button.phone_number}`,
              };
              delete phoneButton.country_code;
              delete phoneButton.url;
              return phoneButton;
            } else if (button.type === "URL") {
              const urlButton = {
                ...button,
              };
              delete urlButton.country_code;
              delete urlButton.phone_number;
              return urlButton;
            }
            return button;
          }
        );
      }
      this.$store
        .dispatch("ADD_WHATSAPP_MESSAGE_TEMPLATES", {
          data: {
            ...messageTemplatePayload,
            name: messageTemplate.templateName,
            category: messageTemplate.category,
            language: messageTemplate.language,
            wa_endpoint: this.wa_endpoint,
          },
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: res,
          });
          this.addingMessageTemplate = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.response.data.error.message,
          });
          this.addingMessageTemplate = false;
        });
      // }
    },
  },
};
</script>

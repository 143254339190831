<template>
  <div class="footer-container">
    <label class="footer-text">{{ previewText }}</label>
  </div>
</template>

<script>
export default {
  name: "WhatsAppMessageTemplateFooter",
  components: {},
  props: {
    previewText: {
      type: String,
      default: "No Preview Available"
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.footer-container {
  padding: 10px 10px 10px 10px;
}
.footer-text {
  color: grey;
}
</style>
<template>
  <el-button
    type="info"
    size="mini"
    :loading="!skipQuery"
    icon="el-icon-download"
    @click="skipQuery = false"
    >Export Workspace</el-button
  >
</template>

<script>
import Vue from "vue";
import gql from "graphql-tag";
import XLSX from "xlsx";
const WATSON_EXPORT = require("@/graphql/WATSON_WORKSPACE_EXPORT.gql");
export default {
  data() {
    return {
      watsonWorkspace: {},
      skipQuery: true,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
  apollo: {
    watsonWorkspaceExport() {
      return {
        query: WATSON_EXPORT,
        fetchPolicy: "network-only",
        update(data) {
          this.watsonWorkspace = data;
          this.skipQuery = true;
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.aoa_to_sheet(data.watsonAPI.watsonExport.entities),
            "Entities"
          );
          XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.aoa_to_sheet(data.watsonAPI.watsonExport.processFlows),
            "Process Flow"
          );
          XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.aoa_to_sheet(data.watsonAPI.watsonExport.intents),
            "Intents"
          );

          const filename = this.$exportFilename("watson_export", "xlsx");
          XLSX.writeFile(wb, filename, {});
          return data;
        },
        skip() {
          return this.skipQuery;
        },
      };
    },
  },
};
</script>

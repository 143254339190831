<template>
  <ChatGroupDropdown
    :show-border="false"
    type="online"
    :chats="chats"
    :expanded="isExpanded"
    custom-classes="agent-chat-item"
    :chatsLoading="isChatLoading"
    @update:expanded="setExpanded"
    @update:selectedChat="setSelectedChat"
  >
    <template slot="title">
      <div class="agent-item">
        <div class="agent-item--info">
          <el-row :gutter="0" class="agent-item--basic-info">
            <el-col :span="24">
              <el-row type="flex" align="middle">
                <span class="agent-name">
                  {{ agentName || "NaN" }}
                </span>
                <div v-if="agentStatus === 'ONLINE'" class="status-container">
                  <span class="status-dot is-online"></span>Online
                </div>
                <div v-else class="status-container">
                  <span class="status-dot is-offline"></span>Offline
                </div>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="0" class="agent-item--stats">
            <el-col :span="8">
              <div class="stats total">
                <div class="label">Total</div>
                <div class="count">
                  {{ onGoingChatCount + resolvedChatCount }}
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="stats on-going">
                <div class="label">Ongoing</div>
                <div class="count">{{ onGoingChatCount }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="stats resolved">
                <div class="label">Resolved</div>
                <div class="count">{{ resolvedChatCount }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </ChatGroupDropdown>
</template>

<script>
import gql from "graphql-tag";
import { mapMutations } from "vuex";
import ChatGroupDropdown from "@/views/Supervisor/ChatGroupDropdown";
export default {
  components: { ChatGroupDropdown },
  data() {
    return {
      chats: [],
      selected: false,
      isChatLoading: false,
    };
  },
  props: {
    isExpanded: Boolean,
    agentEmail: String,
    agentName: String,
    agentStatus: String,
    onGoingChatCount: Number,
    resolvedChatCount: Number,
  },
  methods: {
    ...mapMutations("supervisor", [
      "SET_SELECTED_CHAT_SESSION",
      "SET_SELECTED_AGENT",
    ]),
    setSelectedChat(chatSession) {
      if (chatSession) {
        this.$store.commit("supervisor/SET_SELECTED_CHAT_SESSION", chatSession);
      }
    },
    fetchChatsForAgent: async function () {
      this.isChatLoading = true;
      const response = await this.$apollo.query({
        query: gql`
          query($email: String) {
            livechatAPI {
              livechatSessionsWithinTodayOfAgent(email: $email)
            }
          }
        `,
        variables: {
          email: this.agentEmail,
        },
      });
      this.chats = _.get(
        response,
        "data.livechatAPI.livechatSessionsWithinTodayOfAgent",
        []
      );
      this.isChatLoading = false;
    },
    setExpanded() {
      this.$store.commit("supervisor/SET_SELECTED_AGENT", {
        email: this.isExpanded ? null : this.agentEmail,
      });
    },
  },
  watch: {
    async isExpanded(val) {
      if (val) {
        await this.fetchChatsForAgent();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-item {
  width: 100%;
  .agent-name {
    float: left;
    margin-right: 12px;
  }
}
.stats {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  place-items: center;
  .label {
    margin-right: 3px;
  }
  .count {
    padding: 2px 4px;
    color: white;
    border-radius: 3px;
  }

  &.on-going {
    .count {
      background: rgba(69, 183, 53, 0.7);
    }
  }
  &.resolved {
    .count {
      background: rgba(134, 134, 134, 0.7);
    }
  }
  &.total {
    .count {
      background: rgba(134, 134, 134, 0.1);
      color: #666;
    }
  }
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  border-radius: 50px;
  background-color: #757575;
}

.status-dot.is-online {
  background-color: #67c23a;
}

.status-dot.is-offline {
  background-color: red;
}

.agent-chat-item {
  border-bottom: 1px solid #e6ebf5;

  ::v-deep {
    .el-row {
      margin-bottom: 0;
    }

    .empty-list {
      margin-bottom: 20px;
    }

    .chats-container {
      border-top: 1px solid #e6ebf5;
    }

    .chat-group-down {
      box-shadow: none;
      border-radius: 0;
      border: 0;
      height: 70px;
      padding: 0 15px;
    }

    .agent-item {
      display: flex;
      place-items: center;
    }

    .agent-item--avatar {
      width: 54px;
      height: 54px;
      padding: 0;
      display: inline-table;
      margin-right: 10px;
      border-radius: 50%;
      background: #e6ebf5;
    }

    .agent-item--info {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      place-content: center;
    }

    .agent-item--stats {
      margin-top: 10px;
    }
  }
}

.status-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>

<template>
  <section>
    <el-row type="flex" justify="end" style="align-items: center">
      <el-tooltip
        class="item"
        effect="dark"
        content="Enable/disable on Settings > System > Activate Bot Creation"
        placement="left"
      >
        <i
          style="margin-right: 10px; color: #909399"
          class="el-icon-question"
        />
      </el-tooltip>
      <el-button
        @click="launchCreationWizard"
        :disabled="!wizardSettingEnabled"
        style="margin-right: 20px"
        size="mini"
        type="primary"
        icon="el-icon-chat-line-square"
        >Create New Bot</el-button
      >
    </el-row>

    <el-row v-if="isShowAdvanced">
      <el-col :sm="12">
        Maker Checker status:
        <pre>{{ JSON.stringify(makerCheckerObject, null, 2) }}</pre>
      </el-col>
    </el-row>

    <el-row :gutter="20" justify="center" style="width: 100%">
      <el-col :xs="24" :sm="12" :md="12" :lg="8">
        <!-- Set height 348px + 2px (for border 1px each side) -->
        <el-card
          v-loading="loadingChart"
          element-loading-text="Loading training evaluation..."
          style="
            height: 378px;
            max-height: 458px;
            min-height: 458px;
            text-align: center;
          "
          text
        >
          <div v-if="renderChart && !loadingChart">
            <div v-if="isAccuracyAvailable">
              <chart-container
                :chart="{
                  title: 'Training Evaluation',
                  type: 'doughnut',
                  data: predictionAccuracy,
                }"
              />
            </div>
            <div style="text-align: left" v-else>
              <p>Training Evaluation is not available</p>
              <small style="margin-bottom: 20px; display: inline-block"
                >This is probably caused by examples not trained yet or empty
                examples in all intents.</small
              >
            </div>
          </div>
        </el-card>
        <Ratings v-if="isShowBotRating" />
      </el-col>

      <el-col :xs="24" :sm="12" :md="12" :lg="9">
        <!-- Set height 398px + 2px (for border 1px each side) -->
        <el-card
          v-loading="taskLoading"
          element-loading-text="Loading Tasks..."
          style="
            height: 878px;
            min-height: 878px;
            max-height: 878px;
            text-align: left;
          "
          text
        >
          <el-row>
            <el-col :sm="8">
              <h2>Tasks</h2>
            </el-col>
            <el-col :sm="16" style="text-align: right">
              <strong style="font-size: 5em; line-height: 0.8">{{
                finalSetOfTasks.length
              }}</strong>
            </el-col>
          </el-row>
          <div style="margin-top: 32px">
            <el-input
              v-model="taskFilter"
              type="text"
              placeholder="Filter tasks here..."
            />
            <el-table
              id="taskTable"
              max-height="500"
              :data="filteredTaskData"
              empty-text="No pending tasks"
              class="dashboard-table"
            >
              <el-table-column type="expand" align="center" width="20">
                <template slot-scope="props">
                  <!-- {{props.row}} -->
                  <div v-if="props.row.list && props.row.list.length > 0">
                    <p
                      v-for="(value, key, index) in props.row.users"
                      :key="index"
                    >
                      <!-- value. {{value}}
                      key. {{key}}
                      index. {{index}}-->
                      {{ key.split("@")[0] }} has made
                      <b>{{ value }}</b> changes
                    </p>
                    <div
                      v-if="!props.row.users && props.row.type === 'content'"
                    >
                      <p
                        v-for="(value, key, index) in props.row.list"
                        :key="index"
                        @click="goToEditor(value.contentId, props.row.type)"
                      >
                        <b style="cursor: pointer">{{ value.contentId }}</b> has
                        content node fix(es)
                      </p>
                    </div>
                    <div v-if="!props.row.users && props.row.type === 'faq'">
                      <p
                        v-for="(value, key, index) in props.row.list"
                        :key="index"
                        @click="goToFaq(value.id, props.row.type)"
                      >
                        <b style="cursor: pointer">{{ value.intentName }}</b>
                        has intent fix(es)
                      </p>
                    </div>
                    <div
                      v-if="!props.row.users && props.row.type === 'trigger'"
                    >
                      <p
                        v-for="(value, key, index) in props.row.list"
                        :key="index"
                        @click="goToEditor(value.triggerId, props.row.type)"
                      >
                        <b v-if="!value.wildcard" style="cursor: pointer">{{
                          value.triggerId
                        }}</b>
                        <span v-if="!value.wildcard"
                          >has duplicate trigger fix(es)</span
                        >
                        <b v-if="value.wildcard" style="cursor: pointer">{{
                          value.triggerId
                        }}</b>
                        <span v-if="value.wildcard"
                          >has wildcard trigger fix(es)</span
                        >
                      </p>
                    </div>
                  </div>
                  <p v-else>No updates</p>
                </template>
              </el-table-column>

              <el-table-column align="center" width="155">
                <template slot-scope="scope">
                  <b v-if="scope.row.list.length >= 0 && scope.row.users">
                    {{ scope.row.list.length }} changes in
                    {{ scope.row.type }}
                  </b>
                  <b
                    v-if="
                      scope.row.list.length >= 0 &&
                      !scope.row.users &&
                      scope.row.type !== 'faq'
                    "
                    >{{ scope.row.list.length }} {{ scope.row.type }} node
                    fix(es)</b
                  >
                  <b
                    v-if="
                      scope.row.list.length >= 0 &&
                      !scope.row.users &&
                      scope.row.type === 'faq'
                    "
                    >{{ scope.row.list.length }} {{ scope.row.type }} fix(es)</b
                  >
                  <br />
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <el-button-group>
                    <el-button
                      v-if="scope.row.users"
                      :disabled="!isAuthorizedToCheck"
                      class="approve button"
                      size="mini"
                      icon="el-icon-check"
                      @click="
                        makerCheckerObject.publish
                          ? openApproveConfirmation(scope.row.mainType, 'all')
                          : setupAndPublishChangesNow(scope.row.mainType, 'all')
                      "
                      >Approve</el-button
                    >
                    <el-button
                      size="mini"
                      :disabled="!isAuthorizedToCheck"
                      @click="saveBot(scope.row.type)"
                      v-if="!scope.row.users"
                      class="dismiss button"
                      icon="el-icon-error"
                      >Dismiss</el-button
                    >

                    <el-button
                      size="mini"
                      v-if="scope.row.users"
                      class="button"
                      style="color: white, background-color: #409EFF"
                      @click="
                        $emit('onViewMore', {
                          activeTab: 'tasks',
                          homeActiveLeftPane: 'pending',
                          taskMainType: scope.row.mainType,
                        })
                      "
                    >
                      View more&nbsp;
                      <i class="el-icon-arrow-right" />
                    </el-button>
                    <el-button
                      size="mini"
                      v-if="!scope.row.users && scope.row.type !== 'faq'"
                      class="button"
                      style="color: white, background-color: #409EFF"
                      icon="el-icon-arrow-left"
                      @click="
                        goToEditor(
                          scope.row.type === 'content'
                            ? scope.row.list[0].contentId
                            : scope.row.list[0].triggerId,
                          scope.row.type
                        )
                      "
                    >
                      View more&nbsp;
                      <i class="el-icon-arrow-right" />
                    </el-button>
                    <el-button
                      v-if="!scope.row.users && scope.row.type === 'faq'"
                      class="button"
                      size="mini"
                      style="color: white, background-color: #409EFF"
                      icon="el-icon-arrow-left"
                      @click="goToFaq(scope.row.list[0].id, scope.row.type)"
                    >
                      View more&nbsp;
                      <i class="el-icon-arrow-right" />
                    </el-button>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="7" style="text-align: center">
        <DailyTrainingData />
        <ChatSummary />
        <Feedback />
      </el-col>
    </el-row>

    <el-dialog
      custom-class="approve-dialog-popup"
      title="Approval Confirmation"
      :visible.sync="approveConfirmationVisible"
      width="50%"
      center
    >
      <p style="text-align: center; font-size: 16px">
        Do you wish to publish your changes now or on a future date?
        <span
          v-if="selectedApprove && selectedApprove.mainType === 'dialog'"
          style="font-size: 14px; font-style: italic"
        >
          <br />* <u>Dialog nodes</u> can only be published now due to
          dependency issues
        </span>
      </p>
      <el-form
        ref="approveConfirmationForm"
        v-loading="approveConfirmationLoading"
        :model="approveConfirmationForm"
        label-width="150px"
        :rules="approveConfirmationRule"
        element-loading-text="Creating new publish..."
      >
        <el-form-item label="Publish Name:" prop="publishName">
          <el-input
            v-model="approveConfirmationForm.publishName"
            type="text"
            placeholder="Publication's name as reference"
          />
        </el-form-item>
        <el-form-item label="Publish Date:" prop="publish_date">
          <el-date-picker
            v-model="approveConfirmationForm.publish_date"
            format="dd-MM-yyyy HH:mm"
            value-format="dd-MM-yyyy HH:mm:ss"
            style="width: 50%"
            type="datetime"
            placeholder="Select publish date and time"
            :picker-options="dateTimePicker"
          />
        </el-form-item>
        <el-form-item class="dialog buttons">
          <el-button @click="publishChangesNow()">
            <i class="el-icon-check" /> Publish Now
          </el-button>
          <el-button
            type="primary"
            :disabled="selectedApprove && selectedApprove.mainType === 'dialog'"
            @click="submitApproveForm('approveConfirmationForm')"
          >
            <i class="el-icon-bell" /> Publish on date
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- Creation Wizard -->
    <el-dialog
      class="creation-wizard"
      :before-close="handleClose"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :show-close="true"
      :visible.sync="dialogWizardVisible"
      width="75%"
    >
      <WizardContent
        :creationWizardVisible.sync="creationWizardVisible"
        :activeStep="activeStep"
        @changeStep="changeStep"
      />
    </el-dialog>
  </section>
</template>

<script>
import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { getDepartmentVerifiedChanges } from "@/helperMethods/makerChecker";
import ChartContainer from "@/components/FAQChartContainer";
import WizardContent from "@/components/BotCreationWizard/WizardContent.vue";

import DailyTrainingData from "./DailyTrainingData/Index";
import Feedback from "./Feedback/Index";
import Ratings from "./Ratings/Index";
import ChatSummary from "./ChatSummary/Index";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default {
  components: {
    DailyTrainingData,
    Feedback,
    Ratings,
    ChatSummary,
    ChartContainer,
    WizardContent,
  },
  props: ["taskLoading", "overallTasks", "taskData"],
  data() {
    return {
      taskFilter: "",

      dateTimePicker: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime();
        },
        shortcuts: [
          {
            text: "Tomorrow",
            onClick(picker) {
              const date = new moment().add(1, "day").toDate();
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week later",
            onClick(picker) {
              const date = new moment().add(1, "week").toDate();
              picker.$emit("pick", date);
            },
          },
          {
            text: "A month later",
            onClick(picker) {
              const date = new moment().add(1, "month").toDate();
              picker.$emit("pick", date);
            },
          },
        ],
      },

      // Approve confirmation
      approveConfirmationForm: { publish_date: "", publishName: "" },
      approveConfirmationVisible: false,
      approveConfirmationLoading: false,
      selectedApprove: {
        department: "",
        mainType: "",
      },
      approveConfirmationRule: {
        publish_date: [
          {
            required: true,
            message: "Please input date and time",
            trigger: "blur",
          },
        ],
      },
      renderChart: true,
      loadingChart: false,
      threshold: 0.75,
      isAccuracyAvailable: true,
      creationWizardVisible: false,
      activeStep: 1,
    };
  },
  computed: {
    ...mapGetters([
      "getUserAuthentication",
    ]),
    dialogWizardVisible: {
      get() {
        return this.creationWizardVisible && this.wizardSettingEnabled;
      },
      set(value) {
        this.creationWizardVisible = value;
      },
    },

    isAuthorizedToCheck() {
      const { roles } = this.getUserAuthentication;
      const isAuthorized = checkRoles(getPermittedRoles("editor_approve"));
      const adminRole = roles && roles.includes("admin");

      return isAuthorized || adminRole;
    },
    wizardSettingEnabled() {
      return this.systemEnabled && this.tutorialActivated;
    },
    systemEnabled() {
      const systemEnabled = _.get(this, "$store.state.modules.system.enabled");

      return systemEnabled;
    },
    tutorialActivated() {
      const tutorialActivated = _.get(
        this,
        "$store.state.modules.system.tutorialActivated"
      );

      if (tutorialActivated === undefined) {
        return true;
      }

      return tutorialActivated;
    },
    isShowBotRating() {
      return _.get(
        this,
        "$store.state.modules.rating.showRatingOnHomePage",
        false
      );
    },
    departments() {
      return this.$store.getters.departments;
    },
    makerCheckerObject() {
      return this.$store.getters.makerCheckerObject;
    },
    isShowAdvanced() {
      return this.$store.getters.isShowAdvanced;
    },
    finalSetOfTasks() {
      const contentTasks = _.assign(
        {},
        {
          list: this.$store.state.contentMissingDetails,
          type: "content",
          mainType: "CONTENT",
        }
      );

      const triggerTasks = _.assign(
        {},
        {
          list: _.concat(
            this.$store.state.wildcardTriggers,
            this.$store.state.duplicateTriggers
          ),
          type: "trigger",
          mainType: "TRIGGER",
        }
      );

      const intentTasks = _.assign(
        {},
        {
          list: this.$store.state.intentMissingDetails,
          type: "faq",
          mainType: "FAQ",
        }
      );

      const taskListContent = _.get(contentTasks, "list") || [];
      const taskListTrigger = _.get(triggerTasks, "list") || [];
      const taskListIntent = _.get(intentTasks, "list") || [];
      const totalTask = _.concat(
        this.overallTasks,
        taskListContent.length > 0 ? contentTasks : [],
        taskListTrigger.length > 0 ? triggerTasks : [],
        taskListIntent.length > 0 ? intentTasks : []
      );
      return totalTask;
    },

    filteredTaskData() {
      return _.chain(this.finalSetOfTasks)
        .filter((row) => {
          if (this.taskFilter) {
            return JSON.stringify(row).toLowerCase().includes(this.taskFilter);
          } else {
            return true;
          }
        })
        .sortedUniqBy("type")
        .value();
    },
    predictionAccuracy() {
      const dataset = _.get(this.$store.state, "training.dataset", []);
      this.renderChart = false;
      this.$nextTick(() => {
        this.renderChart = true;
      });

      let correctPredictions = 0;
      let unclearPredictions = 0;

      _.forEach(dataset, (intent) => {
        const variations = intent.variations;
        variations.forEach((variation) => {
          if (Array.isArray(variation.intent_ranking)) {
            const intentRankingNotExist = variation.intent_ranking.length === 0;
            if (intentRankingNotExist) {
              return;
            }
            const intentRanking = variation.intent_ranking.filter(
              (ranking) => ranking.name === intent.question
            );
            if (intentRanking.length === 0) {
              return;
            }
            const confidence = intentRanking[0].confidence;
            // check confidence if above or equal threshold
            if (confidence >= this.threshold) {
              // add to correct prediction count
              correctPredictions++;
            } else {
              // add to correct unclear count
              unclearPredictions++;
            }
          }
        });
      });

      const totalPredictions = correctPredictions + unclearPredictions;

      this.isAccuracyAvailable = totalPredictions > 0;

      // calculate percentage to nearest 2 digit decimals
      const percentageCorrect =
        Math.round((correctPredictions / totalPredictions) * (100 * 100)) / 100;
      const percentageUnclear =
        Math.round((unclearPredictions / totalPredictions) * (100 * 100)) / 100;

      return [
        {
          value: correctPredictions,
          label: `Correctly Predicted (${percentageCorrect || 0}%)`,
        },
        {
          value: unclearPredictions,
          label: `Unclear (${percentageUnclear || 0}%)`,
        },
      ];
    },
  },
  mounted() {
    // FIXME: @Dhoni auto launch creation wizard is temporarely disabled, will be reviewed later
    // // set minimum delay
    // const minDelay = 1000;
    // setTimeout(() => {
    //   this.creationWizardCheck();
    // }, minDelay);
    this.loadDataset();
  },
  methods: {
    creationWizardCheck() {
      const loginCount = window.localStorage.getItem("loginCount");
      // check first login
      if (Number(loginCount) <= 1 && loginCount) {
        this.launchCreationWizard();
        window.localStorage.removeItem("loginCount");
      }
    },
    handleClose(done) {
      this.$confirm("Are you sure to skip this dialog?").then(() => {
        done();
      });
    },
    changeStep(step) {
      this.activeStep = step;
    },
    launchCreationWizard() {
      this.activeStep = 1;
      this.creationWizardVisible = true;
    },
    loadDataset() {
      const faqEnabled = _.get(this, "$store.state.modules.faq.enabled", false);
      const hasFaqPermission = checkRoles(getPermittedRoles("faq"));

      if (!faqEnabled || !hasFaqPermission) {
        return;
      }

      this.loadingChart = true;
      this.isAccuracyAvailable = true;
      this.$apollo
        .query({
          query: gql`
            query {
              faqAPI {
                dataset: getDataset
              }
            }
          `,
        })
        .then((response) => {
          const dataset = _.get(response.data, "faqAPI.dataset");
          this.$set(this.$store.state.training, "dataset", dataset);
        })
        .finally(() => {
          this.loadingChart = false;
        });
    },
    goToEditor(val, type) {
      let id = val;
      this.$store.dispatch("SELECT_NODE", { type: type, id });
      this.$router.push("/editor");
    },
    goToFaq(id, type) {
      this.$set(this.$store.state.pages.faq, "selectedIntentId", id);
      this.$store.commit("faq.SET_ACTIVE_TAB_INDEX", "trainingData");
      this.$router.push("/faq2");
    },
    dataFilter(type, department) {
      const changeArray =
        getDepartmentVerifiedChanges(this.taskData, this.departments) || [];
      return changeArray.filter((change) => {
        return change.mainType === type;
      });
    },
    homePageResolverDataHandover(type, department) {
      return {
        listOfChanges: this.dataFilter(type, department),
        types: [type.toLowerCase()],
        departments: this.departments,
      };
    },
    publishChangesNow() {
      const publishEnabled =
        this.makerCheckerObject && this.makerCheckerObject.publish;
      if (publishEnabled) {
        this.approveConfirmationVisible = true;
        this.approveConfirmationLoading = true;
      }

      const { mainType: type, department } = this.selectedApprove;
      const { publish_date, publishName } = this.approveConfirmationForm;
      const {
        listOfChanges,
        types,
        departments,
      } = this.homePageResolverDataHandover(type, department);

      const payload = {
        publish_date,
        publishName,
        listOfChanges,
        types,
        departments,
      };
      this.$store
        .dispatch("CREATE_AND_COMPLETE_PUBLISH", payload)
        .then((isPublished) => {
          if (isPublished) {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "success",
              message: "Changes has been approved and published successfully",
            });
            this.$emit("getTasks");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "error",
            message: "Encountered error creating and publish content.",
          });
          this.approveConfirmationLoading = false;
          this.approveConfirmationVisible = false;
        });
    },
    publishChangesOnDate() {
      this.approveConfirmationVisible = true;
      this.approveConfirmationLoading = true;

      const { mainType: type, department } = this.selectedApprove;
      const { publish_date, publishName } = this.approveConfirmationForm;
      const {
        listOfChanges,
        types,
        departments,
      } = this.homePageResolverDataHandover(type, department);
      const payload = {
        publish_date,
        publishName,
        listOfChanges,
        types,
        departments,
      };

      this.$store
        .dispatch("CREATE_PUBLISH", payload)
        .then((isPublished) => {
          if (isPublished) {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "success",
              message:
                "Changes has been approved and added to publish queue successfully",
            });
            this.$emit("getTasks");
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
        });
    },

    submitApproveForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.approveConfirmationVisible = false;
          this.publishChangesOnDate();
          return false;
        } else {
          console.log("Error submiting approval form!!");
          return false;
        }
      });
    },
    setupAndPublishChangesNow(mainType, department) {
      this.selectedApprove = { mainType, department };
      this.publishChangesNow();
    },
    openApproveConfirmation(mainType, department) {
      this.approveConfirmationVisible = true;
      this.selectedApprove = { mainType, department };
    },
    saveBot(type) {
      if (type === "content") {
        this.$store.commit("SET_MISSING_DETAILS", { missingDetailAPI: [] });
        const missingContentExpiry = new moment()
          .add(1, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        this.$apollo
          .mutate({
            mutation: gql`
              mutation($missingContentExpiry: String!) {
                setContentExpiry(missingContentExpiry: $missingContentExpiry)
              }
            `,
            variables: {
              missingContentExpiry: missingContentExpiry,
            },
          })
          .then((response) => {
            this.$store.commit(
              "SET_CONTENT_EXPIRY",
              response.data.setContentExpiry
            );
          });
      } else if (type === "trigger") {
        this.$store.commit("SET_DUPLICATE_TRIGGERS", {
          identicalTriggerAPI: [],
        });
        this.$store.commit("SET_WILDCARD_TRIGGERS", { wildCardTriggerAPI: [] });
        const identicalTriggerExpiry = new moment()
          .add(1, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        this.$apollo
          .mutate({
            mutation: gql`
              mutation($identicalTriggerExpiry: String!) {
                setTriggerExpiry(
                  identicalTriggerExpiry: $identicalTriggerExpiry
                )
              }
            `,
            variables: {
              identicalTriggerExpiry: identicalTriggerExpiry,
            },
          })
          .then((response) => {
            this.$store.commit(
              "SET_TRIGGER_EXPIRY",
              response.data.setTriggerExpiry
            );
          });
      } else if (type === "faq") {
        this.$store.commit("SET_INTENT_MISSING_DETAILS", {
          missingIntentDetailAPI: [],
        });
        const missingIntentExpiry = new moment()
          .add(1, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        this.$apollo
          .mutate({
            mutation: gql`
              mutation($missingIntentExpiry: String!) {
                setIntentExpiry(missingIntentExpiry: $missingIntentExpiry)
              }
            `,
            variables: {
              missingIntentExpiry: missingIntentExpiry,
            },
          })
          .then((response) => {
            this.$store.commit(
              "SET_INTENT_EXPIRY",
              response.data.setIntentExpiry
            );
          });
      }
    },
  },
};
</script>

<style lang="scss">
.el-loading-mask {
  z-index: auto;
}
.dashboard-table {
  .el-button-group {
    .el-button {
      font-size: 12px;
    }
  }
  .cell {
    font-size: 12px;
    text-align: left;
    word-break: break-word;
  }
}
.el-row {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>

import { MutationTree } from "vuex/types";

import { MakerCheckerState } from "./types";
import { RootState } from "@/store/types";

const mutations: MutationTree<MakerCheckerState> = {
  SET_PENDING_CHANGES(state, payload) {
    state.pendingChanges = payload;
  },
  SET_COMPLETED_CHANGES(state, payload) {
    state.completedChanges = payload;
  },
  UPDATE_COMPLETED_CHANGES(state, payload: any) {
    state.completedChanges.push(...payload);
  },
  SET_MAIN_TYPE(state, payload: any) {
    state.mainType = payload;
  },
  SET_DEPARTMENT_TYPE(state, payload: any) {
    state.currentDepartment = payload;
  },
};

export default mutations;

<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    datasets: {
      default() {
        return [{ LOADING: 0 }];
      },
    },
    labels: {
      default() {
        return ["waiting"];
      },
    },
    colors: {
      default() {
        return [];
      },
    },
    category: {
      default() {
        return "all";
      },
    },
    options: {
      default() {
        return {};
      },
    },
  },
  computed: {
    getColors() {
      if (this.tooMany) {
        return this.colors.concat([
          "#FFE11A",
          "#FD7400",
          "#4D4D4D",
          "#5DA5DA",
          "#F15854",
          "#FFFFFF",
        ]);
      } else {
        return this.colors;
      }
    },
    tooMany() {
      if (this.datasets && this.datasets[0]) {
        return this.datasets[0].data.length > 4;
      }
      return false;
    },
    smallWithColor() {
      if (this.datasets && !this.tooMany) {
        return [
          Object.assign(this.datasets[0], { backgroundColor: this.getColors }),
        ];
      } else {
        return [];
      }
    },
    largeChart() {
      if (this.datasets && this.tooMany) {
        let results = {};
        let n = this.datasets[0].data.length;
        for (let i = 0; i < n; i++) {
          let category = this.labels[i].split("_")[0];
          if (results[category]) {
            results[category] = results[category] + this.datasets[0].data[i];
          } else {
            results[category] = this.datasets[0].data[i];
          }
        }
        let chart = {
          labels: [],
          datasets: [{ data: [], backgroundColor: this.getColors }],
        };
        Object.keys(results).forEach((k) => {
          chart.datasets[0].data.push(results[k]);
          chart.labels.push(k);
        });
        return chart;
      } else {
        return {};
      }
    },
    getOptions() {
      if (this.tooMany) {
        return {
          legend: {
            display: false,
          },
          ...this.options,
        };
      } else {
        return { ...this.options };
      }
    },
  },
  watch: {
    category() {
      this.$forceUpdate();
    },
    datasets() {
      this.$forceUpdate();
    },
  },
  mounted() {
    if (this.tooMany) {
      this.renderChart(this.largeChart, this.getOptions);
    } else {
      this.renderChart({
        labels: this.labels,
        datasets: this.smallWithColor,
      });
    }
  },
  updated() {
    if (this.tooMany) {
      this.renderChart(this.largeChart, this.getOptions);
    } else {
      this.renderChart({
        labels: this.labels,
        datasets: this.smallWithColor,
      });
    }
  },
};
</script>

<template>
  <section style="padding: 15px">
    <p>HTML head</p>
    <code-diff
      :old-string="selectedWebPage.head_published"
      :new-string="selectedWebPage.head"
      :context="10"
    />

    <p>HTML body</p>
    <code-diff
      name="content"
      :old-string="selectedWebPage.content_published"
      :new-string="selectedWebPage.content"
      :context="10"
    />
  </section>
</template>

<script>
import CodeDiff from "vue-code-diff";
export default {
  props: ["selectedWebPage", "isDisabled"],
  components: { CodeDiff },
};
</script>

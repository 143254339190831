<template>
  <el-card style="box-shadow:none; margin-right: 15px; width:40%;">
    <div slot="header" class="widget-header">
      <div
        style="display:flex; align-items:center; justify-content:space-between;width: 100%;"
      >
        <div>
          <h2>Greeting Editor</h2>
          <br />
          <small style="color: #BDBDBD; font-style: italic;"
            >Press save changes to save greeting...</small
          >
        </div>
        <el-button
          :disabled="!valid"
          :loading="saving"
          type="primary"
          icon="el-icon-check"
          size="mini"
          @click="saveChanges"
          >Save changes</el-button
        >
      </div>
    </div>

    <Bubble v-model="node.content" editable />

    <JSONEditor
      v-if="$store.state.showAdvanced"
      v-model="greeting"
      @valid="valid = true"
      @invalid="valid = false"
    />
  </el-card>
</template>
<script>
import Bubble from "@/components/Bubble";
import JSONEditor from "@/components/JSONEditor.vue";
import _ from "lodash";

export default {
  name: "GreetingEditor",
  components: {
    Bubble,
    JSONEditor,
  },
  props: {
    editorOption: Object,
  },
  data() {
    return {
      saving: false,
      node: {
        content: {
          text: this.$store.state.greeting || "text",
        },
        department: ["general"],
      },
    };
  },
  computed: {
    menu() {
      return {
        buttons: this.$store.state.persistentMenu || [],
      };
    },
    valid() {
      return this.greetingText && this.greetingText.length > 0;
    },
    greetingText() {
      return _.get(this, "node.content.text", "");
    },
  },
  methods: {
    saveChanges() {
      if (this.valid) {
        this.saving = true;
        this.$store
          .dispatch("EDIT_GREETING", this.greetingText)
          .then(
            () => {
              this.saving = false;
              this.$message({
                type: "success",
                message: "Greeting message saved",
              });

              setTimeout(() => {
                this.$rest("get", "facebook_config");
              }, 1000);
            },
            () => {
              this.saving = false;
              this.$message({
                type: "error",
                message: "Error when saving",
              });
              //Here error is not variable || Parameter
              //console.error(error);
            }
          )
          .catch((error) => {});
      } else {
        this.$message({
          type: "error",
          message: "Greeting message is empty!",
        });
      }
    },
  },
};
</script>

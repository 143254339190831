<template>
  <el-main>
    <el-dialog
      title="Delete Intent"
      :visible.sync="deleteDialogVisible"
      :before-close="handleClose"
      :close-on-press-escape="false"
      width="50%"
      center
    >
      <span>
        This will permanently delete
        <b>{{ selectedIntent.intentName }}</b>. Are you sure you want to continue?
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">No</el-button>
        <el-button type="primary" @click="handleDelete">Yes</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
<script>
export default {
  props: ["deleteDialogVisible", "selectedIntent"],
  methods: {
    handleClose() {
      this.$emit("toggleDeleteDialogVisible", false, this.selectedIntent);
    },
    handleDelete() {
      this.$emit("deleteQuestion", this.selectedIntent);
    }
  }
};
</script>

<style scoped></style>

import _ from "lodash";
import store from "@/store";
import moment from "moment";
import { getUserPermissions } from "../index";

export function getIdenticalTrigger(): {} {
  // call graphql function
  const identicalTriggerExpiry = _.get(
    store,
    "state.identicalTriggerExpiry",
    moment().subtract(1, "m").format("YYYY-MM-DD HH:mm:ss")
  );
  const currTime = moment();
  if (currTime.isBefore(moment(identicalTriggerExpiry))) {
    store.commit("SET_DUPLICATE_TRIGGERS", { identicalTriggerAPI: [] });
    return [];
  }

  const userHasEditorPermissions = getUserPermissions("editor");

  if (userHasEditorPermissions) {
    store
      .dispatch("GET_DUPLICATE_TRIGGERS")
      .then((duplicateTriggers) => {
        if (duplicateTriggers) {
          return duplicateTriggers;
        }
      })
      .catch((err) => {});
  }
  return {};
}

export function getWildCardTrigger(): {} {
  const identicalTriggerExpiry = _.get(
    store,
    "state.identicalTriggerExpiry",
    moment().subtract(1, "m").format("YYYY-MM-DD HH:mm:ss")
  );
  const currTime = moment();
  if (currTime.isBefore(moment(identicalTriggerExpiry))) {
    store.commit("SET_WILDCARD_TRIGGERS", { wildCardTriggerAPI: [] });
    return [];
  }

  const userHasEditorPermissions = getUserPermissions("editor");

  if (userHasEditorPermissions) {
    store
      .dispatch("GET_WILDCARD_TRIGGERS")
      .then((wildcardTriggers) => {
        if (wildcardTriggers) {
          return wildcardTriggers;
        }
      })
      .catch((err) => {});
  }
  return {};
}

export function checkIdenticalTrigger(
  id: string,
  changes: { [customIdentifier: string]: { [nodeId: string]: boolean } }
): string {
  const triggerNode = store.state.nodes.trigger[id];
  const triggerType = _.get(triggerNode, "type", "text");
  const conditions = _.get(triggerNode, "conditions", []);
  const entityConditions = _.get(triggerNode, "entityConditions", []);
  const stringifiedConditions = JSON.stringify(conditions);
  const stringifiedEntities = JSON.stringify(entityConditions);
  const context = triggerNode.context || "";
  const pattern = triggerNode.pattern || "";
  const nodeTriggerGroupKey = `${stringifiedConditions},${context},${
    triggerType === "entity" ? stringifiedEntities : pattern
  }`;
  const isAnyNodesIdentical =
    changes[nodeTriggerGroupKey] &&
    Object.keys(changes[nodeTriggerGroupKey]).length > 1;
  if (isAnyNodesIdentical) {
    const otherIdenticalNodes = _.pickBy(
      changes[nodeTriggerGroupKey],
      (_, nodeId) => {
        return nodeId !== id;
      }
    );
    if (_.size(otherIdenticalNodes) === 0) {
      return "";
    }
    const firstIdenticalNodeId = _.first(Object.keys(otherIdenticalNodes));
    return firstIdenticalNodeId || "";
  }
  return "";
}

export function checkWildCardTrigger(id: string): boolean {
  const triggerNode = store.state.nodes.trigger[id];
  const isWildcardPattern =
    triggerNode.pattern.includes("||") || triggerNode.pattern.includes("|/i");
  const isInvalid = isWildcardPattern;
  return isInvalid;
}

<template>
  <div>
    <div class="message-container">
      <div class="top" :style="topStyle">
        <WhatsAppMessageTemplateHeader
          v-if="isValidTextHeader || isValidMediaHeader"
          :format="header.format"
          :preview-text="header.text"
        />
        <WhatsAppMessageTemplateBody
          class="whatsapp-message-template-body"
          v-if="body && body.text"
          :preview-text="body.text"
        />
        <WhatsAppMessageTemplateFooter v-if="footer && footer.text" :preview-text="footer.text" />
        <div style="position: relative;">
          <div class="time">{{ getCurrentTime() }}</div>
        </div>
      </div>
      <div class="call-to-action" v-if="buttonType === 'CALL_TO_ACTION'">
        <WhatsAppMessageTemplateCallToAction
          :key="button.text"
          v-for="button in buttons"
          :type="button.type"
          :preview-text="button.text"
          :url="button.url"
        />
      </div>
      <div class="quick-replies" v-else-if="buttonType === 'QUICK_REPLY'">
        <WhatsAppMessageTemplateQuickReply
          :key="button.text"
          v-for="button in buttons"
          :preview-text="button.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WhatsAppMessageTemplateBody from "./WhatsAppMessageTemplateBody";
import WhatsAppMessageTemplateCallToAction from "./WhatsAppMessageTemplateCallToAction";
import WhatsAppMessageTemplateQuickReply from "./WhatsAppMessageTemplateQuickReply";
import WhatsAppMessageTemplateHeader from "./WhatsAppMessageTemplateHeader";
import WhatsAppMessageTemplateFooter from "./WhatsAppMessageTemplateFooter";
import moment from "moment";
export default {
  name: "WhatsAppMessageTemplatePreview",
  components: {
    WhatsAppMessageTemplateBody,
    WhatsAppMessageTemplateCallToAction,
    WhatsAppMessageTemplateQuickReply,
    WhatsAppMessageTemplateHeader,
    WhatsAppMessageTemplateFooter
  },
  props: {
    header: Object,
    body: Object,
    footer: Object,
    buttons: Array,
    buttonType: {
      type: String,
      required: false,
      validator: function(value) {
        return ["CALL_TO_ACTION", "QUICK_REPLY"].includes(value);
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    getCurrentTime() {
      return moment().format("h:mm A");
    }
  },
  computed: {
    isValidTextHeader() {
      if (!this.header) return false;
      return this.header.format === "TEXT" && this.header.text;
    },
    isValidMediaHeader() {
      if (!this.header) return false;
      return (
        this.header.format === "DOCUMENT" ||
        this.header.format === "IMAGE" ||
        this.header.format === "VIDEO"
      );
    },
    topStyle() {
      if (this.buttonType === "CALL_TO_ACTION") {
        return {
          position: "relative",
          background: "#fff",
          borderRadius: "10px 10px 0px 0px",
          minHeight: "100px",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column"
        };
      } else {
        return {
          position: "relative",
          background: "#fff",
          borderRadius: "10px 10px 10px 10px",
          minHeight: "100px",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column"
        };
      }
    }
  }
};
</script>
<style scoped>
.message-container::before {
  background: url(https://static.xx.fbcdn.net/rsrc.php/v3/yJ/r/jqmjYt3oQXX.png);
  background-color: #e5ddd5;
  position: absolute;
  opacity: 0.4;
  top: 0;
  left: 0;
  width: 100%;
  content: " ";
  height: 100%;
}
.message-container {
  width: 50%;
  min-height: 100px;
  min-width: 500px;
  margin: 30px auto;
}

.time {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: smaller;
  color: lightgrey;
}

.call-to-action {
  position: relative;
  background: #fff;
  min-width: 240px;
}

.call-to-action > * {
  border-top: 1px solid #dadde1;
  border-radius: 0px 0px 10px 10px;
}

.quick-replies {
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
}
.quick-replies > * {
  background: #fff;
  flex: 1 1 45%;
  margin: 3px 3px 0px 3px;
}

.whatsapp-message-template-body {
  flex-grow: 1;
}
</style>
<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-card>
          <h1>Bot JSON</h1>
          <el-input v-model="bot" type="textarea" :rows="10" />
          <el-button type="primary" @click="saveBot()" :loading="savingBot">Save</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import _ from "lodash";
import gql from "graphql-tag";
export default {
  data() {
    return {
      bot: {},
      savingBot: false
    };
  },
  apollo: {
    bot() {
      return {
        query: gql`
          query {
            Bot {
              rawBotJSON
            }
          }
        `,
        fetchPolicy: "cache-and-network",
        update: data => {
          const bot = _.get(data, "Bot.rawBotJSON");
          return JSON.stringify(bot);
        }
      };
    }
  },
  methods: {
    saveBot() {
      this.savingBot = true;
      this.$notify.info({
        title: "Pending Save",
        message: "Bot is currently being saved...",
        position: "bottom-right"
      });
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($bot: JSON!) {
              setBot(bot: $bot)
            }
          `,
          variables: {
            bot: JSON.parse(this.bot)
          }
        })
        .then(() => {
          this.savingBot = false;
          this.$notify.success({
            title: "Save Success",
            message: "Bot has been saved successfully",
            position: "bottom-right"
          });
        })
        .catch(() => {
          this.savingBot = false;
          this.$notify.error({
            title: "Save Error",
            message: "Bot encountered error when saving",
            position: "bottom-right"
          });
        });
    }
  }
};
</script>

<template>
  <el-main>
    <el-row>
      <el-date-picker
        v-model="datePicked"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        format="dd/MM/yyyy"
        :picker-options="datePickerOptions"
        :default-time="['00:00:00', '23:59:59']"
      />
    </el-row>
    <el-row>
      <el-button
        type="danger"
        :loading="isDownloading"
        @click="downloadLogs"
        style="width: 200px"
        >Download Message Logs</el-button
      >
    </el-row>
  </el-main>
</template>
<script>
import XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";
export default {
  name: "MessageLogsTab",
  props: {
    wa_endpoint: String,
  },
  data() {
    return {
      isDownloading: false,
      datePicked: [
        (() => {
          const d = new Date();
          d.setMonth(d.getMonth() - 6);
          return d;
        })(),
        new Date(),
      ],
      datePickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const start = new Date();
              start.setHours(-24);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const start = new Date();
              start.setDate(start.getDate() - 7);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last Year",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "All-Time",
            onClick(picker) {
              const start = new Date();
              start.setFullYear(1990, 1, 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      messageLogs: [],
    };
  },
  components: {},
  computed: {},
  methods: {
    downloadLogs() {
      this.isDownloading = true;
      this.$store
        .dispatch("GET_MESSAGE_LOGS", {
          data: {
            wa_endpoint: this.wa_endpoint,
            start_date: this.datePicked[0].getTime(),
            end_date: this.datePicked[1].getTime(),
          },
        })
        .then((data) => {
          this.messageLogs = data;
          const formattedMessageLogs = _.map(this.messageLogs, (obj) => {
            const returnObj = {};
            const { timestamp, hsm } = obj;
            _.assign(
              returnObj,
              { ...obj },
              {
                timestamp: moment(timestamp).format("DD-MM-YYYY HH:mm:ss"),
                hsm: (hsm && JSON.stringify(hsm)) || "",
              }
            );
            return returnObj;
          });
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(formattedMessageLogs);
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          const filename = this.$exportFilename(
            "whatsapp_message_logs",
            "xlsx"
          );
          XLSX.writeFile(wb, filename, {});

          this.isDownloading = false;
        })
        .catch((err) => {
          this.isDownloading = false;
          const errMsg = err.response.data.error.message;
          this.$message({
            type: "error",
            message:
              errMsg ||
              "Failed to retrieve message logs! Please check the external database config on Whatsapp setting",
          });
        });
    },
  },
  mounted() {},
};
</script>

<template>
  <!-- Set height 768 + 2px (for border 1px each side) -->
  <el-card
    v-loading="followupLoading"
    style="
      height: 618px;
      min-height: 618px;
      max-height: 618px;
      text-align: left;
    "
    element-loading-text="Loading Chats..."
  >
    <el-row>
      <el-col :sm="12">
        <h2 style="font-size: 22px">Chat Summary</h2>
      </el-col>
      <el-col :sm="12" style="text-align: right">
        <strong style="font-size: 4em; line-height: 0.8">
          {{ monitorCount }}
        </strong>
      </el-col>
    </el-row>
    <div style="margin-top: 32px">
      <el-date-picker
        v-model="monitorDate"
        type="daterange"
        start-placeholder="Start date"
        end-placeholder="End date"
        format="dd/MM/yyyy"
        style="width: 100%"
        @change="getFollowups"
      />
      <el-table
        :data="paginatedMonitorChats"
        max-height="240"
        style="width: 100%; height: 100%; font-size: 12px"
        empty-text="No chat summary"
      >
        <el-table-column
          label="Date"
          style="background-color: grey"
          align="left"
        >
          <template slot-scope="scope">
            <span class="followup">
              <span style="font-size: 12px">
                {{ displayDate(scope.row.date) }}
              </span>
              <br />
              <span style="font-size: 12px">
                {{ displayTime(scope.row.date) }}
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="Visitor ID"
          style="background-color: grey"
          width="250"
          align="left"
        >
          <template slot-scope="scope">
            <span class="followup" style="font-size: 12px">
              Visitor #{{ formattedUserId(scope.row.user_id) }} exchanged
              <strong>{{ scope.row.message_count }}</strong>
              <br />messages
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row>
      <div
        v-if="
          paginatedMonitorChats &&
          paginatedMonitorChats.length > 0 &&
          notAllChatsShown
        "
        class="more-conversations"
        @click="incrementNumberOfChatsSliced(20)"
      >
        - show more -
      </div>
    </el-row>
  </el-card>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      followupLoading: false,
      monitorDate: [new Date(), new Date()],
      monitorChats: [],
      numberOfChatsSliced: 20,
    };
  },
  computed: {
    notAllChatsShown() {
      const gotMoreChats =
        this.paginatedMonitorChats?.length !== this.monitorChats.length;
      return gotMoreChats;
    },
    monitorCount() {
      return (this.monitorChats && this.monitorChats.length) || 0;
    },
    paginatedMonitorChats() {
      const slicedMonitorChats = this.monitorChats?.slice(
        0,
        this.numberOfChatsSliced
      );
      return slicedMonitorChats;
    },
  },
  mounted() {
    this.getFollowups();
  },
  methods: {
    incrementNumberOfChatsSliced(increment = 20) {
      this.numberOfChatsSliced += increment;
    },
    formattedUserId(userId) {
      return userId.substring(0, 8);
    },
    displayDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    displayTime(date) {
      return moment(date).format("hh:mm a");
    },
    getFollowups() {
      this.followupLoading = true;
      const queryVariables = {
        dates: this.monitorDate,
      };

      this.$store
        .dispatch("FETCH_INTERACTIONS_SUMMARY", queryVariables)
        .then((data) => {
          this.monitorChats = data;
          this.followupLoading = false;
        })
        .catch((err) => {
          this.followupLoading = false;
          this.$notify.error({
            title: "Fetch Error",
            message: "Encountered error. Please refresh and try again.",
            positon: "bottom-right",
          });
        });
    },
    // interactionsCount(row) {
    //   if (row) {
    //     if (this.monitorChats) {
    //       return this.userOccurrence[row.id] > 1
    //         ? `(${this.userOccurrence[row.id]}) ${row.channel}s`
    //         : `an ${row.channel}`;
    //     }
    //   }
    // }
    // filteredmonitorChats() {
    //   return _.chain(this.monitorChats)
    //     .filter(row => {
    //       if (this.monitorDate) {
    //         return row.date === this.monitorDate;
    //       } else {
    //         return true;
    //       }
    //     })
    //     .map(row => {
    //       return _.assign(row, {
    //         name: _.capitalize(row.name),
    //         channel: _.capitalize(row.channel)
    //       });
    //     })
    //     .sortedUniqBy("id")
    //     .sortBy("time")
    //     .value();
    // },
  },
};
</script>

<style scoped>
.more-conversations {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin: 8px auto 24px;
  font-size: 12px;
  cursor: pointer;
}
</style>

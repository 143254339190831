<template>
  <div>
    <span
      style="margin-left:1.5em;margin-right: 3em;color:#909399; fontSize: 14px; margin-bottom:1em"
    >{{setting.label || name}}</span>
    <el-cascader
      v-model="value[name]"
      :options="setting.options"
      :props="setting.props"
      :collapse-tags="setting['collapse-tags']"
      :clearable="setting.clearable"
    >
      <template slot-scope="{ node, data }">
        <span>{{ data.label }}</span>
        <span
          :style="{float: 'right', color: '#909399', fontSize: 13 + 'px', marginLeft: '1.5em'}"
        >{{ data.value? data.value: data.label }}</span>
      </template>
    </el-cascader>
    <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="right">
      <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
    </el-tooltip>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
<template>
  <el-main>
    <el-header>
      <h4>
        Upload a mini app to your chatbot
        <span
          style="float: right;right:50px;"
        >Current Storage: Cluster Storage ({{ storageType }})</span>
      </h4>
    </el-header>

    <el-row>
      <div>
        <el-radio v-model="uploadMode" border label="image">Upload media files (png, jpg, gif)</el-radio>
        <!-- <el-radio
          v-model="uploadMode"
          :disabled="isDisabled"
          border
          label="html"
        >Upload a single HTML file</el-radio>-->
        <!-- <el-radio
          v-model="uploadMode"
          :disabled="isDisabled"
          border
          label="zip"
        >Upload a bundle (.zip)</el-radio>-->
        <el-radio v-model="uploadMode" border label="doc">Upload a document (pdf)</el-radio>
      </div>
    </el-row>

    <el-row>
      <!-- Upload file area -->
      <el-card v-if="uploadMode === 'image'">
        <div
          class="upload-demo"
          @dragenter="preventDefault"
          @dragstart="preventDefault"
          @dragend="preventDefault"
          @dragleave="preventDefault"
          @dragover="preventDefault"
          @drag="preventDefault"
          @drop="uploadOnChange"
          @click="$refs.image.click()"
        >
          <div tabindex="0" class="el-upload el-upload--picture">
            <div action="#" class="el-upload-dragger">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or
                <em>click to upload</em>
              </div>
            </div>
            <!-- :disabled="isDisabled" -->
            <input
              ref="image"
              type="file"
              name="file"
              multiple="multiple"
              class="el-upload__input"
              accept="image/jpeg, image/png, image/gif"
              @change="uploadOnChange"
            />
          </div>
          <ul class="el-upload-list el-upload-list--picture">
            <li
              v-for="(file, index) in fileList"
              :key="file.name"
              tabindex="0"
              class="el-upload-list__item is-success"
            >
              <img :src="file.localURL" alt class="el-upload-list__item-thumbnail" />
              <a class="el-upload-list__item-name">
                <i class="el-icon-document" />
                {{ file.name }}
              </a>
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check" />
              </label>
              <i class="el-icon-close" @click="uploadOnDelete(index)" />
              <i class="el-icon-close-tip">press delete to remove</i>
            </li>
          </ul>
        </div>
        <!-- :disabled="isDisabled" -->
        <el-button type="success" icon="el-icon-upload" @click="upload">Save</el-button>
      </el-card>

      <el-card v-else-if="uploadMode === 'doc'">
        <div
          class="upload-demo"
          @dragenter="preventDefault"
          @dragstart="preventDefault"
          @dragend="preventDefault"
          @dragleave="preventDefault"
          @dragover="preventDefault"
          @drag="preventDefault"
          @drop="uploadOnChange"
          @click="$refs.doc.click()"
        >
          <div tabindex="0" class="el-upload el-upload--picture">
            <div action="#" class="el-upload-dragger">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" :disabled="isDisabled">
                Drop file here or
                <em>click to upload</em>
              </div>
            </div>
            <input
              ref="doc"
              type="file"
              name="file"
              multiple="multiple"
              class="el-upload__input"
              accept="application/pdf"
              @change="uploadOnChange"
            />
          </div>
          <ul class="el-upload-list el-upload-list--text">
            <li
              v-for="(file, index) in fileList"
              :key="file.name"
              tabindex="0"
              class="el-upload-list__item is-success"
            >
              <a class="el-upload-list__item-name">
                <i class="el-icon-document" />
                {{ file.name }}
              </a>
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check" />
              </label>
              <i class="el-icon-close" @click="uploadOnDelete(index)" />
              <i class="el-icon-close-tip">press delete to remove</i>
            </li>
          </ul>
        </div>
        <el-button type="success" icon="el-icon-upload" @click="upload">Save</el-button>
      </el-card>
    </el-row>

    <!-- File list -->
    <el-row>
      <el-card :v-loading="fetchingFiles">
        <div>
          <el-table
            ref="fileTableData"
            :data="files"
            style="width:100%;"
            element-loading-text="Loading files..."
          >
            <el-table-column prop="thumbnail" label="Thumbnail" :width="columnWidth">
              <template slot-scope="scope">
                <img
                  v-if="scope.row.mimetype.includes('image')"
                  :src="scope.row.url"
                  style="max-height: 50px; max-width 50px;"
                />
                <i v-else class="el-icon-document" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="File Name" :width="columnWidth" sortable />
            <el-table-column prop="mimetype" label="Type" :width="columnWidth" sortable />
            <el-table-column prop="size" label="Size" :width="columnWidth" sortable />
            <el-table-column prop="url" label="URL" :width="columnWidth" sortable>
              <template slot-scope="scope">
                <a
                  target="_blank"
                  :href="scope.row.url"
                  :style="{
                    color: 'blue',
                    'text-decoration': 'underline'
                  }"
                >
                  {{
                  scope.row.url.length > 12
                  ? scope.row.url.substring(0, 10) + "..."
                  : scope.row.url
                  }}
                </a>
              </template>
            </el-table-column>
            <el-table-column label="Operations" :width="operationsWidth">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="copyURL(scope.$index, files)">Copy URL</el-button>
                <!-- <el-button
                  size="mini"
                  type="danger"
                  @click="handleDownload(scope.$index, files)"
                >Download</el-button>-->
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, files)"
                >Delete</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-pagination
        ref="fileTable"
        layout="prev, pager, next"
        :page-size="10"
        :current-page="currentPage"
        :total="noOfItems"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
      />
    </el-row>
  </el-main>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isMounted: false,
      name: "",
      uploadMode: "image",
      code: "",
      editorOptions: {
        mode: "text/html",
        theme: "monokai"
      },
      currentPage: 1, // Used to track the current page in pagination
      fileList: [], // Used for uploads
      files: [], // Used for display in pagination
      htmlFiles: [], // Used to store all html files after retrieving from getAllFolders()
      zipFiles: [], // Used to store all zip files after retrieving from getAllFolders()
      imageFiles: [], // Used to store all image files after retrieving from getAllFolders()
      docFiles: [], // Used to store all image files after retrieving from getAllFolders()
      noOfItems: 0, // Initialize no of items to be used in pagination
      folders: [],

      fetchingFiles: false,
      newFolderName: "",
      showNewFolderPopover: false
    };
  },
  computed: {
    ...mapGetters(["storageType"]),
    columnWidth() {
      if (this.isMounted) {
        return `${(this.$refs.fileTableData.$el.getBoundingClientRect().width *
          0.7) /
          5}`;
      }
      return "";
    },
    operationsWidth() {
      if (this.isMounted) {
        return `${this.$refs.fileTableData.$el.getBoundingClientRect().width *
          0.3}`;
      }
      return "";
    },
    isDisabled: {
      get() {
        return this.name === "";
      },
      set(val) {
        this.isDisabled = val;
      }
    },
    url() {
      var slug = name
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");

      return `https://${this.$store.state.server}/static/${slug}/`;
    }
  },
  watch: {
    uploadMode: function(newMode, oldMode) {
      if (newMode !== oldMode) {
        this.fileList = [];
      }
      if (this.uploadMode === "image") {
        this.noOfItems = this.imageFiles.length;
        this.files = this.imageFiles.slice(
          0,
          this.imageFiles.length >= 10 ? 10 : this.imageFiles.length
        );
      } else if (this.uploadMode === "doc") {
        this.noOfItems = this.docFiles.length;
        this.files = this.docFiles.slice(
          0,
          this.docFiles.length >= 10 ? 10 : this.docFiles.length
        );
      }

      // if (this.uploadMode === "html") {
      //   this.noOfItems = this.htmlFiles.length;
      //   this.files = this.htmlFiles.slice(
      //     0,
      //     this.htmlFiles.length >= 10 ? 10 : this.htmlFiles.length
      //   );
      // } else if (this.uploadMode === "zip") {
      //   this.noOfItems = this.zipFiles.length;
      //   this.files = this.zipFiles.slice(
      //     0,
      //     this.zipFiles.length >= 10 ? 10 : this.zipFiles.length
      //   );
      // } else if (this.uploadMode === "image") {
      //   this.noOfItems = this.imageFiles.length;
      //   this.files = this.imageFiles.slice(
      //     0,
      //     this.imageFiles.length >= 10 ? 10 : this.imageFiles.length
      //   );
      // } else if (this.uploadMode === "doc") {
      //   this.noOfItems = this.docFiles.length;
      //   this.files = this.docFiles.slice(
      //     0,
      //     this.docFiles.length >= 10 ? 10 : this.docFiles.length
      //   );
      // }
    }
  },

  methods: {
    uploadOnDelete(index) {
      this.fileList.splice(index, 1);
    },
    preventDefault(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    uploadOnChange(event) {
      let files;
      if (event.dataTransfer) {
        files = event.dataTransfer.files; // For drag and drop
      } else {
        files = event.target.files; // For click to upload
      }
      let noFailed = 0;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        file.localURL = URL.createObjectURL(file);

        const isImageMode = this.uploadMode === "image";
        const isDocMode = this.uploadMode === "doc";
        if (
          isImageMode &&
          (file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/gif")
        ) {
          this.fileList.push(file);
        } else if (isDocMode && file.type === "application/pdf") {
          this.fileList.push(file);
        }

        // if (this.uploadMode === "html" && file.type === "text/html") {
        //   // Uploading HTML file
        // } else if (
        //   this.uploadMode === "zip" &&
        //   (file.type === "application/zip" ||
        //     file.type === "application/x-zip-compressed" ||
        //     file.type === "multipart/x-zip" ||
        //     file.type === "application/x-rar-compressed")
        // ) {
        //   this.fileList.push(file);
        // } else if (
        //   this.uploadMode === "image" &&
        //   (file.type === "image/png" ||
        //     file.type === "image/jpeg" ||
        //     file.type === "image/jpg" ||
        //     file.type === "image/gif")
        // ) {
        //   this.fileList.push(file);
        // } else if (
        //   this.uploadMode === "doc" &&
        //   file.type === "application/pdf"
        // ) {
        //   this.fileList.push(file);
        // }
        else {
          noFailed++;
        }
      }

      if (noFailed === 0) {
        this.$notify.info({
          message: `${files.length -
            noFailed} file(s) have been received. Preparing for upload..`,
          title: "Preparing",
          position: "bottom-right"
        });
      } else {
        this.$notify.error({
          message: `${noFailed} file(s) failed to upload, please ensure you're uploading ${this.uploadMode} file(s) only!`,
          title: "Upload Error",
          position: "bottom-right"
        });
      }
      event.target.value = "";
      this.preventDefault(event);
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.refreshTable();
    },
    refreshTable() {
      const isImageMode = this.uploadMode === "image";
      const isDocMode = this.uploadMode === "doc";

      if (isImageMode) {
        this.files = this.imageFiles.slice(
          (this.currentPage - 1) * 10,
          this.imageFiles.length >= this.currentPage * 10
            ? this.currentPage * 10
            : this.imageFiles.length
        );
      } else if (isDocMode) {
        this.files = this.docFiles.slice(
          (this.currentPage - 1) * 10,
          this.docFiles.length >= this.currentPage * 10
            ? this.currentPage * 10
            : this.docFiles.length
        );
      }

      // if (this.uploadMode === "html") {
      //   this.files = this.htmlFiles.slice(
      //     (this.currentPage - 1) * 10,
      //     this.htmlFiles.length >= this.currentPage * 10
      //       ? this.currentPage * 10
      //       : this.htmlFiles.length
      //   );
      // } else if (this.uploadMode === "zip") {
      //   this.files = this.zipFiles.slice(
      //     (this.currentPage - 1) * 10,
      //     this.zipFiles.length >= this.currentPage * 10
      //       ? this.currentPage * 10
      //       : this.zipFiles.length
      //   );
      // } else if (this.uploadMode === "image") {
      //   this.files = this.imageFiles.slice(
      //     (this.currentPage - 1) * 10,
      //     this.imageFiles.length >= this.currentPage * 10
      //       ? this.currentPage * 10
      //       : this.imageFiles.length
      //   );
      // }
    },
    handleDownload(index, rows) {
      let form = new FormData();
      form.append("filepath", rows[index].filepath);
      this.$rest("post", "downloadFile", form).then(val => {
        var link = document.createElement("a");
        link.download = rows[index].name;
        link.href = `data:${rows[index].mimetype};base64,` + val;
        link.click();
        link.remove();
      });
    },
    copyURL(index, rows) {
      var copyText = rows[index].url;
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.setAttribute("value", copyText);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.$notify.success({
        title: "Success",
        message: "Successfully copied URL to the clipboard!",
        type: "success",
        position: "bottom-right"
      });
    },
    handleDelete(index, rows) {
      this.$confirm(
        "This will permanently delete the file. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let form = new FormData();
          const fileName = rows[index].name;
          form.append("fileName", fileName);
          this.$rest("post", "miniapp_ceph_delete_file", form)
            .then(response => {
              if (response.isDeleted) {
                this.$notify.success({
                  title: "Success",
                  message: "Successfully deleted file from storage!",
                  position: "bottom-right"
                });
                this.getFiles();
              } else {
                this.$notify.error({
                  message: "Error occurred while trying to delete!",
                  title: "Error",
                  position: "bottom-right"
                });
              }
            })
            .catch(err => {
              this.$notify.error({
                message: err.error.message,
                title: "Error",
                position: "bottom-right"
              });
            });
        })
        .catch(() => {
          this.$notify.info({
            title: "Info",
            message: "Delete canceled",
            position: "bottom-right"
          });
        });
    },
    download() {
      if (this.name) {
        this.$rest("post", "miniapp_download", {
          name: this.name
        }).then(text => {
          this.code = text;
        });
      }
    },
    upload() {
      const isDocMode = this.uploadMode === "doc";
      const isImageMode = this.uploadMode === "image";
      let form = new FormData();
      form.append("brain", this.$store.state.brain);
      form.append("name", this.name);
      if (isImageMode) {
        this.fileList.forEach(file => {
          form.append("images", file);
        });
        this.$store
          .dispatch("MINI_APP_UPLOAD_IMAGE", form)
          .then(response => {
            this.$notify.success({
              title: "Success",
              message: `Successfully uploaded file into storage`,
              position: "bottom-right",
              showClose: true
            });

            this.getFiles();
          })
          .catch(err => {
            this.$notify.error({
              title: "Upload Error",
              message: "Encountered error uploading file to storage",
              position: "bottom-right"
            });
          });
      } else if (isDocMode) {
        this.fileList.forEach(file => {
          form.append("documents", file);
        });
        this.$store
          .dispatch("MINI_APP_UPLOAD_DOCUMENT", form)
          .then(response => {
            this.$notify.success({
              title: "Success",
              message: `Successfully uploaded file into storage`,
              position: "bottom-right",
              showClose: true
            });

            this.getFiles();
          })
          .catch(err => {
            this.$notify.error({
              title: "Upload Error",
              message: "Encountered error uploading file to storage",
              position: "bottom-right"
            });
          });
      }

      this.fileList = [];
    },
    getFiles() {
      this.fetchingFiles = true;
      this.$store
        .dispatch("GET_ALL_IMAGES")
        .then(response => {
          const files = response;
          const images = files.filter(obj => {
            return (
              obj.mimetype === "image/png" ||
              obj.mimetype === "image/jpeg" ||
              obj.mimetype === "image/jpg" ||
              obj.mimetype === "image/gif"
            );
          });

          const documents = files.filter(obj => {
            return obj.mimetype === "application/pdf";
          });

          this.imageFiles = images;
          this.docFiles = documents;

          switch (this.uploadMode) {
            case "html":
              this.noOfItems = this.htmlFiles.length;
              break;
            case "zip":
              this.noOfItems = this.zipFiles.length;
              break;
            case "image":
              this.noOfItems = images.length;
              break;
            case "doc":
              this.noOfItems = documents.length;
              break;
          }

          this.refreshTable();
          // this.$notify.success({
          //   title: "Success",
          //   message: `Successfully fetched latest files!`,
          //   type: "success",
          //   position: "bottom-right"
          // });
        })
        .catch(err => {
          this.$notify.error({
            title: "Error",
            message: _.get(
              err,
              "response.data.error.message",
              "Encountered an error setting up miniapps"
            ),
            position: "bottom-right"
          });
        })
        .finally(() => (this.fetchingFiles = false));
    }
  },
  mounted() {
    this.getFiles();
    this.isMounted = true;
  }
};
</script>

<style></style>

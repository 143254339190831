<template>
  <el-dropdown @visible-change="onVisibleChange" ref="dropdown" trigger="click">
    <el-button
      plain
      style="margin-left: 10px;"
      size="mini"
      type="info"
      icon="el-icon-document-copy"
    >Duplicate Section</el-button>

    <el-dialog title="Confirmation" :visible.sync="dialogVisible" width="30%">
      <p style="font-size: 16px; text-align:center;">Are you sure to duplicate selected section?</p>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="onCancel">Cancel</el-button>
        <el-button
          size="mini"
          plain
          type="primary"
          @click="onDuplicate(true)"
        >Duplicate & Navigate to section</el-button>
        <el-button size="mini" type="primary" @click="onDuplicate(false)">Duplicate</el-button>
      </span>
    </el-dialog>

    <el-dropdown-menu ref="cascader" slot="dropdown" style="padding: 0;">
      <el-cascader-panel v-model="toDuplicate" @change="dialogVisible = true" :options="options"></el-cascader-panel>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import _ from "lodash";
import { v4 as uuid } from "uuid";
export default {
  props: ["flowOptions", "section", "activeFlow"],
  data() {
    return {
      dialogVisible: false,
      toDuplicate: [],
      options: [],
    };
  },
  methods: {
    onVisibleChange(isOpen) {
      if (isOpen) {
        this.$refs.cascader.activePath = [];
        this.options = this.flowOptions.map((option) => {
          delete option.children;
          return option;
        });
      }
    },
    onCancel() {
      this.dialogVisible = false;
      this.toDuplicate = [];
    },
    onDuplicate(navigateAway = false) {
      const section = _.cloneDeep(this.section);
      const flowId = this.toDuplicate[0];
      const map = {};
      section.sectionId = uuid();
      section.title = `DUPLICATED ${section.title}`;
      section.flowId = flowId;

      // duplicate all question and update new question id
      section.questions.forEach((question, index) => {
        const newQuestionId = index === 0 ? `${section.sectionId}_1` : uuid();
        map[question.questionId] = newQuestionId;
        question.flowId = flowId;
        question.sectionId = section.sectionId;
        question.questionId = newQuestionId;
        question.suggestedAnswers.forEach((answer) => {
          answer.id = uuid();
          answer.questionId = newQuestionId;
        });
      });

      // patch with new question id
      section.questions.forEach((question) => {
        question.suggestedAnswers.forEach((answer) => {
          if (_.has(map, answer.afterAction.success)) {
            answer.afterAction.success = map[answer.afterAction.success];
          } else {
            answer.afterAction.success = null;
          }
        });
      });

      this.$eventBus.$emit("duplicateSection", {
        section,
        navigateAway,
      });
      this.onCancel();
    },
  },
};
</script>

//@ts-nocheck
//https://github.com/vuejs/vue/issues/8721

import Vue, { VueConstructor } from "vue";
import _ from "lodash";
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
} from "@auth0/auth0-spa-js";
import Keycloak, { KeycloakInstance, KeycloakPromise } from "keycloak-js";
import LocalStorageManager from "./localStorageManager";
import { config } from "@/helpers/apiConfig";
import axios from "axios";
import { enrichSentryUserData } from "@/sentry";
import { enrichCohereUserData } from "@/cohere";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState: any) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const keycloakPromise = <TSuccess>(
  keycloakPromise: KeycloakPromise<TSuccess, any>
) =>
  new Promise<TSuccess>((resolve, reject) =>
    keycloakPromise
      .success((result: TSuccess) => resolve(result))
      .error((e: any) => reject(e))
  );

let instance: any;

/** Returns the current instance of plugin */
export const getInstance = () => instance;

export const getAuthType = () => instance.authType;

export const getToken = async () => await instance.getToken();

/** Creates an instance of the plugin. If one has already been created, it returns that instance */
export const useAuth = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.href,
  ...options
}) => {
  console.info("Initializing Auth");

  if (instance) return instance;

  const KEYCLOAK_TOKEN_VALID_SECONDS = 10;
  let keycloak: KeycloakInstance | null = null;
  let auth0: Auth0Client | null = null;

  const auth0ClientMixin = {
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o: any) {
        try {
          this.popupOpen = true;
          await auth0!.loginWithPopup(o);
        } catch (e) {
          //FIXME: what should we do if error happens?
          // Low priority due to no usage of login with popup
          console.error(e);
        } finally {
          this.popupOpen = false;
          this.user = (await auth0!.getUser()) as {};
        }
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await auth0!.handleRedirectCallback();
          this.user = (await auth0!.getUser()) as {};
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: any) {
        return auth0!.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: any) {
        return auth0!.getIdTokenClaims(o);
      },
      async getAuth0Token() {
        return auth0?.getTokenSilently(this.buildAuth0ClientOptions());
      },
      buildAuth0ClientOptions(): Auth0ClientOptions {
        return {
          domain: options.domain,
          client_id: options.clientId,
          audience: options.audience,
          redirect_uri: redirectUri,
          useRefreshTokens: options.useRefreshTokens,
          cacheLocation: "localstorage",
        };
      },
      async initializeAuth0Client() {
        const clientOptions = this.buildAuth0ClientOptions();
        auth0 = await createAuth0Client(clientOptions);

        try {
          // If the user is returning to the app after authentication...
          const hasBeenRedirected =
            window.location.search.includes("code=") &&
            window.location.search.includes("state=");

          if (hasBeenRedirected) {
            // handle the redirect and retrieve tokens
            const { appState } = await auth0.handleRedirectCallback();

            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState);

            this.accessToken = await auth0.getTokenSilently(clientOptions);
          }

          // this part will process for every refresh as well as redirect
          const hasAuthenticated = await auth0.isAuthenticated();
          if (hasAuthenticated) {
            this.user = await auth0?.getUser();
            enrichSentryUserData(this.user);
            enrichCohereUserData(this.user);
          }
        } catch (e) {
          console.error(e);
          this.error = e;
        } finally {
          // Initialize our internal authentication state
          this.loading = false;
        }
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup(opts: any) {
        return auth0!.getTokenWithPopup(opts);
      },
    },
  };
  const keycloakClientMixin = {
    methods: {
      /** Call updateToken to get new token within time */
      async getKeycloakAccessToken() {
        try {
          if (!keycloak!.isTokenExpired(KEYCLOAK_TOKEN_VALID_SECONDS)) {
            return keycloak?.token;
          }

          const result = await keycloakPromise(
            keycloak!.updateToken(KEYCLOAK_TOKEN_VALID_SECONDS)
          );

          if (result) {
            console.info("Obtained new token");
            this.accessToken = keycloak?.token ?? this.accessToken;
          }
          return this.accessToken;
        } catch {
          const url = new URL(window.location.href);
          const redirectUri = `${url.origin}/${url.hash}`;
          console.error(
            `Error while updating token, redirecting from ${redirectUri} to login page ...`
          );
          keycloak?.login({
            redirectUri,
          });
        }
      },
      async initializeKeycloakClient() {
        const keycloakConfig = await axios
          .get("keycloak.json")
          .then((response) => response.json())
          .then((config) => ({
            url: config["auth-server-url"],
            realm: config["realm"],
            clientId: config["resource"],
            credentials: config["credentials"],
          }))
          .catch(() => ({
            // fall back to `auth-type` response
            url: _.get(this.authTypeResponse, "auth-server-url"),
            realm: _.get(this.authTypeResponse, "realm"),
            clientId: _.get(this.authTypeResponse, "resource"),
          }));

        keycloak = Keycloak(keycloakConfig as any);
        keycloak.onTokenExpired = async () => {
          console.info("Token has been expired");
          this.accessToken = await this.getKeycloakAccessToken();
        };

        const predefinedInitOptions = _.get(window, "appConfig.KEYCLOAK_INIT");
        const initOptions = {
          redirectUri,
          responseMode: "query",
          checkLoginIframe: false,
          ...predefinedInitOptions,
        };

        try {
          this.loading = true;

          const authenticated = await keycloakPromise(
            keycloak.init(initOptions)
          );

          if (authenticated) {
            this.accessToken = keycloak!.token;
            this.user = await keycloakPromise(keycloak!.loadUserInfo());
            enrichSentryUserData(this.user);
            enrichCohereUserData(this.user);
            await options.store.dispatch("KEYCLOAK_LOGIN", {
              idTokenParsed: keycloak!.idTokenParsed,
            });
          }
        } catch (err) {
          console.error("Keycloak error", err);
        } finally {
          this.loading = false;
        }
      },
    },
  };

  // The 'instance' is simply a Vue object
  instance = new Vue({
    mixins: [auth0ClientMixin, keycloakClientMixin],
    data() {
      return {
        authType: null as AuthProvider | null,
        authTypeResponse: undefined,
        accessToken: undefined as string | undefined,
        loading: true,
        authenticated: false,
        user: {} as {} | undefined,
        popupOpen: false,
        error: null,
        instanceId: new Date().toISOString(),
      };
    },
    methods: {
      /** Get auth type from server */
      async getAuthType() {
        const result = await axios
          .post(config.root + "/api/auth-type", {})
          .then((response) => response.data);
        this.authType = result.authType;
        this.authTypeResponse = result;
        return result;
      },
      getLogoutRedirectSetting() {
        // for cases https://wauat...com/aff/#/... to retain /aff
        const url = new URL(window.location.href);
        url.hash = "#/";

        const urlString = url.toString();
        const logoutRedirectUrl: any = {};

        if (this.authType === AuthProvider.AUTH0) {
          logoutRedirectUrl["key"] = "returnTo";
          logoutRedirectUrl["url"] = urlString;
        } else if (this.authType === AuthProvider.KEYCLOAK) {
          logoutRedirectUrl["key"] = "redirectUri";
          logoutRedirectUrl["url"] = urlString;
        }

        return logoutRedirectUrl;
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getToken() {
        if (this.authType === AuthProvider.AUTH0) {
          this.accessToken = await this.getAuth0Token();
          return this.accessToken;
        }
        if (this.authType === AuthProvider.KEYCLOAK) {
          this.accessToken = await this.getKeycloakAccessToken();
          return this.accessToken;
        }
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(opts: any) {
        if (this.authType === AuthProvider.AUTH0) {
          auth0!.logout(opts);
        }
        if (this.authType === AuthProvider.KEYCLOAK) {
          keycloak!.logout(opts);
        }
      },
      async initializeAuthProvider() {
        if (this.authType === AuthProvider.AUTH0) {
          await this.initializeAuth0Client();

          if (!this.loading && !this.isAuthenticated) {
            auth0?.loginWithRedirect();
          }
        }
        if (this.authType === AuthProvider.KEYCLOAK) {
          await this.initializeKeycloakClient();

          if (!this.loading && (!this.isAuthenticated || !keycloak!.token)) {
            keycloak?.login();
          }
        }
      },
      getAuthClient() {
        switch (this.authType) {
          case AuthProvider.AUTH0:
            return auth0;
          case AuthProvider.KEYCLOAK:
            return keycloak;
        }
      },
      async onPostAuthenticated({ rest }) {
        // Note: we use rest here as param to completely decouple auth from api clients
        if (getAuthType() === AuthProvider.AUTH0) {
          // get dashboard user based on login
          const dashboardUser = await rest("post", "auth0-login", {
            ...this.user,
          });
          window.localStorage.setItem("loginCount", dashboardUser.loginCount);
        }
      },
    },
    /** Use this lifecycle method to instantiate the plugin */
    async created() {
      // Create a new instance of the plugin using members of the given options object
      await this.getAuthType();
      await this.initializeAuthProvider();
    },
    watch: {
      /**
       * @description After authenticated user and having user info
       * @return {void}
       */
      user: async function () {
        LocalStorageManager.createUnreadMessageList();
        await options.store.dispatch("FETCH_PROFILE");
      },
    },
    computed: {
      isAuthenticated() {
        return this.user && this.user.email;
      },
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const AuthPlugin = {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$auth = useAuth(options);
  },
};

export enum AuthProvider {
  AUTH0 = "AUTH0",
  KEYCLOAK = "KEYCLOAK",
}

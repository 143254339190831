import _ from "lodash";
interface Transformation {
  enabled: boolean;
  displayName: string;
  type: string;
  path: string;
  expectedPattern: string;
  replacement: string;
}

export function escapeSpecialCharacters(regex: string): string {
  return regex
    .replace(/\n/g, "\\n")
    .replace(/\t/g, "\\t")
    .replace(/\v/g, "\\v")
    .replace(/\0/g, "\\0")
    .replace(/\f/g, "\\f")
    .replace(/\r/g, "\\r");
}

export function convertRegexStringToRegex(regex: string) {
  const escapedPattern = escapeSpecialCharacters(regex);
  const regexMatches = escapedPattern.match(
    /(?:\\\/(.*)\\\/|\/(.*)\/|(.*))([gimsuy]*)/
  );
  if (regexMatches !== null) {
    const [
      wholeStringMatch,
      firstTypeMatch,
      secondTypeMatch,
      thirdTypeMatch,
      flags,
    ] = regexMatches;
    const regexContent = firstTypeMatch || secondTypeMatch || thirdTypeMatch;
    return new RegExp(regexContent, flags);
  }
  return;
}

export function transformData(
  value: { [key: string]: any },
  transformations: Transformation[]
) {
  return _.reduce(
    transformations,
    (accum, transformation) => {
      const expectedPattern = convertRegexStringToRegex(
        transformation.expectedPattern
      );
      const replacement = transformation.replacement;
      const paths = transformation.path.split(".");
      if (expectedPattern) {
        traversePaths(accum, paths, 0, expectedPattern, replacement);
      }
      return accum;
    },
    value
  );
}

export function traversePaths(
  value: { [key: string]: any },
  paths: string[],
  index: number,
  expectedPattern: RegExp,
  replacement: string
) {
  const data = _.get(value, paths[index]);
  if (!data) return;

  const endOfPathToTraverse = index === paths.length - 1;
  const isToBeIterated = Array.isArray(data);
  if (endOfPathToTraverse) {
    if (isToBeIterated) {
      const replacedData = _.map(data, (text) => {
        return text.replace(expectedPattern, replacement);
      });

      _.set(value, paths[index], replacedData);
    } else {
      const replacedData = data.replace(expectedPattern, replacement);

      _.set(value, paths[index], replacedData);
    }
  } else {
    let dataToProcess = data;
    if (!isToBeIterated) {
      dataToProcess = [data];
    }
    _.forEach(dataToProcess, (data) => {
      traversePaths(data, paths, index + 1, expectedPattern, replacement);
    });
  }
}

export function transformBotData(
  nodesToSave: { [key: string]: any },
  transformations: Transformation[]
) {
  // Transform all content, trigger, etc
  const transformedBotData = _.reduce(
    nodesToSave,
    (accum, data, identifier) => {
      const filteredTransformations = _.filter(
        transformations,
        (transformation) =>
          transformation.enabled && identifier.startsWith(transformation.type)
      );

      const noTransformationsToApply = filteredTransformations.length === 0;
      if (noTransformationsToApply) {
        accum[identifier] = data;
      } else {
        // Transform each individual content and trigger entry
        const clonedData = _.cloneDeep(data);
        const transformedData = _.reduce(
          clonedData,
          (accum, value, id) => {
            accum[id] = transformData(value, filteredTransformations);
            return accum;
          },
          {} as { [identifier: string]: any }
        );
        accum[identifier] = transformedData;
      }
      return accum;
    },
    {} as { [identifier: string]: any }
  );
  return transformedBotData;
}

<template>
  <el-dialog :title="formTitle" :visible="visible" :before-close="handleClose">
    <el-form ref="department" :model="formModel" label-width="120px" class="demo-dynamic">
      <el-form-item
        prop="name"
        label="Name"
        :error="$v.formModel.name.$error ? 'Invalid department name / Department name has been taken' : null"
      >
        <el-input v-model="formModel.name" placeholder="Input department name" />
      </el-form-item>
      <el-form-item prop="description" label="Description">
        <el-input
          v-model="formModel.description"
          type="textarea"
          placeholder="Input department description"
        />
      </el-form-item>

      <el-form-item
        :error="$v.formModel.meta.urls.$error ? 'URL(s) not valid.' : null"
        label="URLs"
        v-if="formModel.meta"
      >
        <div
          v-for="(url, key) in formModel.meta.urls"
          :key="`department-url-${key}`"
          style="display:flex; flex-direction: row; align-items:center; margin-top: 8px;"
        >
          <input
            v-model="formModel.meta.urls[key]"
            style="display:block; height: 30px; min-width: 300px; margin-right: 5px; padding: 0 10px;"
            :placeholder="`Input department url[${key}]`"
          />
          <el-button
            type="text"
            icon="el-icon-delete"
            size="mini"
            @click.prevent="formModel.meta.urls.splice(key, 1)"
          ></el-button>
        </div>
        <el-button @click.prevent="onAddUrl" type="text" size="mini">ADD URL</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('department')">Submit</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { required, minLength, url } from "vuelidate/lib/validators";
import { IDepartment, IAgent, IDepartmentAgent } from "@/typings/agents";
import { pick } from "lodash";
import _ from "lodash";
const AGENTS_GET = require("@/graphql/AgentsGet.graphql");

const defaultModel = {
  name: "",
  description: "",
  meta: {
    urls: []
  }
};

export default {
  props: {
    visible: {},
    propsModel: {},
    departments: {}
  },
  validations() {
    return {
      formModel: {
        name: {
          required,
          minLength: minLength(3),
          uniqDepartmentName: value => {
            const current = _.get(this.propsModel, "name", null);
            if (_.isEmpty(value) || value === current) {
              return true;
            }

            const allDepartmentsName = _.chain(this)
              .get("departments", [])
              .map(department => department.name)
              .value();

            return !_.includes(allDepartmentsName, value);
          }
        },
        meta: {
          urls: {
            $each: {
              required
            }
          }
        }
      }
    };
  },
  data() {
    return {
      formModel: {}
    };
  },
  computed: {
    formTitle() {
      return this.modelExists ? "Edit Department" : "New Department";
    },
    modelExists() {
      return !!this.propsModel;
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        if (this.modelExists) {
          this.formModel = Object.assign({}, this.propsModel);
          if (!_.has(this.propsModel, "meta.urls")) {
            this.formModel.meta = {
              urls: []
            };
          }
        } else {
          this.formModel = Object.assign({}, defaultModel);
        }
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.formModel.name = this.formModel.name.toLowerCase();
        this.$emit("submit:department", this.formModel);
        this.$emit("update:visible", false);
      }
      // const department = this.$refs[formName];
      // department.validate(valid => {
      //   if (valid) {
      //     this.$emit("submit:department", this.formModel);
      //     this.$emit("update:visible", false);
      //   }
      // });
    },
    onAddUrl() {
      this.formModel.meta.urls.push(" ");
      this.$forceUpdate();
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    formatAgent(agent) {
      return pick(agent, ["id"]);
    }
  }
};
</script>

<template>
  <div>
    <div style="margin-bottom: 10px">Output Fields</div>
    <div
      style="display: flex; align-items: flex-start; margin-bottom: 10px"
      v-for="(item, index) in fields"
      :key="index"
    >
      <el-input
        v-model="item.key"
        type="text"
        size="mini"
        style="max-width: 30%"
        placeholder="Input Field Key"
      />
      <el-input
        size="mini"
        style="max-width: 30%; margin-left: 10px"
        v-model="item.value"
        type="text"
        placeholder="Input Field Value"
      />

      <el-button
        size="mini"
        class="el-icon-delete"
        style="margin-left: 10px"
        type="text"
        @click="removeField(index)"
      />
    </div>
    <el-button
      size="mini"
      type="primary"
      plain=""
      icon="el-icon-plus"
      @click="addField"
      >New Output Field</el-button
    >
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["fields"],
  data() {
    return {};
  },
  methods: {
    addField() {
      this.$emit("addField");
    },
    removeField(index) {
      this.$emit("removeField", index);
    },
  },
};
</script>

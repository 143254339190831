<template>
  <div style="position: relative; height: 100%">
    <EditorHeader />

    <el-tabs @tab-click="onTabClick" v-model="activeTab" style="height: 100%">
      <el-tab-pane label="Nodes" name="nodes" :lazy="true">
        <NodesEditor />
      </el-tab-pane>

      <el-tab-pane
        v-if="isFlowEditorModuleEnabled"
        label="Flows"
        name="flows"
        :lazy="true"
      >
        <FlowEditor :editor-option="editorOption" />
      </el-tab-pane>

      <el-tab-pane label="Integrations" name="apieditor" :lazy="true">
        <APIIntegration :editor-option="editorOption" />
      </el-tab-pane>

      <el-tab-pane
        v-if="isWebpageEditorModuleEnabled"
        label="Web Pages"
        name="webpageeditor"
        :lazy="true"
      >
        <WebPageEditor />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import Component from "vue-class-component";
import { mapState } from "vuex";

import EditorHeader from "./Header/Index";
import FlowEditor from "@/components/FlowEditor/Index.vue";
import NodesEditor from "./Nodes";
import APIIntegration from "@/components/APIIntegration.vue";
import WebPageEditor from "@/components/WebPageEditor/Index.vue";

@Component({
  components: {
    FlowEditor,
    APIIntegration,
    EditorHeader,
    NodesEditor,
    WebPageEditor,
  },
  methods: {
    onTabClick(event) {
      if (event.name === "flows") {
        this.$router.push("/editor/flow");
      } else if (this.$route.name !== "editor") {
        this.$router.push("/editor");
      }
    },
    checkActiveTab() {
      if (this.$route.name === "editor-flow") {
        this.activeTab = "flows";
      }
      if (this.$route.name === "editor") {
        this.activeTab = "nodes";
      }
    },
  },
  mounted() {
    this.checkActiveTab();
    this.$eventBus.$on("editorSwitchTab", (tab) => {
      this.activeTab = tab;
    });
  },
  watch: {
    "$route.name": () => {
      this.checkActiveTab();
    }
  },
  computed: {
    ...mapState({
      modules: "modules",
    }),

    /**
     * @description Facebook module enabled
     * @return {boolean}
     */
    isFacebookModuleEnabled() {
      return this.modules.facebook && this.modules.facebook.enabled;
    },

    /**
     * @description Webpage module enabled
     * @return {boolean}
     */
    isWebpageEditorModuleEnabled() {
      return this.modules.webpage_editor && this.modules.webpage_editor.enabled;
    },

    /**
     * @description Floweditor module enabled
     * @return {boolean}
     */
    isFlowEditorModuleEnabled() {
      return this.modules.floweditor && this.modules.floweditor.enabled;
    },

    /**
     * @description Active Tab settings
     * @return {setter getter}
     */
    activeTab: {
      get() {
        return this.$store.state.editor_activeTab;
      },
      set(val) {
        this.$store.commit("SET_EDITOR_ACTIVETAB", val);
      },
    },
  },
})
export default class Editor extends Vue {
  // activeTab = "nodes"; // nodes | flows | persistentmenu | greeting ==> disabled for temporary
  editorOption = {
    tabSize: 2,
    mode: {
      name: "javascript",
      json: true,
    },
    lineNumbers: true,
    theme: "monokai",
    scrollbarStyle: "null",
    keyMap: "sublime",
  };
}
</script>

<style>
.row-fluid {
  white-space: nowrap;
  overflow: auto;
}
.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
</style>

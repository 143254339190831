import FileSaver from "file-saver";
import moment from "moment";
import JSZip from "jszip";
import XLSX from "xlsx";
import _ from "lodash";
import { prepareExcelText, prepareZipText } from "@/helperMethods/livechat/export";

export const livechatExportMixin = {
  computed: {
    chatSources() {
      return [
        {
          displayName: "Facebook",
          value: "facebook",
        },
        {
          displayName: "Line",
          value: "line",
        },
        {
          displayName: "Telegram",
          value: "telegram",
        },
        {
          displayName: "Webchat",
          value: "webchat",
        },
        {
          displayName: "WeChat",
          value: "wechat",
        },
        {
          displayName: "WhatsApp",
          value: "whatsapp",
        },
        {
          displayName: "Workplace",
          value: "workplace",
        },
      ];
    },
  },
  methods: {
    async exportAllChats(type) {
      const component = this as any;
      const [startFormatted, endFormatted] = component.searchChatDateRange.map((date) =>
        moment(date).format("DD-MM-YYYY")
      );
      const formattedDate =
        startFormatted === endFormatted ? startFormatted : `${startFormatted} to ${endFormatted}`;
      component.exportDialogVisible = false;
      const fetchingMessage = component.$notify.warning({
        title: "Export Warning",
        type: "warning",
        message: `Exporting all chats for ${formattedDate}, please don't close this page!`,
        duration: 0,
        position: "bottom-right",
      });

      let doneFetching = false;
      let offset = 0;
      const limit = 40;
      let aggregatedChatInteractions = [];
      while (!doneFetching) {
        try {
          const chats = await component.exportAllChatsFetch(limit, offset);
          const sortedChats = _.map(chats, (chat) => {
            chat.interactions = _.sortBy(
              chat.interactions,
              (interaction) => interaction.date_created
            );
            return chat;
          });

          aggregatedChatInteractions = aggregatedChatInteractions.concat(sortedChats as any);
          if (chats.length >= limit) {
            offset += limit;
          } else {
            doneFetching = true;
            const keysToBeExcluded = [
              "livechat_data",
              "session_history",
              "headers",
              "temp",
              "private_variables",
              "ip",
              "interactions",
            ];
            if (type === "zip") {
              const zip = new JSZip();
              aggregatedChatInteractions.forEach((chat: any) => {
                const chatText = prepareZipText(chat, keysToBeExcluded, true);
                const prefixFileName = moment(chat.last_modified).format("YYYYMMDD_HHmmss");
                const chatId = _.get(chat, "livechat_data.RowKey", chat.RowKey);
                zip.file(`${prefixFileName}_${chatId}_chat.csv`, chatText);
              });
              zip.generateAsync({ type: "blob" }).then(function (content) {
                // see FileSaver.js
                FileSaver.saveAs(content, `exportedchats_${new Date().getTime()}.zip`);
              });
            } else {
              const wb = XLSX.utils.book_new();
              aggregatedChatInteractions.forEach((chat: any, index: number) => {
                const prefixFileName = moment(chat.last_modified).format("YYYYMMDD_HHmmss");
                const ws_data = prepareExcelText(chat, keysToBeExcluded);
                const ws = XLSX.utils.aoa_to_sheet(ws_data);
                XLSX.utils.book_append_sheet(
                  wb,
                  ws,
                  `${index}_${prefixFileName}_${chat.RowKey.substring(0, 6)}`
                );
              });
              XLSX.writeFile(wb, `exportedchats_${new Date().getTime()}.xlsx`, {});
            }
            fetchingMessage.close();

            // const dateString = moment(this.filterChatDate).format("DD-MM-YYYY");
            component.$notify.success({
              title: "Export Success",
              message: `Successfully imported all chats for ${formattedDate}`,
              position: "bottom-right",
            });
          }
        } catch (err) {
          console.log("An error occurred while exporting the chats.", err);
          doneFetching = true;
          fetchingMessage.close();
          component.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `An error occurred while exporting the chats!`,
          });
        }
      }
    },
  },
};

<template>
  <section class="mini-apps-header">
    <CreateFolder v-if="!openedFolder" :folders="folders" />
    <Upload
      v-if="openedFolder || isCephStorage"
      :opened="opened"
      @refresh="$emit('refresh')"
    />
    <!-- <Download
      v-if="selectedNotEmpty || openedFolder"
      :opened-folder="openedFolder"
      :selected="selected"
    /> -->
    <Delete
      v-if="selectedNotEmpty"
      :selected="selected"
      @afterDelete="
        () => {
          $emit('refresh');
          $emit('setSelected', {});
        }
      "
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import CreateFolder from "./CreateFolder";
import Delete from "./Delete";
import Download from "./Download";
import Upload from "./Upload";

export default {
  components: {
    CreateFolder,
    Upload,
    // Download,
    Delete,
  },
  data() {
    return {};
  },
  props: ["folders", "openedFolder", "selected", "opened"],
  computed: {
    ...mapGetters(["isCephStorage"]),
    selectedNotEmpty() {
      return !_.isEmpty(this.selected);
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.mini-apps-header {
  .el-button i:not(.el-icon-loading) {
    font-size: 24px;
    margin-right: 15px;
  }
}
</style>

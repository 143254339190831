<template>
  <div>
    <el-row>
      <el-col :span="5" style="max-width: 160px">
        <label
          v-if="mode !== 'form'"
          style="vertical-align: middle;margin-left:1.6em;margin-bottom: 2em; color:#909399; fontSize: 14px"
        >
          {{ setting.label || name }}
          <el-tooltip
            v-if="setting.detail"
            effect="dark"
            :content="setting.detail"
            placement="left"
          >
            <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
          </el-tooltip>
        </label>
      </el-col>
      <el-col :span="23" style="max-width: 540px; margin-left:0.5em">
        <el-input
          v-model="value[name]"
          type="textarea"
          :placeholder="setting.placeholder"
          :autosize="{ minRows: 4 }"
          :required="setting.required"
          style="margin-bottom:1em"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name", "mode"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
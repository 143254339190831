<template>
  <div>
    <el-popover trigger="hover" placement="bottom">
      <table class="move-example-table">
        <!-- Current intent row -->
        <tr>
          <td style="font-weight: bold">Example</td>
          <td style="font-weight: bold">Target Intent</td>
          <td style="font-weight: bold">Move</td>
        </tr>
        <tr>
          <td>
            <i>"{{ scope.row.text }}"</i>
          </td>
          <td style="color: red">{{ destinationIntent().name }}</td>
          <td>
            <el-button
              type="primary"
              icon="el-icon-upload2"
              size="mini"
              circle
              @click.native="$emit('moveExample', scope.row)"
            ></el-button>
          </td>
        </tr>
        <tr>
          <td>
            <i>"{{ destinationIntent().text }}"</i>
          </td>
          <td style="color: grey">{{ selectedIntent.intentName }} (current)</td>
          <td>
            <el-button
              type="primary"
              icon="el-icon-download"
              size="mini"
              circle
              @click.native="
                $emit('moveExample', destinationIntentVariation(), destinationIntent().name)
              "
            ></el-button>
          </td>
        </tr>
      </table>
      <el-button icon="el-icon-rank" slot="reference">
        Move <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
    </el-popover>
    <el-button
      type="primary"
      icon="el-icon-delete"
      @click="$emit('deleteVariation', selectedIntent, scope.row.text)"
      style="margin-left: 10px"
    >
      Delete
    </el-button>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { faqDatasetMixin, faqConflictedMixin } from "@/mixins/faq";

export default Vue.extend({
  name: "FaqConflictedButtonAction",
  props: ["scope", "selectedIntent"],
  mixins: [faqDatasetMixin, faqConflictedMixin],
  methods: {
    destinationIntent() {
      const { intentName } = this.selectedIntent;
      const {
        row: { intent_ranking },
      } = this.scope;
      if (intentName !== intent_ranking[0].name) {
        // Go to first intent if its already not equals to current intent
        return {
          name: intent_ranking[0].name,
          text: intent_ranking[0].example,
        };
      }

      if (this.isPotentiallyConflicted(intent_ranking)) {
        // Go to second intent if it has a potential conflict
        return {
          name: intent_ranking[1].name,
          text: intent_ranking[1].example,
        };
      }
    },
    destinationIntentVariation() {
      const { name, text } = this.destinationIntent();
      const { pair } = this.getIntentVariation({ intentIdentity: name, text });
      return pair;
    },
  },
});
</script>

<style scoped>
.move-example-table tr td {
  padding: 7px;
}
</style>

<template>
  <div style="height:100%;">
    <!-- LEFT COL -->
    <el-col :span="8" :xs="24" style="height:100%;">
      <NodesList @select="selectNode" />
    </el-col>

    <!-- RIGHT COL -->
    <el-col :span="16" :xs="24" style="height:100%;overflow:auto;">
      <div class="nodes-editor">
        <ContentNodeEditor
          v-if="activeNodeType === 'content'"
          :value="activeNodeId"
          @select="selectNode"
        />
        <TriggerNodeEditor
          v-else-if="activeNodeType === 'trigger'"
          :id="activeNodeId"
          @select="selectNode"
        />
      </div>
    </el-col>
  </div>
</template>

<script>
import NodesList from "@/components/NodesList/Index.vue";
import ContentNodeEditor from "@/components/ContentNodeEditor.vue";
import TriggerNodeEditor from "@/components/TriggerNodeEditor.vue";

export default {
  name: "NodesEditor",
  components: {
    NodesList,
    ContentNodeEditor,
    TriggerNodeEditor,
  },
  props: [],
  computed: {
    activeNodeType() {
      return this.$store.state.activeNodeType;
    },
    activeNodeId() {
      return this.$store.state.activeNodeId;
    },
  },
  methods: {
    selectNode({ type, id }) {
      this.$store.dispatch("SELECT_NODE", { type, id });
    },
  },
};
</script>

<style lang="scss">
.nodes-editor {
  height: 90vh;
  overflow: auto;
  // @media screen and (max-width: 1440px) {
  //   height: 71vh;
  // }
}
</style>

import { PluginFunction } from "vue";
import { rest } from "@/store/api";
import * as Sentry from "@sentry/browser";
import _ from "lodash";

import store from "@/store";
import extractText from "./extractText";
import exportFilename from "./exportFilename";
import listen from "./listen";
import getAssetPath from "./getAssetPath";
import speak from "./speak";
import eventBus from "./eventBus";
import generatePassword from "./generatePassword";

const KeyReplyUtility: PluginFunction<{}> = function (Vue) {
  // FIXME: HP, write test for all func
  Vue.prototype.$rest = rest;
  Vue.prototype.$extractText = extractText;
  Vue.prototype.$exportFilename = exportFilename;
  Vue.prototype.$generatePassword = generatePassword;
  Vue.prototype.$listen = listen;
  Vue.prototype.$speak = speak;
  Vue.prototype.$getAssetPath = getAssetPath;
  Vue.prototype.$eventBus = eventBus;
};

// Expose global variables for dev use.
(window as any).$keyreply = {
  actions: [
    "EXPORT_FAQ_FOR_TESTING",
    "EXPORT_VARIATIONS_FOR_TESTING",
    "EXPORT_CONTENT_FOR_TESTING",
  ],

  dispatch(event: string, payload: string) {
    if (
      typeof event === "string" &&
      (window as any).$keyreply.actions.indexOf(event) > -1
    ) {
      store.dispatch(event, payload);
    } else {
      Sentry.captureMessage(`Event ${event} is not whitelisted`);
    }
  },

  search(query: string) {
    const list: string[] = [];
    _.forEach(store.state.nodes.content, (value: any, key: any) => {
      if (JSON.stringify(value.content).includes(query)) {
        list.push(`Content: ${key}`);
      }
    });

    _.forEach(store.state.nodes.trigger, (value, key) => {
      if (JSON.stringify(value).includes(query)) {
        list.push(`Trigger: ${key}`);
      }
    });
  },

  getStore() {
    return store;
  },

  rest,
};

export default KeyReplyUtility;

<template>
  <span>
    <div
      class="bubble-container"
      :class="[
        blue ? 'bubble-blue' : 'bubble-grey',
        size === 'small' ? 'small' : null,
      ]"
    >
      <div v-if="!hideText" style="text-align: left; padding: 4px" @click="editText">
        <div v-if="nodeIsInvalid" style="width: 100%; display: flex; align-items: center">
          <i
            class="el-icon-warning"
            style="z-index: 1; color: #fa5454; font-size: 1.8em; margin: 10px"
          />
          <span style="color: #fa5454; font-size: 12px" v-html="emptyTextErrorMessage"></span>
        </div>
        <div
          v-show="!edit_text"
          class="bubble-text"
          :class="[blue ? 'border-blue' : 'border-grey', nodeIsInvalid ? 'border-invalid' : '']"
          @click="editText"
        >
          <span v-if="!textArray" v-html="marked(value.text)" />
          <div v-else v-for="(text, index) in value.text" :key="index">
            <span v-html="marked(text)" />
            <hr v-if="index < value.text.length - 1" />
          </div>
        </div>
        <small v-if="showTime" class="pull-right" style="padding-right: 8px">
          {{ value.timestamp | fromNow }}
        </small>

        <div v-show="edit_text">
          <div v-if="textArray">
            <el-input
              v-for="(text, index) in value.text"
              :key="index"
              v-model="value.text[index]"
              type="textarea"
              :autosize="{ minRows: 4 }"
              placeholder="Message"
              style="margin-bottom: 4px"
            />
            <el-button-group>
              <el-button
                size="small"
                icon="el-icon-plus"
                @click="value.text.push('')"
              />
              <el-button
                size="small"
                icon="el-icon-check"
                @click="quitEditText"
              />
            </el-button-group>
          </div>
          <div v-else>
            <el-input
              v-if="editable && !textArray"
              v-model="value.text"
              type="textarea"
              :autosize="{ minRows: 4 }"
              placeholder="Message"
              @blur="quitEditText"
            />
            <el-button
              size="small"
              icon="el-icon-plus"
              @click="$emit('addVariation')"
            />
          </div>
        </div>

        <span
          v-if="editable && showTextChars"
          class="length-counter"
          :style="{
            color: text_chars > 640 ? 'red' : 'white',
            fontSize: '0.8em',
          }"
          >{{ text_chars }}/640</span
        >
      </div>
      <Dropdown
        v-if="$store.state.modules.system.enableContentNodeDropdown"
        style="margin-bottom: 10px"
        @addSelection="$emit('addSelection')"
        v-model="value.selections"
      />
      <Buttons
        v-if="size !== 'small'"
        v-model="value.buttons"
        :editable="editable"
        @addButton="$emit('addButton')"
        @save-side-effect="$emit('save-side-effect')"
      />

      <div
        v-if="quickReplies.length"
        style="
          padding: 5px;
          display: flex;
          flex-flow: row wrap;
          align-items: flex-start;
          justify-content: flex-start;
        "
      >
        <el-tag
          v-for="(quickReply, index) in quickReplies"
          :key="`qr-${index}`"
          style="
            margin-right: 3px;
            margin-bottom: 3px;
            border-radius: 12px;
            background-color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          type="info"
          size="mini"
          effect="plain"
        >
          {{ quickReply.text }}
        </el-tag>
      </div>
    </div>
  </span>
</template>

<script>
import { mdToHtml } from "@/helpers/markdown";

export default {
  name: "Bubble",
  components: {
    Buttons: () => import("@/components/Buttons/Index"),
    Dropdown: () => import("@/components/Dropdown/Index"),
  },
  props: {
    quickReplies: {
      default: [],
    },
    value: {
      default() {
        return {
          text: "",
          buttons: [],
          timestamp: null,
        };
      },
    },
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    size: {
      type: String,
    },
    blue: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    hideText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showTextChars: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    nodeIsInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit_text: false,
      emptyTextErrorMessage:
        "Unable to show buttons and quick replies if content is empty. Please add content.",
    };
  },
  computed: {
    textArray() {
      return Array.isArray(this.value.text);
    },
    valid_text() {
      return !!(
        this.value.text &&
        this.value.text.length > 0 &&
        this.value.text.length < 640
      );
    },
    valid() {
      return !!this.valid_text;
    },
    text_chars() {
      return this.value.text.length;
    },
  },
  methods: {
    marked(markdown = "Text") {
      return mdToHtml(markdown, this.agentTextColor);
    },
    editText() {
      if (this.editable) {
        this.edit_text = true;
        // this.edit_buttons.fill(false)
        this.$forceUpdate();
      }
    },
    quitEditText() {
      if (Array.isArray(this.value.text)) {
        this.value.text = this.value.text.filter((t) => t);
      }

      this.edit_text = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style>
.bubble-container.small p {
  margin: 0;
  padding: 0;
}
.bubble-container.small ol {
  margin: 0;
  -webkit-padding-start: 10px;
}
</style>

<style scoped lang="scss">
$font-stack: "Tahoma", "ProximaNova", "Montserrat", "Avenir Next", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

span:empty:before {
  content: "\200b";
}

.bubble-container {
  max-width: 500px;
  margin: 4px auto;
  border-radius: 4px;

  .bubble-text {
    padding: 8px 10px;
    font-family: $font-stack;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: text;
    &.border-grey {
      border: 1px solid lightgray;
    }
    &.border-grey:hover {
      border: 1px solid #9f9f9f;
    }

    &.border-blue {
      border: 1px solid #0084ff;
    }
    &.border-blue:hover {
      border: 1px solid #0063c0;
    }

    &.border-invalid {
      border: 1px solid #fa5454;
    }

    p {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    word-break: break-word;
  }

  .bubble-text:hover {
    border: 1px solid #9f9f9f;
    border-radius: 5px;
    cursor: text;
  }
  &.small {
    min-width: 154px;
    max-width: 200px;

    .bubble-text {
      padding: 4px;
      font-size: 12px;
    }
  }

  &.bubble-grey {
    border: lightgray;
    background-color: lightgray;
    color: #4b4f56;
  }

  &.bubble-blue {
    border: lightgray;
    background-color: #0084ff;
    color: #fff;
  }
}

.length-counter {
  position: relative;
  bottom: 0;
  left: 244px;
}
</style>

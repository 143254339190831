<template>
  <div>
    <MonitorChatFilters
      :selectedChat="selectedChat"
      @setHasNoMoreChats="hasNoMoreChats = $event"
      @actionTriggered="onActionTriggered"
    />
    <el-row>
      <el-col
        id="monitorChatLeftPane"
        class="leftPane"
        :xl="6"
        :lg="6"
        :md="8"
        :sm="24"
      >
        <el-card style="margin: 0 5px" class="box-card">
          <div slot="header" class="clearfix" style="margin-bottom: 0px">
            <span>
              {{ monitorChatsCount }} Monitor Chats
              <el-tooltip
                effect="dark"
                content="These conversation are not updated in real-time"
                placement="right"
                style="margin-left: 4px"
              >
                <i
                  class="el-icon-question"
                  style="margin-left: 0.5em; color: #909399"
                />
              </el-tooltip>
            </span>
          </div>
          <div
            v-loading="fetchMonitorChatLoading"
            element-loading-text="Loading chats"
          >
            <el-row
              v-if="!monitorChatsArray || monitorChatsArray.length === 0"
              style="text-align: center; position: relative"
            >
              <h1>There are currently no ongoing chats.</h1>
            </el-row>
            <el-row
              v-for="(chat, index) in monitorChatsArray"
              :key="`${chat.PartitionKey}_${chat.RowKey}-${index}`"
              class="item"
              effect="dark"
              placement="right"
              @click.native="selectChat(chat.PartitionKey, chat.RowKey)"
              style="margin-bottom: 4px"
              :class="
                getChatClass(
                  index,
                  chat,
                  selectedMonitorChatRowKey,
                  selectedMonitorChatPartitionKey
                )
              "
            >
              <el-row style="margin-bottom: 0">
                <el-col
                  :span="24"
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: flex;
                  "
                >
                  <ChatAvatar :chat="chat" style="display: inline-block" />
                  <span
                    style="
                      margin: 5px 0 8px;
                      font-size: 16px;
                      place-items: center;
                      display: flex;
                    "
                  >
                    {{ getChatName(chat) }}
                  </span>
                </el-col>
                <el-col
                  v-if="get(chat, 'agents.length') > 0"
                  :span="24"
                  style="
                    font-size: 0.6em;
                    color: #757575;
                    margin-top: 5px;
                    white-space: normal;
                    font-weight: 400;
                  "
                >
                  <i>Attended by {{ chat.agents.join(", ") }}</i>
                </el-col>
              </el-row>
            </el-row>
            <el-row>
              <div
                v-loading="showMoreIsLoading"
                element-loading-spinner="el-icon-loading"
                v-if="
                  monitorChatsArray &&
                  monitorChatsArray.length > 0 &&
                  !hasNoMoreChats
                "
                class="prev-conversation"
                @click="fetchMoreMonitorChats"
              >
                - show more -
              </div>
            </el-row>
          </div>
        </el-card>
      </el-col>

      <el-col class="rightPane" :xl="18" :lg="18" :md="16" :sm="24">
        <ChatsInteractions
          v-if="selectedChat"
          :chats-list="monitorChatsArray"
          :selected-chat="selectedChat"
          :selected-index.sync="selectedIndex"
          :selected-row-key.sync="selectedMonitorChatRowKey"
          :is-loading="$apollo.queries.monitorChats.loading"
          :closed.sync="closed"
        />

        <el-row v-if="$store.state.showAdvanced">
          <JSONEditor v-model="selectedChat" />
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MonitorChatFilters from "@/components/LiveChat/monitorChats/MonitorChatFilters";
import ChatsInteractions from "@/components/ChatInteractions/Index";
import JSONEditor from "@/components/JSONEditor";
import ChatAvatar from "@/components/LiveChat/ChatAvatar";
import MonitorQuery from "@/graphql/LIVECHATS_GET_MONITOR.gql";
import { pushNotification } from "@/helperMethods/pushNotification";
import {
  getDisplayId,
  getChatClass,
  getAvatar,
  getPreview,
} from "@/helperMethods/livechat/util";
import { parseMonitorChats } from "@/helperMethods/livechat/parseMonitorChats";
import { getChatName } from "@/filters/index";

import { mapGetters } from "vuex";
import _ from "lodash";
import get from "lodash/get";

export default {
  name: "MonitorChatsTab",
  components: {
    MonitorChatFilters,
    ChatsInteractions,
    JSONEditor,
    ChatAvatar,
  },
  data() {
    return {
      selectedIndex: 0,
      tncDisabled: !this.$store.state.modules.tnc.enabled,
      filterActiveUser: false,
      closed: false,
      hasNoMoreChats: false,
      showMoreIsLoading: false,
      intervalFetchMonitorChats: null,
      sessionId: "",
    };
  },
  computed: {
    ...mapGetters([
      "selectedMonitorChatPartitionKey",
      "selectedMonitorChatRowKey",
      "monitorChatFilters",
      "fetchMonitorChatLoading",
    ]),
    monitorChatsArray() {
      return this.$store.getters.monitorChatsArray;
    },
    monitorChatsCount() {
      if (_.isEmpty(this.monitorChatsArray)) {
        return 0;
      }
      return this.monitorChatsArray.length;
    },
    selectedChat() {
      return this.monitorChatsArray.find(
        (chat) =>
          chat.PartitionKey === this.selectedMonitorChatPartitionKey &&
          chat.RowKey === this.selectedMonitorChatRowKey
      );
    },
    activeUsersToolTip() {
      let tooltipMessage =
        "Active users will filter chats with TNC modules and accepted the TNC";
      if (this.tncDisabled) {
        tooltipMessage = "TnC module is disabled, default to display all users";
      }
      return tooltipMessage;
    },
  },
  mounted() {
    const sessionId = this.$route.params.sessionId;
    if (sessionId) {
      this.sessionId = sessionId;
      const cloneMonitorChatFilters = _.cloneDeep(this.monitorChatFilters);
      cloneMonitorChatFilters.sessionId = sessionId;
      cloneMonitorChatFilters.messageLimit = 200;
      this.$store.commit(
        "UPDATE_MONITOR_LIVECHAT_FILTERS",
        cloneMonitorChatFilters
      );
    }
  },
  methods: {
    getAvatar,
    getDisplayId,
    getChatClass,
    getPreview,
    get,
    getChatName,

    closeMobileChatView() {
      this.closed = false;
    },
    selectChat(partitionKey, rowKey) {
      this.$store.commit("SELECT_MONITOR_CHAT", {
        partitionKey,
        rowKey,
        type: "monitor",
      });
      // this.$store.commit("SET_SELECTED_CHAT_ID", chatId);
    },
    fetchMoreMonitorChats() {
      this.showMoreIsLoading = true;
      const cloneMonitorChatFilters = _.cloneDeep(this.monitorChatFilters);
      cloneMonitorChatFilters.limit = 20;
      cloneMonitorChatFilters.offset = this.monitorChatsArray.length;
      this.$store.commit(
        "UPDATE_MONITOR_LIVECHAT_FILTERS",
        cloneMonitorChatFilters
      );
    },
    onActionTriggered() {
      this.resetIntervalFetchMonitorChats();
    },
    fetchMonitorChats() {
      const isBusy = this.showMoreIsLoading || this.fetchMonitorChatLoading;

      if (!isBusy && !this.selectedChat) {
        const filter = _.cloneDeep(this.monitorChatFilters);
        filter.limit =
          this.monitorChatsArray.length < 20
            ? 20
            : this.monitorChatsArray.length;

        filter.offset = 0;
        this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", true);
        this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY");
        this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", filter);

        if (this.$apollo.queries.monitorChats) {
          this.$apollo.queries.monitorChats.refresh();
        }
      }
    },
    resetIntervalFetchMonitorChats() {
      this.clearIntervalFetchMonitorChats();
      this.startIntervalFetchMonitorChats();
    },
    clearIntervalFetchMonitorChats() {
      if (this.intervalFetchMonitorChats) {
        clearInterval(this.intervalFetchMonitorChats);
      }
    },
    startIntervalFetchMonitorChats() {
      this.intervalFetchMonitorChats = setInterval(
        this.fetchMonitorChats,
        30000
      );
    },
  },
  beforeDestroy() {
    this.clearIntervalFetchMonitorChats();
  },
  apollo: {
    monitorChats: {
      query: MonitorQuery,
      variables() {
        return this.monitorChatFilters;
      },
      fetchPolicy: "network-only",
      update(result) {
        // console.log("Fetched monitor Chats..");
        const rawMonitorChats = _.get(
          result,
          "livechatAPI.fetchMonitorChats",
          []
        );
        // TODO: to remove and put in server side. Will affect chatsInteraction
        const fetchMonitorChatsArray = parseMonitorChats(rawMonitorChats);
        this.$store.commit("SET_MONITOR_CHATS_ARRAY", {
          monitorChatsArray: fetchMonitorChatsArray,
        });

        if (this.sessionId) {
          if (fetchMonitorChatsArray.length > 0) {
            this.$store.commit(
              "SELECT_MONITOR_CHAT_BY_SESSION_ID",
              this.sessionId
            );
          } else {
            const pushNotifMessage = {
              title: "Cannot find selected chat",
              icon: "./img/icons/mstile-150x150.png",
            };
            pushNotification(pushNotifMessage, 2000, () => {
              window.focus();
            });
            _.set(this.sessionId, "");
            this.$router.replace("/livechat").catch(() => {});
          }
        }

        this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", false);
        this.showMoreIsLoading = false;

        if (fetchMonitorChatsArray.length < this.monitorChatFilters.limit) {
          // to hide show more if no more chats available.
          this.hasNoMoreChats = true;
        }

        return fetchMonitorChatsArray;
      },
    },
  },
};
</script>
<style scoped>
@media (min-width: 580px) and (max-width: 1024px) {
  .leftPane .box-card {
    height: auto;
  }
  .leftPane,
  .rightPane {
    height: auto !important;
  }
}

.leftPane,
.rightPane {
  height: 73vh;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1024px) {
  .leftPane,
  .rightPane {
    height: 76vh;
  }
}

@media only screen and (min-width: 1280px) {
  .leftPane,
  .rightPane {
    height: 79vh;
  }
}

@media only screen and (min-width: 1440px) {
  .leftPane,
  .rightPane {
    height: 81vh;
  }
}

.leftPane .box-card {
  height: 100%;
}

.box-card {
  border-color: transparent;
  height: 100%;
}

.leftPane .el-tab-pane {
  padding: 0;
}

.mark {
  margin-top: 8px;
  margin-left: 3px;
  line-height: 1;
  float: right;
}
.list-item {
  width: 100%;
  padding: 8px;
  border: 1px solid #e0e6ed;
  /* line-height: 32px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.box-card {
  border-color: transparent;
}

.active-chat {
  font-weight: 600;
  /* background: #f6f8f9; */
  background: #d0dfff;
  border: solid black 1px;
}
.joined-chat {
  /* background: rgb(255, 251, 212); */
  background: #f5f7fd;
}

.prev-conversation {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin: 8px auto 24px;
  font-size: 12px;
  cursor: pointer;
}

.list-item.appointment-chat {
  background: #fef0f0;
  border-color: #fbc4c4;
}
</style>

<style>
#monitorChatLeftPane .el-card__body {
  height: 90%;
  overflow: auto;
}
</style>

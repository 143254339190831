import { MakerCheckerState } from "./types";

const state: MakerCheckerState = {
  pendingChanges: [],
  completedChanges: [],
  userAuthentication: {},
  mainType: "",
  currentDepartment: "",
  activeLeftPane: "pending",
  completedLoading: true,
  pendingLoading: true,
};

export default state;

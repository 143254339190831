import { MutationTree } from "vuex/types";

import { LivechatState, AgentStatusType } from "./types";
import Vue from "vue";
import _ from "lodash";
import { getSelectedChatArrays } from "@/helperMethods/livechat/util";

export type LivechatType = "monitor" | "queue" | "resolved";

const mutations: MutationTree<LivechatState> = {
  SET_CONNECTED_AGENTS: (state, payload) => {
    Vue.set(state, "connectedAgents", payload);
  },
  SET_SOCKET_CONNECTION_STATUS: (state, payload: string) => {
    Vue.set(state, "socketConnection", payload);
  },
  SET_AGENT_STATUS: (state, payload: AgentStatusType) => {
    Vue.set(state, "agentStatus", payload);
  },
  SET_ACTIVE_TAB_NAME(state, activeTabName) {
    Vue.set(state, "activeTabName", activeTabName);
  },
  SET_SELECTED_CHAT_ID(state, chatId) {
    Vue.set(state, "selectedChatId", chatId);
  },
  SELECT_MONITOR_CHAT(
    state,
    { partitionKey, rowKey }: { partitionKey: string; rowKey: string }
  ) {
    const selectedChats = getSelectedChatArrays("monitor");

    const selectedChat = _.find(selectedChats, (chat) => {
      const currentRowKey = _.get(chat, "RowKey"); // Queue & Resolved
      const currentPartitionKey = _.get(chat, "PartitionKey");
      return currentRowKey === rowKey && currentPartitionKey === partitionKey;
    });

    if (!selectedChat) {
      Vue.set(state, "selectedMonitorChatPartitionKey", "");
      Vue.set(state, "selectedMonitorChatRowKey", "");
      return;
    }

    Vue.set(state, "selectedMonitorChatPartitionKey", partitionKey);
    Vue.set(state, "selectedMonitorChatRowKey", rowKey);
  },

  SELECT_MONITOR_CHAT_BY_SESSION_ID(state, sessionId: string) {
    const monitorChats = getSelectedChatArrays("monitor");
    const selectedChat = _.find(monitorChats, (chat) => {
      const chatSessionId = _.get(chat, "sessionId");
      return sessionId === chatSessionId;
    });
    if (!selectedChat) {
      Vue.set(state, "selectedMonitorChatPartitionKey", "");
      Vue.set(state, "selectedMonitorChatRowKey", "");
      return;
    }

    Vue.set(
      state,
      "selectedMonitorChatPartitionKey",
      _.get(selectedChat, "PartitionKey")
    );
    Vue.set(state, "selectedMonitorChatRowKey", _.get(selectedChat, "RowKey"));
  },

  SELECT_LIVE_CHAT(
    state,
    { chatId, type }: { chatId: string; type: LivechatType }
  ) {
    const selectedChats = getSelectedChatArrays(type);

    const selectedChat: any = _.find(selectedChats, (chat) => {
      const currentChatId = _.get(chat, "RowKey", ""); // Queue & Resolved
      // const currentChatId = _.get(chat, "RowKey", chatSessionId); // Monitor
      return currentChatId === chatId;
    });

    if (!selectedChat) {
      Vue.set(state, "selectedChatId", "");
      return;
    }

    Vue.set(state, "selectedChatId", chatId);
  },

  SET_QUEUE_CHAT_OBJECT(state, { queueChatObject }) {
    Vue.set(state, "queueChatObject", queueChatObject);
  },

  SET_RESOLVED_CHATS_ARRAY(state, { resolvedChatsArray }) {
    const stateResolvedChatsArray = _.cloneDeep(state.resolvedChatsArray);
    stateResolvedChatsArray.push(...resolvedChatsArray);
    Vue.set(
      state,
      "resolvedChatsArray",
      _.uniqBy(stateResolvedChatsArray, "RowKey")
    );
  },

  SET_MONITOR_CHATS_ARRAY(state, { monitorChatsArray }) {
    const stateMonitorChatsArray = _.cloneDeep(state.monitorChatsArray);
    stateMonitorChatsArray.push(...monitorChatsArray);
    Vue.set(state, "monitorChatsArray", stateMonitorChatsArray);
  },

  FLUSH_MONITOR_CHATS_ARRAY(state) {
    Vue.set(state, "monitorChatsArray", []);
  },

  FLUSH_RESOLVED_CHATS_ARRAY(state) {
    Vue.set(state, "resolvedChatsArray", []);
  },

  // Used for queue 'show more chats' function
  APPEND_QUEUE_CHAT_OBJECT(state, { queueChatObject }) {
    const newQueueChatObject = Object.assign(
      {},
      state.queueChatObject,
      queueChatObject
    );
    Vue.set(state, "queueChatObject", newQueueChatObject);
  },
  UPDATE_TYPING_INDICATOR_FOR_CHAT(state, { user_id, isTyping }) {
    Vue.set(state.typingIndicatorUserIds, user_id, isTyping);
  },
  UPDATE_QUEUE_CHAT(state, { updatedLivechat }) {
    // TODO: get livechats
    const chatId = updatedLivechat.RowKey;
    const targetLivechat = state.queueChatObject[chatId];
    if (!targetLivechat) {
      Vue.set(state.queueChatObject, chatId, updatedLivechat);
    } else {
      // this helps to prevent the object assignment below from overwriting valid form data
      const updatedFormData = updatedLivechat.formData;
      const targetFormData = targetLivechat.formData;
      const newFormdata = Object.assign({}, updatedFormData, targetFormData);

      // formData order is swapped to ensure that input from visitor profile form is reflected
      // as it is overwritten in the above object assignment
      const newLivechatObject = Object.assign(
        {},
        targetLivechat,
        updatedLivechat,
        { formData: newFormdata }
      );
      Vue.set(state.queueChatObject, chatId, newLivechatObject);
    }
  },
  UPDATE_RESOLVED_CHAT(state, { updatedLivechat }) {
    const chatId = updatedLivechat.RowKey;
    const resolvedChats = _.cloneDeep(state.resolvedChatsArray);
    const targetLivechatIndex = resolvedChats.findIndex(
      (chat) => chat.RowKey === chatId
    );
    if (targetLivechatIndex < 0) {
      return;
    }

    resolvedChats.splice(targetLivechatIndex, 1, updatedLivechat);
    Vue.set(state, "resolvedChatsArray", resolvedChats);
  },
  UPDATE_MONITOR_CHAT(state, { updatedLivechat }) {
    const chatId = updatedLivechat.RowKey;
    const targetLivechatIndex = _.findIndex(
      state.monitorChatsArray,
      (chatObj) => chatObj.RowKey === chatId
    );
    if (targetLivechatIndex === -1) {
      return;
    }
    const targetLiveChat = state.monitorChatsArray[targetLivechatIndex];
    const newLivechatObject = Object.assign(
      {},
      targetLiveChat,
      updatedLivechat
    );
    Vue.set(state.monitorChatsArray, targetLivechatIndex, newLivechatObject);
  },

  UPDATE_MONITOR_CHATS(state, { updatedLivechat }) {
    // TODO: get livechats
    // get the one livechat element
    // update array
    // set array back into state
  },

  REMOVE_QUEUE_CHAT(state, { resolvedChatId }) {
    const newQueueChatObject = _.cloneDeep(state.queueChatObject);
    if (newQueueChatObject[resolvedChatId]) {
      newQueueChatObject[resolvedChatId].isRemoved = true;
      Vue.set(state, "queueChatObject", newQueueChatObject);
    }
  },

  ADD_QUEUE_CHAT(state, { newChat }) {
    const newQueueChatObject = _.merge({}, state.queueChatObject, {
      [newChat.RowKey]: newChat,
    });

    Vue.set(state, "queueChatObject", newQueueChatObject);
  },

  ADD_RESOLVED_CHAT(state, { resolvedChat }) {
    const stateResolvedChatsArray = _.cloneDeep(state.resolvedChatsArray);
    if (stateResolvedChatsArray.length > 0) {
      stateResolvedChatsArray.unshift(resolvedChat.chat);
      Vue.set(state, "resolvedChatsArray", stateResolvedChatsArray);
    }
  },

  SET_FETCH_QUEUE_CHAT_LOADING(state, isLoading) {
    Vue.set(state, "fetchQueueChatLoading", isLoading);
  },
  SET_FETCH_RESOLVED_CHAT_LOADING(state, isLoading) {
    Vue.set(state, "fetchResolvedChatLoading", isLoading);
  },
  SET_FETCH_MONITOR_CHAT_LOADING(state, isLoading) {
    Vue.set(state, "fetchMonitorChatLoading", isLoading);
  },
  RESET_ALL_FILTERS(state, payload) {
    const defaultFilters = state.defaultFilters;
    const resolvedChatFilters = state.resolvedChatFilters;
    Vue.set(state, "queueChatFilters", {
      searchTerm: "",
      chatSources: [],
    });
    Vue.set(state, "resolvedChatFilters", resolvedChatFilters);
    Vue.set(state, "monitorChatFilters", defaultFilters);
  },

  UPDATE_QUEUE_LIVECHAT_FILTERS(state, queueChatFilter) {
    Vue.set(state, "queueChatFilters", queueChatFilter);
  },
  UPDATE_RESOLVED_LIVECHAT_FILTERS(state, resolvedChatFilter) {
    Vue.set(state, "resolvedChatFilters", resolvedChatFilter);
  },
  UPDATE_MONITOR_LIVECHAT_FILTERS(state, monitorChatFilter) {
    Vue.set(state, "monitorChatFilters", monitorChatFilter);
  },
  UPDATE_ALL_FIELD_DATA(state, payload) {
    Vue.set(state, "fieldData", payload);
  },
};

export default mutations;

import LocalStorageManager from "@/localStorageManager";
import { jsonParse } from "@/helpers/parser";
import moment from "moment";
const storageKey = "networkStatuses";

export function trackNetworkStatus(isOnline) {
  let records = getTrackedNetworkStatus();
  const record = { t: moment(), s: isOnline };
  records.push(record);
  // keep last 100 records
  records = records.slice(-100);
  LocalStorageManager.setItem(storageKey, JSON.stringify(records));

  return record;
}

export function getTrackedNetworkStatus() {
  const savedRecords = LocalStorageManager.getItem(storageKey);
  let records: any = [];
  if (savedRecords) {
    records = jsonParse(savedRecords);
  }
  return records;
}

export function clearTrackedNetworkStatus() {
  LocalStorageManager.removeItem(storageKey);
}

<template>
  <div>
    <span
      style="margin-left:1.5em;margin-right: 3.5em;color:#909399; fontSize: 14px; margin-bottom:1em"
    >{{ setting.label || name }}</span>
    <el-button
      :disabled="setting.disabled ? true : false"
      :type="setting.buttonType ? setting.buttonType : ''"
      :size="setting.size ? setting.size : ''"
      @click="setting.onClick"
    >{{ setting.text }}</el-button>
    <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="right">
      <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
    </el-tooltip>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
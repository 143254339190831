<template>
  <!-- Deprecated -->
  <div>
    <el-container v-if="!isLoading && isAzureStorage" class="apps-container">
      <AzureStorageFileUpload />
    </el-container>
    <el-container v-else-if="!isLoading && isCephStorage">
      <CephStorageFileUpload />
    </el-container>
  </div>
</template>
<script>
import AzureStorageFileUpload from "@/components/Miniapps/AzureStorageFileUpload";
import CephStorageFileUpload from "@/components/Miniapps/CephStorageFileUpload";

import { mapGetters } from "vuex";

export default {
  components: {
    AzureStorageFileUpload,
    CephStorageFileUpload,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["storageType", "isAzureStorage", "isCephStorage"]),
  },
  mounted() {
    this.isLoading = true;
    this.$store
      .dispatch("GET_STORAGE_TYPE")
      .then((storageType) => {
        if (storageType) {
          this.$notify.success({
            title: "Success",
            message: `Loaded ${storageType} Storage successfully`,
            position: "bottom-right",
          });
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        this.$notify.error({
          title: "Error",
          message: "Encountered error fetching storage type",
          position: "bottom-right",
        });
      });
  },
};
</script>

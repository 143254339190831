
<template>
  <el-card class="broadcast-list-card">
    <div slot="header" class="clearfix">
      <h3 style="line-height: 36px;">Broadcasts</h3>
      <router-link :to="'/broadcast/'">
        <el-button style="float: right;" @click="clearSelectedBroadcast">
          <i class="ion ion-plus-round" /> New Broadcast
        </el-button>
      </router-link>
    </div>
    <div style="overflow-y:auto">
      <el-table
        v-loading="loading"
        element-loading-text="Loading broadcasts..."
        highlight-current-row
        :row-class-name="highlightPendingBroadcasts"
        empty-text="No broadcasts"
        class="broadcast-list"
        :data="allBroadcasts"
        :show-header="false"
        @current-change="selectRow"
      >
        <el-table-column width="150">
          <template slot-scope="scope">
            <i class="ion ion-chevron-right" />
            <span style="line-height: 24px;">
              {{
              scope.row.timestamp | moment
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-tag
              v-if="isPast(scope.row.timestamp)"
              type="info"
              style="float: right;"
            >{{ scope.row.reach }} REACHED</el-tag>
            <el-tag v-else style="float: right;">PENDING</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import {
  isPast,
  highlightPendingBroadcasts
} from "@/helperMethods/broadcast/index";

export default {
  computed: {
    ...mapGetters([
      "selectedBroadcast",
      "selectedBroadcastId",
      "broadcastObjects",
      "broadcastArray"
    ]),
    loading() {
      const broadcasts = this.broadcastArray || [];
      const broadcastsLoading = broadcasts.length === 0;
      return broadcastsLoading;
    },
    ready() {
      return Object.keys(this.$store.state.broadcast.broadcasts).length > 0;
    },
    allBroadcasts() {
      const broadcastTimestamps = Object.keys(this.broadcastObjects);
      const broadcasts = broadcastTimestamps
        .sort((a, b) => {
          return Number(b) - Number(a);
        })
        .map(timestamp => {
          const reachNum = _.get(
            this.broadcastObjects,
            `[${timestamp}].reach`,
            null
          );
          const broadcastListObject = {
            timestamp,
            reach: reachNum
          };
          return broadcastListObject;
        });
      return broadcasts;
    }
  },
  filters: {
    moment: function(date) {
      return moment(date, 'x')
        .local()
        .format("D MMM h:mmA");
    }
  },
  methods: {
    highlightPendingBroadcasts: highlightPendingBroadcasts,
    isPast: isPast,
    clearSelectedBroadcast() {
      this.$store.commit("CLEAR_SELECTED_BROADCAST");
    },
    selectRow(row) {
      if (row) {
        this.$store.commit("SET_SELECTED_BROADCAST", row.timestamp);
        this.$router.push("/broadcast/" + row.timestamp);
      }
    }
  }
};
</script>

<style scoped>
.node_column {
  height: 100%;
  overflow: auto;
}

.broadcast-list i {
  opacity: 0;
  margin-right: 2px;
}

.broadcast-list.router-link-active i {
  opacity: 1;
  color: black;
}

@media only screen and (max-height: 640px) {
  .broadcast-list-card {
    height: 500px;
  }
}

@media only screen and (min-height: 640px) {
  .broadcast-list-card {
    height: 700px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 740px) {
  .broadcast-list-card {
    height: 900px;
    overflow-y: auto;
  }
}
</style>
<style>
.el-table .warning-broadcast-row {
  background: oldlace;
}
.el-table .past-broadcast-row {
  background: #dfdfdf;
}
</style>
<template>
  <el-dialog
    :visible.sync="topicModelDialogVisible"
    :width="getAppropriateWidth()"
    top="20vh"
    :show-close="true"
    :before-close="handleClose"
    custom-class="previous-bot-dialog-popup"
    body-style="padding: 5px 20px 5px"
  >
    <FaqTestFileUploadCollapse v-if="$store.getters.evaluationModuleEnabled" />
    <el-table
      id="previous-bot-popup"
      :data="modelsToLoad()"
      max-height="340"
      :row-class-name="highlightCurrentPublished"
      highlight-current-row
      style="text-align:left"
      size="mini"
    >
      <el-table-column
        v-if="$store.state.faq.currentViewingTopic"
        prop="topic"
        header-align="left"
        label="Topic"
      />

      <el-table-column prop="modelId" header-align="left" label="ID" />

      <el-table-column
        prop="trainedFromNow"
        header-align="left"
        label="Trained"
        :width="120"
      >
        <template slot-scope="props">
          <strong v-if="checkPublished(props.row.modelId)">
            {{ props.row.trainedFromNow }}
          </strong>
          <span v-else>{{ props.row.trainedFromNow }}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="$store.getters.evaluationModuleEnabled"
        header-align="center"
        :width="150"
        align="center"
      >
        <!-- Custom column header -->
        <template slot="header">
          <span>Performance</span>

          <el-tooltip
            content="Performance: % based on intent matched against test dataset. Test duration will differ with size of test file"
            placement="top"
          >
            <i class="el-icon-question" />
          </el-tooltip>
        </template>
        <template slot-scope="props">
          <el-button
            v-if="modelUnevaluated(props.row)"
            :loading="evaluateLoading"
            size="mini"
            @click="evaluate(props.row.modelId)"
            >Test</el-button
          >
          <div v-else>
            <span>{{ formatConfidence(props.row.confidence) }}</span>
            <el-tooltip
              :content="`Model performance is lower than current model`"
              placement="top"
              v-if="
                $store.getters.evaluationModuleEnabled &&
                  modelConfidenceBelowPublished(props.row, models)
              "
            >
              <i style="color:red;margin-left:4px" class="el-icon-warning"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column :width="120">
        <template slot-scope="props">
          <!-- Published version -->
          <b
            v-if="checkPublished(props.row.modelId)"
            style="color:green;vertical-align:middle;"
            >Published</b
          >

          <!-- All OK to publish -->
          <el-button
            v-else
            :loading="$store.state.faq.publishingInProgress"
            size="mini"
            @click="publish(props.row.modelId)"
            >Publish</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer></el-footer>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import gql from "graphql-tag";
import moment from "moment";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  formatConfidence,
  modelUnevaluated,
  modelConfidenceBelowThreshold,
  modelConfidenceBelowPublished,
} from "@/helperMethods/faq/util";
import FaqTestFileUploadCollapse from "@/components/Faq/FaqTestFileUpload.vue";

export default {
  components: { FaqTestFileUploadCollapse },
  data() {
    return {
      models: [],
      evaluateLoading: false,
    };
  },
  props: ["topicModelDialogVisible"],
  computed: {
    ...mapGetters(["rasaEnabled", "elsaEnabled", "getPublishingInProgress"]),

    currentViewingTopic() {
      return _.get(this, "$store.state.faq.currentViewingTopic", "");
    },

    classifierThreshold() {
      if (this.rasaEnabled) {
        return _.get(this, "$store.state.modules.faq.Rasa.threshold", 0.2);
      } else {
        return _.get(this, "$store.state.modules.faq.Elsa.threshold", 0.2);
      }
    },
  },

  methods: {
    setTrainingInProgress(inProgress) {
      this.$store.commit("SET_TRAINING_IN_PROGRESS", inProgress);
    },

    setPublishingInProgress(inProgress) {
      this.$store.commit("SET_PUBLISHING_IN_PROGRESS", inProgress);
    },

    handleClose() {
      this.$emit("toggleTopicFaqModelListDialogVisible", false);
    },

    formatConfidence(confidence) {
      return formatConfidence(confidence);
    },

    modelUnevaluated(row) {
      return modelUnevaluated(row);
    },

    modelConfidenceBelowThreshold(row, threshold) {
      return modelConfidenceBelowThreshold(row, threshold);
    },

    modelConfidenceBelowPublished(row, models) {
      return modelConfidenceBelowPublished(row, models);
    },

    getAppropriateWidth() {
      const isWiderDialog = this.$store.getters.evaluationModuleEnabled;
      return isWiderDialog ? "45%" : "40%";
    },

    highlightCurrentPublished({ row, rowIndex }) {
      const rowModelId = row.modelId;
      if (this.checkPublished(rowModelId)) {
        return "published-model";
      }
      return "";
    },

    checkPublished(rowModelId) {
      let status = false;

      if (this.rasaEnabled) {
        const { topicModelIds } = _.get(
          this,
          "$store.state.modules.faq.Rasa",
          {}
        );

        status = rowModelId === topicModelIds[this.currentViewingTopic];
      } else if (this.elsaEnabled) {
        const { publishedModelIds } = _.get(
          this,
          "$store.state.modules.faq.Elsa",
          {}
        );

        status = rowModelId === publishedModelIds[this.currentViewingTopic];
      }

      return status;
    },

    modelsToLoad() {
      if (this.elsaEnabled) {
        const modelsWithTopics = _.map(this.models, (model) => {
          return Object.assign({}, model, {
            trainedFromNow: moment(model.timestamp).fromNow(),
            topic: this.currentViewingTopic,
          });
        });
        return modelsWithTopics;
      }

      if (this.currentViewingTopic) {
        return _.filter(this.models, { topic: this.currentViewingTopic });
      }
      return _.filter(this.models, { topic: "model" });
    },

    async publish(modelId) {
      this.setPublishingInProgress(true);
      const topic = this.currentViewingTopic;

      await this.$apollo.mutate({
        mutation: gql`
          mutation($modelId: String!, $topic: String!) {
            faqAPI {
              publishByTopic(modelId: $modelId, topic: $topic)
            }
          }
        `,
        variables: {
          modelId,
          topic,
        },
        update: (
          store,
          {
            data: {
              faqAPI: { publishByTopic },
            },
          }
        ) => {
          if (publishByTopic && publishByTopic.success) {
            if (publishByTopic.Elsa) {
              const copyPublishedModelIds = _.cloneDeep(
                _.get(
                  this,
                  "$store.state.modules.faq.Elsa.publishedModelIds",
                  {}
                )
              );
              copyPublishedModelIds[this.currentViewingTopic] = modelId;

              this.$set(
                this.$store.state.modules.faq.Elsa,
                "publishedModelIds",
                copyPublishedModelIds
              );
            } else if (publishByTopic.Rasa) {
              const copyTopicModelIds = _.cloneDeep(
                _.get(this, "$store.state.modules.faq.Rasa.topicModelIds", {})
              );
              copyTopicModelIds[this.currentViewingTopic] = modelId;

              this.$set(
                this.$store.state.modules.faq.Rasa,
                "topicModelIds",
                copyTopicModelIds
              );
            }

            this.$notify.success({
              title: "Success",
              message: "Model published",
              position: "bottom-right",
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Failed to publish model",
              position: "bottom-right",
            });
          }
        },
      });
      this.setPublishingInProgress(false);
    },

    evaluate(modelId) {
      // dispatch evaluate
      this.evaluateLoading = true;
      this.setTrainingInProgress(true);
      this.$store
        .dispatch("FAQ_TEST_MODEL_WITH_TEST_DATA", {
          modelId,
        })
        .then((updatedDataset) => {
          if (updatedDataset) {
            this.$notify.success({
              title: "Evaluation Success",
              position: "bottom-right",
              message: `Successfully tested ${modelId}`,
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Evaluation Error",
            position: "bottom-right",
            message: `Encountered error testing model`,
          });
        })
        .finally(() => {
          this.evaluateLoading = false;
          this.setTrainingInProgress(false);
        });
    },
  },

  apollo: {
    models() {
      return {
        query: gql`
          query($modelName: String) {
            faqAPI {
              models: getModels(modelName: $modelName)
            }
          }
        `,
        variables() {
          return {
            modelName: this.currentViewingTopic,
          };
        },
        fetchPolicy: "cache-and-network",
        update: (data) => {
          if (!data.faqAPI) return [];

          let models = data.faqAPI.models;

          if (this.elsaEnabled) return models;

          models = _.map(models, (model) => {
            const splitByUnderscoreRegex = /[^_]*/;
            const topic = splitByUnderscoreRegex.exec(model.modelId)[0];
            return Object.assign({}, model, {
              trainedFromNow: moment(
                model.timestamp,
                "YYYY-MM-DDTHH:mm:ss"
              ).fromNow(),
              topic,
            });
          });

          return models;
        },
        subscribeToMore: {
          document: gql`
            subscription {
              modelList: modelListChanged
            }
          `,
          updateQuery: (previousResult, { subscriptionData }) => {
            const modelList = _.get(subscriptionData, "data.modelList");
            return { faqAPI: { models: modelList } };
          },
        },
      };
    },
  },
};
</script>

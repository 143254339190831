<template>
  <div>
    <SearchChatFilters @resetResolvedChatFilters="reset" tab="resolved" />
    <el-row>
      <el-col
        id="resolvedChatLeftPane"
        class="leftPane"
        :xl="6"
        :lg="6"
        :md="8"
        :sm="24"
      >
        <el-card class="box-card" style="margin: 0 5px">
          <div slot="header" class="clearfix">
            <span>{{ resolvedBadgeNumber || 0 }} Resolved Chats</span>
          </div>
          <ResolvedChatsList
            @fetchMoreResolvedChats="fetchMoreResolvedChats"
            :selected-index.sync="selectedIndex"
            :show-more-is-loading="showMoreIsLoading"
            :has-no-more-chats="hasNoMoreChats"
          />
        </el-card>
      </el-col>

      <el-col
        v-if="selectedIndex >= 0"
        class="rightPane"
        :xl="18"
        :lg="18"
        :md="16"
        :sm="24"
      >
        <ChatsInteractions
          v-if="selectedChat"
          :chats-list="resolvedChatsArray"
          :selected-chat="selectedChat"
          :selected-index.sync="selectedIndex"
          :is-loading="fetchResolvedChatLoading"
          :closed.sync="closed"
        />
      </el-col>
    </el-row>
    <el-row v-if="$store.state.showAdvanced">
      <JSONEditor v-model="selectedChat" />
    </el-row>
  </div>
</template>

<script>
import SearchChatFilters from "./Filter.vue";
import ResolvedChatsList from "./List.vue";
import ChatsInteractions from "@/components/ChatInteractions/Index";
import JSONEditor from "@/components/JSONEditor";
import FETCH_RESOLVED_CHATS_QUERY from "@/graphql/LIVECHATS_GET_RESOLVED.gql";
import { getAvatar } from "@/helperMethods/livechat/util";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "ChatsTab",
  components: {
    SearchChatFilters,
    ResolvedChatsList,
    ChatsInteractions,
    JSONEditor,
  },
  props: { resolvedChatLoading: Boolean },
  data() {
    return {
      selectedIndex: 0,
      closed: false,
      type: { name: "resolved" },
      hasNoMoreChats: false,
      showMoreIsLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "fetchResolvedChatLoading",
      "resolvedChatTags",
      "resolvedChatsArray",
      "resolvedChatFilters",
      "resolvedBadgeNumber",
      "selectedChatId",
      "selectedChat",
    ]),
  },

  methods: {
    getAvatar,
    reset() {
      this.$store.commit("FLUSH_RESOLVED_CHATS_ARRAY");
      this.hasNoMoreChats = false;
    },
    fetchMoreResolvedChats() {
      this.showMoreIsLoading = true;
      const filters = _.cloneDeep(this.resolvedChatFilters);
      filters.offset = this.resolvedChatsArray.length;
      this.$store.commit("UPDATE_RESOLVED_LIVECHAT_FILTERS", filters);
    },
  },
  apollo: {
    resolvedChats: {
      query: FETCH_RESOLVED_CHATS_QUERY,
      variables() {
        return this.resolvedChatFilters;
      },
      fetchPolicy: "network-only",
      update(result) {
        const newResolvedSessions = _.get(
          result,
          "livechatAPI.resolvedSessions",
          []
        );

        if (newResolvedSessions.length < this.resolvedChatFilters.limit) {
          this.hasNoMoreChats = true;
        }

        this.$store.commit("SET_RESOLVED_CHATS_ARRAY", {
          resolvedChatsArray: newResolvedSessions,
        });
        this.$store.commit("SET_FETCH_RESOLVED_CHAT_LOADING", false);
        this.showMoreIsLoading = false;
      },
    },
  },
};
</script>

<style scoped>
@media (min-width: 580px) and (max-width: 1024px) {
  .leftPane .box-card {
    height: auto;
  }
  .leftPane,
  .rightPane {
    height: auto !important;
  }
}

.leftPane,
.rightPane {
  height: 73vh;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1024px) {
  .leftPane,
  .rightPane {
    height: 76vh;
  }
}

@media only screen and (min-width: 1280px) {
  .leftPane,
  .rightPane {
    height: 79vh;
  }
}

@media only screen and (min-width: 1440px) {
  .leftPane,
  .rightPane {
    height: 81vh;
  }
}

.leftPane .box-card {
  height: 100%;
}

.box-card {
  border-color: transparent;
  height: 100%;
}

.leftPane .el-tab-pane {
  padding: 0;
}

.mark {
  margin-top: 8px;
  margin-left: 3px;
  line-height: 1;
  float: right;
}
</style>

<style>
/* TODO: remove once not needed by avatar */
.img-circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;

  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.leftPane .el-card__body {
  /* height: 90%; */
  overflow: auto;
}

#resolvedChatLeftPane .el-card__body {
  height: 90%;
  overflow: auto;
}

.listTitle {
  border-bottom: 1px solid #ebeef5;
  padding-bottom: 5px;
}
</style>

export default async function () {
  const algoKeyGen = {
    name: "AES-GCM",
    length: 256,
  };
  const key = await window.crypto.subtle.generateKey(algoKeyGen, true, [
    "encrypt",
  ]);
  return key;
}

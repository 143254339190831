import { graph, rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { FaqState } from "./types";
import gql from "graphql-tag";
import _ from "lodash";

const actions: ActionTree<FaqState, RootState> = {
  FAQ_ADD_NEW_INTENT: (store, { intent }) => {
    return store.commit("SET_ADD_NEW_INTENT", intent);
  },
  FAQ_TEST_TRAINING_DATA: (
    state,
    { intents, latestModelId, isIgnoreFallbackOthersIntent }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $intents: [JSON]
            $latestModelId: String
            $isIgnoreFallbackOthersIntent: Boolean
          ) {
            faqAPI {
              testTrainingData(
                intents: $intents
                latestModelId: $latestModelId
                isIgnoreFallbackOthersIntent: $isIgnoreFallbackOthersIntent
              )
            }
          }
        `,
        variables: { intents, latestModelId, isIgnoreFallbackOthersIntent },
      })
      .then((response) => {
        const data = _.get(response, "data.faqAPI.testTrainingData");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  FAQ_TEST_MODEL_WITH_TEST_DATA: (state, { modelId }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($modelId: String!) {
            faqAPI {
              testModelIdWithTestData(modelId: $modelId)
            }
          }
        `,
        variables: { modelId },
      })
      .then((response) => {
        const data = _.get(response, "data.faqAPI.testModelIdWithTestData");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  FAQ_GET_UNLABELED_MESSAGES_BY_SEARCH: ({ commit, state }, { searchTerm }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($searchTerm: String!) {
            faqAPI {
              getUnlabeledMessagesBySearch(searchTerm: $searchTerm)
            }
          }
        `,
        variables: { searchTerm },
      })
      .then((response) => {
        const data = _.get(
          response,
          "data.faqAPI.getUnlabeledMessagesBySearch"
        );
        commit("SET_SEARCH_FAQ_UNLABELED", { questions: data });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_FAQ_UNLABELED: ({ commit, state }) => {
    return rest("get", "faq_unlabeled_messages", {}).then((data) => {
      commit("SET_FAQ_UNLABELED", { questions: data });
    });
  },
  FAQ_TRAIN_BY_TOPICS: (state, { selectedTopics }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($selectedTopics: [String!]) {
            faqAPI {
              trainByTopics(selectedTopics: $selectedTopics)
            }
          }
        `,
        variables: { selectedTopics },
      })
      .then((response) => {
        const isSuccessful = _.get(response, "data.faqAPI.trainByTopics");
        return isSuccessful;
      })
      .catch((err) => {
        throw err;
      });
  },
  FAQ_TRAIN_ALL_TOPICS_AS_INTENTS: (state) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            faqAPI {
              trainAllTopicsAsIntents
            }
          }
        `,
      })
      .then((response) => {
        const isSuccessful = _.get(
          response,
          "data.faqAPI.trainAllTopicsAsIntents"
        );
        return isSuccessful;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default actions;

<template>
  <el-row style="margin-bottom: 0;">
    <el-col>
      <el-row
        type="flex"
        align="middle"
        v-if="showTagInput && showPredefinedCascader && predefinedTags.length"
        style="margin-bottom: 10px"
      >
        <el-col :span="4" :lg="2">
          <span style="font-size: 12px">ADD TAG</span>
        </el-col>
        <el-col :span="16">
          <el-cascader
            v-model="tagInput"
            :options="predefinedTags"
            ref="saveTagInput"
            size="mini"
            style="margin-left: 5px; width: 100%"
            filterable
          >
          </el-cascader>
        </el-col>
        <el-col :span="4">
          <el-button type="info" size="mini" style="margin-left: 10px" plain @click="addTag()">
            <i class="el-icon-check"></i>
          </el-button>
          <el-button type="text" plain @click="showPredefinedCascader = false">
            <i class="el-icon-edit"></i>
          </el-button>
        </el-col>
      </el-row>
      <el-row v-else-if="showTagInput" type="flex" align="middle" style="margin-bottom: 10px">
        <el-col :span="4" :lg="2">
          <span style="font-size: 12px">ADD TAG</span>
        </el-col>
        <el-col :span="16">
          <el-input
            v-model="tagInput"
            ref="saveTagInput"
            class="input-new-tag"
            size="mini"
            style="margin-left: 5px; width: 100%"
            maxlength="50"
            @keyup.enter.native="addTag()"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="info" size="mini" style="margin-left: 10px" plain @click="addTag()">
            <i class="el-icon-check"></i>
          </el-button>
          <el-button
            v-if="predefinedTags.length"
            type="text"
            plain
            @click="showPredefinedCascader = true"
            style="margin-left: 10px"
          >
            <i class="el-icon-s-operation"></i>
          </el-button>
        </el-col>
      </el-row>
    </el-col>
    <el-col>
      <el-tag
        v-for="(tag, index) in selectedChat.tags"
        :key="index"
        size="small"
        style="margin: 0 5px 5px 0;"
        closable
        :disable-transitions="false"
        @close="removeTag(tag)"
        >{{ tag }}</el-tag
      >
      <el-tag
        :key="stateVariableIndex"
        :disable-transitions="false"
        v-for="(stateVariable, stateVariableIndex) in stateVariables"
        size="small"
        style="margin: 0 5px 5px 0;"
      >{{ `${stateVariableIndex}:${stateVariable}` }}</el-tag>
      <el-tag
        v-if="isQueueTab || isResolvedTab"
        size="small"
        style="margin: 0 10px 5px 0; cursor:pointer;"
        type="info"
        @click="showInput"
      >
        Add Tag
        <i class="el-icon-plus el-icon-right" />
      </el-tag>
    </el-col>
  </el-row>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandler";

export default {
  props: ["selectedChat", "isQueueTab", "isResolvedTab", "stateVariables"],
  mixins: [errorHandlerMixin],
  data() {
    return {
      tagInput: "",
      showTagInput: false,
      showPredefinedCascader: false,
    };
  },
  mounted() {
    if (this.predefinedTags.length) {
      this.showPredefinedCascader = true;
    }
  },
  computed: {
    predefinedTags() {
      const predefinedTagsOptions = _.get(
        this,
        "$store.state.modules.handover.predefinedTags",
        null
      );
      if (!predefinedTagsOptions?.enabled) {
        return [];
      }
      const res = _.get(this, "$store.state.modules.handover.predefinedTags.tags", []);

      if (res.length && predefinedTagsOptions?.validateDepartment?.enabled) {
        const disabledRes = this.validateDepartment(res);
        return disabledRes;
      }

      return res;
    },
    predefinedTagsLevel() {
      const level = _.get(
        this,
        "$store.state.modules.handover.predefinedTags.validateDepartment.level",
        1
      );
      return level;
    },
    departments() {
      return this.$store.getters.userDepartment;
    },
  },
  methods: {
    async addTag() {
      const delimiter = _.get(this, "$store.state.modules.handover.predefinedTags.delimiter", ">");
      const newTag =
        this.predefinedTags.length && this.showPredefinedCascader && this.tagInput.length
          ? this.tagInput.join(delimiter)
          : this.tagInput.trim();
      this.showTagInput = false;

      const livechatTags = _.get(this.selectedChat, "tags", []);

      if (livechatTags.indexOf(newTag) > -1) {
        this.$notify.warning({
          title: "Warning",
          message: "This chat already has this tag",
          position: "bottom-right",
        });
        return;
      }

      if (livechatTags.length >= 50) {
        this.$notify.warning({
          title: "Warning",
          message: "This chat already has 50 tags",
          position: "bottom-right",
        });
        return;
      }

      if (newTag.length === 0) return;
      this.tagInput = "";

      await this.$store
        .dispatch("CREATE_TAG", {
          newTag,
          chat: this.selectedChat
        })
        .then(() => {
          if (!this.isQueueTab) {
            this.selectedChat.tags.push(newTag);
          }

          this.$notify.success({
            title: "Success",
            message: "Successfully created tag",
            position: "bottom-right"
          });
        })
        .catch(error => {
          this.$notify.error({
            title: "Error",
            message: this.graphQLError(error, "Encountered error adding tag"),
            position: "bottom-right",
          });
        });
    },

    async removeTag(tagToDelete) {
      await this.$store
        .dispatch("DELETE_TAG", {
          tagToDelete,
          chat: this.selectedChat
        })
        .then(() => {
          const tags = this.selectedChat.tags;
          this.selectedChat.tags.splice(_.findIndex(tags, tagToDelete), 1);
          this.$notify.success({
            title: "Success",

            message: "Successfully removed tag",
            position: "bottom-right"
          });
        })
        .catch(error => {
          this.$notify.error({
            title: "Error",
            message: "Encountered error removing tag",
            position: "bottom-right"
          });
        });
    },

    showInput() {
      this.showTagInput = true;
      this.$nextTick(() => {
        if (!this.predefinedTags.length) {
          this.$refs.saveTagInput.focus();
        }
      });
    },

    validateDepartment(tags, loop = 1) {
      const resultArr = [];

      for (const tag of tags) {
        const resultObj = _.cloneDeep(tag);
        if (loop === this.predefinedTagsLevel) {
          resultObj.disabled = true;
        } else {
          resultObj.disabled = false;
        }
        if (
          _.includes(this.departments, tag.value.toLowerCase()) &&
          loop === this.predefinedTagsLevel
        ) {
          resultObj.disabled = false;
        }
        if (tag.children) {
          resultObj.children = this.validateDepartment(tag.children, loop + 1);
        }
        resultArr.push(resultObj);
      }
      return resultArr;
    },
  },
};
</script>

import { MutationTree } from "vuex/types";

import { WatsonState } from "./types";
import { RootState } from "@/store/types";
import _ from "lodash";

const mutations: MutationTree<WatsonState> = {
  SET_WATSON_INTENT(state, payload) {
    state.intents = payload;
  },
  SET_WATSON_MY_ENTITIES(state, payload) {
    state.entities = payload;
  },
  SET_WATSON_DIALOG_NODES(state, payload) {
    state.dialogNodes = payload;
  },

  SET_WATSON_WORKSPACE(state, payload) {
    state.workspace = payload;
  },
  SET_WATSON_IS_EDITED_OBJECT(state, { nodeVariableType, isEdited }) {
    const newisEditedObject = _.cloneDeep(state.isEditedObject);
    newisEditedObject[nodeVariableType] = isEdited;
    state.isEditedObject = newisEditedObject;
  },
};

export default mutations;

import { graph, rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { FileUploadState } from "./types";
import gql from "graphql-tag";
import _ from "lodash";
import * as Sentry from "@sentry/browser";

const actions: ActionTree<FileUploadState, RootState> = {
  MINI_APP_UPLOAD_IMAGE: async ({ state, commit }, file) => {
    return rest("post", "miniapp_ceph_images_upload", file)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw err;
      });
  },
  MINI_APP_UPLOAD_DOCUMENT: async ({ state, commit }, file) => {
    return rest("post", "miniapp_ceph_documents_upload", file)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw err;
      });
  },
  GET_STORAGE_TYPE: async ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            Server {
              storageType
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        const storageType = _.get(response, "data.Server.storageType", "azure");
        commit("SET_STORAGE_TYPE", storageType);
        return storageType;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_ALL_IMAGES: async ({ state, commit, rootState }) => {
    let response;
    try {
      if (state.storageType === "azure") {
        response = await rest("get", `getAllFolders?brain=${rootState.brain}`, {});
      } else if (state.storageType === "ceph") {
        response = await rest("get", "miniapp_ceph_get_images", {})
      }
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    };
    return response;
  },
};

export default actions;

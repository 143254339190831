<template>
  <div>
    <el-row :gutter="40" style="width:100%;">
      <el-col :span="10">
        <span style="font-size:24px; font-weight: light;">
          <b>Step 1: Get Verification Code</b>
        </span>
        <br />
        <br />
        <el-form
          ref="registrationForm"
          :model="registrationForm"
          :label-position="'left'"
          label-width="160px"
          :rules="registrationFormRules"
        >
          <el-form-item label="Country Code" prop="cc">
            <el-input v-model="registrationForm.cc" />
          </el-form-item>
          <el-form-item label="Phone Number" prop="phone_number">
            <el-input v-model="registrationForm.phone_number" />
          </el-form-item>
          <el-form-item label="Verification Method" prop="method">
            <el-radio-group v-model="registrationForm.method">
              <el-radio label="sms">SMS</el-radio>
              <el-radio label="voice">Voice Call</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Base-64 Certificate" prop="cert">
            <el-input v-model="registrationForm.cert" />
          </el-form-item>
          <el-button type="primary" @click="register">Register</el-button>
          <el-button type="danger" @click="clear">Clear</el-button>
        </el-form>
      </el-col>
      <el-col :span="10">
        <span style="font-size:24px; font-weight: light;">
          <b>Step 2: Verify Code</b>
        </span>
        <br />
        <br />
        <el-form
          ref="verificationForm"
          :model="verificationForm"
          :label-position="'left'"
          label-width="160px"
          :rules="verificationFormRules"
        >
          <el-form-item label="Verification Code" prop="code">
            <el-input v-model="verificationForm.code" />
          </el-form-item>
          <el-button type="primary" @click="verify">Verify</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "RegisterVerifyTab",
  props: {
    wa_endpoint: String
  },
  data() {
    return {
      verificationMethods: [
        {
          label: "SMS",
          value: "sms"
        },
        {
          label: "Voice Call",
          value: "voice"
        }
      ],
      registrationForm: {
        cc: "",
        phone_number: "",
        method: "",
        cert: ""
      },
      verificationForm: {
        code: ""
      },
      verificationFormRules: {
        code: [
          {
            required: true,
            message: "Please enter a valid verification code!",
            trigger: "change"
          }
        ]
      },
      registrationFormRules: {
        cc: [
          {
            required: true,
            message: "Please enter a country code!",
            trigger: "change"
          }
        ],
        phone_number: [
          {
            required: true,
            message: "Please enter a valid phone number!",
            trigger: "change"
          }
        ],
        method: [
          {
            required: true,
            message: "Please choose a validation method!",
            trigger: "change"
          }
        ],
        cert: [
          {
            required: true,
            message: "Please enter a valid certificate!",
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    register() {
      this.$refs.registrationForm.validate(valid => {
        if (valid) {
          this.$store
            .dispatch("REGISTER_WHATSAPP_NUMBER", {
              data: {
                wa_endpoint: this.wa_endpoint,
                ...this.$data.registrationForm
              }
            })
            .then(res => {
              this.$message({
                type: "success",
                message: res
              });
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: `Failed to register number!`
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    verify() {
      this.$refs.verificationForm.validate(valid => {
        if (valid) {
          this.$store
            .dispatch("VERIFY_WHATSAPP_NUMBER", {
              data: {
                wa_endpoint: this.wa_endpoint,
                ...this.$data.verificationForm
              }
            })
            .then(res => {
              this.$message({
                type: "success",
                message: res
              });
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: `Failed to verify number!`
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clear() {
      this.$data.registrationForm = {
        cc: "",
        number: "",
        method: "",
        cert: ""
      };
    }
  }
};
</script>
<style scoped>
.change-password-form {
  width: 700px;
}

.change-password-input {
  width: 300px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MonitorChatFilters',{attrs:{"selectedChat":_vm.selectedChat},on:{"setHasNoMoreChats":function($event){_vm.hasNoMoreChats = $event},"actionTriggered":_vm.onActionTriggered}}),_c('el-row',[_c('el-col',{staticClass:"leftPane",attrs:{"id":"monitorChatLeftPane","xl":6,"lg":6,"md":8,"sm":24}},[_c('el-card',{staticClass:"box-card",staticStyle:{"margin":"0 5px"}},[_c('div',{staticClass:"clearfix",staticStyle:{"margin-bottom":"0px"},attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v(" "+_vm._s(_vm.monitorChatsCount)+" Monitor Chats "),_c('el-tooltip',{staticStyle:{"margin-left":"4px"},attrs:{"effect":"dark","content":"These conversation are not updated in real-time","placement":"right"}},[_c('i',{staticClass:"el-icon-question",staticStyle:{"margin-left":"0.5em","color":"#909399"}})])],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fetchMonitorChatLoading),expression:"fetchMonitorChatLoading"}],attrs:{"element-loading-text":"Loading chats"}},[(!_vm.monitorChatsArray || _vm.monitorChatsArray.length === 0)?_c('el-row',{staticStyle:{"text-align":"center","position":"relative"}},[_c('h1',[_vm._v("There are currently no ongoing chats.")])]):_vm._e(),_vm._l((_vm.monitorChatsArray),function(chat,index){return _c('el-row',{key:((chat.PartitionKey) + "_" + (chat.RowKey) + "-" + index),staticClass:"item",class:_vm.getChatClass(
                index,
                chat,
                _vm.selectedMonitorChatRowKey,
                _vm.selectedMonitorChatPartitionKey
              ),staticStyle:{"margin-bottom":"4px"},attrs:{"effect":"dark","placement":"right"},nativeOn:{"click":function($event){return _vm.selectChat(chat.PartitionKey, chat.RowKey)}}},[_c('el-row',{staticStyle:{"margin-bottom":"0"}},[_c('el-col',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","display":"flex"},attrs:{"span":24}},[_c('ChatAvatar',{staticStyle:{"display":"inline-block"},attrs:{"chat":chat}}),_c('span',{staticStyle:{"margin":"5px 0 8px","font-size":"16px","place-items":"center","display":"flex"}},[_vm._v(" "+_vm._s(_vm.getChatName(chat))+" ")])],1),(_vm.get(chat, 'agents.length') > 0)?_c('el-col',{staticStyle:{"font-size":"0.6em","color":"#757575","margin-top":"5px","white-space":"normal","font-weight":"400"},attrs:{"span":24}},[_c('i',[_vm._v("Attended by "+_vm._s(chat.agents.join(", ")))])]):_vm._e()],1)],1)}),_c('el-row',[(
                _vm.monitorChatsArray &&
                _vm.monitorChatsArray.length > 0 &&
                !_vm.hasNoMoreChats
              )?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.showMoreIsLoading),expression:"showMoreIsLoading"}],staticClass:"prev-conversation",attrs:{"element-loading-spinner":"el-icon-loading"},on:{"click":_vm.fetchMoreMonitorChats}},[_vm._v(" - show more - ")]):_vm._e()])],2)])],1),_c('el-col',{staticClass:"rightPane",attrs:{"xl":18,"lg":18,"md":16,"sm":24}},[(_vm.selectedChat)?_c('ChatsInteractions',{attrs:{"chats-list":_vm.monitorChatsArray,"selected-chat":_vm.selectedChat,"selected-index":_vm.selectedIndex,"selected-row-key":_vm.selectedMonitorChatRowKey,"is-loading":_vm.$apollo.queries.monitorChats.loading,"closed":_vm.closed},on:{"update:selectedIndex":function($event){_vm.selectedIndex=$event},"update:selected-index":function($event){_vm.selectedIndex=$event},"update:selectedRowKey":function($event){_vm.selectedMonitorChatRowKey=$event},"update:selected-row-key":function($event){_vm.selectedMonitorChatRowKey=$event},"update:closed":function($event){_vm.closed=$event}}}):_vm._e(),(_vm.$store.state.showAdvanced)?_c('el-row',[_c('JSONEditor',{model:{value:(_vm.selectedChat),callback:function ($$v) {_vm.selectedChat=$$v},expression:"selectedChat"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
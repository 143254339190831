<template>
  <div>
    <el-button size="mini" @click="dialogTableVisible = true" type="text"
      >View Interactions</el-button
    >

    <el-dialog
      @opened="opened"
      @closed="closed"
      title="Visitor Interactions"
      :visible.sync="dialogTableVisible"
    >
      <section>
        <el-table size="mini" :data="interactions">
          <el-table-column property="date" label="Time" width="150">
            <template slot-scope="scope">
              <small>
                {{ scope.row.Timestamp | moment }}
              </small>
            </template>
          </el-table-column>
          <el-table-column
            property="event"
            label="Action"
            width="200"
          ></el-table-column>
          <el-table-column property="data" label="Content">
            <template slot-scope="scope">
              <div v-if="typeof flatten(scope.row.data) === 'string'">
                {{ scope.row.data }}
              </div>
              <div v-else>
                <el-tooltip placement="bottom" effect="light">
                  <table slot="content">
                    <tr
                      :key="`${scope.row.id}-${key}`"
                      v-for="(item, key) in flatten(scope.row.data)"
                    >
                      <th>{{ key }}</th>
                      <td>{{ item }}</td>
                    </tr>
                  </table>
                  <el-button type="text" size="mini">View Data</el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 10px">
          <el-button
            type="text"
            size="mini"
            :loading="loading"
            :disabled="loading || !canShowMore"
            @click="showMore"
            >show more</el-button
          >
        </div>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import flat from "flat";
import moment from "moment";
import { defaultPickerOptions } from "@/helperMethods/util";
export default {
  data() {
    return {
      pickerOptions: defaultPickerOptions,
      loading: false,
      pageSize: _.get(
        this.$store,
        "state.modules['visitors'].interactions_page_size",
        25
      ),
      currentPage: 1,
      dialogTableVisible: false,
      interactionsCount: 0,
      interactions: [],
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).local().format("D MMM YY h:mm A");
    },
  },
  computed: {
    canShowMore() {
      return this.currentPage < this.totalPages;
    },
    totalPages() {
      return Math.ceil(this.interactionsCount / this.pageSize);
    },
  },
  props: ["visitor"],
  methods: {
    flatten(value) {
      if (!value) {
        return "";
      }
      try {
        const output = JSON.parse(value);
        if (typeof output === "number") {
          throw new Error();
        }
        return flat(output);
      } catch (e) {
        return value;
      }
    },
    opened() {
      this.getInteractions();
    },
    closed() {
      this.interactions = [];
      this.currentPage = 1;
      this.interactionsCount = 0;
    },
    showMore() {
      this.currentPage += 1;
      this.getInteractions();
    },
    getInteractions() {
      this.loading = true;
      let startDate = "";
      let endDate = "";
      if (this.searchDateRange && this.searchDateRange.length > 0) {
        startDate = this.searchDateRange[0];
        endDate = this.searchDateRange[1];
      }
      this.$store
        .dispatch("FETCH_USER_INTERACTIONS_BY_PAGE", {
          userId: this.visitor.RowKey,
          page: this.currentPage,
          pageSize: this.pageSize,
          searchString: this.search,
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => {
          const data = _.get(response, "data.getInteractionsByPage.rows", []);
          this.loading = false;
          this.interactions.push(...data);
          this.interactionsCount = _.get(
            response,
            "data.getInteractionsByPage.count",
            0
          );
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error fetching users.`,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #5a5e66;
  text-align: left;
  padding: 4px 10px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>

<template>
  <div style="text-align:center;">
    <div style="text-align:center; margin-bottom: 10px;">
      <el-radio-group v-model="sideEffect.data.type" size="mini" @change="updateDataType">
        <el-radio-button label="string">Text</el-radio-button>
        <el-radio-button label="number">Number</el-radio-button>
        <el-radio-button label="boolean">True/False</el-radio-button>
        <el-radio-button label="object">Object</el-radio-button>
        <el-radio-button label="input">Value from user</el-radio-button>
      </el-radio-group>
    </div>

    <div
      v-if="
        sideEffect.data.type === 'string' && typeof sideEffect.data.value === 'string'
      "
      class="el-input el-input--mini el-input-group el-input-group--prepend"
      style="width: 60%; margin-bottom:10px;"
    >
      <div class="el-input-group__prepend">Value</div>
      <input
        v-model="sideEffect.data.value"
        autocomplete="off"
        placeholder="Input value"
        class="el-input__inner"
      />
    </div>

    <div
      v-if="
        sideEffect.data.type === 'number' && typeof sideEffect.data.value === 'number'
      "
      class="el-input el-input--mini el-input-group el-input-group--prepend"
      style="width: 60%; margin-bottom:10px;"
    >
      <div class="el-input-group__prepend">Value</div>
      <input
        v-model.number="sideEffect.data.value"
        type="number"
        autocomplete="off"
        placeholder="Input value"
        class="el-input__inner"
      />
    </div>

    <div
      style="margin-bottom: 10px;"
      v-if="
          sideEffect.data.type === 'boolean' && typeof sideEffect.data.value === 'boolean'
        "
    >
      <input v-model="sideEffect.data.value" type="radio" name="type-boolean" :value="true" />
      <label style="margin-right: 20px;">True</label>
      <input v-model="sideEffect.data.value" type="radio" name="type-boolean" :value="false" />
      <label>False</label>
      <br />
    </div>

    <ul
      style="padding-left:0;"
      v-if="
        sideEffect.data.type === 'object' && typeof sideEffect.data.value === 'object'
      "
    >
      <li style="list-style-type:none;  ">
        <el-button
          icon="el-icon-plus"
          type="text"
          size="mini"
          style="margin-bottom: 5px;"
          @click="addPair"
        >Add Key/Value Pair</el-button>
      </li>
      <li v-for="(kv, index) in sideEffect.data.value" :key="index" style="list-style-type:none;">
        <input
          v-model="kv.key"
          type="text"
          style="max-width: 40%; margin-right: 5px;"
          placeholder="Insert key"
        />
        <input
          v-model="kv.value"
          type="text"
          style="max-width: 40%; margin-right: 5px;"
          placeholder="Insert value"
        />
        <el-button class="el-icon-delete" size="mini" type="text" @click="removePair(index)" />
      </li>
    </ul>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  props: ["sideEffect"],
  beforeMount() {
    if (!this.sideEffect.data || !_.isPlainObject(this.sideEffect.data)) {
      this.sideEffect.data = {};
    }
  },
  methods: {
    addPair() {
      this.sideEffect.data.value.push({
        key: "",
        value: ""
      });
      this.$forceUpdate();
    },
    removePair(key) {
      this.sideEffect.data.value.splice(key, 1);
    },
    updateDataType(value) {
      switch (this.sideEffect.data.type) {
        case "object":
          this.sideEffect.data.value = [];
          break;
        case "input":
          this.sideEffect.data.value = null;
          break;
        case "boolean":
          this.sideEffect.data.value = true;
          break;
        case "number":
          this.sideEffect.data.value = 0;
          break;
        case "string":
        default:
          this.sideEffect.data.value = " ";
      }

      this.$forceUpdate();
    }
  }
};
</script>

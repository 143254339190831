var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-menu',{staticStyle:{"height":"100%"},attrs:{"collapse":""}},[_c('router-link',{attrs:{"to":"/"}},[_c('el-menu-item',{staticClass:"sidenav-logo",attrs:{"index":"logo"}},[_c('img',{attrs:{"src":require('../assets/logo.png')}})])],1),_c('router-link',{attrs:{"to":"/"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"home"}},[_c('i',{staticClass:"ion ion-ios-home"}),_c('small',[_vm._v("Home")])])],1),(_vm.checkRoles(_vm.getPermittedRoles('editor')))?_c('router-link',{attrs:{"to":"/editor"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"editor"}},[_c('i',{staticClass:"el-icon-edit-outline"}),_c('small',[_vm._v("Editor")])])],1):_vm._e(),(
      _vm.showModule('faq', 'WatsonAssistant') &&
      _vm.checkRoles(_vm.getPermittedRoles('faqw'))
    )?_c('router-link',{attrs:{"to":"/faqw"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"faqw"}},[_c('i',{staticClass:"ion ion-ios-help-outline"}),_c('small',[_vm._v("FAQs")])])],1):_vm._e(),(
      _vm.showModule('faq', 'Alexandria') && _vm.checkRoles(_vm.getPermittedRoles('faq'))
    )?_c('router-link',{attrs:{"to":"/faq"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"faq"}},[_c('i',{staticClass:"ion ion-ios-help-outline"}),_c('small',[_vm._v("FAQs")])])],1):_vm._e(),(
      _vm.showModule('faq', ['Rasa', 'Elsa']) &&
      _vm.checkRoles(_vm.getPermittedRoles('faq'))
    )?_c('router-link',{attrs:{"to":"/faq2"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"faq2"}},[_c('i',{staticClass:"ion ion-ios-help-outline"}),_c('small',[_vm._v("FAQs")])])],1):_vm._e(),(
      _vm.showModule('transactions') &&
      _vm.checkRoles(_vm.getPermittedRoles('transactions'))
    )?_c('router-link',{attrs:{"to":"/transaction"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"batch_test"}},[_c('i',{staticClass:"ion ion-ios-filing-outline"}),_c('small',[_vm._v(_vm._s(_vm.transactionsLabel))])])],1):_vm._e(),(_vm.checkRoles(_vm.getPermittedRoles('livechat')))?_c('router-link',{attrs:{"to":"/livechat"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"livechat"}},[_c('el-badge',{attrs:{"is-dot":_vm.queueBadgeNumber > 0}},[_c('i',{staticClass:"el-icon-chat-dot-round"})]),_c('small',[_vm._v("Live Chat ")])],1)],1):_vm._e(),(_vm.checkRoles(_vm.getPermittedRoles('faq')))?_c('router-link',{attrs:{"to":"/entities"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"entities"}},[_c('i',{staticClass:"el-icon-discount"}),_c('small',[_vm._v("Entities")])])],1):_vm._e(),(
      _vm.checkRoles(_vm.getPermittedRoles('whatsapp')) &&
      _vm.$store.state.modules.whatsapp.enabled
    )?_c('router-link',{attrs:{"to":"/whatsapp"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"whatsapp"}},[_c('i',{staticClass:"ion ion-social-whatsapp"}),_c('small',[_vm._v("WhatsApp")])])],1):_vm._e(),(_vm.showModule('webchat') && _vm.checkRoles(_vm.getPermittedRoles('webchat')))?_c('router-link',{attrs:{"to":"/widget"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"webchat"}},[_c('i',{staticClass:"ion ion-android-laptop"}),_c('small',[_vm._v("Widget")])])],1):_vm._e(),(
      _vm.showModule('analytics') && _vm.checkRoles(_vm.getPermittedRoles('analytics'))
    )?_c('router-link',{attrs:{"to":"/analytics"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"analytics"}},[_c('i',{staticClass:"el-icon-data-line"}),_c('small',[_vm._v("Analytics")])])],1):_vm._e(),(_vm.checkRoles(_vm.getPermittedRoles('supervisor')))?_c('router-link',{attrs:{"to":"/supervisor"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"supervisor"}},[_c('i',{staticClass:"el-icon-view"}),_c('small',[_vm._v("Supervisor")])])],1):_vm._e(),(
      _vm.showModule('miniapps') && _vm.checkRoles(_vm.getPermittedRoles('file-storage'))
    )?_c('router-link',{attrs:{"to":"/file-storage"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"file-storage"}},[_c('i',{staticClass:"el-icon-folder-opened"}),_c('small',[_vm._v("Files")])])],1):_vm._e(),(_vm.showModule('visitors') && _vm.checkRoles(_vm.getPermittedRoles('visitors')))?_c('router-link',{attrs:{"to":"/visitors"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"visitors"}},[_c('i',{staticClass:"el-icon-user"}),_c('small',[_vm._v("Visitors")])])],1):_vm._e(),(_vm.checkRoles(_vm.getPermittedRoles('settings')))?_c('router-link',{attrs:{"to":"/settings"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"settings"}},[_c('i',{staticClass:"el-icon-set-up"}),_c('small',[_vm._v("Settings")])])],1):_vm._e(),(
      _vm.showModule('broadcast') && _vm.checkRoles(_vm.getPermittedRoles('broadcast'))
    )?_c('router-link',{attrs:{"to":"/broadcast"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"broadcast"}},[_c('i',{staticClass:"ion ion-social-rss"}),_c('small',[_vm._v("Broadcasting")])])],1):_vm._e(),(
      _vm.showModule('subscription') &&
      _vm.checkRoles(_vm.getPermittedRoles('subscription'))
    )?_c('router-link',{attrs:{"to":"/subs"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"subscription"}},[_c('i',{staticClass:"ion ion-ios-paper-outline"}),_c('small',[_vm._v("Subscriptions")])])],1):_vm._e(),(
      (_vm.showModule('webchat', 'showPreChatForm') ||
        _vm.showModule('webchat', 'showPostChatForm')) &&
      _vm.checkRoles(_vm.getPermittedRoles('chat_form'))
    )?_c('router-link',{attrs:{"to":"/chat-form"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"chat_form"}},[_c('i',{staticClass:"el-icon-document"}),_c('small',[_vm._v("Pre/Post"),_c('br'),_vm._v("Chat Form")])])],1):_vm._e(),(
      _vm.showModule('access_control') &&
      _vm.checkRoles(_vm.getPermittedRoles('access_control'))
    )?_c('router-link',{attrs:{"to":"/access-control"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"access_control"}},[_c('i',{staticClass:"el-icon-warning-outline"}),_c('small',[_vm._v("Access"),_c('br'),_vm._v("Control")])])],1):_vm._e(),(_vm.checkRoles(_vm.getPermittedRoles('import_export')))?_c('router-link',{attrs:{"to":"/import_export"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"import_export"}},[_c('i',{staticClass:"ion ion-ios-cloud-download"}),_c('small',[_vm._v("Import"),_c('br'),_vm._v("Export")])])],1):_vm._e(),(_vm.checkRoles(_vm.getPermittedRoles('audit')))?_c('router-link',{attrs:{"to":"/audit"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"audit"}},[_c('i',{staticClass:"el-icon-tickets"}),_c('small',[_vm._v("Audit")])])],1):_vm._e(),(_vm.showModule('prism') && _vm.checkRoles(_vm.getPermittedRoles('prism')))?_c('router-link',{attrs:{"to":"/prism"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"prism"}},[_c('i',{staticClass:"el-icon-house"}),_c('small',[_vm._v("Prism")])])],1):_vm._e(),_c('a',{attrs:{"href":"https://www.keyreply.com/en/knowledge","target":"_blank"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"manual"}},[_c('i',{staticClass:"el-icon-reading"}),_c('small',[_vm._v("Manual")])])],1),(
      _vm.isKeycloakAuth &&
      _vm.$store.state.modules.system.enableKeycloakChangePassword
    )?_c('a',{attrs:{"href":_vm.getKeycloakChangePasswordURL,"target":"_blank"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"change_password"}},[_c('i',{staticClass:"el-icon-unlock"}),_c('small',[_vm._v("Change"),_c('br'),_vm._v("Password")])])],1):_vm._e(),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.loggingOut($event)}}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"logout"}},[_c('i',{staticClass:"ion ion-log-out"}),_c('small',[_vm._v("Log out")])])],1),(_vm.checkRoles(_vm.getPermittedRoles('labs')))?_c('router-link',{attrs:{"to":"/labs"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"labs"}},[_c('i',{staticClass:"ion ion-ionic"}),_c('small',[_vm._v("Labs")])])],1):_vm._e(),(
      _vm.$store.state.showAdvanced && _vm.checkRoles(_vm.getPermittedRoles('settings'))
    )?_c('router-link',{attrs:{"to":"/superadmin"}},[_c('el-menu-item',{staticClass:"sidenav-item",attrs:{"index":"superadmin"}},[_c('i',{staticClass:"ion ion-alert-circled"}),_c('small',[_vm._v("Admin")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
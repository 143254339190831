<template>
  <el-container>
    <el-main>
      <el-row v-if="showAdvanced">
        <el-col :sm="12">
          Maker Checker status:
          <pre>{{ JSON.stringify(makerCheckerConfiguration, null, 2) }}</pre>
        </el-col>
        <el-col :sm="12">
          <el-tooltip
            v-if="showAdvanced"
            effect="dark"
            content="Deploy Content into production"
            placement="bottom"
          >
            <el-button
              style="margin-left: 1em; float: right"
              type="success"
              :loading="pendingLoading"
              :plain="true"
              @click="publishContent"
            >
              Publish Bot's Content
              <i class="el-icon-check" />
            </el-button>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row class="filterGroup" :gutter="20">
        <!-- Filter and List of tasks -->
        <el-col :sm="8">
          <!-- Sets of filters -->
          <el-collapse v-model="activeCollapseName">
            <!-- search filter -->
            <el-collapse-item name="search">
              <template slot="title">
                <i class="el-icon-search" /> Search & Filter
              </template>
              <el-row>
                <el-col :sm="24">
                  <el-input v-model="searchTerm" placeholder="Search" />
                </el-col>
              </el-row>
              <!-- Department filter -->
              <el-row>
                <el-col :sm="24" style="width: 100%">
                  <el-select
                    v-model="activeDepartment"
                    clearable
                    placeholder="Filter by department"
                    style="width: 100%"
                    filterable
                  >
                    <!-- <el-option key="general" label="General" value="general"></el-option> -->
                    <el-option
                      v-for="department in formattedDepartments"
                      :key="department"
                      :label="department"
                      :value="department.toLowerCase()"
                    >
                      <span class="right">{{ department }}</span>
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <!-- change type filter -->
              <el-row>
                <el-col :sm="24" style="width: 100%">
                  <el-select
                    v-model="activeTaskType"
                    clearable
                    multiple
                    filterable
                    placeholder="Filter by task type"
                    style="width: 100%"
                  >
                    <el-option-group
                      v-for="group in typeGroups"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item"
                        :label="item"
                        :value="item.toLowerCase()"
                      >
                        <span class="right">{{ item }}</span>
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-col>
              </el-row>

              <!-- Filter by date, after soft launch -->
              <!-- <el-row>
                <el-col :sm="12">
                  <el-date-picker
                    v-model="filterDate"
                    clearable
                    type="date"
                    placeholder="Filter by date"
                    value-format="yyyy-MM-dd"
                    :picker-options="dateTimePicker">
                  </el-date-picker>
                </el-col>
                <el-col :sm="12">
                  <el-select placeholder="Outstanding tasks" :v-model="taskDate" @click="filterDate = taskDate">
                    <el-option v-for="(value,index) in pendingChangesDateList" :key="index" :value="value.last_modified">
                      {{value.last_modified}}
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>-->
            </el-collapse-item>
          </el-collapse>

          <!-- LEFT PANE -->
          <el-tabs
            v-model="activeLeftPane"
            v-loading="pendingLoading"
            type="border-card"
            element-loading-text="Loading tasks..."
            @tab-click="resetChangeSelection"
          >
            <!-- Pending -->
            <el-tab-pane
              :lazy="true"
              :label="`Pending (${pendingCounter})`"
              name="pending"
              style="height: 400px; overflow-y: scroll"
            >
              <el-row
                v-if="overallTasks.length === 0"
                style="text-align: center; position: relative"
              >
                <el-col>
                  <h2>Well done!</h2>
                  <h1>There are no pending tasks.</h1>
                </el-col>
              </el-row>
              <el-row v-else>
                <el-row>
                  <el-col :sm="24">
                    <el-table
                      :data="overallTasks"
                      style="width: 100%; height: 100%"
                      row-key="last_modified_type"
                      :expand-row-keys="leftPendingRowExpandData"
                      empty-text="No pending tasks"
                    >
                      <el-table-column type="expand">
                        <template slot-scope="props">
                          <!-- {{props.row}} -->
                          <div v-if="props.row.list.length > 0">
                            <div
                              v-for="(value, key, index) in props.row.users"
                              :key="index"
                              class="specific-change-row"
                              @click="
                                selectChange(
                                  key,
                                  value,
                                  props.row.type,
                                  props.row.mainType
                                )
                              "
                            >
                              <!-- value. {{value}}
                              key. {{key}}
                              index. {{index}}-->
                              {{ key.split("@")[0] }} has made
                              <b>{{ value }}</b> changes
                            </div>
                          </div>
                          <p v-else>No updates</p>
                        </template>
                      </el-table-column>

                      <el-table-column min-width="120px">
                        <template slot-scope="scope">
                          <b
                            v-if="scope.row.list.length >= 0"
                            class="specific-change-row"
                            @click="
                              leftTogglePendingRowExpansion(
                                scope.row.last_modified_type
                              )
                            "
                          >
                            {{ scope.row.list.length }} changes in
                            {{ scope.row.type }}
                          </b>
                        </template>
                      </el-table-column>
                      <el-table-column min-width="120px">
                        <template slot-scope="scope">
                          <el-button-group id="buttonGroup">
                            <!-- This is a placeholder until maker checker on/off is completed -->
                            <el-button
                              plain
                              size="mini"
                              :disabled="!isAuthorizedToCheck"
                              @click="
                                makerCheckerConfiguration.sendEmail
                                  ? openRejectConfirmation(
                                      scope.row.mainType,
                                      'all'
                                    )
                                  : reject(scope.row.mainType, 'all')
                              "
                            >Reject All</el-button>
                            <el-button
                              size="mini"
                              type="success"
                              :disabled="!isAuthorizedToCheck"
                              @click="
                                makerCheckerConfiguration.publish
                                  ? openApproveConfirmation(
                                      scope.row.mainType,
                                      'all'
                                    )
                                  : setupAndPublishChangesNow(
                                      scope.row.mainType,
                                      'all'
                                    )
                              "
                            >Approve All</el-button>
                          </el-button-group>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-row>
            </el-tab-pane>
            <!-- Completed -->
            <el-tab-pane
              :lazy="true"
              :label="`Completed (${completedCounter})`"
              name="completed"
              style="height: 400px; overflow-y: scroll"
            >
              <el-row>
                <el-col :sm="24">
                  <el-table
                    v-loading="completedLoading"
                    :data="overallTasks"
                    element-loading-text="Loading completed tasks..."
                    style="width: 100%"
                    row-key="last_modified_type"
                    :expand-row-keys="leftCompletedRowExpandData"
                  >
                    <el-table-column type="expand">
                      <template slot-scope="props">
                        <!-- {{props.row}} -->
                        <div v-if="props.row.list.length > 0">
                          <div
                            v-for="(value, key, index) in props.row.users"
                            :key="index"
                            class="specific-change-row"
                            @click="
                              selectChange(
                                key,
                                value,
                                props.row.type,
                                props.row.mainType,
                                props.row.last_modified
                              )
                            "
                          >
                            <!-- value. {{value}}
                            key. {{key}}
                            index. {{index}}-->
                            {{ key.split("@")[0] }} has made
                            <b>{{ value }}</b>
                            {{ value === 1 ? "change" : "changes" }}
                          </div>
                        </div>

                        <p v-else>No updates</p>
                      </template>
                    </el-table-column>

                    <el-table-column>
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.list.length >= 0"
                          class="specific-change-row"
                          @click="
                            leftToggleCompletedRowExpansion(
                              scope.row.last_modified_type
                            )
                          "
                        >
                          <b>{{ scope.row.list.length }} changes</b>
                          in
                          {{ scope.row.type }}
                          <span
                            style="
                              float: right;
                              color: #cdcdcd;
                              font-size: 12px;
                            "
                          >{{ scope.row.last_modified }}</span>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div v-if="!allCompletedChangedFetched && completedCounter > 0" style="text-align: center">
                    <el-button
                      type="text"
                      class="completed-view-more"
                      @click="fetchNextHundredCompletedChanges()"
                    >-- View more --</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-col>

        <!-- Changes overview table -->
        <ChangesOverview
          :searchTerm="searchTerm"
          :isAuthorizedToCheck="isAuthorizedToCheck"
          :activeDepartment="activeDepartment"
          :activeTaskType="activeTaskType"
          :activeLeftPane="activeLeftPane"
          :dependenceMap="dependenceMap"
          :changesMap="changesMap"
          :specificChange="specificChange"
          :overallDependenceMap="overallDependenceMap"
          :dialogChangesMap="dialogChangesMap"
          :fetch-completed-changes="fetchCompletedChanges"
          :fetch-pending-changes="fetchPendingChanges"
          :filtered-changes-list="filteredChangesList"
          @resetChangeSelection="resetChangeSelection"
        ></ChangesOverview>
      </el-row>

      <!-- Reject Confirmation dialog -->
      <RejectChangesDialog
        v-if="showRejectConfirmationForm"
        :activeDepartment="activeDepartment"
        :filteredDepartments="filteredDepartments"
        :multipleSelection="multipleSelection"
        :specificChange="specificChange"
        :activeTaskType="activeTaskType"
        :filterActivated="filterActivated"
        :data-filter="dataFilter"
        :editorOptions="editorOptions"
        :editorHTMLOptions="editorHTMLOptions"
        :data-user-filter="dataUserFilter"
        @resetChangeSelection="resetChangeSelection"
        @fetchCompletedChanges="fetchCompletedChanges"
        @fetchPendingChanges="fetchPendingChanges"
        @closeApprove="closeApprove"
      ></RejectChangesDialog>

      <!-- Approval Confirmation dialog -->
      <ApproveChangesDialog
        v-if="approveConfirmationVisible"
        :activeDepartment="activeDepartment"
        :filteredDepartments="filteredDepartments"
        :multipleSelection="multipleSelection"
        :specificChange="specificChange"
        :activeTaskType="activeTaskType"
        :filterActivated="filterActivated"
        :data-filter="dataFilter"
        @resetChangeSelection="resetChangeSelection"
        @fetchCompletedChanges="fetchCompletedChanges"
        @fetchPendingChanges="fetchPendingChanges"
        @closeApprove="closeApprove"
      ></ApproveChangesDialog>
    </el-main>
  </el-container>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import ChangesOverview from "./ChangesOverview";
import ApproveChangesDialog from "./ApproveChangesDialog";
import RejectChangesDialog from "./RejectChangesDialog";
import { mapGetters } from "vuex";
import { getDepartmentVerifiedChanges } from "@/helperMethods/makerChecker";
import { pickerOptionsForFutureDatesVersionII } from "@/helperMethods/util";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default Vue.extend({
  components: {
    ChangesOverview,
    ApproveChangesDialog,
    RejectChangesDialog,
  },
  props: [
    "newFetchIntent",
    "test",
    "uniqueCountChangesByUser",
    "fetchEditor",
    "fetchEntities",
    "fetchIntents",
    "fetchDialogs",
    "homeActiveLeftPane",
    "homeActiveTab",
    "homeTaskMainType",
  ],
  data() {
    return {
      // Filter set up
      isCollapsed: false,
      activeCollapseName: "search",

      // Filter by search
      searchTerm: "",

      // Filter by department
      activeDepartment: "All Departments",
      filteredDepartments: [],

      // Filter by date
      taskDate: "",
      filterDate: "",

      // Filter by active task type
      activeTaskType: [],
      tasksType: [
        "Example",
        "Editor",
        "Entity",
        "Intent",
        "Pattern", // @JOSH may remove
        "Synonym", // @JOSH may remove
        "Value",
      ],

      // Filter: specific change
      specificChange: {},

      multipleSelection: [],

      // Admin Console
      editorOptions: {
        mode: "text/javascript",
        value: "",
        title: "JSON",
        highlightDifferences: true,
        tabSize: 4,
        readOnly: true,
        smartIndent: true,
        lineWrapping: true,
      },
      editorHTMLOptions: {
        mode: "text/html",
        value: "",
        title: "Email Preview",
        highlightDifferences: true,
        tabSize: 4,
        readOnly: true,
        smartIndent: true,
        lineWrapping: true,
        tags: {
          style: [
            ["type", /^text\/(x-)?scss$/, "text/x-scss"],
            [null, null, "css"],
          ],
        },
      },
      valid: true,

      // pendingChangesList: [],
      leftPendingRowExpandData: [],

      // completedChangesList: [],
      leftCompletedRowExpandData: [],
      rowExpandData: [],
      allCompletedChangedFetched: false,

      // Approve Confirmation
      approveConfirmationForm: { publish_date: "", publishName: "" },
      approveConfirmationVisible: false,
      approveConfirmationLoading: false,
      selectedApprove: {
        department: "",
        mainType: "",
      },
      approveConfirmationRule: {
        publish_date: [
          {
            required: true,
            message: "Please input date and time",
            trigger: "blur",
          },
        ],
      },
      dateTimePicker: pickerOptionsForFutureDatesVersionII,

      // Reject Confirmation
      showRejectConfirmationForm: false,
      selectedReject: {
        department: "",
        mainType: "",
      },
      rejectConfirmationForm: {
        from: "",
        to: "",
        cc: "",
        name: "",
        message: "",
      },
      rejectConfirmationRule: {
        message: [
          { required: true, message: "A comment for user is required" },
        ],
      },

      // Changes dependency setup
      dependenceMap: new Map(), // Map the latest change to it's depedency changes
      changesMap: new Map(),
      overallDependenceMap: new Map(), // To map each and every changes to their latest change
      publishContentNodeObject: {},
      dialogChangesMap: new Map(), // to find out the earlier changes to every change itself
      selectionErrorList: [],
      filteredSelectionErrorList: [],
      selectedOldNode: {},
      selectedNewNode: {},
      editorActiveTab: {},
    };
  },
  computed: {
    ...mapGetters([
      "pendingChangesList",
      "completedChangesList",
      "getUserAuthentication",
      "makerCheckerObject",
      "departments",
      "formattedDepartments",
      "showAdvanced",
    ]),
    pendingLoading: {
      get() {
        return this.$store.state.makerChecker.pendingLoading;
      },
      set(newValue) {
        this.$set(this.$store.state.makerChecker, "pendingLoading", newValue);
      },
    },
    completedLoading: {
      get() {
        return this.$store.state.makerChecker.completedLoading;
      },
      set(newValue) {
        this.$set(this.$store.state.makerChecker, "completedLoading", newValue);
      },
    },
    dialogNodesChosen() {
      if (this.multipleSelection) {
        const tempDialogNodeArray = _.filter(
          this.multipleSelection,
          (selection) => {
            return selection.mainType === "dialog";
          }
        );
        return tempDialogNodeArray.length > 0;
      } else {
        return false;
      }
    },
    filterActivated() {
      return (
        !_.isEmpty(this.specificChange) ||
        !_.isEmpty(this.activeDepartment) ||
        !_.isEmpty(this.searchTerm) ||
        !_.isEmpty(this.activeTaskType)
      );
    },
    activeLeftPane: {
      get() {
        return this.$store.state.makerChecker.activeLeftPane;
      },
      set(newValue) {
        this.$set(this.$store.state.makerChecker, "activeLeftPane", newValue);
      },
    },
    makerCheckerConfiguration() {
      return _.assign(this.makerCheckerObject, {
        filterActivated: this.filterActivated,
      });
    },
    typeGroups: {
      get() {
        let types = [];
        const departments = this.departments;
        const tasksTypes = this.tasksType;

        if (tasksTypes && tasksTypes.length > 0) {
          types.push({ label: "Change Types", options: tasksTypes });
        }
        return types;
      },
    },
    pendingCounter() {
      const currentList = this.pendingChangesList;
      const selectedStatus = "pending";
      const currentPendingList = this.filterSelectedStatusTasks(currentList, selectedStatus);
      return _.chain(currentPendingList).uniqBy("mainType").value().length;
    },
    completedCounter() {
      const currentList = this.completedChangesList;
      const selectedStatus = "completed";
      const currentCompletedList = this.filterSelectedStatusTasks(currentList, selectedStatus);
      return _.chain(currentCompletedList)
        .uniqBy((v) => [v.type, v.last_modified].join())
        .value().length;
    },

    chosenChangeList() {
      switch (this.activeLeftPane) {
        case "completed":
          return this.completedChangesList;
        case "pending":
        default:
          return this.pendingChangesList;
      }
    },
    pendingChangesDateList() {
      // FIXME: not in use
      const currentList = this.pendingChangesList;
      return _.chain(currentList).uniqBy("last_modified").value();
    },
    isAuthorizedToCheck() {
      const { roles } = this.getUserAuthentication;
      const isAuthorized = checkRoles(getPermittedRoles("editor_approve"));
      const adminRole = roles && roles.includes("admin");

      return isAuthorized || adminRole;
    },
    isAuthorizedToRead() {
      const { roles } = this.getUserAuthentication;
      const editorApproveRoles = getPermittedRoles("editor_approve");
      const editorRoles = getPermittedRoles("editor");
      const mergedRoles = _.uniq([...editorApproveRoles, ...editorRoles]);
      const isAuthorized = checkRoles(mergedRoles);
      const adminRole = roles && roles.includes("admin");

      return isAuthorized || adminRole;
    },
    filteredChangesList() {
      if (!this.isAuthorizedToRead) {
        return [];
      }
      
      let currentList;
      let selectedStatus = this.activeLeftPane;
      const activeDepartment = this.activeDepartment && [
        this.activeDepartment.toLowerCase(),
      ]; // change naming to local

      switch (selectedStatus) {
        case "completed":
          currentList = this.completedChangesList;
          break;
        case "pending":
        default:
          currentList = this.pendingChangesList;
          break;
      }
      const { departments, permissions, roles } = this.getUserAuthentication;

      const editedUserDepartments = _.map(
        _.cloneDeep(departments),
        (department) => department && department.toLowerCase()
      ); // editedUserDepartments => editedUserDepartments

      editedUserDepartments.push("general");

      let filteredDepartments = activeDepartment;
      const allDepartmentsSelected = activeDepartment.includes(
        "all departments"
      );
      if (_.isEmpty(activeDepartment) || allDepartmentsSelected) {
        this.filteredDepartments = editedUserDepartments;
        filteredDepartments = editedUserDepartments;
      }

      // FIXME: remove getDepartmentVerifiedChanges. Already done
      let currentDepartmentFilteredList = getDepartmentVerifiedChanges(
        currentList,
        filteredDepartments
      );

      const searchFilteredChanges = currentDepartmentFilteredList.filter(
        (change) => {
          // Filter by search values
          if (this.searchTerm) {
            return JSON.stringify(change)
              .toLowerCase()
              .includes(this.searchTerm);
          } else {
            return true;
          }
        }
      );

      // FIXME: date filter is not in use
      const dateFilteredChanges = searchFilteredChanges.filter((change) => {
        // Filter by date of change; Hidden from user
        if (change && this.filterDate) {
          return (
            change.date_created.includes(this.filterDate) ||
            (change.last_modified &&
              change.last_modified.includes(this.filterDate))
          );
        } else {
          return true;
        }
      });

      const taskTypeFilteredChanges = dateFilteredChanges.filter((change) => {
        // Filter by task type
        const activeTaskType =
          this.activeTaskType &&
          this.activeTaskType.map((v) => v.toLowerCase());

        const originalTasksType =
          this.tasksType && this.tasksType.map((v) => v.toLowerCase());
        let type = "";
        if (
          change &&
          activeTaskType.length > 0 &&
          _.intersection(activeTaskType, originalTasksType).length > 0
        ) {
          if (change.type.includes("editor")) {
            type = change.type.split("_")[0];
          } else {
            type = change.type.split("_")[1];
          }
          return activeTaskType.includes(type);
        } else {
          return true;
        }
      });

      const finalFilteredChanges = _.chain(taskTypeFilteredChanges)
        .filter((change) => {
          // Filter by specific change selected on left pane
          const specificChange = this.specificChange;
          const specificChangeChosen = !_.isEmpty(specificChange);
          if (specificChangeChosen) {
            const changeSpottedByType =
              change.modified_by &&
              change.modified_by.toLowerCase() === specificChange.modified_by &&
              change.mainType.includes(specificChange.mainType);

            const changeSpottedByLastModified =
              selectedStatus === "pending"
                ? changeSpotted
                : change.last_modified === specificChange.last_modified; //if else

            const changeSpotted =
              this.activeLeftPane === "completed"
                ? changeSpottedByType && changeSpottedByLastModified
                : changeSpottedByType; // if else

            if (changeSpotted && !this.isCollapsed) {
              // For feature if user can check "always expand"
              this.rowExpandData.push(change.RowKey);
            }
            return changeSpotted;
          } else {
            return true;
          }
        })
        .orderBy(["last_modified"], ["desc"])
        .value();

      return finalFilteredChanges;
    },
    overallTasks() {
      const currentList = this.chosenChangeList;
      const selectedStatus = this.activeLeftPane;
      return this.filterSelectedStatusTasks(currentList, selectedStatus);
    },
  },
  methods: {
    getDepartmentVerifiedChanges: getDepartmentVerifiedChanges,
    filterSelectedStatusTasks(currentList, selectedStatus) {
      if (!this.isAuthorizedToRead) {
        return [];
      }

      const activeDepartment = this.activeDepartment && [this.activeDepartment.toLowerCase()];

      const defaultUserObject = {
        keys: [],
        values: [],
      };
      const { departments, permissions, roles } = this.getUserAuthentication;

      const editedUserDepartments = _.map(
        _.cloneDeep(departments),
        (v) => v && v.toLowerCase()
      );
      editedUserDepartments.push("general");

      const filteredDepartments =
        _.isEmpty(activeDepartment) ||
        activeDepartment.includes("all departments")
          ? editedUserDepartments
          : activeDepartment;

      let currentDepartmentFilteredList = getDepartmentVerifiedChanges(
        currentList,
        filteredDepartments
      );

      const table = _.chain(currentDepartmentFilteredList)
        .filter((change) => {
          // Filter by search values
          if (this.searchTerm) {
            return JSON.stringify(change)
              .toLowerCase()
              .includes(this.searchTerm);
          } else {
            return true;
          }
        })
        .filter((change) => {
          if (selectedStatus === "pending") {
            return ["add", "modify", "delete"].includes(change.status);
          } else {
            // selectedStatus === "completed"
            return ["approve", "reject"].includes(change.status);
          }
        })
        .uniqBy((v) => {
          // TODO: @JOSH if else
          if (selectedStatus === "pending") {
            return v.mainType;
          } else {
            // selectedStatus === "completed"
            return [v.mainType, v.last_modified].join();
          }
        })
        .map((change) => {
          const { mainType, last_modified } = change;

          switch (mainType) {
            case "intent":
            case "example":
              return {
                last_modified,
                last_modified_type: _.concat(last_modified, "intent").join("_"),
                type: "Intents",
                mainType: "intent",
                list:
                  this.fetchIntents(
                    currentList,
                    filteredDepartments,
                    last_modified,
                    selectedStatus
                  ) || [],
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchIntents(
                      currentList,
                      filteredDepartments,
                      last_modified,
                      selectedStatus
                    )
                  ) || defaultUserObject,
              };

            case "entity":
            case "value":
            case "pattern":
            case "synonym":
              return {
                last_modified,
                last_modified_type: _.concat(last_modified, "entity").join("_"),
                type: "Entities",
                mainType: "entity",
                list:
                  this.fetchEntities(
                    currentList,
                    filteredDepartments,
                    last_modified,
                    selectedStatus
                  ) || [],
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchEntities(
                      currentList,
                      filteredDepartments,
                      last_modified,
                      selectedStatus
                    )
                  ) || defaultUserObject,
              };
            case "dialog":
              return {
                last_modified,
                last_modified_type: _.concat(last_modified, "dialog").join("_"),
                type: "Dialogs",
                mainType: "dialog",
                list:
                  this.fetchDialogs(
                    currentList,
                    filteredDepartments,
                    last_modified,
                    selectedStatus
                  ) || [],
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchDialogs(
                      currentList,
                      filteredDepartments,
                      last_modified,
                      selectedStatus
                    )
                  ) || defaultUserObject,
              };
            case "editor":
              return {
                last_modified,
                last_modified_type: _.concat(last_modified, "editor").join("_"),
                type: "Editor",
                mainType: "editor",
                list:
                  this.fetchEditor(
                    currentList,
                    editedUserDepartments,
                    last_modified,
                    selectedStatus
                  ) || {},
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchEditor(
                      currentList,
                      editedUserDepartments,
                      last_modified,
                      selectedStatus
                    )
                  ) || defaultUserObject,
              };
          }
        })
        .filter((changeLineItem) => {
          // Filter by task type, mapped to mainType
          const activeTaskType =
            this.activeTaskType &&
            _.chain(this.activeTaskType)
              .map((v) => v.toLowerCase())
              .map((v) => {
                switch (v) {
                  case "intent":
                  case "example":
                    v = "intent";
                    break;

                  case "entity":
                  case "value":
                  case "pattern":
                  case "synonym":
                    v = "entity";
                    break;
                  case "dialog":
                    v = "dialog";
                    break;
                  case "editor":
                    v = "editor";
                    break;
                }
                return v;
              })
              .value();

          if (changeLineItem && activeTaskType.length > 0) {
            return activeTaskType.includes(changeLineItem.mainType);
          } else {
            return true;
          }
        })
        .map((listLineItem) => {
          if (listLineItem) {
            const last_modified = listLineItem.last_modified;
            const moment_last_modified = moment(
              last_modified,
              "DD-MM-YYYY HH:mm"
            );
            _.assign(listLineItem, { moment_last_modified });
          }

          return listLineItem;
        })
        .orderBy(["moment_last_modified", "type"], ["desc", "asc"])
        .value();

      return table;
    },
    publishContent() {
      this.pendingLoading = true;
      this.$store
        .dispatch("PUBLISH_EDITOR_CONTENT")
        .then((isPublished) => {
          if (isPublished) {
            this.pendingLoading = false;
            this.fetchPendingChanges();
            this.fetchCompletedChanges();
            this.resetChangeSelection();
            this.$message({
              type: "success",
              message:
                "All DEV Changes has been published to PROD successfully",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error publishing content.",
          });
          this.pendingLoading = false;
        });
    },

    typeFilter(type, department) {
      if (department === "all") {
        return [type.toLowerCase()];
      }
      if (_.get(this, "multipleSelection.length") === 0) {
        return [];
      }

      const selectedMainTypes = _.map(this.multipleSelection, (change) => {
        return change.mainType && change.mainType.toLowerCase();
      });
      const uniqueTypes = _.uniq(selectedMainTypes);
      return uniqueTypes;
    },

    departmentsFilter(type, department) {
      const userDepartments = this.departments || [];
      const lowerCaseUserDepartments = userDepartments.map((v) =>
        v.toLowerCase()
      );

      const activeDepartment = this.activeDepartment || "";
      const lowerCaseActiveDepartment = [activeDepartment].map((v) =>
        v.toLowerCase()
      );

      const allDeparmentsSelected =
        department === "all" ||
        !department ||
        lowerCaseActiveDepartment.includes("all departments");

      if (allDeparmentsSelected) {
        return userDepartments;
      }

      if (lowerCaseActiveDepartment) {
        const userValidatedDeparments = _.intersection(
          activeDepartment,
          userDepartments
        );
        return userValidatedDeparments;
      }
    },

    dataFilter(type, department) {
      const dependenceMap = this.dependenceMap;

      if (department === "all") {
        const filteredChanges = getDepartmentVerifiedChanges(
          this.pendingChangesList,
          this.filteredDepartments
        );

        const finalChanges = filteredChanges.filter((change) => {
          return change.mainType === type;
        });
        return finalChanges;
      }

      if (_.get(this, "multipleSelection.length") === 0) {
        return [];
      }

      const finalChanges = _.chain(this.multipleSelection)
        .map((change) => {
          let tempArray = [];
          const changeHasDependencies = dependenceMap.has(change.RowKey);
          if (changeHasDependencies) {
            const dependentChange = dependenceMap.get(change.RowKey);
            const dependenceArr = dependentChange.dependence;

            const latestChange = this.changesMap.get(change.RowKey);
            tempArray.push(latestChange); // Push the latest change first

            this.dependenceMap.delete(change.RowKey);

            dependenceArr &&
              dependenceArr.map((rowKey) => {
                const tempChange = this.changesMap.get(rowKey);
                tempArray.push(tempChange); // Push other previous changes of same ID
              });
          }
          return tempArray;
        })
        .flattenDeep()
        .orderBy(["last_modified"], ["desc"])
        .value();

      return finalChanges;
    },

    resolverDataHandover(type, department) {
      const result = {
        listOfChanges: this.dataFilter(type, department),
        types: this.typeFilter(type, department),
        departments: this.departmentsFilter(type, department),
      };
      return result;
    },

    setupAndPublishChangesNow(mainType, department) {
      this.selectedApprove = { mainType, department };
      this.publishChangesNow();
    },

    publishChangesNow() {
      const publishEnabled = _.get(
        this.makerCheckerConfiguration,
        "publish",
        false
      );

      if (publishEnabled) {
        this.approveConfirmationVisible = true;
        this.approveConfirmationLoading = true;
      }

      const { mainType: type, department } = this.selectedApprove;
      const { publish_date, publishName } = this.approveConfirmationForm;
      const { listOfChanges, types, departments } = this.resolverDataHandover(
        type,
        department
      );

      const payload = {
        publish_date,
        publishName,
        listOfChanges,
        types,
        departments,
      };
      this.$store
        .dispatch("CREATE_AND_COMPLETE_PUBLISH", payload)
        .then((isPublished) => {
          if (isPublished) {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "success",
              message: "Changes has been approved and published successfully",
            });
            this.fetchPendingChanges();
            this.fetchCompletedChanges();
            this.resetChangeSelection();
          } else {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "error",
              message: "Encountered error trying to approve changes",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error creating and publish content.",
          });
          this.approveConfirmationLoading = false;
          this.approveConfirmationVisible = false;
        });
    },

    resetActiveLeftPane() {
      if (this.homeActiveTab === "workbench") {
        this.activeLeftPane = "pending";
      }
    },
    openRejectConfirmation(mainType, department) {
      this.showRejectConfirmationForm = true;
      this.$store.commit("SET_MAIN_TYPE", mainType);
      this.$store.commit("SET_DEPARTMENT_TYPE", department);
    },
    openApproveConfirmation(mainType, department) {
      this.approveConfirmationVisible = true;
      this.$store.commit("SET_MAIN_TYPE", mainType);
      this.$store.commit("SET_DEPARTMENT_TYPE", department);
    },
    closeApprove() {
      this.approveConfirmationVisible = false;
      this.showRejectConfirmationForm = false;
    },

    resetChangeSelection() {
      this.specificChange = {};
      this.collapseAll();
      this.activeDepartment = "";
      this.searchTerm = "";
      this.activeTaskType = [];
    },

    selectChange(user, numberOfChanges, typeOfChange, mainType, last_modified) {
      this.specificChange = {
        modified_by: user && user.toLowerCase(),
        numberOfChanges,
        type: typeOfChange && typeOfChange.toLowerCase(),
        mainType,
        last_modified,
      };
    },

    leftTogglePendingRowExpansion(type) {
      if (!this.leftPendingRowExpandData.includes(type)) {
        this.leftPendingRowExpandData.push(type);
      } else {
        this.leftPendingRowExpandData.splice(
          _.indexOf(this.leftPendingRowExpandData, type),
          1
        );
      }
    },
    leftToggleCompletedRowExpansion(last_modified_type) {
      if (!this.leftCompletedRowExpandData.includes(last_modified_type)) {
        this.leftCompletedRowExpandData.push(last_modified_type);
      } else {
        this.leftCompletedRowExpandData.splice(
          _.indexOf(this.leftCompletedRowExpandData, last_modified_type),
          1
        );
      }
    },
    collapseAll() {
      this.isCollapsed = true;
      this.rowExpandData = [];
    },

    reject(type, department, date) {
      this.pendingLoading = true;
      const { listOfChanges, types, departments } = this.resolverDataHandover(
        type,
        department
      );

      const payload = {
        type: types,
        date,
        departments,
        customListOfChanges: listOfChanges,
      };

      const isEmpty = listOfChanges.length === 0;
      if (isEmpty) {
        this.$notify.error({
          title: "Empty Change list",
          position: "bottom-right",
          message: "No changes were selected",
        });
        this.showRejectConfirmationForm = false;
        return;
      }

      this.$store
        .dispatch("REJECT_CHANGES", payload)
        .then((isRejected) => {
          this.pendingLoading = false;
          if (isRejected) {
            this.fetchPendingChanges();
            this.fetchCompletedChanges();
            this.resetChangeSelection();
            if (
              this.makerCheckerConfiguration &&
              this.makerCheckerConfiguration.sendEmail
            ) {
              this.sendRejectionEmail(
                _.assign(this.rejectConfirmationForm, {
                  customListOfChanges: listOfChanges,
                })
              );
            }
          } else {
            this.$message({
              type: "error",
              message: "Encountered error rejecting changes.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error rejecting changes.",
          });

          this.pendingLoading = false;
        });
    },

    sendRejectionEmail(payload) {
      this.$store
        .dispatch("SEND_REJECTION_EMAIL", payload)
        .then((sent) => {
          if (sent) {
            this.$message({
              type: "success",
              message:
                "Changes has been rejected successfully. Email has been sent.",
            });
          } else {
            this.$message({
              type: "error",
              message:
                "Failed to reject changes in server. No email was been sent.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message:
              "Encountered error sending rejection email. No email was sent.",
          });
        });
    },

    dataUserFilter(type, department) {
      const dependenceMap = this.dependenceMap;
      if (department === "all") {
        const filteredData = getDepartmentVerifiedChanges(
          this.pendingChangesList,
          this.filteredDepartments
        );
        const finalChanges = _.chain(filteredData)
          .filter((change) => {
            return change.mainType === type;
          })
          .map((change) => {
            return change.modified_by;
          })
          .uniq()
          .value()
          .join(";");

        return finalChanges;
      }

      if (_.get(this, "multipleSelection.length") === 0) {
        return [];
      }

      return _.chain(this.multipleSelection)
        .map((change) => {
          let tempArray = [];
          if (dependenceMap.has(change.RowKey)) {
            const dependentChange = dependenceMap.get(change.RowKey);
            const dependence = dependentChange.dependence;

            tempArray.push(this.changesMap.get(change.RowKey)); // Push the latest change
            dependence &&
              dependence.map((rowKey) => {
                tempArray.push(this.changesMap.get(rowKey)); // Push other previous changes of same ID
              });
          }
          return tempArray;
        })
        .flattenDeep()
        .orderBy(["last_modified"], ["asc"])
        .map((change) => {
          return change.modified_by;
        })
        .uniq()
        .value()
        .join(";");
    },

    fetchPendingChanges(status = "pending") {
      const makerCheckerEnabled = _.get(
        this.makerCheckerConfiguration,
        "enabled",
        false
      );

      if (!makerCheckerEnabled) {
        this.pendingLoading = false;
        return;
      }

      this.pendingLoading = true;
      this.$store
        .dispatch("FETCH_CHANGES", { status })
        .then((changes) => {
          this.pendingLoading = false;
          // this.pendingChangesList = changes;
          this.mapDependence(changes);
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error fetching changes.",
          });
          this.pendingLoading = false;
        });
    },
    fetchCompletedChanges(status = "completed", offset = 0) {
      const makerCheckerEnabled = _.get(
        this.makerCheckerConfiguration,
        "enabled",
        false
      );
      if (!makerCheckerEnabled) {
        this.completedLoading = false;
        return;
      }

      this.completedLoading = true;
      this.$store
        .dispatch("FETCH_CHANGES", { status, offset })
        .then((changes) => {
          this.completedLoading = false;
          // this.completedChangesList = changes;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error fetching changes.",
          });
          this.completedLoading = false;
        });
    },
    fetchNextHundredCompletedChanges(status = "completed") {
      const currentCompletedSize = _.get(this.completedChangesList, "length");
      const completedChangesExist = currentCompletedSize > 0;

      const makerCheckerEnabled = _.get(
        this.makerCheckerConfiguration,
        "enabled",
        false
      );

      if (!makerCheckerEnabled) {
        this.completedLoading = false;
        return;
      }

      this.completedLoading = true;
      this.$store
        .dispatch("FETCH_CHANGES", { status, offset: currentCompletedSize })
        .then((changes) => {
          this.completedLoading = false;
          if (changes && changes.length === 0) {
            this.allCompletedChangedFetched = true;
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error fetching changes.",
          });
          this.completedLoading = false;
        });
    },
    mapDependence(currentList) {
      const tempDependenceMap = new Map();
      let tempDialogChangeArray = [];
      let tempDialogChangesMap = new Map();
      const { departments, permissions, roles } = this.getUserAuthentication;
      const results = _.chain(currentList)
        .orderBy(["last_modified"], ["asc"])
        .filter((change) => {
          const lowerCaseChangeDepartment =
            change.department && change.department.map((v) => v.toLowerCase());
          if (departments) {
            const lowerCaseDepartments = departments.map((v) =>
              v.toLowerCase()
            );
            return (
              _.intersection(lowerCaseDepartments, lowerCaseChangeDepartment)
                .length > 0 || lowerCaseChangeDepartment.includes("general")
            );
          } else {
            return (
              _.intersection(["general"], lowerCaseChangeDepartment).length > 0
            );
          }
        })
        .filter((change) =>
          ["entity", "intent", "editor", "dialog"].includes(change.mainType)
        )
        .map((change) => {
          this.changesMap.set(change.RowKey, change);
          const status = change.status;
          const type = change.mainType;
          // Dependence set up
          const old_values = _.get(change, "old_values");
          const new_values = _.get(change, "new_values");
          const old_id =
            old_values.id ||
            old_values.entity ||
            old_values.intent ||
            old_values.dialog_node;

          const new_id =
            new_values.id ||
            new_values.entity ||
            new_values.intent ||
            new_values.dialog_node;

          const isDiff = !_.isEqual(old_id, new_id);
          let id = "";
          let final_old_id = type + "." + old_id;
          let final_new_id = type + "." + new_id;

          switch (status) {
            case "add":
              id = final_new_id;
              break;
            case "modify":
              id = isDiff ? final_new_id : final_old_id;
              break;
            case "delete":
              id = final_old_id;
              break;
          }

          if (type === "dialog") {
            // Additional mapping for dialog.
            // Earlier changes has to be selected together or before the older ones,

            tempDialogChangesMap.set(
              change.RowKey,
              _.cloneDeep(tempDialogChangeArray)
            );
            tempDialogChangeArray.push(change.RowKey);
          }

          if (tempDependenceMap.has(id)) {
            // Adjusting existing IDs to get the latest change
            let tempObject = tempDependenceMap.get(id);
            if (
              !tempObject.dependence.includes(tempObject.RowKey) &&
              tempObject.RowKey !== change.RowKey
            ) {
              tempObject.dependence.push(tempObject.RowKey);
            }

            // Set the latest change as the new anchor
            tempDependenceMap.set(id, {
              value: new_values,
              last_modified: change.last_modified,
              RowKey: change.RowKey,
              change: change,
              dependence: tempObject.dependence,
            });
          }
          if (tempDependenceMap.has(final_old_id) && isDiff) {
            // modifying existing IDs
            let tempObject = tempDependenceMap.get(final_old_id);
            if (
              !tempObject.dependence.includes(tempObject.RowKey) &&
              tempObject.RowKey !== change.RowKey
            ) {
              tempObject.dependence.push(tempObject.RowKey);
            }

            // add in delete from dependencemap
            tempDependenceMap.delete(final_old_id);
            // Set the latest change as the new anchor
            tempDependenceMap.set(id, {
              value: new_values,
              last_modified: change.last_modified,
              RowKey: change.RowKey,
              change: change,
              dependence: tempObject.dependence,
            });
          }

          if (!tempDependenceMap.has(id)) {
            // Set the new change as the anchor
            tempDependenceMap.set(id, {
              value: new_values || old_values,
              last_modified: change.last_modified,
              RowKey: change.RowKey,
              change: change,
              dependence: [],
            });
          }
        })
        .value();

      this.dependenceMap = new Map();
      tempDependenceMap.forEach((value, id) => {
        // This will set the main latest changes with other changes as dependencies.
        const { dependence, RowKey } = value;
        this.dependenceMap.set(RowKey, _.assign(value, { id }));

        // This is to map each change to the main change that needs to be approved

        this.overallDependenceMap.set(RowKey, RowKey);
        dependence.map((dependencyRowKey) => {
          this.overallDependenceMap.set(dependencyRowKey, RowKey);
        });
      });
      // console.log("tempDependenceMap: ", tempDependenceMap);
      // console.log("overallDependenceMap: ", this.overallDependenceMap);

      // Dialog related only!
      // This will set up the list of Dialog changes that have to be
      const noDialogChanges = tempDialogChangesMap.size === 0;
      if (noDialogChanges) {
        return;
      }

      const dependenceValues = Array.from(
        tempDependenceMap.values() || new Map()
      );

      const tempLatestChangesRowKeyArray = _.map(
        dependenceValues,
        (value) => value.RowKey
      );

      tempLatestChangesRowKeyArray.forEach((rowKey) => {
        const arrayOfEarlierChangesThanCurrent = tempDialogChangesMap.get(
          rowKey
        );
        const dependentLatestChanges =
          _.intersection(
            tempLatestChangesRowKeyArray,
            arrayOfEarlierChangesThanCurrent
          ) || [];

        this.dialogChangesMap.set(rowKey, dependentLatestChanges);
      });

      // console.log("dialogChangesMap: ", this.dialogChangesMap);
    },
  },
  mounted() {
    this.fetchPendingChanges();
    this.fetchCompletedChanges();
  },
});
</script>

<style scoped>
.specific-change-row:hover {
  cursor: pointer;
}

.completed-view-more {
  color: grey;
}
.completed-view-more:hover {
  color: #e4392b;
}
</style>

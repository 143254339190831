import axios, { AxiosRequestConfig } from "axios";
import { getAuthType, getToken } from "@/auth";
import { formatToken } from "@/helperMethods/util";
import { logAxiosExceptionToSentry } from "@/sentry";
import { config } from "@/helpers/apiConfig";

export const initREST = () => {
  const restInstance = axios.create({
    baseURL: config.root,
  });

  restInstance.interceptors.request.use(
    async (config) => {
      config.headers["Authorization"] = await getToken().then(formatToken);
      config.headers["Authorization-Client"] = getAuthType();
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  restInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      logAxiosExceptionToSentry(error);
      return Promise.reject(error);
    }
  );

  return (
    method: AxiosRequestConfig["method"],
    endpoint: string,
    payload: Object
  ) => {
    return restInstance
      .request({
        method,
        url: `/api/${endpoint}`,
        data: payload,
      })
      .then((res: any) => res.data);
  };
};

import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { TransactionState } from "./types";
import _ from "lodash";

const getters: GetterTree<TransactionState, RootState> = {
  currentType(state, getters, rootState) {
    return state.currentType;
  },
  currentRow(state, getters, rootState) {
    return state.currentRow;
  },
};

export default getters;

import _ from "lodash";
import moment from "moment";

function stringifyArray(fieldObj: {}) {
  return _.reduce(
    fieldObj,
    (accum, value, key) => {
      if (accum === "") {
        accum += `${key}: ${value}`;
      } else {
        accum += `,${key}: ${value}`;
      }
      return accum;
    },
    ""
  );
}

export function formatAggregator(aggregator: number) {
  return aggregator.toFixed(2);
}
export function exportToCSV(
  type: string,
  fields: any,
  transactions: any
): string[][] {
  const ws_data: any[] = [];
  ws_data.push([type, ""]);
  ws_data.push([]);
  ws_data.push(["ID", "Date", ..._.map(fields, (a) => a.displayValue)]);
  _.forEach(transactions, (batchTestRow) => {
    const data = _.get(batchTestRow, "data");
    if (!data) return;

    const ws_row: any[] = [];
    ws_row.push(batchTestRow.id);
    ws_row.push(batchTestRow.createdAt);

    const longestArray = _.chain(data)
      .filter((values, fieldName) => Array.isArray(values))
      .orderBy([(values, fieldName) => values.length], ["desc"])
      .head()
      .value();

    if (!longestArray) {
      _.forEach(_.keys(fields), (field) => {
        const fieldData = _.get(data, field, "");
        if (!fieldData) {
          ws_row.push(fieldData);
          return;
        }
        if (fields[field].dataType === "datetime") {
          ws_row.push(moment(fieldData).format("DD/MM/YYYY"));
        } else {
          ws_row.push(fieldData);
        }
      });
      ws_data.push(ws_row);
    } else {
      _.forEach(_.keys(fields), (field) => {
        const fieldData = _.get(data, field, "");
        if (!fieldData) {
          ws_row.push(fieldData);
        } else if (fields[field].dataType === "array") {
          let stringifiedArray = fieldData
            .map((_data: any) => stringifyArray(_data))
            .join("\r\n");

          for (const summaryKey in batchTestRow.data.summary) {
            const dataType = _.get(
              batchTestRow,
              `data.summary[${summaryKey}]`,
              ""
            );
            let value = batchTestRow.data.summary[summaryKey].value;
            if (dataType === "currency") {
              value = formatAggregator(value);
            }
            stringifiedArray += `\r\n${batchTestRow.data.summary[summaryKey].displayValue} - ${value}`;
          }

          ws_row.push(stringifiedArray);
        } else if (fields[field].dataType === "datetime") {
          ws_row.push(moment(fieldData).format("DD/MM/YYYY"));
        } else {
          ws_row.push(fieldData);
        }
      });
      ws_data.push(ws_row);
    }
  });

  return ws_data;
}

export const pickerOptions = {
  shortcuts: [
    {
      text: "Last week",
      onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "Last month",
      onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "Last 3 months",
      onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      },
    },
  ],
};

export function bulkUploadPayload(selectedRows: any, statusToUpdate: any): any {
  const selectedTransactions = _.map(selectedRows, (row) => {
    row.data[statusToUpdate.name] = statusToUpdate.value;

    return {
      data: JSON.stringify(row.data),
      whereClause: { id: row.id },
    };
  });
  return selectedTransactions;
}

<template>
  <el-row v-loading="fetchQueueChatLoading">
    <el-row
      v-for="(chat, index) in leadPriorityChatsList"
      :key="`${chat.PartitionKey}-${chat.RowKey}`"
      @click.native="selectChat(chat.RowKey)"
      style="margin-bottom: 4px"
    >
      <el-badge
        :class="getChatClass(chat.RowKey, chat, selectedChatId)"
        :value="chat.unreadMessage"
        :hidden="chat.unreadMessage === 0"
        style="z-index: 999; width: 98%"
      >
        <el-row style="margin-bottom: 0">
          <el-col
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            <el-row type="flex" style="margin-bottom: 0; align-items: center">
              <ChatAvatar :chat="chat" style="display: inline-block" />
              <el-row
                type="flex"
                style="
                  flex-direction: column;
                  margin-bottom: 0;
                  overflow: hidden;
                "
              >
                <span style="font-size: 16px">{{ getChatName(chat) }}</span>
                <div
                  style="
                    text-align: left;
                    margin-top: 3px;
                    font-size: 0.6em;
                    margin-right: 5px;
                  "
                >
                  {{ waitedTime[index] }}
                </div>
              </el-row>
            </el-row>
          </el-col>
          <el-col
            v-if="chat.agents.length > 0"
            :span="24"
            style="
              font-size: 0.6em;
              color: #757575;
              margin-top: 5px;
              white-space: normal;
              font-weight: 400;
            "
          >
            <i>Attended by {{ chat.agents.join(", ") }}</i>
          </el-col>
        </el-row>
      </el-badge>
      <!-- </el-row> -->
    </el-row>

    <!-- Is Empty -->
    <p v-if="!chatsList || chatsList.length === 0">
      {{
        isUnassignedChatList
          ? "There are no users who need your help at the moment."
          : "You are not attending to any users at the moment."
      }}
    </p>
  </el-row>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { simplifyMessage } from "@/helperMethods/livechat/simplifyMessage";
import ChatAvatar from "@/components/LiveChat/ChatAvatar";
import { mapGetters } from "vuex";
import { getChatName } from "@/filters";
import {
  getDisplayId,
  getChatClass,
  getAvatar,
  getPreview,
  printDuration,
} from "@/helperMethods/livechat/util";

export default {
  name: "ChatsList",
  components: {
    ChatAvatar,
  },
  props: {
    chatsList: Array,
    selectedIndex: Number,
    closed: Boolean,
    name: String,
  },
  data() {
    return {
      waitedTime: [],
      tab: "queue",
    };
  },
  computed: {
    ...mapGetters([
      "fetchQueueChatLoading",
      "selectedChatId",
      "selectedChat",
      "queueChatsArray",
    ]),
    isUnassignedChatList() {
      return this.name === "unassignedChatList";
    },
    isUnassignedChatListEmpty() {
      return this.chatsList.length === 0;
    },
    leadPriorityChatsList() {
      if (this.chatsList) {
        const sortedList = _.sortBy(this.chatsList, (chat) => {
          return moment(chat.startQueueTime).valueOf();
        });
        return sortedList;
      } else {
        return [];
      }
    },
  },

  methods: {
    getAvatar,
    getDisplayId,
    getChatClass,
    getPreview,
    printDuration,
    getChatName,
    selectChat(chatId) {
      this.$store.commit("SET_SELECTED_CHAT_ID", chatId);
      this.$store.dispatch("RESET_UNREAD_MESSAGE", chatId);
      this.$emit("update:closed", true);
    },
    getSessionHistory(chat) {
      if (chat.interactions) {
        const chatInteractions = _.orderBy(
          chat.interactions,
          [
            (interaction) => {
              return moment(interaction.date_created).valueOf();
            },
          ],
          ["asc"]
        );
        const afterSlice = _.slice(
          chatInteractions,
          chatInteractions.length - 15
        );
        const sessionHistory = _.chain(afterSlice)
          .map(simplifyMessage)
          .compact()
          .value();
        return sessionHistory;
      }
      return [];
    },
    calculateWaitedTime() {
      this.waitedTime = _.map(this.leadPriorityChatsList, (chat) => {
        return this.printDuration(chat.startQueueTime, moment().format());
      });
    },
  },
  mounted() {
    this.calculateWaitedTimeinterval = setInterval(() => {
      this.calculateWaitedTime();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.calculateWaitedTimeinterval);
  },
};
</script>

<style scoped>
@media (min-width: 580px) and (max-width: 1024px) {
  .chat-tooltip {
    font-size: 1.5em;
  }
}

.chat-tooltip::-webkit-scrollbar {
  background-color: #2d2f3a;
  width: 5px;
}

/* Track */
.chat-tooltip::-webkit-scrollbar-track {
  background-color: #2d2f3a;
  border-radius: 10px;
}

.chat-tooltip::-webkit-scrollbar-track-piece {
  background-color: #2d2f3a;
}

/* Handle */
.chat-tooltip::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
}

.chat-tooltip {
  max-width: 300px;
  max-height: 500px;
  overflow-y: scroll;
}

.list-item {
  width: 95%;
  padding: 8px;
  border: 1px solid #e0e6ed;
  /* line-height: 32px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.active-chat {
  font-weight: 600;
  /* background: #f6f8f9; */
  background: #d0dfff;
  border: solid black 1px;
}
.joined-chat {
  /* background: rgb(255, 251, 212); */
  background: #f5f7fd;
}

.prev-conversation {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin: 0 auto 15px;
  font-size: 12px;
  cursor: pointer;
}

.list-item.appointment-chat {
  background: #fef0f0;
  border-color: #fbc4c4;
}

@keyframes flashing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.911;
  }
}

.notification-flash {
  background-color: rgba(255, 191, 102);
  will-change: opacity;
  animation: flashing 1500ms 3.5 forwards;
}
</style>

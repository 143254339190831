<template>
  <el-form-item
    label="Fields"
    :error="hasFieldNameNotUniqueError || error ? 'Fields are invalid.' : null"
  >
    <el-card
      class="box-card"
      style="margin-bottom: 15px;"
      v-for="(field, index) in fields"
      :key="`meta-field-${index}`"
    >
      <el-button
        @click.prevent="onDelete(index)"
        plain
        circle
        v-if="canEdit"
        :disabled="!canDeleteField"
        class="position-absolute-top-right"
        type="danger"
        size="mini"
        icon="el-icon-delete"
      ></el-button>

      <el-row :gutter="12" style="margin-bottom: 0;">
        <el-col :xs="24">
          <el-tag size="mini">{{ field.dataType }}</el-tag>
        </el-col>

        <!-- field display value / placeholder start -->
        <el-col :xs="24" :sm="24" :md="12">
          <el-input
            @change="onDisplayNameChanged(index)"
            size="mini"
            v-model="field.displayValue"
            :disabled="!canEdit"
          >
            <template slot="prepend">Name</template>
          </el-input>
        </el-col>
        <!-- field display value / placeholder end -->

        <!-- field column width start -->
        <el-col :xs="24" :sm="24" :md="12" v-if="field.dataType.toLowerCase() !== 'array'">
          <el-input size="mini" v-model="field.columnWidth" :disabled="!canEdit">
            <template slot="prepend">Column Width</template>
          </el-input>
        </el-col>
        <!-- field column width end -->
      </el-row>
      <el-row :gutter="12" style="margin-bottom: 0;">
        <el-col :xs="24">
          <component :is="getComponentName(field.dataType)" :field="field" :canEdit="canEdit"></component>
        </el-col>
      </el-row>
    </el-card>

    <slot></slot>
  </el-form-item>
</template>

<script>
import Vue from "vue";
import TypeComponentString from "./FieldTypes/String";
import TypeComponentList from "./FieldTypes/List";
import TypeComponentNumber from "./FieldTypes/Number";
import TypeComponentDatetime from "./FieldTypes/Datetime";
import TypeComponentArray from "./FieldTypes/Array/Index";
import { toCamelCase } from "@/filters/index";

export default Vue.extend({
  props: ["fields", "error", "canEdit"],
  methods: {
    /**
     * @description Get component name for the dataType
     * @return {string}
     */
    getComponentName(name) {
      const _capitalize = _.capitalize(name);
      return "TypeComponent" + _capitalize;
    },

    /**
     * @description Slugify displayName to name
     * @return {void}
     */
    onDisplayNameChanged(index) {
      this.fields[index].stateRefKey = toCamelCase(
        this.fields[index].displayValue
      );
    },

    /**
     * @description Handle field delete button event
     * @param {number} index
     * @return {void}
     */
    onDelete(index) {
      if (this.canDeleteField) {
        this.$confirm("Are you sure to delete this field?", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel"
        }).then(() => this.fields.splice(index, 1));
      }
    }
  },
  computed: {
    /**
     * @description Can delete field check
     * @description Atleast 1 field is required
     * @return {boolean}
     */
    canDeleteField() {
      return this.fields.length > 1;
    },

    /**
     * @description Check for unique field name
     * @return {boolean}
     */
    hasFieldNameNotUniqueError() {
      const hasMoreThanOneField = this.fields.length > 1;
      return (
        hasMoreThanOneField &&
        _.chain(this.fields)
          .map("stateRefKey")
          .uniq()
          .value().length === 1
      );
    }
  },
  components: {
    TypeComponentString,
    TypeComponentNumber,
    TypeComponentDatetime,
    TypeComponentList,
    TypeComponentArray
  }
});
</script>

<style scoped>
>>> .el-card__body {
  position: relative;
}

>>> .position-absolute-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
</style>

<template>
  <div>
    <el-card v-if="value[name]">
      <h3 slot="header">
        {{ setting.label || name }}
        <el-tooltip
          v-if="setting.detail"
          effect="dark"
          :content="setting.detail"
          placement="right"
        >
          <i
            class="el-icon-question"
            style="margin-left:0.5em; margin-right:0.5em; color: #909399"
          />
        </el-tooltip>

        <!-- <component
          v-if="'enabled' in setting.children"
          v-model="value[name]"
          name="enabled"
          :setting="setting.children.enabled"
          :is="toComponent(setting.children.enabled)"
          style="margin-left: 8px;"
        ></component>-->
      </h3>
      <div style="display:flex;flex-direction:column;">
        <component
          v-model="value[name]"
          v-for="(childSetting, childKey) in setting.children"
          :key="childKey"
          :name="childKey"
          :setting="childSetting"
          :is="toComponent(childSetting)"
          :modules="modules"
          style="margin:8px;"
        ></component>
      </div>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";
import SmartFieldString from "./String";
import SmartFieldNumber from "./Number";
import SmartFieldAutocomplete from "./Autocomplete";
import SmartFieldTimerange from "./Timerange";
import SmartFieldTag from "./Tag";
import SmartFieldBoolean from "./Boolean";
import SmartFieldColor from "./Color";
import SmartFieldSelect from "./Select";
import SmartFieldMultiselect from "./Multiselect";
import SmartFieldTextarea from "./Textarea";
import SmartFieldJson from "./Json";
import SmartFieldButton from "./Button";
import SmartFieldTransfer from "./Transfer";
import SmartFieldCascader from "./Cascader";
import SmartFieldCascaderwithheaders from "./CascaderWithHeaders";
// import SmartFieldHandoverroutingskillslibrary from "./Custom/HandoverRoutingSkillsLibrary";
import SmartFieldOfficehourdepartmentdatetimes from "./Custom/OfficeHourDepartmentDatetimes";

export default {
  props: ["value", "setting", "name", "modules"],
  components: {
    SmartFieldString,
    SmartFieldNumber,
    SmartFieldAutocomplete,
    SmartFieldTimerange,
    SmartFieldTag,
    SmartFieldBoolean,
    SmartFieldColor,
    SmartFieldSelect,
    SmartFieldMultiselect,
    SmartFieldTextarea,
    SmartFieldJson,
    SmartFieldButton,
    SmartFieldTransfer,
    SmartFieldCascader,
    SmartFieldCascaderwithheaders,
    // SmartFieldHandoverroutingskillslibrary,
    SmartFieldOfficehourdepartmentdatetimes,
    SmartFieldObject: () =>
      import("@/components/ModuleEditor/SmartFields/Object"),
  },
  mounted() {
    const isNotObject = !_.isObject(this.value[this.name]);
    const isEmpty = _.isEmpty(this.value[this.name]);
    if (isNotObject || isEmpty) {
      this.$set(this.value, this.name, this.setting.default);
    }
  },
  methods: {
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const isComponentExist = _.has(this.$options.components, componentName);
      return isComponentExist ? componentName : null;
    },
  },
};
</script>

// @ts-nocheck

import moment from "moment";

export default {
  methods: {
    getDefaultFilter(filterType) {
      return [
        {
          name: "yesterday handover",
          description: "yesterday handover chat",
          styles: {
            color: "white",
            backgroundColor: "dimgrey",
            extras: {},
          },
          type: filterType,
          conditions: [
            { key: "searchTerm", value: "" },
            { key: "chatSources", value: "" },
            { key: "contextHistory", value: "handover" },
          ],
          dateRange: {
            type: "text",
            value: "last X days", // X <= 7
          },
        },
      ];
    },
  },
  computed: {
    coreFormMixin() {
      return {
        contextExpiryTiming: {
          label: "Time until context expires (in minutes)",
          detail: "Set the timing for chat message to expire",
          default: 1.5,
          type: "number",
        },
        session_length: {
          default: 30,
          min: 1,
          type: "number",
          label: "Session Length (in minutes)",
          detail:
            "The number of minutes before the bot starts to refresh for new session",
        },
        fileStorageAntivirus: {
          default: true,
          type: "boolean",
          label: "Enable Antivirus for file storage upload",
          detail: "If enabled, will run antivirus scanning first before upload",
        },
        officeHour: {
          default: {
            enabled: false,
            datetimes: [
              moment("2013-02-08 08:00:00").toDate(),
              moment("2013-02-08 19:00:00").toDate(),
            ],
          },
          type: "object",
          label: "Operating / Office Hours",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "Enable",
            },
            datetimes: {
              default: [
                moment("2013-02-08 08:00:00").toDate(),
                moment("2013-02-08 19:00:00").toDate(),
              ],
              type: "timerange",
              label: "Time",
              detail: "Indicated operating hours.",
            },
            departmentDatetimes: {
              default: {},
              type: "OfficeHourDepartmentDatetimes",
              departments: this.departments,
            },
          },
        },
        limitMaximumConcurrentUsers: {
          default: {
            enabled: false,
            max: 200,
          },
          type: "object",
          label: "Limit maximum number of concurrent users on the webchat",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "Enable",
            },
            max: {
              default: 200,
              min: 0,
              type: "number",
              label: "Maximum number",
              detail:
                "The maximum number of concurrent webchat users allowed at any one point before the webchat launcher is not shown to users",
            },
          },
        },
        channelCephStorageProvider: {
          default: {
            bucketName: "channel",
          },
          type: "object",
          label: "Ceph Storage for All Channels Agents/Users File Upload",
          detail: "Storage info to be separate from dashboard Files storage",
          children: {
            bucketName: {
              default: "channel",
              type: "string",
              label: "Folder Name",
              detail: "Folder name used by ceph storage",
            },
          },
        },
        inactivityManager: {
          default: {
            bot: {
              enabled: false,
              secondPrompt: false,
              threshold: 200,
            },
            livechat: {
              enabled: false,
              threshold: 200,
              resolveAfterFirstPrompt: false,
            },
            livechatAgent: {
              enabled: false,
              threshold: 200,
            },
          },
          type: "InactivityManager",
        },
        liveChatFilters: {
          default: {
            enabled: false,
            filters: {
              monitor: {},
              queued: {},
              resolved: {},
            },
          },
          type: "object",
          label: "Live chat custom filtering",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "Enable",
            },
            queuedChat: {
              default: this.getDefaultFilter("queuedChat"),
              type: "json",
              label: "For queuedChat view",
              detail: "Filtering queuedChat chats",
            },
            resolvedChat: {
              default: this.getDefaultFilter("resolvedChat"),
              type: "json",
              label: "For resolvedChat view",
              detail: "Filtering resolvedChat chats",
            },
            monitorChat: {
              default: this.getDefaultFilter("monitorChat"),
              type: "json",
              label: "For monitor view",
              detail: "Filtering monitor chats",
            },
          },
        },
      };
    },
  },
};

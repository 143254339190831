<template>
  <div>
    <template v-if="conditionIsEmpty">
      <small>
        <i style="color: #878d99;">This answer don't have condition.</i>
      </small>
    </template>
    <template v-else>
      <el-row
        type="flex"
        :key="`${answer.id}-condition-${conditionIndex}`"
        v-for="(condition, conditionIndex) in answer.conditions"
      >
        <el-input
          placeholder="Condition property"
          style="margin-right:5px;"
          size="mini"
          v-model="condition.property"
        ></el-input>
        <el-select
          v-model="condition.operator"
          style="margin-right:5px;"
          placeholder="Operator"
          size="mini"
          filterable
        >
          <!-- this options list from contentNode.ts // contentNodeOptionsMixin -->
          <el-option
            :key="`condition-operator-option-${index}`"
            v-for="(conditionOperatorOption, index) in conditionOperatorOptions"
            :value="conditionOperatorOption.value"
            :label="conditionOperatorOption.name"
          />
        </el-select>
        <el-input
          placeholder="Condition value"
          style="margin-right:5px;"
          size="mini"
          v-model="condition.value"
        ></el-input>
        <el-button
          round
          type="text"
          size="mini"
          icon="el-icon-delete"
          style="padding: 0;"
          @click.prevent="answer.conditions.splice(conditionIndex, 1)"
        ></el-button>
      </el-row>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { contentNodeConditionOptionsDataMixin } from "@/mixins/contentNode";

export default {
  props: ["answer"],
  mixins: [contentNodeConditionOptionsDataMixin],
  computed: {
    conditionIsEmpty() {
      return _.isEmpty(this.answer.conditions);
    }
  },
  methods: {
    onAdd() {
      const isCurrentConditionValid = _.isArray(this.answer.conditions);
      if (!isCurrentConditionValid) {
        this.answer.conditions = [];
      }
      this.answer.conditions.push({ property: "", operator: "", value: "" });
    }
  }
};
</script>
<template>
  <el-dialog
    :before-close="beforeDialogClose"
    :title="isEditMode ? `View Meta -> ${meta.title}` : 'Add New Meta'"
    :visible.sync="isDialogOpen"
    :show-close="false"
    append-to-body
    custom-class="transaction-meta-form"
  >
    <el-form
      label-position="left"
      label-width="100px"
      ref="meta-form"
      @submit.native.prevent="onSubmit"
    >
      <!-- meta title start -->
      <el-form-item
        label="Title"
        :error="
          $v.meta.title.$error ? 'Title is invalid / has been taken.' : null
        "
      >
        <el-input
          @change="onMetaTitleChanged"
          size="mini"
          v-model="meta.title"
          :disabled="!canEdit"
        ></el-input>
      </el-form-item>
      <!-- meta title end -->

      <!-- meta description start -->
      <el-form-item
        label="Description"
        :error="$v.meta.description.$error ? 'Description is invalid.' : null"
      >
        <el-input
          type="textarea"
          size="mini"
          v-model="meta.description"
          :disabled="!canEdit"
        ></el-input>
      </el-form-item>
      <!-- meta description end -->

      <!-- form extras component start -->
      <el-form-item label="Extras">
        <el-card
          class="box-card"
          style="margin-bottom: 15px;"
          v-for="(extra, index) in extraOptions"
          :key="`meta-extra-${index}`"
        >
          <FormExtra :meta="meta" :current="extra" :canEdit="canEdit" />
        </el-card>
      </el-form-item>
      <!-- form extras component end -->

      <!-- form fields component start -->
      <form-fields
        :error="$v.meta.fields.$each.$error"
        :fields="meta.fields"
        :canEdit="canEdit"
      >
        <el-popover
          v-model="addNewFieldPopover"
          placement="top"
          title="Select field type to create."
          trigger="click"
        >
          <el-radio-group
            @change="onAddNewField"
            v-model="temporaryType"
            size="mini"
          >
            <el-radio-button
              v-for="(fieldTypeOption, index) in fieldTypeOptions"
              :key="`data-type-option-${index}`"
              :value="fieldTypeOption"
              :label="fieldTypeOption"
              :disabled="!canEdit"
            ></el-radio-button>
          </el-radio-group>
          <el-button slot="reference" size="mini" :disabled="!canEdit"
            >Add More Field</el-button
          >
        </el-popover>
      </form-fields>
      <!-- form fields component end -->

      <el-form-item style="text-align:right;">
        <el-button
          @click.prevent="onDialogClose"
          plain
          :disabled="isBusy"
          :loading="isBusy"
          type="primary"
          native-type="button"
          size="mini"
          >Cancel</el-button
        >
        <el-button
          v-if="canEdit || !isEditMode"
          :disabled="isBusy"
          :loading="isBusy"
          type="primary"
          native-type="submit"
          size="mini"
          >{{ isEditMode ? "Save" : "Create" }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import FormFields from "./FormFields";
import FormExtra from "./FormExtra";
import {
  META,
  TYPES,
  EXTRAS,
  TYPE_STRING,
  TYPE_NUMBER,
  TYPE_DATETIME,
  TYPE_LIST,
  TYPE_ARRAY,
  TYPE_CURRENCY
} from "./Field";
import { required, minLength, between } from "vuelidate/lib/validators";
import { toCamelCase } from "@/filters/index";

export default Vue.extend({
  props: ["existingMetaTypes"],
  data() {
    return {
      isBusy: false,
      isDialogOpen: false,
      fieldTypeOptions: TYPES,
      temporaryType: null,
      addNewFieldPopover: false,
      meta: _.cloneDeep(META),
      extraOptions: EXTRAS
    };
  },
  validations() {
    return {
      meta: {
        title: {
          required,
          isUniqueMetaTitle: value => {
            if (!value) {
              return true;
            }

            const camelCased = toCamelCase(value);

            if (this.existingMetaTypes.indexOf(camelCased) < 0) {
              return true;
            }

            if (
              this.isEditMode &&
              this.oldMeta &&
              this.oldMeta.type === camelCased
            ) {
              return true;
            }

            return false;
          }
        },
        type: {
          required
        },
        description: {
          required
        },
        fields: {
          $each: {
            displayValue: {
              required
            },
            dataType: {
              required
            }
          }
        }
      }
    };
  },
  computed: {
    /**
     * @description Getter whether is edit mode or create a new meta.
     * @return {boolean}
     */
    isEditMode() {
      return _.has(this.meta, "id");
    },

    /**
     * @description Can edit or not
     * @return {boolean}
     */
    canEdit() {
      return this.$store.state.showAdvanced || !this.isEditMode;
    }

    // /**
    //  * @description Getter for existing meta
    //  * @description Used to check for unique type / name
    //  * @return {object}
    //  */
    // oldMeta() {
    //   return this.meta.id && this.metas
    //     ? this.metas.find(item => item.id === this.meta.id)
    //     : null;
    // }
  },
  methods: {
    /**
     * @description Handle meta title changed event
     * @description Set type for the meta
     * @return {void}
     */
    onMetaTitleChanged() {
      this.meta.type = toCamelCase(this.meta.title);
    },

    /**
     * @description Handle dialog open
     * @param existingMeta
     * @return {void}
     */
    onDialogOpen(existingMeta = null) {
      if (existingMeta) {
        this.meta = _.cloneDeep(existingMeta);
      }
      this.isDialogOpen = true;
    },

    /**
     * @description Handle dialog close
     * @return {void}
     */
    onDialogClose() {
      this.meta = _.cloneDeep(META);
      this.isDialogOpen = false;
    },

    /**
     * @description Handle adding new field to meta
     * @return {void}
     */
    onAddNewField() {
      switch (this.temporaryType) {
        case "number":
          this.meta.fields.push(_.cloneDeep(TYPE_NUMBER));
          break;

        case "datetime":
          this.meta.fields.push(_.cloneDeep(TYPE_DATETIME));
          break;

        case "list":
          this.meta.fields.push(_.cloneDeep(TYPE_LIST));
          break;

        case "array":
          this.meta.fields.push(_.cloneDeep(TYPE_ARRAY));
          break;

        case "string":
          this.meta.fields.push(_.cloneDeep(TYPE_STRING));
          break;

        case "currency":
          this.meta.fields.push(_.cloneDeep(TYPE_CURRENCY));
          break;

        default:
          return;
      }

      this.$nextTick(() => {
        this.temporaryType = null;
        this.addNewFieldPopover = false;
      });
    },

    /**
     * @description Handle on submit single meta
     * @description Validate form
     * @return {void}
     */
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid && !this.isBusy) {
        this.submit();
      }
    },

    /**
     * @description Form validated
     * @description Emit meta
     * @return {void}
     */
    submit() {
      this.isBusy = true;

      let formData = _.cloneDeep(this.meta);
      formData.metaData = formData.extras;

      // formData.fields.forEach(field => {
      //   if (_.has(field, "tableHeaders")) {
      //     for (let tableHeaderKey in field.tableHeaders) {
      //       console.log(tableHeaderKey);
      //     }
      //   }

      //   if (_.has(field, "tableAggregrations")) {
      //     for (let tableAggregationKey in field.tableAggregrations) {
      //       console.log(tableAggregationKey);
      //     }
      //   }
      // });

      formData.metaData.fields = _.keyBy(formData.fields, "stateRefKey");

      delete formData.extras;
      delete formData.fields;

      this.$store
        .dispatch("CREATE_OR_UPDATE_TRANSACTION_META", formData)
        .then(response => {
          if (this.isEditMode) {
            this.$emit(
              "onUpdated",
              response.data.transactionAPI.createOrUpdateTransactionMeta
            );
          } else {
            this.$emit(
              "onCreated",
              response.data.transactionAPI.createOrUpdateTransactionMeta
            );
          }
          this.onDialogClose();
        })
        .catch(error =>
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to ${
              this.isEditMode ? "update" : "create"
            } transaction meta data.`
          })
        )
        .finally(() => (this.isBusy = false));
    },

    /**
     * @description Handle before dialog close event.
     * @description To persist dialog even if user press outside dialog
     * @return {void}
     */
    beforeDialogClose(done) {
      // if (!this.isExporting) {
      //   done();
      // }
    }
  },

  components: {
    FormFields,
    FormExtra
  }
});
</script>

import {
  LivechatSessionType,
  LivechatStateVariables,
  UserDetailsPayload,
} from "@/store/modules/livechat/types";
import _ from "lodash";

interface rawMonitorChat {
  livechat_data: LivechatSessionType;
  agents: string[];
  RowKey: string;
  fullName?: string;
  alias?: string;
  mobileNo?: string;
  email?: string;
  source?: string;
  profile_pic?: string;
  session_history?: string;
  channel?: string;
  session_id?: string;
  last_modified?: string;
  date_created?: string;
}

export function parseMonitorChats(
  rawMonitorChats: rawMonitorChat[]
): LivechatSessionType[] {
  const monitorChatsArray = rawMonitorChats.map((chat) => {
    const agents = _.get(chat, "livechat_data.agents", chat.agents) || [];
    const existingStateVariables = _.get(chat, "stateVariables", {});
    const livechatStateVariable: LivechatStateVariables = _.pick(chat, [
      // TODO: get this to read from a livechat setting variable in a particular order
      "source",
      "profile_pic",
      "session_history",
      "channel",
    ]);
    const userDetails: UserDetailsPayload = _.get(
      chat,
      // TODO: get this to read from a livechat setting variable in a particular order
      "userDetails",
      {}
    );
    // handle facebook names
    const updatedStateVariables = Object.assign(
      {},
      existingStateVariables,
      livechatStateVariable
    );

    const updatedUserDetails = appendFacebookName(chat, userDetails);

    const parsedMonitorChat = Object.assign({}, chat.livechat_data, {
      RowKey: chat.RowKey,
      user_id: chat.RowKey,
      agents,
      stateVariables: updatedStateVariables,
      userDetails: updatedUserDetails,
      sessionId: chat.session_id,
      last_modified: chat.last_modified,
      date_created: chat.date_created,
    });
    return parsedMonitorChat;
  });

  return monitorChatsArray;
}

function appendFacebookName(
  chat: rawMonitorChat,
  userDetails: UserDetailsPayload
): UserDetailsPayload {
  const facebookFullName = _.get(chat, "name", "");
  if (facebookFullName && !userDetails.name) {
    return Object.assign({}, userDetails, {
      name: facebookFullName,
    });
  }
  return userDetails;
}

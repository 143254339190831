<script>
import { Radar } from "vue-chartjs";

export default {
  extends: "Radar",
  props: {
    datasets: {
      default() {
        return [];
      }
    },
    labels: {
      default() {
        return [];
      }
    },
    colors: {
      default() {
        return [];
      }
    }
  },
  computed: {
    dataWithColor() {
      let result = this.datasets;
      for (let i = 0; i < this.datasets.length(); i++) {
        result[i].backgroundColor = this.colors[i];
      }
      return result;
    }
  },
  mounted() {
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets
    });
  }
};
</script>

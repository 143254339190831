<template>
  <div v-if="type === 'PHONE_NUMBER'" class="phone-number-container">
    <span class="phone-number-image"></span>
    <span class="call-to-action-button">{{ previewText }}</span>
  </div>
  <div v-else-if="type === 'URL' && url" class="url-container">
    <a :href="url" target="_blank" class="url-anchor-container">
      <span class="url-image"></span>
      <span class="call-to-action-button">{{ previewText }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "WhatsAppMessageTemplateCallToAction",
  components: {},
  props: {
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return ["PHONE_NUMBER", "URL"].includes(value);
      }
    },
    previewText: {
      type: String,
      required: true,
      default: "No Preview Available"
    },
    url: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.phone-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  cursor: default;
}

.phone-number-image {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yO/r/8o77vvYFLgb.png);
  width: 16px;
  height: 16px;
  opacity: 1;
  color: #00a5f4;
  background-size: contain;
  white-space: pre-wrap;
}

.url-image {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y0/r/OzOCQC-ukqH.png);
  width: 16px;
  height: 16px;
  opacity: 1;
  color: #00a5f4;
  background-size: contain;
  white-space: pre-wrap;
}

.url-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}

.url-anchor-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.call-to-action-button {
  color: #00a5f4;
  padding: 0 16px;
  white-space: pre-wrap;
}
</style>
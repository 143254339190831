import _ from "lodash";

export default {
  computed: {
    translateFormMixin(): any {
      // @ts-ignore
      const formattedLanguages = _.map(this.languages, (lang) => ({
        value: lang.code,
        label: lang.name
      }));
      return {
        language_engine: {
          default: "cld",
          label: "Language Engine",
          type: "select",
          options: [
            { value: "cld", label: "CLD" },
            { value: "franc", label: "Franc" },
            { value: "fasttext", label: "FastText" },
          ],
          detail:
            "The language engine to use for detection, CLD only supports open-ended detection while Franc allows language restriction",
        },
        supported_languages: {
          default: ["en"],
          label: "Supported Languages",
          hint:
            "Comma separated ISO 639-1 language code (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes). eg. 'en,ms,id,tl'",
          type: "multiselect",
          // @ts-ignore
          options: this.languages,
        },
        unsupported_languages: {
          default: "",
          label: "Unsupported Languages",
          hint:
            "Comma separated ISO 639-1 language code. eg: 'ja,zh' https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
          type: "multiselect",
          // @ts-ignore
          options: this.languages,
        },
        fallback_language: {
          default: "en",
          label: "Fallback Language",
          type: "select",
          options: formattedLanguages,
          detail:
            "If detected language is not in supported language, will default to this language",
        },
        pinyin_detection: {
          type: "boolean",
          default: false,
          label: "Pinyin Detection",
          detail:
            "Attempts to detect pinyin and return language as Chinese if detected.",
        },
        katakana_hiragana_detection: {
          type: "boolean",
          default: false,
          label: "Katakana / Hiragana Detection",
          detail:
            "Attempts to detect Katakana / Hiragana and return language as Japanese if detected.",
        },
        language_mapping: {
          default: [],
          label: "Language Mapping",
          type: "cascader",
          options: _.map(formattedLanguages, languageObj => {
            const clone = _.cloneDeep(languageObj);
            // Check from a list of language objects and filter
            // non-matching ones as options
            const otherLanguages = _.filter(
              formattedLanguages,
              langObj => langObj.value !== clone.value
            );
            return {
              ...clone,
              children: [...otherLanguages],
            };
          }),
          props: { multiple: true },
          "collapse-tags": true,
          clearable: true,
          detail: `Maps Language B (leaf-node languages) to Language A (root-level) if Language B is detected.`,
        },
        key: {
          default: "",
          type: "string",
        },
        translate_responses: {
          default: false,
          type: "boolean",
        },
        stopWords: {
          default: "",
          type: "textarea",
          label: "Stop words",
          detail:
            "Words to ignore during language detect. Separate each words with pipe ( | ) symbol.",
        },
      };
    },
  },
};

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import Vue from "vue";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import _ from "lodash";
import { GraphQLError } from "graphql";
import { isLocalhost } from "@/helperMethods/util";
import { getTrackedNetworkStatus } from "@/helpers/networkStatusStorage";

/**
 * @description Init sentry package
 * @return {void}
 */
export function initSentry() {
  if (isLocalhost()) {
    return;
  }

  Sentry.init({
    dsn: "https://5186808e218b4da59e868263ccccff81@sentry.io/242275",
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        tracingOptions: { trackComponents: false },
      }),
      new TracingIntegrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.2, // when test on dev, can disable to by pass CORS with `sentry-trace` header
    release: window.DASHBOARD_RELEASE_ID,
    environment: process.env.NODE_ENV,
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb.category === "ui.click" ? null : breadcrumb;
    },
    beforeSend(event: Sentry.Event) {
      const exception = event.exception;
      // Check if it is an exception, if so, show the report dialog
      if (exception) {
        const message: any = _.get(exception, "values[0].value");

        // Only check for exception message as string,
        // if message is object (due to wrong error capturing), simply ignore the event
        if (typeof message !== "string") {
          return null;
        }

        const isGraphQLPermissionError = message.match(
          /GraphQL error: 401: User does not have permission/i
        );
        const isHttpPermissionError = message.match(/403/i);
        const is4XXError = isGraphQLPermissionError || isHttpPermissionError;
        const isNetworkError = message.match(
          /network error|timeout of 0ms exceeded|xhr post error|xhr poll error/i
        );

        if (is4XXError || isNetworkError) {
          return null;
        }
      }

      // attach network statuses
      const trackedNetworkStatuses = getTrackedNetworkStatus();
      if (trackedNetworkStatuses && trackedNetworkStatuses.length > 0) {
        event.breadcrumbs?.push({
          level: Sentry.Severity.Info,
          data: {
            trackedNetworkStatuses: JSON.stringify(
              trackedNetworkStatuses,
              null,
              2
            ),
          },
        });
      }
      return event;
    },
  } as any);

  Sentry.setTag("module", "dashboard");

  // @ts-ignore
  window.testSentry = () => {
    Sentry.captureException(
      new Error(`Test Sentry ${new Date().toISOString()}`)
    );
  };
}

export function captureGraphQLError(operation: any, err: GraphQLError) {
  Sentry.withScope((scope) => {
    const { message, locations, path, extensions } = err;
    const graphQLPath = Array.isArray(path) ? path.join(" > ") : "";

    if (graphQLPath) {
      scope.addBreadcrumb({
        category: "query-path",
        message: graphQLPath,
        level: Sentry.Severity.Debug,
      });
    }
    scope.setExtra("path", graphQLPath);
    scope.setExtra("variables", _.get(operation, "variables"));
    scope.setExtra("message", message);
    scope.setExtra("locations", locations);
    scope.setExtra("extensions", extensions);
    Sentry.captureException(
      new GraphQLError(`GraphQL Error: ${graphQLPath} "${message}"`)
    );
  });
}

export const logAxiosExceptionToSentry = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    Sentry.captureException(
      new Error(
        JSON.stringify({
          response: error.response.data,
          status: error.response.status,
          url: (error.response.config && error.response.config.url) || "",
        })
      )
    );
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser
    // - see: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
    // and an instance of http.ClientRequest in node.js
    Sentry.captureException(
      new Error(
        JSON.stringify({
          response: error.request.responseText || "",
          status: error.request.status,
          url: error.request.responseURL || "",
        })
      )
    );
  } else {
    // Something happened in setting up the request that triggered an Error
    Sentry.captureException(new Error(error.message));
  }
};

export function enrichSentryUserData(user: any) {
  if (!user) {
    Sentry.configureScope((scope) => scope.setUser(null));
  } else {
    Sentry.setUser({ email: user.email });
  }
}

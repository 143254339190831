<template>
  <el-row v-loading="fetchResolvedChatLoading">
    <!-- Empty chat message -->
    <el-row
      v-if="!resolvedChatsArray || resolvedChatsArray.length === 0"
      style="text-align: center; position: relative"
    >
      <h1>There are currently no resolved chats.</h1>
    </el-row>

    <el-row
      @click.native="selectChat(chat.RowKey)"
      v-for="(chat, index) in resolvedChatsArray"
      :key="index"
      style="margin-bottom: 4px"
    >
      <el-tooltip class="list-item" effect="dark" placement="right">
        <div slot="content" style="max-width: 400px">
          <ResolvedTooltip :chat="chat" />
        </div>

        <el-row
          class="list-item"
          :class="getChatClass(index, chat, selectedChatId)"
          style="margin-bottom: 0px"
        >
          <!-- Avatar -->
          <el-col
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            <ChatAvatar :chat="chat" style="display: inline-block" />
            <span style="margin: 2px 0 8px; font-size: 16px">
              {{ getChatName(chat) }}
            </span>
            <!-- <div style="color: #b9bcc1; font-size: 16px;">{{getPreview(chat.interactions)}}</div> -->
          </el-col>

          <!-- Agents -->
          <el-col
            v-if="chat.agents.length > 0"
            :span="24"
            style="
              font-size: 0.6em;
              color: #757575;
              margin-top: 5px;
              white-space: normal;
              font-weight: 400px;
            "
          >
            <i>Attended by {{ chat.agents.join(", ") }}</i>
          </el-col>
        </el-row>
      </el-tooltip>
    </el-row>
    <el-row>
      <div
        v-loading="showMoreIsLoading"
        element-loading-spinner="el-icon-loading"
        v-if="
          resolvedChatsArray && resolvedChatsArray.length > 0 && !hasNoMoreChats
        "
        class="prev-conversation"
        @click="$emit('fetchMoreResolvedChats')"
      >
        - show more -
      </div>
    </el-row>
  </el-row>
</template>

<script>
import ResolvedTooltip from "./Tooltip";
import ChatAvatar from "@/components/LiveChat/ChatAvatar";
import { mapGetters } from "vuex";
import moment from "moment";
import map from "lodash/map";
import get from "lodash/get";
import { getChatName } from "@/filters/index";

import {
  getDisplayId,
  getChatClass,
  getAvatar,
  getPreview,
  printDuration,
} from "@/helperMethods/livechat/util";

export default {
  props: {
    selectedIndex: Number,
    showMoreIsLoading: {},
    hasNoMoreChats: {},
  },
  components: {
    ResolvedTooltip,
    ChatAvatar,
  },
  data() {
    return {
      waitedTime: [],
      pageNo: 0,
      tab: "resolved",
    };
  },
  computed: {
    ...mapGetters([
      "fetchResolvedChatLoading",
      "resolvedChatsArray",
      "resolvedChatFilters",
      "selectedChatId",
    ]),
  },
  methods: {
    getAvatar,
    getDisplayId,
    getChatClass,
    getPreview,
    get,
    getChatName,

    selectChat(chatId) {
      this.$set(this.$store.state.livechat, "selectedChatId", chatId);
      this.$store.commit("SELECT_LIVE_CHAT", { chatId, type: "resolved" });
    },

    calculateWaitedTime() {
      this.waitedTime = map(this.resolvedChatsArray, (chat) => {
        return printDuration(chat.startQueueTime, moment().format());
      });
    },
  },
  mounted() {
    this.calculateWaitedTimeInterval = setInterval(() => {
      this.calculateWaitedTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.calculateWaitedTimeInterval);
  },
};
</script>

<style scoped>
.list-item {
  width: 100%;
  padding: 8px;
  border: 1px solid #e0e6ed;
  /* line-height: 32px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.active-chat {
  font-weight: 600;
  /* background: #f6f8f9; */
  background: #d0dfff;
  border: solid black 1px;
}
.joined-chat {
  /* background: rgb(255, 251, 212); */
  background: #f5f7fd;
}

.prev-conversation {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin: 0 auto 15px;
  font-size: 12px;
  cursor: pointer;
}

.list-item.appointment-chat {
  background: #fef0f0;
  border-color: #fbc4c4;
}
</style>

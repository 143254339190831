<template>
  <el-card style="position: relative">
    <br />
    <br />
    <template v-for="(suggestedAnswer, index) in suggestedAnswers">
      <el-row
        :gutter="10"
        :key="`${suggestedAnswer.id}`"
        style="margin-bottom: 5px"
      >
        <el-divider style="margin: 12px 0 0 0">
          Multiple Choice #{{ index + 1 }}
          <el-button
            circle
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click.prevent="deleteSuggestedAnswer(suggestedAnswer.id)"
          />
        </el-divider>
        <el-form :model="suggestedAnswer" label-width="140px">
          <el-form-item style="margin-bottom: 0px">
            <label slot="label" style="text-align: left">
              Conditions
              <a
                @click.prevent="$refs['question-conditions'][index].onAdd()"
                style="
                  font-size: 20px;
                  cursor: pointer;
                  color: #e4392b;
                  margin-left: 5px;
                "
                >+</a
              >
            </label>
            <QuestionConditions
              ref="question-conditions"
              :answer="suggestedAnswer"
            />
          </el-form-item>
          <el-form-item style="margin-bottom: 0px">
            <label slot="label" style="text-align: left">
              Button Text
              <el-tooltip
                effect="dark"
                content="Button's text"
                placement="right"
              >
                <i
                  class="el-icon-question"
                  style="margin-left: 0.5em; color: #909399"
                />
              </el-tooltip>
            </label>
            <el-input
              v-model="suggestedAnswer.optionValue"
              placeholder="Option"
              size="mini"
              style="margin-right: 5px"
            />
          </el-form-item>

          <el-form-item style="margin-bottom: 0px">
            <label slot="label" style="text-align: left">
              If selected
              <el-tooltip
                effect="dark"
                content="Which content node do you wish when button is clicked?"
                placement="right"
              >
                <i
                  class="el-icon-question"
                  style="margin-left: 0.5em; color: #909399"
                />
              </el-tooltip>
            </label>

            <div style="display: flex; align-items: center">
              <el-tooltip
                popper-class="previewer"
                placement="top"
                :content="
                  previewSuccessOption(suggestedAnswer.afterAction.success)
                "
              >
                <el-select
                  filterable
                  v-model="suggestedAnswer.afterAction.success"
                  style="width: 100%"
                  size="mini"
                >
                  <el-option-group
                    v-for="group in successOptions"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="`${item.value}-${getUuid()}`"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </el-tooltip>

              <el-button
                size="mini"
                type="text"
                icon="el-icon-d-arrow-right"
                @click.prevent="
                  $emit('navigate', suggestedAnswer.afterAction.success)
                "
                circle
              ></el-button>
            </div>
          </el-form-item>

          <el-form-item style="margin-bottom: 0px" v-if="$store.getters.isShowAdvanced">
            <label slot="label" style="text-align: left">
              Side Effect
              <el-tooltip
                effect="dark"
                content="Side effect event to execute when suggested answer is met. Cannot have 'goto' OR 'starflow'"
                placement="right"
              >
                <i class="el-icon-question" style="margin-left: 0.5em; color: #909399" />
              </el-tooltip>
            </label>

            <SideEffect :active-flow="activeFlow" :answer="suggestedAnswer" />
          </el-form-item>
        </el-form>
      </el-row>
    </template>

    <el-button
      plain
      size="mini"
      type="text"
      icon="el-icon-plus"
      @click.prevent="addSuggestedAnswer"
      >Add</el-button
    >
  </el-card>
</template>
<script>
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { previewSuccessOption } from "../Index";
import QuestionConditions from "../Conditions/Index";
import SideEffect from "../SideEffect/Index";

export default {
  props: ["question", "activeFlow", "successOptions"],
  components: { QuestionConditions, SideEffect },
  computed: {
    suggestedAnswers() {
      const tempAnswers = this.question.suggestedAnswers;
      if (Array.isArray(tempAnswers)) {
        return tempAnswers
          .filter((answer) => {
            return answer.answerType === "multipleChoice";
          })
          .map((answer) => {
            if (!answer.sideEffect) {
              answer.sideEffect = { data: "", event: "" };
            }
            return answer;
          })
          .map((answer) => {
            if (!Array.isArray(answer.conditions)) {
              this.$set(answer, "conditions", []);
            }
            return answer;
          })
          .map((answer) => {
            if (!Array.isArray(answer.validationMethods)) {
              this.$set(answer, "validationMethods", []);
            }
            return answer;
          });
      }
      return tempAnswers;
    },
  },
  methods: {
    previewSuccessOption,
    getUuid() {
      return uuid();
    },
    addSuggestedAnswer() {
      this.$store
        .dispatch("CREATE_NEW_SUGGESTED_ANSWER", {
          questionId: this.question.questionId,
          answerType: "multipleChoice",
          question: this.question,
        })
        .then((newSuggestedAnswer) => {
          if (newSuggestedAnswer) {
            this.question.suggestedAnswers.push(newSuggestedAnswer);
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error creating new suggested answer.",
          });
        });
    },
    deleteSuggestedAnswer(suggestedAnswerIndex) {
      this.question.suggestedAnswers.splice(suggestedAnswerIndex, 1);
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.previewer {
  width: 300px;
}
</style>

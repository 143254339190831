import _ from "lodash";
interface TabObject {
  title: string;
  url: string;
  style?: any;
}

export function tabSettingValidation(tabs: TabObject[]) {
  if (_.isEmpty(tabs)) {
    return true;
  }
  const validatedTabs: boolean[] = _.map(tabs, (tab: TabObject) => {
    return !!(tab.title && tab.url);
  });
  if (validatedTabs.includes(false)) {
    return false;
  } else {
    return true;
  }
}

<template>
  <el-card>
    <el-form :model="question" label-width="140px">
      <el-form-item style="margin-bottom:0;">
        <label slot="label" style="text-align:left;">
          If fail
          <el-tooltip
            effect="dark"
            content="What is the content to show if all suggested answers fail"
            placement="right"
          >
            <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
          </el-tooltip>
        </label>
        <el-select
          v-model="question.afterAction.fail"
          filterable
          placeholder="Select"
          size="mini"
          style="width:100%;"
        >
          <el-option
            v-for="(option, index) in textFailOptions"
            :key="index"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <!-- If selected fail action is 'try_again' -->
      <el-form-item v-if="question.afterAction.fail === 'try_again'" style="margin-bottom:0;">
        <label slot="label" style="text-align:left;">
          Fail content
          <el-tooltip
            effect="dark"
            content="Content to re-ask user for this question"
            placement="right"
          >
            <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
          </el-tooltip>
        </label>
        <el-input
          v-model="question.afterAction.failText"
          type="textarea"
          spellcheck
          :autosize="{ minRows: 3, maxRows: 6 }"
        />
      </el-form-item>

      <el-form-item style="margin-bottom:0;">
        <label slot="label" style="text-align:left;">
          If success
          <el-tooltip effect="dark" content="Next action if success" placement="right">
            <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
          </el-tooltip>
        </label>
        <el-select
          size="mini"
          filterable
          v-model="question.afterAction.success"
          placeholder="Select"
          style="width:100%;"
        >
          <el-option
            v-for="(option, index) in textSuccessOptions"
            :key="index"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-if="successComponent" style="margin-bottom:0;margin-top:5px;">
        <label slot="label" style="text-align:left;">Success content</label>
        <component
          :text-success-options="textSuccessOptions"
          :question="question"
          :is="successComponent"
        ></component>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import _ from "lodash";
import ActionSuccessCallApi from "./ActionSuccessCallApi";
import ActionSuccessEscalatedEmail from "./ActionSuccessEscalateEmail";
import { TEXT_FAIL_OPTIONS, TEXT_SUCCESS_OPTIONS } from "../Index";

export default {
  props: ["question"],
  components: {
    ActionSuccessCallApi,
    ActionSuccessEscalatedEmail
  },
  data() {
    return {
      textFailOptions: TEXT_FAIL_OPTIONS,
      textSuccessOptions: TEXT_SUCCESS_OPTIONS
    };
  },
  computed: {
    successComponent() {
      const successAction = _.get(this.question, "afterAction.success", null);
      let component = null;
      switch (successAction) {
        case "call_api":
          component = "ActionSuccessCallApi";
          break;

        case "escalate_email":
          component = "ActionSuccessEscalatedEmail";
          break;

        case "escalate_email_after_work_hours":
          component = "ActionSuccessEscalatedEmail";
          break;

        default:
        //
      }
      return component;
    }
  }
};
</script>

<template>
  <div>
    <el-input style="width: 100%;" size="mini" :value="current" disabled>
      <template slot="prepend">Name</template>
    </el-input>

    <el-select
      @change="onChange"
      v-model="selected"
      style="width: 100%;"
      multiple
      filterable
      allow-create
      size="mini"
      placeholder="Add more item"
      :disabled="!canEdit"
    >
      <el-option
        v-for="item in meta.extras[current]"
        :key="item"
        :label="item"
        :value="item"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      selected: []
    };
  },
  props: ["meta", "current", "canEdit"],
  methods: {
    /**
     * @description On extras selected.
     * @return {void}
     */
    onChange() {
      this.meta.extras[this.current] = this.selected;
      this.$forceUpdate();
    }
  },
  mounted() {
    this.selected = _.cloneDeep(this.extras);
  }
});
</script>

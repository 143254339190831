<template>
  <div>
    <el-input v-model="value[name]" style="width: 80%; max-width: 680px; margin-bottom:1em">
      <div slot="prepend" style="width: 120px;">
        {{ setting.label || name }}
        <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="left">
          <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
        </el-tooltip>
      </div>
    </el-input>
    <el-color-picker v-model="value[name]" style="position:relative;vertical-align: top;" />
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
import _ from "lodash";
import store from "@/store";
import { IStandardFlow } from "../editorType";
import { ContentNode } from "../type";

interface FlowLabelObject {
  value: string;
  label: string;
}

export function flowSectionsTitle(flows: {
  [flowId: string]: IStandardFlow;
}): FlowLabelObject[] {
  const allFlows = Object.values(flows);
  const temp = _.chain(allFlows)
    .filter(notRouterFlow)
    .map(getFlowSections)
    .flattenDeep()
    .map(getSectionTitle)
    .value() as unknown; // lodash .value returns a boolean[]

  return temp as FlowLabelObject[];
}

const notRouterFlow = ({ title }: { title: string }) => {
  return title.toLowerCase() !== "router";
};
const getFlowSections = (flow: IStandardFlow) => {
  return flow.sections;
};
const getSectionTitle = ({
  sectionId,
  title,
  flowId,
}: {
  sectionId: string;
  title: string;
  flowId: string;
}) => {
  const flow = _.get(store, `state.floweditor.flows[${flowId}]`, {});
  return {
    value: sectionId,
    label: `${title} (${flow.title})`,
  };
};
export interface ContentNodeObject {
  id: string;
  payload: ContentNode;
  isDelete?: boolean;
  author?: string;
}
export function updateContentNodeObject(
  originalContentNodes: { [nodeId: string]: ContentNode },
  contentNodeObjects: ContentNodeObject[]
) {
  let updatedContentNodes = _.cloneDeep(originalContentNodes);
  contentNodeObjects.forEach((contentNodeObject) => {
    const id = _.get(contentNodeObject, "id");
    const contentNode = _.get(contentNodeObject, "payload");
    const isDelete = _.get(contentNodeObject, "isDelete", false);

    let deleted = false;
    let isUpdated = false;

    if (isDelete) {
      delete updatedContentNodes[id];
      deleted = true;
    } else {
      updatedContentNodes = Object.assign({}, updatedContentNodes, {
        [id]: contentNode,
      });
      isUpdated = true;
    }

    // FIXME: console logs to be removed once dev is completed
    console.log("isUpdated: ", isUpdated);
    console.log("deleted: ", deleted);
  });

  return { updatedContentNodes };
}

export function formatFlowSection<T>(
  labelArray: {
    value: string;
    label: T;
  }[]
): {
  value: string;
  label: T & "_1";
}[] {
  let formattedArray: any[] = [];
  if (Array.isArray(labelArray)) {
    formattedArray = labelArray.map((label) => {
      const formattedLabel = Object.assign({}, label, {
        value: `${label.value}_1`,
      });
      return formattedLabel;
    });
  }

  return formattedArray as {
    value: string;
    label: T & "_1";
  }[];
}

export function doesLinkExist(next: string): boolean {
  const contentExists = doesContentExist(next);
  const eventExists = doesEventExist(next);
  return contentExists || eventExists;
}

export function doesContentExist(contentId: string) {
  const contentExists = !!store.state.nodes.content[contentId];
  return contentExists;
}

export function doesEventExist(eventId: string) {
  const eventExists = !!store.state.nodes.event.includes(eventId);
  return eventExists;
}

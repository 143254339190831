<template>
  <el-drawer
    title="Download Flow Results"
    :visible.sync="isDrawerOpened"
    direction="rtl"
    ref="download-drawer"
    id="download-drawer"
    :before-close="handleClose"
    size="50%"
    @close="$emit('close')"
  >
    <div style="padding:0 20px">
      <el-form label-width="120px">
        <el-form-item label="Selected flows">
          <el-select
            v-model="flowIds"
            style="width: 100%"
            clearable
            multiple
            filterable
            placeholder="Select Flow; Keep blank to select all"
          >
            <el-option
              v-for="(flow, index) in arrayFlows"
              :key="index"
              :label="flow.title"
              :value="flow.flowId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Time period">
          <el-col :span="11">
            <el-date-picker
              v-model="startEndDates"
              type="daterange"
              format="dd/MM/yyyy"
              style="margin-right: 10px; min-width: 300px;"
              align="right"
              unlink-panels
              range-separator=" ~ "
              start-placeholder="Start date"
              size="mini"
              end-placeholder="End date"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="isBusy"
            :disabled="$store.getters.isFetchingFlows || isBusy || parentIsBusy"
            type="primary"
            icon="el-icon-download"
            @click="downloadFlowsResults"
          >Download</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
import XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";
import { defaultPickerOptions } from "@/helperMethods/util";

export default {
  name: "DownloadFlowDrawer",
  props: ["showDrawer", "arrayFlows", "parentIsBusy"],
  data() {
    return {
      isBusy: false,
      isDrawerOpened: false,
      startEndDates: [new Date(), new Date()],
      flowIds: [],
      direction: "rtl",
      pickerOptions: defaultPickerOptions
    };
  },
  mounted() {
    this.$refs["download-drawer"].close();
  },
  watch: {
    showDrawer: {
      handler(newValue, oldValue) {
        console.log(oldValue);
        console.log(newValue);

        this.isDrawerOpened = !!newValue;
      }
    }
  },
  methods: {
    handleClose(done) {
      this.isDrawerOpened = false;
    },

    async downloadFlowsResults() {
      this.isBusy = true;
      let doneFetching = false;
      let aggregatedFlowResults = [];

      const startDate = this.startEndDates[0];
      const endDate = this.startEndDates[1];
      let limit = 40;
      let offset = 0;
      const flowIds = this.flowIds;

      while (!doneFetching) {
        try {
          const flowResults = await this.$store.dispatch(
            "FETCH_ALL_FLOWS_RESULTS",
            {
              startDate,
              endDate,
              limit,
              offset,
              flowIds
            }
          );

          aggregatedFlowResults = aggregatedFlowResults.concat(flowResults);

          const fetchCompleted = flowResults.length < limit;
          if (!fetchCompleted) {
            offset += limit;
          } else {
            doneFetching = true;
            // Get out of while loop

            const wb = XLSX.utils.book_new();
            aggregatedFlowResults.forEach((flowResult, flowIndex) => {
              const { title, headers, userData } = flowResult;

              const ws_data = [];
              ws_data.push([`Flow Title: ${title}`], []);
              ws_data.push(["User", "Timestamp", ...headers]);

              Object.keys(userData).map(user_row_id => {
                const user = userData[user_row_id];
                const { date_created } = user;
                const currentRow = [];
                currentRow.push(`User #${user_row_id.substring(0, 25)}`);
                currentRow.push(moment(date_created).format("LLL"));
                headers.forEach(field => {
                  currentRow.push(user[field]);
                });
                ws_data.push(currentRow);
              });

              ws_data.push([], [], [`---- End of results for ${title}----`]);

              const ws = XLSX.utils.aoa_to_sheet(ws_data);
              const sheetTitle = `#${flowIndex + 1}. ${title}`;
              let trancatedSheetTitle = sheetTitle;
              if (trancatedSheetTitle.length > 31) {
                trancatedSheetTitle = trancatedSheetTitle.substring(0, 31);
              }
              XLSX.utils.book_append_sheet(wb, ws, trancatedSheetTitle);
            });

            const filename = this.$exportFilename("form_results", "xlsx");
            XLSX.writeFile(wb, filename, {});
          }
        } catch (err) {
          console.error(err);

          this.$notify({
            type: "error",
            message: "Encountered error fetching flow results.",
            title: "Error",
            position: "bottom-right"
          });
        } finally {
          doneFetching = true;
          this.isBusy = false;
        }
      }
    }
  }
};
</script>


<style scoped>
#download-drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
</style>
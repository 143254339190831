<template>
  <div>
    <el-form :model="value" :rules="rules">
      <div v-if="value.event">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item @click.native="value.event = null">Actions</el-breadcrumb-item>
          <el-breadcrumb-item>{{ value.event }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form-item prop="event">
          <el-autocomplete
            v-model="value.event"
            class="autocomplete-list"
            :fetch-suggestions="getEventNodes"
            :disabled="
              [
                'goto',
                'capture',
                'startflow',
                'callApi',
                '$display',
                'handover_router',
                'set',
              ].includes(value.event)
            "
          >
            <div slot="prepend" style="width:40px;">Action</div>
          </el-autocomplete>
        </el-form-item>

        <component
          v-if="eventComponentIsValid"
          :value="value"
          :is="eventComponent"
          :previous-node="previousNode"
        ></component>
      </div>

      <div v-else style="text-align: center;">
        <template v-for="(eventNodeType, index) in eventNodeTypes">
          <el-button
            :key="`event-node-selector-button-${index}`"
            size="medium"
            style="width: 200px; margin-bottom: 4px;"
            round
            @click="setType(eventNodeType.name)"
          >{{ eventNodeType.label }}</el-button>
          <br :key="`event-node-selector-break-${index}`" />
        </template>
      </div>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import Vue from "vue";
import EventNodeSelectorNext from "./Next.vue";
import { doesContentExist } from "@/helperMethods/editor";
import { EVENT_NODE_TYPES } from "./Types/Index";
import TypeComponentCallapi from "./Types/CallApi";
import TypeComponentCapture from "./Types/Capture";
import TypeComponentGoto from "./Types/Goto";
import TypeComponentDisplay from "./Types/Display";
import TypeComponentTab from "./Types/Tab";
import TypeComponentStartflow from "./Types/Startflow";
import TypeComponentCustomevent from "./Types/CustomEvent";
import TypeComponentSet from "./Types/Set";

export default {
  components: {
    EventNodeSelectorNext,
    TypeComponentCallapi,
    TypeComponentCapture,
    TypeComponentGoto,
    TypeComponentDisplay,
    TypeComponentTab,
    TypeComponentStartflow,
    TypeComponentCustomevent,
    TypeComponentSet,
  },
  name: "EventNodeSelector",
  props: {
    value: {
      event: "",
      data: {},
      side_effects: Array
    },
    previousNode: {}
  },
  data() {
    const isLinkNotFound = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Field is required"));
      }

      if (typeof value === "string") {
        if (this.value.event == "goto") {
          if (!this.$store.state.nodes.content[value]) {
            return callback(new Error("Please enter a valid content node"));
          }
        } else if (this.value.event == "callApi") {
          if (!this.searchApiMappings(value)) {
            return callback(new Error("Please enter a valid content node"));
          }
        }
      }

      return callback();
    };
    return {
      availableEvents: [
        "$display",
        "$tab",
        "callApi",
        "capture",
        "goto",
        "startflow"
      ],
      eventNodeTypes: EVENT_NODE_TYPES,
      rules: {
        event: [
          {
            required: true,
            trigger: "blur",
            message: "Event is required"
          }
        ],
        data: [
          {
            validator: isLinkNotFound,
            trigger: "change"
          }
        ]
      }
    };
  },
  computed: {
    /**
     * @description Check if component is valid / imported before render
     * @return {boolean}
     */
    eventComponentIsValid() {
      const isExist = _.has(this.$options.components, this.eventComponent);
      return isExist;
    },

    /**
     * @description Computed EventType component for rendering
     * @return {string}
     */
    eventComponent() {
      let _name = this.value.event;

      if (_name === "$display") {
        _name = "Display";
      } else if (_name === "$tab") {
        _name = "Tab";
      } else if (_name === "set") {
        _name = "Set";
      } else if (!this.availableEvents.includes(_name)) {
        _name = "CustomEvent";
      }

      const _capitalize = _.capitalize(_name);
      const _componentName = `TypeComponent${_capitalize}`;
      return _componentName;
    }
  },
  mounted() {
    if (this.value.data && typeof this.value.data.next === "string") {
      this.value.data.next = {
        event: "goto",
        data: this.value.data.next
      };
    }
  },
  methods: {
    /**
     * @description Search available api mappings
     * @param {string} value
     * @return {[]}
     */
    searchApiMappings(value) {
      return this.$store.state.search.api_mappings.search(value);
    },

    /**
     * @description
     */
    setType(type) {
      switch (type) {
        case "callApi":
          this.$set(this.value, "event", "callApi");
          break;
        case "$display":
          this.$set(this.value, "event", "$display");
          break;
        case "goto":
          this.$set(this.value, "event", "goto");
          this.$set(this.value, "data", "conversation_start");
          break;
        case "capture":
          this.$set(this.value, "event", "capture");
          this.$set(this.value, "data", {});
          break;
        case "set":
          this.$set(this.value, "event", "set");
          this.$set(this.value, "data", {});
          break;
        case "escalate":
          this.$set(this.value, "event", "handover_router");
          this.$set(this.value, "data", "");
          break;
        case "custom event":
          this.$set(this.value, "event", "custom event");
          this.$set(this.value, "data", "custom value");
          break;
        case "startflow":
          this.$set(this.value, "event", "startflow");
          this.$set(this.value, "data", {
            id: "",
            value: ""
          });
          break;
        default:
      }
    },

    getEventNodes(value, cb) {
      if (value) {
        cb(this.$store.state.search.event.search(value));
      }
    }
  }
};
</script>


<style>
.autocomplete-list {
  margin-top: 15px;
  width: 100%;
}
.el-scrollbar ul {
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
}
</style>
<template>
  <el-row
    type="flex"
    justify="flex-start"
    style="margin-bottom: 0; flex-wrap: wrap; align-items: flex-start"
  >
    <el-input
      v-model="searchTerm"
      placeholder="Input search term..."
      size="mini"
      style="margin-right: 10px; margin-bottom: 10px; max-width: 300px"
    >
      <template slot="append">
        <el-popover
          placement="bottom"
          title="Filter"
          trigger="click"
          width="300"
        >
          <div>
            <!-- <el-select
              size="mini"
              v-model="searchTags"
              multiple
              placeholder="Filter by tag name"
              style="width:100%; margin-bottom: 10px;"
            >
              <el-option
                v-for="(tag, index) in monitorChatTags"
                :key="index"
                :label="tag"
                :value="tag"
              />
            </el-select>-->

            <el-select
              v-model="searchChatSources"
              placeholder="Filter by chat source"
              multiple
              filterable
              style="width: 100%; margin-bottom: 10px"
              size="mini"
            >
              <el-option
                v-for="source in chatSources"
                :key="source.value"
                :label="source.displayName"
                :value="source.value"
              ></el-option>
            </el-select>

            <el-select
              filterable
              clearable
              v-model="searchContextHistory"
              size="mini"
              placeholder="Filter by content node"
              style="width: 100%"
            >
              <el-option
                v-for="(contentNode, index) in contentNodeNames"
                :key="index"
                :label="contentNode"
                :value="contentNode"
              />
            </el-select>

            <PredefinedFilter
              type="monitorChat"
              @selectedFilter="onPredefinedFilterSelected"
            />
          </div>
          <el-button
            :disabled="fetchMonitorChatLoading"
            slot="reference"
            size="mini"
            icon="el-icon-circle-plus-outline"
          >
            Filter
          </el-button>
        </el-popover>
      </template>
    </el-input>

    <el-date-picker
      v-model="searchChatDateRange"
      type="datetimerange"
      format="dd/MM/yyyy HH:mm"
      style="margin-right: 10px; width: 300px; margin-bottom: 10px"
      align="right"
      unlink-panels
      range-separator=" ~ "
      start-placeholder="Start date"
      size="mini"
      end-placeholder="End date"
      :clearable="false"
    ></el-date-picker>

    <el-button
      :disabled="fetchMonitorChatLoading"
      type="primary"
      plain
      size="mini"
      icon="el-icon-search"
      @click="onSearchHandler"
      >Search</el-button
    >

    <el-button
      :disabled="fetchMonitorChatLoading"
      icon="el-icon-close"
      size="mini"
      @click="onResetHandler"
      >Clear</el-button
    >

    <el-button
      :disabled="fetchMonitorChatLoading"
      :loading="fetchMonitorChatLoading"
      type="primary"
      size="mini"
      icon="el-icon-refresh"
      style="margin-right: 10px"
      @click="refreshData"
      >Refresh Data</el-button
    >

    <el-dropdown
      size="mini"
      :disabled="fetchMonitorChatLoading || sendEmailLoading"
    >
      <el-button type="info" plain size="mini">
        More
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="emailChat">
          <i class="el-icon-s-promotion"></i>&nbsp;Email Chat Transcript
        </el-dropdown-item>
        <el-dropdown-item @click.native="exportDialogVisible = true">
          <i class="el-icon-download"></i>&nbsp;Download All Chats
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      id="livechat_downloadAllChats"
      title="Download All Chats"
      :visible.sync="exportDialogVisible"
      :before-close="beforeExportDialogClose"
      width="50%"
    >
      <div
        element-loading-text="Exporting chats, it might take awhile..."
        v-loading="isExporting"
      >
        <div width="100%">
          <span>Please select how you would like to export the chats?</span>
        </div>
        <br />
        <div v-if="exportChoice === 'zip'">
          <img src="/template/zipsample1.gif" width="100%" />
          <span>
            This export type will seperate each conversation into one .csv file,
            combining all of them into one compressed .zip file
          </span>
        </div>
        <div v-if="exportChoice === 'single'">
          <img src="/template/singlesample1.gif" width="100%" />
          <span>
            This export type will separate each conversation into different
            sheets, combining all of them into one .xlsx file
          </span>
        </div>
        <div width="100%">
          <el-radio v-model="exportChoice" label="zip"
            >A .zip file (multiple .csv)</el-radio
          >
          <el-radio v-model="exportChoice" label="single"
            >An .xlsx file (multiple sheets)</el-radio
          >
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            :disabled="isExporting"
            type="primary"
            @click="exportAllChats(exportChoice)"
            >Confirm</el-button
          >
          <el-button
            :disabled="isExporting"
            @click="exportDialogVisible = false"
            >Cancel</el-button
          >
        </span>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import { v4 as uuid } from "uuid";
import _ from "lodash";

import { mapGetters } from "vuex";
import {
  isDateRangeValid,
  getDefaultDateRange,
  showDateRangeError,
} from "@/components/LiveChat/helpers/filters";
import PredefinedFilter from "@/components/LiveChat/PredefinedFilter";
import { livechatExportMixin } from "@/mixins/liveChat";

export default {
  components: { PredefinedFilter },
  props: ["selectedChat"],
  mixins: [livechatExportMixin],
  data() {
    return {
      chatStatus: "all",
      isSearching: false,
      searchTerm: "",
      searchChatSources: [],
      searchTags: [],
      searchContextHistory: "",
      searchChatLimit: 20,
      searchChatDate: new Date(),
      searchChatDateRange: [...this.getDefaultDateRange()],
      exportDialogVisible: false,
      exportChoice: "zip",
      isExporting: false,
    };
  },
  computed: {
    ...mapGetters([
      "dataCollectionFormEnabled",
      "monitorChatFilters",
      "fetchMonitorChatLoading",
      "monitorChatTags",
      "monitorChatsArray",
      "selectedChatId",
      "sendEmailLoading",
      "contentNodeNames",
    ]),
  },

  methods: {
    getDefaultDateRange,
    isDateRangeValid,
    refreshData() {
      this.onSearchHandler();
    },
    emailChat() {
      this.$set(this.$store.state.livechat, "sendEmailLoading", true);
      this.sendingEmail = true;
      const chat = this.selectedChat;
      const livechatData = _.get(this.selectedChat, "livechat_data");
      const agent_email = this.$store.state.profile.email;
      if (livechatData) {
        this.$store
          .dispatch("EMAIL_CHAT_TRANSCRIPT", {
            userId: chat.RowKey,
            partitionKey: livechatData.PartitionKey,
            channel: chat.channel,
            agentEmail: agent_email,
          })
          .then((response) => {
            this.$set(this.$store.state.livechat, "sendEmailLoading", false);
            const message = response.message;
            if (response.type === "info") {
              this.$notify.info({
                title: "Info",
                message: message,
              });
            } else if (response.type === "error") {
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: message,
              });
            } else {
              this.$notify.success({
                title: "Success",
                message: response.message,
                position: "bottom-right",
              });
            }
          })
          .catch((error) => {
            this.$set(this.$store.state.livechat, "sendEmailLoading", false);
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Error sending email.",
            });
          });
      }
    },
    onSearchHandler(e) {
      this.$emit("actionTriggered");

      const monitorChatFilter = this.getSearchFilters();

      if (!this.isDateRangeValid(monitorChatFilter, 7)) {
        showDateRangeError();
        return;
      }

      this.$emit("setHasNoMoreChats", false);
      this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY"); // remove array of chats from state
      this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", true); // apollo.then will set to false when fetch is complete
      this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", monitorChatFilter);
    },
    onResetHandler(e) {
      this.$emit("actionTriggered");

      this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", true); // apollo.then will set to false when fetch is complete
      this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY"); // remove array of chats from state
      this.resetFilters();
      const monitorChatFilter = this.getSearchFilters();
      this.$emit("setHasNoMoreChats", false);
      this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", monitorChatFilter);
    },
    getSearchFilters() {
      return {
        limit: parseInt(this.searchChatLimit),
        offset: 0,
        randomNumber: uuid(),

        date: this.searchChatDate,
        dates: [this.searchChatDateRange?.[0], this.searchChatDateRange?.[1]],
        startDate: this.searchChatDateRange?.[0],
        endDate: this.searchChatDateRange?.[1],

        searchTerm: this.searchTerm,
        chatSources: this.searchChatSources,
        contextHistory: this.searchContextHistory,
        tags: this.searchTags,
        messageLimit: 15,
      };
    },
    resetFilters() {
      this.searchChatDate = new Date();
      this.searchChatDateRange = this.getDefaultDateRange();
      this.searchChatLimit = 20;
      this.searchTerm = "";
      this.searchChatSources = [];
      this.searchTags = [];
      this.searchContextHistory = "";
    },
    beforeExportDialogClose(done) {
      if (!this.isExporting) {
        done();
      }
    },
    exportAllChatsFetch(limit, offset) {
      this.$store.dispatch("FETCH_ALL_INTERACTIONS", {
        date: this.getSearchFilters()["date"],
        dates: this.getSearchFilters()["dates"],
        limit,
        offset,
        messageLimit: 200,
      });
    },
    onPredefinedFilterSelected(filter) {
      if (filter.startDate && filter.endDate) {
        this.searchChatDateRange = [filter.startDate, filter.endDate];
      }
      this.searchTerm = filter?.searchTerm || "";
      this.searchContextHistory = filter?.contextHistory || "";
      this.searchChatSources = filter?.chatSources?.split(",") || [];
      this.onSearchHandler();
    },
  },
};
</script>

<style scoped>
.filterGroup .el-select {
  width: 100%;
}
.downloadChats {
  text-align: right;
  padding: 5px 5px;
}
</style>

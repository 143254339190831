<template>
  <div style="padding: 0 30px">
    <span slot="title">
      <i class="el-icon-question" style="color:#eb9e05;position:relative;right:5px;"></i>
      <label style="position:relative; font-weight: bold;">Bot Creation Wizard</label>
    </span>
    <el-container>
      <el-aside width="20%">
        <el-row type="flex" justify="center">
          <WizardStep
            :steps="lastStepDisabled ? 5 : 6"
            :active="activeStep"
            @changeStep="changeStep"
          />
        </el-row>
      </el-aside>
      <el-container class="wizard-content">
        <el-main>
          <el-row
            style="height: 90%;"
            v-if="activeStep === 1"
            type="flex"
            justify="center"
            align="middle"
          >
            <el-col>
              <el-image style="height: 200px" :src="require('./assets/welcome.png')" fit="contain"></el-image>
              <el-row type="flex" justify="center">
                <h2>Hello!</h2>
              </el-row>
              <el-row type="flex" justify="center">
                <h2>How do you name me?</h2>
              </el-row>
              <el-row type="flex" justify="center">
                <el-form ref="form" :model="form">
                  <el-form-item>
                    <el-input
                      @focus="
                        handleDefault(
                          'botName',
                          'SampleBot'
                        )
                      "
                      placeholder="SampleBot"
                      v-model="form.botName"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            style="height: 90%;"
            v-if="activeStep === 2"
            type="flex"
            justify="center"
            align="middle"
          >
            <el-col>
              <el-row type="flex" justify="center">
                <h2>How do you want to start?</h2>
              </el-row>
              <el-row :gutter="20" type="flex" justify="center">
                <el-col :span="6">
                  <el-card class="card" :class="starterChoice === 1 && 'border'" shadow="hover">
                    <div @click="setChoice(1)">
                      <el-row type="flex" justify="center">
                        <el-image
                          style="height: 100px"
                          :src="require('./assets/create.png')"
                          fit="contain"
                        ></el-image>
                      </el-row>
                      <el-row type="flex" justify="center">Create New</el-row>
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card
                    class="card"
                    :class="starterChoice === 2 && 'border'"
                    @click="setChoice(2)"
                    shadow="hover"
                  >
                    <div @click="setChoice(2)">
                      <el-row type="flex" justify="center">
                        <el-image
                          style="height: 100px"
                          :src="require('./assets/template.png')"
                          fit="contain"
                        ></el-image>
                      </el-row>
                      <el-row type="flex" justify="center">Choose a Template</el-row>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            style="height: 90%;"
            v-if="activeStep === 3"
            type="flex"
            justify="center"
            align="middle"
          >
            <el-col v-if="starterChoice !== 0" :span="12">
              <el-row v-if="starterChoice === 1">
                <el-image
                  style="height: 200px"
                  :src="require('./assets/scretch.png')"
                  fit="contain"
                ></el-image>
                <el-form ref="form" :model="form">
                  <el-form-item label="How will I start the conversation?">
                    <el-input
                      type="textarea"
                      @focus="
                        handleDefault(
                          'conversationStart',
                          'Hi! I am Sample Bot, how can I help you?'
                        )
                      "
                      placeholder="Hi! I am Sample Bot, how can I help you?"
                      v-model="form.conversationStart"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </el-row>

              <el-row v-if="starterChoice === 2">
                <el-col>
                  <el-row type="flex" justify="center">
                    <h2>Which template do you choose to start?</h2>
                  </el-row>
                  <el-row type="flex" justify="center">
                    <el-col :span="18">
                      <el-card
                        class="card"
                        :class="
                          form.templateChoice === 'healthcare' && 'border'
                        "
                        shadow="hover"
                      >
                        <div @click="setTemplateChoice('healthcare')">
                          <el-row type="flex" justify="center">Healthcare</el-row>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="center">
                    <el-col :span="18">
                      <el-card
                        class="card"
                        :class="
                          form.templateChoice === 'publicService' && 'border'
                        "
                        shadow="hover"
                      >
                        <div @click="setTemplateChoice('publicService')">
                          <el-row type="flex" justify="center">Public Service</el-row>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="center">
                    <el-col :span="18">
                      <el-card
                        class="card"
                        :class="form.templateChoice === 'insurance' && 'border'"
                        shadow="hover"
                      >
                        <div @click="setTemplateChoice('insurance')">
                          <el-row type="flex" justify="center">Insurance</el-row>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="center">
                    <el-col :span="18">
                      <el-card
                        class="card"
                        :class="form.templateChoice === 'bank' && 'border'"
                        shadow="hover"
                      >
                        <div @click="setTemplateChoice('bank')">
                          <el-row type="flex" justify="center">Bank</el-row>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            style="height: 90%;"
            v-if="activeStep === 4"
            type="flex"
            justify="center"
            align="top"
          >
            <el-col>
              <el-row type="flex" justify="center">
                <h2>Do you want to activate live agent?</h2>
              </el-row>
              <el-row type="flex" justify="center">
                <el-form ref="form" :model="form">
                  <el-form-item>
                    <el-switch
                      @change="resetLiveAgentHandover"
                      v-model="form.handover"
                      active-text="Yes"
                      inactive-text="No"
                    ></el-switch>
                  </el-form-item>
                </el-form>
              </el-row>

              <el-row v-if="form.handover" type="flex" justify="center">
                <h2>Do you want to use Keyreply livechat?</h2>
              </el-row>
              <el-row v-if="form.handover" type="flex" justify="center">
                <el-form ref="form" :model="form">
                  <el-form-item>
                    <el-switch v-model="form.livechat" active-text="Yes" inactive-text="No"></el-switch>
                  </el-form-item>
                </el-form>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            style="height: 90%;"
            v-if="activeStep === 5"
            type="flex"
            justify="center"
            align="middle"
            :gutter="30"
          >
            <el-col>
              <el-row type="flex" justify="center">
                <h2>What platforms are you interested to activate?</h2>
              </el-row>
              <el-row type="flex" justify="flex-start">
                <el-col :span="12">
                  <el-image
                    style="height: 200px"
                    :src="require('./assets/integration.png')"
                    fit="contain"
                  ></el-image>
                </el-col>
                <el-col :span="8">
                  <el-row
                    v-for="(platform, module_id) in listOfPlatforms"
                    :key="module_id"
                    type="flex"
                    justify="space-between"
                  >
                    <span>{{module_id.toUpperCase().replace("_", " ")}}</span>
                    <el-switch v-model="modules[module_id].enabled"></el-switch>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            style="height: 90%;"
            v-if="activeStep === 6"
            type="flex"
            justify="center"
            align="top"
          >
            <el-col>
              <h2>Configure your platforms</h2>
              <el-tabs v-model="tabActive">
                <el-tab-pane
                  v-for="(platform, module_id) in listOfPlatforms"
                  :key="module_id"
                  :name="module_id"
                >
                  <div slot="label">
                    <span
                      style="line-height: 9px; vertical-align: middle;margin-right: 10px;"
                    >{{ module_id.toUpperCase().replace("_", " ") }}</span>
                  </div>
                  <el-col v-if="modules[module_id] && modules[module_id].enabled">
                    <div
                      v-for="(setting, key) in platform"
                      :key="key"
                      :required="setting.required"
                      style="margin:8px;"
                    >
                      <component
                        v-model="modules[module_id]"
                        :name="key"
                        :setting="setting"
                        :is="toComponent(setting)"
                        :modules="modules"
                      ></component>
                    </div>
                  </el-col>
                  <span v-else>This feature is disabled.</span>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </el-main>
        <el-footer>
          <el-row type="flex" justify="space-between">
            <el-col>
              <el-row type="flex" justify="center">
                <el-button
                  v-show="activeStep > 1"
                  :disabled="loading || skipLoading"
                  @click="changeStep(activeStep - 1)"
                  type="primary"
                  plain
                >Previous</el-button>
              </el-row>
            </el-col>
            <el-col>
              <el-row type="flex" justify="center">
                <el-button
                  v-show="activeStep === 5 || activeStep === 6"
                  :disabled="loading"
                  :loading="skipLoading"
                  @click="dialogSkip"
                  type="primary"
                  plain
                >I'll do it later</el-button>
              </el-row>
            </el-col>
            <el-col>
              <el-row type="flex" justify="center">
                <el-button
                  :disabled="disableNext"
                  :loading="loading"
                  @click="nextStep"
                  type="primary"
                >{{ activeStep === 6 || (activeStep === 5 && lastStepDisabled) ? "Done" : "Next"}}</el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import SmartFieldString from "@/components/ModuleEditor/SmartFields/String";
import SmartFieldNumber from "@/components/ModuleEditor/SmartFields/Number";
import SmartFieldAutocomplete from "@/components/ModuleEditor/SmartFields/Autocomplete";
import SmartFieldTimerange from "@/components/ModuleEditor/SmartFields/Timerange";
import SmartFieldTag from "@/components/ModuleEditor/SmartFields/Tag";
import SmartFieldBoolean from "@/components/ModuleEditor/SmartFields/Boolean";
import SmartFieldColor from "@/components/ModuleEditor/SmartFields/Color";
import SmartFieldSelect from "@/components/ModuleEditor/SmartFields/Select";
import SmartFieldMultiselect from "@/components/ModuleEditor/SmartFields/Multiselect";
import SmartFieldTextarea from "@/components/ModuleEditor/SmartFields/Textarea";
import SmartFieldJson from "@/components/ModuleEditor/SmartFields/Json";
import SmartFieldButton from "@/components/ModuleEditor/SmartFields/Button";
import SmartFieldTransfer from "@/components/ModuleEditor/SmartFields/Transfer";
import SmartFieldCascader from "@/components/ModuleEditor/SmartFields/Cascader";
import SmartFieldCascaderwithheaders from "@/components/ModuleEditor/SmartFields/CascaderWithHeaders";

import BaseObjects from "./BaseObjects";

import _ from "lodash";
import WizardStep from "@/components/BotCreationWizard/WizardStep.vue";
import { tabSettingValidation } from "@/helperMethods/modules";

export default {
  name: "WizardContent",
  components: {
    WizardStep,
    SmartFieldTimerange,
    SmartFieldString,
    SmartFieldNumber,
    SmartFieldAutocomplete,
    SmartFieldTag,
    SmartFieldBoolean,
    SmartFieldColor,
    SmartFieldSelect,
    SmartFieldMultiselect,
    SmartFieldTextarea,
    SmartFieldJson,
    SmartFieldButton,
    SmartFieldTransfer,
    SmartFieldCascader,
    SmartFieldCascaderwithheaders,
  },
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      skipLoading: false,
      starterChoice: 0,
      form: {
        botName: "",
        conversationStart: "",
        templateChoice: "",
        handover: false,
        livechat: false,
        platforms: {
          webchat: BaseObjects.Webchat,
          whatsapp: BaseObjects.Whatsapp,
          facebook: BaseObjects.Facebook,
          line: BaseObjects.Line,
          telegram: BaseObjects.Telegram,
        },
      },
      tabActive: "webchat",
    };
  },
  computed: {
    listOfPlatforms() {
      return _.pickBy(this.form.platforms, (platform, key) => {
        const module = this.modules[key];
        const moduleExist = !!module;

        return moduleExist;
      });
    },
    disableNext() {
      const isStepOneAndHasNoBotName =
        this.activeStep === 1 && this.form.botName === "";
      const isStepTwoAndNoChoice =
        this.activeStep === 2 && this.starterChoice === 0;
      const isStepThreeAndHasNoGreeting =
        this.activeStep === 3 &&
        this.starterChoice === 1 &&
        this.form.conversationStart === "";
      const isStepThreeHasNoTemplateChoice =
        this.activeStep === 3 &&
        this.starterChoice === 2 &&
        this.form.templateChoice === "";
      const isStepSixAndIsSkipLoading =
        this.activeStep === 6 && this.skipLoading;
      return (
        isStepOneAndHasNoBotName ||
        isStepTwoAndNoChoice ||
        isStepThreeAndHasNoGreeting ||
        isStepThreeHasNoTemplateChoice ||
        isStepSixAndIsSkipLoading
      );
    },
    lastStepDisabled() {
      const platformValues = Object.values(this.form.platforms);
      const platformKeys = Object.keys(this.form.platforms);
      const activeCount = _.filter(platformKeys, (value) => {
        return this.modules[value].enabled;
      }).length;
      const noActivePlatforms = activeCount === 0;
      return noActivePlatforms;
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
  },
  methods: {
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const isComponentExist = _.has(this.$options.components, componentName);
      return isComponentExist ? componentName : null;
    },
    async createBot() {
      const botName = this.form.botName;
      if (/_/.test(botName)) {
        this.$notify({
          message: "Bot name cannot contain invalid characters (_)",
          type: "error",
        });
        return;
      }

      const bots = this.$store.state.bots;

      const currentBots = _.findIndex(bots, (bot) => bot === botName);
      if (currentBots >= 0) {
        this.$notify({
          message: "Bot name already exists",
          type: "error",
        });
        return;
      }

      this.loading = true;

      await this.$store
        .dispatch("CREATE_BOT", botName)
        .then(async () => {
          this.$notify({
            type: "success",
            message: `Created ${botName}. Please wait while switching the bot...`,
          });

          await this.switchBot(botName);
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to create bot.`,
          });
        });
    },
    async switchBot(botName) {
      this.loading = true;
      if (this.$store.state.brain !== botName) {
        this.$store.state.nodes.changes = {};
      }
      await this.$store
        .dispatch("SWITCH_BOT", botName)
        .then(() => {
          this.loading = false;
          this.$notify({
            type: "success",
            message: `Loaded ${botName}`,
          });
          this.changeStep(this.activeStep + 1);
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to switch bot.`,
          });
        });
    },
    nextStep() {
      switch (this.activeStep) {
        case 1:
          this.createBot();
          break;
        case 2:
          this.changeStep(this.activeStep + 1);
          break;
        case 3:
          if (this.starterChoice === 1) {
            this.setConversationStart();
          } else if (this.starterChoice === 2) {
            this.setTimeout();
          }
          break;
        case 4:
          this.setLiveAgent();
          break;
        case 5:
          if (this.lastStepDisabled) {
            return this.dialogDone();
          }
          this.changeStep(this.activeStep + 1);
          break;
        case 6:
          this.dialogDone();
          break;
        default:
          break;
      }
    },
    async setConversationStart() {
      this.loading = true;
      if (this.starterChoice === 1) {
        await this.setGreetingMessage(this.form.conversationStart);
      }
      this.loading = false;
      this.changeStep(this.activeStep + 1);
    },
    setTemplate() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.changeStep(this.activeStep + 1);
      }, 1000);
    },
    async setLiveAgent() {
      this.loading = true;
      await this.setHandoverModuleEnabled(
        this.form.handover,
        this.form.livechat
      );
      this.loading = false;
      this.changeStep(this.activeStep + 1);
    },
    async changeStep(step) {
      if (step === 3) {
        this.form.conversationStart = this.getGreetingMessage();
      }
      if (step === 4) {
        const { handover, livechat } = this.getHandoverModuleEnabled();

        this.form.handover = handover;
        this.form.livechat = livechat;
      }

      await this.$emit("changeStep", step);
    },
    closeDialog() {
      // localStorage.setItem("isNotFirstTime", "1");
      this.$emit("update:creationWizardVisible", false);
    },
    async dialogDone() {
      this.loading = true;
      // await this.submitInitData();
      // TODO: submission integration configuration
      // ...
      // ...

      await this.saveChanges();
      this.loading = false;

      // FIXME: here is for temporarely
      // this.loading = true;
      // setTimeout(() => {
      //   this.loading = false;
      //   this.closeDialog();
      // }, 1000);
      this.closeDialog();
    },
    async dialogSkip() {
      // this.skipLoading = true;
      // await this.submitInitData();
      // this.skipLoading = false;
      this.closeDialog();
    },
    setChoice(choice) {
      this.starterChoice = choice;
    },
    setTemplateChoice(choice) {
      this.form.templateChoice = choice;
    },
    handleDefault(key, input) {
      if (this.form[key] === "") {
        this.form[key] = input;
      }
    },
    resetLiveAgentHandover() {
      if (!this.form.handover) {
        this.form.livechat = false;
      }
    },
    getGreetingMessage() {
      const nodes = this.$store.state.nodes.content;
      const greetings = _.get(nodes, "conversation_start.content.text", []);
      if (greetings.length === 0) {
        return "";
      }
      return greetings[0];
    },
    async setGreetingMessage(message) {
      const nodes = this.$store.state.nodes.content;
      const contentId = "conversation_start";
      const greetingNode = _.get(nodes, contentId, {});

      // modify content
      const newNode = _.cloneDeep(greetingNode);
      newNode.content.text = [message];

      const payload = {
        type: "content",
        id: contentId,
        node: newNode,
        isRename: false,
        old_values: {
          id: contentId,
          type: "content",
          node: greetingNode,
        },
        new_values: {
          id: contentId,
          type: "content",
          node: newNode,
        },
      };

      try {
        await this.$store.dispatch("EDIT_NODE", payload);
        this.$store.commit("SET_CHANGE_CONTENT_NODE", true);
        this.$store.dispatch("FETCH_CONTENT_SNAPSHOTS", {
          brain: this.$store.state.brain,
        });
        this.$message({
          type: "success",
          message: "Greeting message saved!",
        });
        // this.$message({
        //   type: "success",
        //   message: "Greeting message is being saved!"
        // });
      } catch (err) {
        this.$message({
          type: "error",
          message: "Error saving greeting message",
        });
      }
    },
    async submitInitData() {
      if (this.starterChoice === 1) {
        await this.setGreetingMessage(this.form.conversationStart);
      }
      if (this.starterChoice === 2) {
        // TODO: this is set for template loader
        // ...
        // ...
      }
      await this.setHandoverModuleEnabled(
        this.form.handover,
        this.form.livechat
      );
    },
    getHandoverModuleEnabled() {
      const modules = this.$store.state.modules;
      const handover = _.get(modules, "handover", {});
      const handoverEnabled = _.get(handover, "enabled", false);
      const livechatEnabled = _.get(handover, "livechat", false);
      return {
        handover: handoverEnabled,
        livechat: livechatEnabled,
      };
    },
    async setHandoverModuleEnabled(handoverEnabled, livechatEnabled) {
      const modules = this.$store.state.modules;
      const handover = _.get(modules, "handover", {});
      handover.enabled = handoverEnabled;
      if (handover.enabled) {
        handover.livechat = livechatEnabled;
      } else {
        handover.livechat = false;
      }

      try {
        await this.$store.dispatch("SAVE_MODULES", {
          modules,
        });
        this.$message({
          type: "success",
          message: "Livechat configuration saved!",
        });
        // this.$message({
        //   type: "success",
        //   message: "Livechat configuration is being saved!"
        // });
      } catch (err) {
        this.$message({
          type: "error",
          message: "Error saving livechat module",
        });
      }
    },
    async saveChanges(event) {
      const tabSetting = _.get(this.modules, "webchat.tabs", []);
      if (!tabSettingValidation(tabSetting)) {
        this.$message({
          type: "error",
          message: "Error saving tab config",
        });
        return;
      }

      try {
        await this.$store.dispatch("SAVE_MODULES", {
          modules: this.modules,
        });
        this.$message({
          type: "success",
          message: "Config Saved",
        });
      } catch (err) {
        this.$message({
          type: "error",
          message: "Error saving config",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
}
.border {
  border-color: #e4392b;
  border-width: 0.2em;
  border-radius: 5px;
}
</style>

<template>
  <el-card>
    <div slot="header" class="clearfix">
      <el-row>
        <el-col :sm="12" :xs="24">
          <h3 style="line-height: 36px">ChatBot Visitors</h3>
        </el-col>
        <el-col :sm="12" :xs="24">
          <div style="float: right; display: flex; flex-direction: row">
            <el-dropdown @command="exportCSV" trigger="click">
              <el-button
                style="margin-right: 10px"
                type="primary"
                :loading="downloading"
                size="mini"
                :disabled="pagedUsers.length === 0"
                icon="el-icon-download"
                >Export<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="visitor">
                  <i class="el-icon-user"></i>Visitor Data
                </el-dropdown-item>
                <el-dropdown-item command="interaction">
                  <i class="el-icon-chat-line-square"></i>Interaction Data
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-input
              v-model="search"
              size="mini"
              placeholder="Search"
              clearable
              suffix-icon="el-icon-search"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" align="middle">
      <small style="font-weight: bold">Filter by date range</small>
      <el-date-picker
        v-model="searchDateRange"
        type="daterange"
        size="mini"
        format="yyyy-MM-dd"
        style="width: 400px; margin-left: 15px; margin-bottom: 0"
        align="right"
        @change="handleDateRangeChange"
        unlink-panels
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :picker-options="pickerOptions"
      ></el-date-picker>
    </el-row>
    <br />
    <div class="block">
      <el-pagination
        background
        small
        layout="prev, pager, next"
        :pageSize="pageSize"
        :current-page.sync="currentPage"
        :total="noOfItems"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
        @size-change="handleSizeChange"
        style="display: inline-block"
      ></el-pagination>
      <div style="display: inline-block">
        <span
          >{{ noOfItems }} {{ noOfItems === 1 ? "record" : "records" }}</span
        >
      </div>
    </div>
    <div>
      <el-table
        v-loading="loading"
        @selection-change="handleSelectionChange"
        element-loading-text="Loading user list.."
        :data="pagedUsers"
        style="width: 100%"
        size="mini"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <Visitor :user="props.row" :viewMeta="actualMetaData"></Visitor>
          </template>
        </el-table-column>
        <el-table-column label="RowKey">
          <template slot-scope="scope">
            <p style="line-height: 1; margin-bottom: 0; margin-top: 0">
              <small style="font-weight: bold">{{ scope.row.RowKey }}</small>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Last Seen">
          <template slot-scope="scope">
            <small style="line-height: 1">
              {{ scope.row.Timestamp | moment }}
            </small>
          </template>
        </el-table-column>
        <el-table-column
          header-align="left"
          :label="meta.displayValue"
          v-for="(meta, name, index) in viewMetaData.fields"
          :key="index"
          :min-width="meta.columnWidth ? meta.columnWidth : '100px'"
        >
          <template slot-scope="scope">
            {{
              meta.dataType === "datetime"
                ? formatDate(scope.row[name])
                : scope.row[name]
            }}
          </template>
        </el-table-column>
        <el-table-column prop="interaction" label="View Interactions">
          <template slot-scope="scope">
            <VisitorInteractions :visitor="scope.row" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>
<script>
import { exportUsersToXLS, exportInteractionsToXLS } from "@/helperMethods/visitors";
import moment from "moment";
import gql from "graphql-tag";
import _ from "lodash";
import { defaultPickerOptions } from "@/helperMethods/util";
import Visitor from "./VisitorRow";
import XLSX from "xlsx";
import VisitorInteractions from "./VisitorInteractions";

export default {
  components: {
    Visitor,
    VisitorInteractions,
  },
  data() {
    return {
      search: "",
      loading: false,
      downloading: false,
      viewMetaData: {},
      actualMetaData: {},
      pagedUsers: [],
      totalRecordCount: 0,
      searchDateRange: [],
      pageSize: 25,
      currentPage: 1,
      pickerOptions: defaultPickerOptions,
      selectedRows: [],
    };
  },
  computed: {
    noOfItems() {
      return this.totalRecordCount;
    },
  },
  watch: {
    // whenever question changes, this function will run
    search: _.debounce(function (newSearch, oldSearch) {
      this.currentPage = 1;
      this.getUsers();
    }, 500),
  },
  filters: {
    moment: function (date) {
      return moment(date).local().format("D MMM YY h:mm A");
    },
  },
  methods: {
    setDateTodayForFilteringByDefault() {
      const dateNow = new Date();
      this.searchDateRange = [dateNow, dateNow];
    },
    formatDate(date) {
      return date ? moment(date).local().fromNow() : "N/A";
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.getUsers();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getUsers();
    },
    handleDateRangeChange() {
      this.currentPage = 1;
      this.getUsers();
    },
    loadViewMetaData() {
      this.actualMetaData = this.$store.state.modules["visitors"].config;
      //prepare view metadata. filter out fields not to be shown in the grid
      const viewMetaData = {
        fields: {},
      };
      _.forEach(this.actualMetaData.fields, (field, key) => {
        if (field.hideFromTable !== true) {
          viewMetaData.fields[key] = field;
        }
      });
      this.viewMetaData = viewMetaData;
    },
    getUsers() {
      this.loading = true;
      let startDate = "";
      let endDate = "";
      if (this.searchDateRange && this.searchDateRange.length > 0) {
        startDate = this.searchDateRange[0];
        endDate = this.searchDateRange[1];
      }
      this.$store
        .dispatch("FETCH_USERS_BY_PAGE", {
          page: this.currentPage,
          pageSize: this.pageSize,
          searchString: this.search,
          startDate: startDate,
          endDate: endDate,
          mode: "display",
        })
        .then((response) => {
          this.loading = false;
          this.pagedUsers = _.get(response, "data.fetchUsersByPage.rows", []);
          this.totalRecordCount = _.get(
            response,
            "data.fetchUsersByPage.count",
            0
          );
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error fetching users.`,
          });
        });
    },
    exportCSV(command) {
      let startDate = "";
      let endDate = "";
      if (this.searchDateRange && this.searchDateRange.length > 0) {
        startDate = this.searchDateRange[0];
        endDate = this.searchDateRange[1];
      }
      if (command === "visitor") {
        this.downloading = true;
        this.$store
          .dispatch("FETCH_USERS_BY_PAGE", {
            page: this.currentPage,
            pageSize: this.pageSize,
            searchString: this.search,
            startDate: startDate,
            endDate: endDate,
            mode: "download",
          })
          .then((response) => {
            const fullUserList = _.get(response, "data.fetchUsersByPage.rows", []);
            const wb = exportUsersToXLS(this.viewMetaData.fields, fullUserList);
            const filename = this.$exportFilename("chatbot_visitors", "xlsx");
            XLSX.writeFile(wb, filename, {});
          })
          .catch((err) => {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Error fetching users.`,
            });
          })
          .finally(() => {
            this.downloading = false;
          });
      }
      if (command === "interaction") {
        if (!this.selectedRows.length) {
          this.$notify.warning({
            title: "Warning",
            position: "bottom-right",
            message: `Please select at least 1 of row(s) to download interaction.`,
          });
          return;
        }
        const selectedUsers = this.selectedRows.map((v) => `${v.RowKey}||${v.PartitionKey}`);
        const rowData = _.groupBy(this.selectedRows, "RowKey");

        this.downloading = true;
        this.$store
          .dispatch("FETCH_USER_INTERACTIONS_BATCH", {
            userIds: selectedUsers,
            startDate: startDate,
            endDate: endDate,
          })
          .then((response) => {
            const fields = this.viewMetaData.fields;
            const result = _.chain(response)
              .get("data.getInteractionsBatch.rows", [])
              .groupBy("user_id")
              .value();
            const wb = exportInteractionsToXLS(fields, result, rowData);
            const filename = this.$exportFilename("chatbot_visitors_interactions", "xlsx");
            XLSX.writeFile(wb, filename, {});
          })
          .catch((err) => {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Error fetching users.`,
            });
          })
          .finally(() => {
            this.downloading = false;
          });
      }
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
  },
  mounted() {
    this.setDateTodayForFilteringByDefault();
    const pageSize = _.get(
      this.$store,
      "state.modules['visitors'].page_size",
      25
    );
    this.pageSize = pageSize;
    this.loadViewMetaData();
    this.getUsers();
  },
};
</script>

<template>
  <section>
    <el-dialog title="Add new Auth0 user" :visible.sync="showAddUserDialog">
      <el-form ref="newUserForm" :model="newUserForm" :rules="newUserFormRules">
        <el-form-item label="Email" prop="email">
          <el-input v-model="newUserForm.email" required />
        </el-form-item>
        <el-form-item label="Name" prop="name">
          <el-input v-model="newUserForm.name" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input
            type="textarea"
            :rows="2"
            :autosize="false"
            value="For security reasons, passwords are disabled during account creation. All new users are required to use their email to reset their password via the login page."
            disabled
          />
        </el-form-item>
        <el-form-item label="Department" prop="department">
          <el-select
            class="inline-input"
            v-model="newUserForm.departments"
            placeholder="Select departments"
            multiple
            filterable
          >
            <el-option
              v-for="item in departments"
              :key="item.id"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Role" prop="role">
          <el-select
            class="inline-input"
            v-model="newUserForm.roles"
            placeholder="Select roles"
            multiple
            filterable
          >
            <el-option
              v-for="item in roles"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="isUpdatingUsers"
            :disabled="isUpdatingUsers"
            size="mini"
            type="primary"
            @click="submitNewUser"
            >Submit</el-button
          >
          <el-button
            :disabled="isUpdatingUsers"
            size="mini"
            @click="showAddUserDialog = false"
            >Cancel</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Delete Auth0 user"
      :visible.sync="deleteUserDialogIsVisible"
    >
      <span>
        Are you sure you want to delete this user
        <b>{{ userToDelete.email }}</b>
      </span>
      <div style="margin-top: 20px">
        <el-button
          :loading="isUpdatingUsers"
          :disabled="isUpdatingUsers"
          size="mini"
          type="primary"
          @click="deleteUser"
          >Submit</el-button
        >
        <el-button
          size="mini"
          @click="deleteUserDialogIsVisible = false"
          :disabled="isUpdatingUsers"
          >Cancel</el-button
        >
      </div>
    </el-dialog>
    <el-row>
      <el-row>
        <el-col style="margin-bottom:10px">
          <el-button
            size="mini"
            type="primary"
            @click="showAddUserDialog = true"
            >Add User</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-card>
            <el-table
              size="mini"
              :data="$store.state.auth0.users"
              style="width: 100%"
              :row-class-name="getRowClassName"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <section style="display:flex; flex-flow:row wrap;">
                    <div
                      style="margin-bottom: 15px; width: 40%; margin-right: 20px;"
                    >
                      <strong style="margin-bottom: 5px; display:block;"
                        >Name:</strong
                      >
                      <el-input
                        size="mini"
                        v-model="props.row.user_metadata.name"
                      />
                    </div>

                    <div
                      style="margin-bottom: 15px; width: 40%; margin-right: 20px;"
                    >
                      <strong style="margin-bottom: 5px; display:block;"
                        >Alias:</strong
                      >
                      <el-input
                        size="mini"
                        v-model="props.row.user_metadata.alias"
                      />
                    </div>

                    <div
                      style="margin-bottom: 15px; width: 40%; margin-right: 20px;display:flex;flex-flow:row wrap;"
                    >
                      <el-checkbox
                        v-for="role in $store.state.modules['access_control']
                          .roles"
                        :key="role.name"
                        :label="role.name"
                        style="margin-left:0;"
                        v-model="props.row.app_metadata.roles[role.name]"
                      />
                    </div>

                    <div
                      style="margin-bottom: 15px; width: 40%; margin-right: 20px;"
                    >
                      <strong style="margin-bottom: 5px; display:block;"
                        >Departments:</strong
                      >
                      <div>
                        <el-dropdown
                          size="mini"
                          trigger="click"
                          @command="
                            props.row.app_metadata.departments.push($event)
                          "
                        >
                          <el-tag
                            style="margin-right: 5px; cursor:pointer;"
                            size="mini"
                            type="info"
                          >
                            Assign
                            <i class="el-icon-plus el-icon-right" />
                          </el-tag>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                              v-for="department in $store.state.department
                                .departments"
                              :key="department.id"
                              :disabled="
                                props.row.app_metadata.departments &&
                                props.row.app_metadata.departments.indexOf(
                                  department.name
                                ) > -1
                              "
                              :command="department.name"
                              >{{ department.name }}</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>

                        <el-tag
                          v-for="(department, index) in props.row.app_metadata
                            .departments"
                          v-dragging="{
                            item: department,
                            list: props.row.app_metadata.departments,
                            group: `${props.row.email}_departments`,
                          }"
                          :closable="true"
                          :key="`${props.row.email}-${department}`"
                          style="margin-right: 5px; cursor:pointer; text-transform: capitalize;margin-botton: 5px;"
                          size="mini"
                          @close="
                            props.row.app_metadata.departments.splice(index, 1)
                          "
                          >{{ department }}</el-tag
                        >
                      </div>
                    </div>

                    <!-- <div
                      v-if="isHandoverRoutingBySkillEnabled"
                      style="margin-bottom: 15px; width: 40%; margin-right: 20px;"
                    >
                      <strong style="margin-bottom: 5px; display:block;"
                        >Skills:</strong
                      >
                      <el-select
                        style="width: 100%;"
                        v-model="props.row.app_metadata.skills"
                        filterable
                        placeholder="Select skill"
                        multiple
                      >
                        <el-option
                          v-for="(skill, skillIndex) in skills"
                          :key="`${skill}-${skillIndex}`"
                          :label="skill.name"
                          :value="skill.stateVariable"
                        />
                      </el-select>
                    </div> -->
                  </section>
                </template>
              </el-table-column>
              <el-table-column label="Name">
                <template slot-scope="props">{{ props.row.user_metadata.name || props.row.name }} 
                  <el-tag style="margin-left: 4px;" v-if="props.row.user_metadata.alias" size="mini" type="info">{{props.row.user_metadata.alias}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="Email">
                <template slot-scope="props">{{ props.row.email }}</template>
              </el-table-column>
              <el-table-column label="Roles" 
              :filters="$store.state.modules['access_control'].roles.map(role=> ({text: role.name, value: role.name}))"
              :filter-method="filterRole">
                <template slot-scope="props">
                  <el-tag 
                  v-for="role in getRoles(props.row)" :key="role"
                  size="mini"
                  effect="plain"
                  closable
                  @close="removeRole(props.row, role)"
                  style="margin-right: 4px;"
                  >{{role}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label>
                <template slot-scope="props">
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    size="mini"
                    @click="
                      showDeleteUserDialog(props.row.email, props.row.user_id)
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>

            <br />

            <el-tooltip
              content="Users are required to relogin for changes to take effect."
            >
              <el-button
                style="width: 100%;"
                type="primary"
                :disabled="isUpdatingUsers"
                @click="persistUsers"
              >
                Save Changes&nbsp;&nbsp;
                <i
                  :class="{
                    'el-icon-upload2': !isUpdatingUsers,
                    'el-icon-loading': isUpdatingUsers,
                  }"
                ></i>
              </el-button>
            </el-tooltip>
          </el-card>
        </el-col>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import _ from "lodash";

export default {
  props: ["isFetchingUsers"],
  data() {
    return {
      isUpdatingUsers: false,
      showAddUserDialog: false,
      deleteUserDialogIsVisible: false,
      userToDelete: "",
      newUserForm: {
        email: "",
        name: "",
        departments: [],
        roles: [],
      },
      newUserFormRules: {
        email: [
          { required: true, message: "Please input Email", trigger: "change" },
          {
            type: "email",
            message: "Please enter a valid Email",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "Please input Name", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    // isHandoverRoutingBySkillEnabled() {
    //   return _.get(
    //     this.$store.state,
    //     "modules.handover.handoverRouting.bySkill",
    //     false
    //   );
    // },
    skills() {
      return _.get(
        this.$store.state,
        "modules.handover.handoverRouting.skillsLibrary",
        []
      );
    },
    roles() {
      return this.$store.state.modules["access_control"].roles;
    },
    departments() {
      const departments = _.get(
        this,
        "$store.state.department.departments",
        []
      );
      return departments;
    },
  },
  methods: {
    getRoles(user) {
      let roles = []
      _.forEach(user.app_metadata.roles, (value, key) => {
        if (value) {
          roles.push(key)
        }
      });

      return roles;
    },

    filterRole(value, row) {
      return this.getRoles(row).includes(value);
    },

    removeRole(user, role) {
      delete user.app_metadata.roles[role];
      this.$forceUpdate();
    },

    async submitNewUser() {
      if (this.isUpdatingUsers) {
        return;
      }

      this.isUpdatingUsers = true;
      this.$refs.newUserForm
        .validate()
        .then(async () => {
          return await this.$store.dispatch("ADD_USER", {
            ...this.newUserForm,
          });
        })
        .then((result) => {
          if (!result) {
            throw new Error("GraphQL failed response");
          }
          this.$refs.newUserForm.resetFields();

          this.$notify.success({
            title: "Success",
            position: "bottom-right",
            message: "User has been added",
          });
          this.showAddUserDialog = false;
        })
        .catch((e) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message:
              "User might have already exists. Please check that you have entered the details correctly",
          });
        })
        .finally(() => {
          this.isUpdatingUsers = false;
        });
    },
    showDeleteUserDialog(email, userId) {
      this.deleteUserDialogIsVisible = true;
      this.userToDelete = {
        email,
        userId,
      };
    },
    async deleteUser() {
      if (this.isUpdatingUsers) {
        return;
      }
      this.isUpdatingUsers = true;
      try {
        const { email, userId } = this.userToDelete;
        const userDeleted = await this.$store.dispatch("DELETE_USER", {
          email,
          userId,
        });
        if (userDeleted) {
          this.$notify.success("User has been deleted");
        } else {
          this.$notify.error(
            "There was an issue with deleting user. Please try again later."
          );
        }
        this.userToDelete = {};
        this.deleteUserDialogIsVisible = false;
      } catch (error) {
        this.$notify.error(
          "There was an issue with deleting user. Please try again later."
        );
      } finally {
        this.isUpdatingUsers = false;
      }
    },
    selectAllUsers(role, event) {
      _.map(this.$store.state.auth0.users, (user) => {
        user.app_metadata.roles[role.name] = event;
      });
    },
    modifiedUsers(modified, original) {
      const modifiedJsons = _.map(modified, JSON.stringify);
      const originalJsons = _.map(original, JSON.stringify);
      return _.chain(modifiedJsons)
        .filter((modifiedUser) => {
          const includes = _.includes(originalJsons, modifiedUser);
          return !includes;
        })
        .map(JSON.parse)
        .value();
    },
    getRowClassName(scope) {
      const statusArray = _.chain(scope)
        .get("row.app_metadata.roles", [])
        .values()
        .compact()
        .value();
      return statusArray.length === 0 ? "has-background-oldlace" : "";
    },
    persistUsers() {
      this.isUpdatingUsers = true;
      let users = _.cloneDeep(this.$store.state.auth0.users);

      // filter by modified
      users = this.modifiedUsers(users, this.$store.state.auth0.usersStatic);
      users = _.map(users, (user) => {
        user.app_metadata.roles = _.chain(user.app_metadata.roles)
          .map((v, k) => {
            if (v && k != "null") {
              return k;
            }
          })
          .compact()
          .value();
        return _.pick(user, [
          "app_metadata",
          "user_metadata",
          "user_id",
          "email",
        ]);
      });

      console.log("converted users", users);

      this.$store
        .dispatch("SAVE_USERS", {
          users,
        })
        .then(
          () => {
            this.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: "Users Saved",
            });
          },
          () => {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Error saving users",
            });
          }
        )
        .then(() => {
          this.isUpdatingUsers = false;
        });
    },
  },
};
</script>

<style>
.has-background-oldlace {
  background-color: oldlace !important;
}
</style>

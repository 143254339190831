<template>
  <div>
    <el-tooltip
      class="item"
      effect="dark"
      :content="formatted_date_value"
      placement="bottom"
    >
      <span>{{ prefixMessage }} {{ fromNow }}</span>
    </el-tooltip>
  </div>
</template>
<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "FromNow",
  props: {
    value: { type: String, default: () => moment().toISOString() },
    interval: { type: Number, default: 1000 },
    prefixMessage: { type: String },
  },
  data() {
    return { fromNow: moment(this.value).fromNow() };
  },
  mounted() {
    this.intervalId = setInterval(this.updateFromNow, this.interval);
    this.unwatchValue = this.$watch("value", this.updateFromNow);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.unwatchValue();
  },
  methods: {
    updateFromNow() {
      let newFromNow = moment(this.value).fromNow();
      if (newFromNow !== this.fromNow) {
        this.fromNow = newFromNow;
      }
    },
  },
  computed: {
    formatted_date_value() {
      return `${this.prefixMessage} on ${moment(this.value).toLocaleString()}`;
    },
  },
};
</script>

<template>
  <el-card class="cursor-pointer" @click.native="selectTopic(topic)" shadow="hover">
    <el-row type="flex" align="middle" justify="space-between">
      <span class="text-capitalize">{{ topic }}</span>
      <!-- <el-button v-if="groupByTopicIsEnabled" size="small" round type="primary" plain>Train</el-button> -->
    </el-row>
  </el-card>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  methods: {
    selectTopic(topic) {
      this.$emit("selectTopic", topic);
      this.$store.commit("SET_CURRENT_VIEWING_TOPIC", topic);
    }
  },
  computed: {
    groupByTopicIsEnabled() {
      return this.$store.getters.groupByTopicIsEnabled;
    }
  },
  props: {
    topic: {}
  }
});
</script>



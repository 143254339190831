<template>
  <div>
    <div v-for="(node, id) in nodes" :key="id">
      {{ id }} -> links: {{ getEdges(node) }}
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    nodes() {
      return this.$store.state.nodes.content;
    }
  },
  methods: {
    getEdges(node) {
      let edges = [];

      edges.push(_.get(node, "next"));

      edges.push(_.get(node, "content.buttons.event"));
      edges.push(_.get(node, "content.buttons.data"));
      edges.push(_.get(node, "quickReplies.event"));
      edges.push(_.get(node, "quickReplies.data"));

      return edges;
    }
  }
};
</script>

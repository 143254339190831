<template>
  <div>
    <el-row>
      <el-tooltip content="Export as CSV" placement="top">
        <el-button
          class="exportAddNewBtn importExportBtn"
          plain
          @click="exportUserAddedQuestions"
          size="mini"
          icon="el-icon-download"
        />
      </el-tooltip>
    </el-row>
    <div v-loading="isFetching"></div>

    <br />

    <el-table
      :data="trainings"
      border
      highlight-current-row
      ref="user-added-trainings-table"
      style="width: 60%"
      size="mini"
    >
      <el-table-column prop="#" label="#" width="60">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column prop="question" label="Question"></el-table-column>
      <el-table-column prop="createdDate" label="Created At">
        <template slot-scope="scope">{{
          scope.row.createdDate | moment("YYYY-MM-DD")
        }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { graph } from "@/store/api";
import gql from "graphql-tag";
import XLSX from "xlsx";

export default {
  data() {
    return {
      isFetching: false,
      trainings: [],
    };
  },
  mounted() {
    this.fetchUserAddedTrainings();
  },
  methods: {
    onArchive(scope) {
      this.$store.dispatch("ARCHIVE_TRAINING_RECORD", scope.row).then(() => {
        this.trainings.splice(scope.$index, 1);
      });
    },
    fetchUserAddedTrainings() {
      this.isFetching = true;
      graph
        .query({
          query: gql`
            query {
              faqAPI {
                getUserAddedUnlabeledQuestions
              }
            }
          `,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          const trainings = _.get(
            response.data,
            "faqAPI.getUserAddedUnlabeledQuestions",
            []
          );
          this.trainings = trainings;
        })
        .catch((error) =>
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to fetch user added trainings.`,
          })
        )
        .finally(() => (this.isFetching = false));
    },
    exportUserAddedQuestions() {
      const data = this.trainings;
      let toExport = [];
      toExport.push(["#", "question", "createdDate"]);

      for (let index = 0; index < data.length; index++) {
        const row = data[index];
        const rowInput = [index + 1, row.question, row.createdDate];
        toExport.push(rowInput);
      }

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(toExport);
      XLSX.utils.book_append_sheet(wb, ws, "userAddedQuestions");

      const filename = this.$exportFilename("FAQ_userAddedQuestions", "csv");
      XLSX.writeFile(wb, filename, {});
    },
  },
};
</script>

<template>
  <div>
    <el-row type="flex" justify="space-between">
      <div>
        <span>{{ title }}</span>
        <el-tooltip
          style="color: gray"
          content="Evaluation Results for the past 5 tests"
        >
          <i class="el-icon-question" />
        </el-tooltip>
      </div>

      <el-button
        type="primary"
        round
        size="mini"
        plain
        icon="el-icon-download"
        @click="exportPastResults"
        >Download</el-button
      >
    </el-row>

    <el-table
      style="margin-top: 15px"
      stripe
      size="mini"
      :data="pastTestResults"
    >
      <el-table-column prop="department" label="Department" />
      <el-table-column v-for="(date, index) in dates" :key="index">
        <template slot="header" slot-scope="scope">
          <span>{{ dates[scope.$index - 1].date }}</span>
          <br />
          <span>{{ dates[scope.$index - 1].time }}</span>
        </template>
        <template slot-scope="props">
          {{ pastTestResults[props.$index][date.formattedDateTime] }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["title", "pastTestResults", "dates"],
  methods: {
    exportPastResults() {
      this.$emit("exportpastresults");
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.testLoading)?_c('el-button',{staticClass:"test-loading-btn"},[_c('i',{staticClass:"el-icon-loading"})]):(
      !_vm.scope.row.intent_ranking ||
        (_vm.scope.row.intent_ranking && _vm.scope.row.intent_ranking.length === 0)
    )?_c('el-tooltip',{attrs:{"content":"This example is not evaluated yet 🚼","placement":"top"}},[_c('i',{staticClass:"list-icon el-icon-minus",staticStyle:{"color":"#707070"}})]):(_vm.isConflictedByFirstIntent(_vm.scope.row.intent_ranking))?_c('el-popover',{attrs:{"placement":"top-start","width":"300","trigger":"click"}},[_c('el-row',[_c('el-col',[_c('span',[_c('table',{attrs:{"id":"conflictingIntentTable"}},[_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Current Intent:")])]),_c('tr',[_c('td',[_c('span',{staticClass:"example-intent-listing",on:{"click":function($event){return _vm.setSearch(_vm.selectedIntent.intentName)}}},[_vm._v(_vm._s(_vm.selectedIntent.intentName))])]),_c('td',{staticStyle:{"text-align":"center"}},[(
                    _vm.fetchCurrentIntentConfidence(_vm.scope.row.intent_ranking)
                  )?_c('span',[_vm._v(_vm._s(_vm.fetchCurrentIntentConfidence(_vm.scope.row.intent_ranking)))]):_c('el-tooltip',{attrs:{"content":"Not the top 3 predictions","placement":"top"}},[_c('i',{staticClass:"list-icon el-icon-minus",staticStyle:{"color":"#707070"}})])],1)]),_c('br'),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Top Prediction:")])]),_c('tr',[_c('td',[_c('el-tooltip',{attrs:{"content":("Example: " + (_vm.scope.row.intent_ranking[0].example || '')),"placement":"left"}},[_c('i',{staticClass:"el-icon-question",staticStyle:{"margin-right":"0.5em","color":"#909399"}})]),_c('span',{staticClass:"example-intent-listing",style:(_vm.formatIntentListStyle(
                      _vm.scope.row.intent_ranking,
                      _vm.scope.row.intent_ranking[0].name,
                      0
                    )),on:{"click":function($event){return _vm.setSearch(_vm.scope.row.intent_ranking[0].name)}}},[_vm._v(_vm._s(_vm.scope.row.intent_ranking[0].name))])],1),_c('td',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.formatConfidence(_vm.scope.row.intent_ranking[0].confidence))+"% ")])])])])])])],1),_c('el-row',[_c('el-col',[_c('FaqConflictedButtonAction',{attrs:{"scope":_vm.scope,"selected-intent":_vm.selectedIntent},on:{"moveExample":_vm.moveExample,"deleteVariation":_vm.deleteVariation}})],1)],1),_c('el-button',{staticStyle:{"border":"0px"},attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticStyle:{"color":"#e4392b"}},[_vm._v("Conflicted")])])],1):(_vm.isPotentiallyConflicted(_vm.scope.row.intent_ranking))?_c('el-popover',{attrs:{"placement":"top-start","width":"300","trigger":"click"}},[_c('el-row',[_c('el-col',[_c('table',{attrs:{"id":"conflictingIntentTable"}},[_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Current Intent:")])]),_c('tr',[_c('td',[_c('span',{staticClass:"example-intent-listing",style:(_vm.formatIntentListStyle(
                    _vm.scope.row.intent_ranking,
                    _vm.scope.row.intent_ranking[0].name,
                    0
                  )),on:{"click":function($event){return _vm.setSearch(_vm.scope.row.intent_ranking[0].name)}}},[_vm._v(_vm._s(_vm.scope.row.intent_ranking[0].name))])]),_c('td',[_vm._v(_vm._s(_vm.formatConfidence(_vm.scope.row.intent_ranking[0].confidence))+"%")])]),_c('br'),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Confused Prediction:")])]),_c('tr',[_c('td',[_c('el-tooltip',{attrs:{"content":("Example: " + (_vm.scope.row.intent_ranking[1].example || '')),"placement":"left"}},[_c('i',{staticClass:"el-icon-question",staticStyle:{"margin-right":"0.5em","color":"#909399"}})]),_c('span',{staticClass:"example-intent-listing",style:(_vm.formatIntentListStyle(
                    _vm.scope.row.intent_ranking,
                    _vm.scope.row.intent_ranking[1].name,
                    1
                  )),on:{"click":function($event){return _vm.setSearch(_vm.scope.row.intent_ranking[1].name)}}},[_vm._v(_vm._s(_vm.scope.row.intent_ranking[1].name))])],1),_c('td',[_vm._v(_vm._s(_vm.formatConfidence(_vm.scope.row.intent_ranking[1].confidence))+"%")])])])])],1),_c('el-row',[_c('el-col',[_c('FaqConflictedButtonAction',{attrs:{"scope":_vm.scope,"selected-intent":_vm.selectedIntent},on:{"moveExample":_vm.moveExample,"deleteVariation":_vm.deleteVariation}})],1)],1),_c('el-button',{staticStyle:{"border":"0px"},attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticStyle:{"color":"#e6a23c"}},[_vm._v("Potential")]),_c('br'),_c('span',{staticStyle:{"color":"#e6a23c"}},[_vm._v(_vm._s(" ")+"Conflict")])])],1):(!_vm.isConflictedByFirstIntent(_vm.scope.row.intent_ranking))?_c('el-tooltip',{attrs:{"content":("High confidence (" + (_vm.formatConfidence(_vm.scope.row.intent_ranking[0].confidence)) + "%) 👍🏻"),"placement":"top"}},[_vm._v(" "+_vm._s(_vm.scope.row.intent_ranking[0])+" "),_c('i',{staticClass:"list-icon el-icon-success",staticStyle:{"color":"#67c23a"}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
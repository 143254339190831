<template>
  <el-main style="padding: 0">
    <el-row v-if="$store.state.modules.evaluation.manualEvaluation">
      <el-card class="box-card">
        <p style="margin: 0 0 10px 0; font-size: 12px">
          <template v-if="currentTestFile">
            "{{ currentTestFile }}" is being used for all manual/scheduled
            evaluations.
          </template>
          <template v-else>No file uploaded for manual/scheduled evaluations.</template>
        </p>
        <el-row type="flex">
          <el-upload
            ref="upload"
            action="#"
            :limit="1"
            :auto-upload="false"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-change="onChange"
            :file-list="fileList"
            :multiple="false"
          >
            <el-input
              size="mini"
              readonly
              placeholder="Select file to start evaluation or upload"
              slot="trigger"
              style="width: 350px; margin-right: 10px"
              :value="fileList.length > 0 ? fileList[0].name : ''"
            >
              <el-button icon="el-icon-document" size="mini" plain slot="append" type="primary"></el-button>
            </el-input>
          </el-upload>

          <el-button
            size="mini"
            :disabled="fileList.length === 0"
            :loading="batchTestLoading"
            icon="el-icon-upload2"
            type="warning"
            plain
            @click="submitUpload"
          >Upload File</el-button>

          <el-button
            size="mini"
            icon="el-icon-download"
            type="info"
            plain
            :loading="batchTestLoading"
            @click="downloadFile"
          >Download File</el-button>

          <!-- Topic Evaluation -->
          <template v-if="groupByTopicIsEnabled && rasaEnabled">
            <el-button
              size="mini"
              icon="el-icon-video-play"
              type="success"
              plain
              :loading="batchTestLoading"
              @click="topicBatchTestCSV"
            >
              Start Evaluation Using Topics (expectedTopic column)
              <el-tooltip
                style="color: gray"
                content="Have each test row's topic under a column called 'expectedTopic'"
              >
                <i class="el-icon-question" />
              </el-tooltip>
            </el-button>

            <el-button
              size="mini"
              icon="el-icon-video-play"
              type="success"
              plain
              :loading="batchTestLoading"
              @click="endToEndTopicBatchTestCSV"
            >Start End-to-End Evaluation</el-button>
          </template>

          <template v-else>
            <el-button
              size="mini"
              icon="el-icon-video-play"
              type="success"
              plain
              :loading="batchTestLoading"
              @click="batchTestCSV"
            >Start Evaluation</el-button>
          </template>
        </el-row>
      </el-card>
    </el-row>

    <el-card stlye="overflow: hidden">
      <div slot="header" class="clearfix">
        <el-row type="flex" justify="space-between" align="center" style="margin-bottom: 0">
          <div style="display: flex; align-items: center">
            Evaluation Result
            <el-select
              v-model="selectedPastBatchTest"
              filterable
              size="mini"
              style="margin-left: 10px"
              placeholder="Select Past Evaluations"
              @change="selectPastTest(selectedPastBatchTest)"
            >
              <el-option
                v-for="pastTest in pastBatchTests"
                :key="pastTest.name"
                multiple="false"
                :label="pastTest.name"
                :value="pastTest.name"
              />
            </el-select>
            <el-select
              v-if="modelPredictionScore.length > 0"
              v-model="selectedDepartment"
              filterable
              style="margin-left: 10px"
              size="mini"
              placeholder="Select Department"
              :disabled="selectedPastBatchTest === ''"
              @change="selectDepartment"
            >
              <el-option
                v-for="modelPredictionScoreComponent in modelPredictionScore"
                :key="modelPredictionScoreComponent.department"
                multiple="false"
                :label="modelPredictionScoreComponent.department"
                :value="modelPredictionScoreComponent.department"
              />
            </el-select>
          </div>

          <el-button
            type="primary"
            round
            size="mini"
            icon="el-icon-download"
            plain
            @click="exportCSV"
          >Download</el-button>
        </el-row>
      </div>

      <BatchTestResultsContentNodeId v-if="isWatson" :batch-test-result="batchTestResult" />
      <BatchTestResults v-if="!isWatson" :batch-test-result="batchTestResult" />
    </el-card>

    <br />

    <masonry :cols="{ default: 2, 600: 1 }" :gutter="15">
      <template v-if="selectedPastBatchTest !== '' || batchTestResult.length > 0">
        <el-card class="box-card" style="margin-bottom: 15px">
          <span>Model Prediction Score Breakdown</span>
          <el-tooltip
            style="color: gray"
            content="Accuracy is determined by the number of Expected Intents that match Intent 1 over the total number of Intents that are tested"
          >
            <i class="el-icon-question" />
          </el-tooltip>
          <el-table stripe :data="modelPredictionScore" size="mini">
            <el-table-column prop="department" label="Department" min-width="130" />
            <el-table-column prop="accuracy" label="Accuracy" min-width="100" />
          </el-table>
        </el-card>

        <el-card
          v-if="groupByTopicIsEnabled && rasaEnabled"
          class="box-card"
          style="margin-bottom: 15px"
        >
          <span>Topic Score Breakdown</span>
          <el-tooltip
            style="color: gray"
            content="Accuracy is determined by the number of Expected Intents that match Intent 1 over the total number of Intents that are tested per Topic"
          >
            <i class="el-icon-question" />
          </el-tooltip>
          <el-table stripe :data="topicsScore" size="mini">
            <el-table-column prop="topic" label="Topic" min-width="130" />
            <el-table-column prop="accuracy" label="Accuracy" min-width="100" />
          </el-table>
        </el-card>

        <el-card v-if="isWatson" class="box-card" style="margin-bottom: 15px">
          <span>Answer Score Breakdown</span>
          <el-tooltip
            style="color: gray"
            content="Accuracy is determined by the number of Expected Content Node Ids that match the Predicted Content Node Id over the total number of Content Node Ids that are tested"
          >
            <i class="el-icon-question" />
          </el-tooltip>
          <el-table stripe size="mini" :data="answerScore">
            <el-table-column prop="department" label="Department" min-width="130" />
            <el-table-column prop="accuracy" label="Accuracy" min-width="100" />
          </el-table>
        </el-card>
      </template>

      <el-card
        class="box-card"
        style="margin-bottom: 15px"
        v-if="pastTestResultsByModelPrediction.length > 0"
      >
        <PastResultsTable
          title="Past 5 Results (Model Prediction)"
          :dates="dates"
          :pastTestResults="pastTestResultsByModelPrediction"
          :loading="batchTestLoading"
          v-on:exportpastresults="exportAllPastResultsByModelPrediction"
        />
      </el-card>

      <el-card
        v-if="pastTestResultsByAnswer.length > 0"
        style="margin-bottom: 15px"
        class="box-card"
      >
        <PastResultsTable
          title="Past 5 Results (Answer)"
          :dates="dates"
          :pastTestResults="pastTestResultsByAnswer"
          :loading="batchTestLoading"
          v-on:exportpastresults="exportAllPastResultsByAnswer"
        />
      </el-card>
    </masonry>
  </el-main>
</template>

<script>
import XLSX from "xlsx";
import { Loading } from "element-ui";
import Vue from "vue";
import _ from "lodash";
import * as Papa from "papaparse";
import BatchTestResults from "./BatchTestResults";
import BatchTestResultsContentNodeId from "./BatchTestResultsContentNodeId";
import PastResultsTable from "./PastResultsTable";
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";
import { HTTP_STATUS } from "@/lib/httpConstants";

const LOADER_INIT = {
  target: ".loader-target",
  fullscreen: false,
  text: "Loading..."
};

export default Vue.extend({
  components: {
    BatchTestResults,
    BatchTestResultsContentNodeId,
    PastResultsTable
  },
  data() {
    return {
      selectedDepartment: "",
      fileList: [],
      pastBatchTests: [],
      selectedPastBatchTest: "",
      selectedPastBatchTestDepartments: [],
      currentTestFile: "",
      batchTestResult: [],
      batchTestResultMap: {},
      modelPredictionScore: [],
      modelPredictionScoreMap: {},
      answerScore: [],
      answerScoreMap: {},
      topicsScore: [],
      pastTestResultsByModelPrediction: [],
      pastTestResultsByAnswer: [],
      dates: [],
      selectedTopic: "",
      batchTestLoading: false
    };
  },
  computed: {
    ...mapGetters(["rasaEnabled", "groupByTopicIsEnabled", "getFaqTopics"]),
    isWatson() {
      return _.get(
        this.$store,
        "state.modules.faq.WatsonAssistant.enabled",
        false
      );
    }
  },
  mounted() {
    this.fetchHistoryTests();
    this.listLatestFile();
    this.getPastTestResults();
  },
  methods: {
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    selectDepartment() {
      if (this.selectedDepartment === "Overall") {
        this.batchTestResult = this.batchTestResultMap[
          this.selectedPastBatchTest
        ];
      } else {
        this.batchTestResult = this.batchTestResultMap[
          this.selectedPastBatchTest
        ].filter(result => result.department === this.selectedDepartment);
      }
    },
    async listLatestFile() {
      this.batchTestLoading = true;
      const response = await this.$rest("get", "list_latest_file")
        .then(result => result)
        .catch(() => {
          this.batchTestLoading = false;
          this.$message.error("Error uploading file!");
        });

      if (!response.fileName) {
        this.$message.success("No file uploads exist");
      }

      this.batchTestLoading = false;
      this.currentTestFile = response.fileName;
    },
    async getPastTestResults() {
      const pastTestResults = await this.$rest(
        "get",
        "past_test_results?limit=5"
      )
        .then(({ pastTestResults }) => pastTestResults)
        .catch(err => {
          console.log(err);
          this.$message.error("Error listing past test results!");
        });

      const dates = [];
      const modelPredictionResultsByDate = {};
      const answerResultsByDate = {};
      pastTestResults.forEach(pastTestResult => {
        const date = moment(pastTestResult.createdAt)
          .utcOffset(8)
          .format("YYYY-MM-DD");
        const time = moment(pastTestResult.createdAt)
          .utcOffset(8)
          .format("HH:mm:ss");
        const formattedDateTime = date + " " + time;
        dates.unshift({
          date,
          time,
          formattedDateTime
        });

        const modelPredictionAccuracy = this.calculateAccuracy(
          pastTestResult.departmentsScore,
          "department"
        );

        modelPredictionAccuracy.forEach(modelPredictionAccuracyByDepartment => {
          const { accuracy, department } = modelPredictionAccuracyByDepartment;
          if (!modelPredictionResultsByDate[department]) {
            modelPredictionResultsByDate[department] = {
              department,
              [formattedDateTime]: accuracy
            };
          } else {
            modelPredictionResultsByDate[department] = Object.assign(
              {},
              modelPredictionResultsByDate[department],
              { [formattedDateTime]: accuracy }
            );
          }
        });

        if (pastTestResult.answerScore) {
          const answerAccuracy = this.calculateAccuracy(
            pastTestResult.answerScore,
            "department"
          );
          answerAccuracy.forEach(answerAccuracyByDepartment => {
            const { accuracy, department } = answerAccuracyByDepartment;
            if (!answerResultsByDate[department]) {
              answerResultsByDate[department] = {
                department,
                [formattedDateTime]: accuracy
              };
            } else {
              answerResultsByDate[department] = Object.assign(
                {},
                answerResultsByDate[department],
                { [formattedDateTime]: accuracy }
              );
            }
          });
        }
      });
      this.dates = dates;
      this.pastTestResultsByModelPrediction = Object.keys(
        modelPredictionResultsByDate
      ).map(department => {
        return modelPredictionResultsByDate[department];
      });

      this.pastTestResultsByAnswer = Object.keys(answerResultsByDate).map(
        department => answerResultsByDate[department]
      );
    },
    beforeUpload(file) {
      const isCSV =
        (file.type === "text/csv" ||
          file.type === "application/vnd.ms-excel") &&
        file.name.endsWith(".csv");
      if (isCSV) {
        this.batchTestLoading = true;
        this.handleSuccess(file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },
    handleSuccess(file) {
      const reader = new FileReader();

      reader.readAsText(file);
      const fileName = file.name;
      this.uploadFile(fileName, file);
    },
    async uploadFile(fileName, file) {
      const response = await this.$rest("post", "evaluation_upload_file", {
        fileName
      })
        .then(response => response)
        .catch(err => {
          this.$message.error("Error uploading file!");
        });

      if (!response.uploadURL) {
        this.batchTestLoading = false;
        return "";
      }

      const uploadURLObj = new URL(response.uploadURL);
      const uploadURLHost = uploadURLObj.host;
      let uploadResponse;

      const toUseLocalUpload =
        uploadURLHost.indexOf(this.$store.state.server) >= 0 &&
        response.storageType === "local";
      if (toUseLocalUpload) {
        let form = new FormData();
        form.append("file", file);
        uploadResponse = await this.$rest(
          "post",
          "evaluation_local_upload",
          form
        )
          .then(response => response)
          .catch(() => {
            this.batchTestLoading = false;
            this.$message.error("Error uploading file!");
          });
      } else {
        uploadResponse = await axios
          .put(response.uploadURL, file, {
            headers: {
              "content-type": "text/csv",
              "x-ms-blob-type": "BlockBlob"
            }
          })
          .then(response => response)
          .catch(() => {
            this.batchTestLoading = false;
            this.$message.error("Error uploading file!");
          });
      }

      if (uploadResponse.status === HTTP_STATUS.CREATED) {
        this.$message({
          type: "success",
          message: "File uploaded success!"
        });
        this.batchTestLoading = false;
        this.currentTestFile = fileName;
      } else {
        this.batchTestLoading = false;
        this.$message.error("Error uploading file!");
      }
    },
    downloadFile(data) {
      this.batchTestLoading = true;
      if (!this.currentTestFile) {
        this.$message.error(
          "File upload does not exist! Please ensure that you have uploaded a file"
        );
        this.batchTestLoading = false;
        return;
      }
      this.$rest("get", "download_file")
        .then(result => {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(result.data.testData);
          XLSX.utils.book_append_sheet(wb, ws, "test_set");
          XLSX.writeFile(wb, result.data.fileName, {});
          this.batchTestLoading = false;
        })
        .catch(() => {
          this.batchTestLoading = false;
          this.$message.error("Error downloading file!");
        });
    },
    async batchTestCSV() {
      this.batchTestLoading = true;
      const response = await this.$rest("post", "batch_test")
        .then(result => result)
        .catch(err => {
          this.batchTestLoading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error evaluating!`
          });
        });

      if (!response) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Error evaluating!`
        });
        return;
      }

      this.$notify.success({
        title: "Success",
        position: "bottom-right",
        message: `Evaluation success`
      });

      this.batchTestLoading = false;
      this.postBatchTestProcessing(response);
      this.fetchHistoryTests();
    },

    async topicBatchTestCSV() {
      this.batchTestLoading = true;
      const response = await this.$rest("post", "batch_test_topic")
        .then(result => result)
        .catch(err => {
          this.batchTestLoading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error evaluating!`
          });
        });

      if (!response) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Error evaluating!`
        });
        return;
      }

      this.$notify.success({
        title: "Success",
        position: "bottom-right",
        message: `Evaluation success`
      });

      this.batchTestLoading = false;
      this.postBatchTestProcessing(response);
      this.postBatchTestTopicProcessing(response);
      this.fetchHistoryTests();
    },

    async endToEndTopicBatchTestCSV() {
      this.batchTestLoading = true;
      const response = await this.$rest("post", "batch_test_topic_end_to_end")
        .then(result => result)
        .catch(err => {
          this.batchTestLoading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error evaluating!`
          });
        });

      if (!response) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Error evaluating!`
        });
        return;
      }

      this.$notify.success({
        title: "Success",
        position: "bottom-right",
        message: `Evaluation success`
      });

      this.batchTestLoading = false;
      this.postBatchTestProcessing(response);
      this.fetchHistoryTests();
    },

    selectTopic(topic) {
      this.selectedTopic = topic;
    },

    postBatchTestProcessing(response) {
      this.batchTestResultMap[response.batchTestName] =
        response.batchTestResult.testResult;
      this.selectedPastBatchTest = response.batchTestName;
      this.batchTestResult = response.batchTestResult.testResult;
      this.modelPredictionScore = this.calculateAccuracy(
        response.batchTestResult.departmentsScore,
        "department"
      );
      const answerScore = _.get(response, "batchTestResult.answerScore");
      if (answerScore) {
        this.answerScore = this.calculateAccuracy(answerScore, "department");
      }
    },

    postBatchTestTopicProcessing(response) {
      const topicsScore = _.get(response, "batchTestResult.topicsScore");
      if (topicsScore) {
        this.topicsScore = this.calculateAccuracy(topicsScore, "topic");
      }
    },

    async fetchHistoryTests() {
      const response = await this.$rest("get", "batch_test_history", {})
        .then(result => result)
        .catch(err => {
          this.$message.error("Error fetching past evaluations!");
        });
      this.pastBatchTests = response.data;
    },

    calculateAccuracy(scoreAggregation, resultType) {
      const scoreAggregationArr = [];
      Object.keys(scoreAggregation).forEach(key => {
        const { correct, incorrect } = scoreAggregation[key];

        scoreAggregation[key][resultType] = key;
        scoreAggregation[key]["accuracy"] = "0%";
        const total = correct + incorrect;
        if (correct + incorrect > 0) {
          const accuracy =
            Math.round(((correct * 1.0) / (correct + incorrect)) * 100 * 100) /
            100.0;
          scoreAggregation[key].accuracy =
            correct + "/" + total + " (" + accuracy + "%)";
        }
        if (!_.isEmpty(key)) {
          scoreAggregationArr.push(scoreAggregation[key]);
        }
      });
      return scoreAggregationArr;
    },

    async selectPastTest(name) {
      if (this.batchTestResultMap[name]) {
        this.batchTestResult = this.batchTestResultMap[name];
        this.answerScore = this.answerScoreMap[name];
        this.modelPredictionScore = this.modelPredictionScoreMap[name];
      } else {
        const response = await this.$rest("post", "batch_test_data", {
          name
        })
          .then(result => result)
          .catch(err => {
            console.log(err.message);
            this.$message.error("Error selecting past test!");
          });

        const responseBatchTestResult = JSON.parse(response.data.data);
        this.batchTestResultMap[name] = responseBatchTestResult.testResult;
        this.batchTestResult = responseBatchTestResult.testResult;

        this.modelPredictionScore = this.calculateAccuracy(
          responseBatchTestResult.departmentsScore,
          "department"
        );
        this.answerScore = this.calculateAccuracy(
          responseBatchTestResult.answerScore,
          "department"
        );

        this.answerScoreMap[name] = this.answerScore;
        this.modelPredictionScoreMap[name] = this.modelPredictionScore;
      }
      this.selectedDepartment = "Overall";
      this.selectedPastBatchTest = name;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    exportCSV() {
      if (this.selectedPastBatchTest === "") {
        this.$message.error("Select a evaluation result to export!");
        return false;
      }
      const wb = XLSX.utils.book_new();
      const ws_data = [];
      const overallAccuracy = _.find(this.modelPredictionScore, {
        department: "Overall"
      });

      const batchTestRow = this.batchTestResultMap[
        this.selectedPastBatchTest
      ][0];

      const defaultFields = [
        "department",
        "appSource",
        "role",
        "utterance",
        "expectedIntent",
        "intent1",
        "intent2",
        "expectedContentNodeId",
        "contentNodeId",
        "expectedEventId",
        "eventId",
        "entities",
        "intents",
        "formattedEntities"
      ];

      const additionalFields = Object.keys(_.omit(batchTestRow, defaultFields));

      ws_data.push([
        "Model Prediction Score",
        _.get(overallAccuracy, "accuracy"),
        "",
        "Answer (Content Node) Score",
        ""
      ]);
      ws_data.push([]);
      ws_data.push(
        [
          "Department",
          "App Source",
          "Role",
          "Utterance",
          "Expected Intent",
          "Top Predicted Intent",
          "2nd Top Predicted Intent",
          "Expected Content Node Id",
          "Predicted Content Node Id",
          "Expected Event Id",
          "Predicted Event Id",
          "Entities",
          "All Intents Predicted (By Descending Confidence Score)",
          "All Entities Detected"
        ].concat(additionalFields)
      );

      this.batchTestResultMap[this.selectedPastBatchTest].forEach(
        batchTestRow => {
          const {
            department,
            appSource,
            role,
            utterance,
            expectedIntent,
            intents,
            formattedEntities,
            entities,
            expectedContentNodeId,
            contentNodeId,
            expectedEventId,
            eventId
          } = batchTestRow;

          let allEntities = "";
          entities.forEach((entity, index) => {
            let type = _.get(entity, "type");
            let value = _.get(entity, "entity");
            if (!type) {
              type = _.get(entity, "entity");
              value = _.get(entity, "value");
            }

            allEntities =
              allEntities +
              type +
              " (" +
              value +
              ")" +
              (index + 1 === entities.length ? "" : ",");
          });

          let allIntents = "";
          intents.forEach((intent, index) => {
            let score = _.get(intent, "score");
            if (!score) {
              score = _.get(intent, "confidence");
            }

            const intentName =
              _.get(intent, "intent", "") || _.get(intent, "name", "");
            const trailingComma = index + 1 === intents.length ? "" : ",";

            score = Math.round(score * 100 * 100) / 100.0;
            allIntents = `${allIntents} ${intentName} (${score}%)${trailingComma}`;
          });

          allIntents = allIntents.trim();

          const intent1Name =
            _.get(batchTestRow, "intents[0].intent", "") ||
            _.get(batchTestRow, "intents[0].name", "");
          const intent2Name =
            _.get(batchTestRow, "intents[1].intent", "") ||
            _.get(batchTestRow, "intents[1].name", "");

          const additionalFieldValues = _.omit(batchTestRow, defaultFields);
          const additionalFieldValuesArr = additionalFields.map(field => {
            return additionalFieldValues[field];
          });

          const ws_row = [
            department,
            appSource,
            role,
            utterance,
            expectedIntent,
            intent1Name,
            intent2Name,
            expectedContentNodeId,
            contentNodeId,
            expectedEventId,
            eventId,
            formattedEntities,
            allIntents,
            allEntities
          ].concat(additionalFieldValuesArr);
          ws_data.push(ws_row);
        }
      );

      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, this.selectedPastbatchTest);

      XLSX.writeFile(
        wb,
        `${this.$store.state.brain}-${moment()
          .utcOffset(8)
          .format("YYYY-MM-DD")}-KeyReply.xlsx`,
        {}
      );
      return ws_data;
    },
    async exportAllPastResultsByModelPrediction() {
      const response = await this.$rest("get", "past_test_results?limit=none")
        .then(result => result)
        .catch(err => {
          console.log(err.message);
          this.$message.error("Error exporting past test results!");
        });
      const formattedDateTimeArr = [];
      const modelPredictionResultsByDate = this.processPastTestResultsByModelPrediction(
        response.pastTestResults,
        formattedDateTimeArr
      );

      return this.createAllPastResultsExcelFile(
        modelPredictionResultsByDate,
        formattedDateTimeArr,
        "Model Prediction"
      );
    },
    async exportAllPastResultsByAnswer() {
      const response = await this.$rest("get", "past_test_results?limit=none")
        .then(result => result)
        .catch(err => {
          console.log(err.message);
          this.$message.error("Error exporting past test results!");
        });

      const formattedDateTimeArr = [];
      const answerResultsByDate = this.processPastTestResultsByAnswer(
        response.pastTestResults,
        formattedDateTimeArr
      );

      return this.createAllPastResultsExcelFile(
        answerResultsByDate,
        formattedDateTimeArr,
        "Answer"
      );
    },
    processPastTestResultsByAnswer(pastTestResults, formattedDateTimeArr) {
      const resultsByDate = {};
      pastTestResults.forEach(pastTestResult => {
        const formattedDateTime = this.formatPastTestResultDate(
          pastTestResult.createdAt
        );
        formattedDateTimeArr.unshift(formattedDateTime);
        if (pastTestResult.answerScore) {
          const answerAccuracy = this.calculateAccuracy(
            pastTestResult.answerScore,
            "department"
          );
          this.formatPastTestResults(
            answerAccuracy,
            formattedDateTime,
            resultsByDate
          );
        }
      });
      return resultsByDate;
    },
    processPastTestResultsByModelPrediction(
      pastTestResults,
      formattedDateTimeArr
    ) {
      const resultsByDate = {};
      pastTestResults.forEach(pastTestResult => {
        const formattedDateTime = this.formatPastTestResultDate(
          pastTestResult.createdAt
        );
        formattedDateTimeArr.unshift(formattedDateTime);
        const modelPredictionAccuracy = this.calculateAccuracy(
          pastTestResult.departmentsScore,
          "department"
        );
        this.formatPastTestResults(
          modelPredictionAccuracy,
          formattedDateTime,
          resultsByDate
        );
      });
      return resultsByDate;
    },
    formatPastTestResultDate(timestamp) {
      const date = moment(timestamp)
        .utcOffset(8)
        .format("YYYY-MM-DD");
      const time = moment(timestamp)
        .utcOffset(8)
        .format("HH:mm:ss");
      const formattedDateTime = date + " " + time;
      return formattedDateTime;
    },
    formatPastTestResults(accuracyScore, formattedDateTime, resultsByDate) {
      accuracyScore.forEach(accuracyScore => {
        const { accuracy, department } = accuracyScore;
        resultsByDate[department] = Object.assign(
          {},
          resultsByDate[department],
          {
            department,
            [formattedDateTime]: accuracy
          }
        );
      });
    },
    createAllPastResultsExcelFile(resultsByDate, formattedDateTimeArr, type) {
      const wb = XLSX.utils.book_new();
      const ws_data = [];
      const header = [];
      header.push("Department");
      formattedDateTimeArr.forEach(formattedDateTime => {
        header.push(formattedDateTime);
      });

      ws_data.push(header);
      Object.keys(resultsByDate).forEach(department => {
        const row = [];
        row.push(department);
        formattedDateTimeArr.forEach(formattedDateTime => {
          row.push(resultsByDate[department][formattedDateTime]);
        });
        ws_data.push(row);
      });

      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, "PastTestResults");

      XLSX.writeFile(
        wb,
        `${this.$store.state.brain}-All Past Results (${type})-${moment()
          .utcOffset(8)
          .format("YYYY-MM-DD")}-KeyReply.xlsx`,
        {}
      );
      return ws_data;
    }
  }
});
</script>
<style scoped>
.el-upload {
  width: auto !important;
}
.el-upload__tip {
  padding-left: 20px;
}
/* .topic-button-divider {
  display: inline-block;
  width: 2px;
  height: 35px;
  margin-left: 20px;
  vertical-align: middle;
  position: relative;
} */
</style>

import { TransactionState } from "./types";

const state: TransactionState = {
  transactions: [],
  transactionsMeta: [],
  currentType: "",
  currentRow: null,
};

export default state;

<template>
  <div style="height: 100%">
    <el-row type="flex" :gutter="20" style="height: 100%">
      <el-col
        :span="10"
        style="
          max-width: 450px;
          display: flex;
          flex-direction: column;
          padding: 0;
        "
      >
        <el-card style="height: 100%" class="agent-session-list">
          <ChatSearchBar
            class="search-bar-container"
            placeholder="Search by name or email"
          />
          <AgentSessionList
            style="width: 100%"
            class="agent-session-list-container"
          />
        </el-card>
      </el-col>
      <el-col :span="14" style="width: 100%">
        <el-card class="agent-session-chat-details">
          <el-row
            style="margin-bottom: 0"
            v-for="(message, index) in chatInteractions"
            :key="index"
          >
            <!-- CHAT MESSAGE -->
            <div
              :style="{
                width: '50%',
                float: isMessageByUser(message.type) ? 'left' : 'right',
              }"
            >
              <span>
                <message
                  :key="message.RowKey"
                  :message="message"
                  :has-more-than-one-agent-in-chat="hasMoreThanOneAgentInChat"
                  font="Tahoma"
                  agent-bubble-color="#F0F0F0"
                  agent-text-color="#4b4f56"
                  user-bubble-color="#0084FF"
                  user-text-color="#fff"
                  button-outline-color="black"
                />
              </span>
            </div>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isMessageByUser } from "@/helperMethods/livechat/util";
import Message from "@/components/ChatInteractions/Message/Index";
import ChatSearchBar from "@/views/Supervisor/ChatSearchBar";
import AgentSessionList from "@/views/Supervisor/AgentSessionList";

export default {
  components: { Message, AgentSessionList, ChatSearchBar },
  data() {
    return {
      chatInteractions: [],
    };
  },
  props: {},
  computed: {
    ...mapGetters("supervisor", ["selectedChatSession"]),
  },
  methods: {
    isMessageByUser,
    updateChatInteractions() {
      let interactionsFromSelectedChat = _.get(
        this.selectedChatSession,
        "interactions",
        []
      );

      if (typeof interactionsFromSelectedChat === "string") {
        interactionsFromSelectedChat = JSON.parse(interactionsFromSelectedChat);
      }
      this.chatInteractions = _.chain(interactionsFromSelectedChat)
        .uniqBy((interaction) => interaction.RowKey)
        .orderBy(["date_created"], ["asc"])
        .value();
    },
    /**
     * @description Has more than 1 agent in chat, analog to Chat Interaction component
     * @return {boolean}
     */
    hasMoreThanOneAgentInChat() {
      return _.get(this.selectedChatSession, "agents", []).length > 1;
    },
  },
  watch: {
    selectedChatSession() {
      this.updateChatInteractions();
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-session-list {
  ::v-deep .el-card__body {
    padding: 0;
  }
}

.search-bar-container {
  ::v-deep .el-input__inner {
    border: 0;
  }

  border-bottom: 1px solid #e6ebf5;
}

.agent-session-list-container {
  padding: 0;
}

.agent-session-details {
  ::v-deep .el-card__body {
    padding: 0;
  }
}

.agent-session-chat-details {
  height: 100%;
  overflow-y: auto;
}
</style>

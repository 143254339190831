<template>
  <el-card v-if="form" class="box-card" style="margin-bottom: 15px;">
    <el-button
      @click.prevent="$emit('onDeleteTableAggregation')"
      plain
      circle
      class="position-absolute-top-right"
      type="danger"
      size="mini"
      icon="el-icon-delete"
      v-if="canEdit"
    ></el-button>

    <el-row :gutter="12" style="margin-bottom: 0;">
      <el-col :xs="24">
        <el-tag size="mini">{{ form.dataType }}</el-tag>
      </el-col>

      <!-- field display value / placeholder start -->
      <el-col :xs="24" :sm="24" :md="12">
        <el-input
          @change="$emit('onTableAggregationDisplayValueChanged')"
          :disabled="!canEdit"
          size="mini"
          v-model="form.displayValue"
        >
          <template slot="prepend">Name</template>
        </el-input>
      </el-col>
      <!-- field display value / placeholder end -->

      <!-- field column width start -->
      <el-col :xs="24" :sm="24" :md="12" v-if="form.dataType.toLowerCase() !== 'array'">
        <el-input :disabled="!canEdit" size="mini" v-model="form.columnWidth">
          <template slot="prepend">Column Width</template>
        </el-input>
      </el-col>
      <!-- field column width end -->
    </el-row>
    <el-row :gutter="12" style="margin-bottom: 0;">
      <el-col :xs="24">
        <component :is="getComponentName(form.dataType)" :canEdit="canEdit" :field="form"></component>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import Vue from "vue";
import TypeComponentString from "../String";
import TypeComponentList from "../List";
import TypeComponentNumber from "../Number";
import TypeComponentDatetime from "../Datetime";
import TypeComponentCurrency from "../Currency";

export default Vue.extend({
  props: ["aggregation", "canEdit"],
  data() {
    return {
      form: null
    };
  },
  mounted() {
    this.form = this.aggregation;
  },
  methods: {
    /**
     * @description Get component name for the dataType
     * @return {string}
     */
    getComponentName(name) {
      const _capitalize = _.capitalize(name);
      return "TypeComponent" + _capitalize;
    },

    onDelete() {
      delete this.headers[this.headerIndex];
    }
  },
  components: {
    TypeComponentString,
    TypeComponentNumber,
    TypeComponentDatetime,
    TypeComponentList,
    TypeComponentCurrency
  }
});
</script>
<template>
  <div>
    <div class="node-sub-heading">If bot recognises:</div>
    <el-form-item v-for="(cond, index) in conditionArray" :key="index">
      <el-col :span="4">
        <el-select
          v-if="cond.condition"
          v-model.trim="cond.condition"
          :disabled="!authorized"
          style="margin-bottom:5px;"
          placeholder="select"
          @change="setEdited('condition.condition', true);changeCondition(cond.text)"
        >
          <el-option label="and" value="&&" />
          <el-option label="or" value="||" />
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-autocomplete
          v-model.trim="cond.text"
          popper-class="mcr-autocomplete"
          :fetch-suggestions="searchIntent"
          :disabled="!authorized"
          style="margin-bottom:5px;"
          @input="setEdited('condition.text', true);"
          @select="setEdited('condition.text', true);"
          @blur="changeConditionText(cond.text, index)"
        />
      </el-col>
      <el-col :span="4">
        <el-button
          :disabled="!authorized"
          circle
          icon="el-icon-minus"
          @click="setEdited('condition', true);removeCondition(index)"
        />
        <el-button
          v-if="index === conditionArray.length - 1"
          :disabled="!authorized"
          circle
          icon="el-icon-plus"
          @click="setEdited('condition', true);addCondition()"
        />
      </el-col>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "NodeConditions",
  props: {
    nodeForm: Object,
    authorized: Boolean,
    searchIntent: Function
  },
  data() {
    return {
      conditionArray: []
    };
  },
  computed: {},
  watch: {
    nodeForm: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setConditionArray();
      }
    }
  },
  mounted() {
    this.setConditionArray();
  },
  methods: {
    setEdited(nodeVariableType, isEdited) {
      this.$set(this.$store.state.watson, "isEditedObject", {
        [nodeVariableType]: isEdited
      });
    },
    /* GROUP - CONDITION */
    changeCondition(text) {
      if (text !== "") {
        this.$set(this.nodeForm, "conditionArray", this.conditionArray);
        this.$emit("updateNode-click", this.nodeForm);
      }
    },
    changeConditionText(text, index) {
      if (text.text !== "") {
        this.$set(this.nodeForm, "conditionArray", this.conditionArray);
        this.$emit("updateNode-click", this.nodeForm);
      } else {
        this.removeCondition(index);
      }
    },
    /* GROUP - CONDITION AND MCR POPUP RESPONSES */
    addCondition() {
      this.conditionArray.push({
        text: "",
        condition: "&&"
      });
    },
    removeCondition(index) {
      if (index === 0 && this.conditionArray.length === 1) {
        this.conditionArray[index].text = "";
      } else if (index === 0) {
        this.conditionArray.splice(0, 1);
        this.conditionArray[0].condition = null;
      } else {
        this.conditionArray.splice(index, 1);
      }
      this.$set(this.nodeForm, "conditionArray", this.conditionArray);
      this.$emit("updateNode-click", this.nodeForm);
    },
    setConditionArray() {
      this.conditionArray = [];
      if (
        this.nodeForm.conditions &&
        (this.nodeForm.conditions.includes("&") ||
          this.nodeForm.conditions.includes("|"))
      ) {
        let tmp = "",
          j = 0;
        for (let i = 0; i < this.nodeForm.conditions.length; i++) {
          if (
            (this.nodeForm.conditions[i] === "&" &&
              this.nodeForm.conditions[i + 1] === "&") ||
            (this.nodeForm.conditions[i] === "|" &&
              this.nodeForm.conditions[i + 1] === "|")
          ) {
            if (this.conditionArray.length === 0) {
              this.conditionArray.push({
                text: tmp.trim(),
                condition: null
              });
            } else {
              this.conditionArray[j].text = tmp.trim();
            }
            j++;
            this.conditionArray.push({
              text: "",
              condition:
                this.nodeForm.conditions[i] + this.nodeForm.conditions[i]
            });
            tmp = "";
            i++;
            i++;
          }
          tmp += this.nodeForm.conditions[i];
        }
        this.conditionArray[j].text = tmp;
      } else if (this.nodeForm.conditions) {
        this.conditionArray.push({
          text: this.nodeForm.conditions,
          condition: null
        });
      } else {
        this.conditionArray.push({
          text: "",
          condition: null
        });
      }
      //fixed conditionArray not defined error
      this.$set(this.nodeForm, "conditionArray", this.conditionArray);
    }
  }
};
</script>

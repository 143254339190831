<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane
      v-for="(title, index) in metaTransactions"
      :key="index"
      style="overflow: hidden"
      :label="title.title"
      :name="title.title"
      v-loading="tabLoading"
    >
      <el-row type="flex" align="center" justify="space-between">
        <el-col>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-refresh"
            @click="refreshTableData"
            >Refresh</el-button
          >
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-folder-checked"
            @click="saveAll"
            >Save</el-button
          >
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-download"
            @click="exportCSV"
            >Export</el-button
          >
          <ActionButtonImport
            @setBusy="tableLoading = $event"
            :is-busy="tableLoading"
          />
        </el-col>

        <MetaDialog />
      </el-row>

      <el-collapse>
        <el-collapse-item
          title="Filter"
          name="1"
          v-if="Object.keys(filters).length !== 0"
        >
          <el-row :gutter="5">
            <el-col :xs="24" :md="8">
              <span class="text-option-sub-title">Request Date:</span>
              <el-date-picker
                v-model="filterDateRange"
                type="daterange"
                format="dd/MM/yyyy"
                style="width: 100%"
                align="right"
                unlink-panels
                range-separator="-"
                start-placeholder="Request Date From"
                end-placeholder="Request Date To"
                :picker-options="pickerOptions"
                size="mini"
              ></el-date-picker>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="4"
              v-for="(meta, name, index) in filters"
              :key="index"
            >
              <span
                class="text-option-sub-title-red"
                v-if="meta.dataType === 'list'"
                >{{ meta.displayValue }}:</span
              >
              <el-select
                v-if="meta.dataType === 'list'"
                v-model="filtersModel[name]"
                class="controll-margin"
                style="width: 100%"
                clearable
                filterable
                size="mini"
                :collapse-tags="true"
                :placeholder="meta.displayValue"
              >
                <el-option
                  v-for="tag in meta.listValues"
                  :key="tag"
                  :label="tag"
                  :value="tag"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="8" :xs="24">
              <el-input
                v-model="searchText"
                placeholder="Search for record"
                class="search-bar controll-margin"
                prefix-icon="el-icon-search"
                size="mini"
                clearable
              />
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="2">
              <el-tooltip content="Clear Filters" placement="top">
                <el-button
                  @click="clearFilter"
                  class="exportAddNewBtn importExportBtn"
                  plain
                  size="mini"
                  >Clear Filters</el-button
                >
              </el-tooltip>
            </el-col>

            <!-- <el-col :span="2">
            <el-tooltip content="Apply Filters" placement="top">
              <el-button
                @click="applyFilter"
                class="exportAddNewBtn importExportBtn"
                plain
              >Apply Filters</el-button>
            </el-tooltip>
            </el-col>-->
          </el-row>
        </el-collapse-item>
        <el-collapse-item
          title="Bulk Update"
          name="2"
          v-if="Object.keys(bulkUpdateFields).length !== 0"
        >
          <el-row v-for="(meta, name) in bulkUpdateFields" :key="name">
            <span class="text-option-sub-title-red"
              >{{ meta.displayValue }}:</span
            >
            <div>
              <el-date-picker
                v-if="meta.dataType === 'datetime'"
                v-model="bulkUpdateModel[name]"
                style="width: 15%"
                size="mini"
                type="date"
                format="dd/MM/yyyy"
                placeholder="Delivery Date"
              />
              <el-select
                v-if="meta.dataType === 'list'"
                v-model="bulkUpdateModel[name]"
                clearable
                filterable
                style="width: 15%"
                size="mini"
                :collapse-tags="true"
                :placeholder="meta.displayValue"
              >
                <el-option
                  v-for="tag in meta.listValues"
                  :key="tag"
                  :label="tag"
                  :value="tag"
                />
              </el-select>
              <el-button
                style="width: 10%; margin-left: 5px"
                class="exportAddNewBtn importExportBtn"
                plain
                size="mini"
                @click="updateSelectedRowsDelivery(name)"
                >Update</el-button
              >
            </div>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <el-row>
        <el-col>
          <br />
          <el-row type="flex" justify="space-between" align="center">
            <el-pagination
              background
              small
              layout="prev, pager, next"
              :pageSize="pageSize"
              :current-page.sync="currentPage"
              :total="noOfItems"
              @prev-click="togglePage"
              @next-click="togglePage"
              @current-change="togglePage"
              @size-change="handleSizeChange"
              style="display: inline-block"
            ></el-pagination>
            <span class="total">{{ noOfItems }} records</span>
          </el-row>
        </el-col>

        <el-col>
          <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisible">
            <el-table
              :data="dialogTableData"
              header-cell-class-name="transaction-table"
              cell-class-name="transaction-table"
              :row-key="(row, index) => index"
              style="width: 100%"
              highlight-current-row
            >
              <el-table-column
                class-name="transaction-table"
                header-align="left"
                :label="headerValue.displayValue"
                v-for="(headerValue, name, index) in dialogTableHeaders"
                :key="index"
                :min-width="
                  headerValue.columnWidth ? headerValue.columnWidth : '100px'
                "
              >
                <template slot-scope="scope" v-if="headerValue.visible">
                  <el-select
                    v-if="
                      scope.row.edit &&
                      headerValue.dataType === 'list' &&
                      headerValue.editable
                    "
                    v-model="scope.row[name]"
                    class="controll-margin"
                    style="width: 100%"
                    filterable
                    :collapse-tags="true"
                    :clearable="headerValue.closable"
                    :placeholder="headerValue.displayValue"
                  >
                    <el-option
                      v-for="tag in headerValue.listValues"
                      :key="tag"
                      :label="tag"
                      :value="tag"
                    />
                  </el-select>
                  <el-date-picker
                    v-model="scope.row[name]"
                    v-else-if="
                      scope.row.edit &&
                      headerValue.dataType === 'datetime' &&
                      headerValue.editable
                    "
                    type="date"
                    format="dd/MM/yyyy"
                    :placeholder="headerValue.displayValue"
                  ></el-date-picker>
                  <el-input-number
                    v-model="scope.row[name]"
                    v-else-if="
                      scope.row.edit &&
                      headerValue.dataType === 'number' &&
                      headerValue.editable
                    "
                    :min="headerValue.min"
                    :max="headerValue.max"
                    :placeholder="headerValue.displayValue"
                    controls-position="right"
                  ></el-input-number>
                  <el-input
                    style="word-break: break-word !important"
                    v-model="scope.row[name]"
                    v-else-if="
                      scope.row.edit &&
                      headerValue.dataType === 'string' &&
                      headerValue.multiline &&
                      headerValue.editable
                    "
                    type="textarea"
                    :rows="2"
                    :placeholder="headerValue.displayValue"
                  ></el-input>
                  <el-input
                    v-model="scope.row[name]"
                    v-else-if="
                      scope.row.edit &&
                      headerValue.dataType === 'string' &&
                      headerValue.editable
                    "
                    :placeholder="headerValue.displayValue"
                  ></el-input>
                  <div v-else>
                    {{
                      headerValue.dataType === "datetime"
                        ? getProperDate(scope.row[name])
                        : scope.row[name]
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div
              v-for="(aggregator, key, index) in formattedAggregators"
              :key="index"
            >
              <label>
                <b>{{ aggregator.displayValue }}</b>
                {{
                  aggregator.prefix
                    ? aggregator.prefix + formatAggregator(aggregator.value)
                    : formatAggregator(aggregator.value)
                }}
              </label>
            </div>
          </el-dialog>
          <el-table
            header-cell-class-name="transaction-table"
            cell-class-name="transaction-table"
            ref="transactions"
            :data="pagedTransaction"
            :row-key="(row, index) => index"
            style="width: 100%"
            highlight-current-row
            @row-click="handleCurrentChange"
            @selection-change="handleSelectionChange"
            v-loading="tableLoading"
          >
            <el-table-column
              class-name="request-column"
              type="selection"
              width="35"
            ></el-table-column>

            <el-table-column header-align="left" label="ID">
              <template slot-scope="scope">
                <div>{{ scope.row.id }}</div>
              </template>
            </el-table-column>

            <el-table-column
              header-align="left"
              label="Request Date"
              min-width="120px"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.createdAt }}</div>
              </template>
            </el-table-column>

            <el-table-column
              class-name="transaction-table"
              header-align="left"
              :label="meta.displayValue"
              v-for="(meta, name, index) in metaData.fields"
              :key="index"
              :min-width="meta.columnWidth ? meta.columnWidth : '100px'"
            >
              <template slot-scope="scope" v-if="meta.visible">
                <el-select
                  v-if="
                    scope.row.edit && meta.dataType === 'list' && meta.editable
                  "
                  v-model="scope.row.data[name]"
                  class="controll-margin"
                  style="width: 100%"
                  filterable
                  :collapse-tags="true"
                  :clearable="meta.closable"
                  :placeholder="meta.displayValue"
                >
                  <el-option
                    v-for="tag in meta.listValues"
                    :key="tag"
                    :label="tag"
                    :value="tag"
                  />
                </el-select>
                <el-date-picker
                  v-model="scope.row.data[name]"
                  v-else-if="
                    scope.row.edit &&
                    meta.dataType === 'datetime' &&
                    meta.editable
                  "
                  type="date"
                  format="dd/MM/yyyy"
                  :placeholder="meta.displayValue"
                ></el-date-picker>
                <el-input-number
                  v-model="scope.row.data[name]"
                  v-else-if="
                    scope.row.edit &&
                    meta.dataType === 'number' &&
                    meta.editable
                  "
                  :min="meta.min"
                  :max="meta.max"
                  :placeholder="meta.displayValue"
                  controls-position="right"
                ></el-input-number>
                <el-input
                  style="word-break: break-word !important"
                  v-model="scope.row.data[name]"
                  v-else-if="
                    scope.row.edit &&
                    meta.dataType === 'string' &&
                    meta.multiline &&
                    meta.editable
                  "
                  type="textarea"
                  :rows="2"
                  :placeholder="meta.displayValue"
                ></el-input>
                <el-input
                  v-model="scope.row.data[name]"
                  v-else-if="
                    scope.row.edit &&
                    meta.dataType === 'string' &&
                    meta.editable
                  "
                  :placeholder="meta.displayValue"
                ></el-input>
                <el-button
                  type="danger"
                  v-else-if="meta.dataType === 'array'"
                  @click="displayDialog(meta, scope.row.data[name])"
                  size="mini"
                  >View More</el-button
                >
                <div v-else>
                  {{
                    meta.dataType === "datetime"
                      ? getProperDate(scope.row.data[name])
                      : scope.row.data[name]
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import {
  exportToCSV,
  pickerOptions,
  bulkUploadPayload,
  formatAggregator,
} from "@/helperMethods/transaction";
import XLSX from "xlsx";
import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";
import MetaDialog from "./MetaDialog/Index.vue";
import ActionButtonImport from "./Import.vue";

export default Vue.extend({
  components: {
    MetaDialog,
    ActionButtonImport,
  },
  data() {
    return {
      dialogTableAggregations: {},
      dialogTableVisible: false,
      dialogTitle: "",
      dialogTableData: [],
      dialogTableHeaders: {},
      activeName: _.get(
        this.$store.state.transaction.transactionsMeta[0],
        "title"
      ),
      selectedTabIndex: 0,
      searchText: "",
      loading: false,
      metaData: null,
      tabLoading: false,
      tableLoading: false,
      fromDate: "",
      toDate: "",
      isBulkUpdate: false,
      bulkUpdateFields: {},
      bulkUpdateModel: {},
      pickerOptions,
      filterData: {},
      filterDateRange: "",
      updateDate: "",
      updateDeliveryStatus: "",
      selectedRows: [],
      filteredTransactions: [],
      pagedTransaction: [],
      editedTransactions: [],
      currentTypeLabel: "",
      filters: {},
      filtersModel: {},
      pageSize: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(["currentType", "currentRow"]),
    noOfItems() {
      this.applyFilter();
      this.currentPage = 1;
      return this.filteredTransactions.length;
    },
    formattedAggregators() {
      return this.formatAggregators(
        this.dialogTableAggregations,
        this.dialogTableData
      );
    },
    metaTransactions() {
      const metaTransactionArray = this.$store.state.transaction
        .transactionsMeta;
      if (metaTransactionArray.length > 0) {
        if (this.currentType === "") {
          const type = _.get(metaTransactionArray[0], "type");
          this.$store.commit("SET_CURRENT_TYPE", type);
          this.getTransactions(this.currentType);
        }
        const currentMetaTransaction = _.filter(metaTransactionArray, (ele) => {
          return ele.type === this.currentType;
        });
        this.filters = {};
        this.activeName = _.get(currentMetaTransaction[0], "title");
        this.metaData = JSON.parse(
          _.get(currentMetaTransaction[0], "metaData")
        );
        this.getDynamicObject();
        this.selectedTabIndex = 0;
        return this.$store.state.transaction.transactionsMeta;
      } else {
        return [];
      }
    },
    finalTransactions() {
      this.tableLoading = false;
      const transactions = this.$store.state.transaction.transactions;
      const processedTransactions = _.chain(transactions)
        .map((transaction) => {
          try {
            if (transaction.data) {
              const data = JSON.parse(transaction.data);
              return {
                data: data,
                createdAt: moment(transaction.createdAt).format("DD/MM/YYYY"),
                edit: false,
                id: transaction.id,
              };
            }
          } catch (err) {
            return;
          }
        })
        .compact()
        .value();
      return _.orderBy(processedTransactions);
    },
  },
  methods: {
    formatAggregator,
    formatAggregators(dialogTableAggregations, dialogTableData) {
      return _.reduce(
        dialogTableAggregations,
        (accum, value, key) => {
          const aggregator = value.aggregator;
          const split = aggregator.split(" ");
          let previous;
          let previousOperator;
          split.forEach((variableNumberOperator, index) => {
            const isOperator = this.evaluateOperator(variableNumberOperator);

            if (isOperator) {
              previousOperator = variableNumberOperator;
              return;
            }

            const operand =
              (!isNaN(variableNumberOperator) && variableNumberOperator) ||
              (accum[variableNumberOperator] &&
                accum[variableNumberOperator].value) ||
              _.chain(dialogTableData)
                .filter((row) => row[variableNumberOperator])
                .map((row) => row[variableNumberOperator])
                .value();
            if (!previous) {
              previous = operand;
              return;
            }

            if (previous && previousOperator) {
              previous = this.evaluateOperation(
                previous,
                operand,
                previousOperator
              );
            }
          });
          if (value.ceiling && previous > value.ceiling) {
            previous = value.ceiling;
          }

          if (value.dataType === "currency") {
            previous = parseFloat(this.formatAggregator(previous));
          }
          accum[key] = Object.assign({}, value, { value: previous });
          return accum;
        },
        {}
      );
    },
    evaluateArithmetic(previous, operand, previousOperator) {
      if (previousOperator === "+") {
        return previous + operand;
      } else if (previousOperator === "-") {
        return previous - operand;
      } else if (previousOperator === "*") {
        return previous * operand;
      } else if (previousOperator === "/") {
        return previous / operand;
      }
    },
    evaluateOperation(previous, operand, previousOperator) {
      const isPreviousArray = Array.isArray(previous);
      const isOperandArray = Array.isArray(operand);
      if (!isPreviousArray && !isOperandArray) {
        return this.evaluateArithmetic(previous, operand, previousOperator);
      } else if (isPreviousArray && !isOperandArray) {
        const previous = _.reduce(
          previous,
          (accum, value) => {
            accum += value;
            return accum;
          },
          0
        );
        return this.evaluateArithmetic(previous, operand, previousOperator);
      } else if (!isPreviousArray && isOperandArray) {
        const operand = _.reduce(
          operand,
          (accum, value) => {
            accum += value;
            return accum;
          },
          0
        );
        return this.evaluateArithmetic(previous, operand, previousOperator);
      } else {
        const result = _.chain(previous)
          .map((previous, index) => {
            return this.evaluateArithmetic(
              previous,
              operand[index],
              previousOperator
            );
          })
          .sum()
          .value();
        return result;
      }
    },

    evaluateOperator(variableNumberOperator) {
      const isOperator =
        variableNumberOperator === "+" ||
        variableNumberOperator === "-" ||
        variableNumberOperator === "/" ||
        variableNumberOperator === "*";
      return isOperator;
    },
    displayDialog(meta, tableData) {
      this.dialogTitle = meta.tableTitle;
      const tableAggregations = meta.tableAggregations;
      this.dialogTableData = tableData;
      this.dialogTableHeaders = meta.tableHeaders;
      this.dialogTableVisible = true;
      this.dialogTableAggregations = tableAggregations;
    },
    refreshTableData() {
      this.tableLoading = true;
      this.getTransactions(this.currentType);
    },
    getTransactions(type, update = false) {
      if (!type) {
        return;
      }
      this.$store
        .dispatch("FETCH_TRANSACTIONS", {
          type: type,
        })
        .then((val) => {
          this.$store.commit("SET_TRASACTIONS", val);
          this.tableLoading = false;
          if (update) {
            this.$message({
              type: "success",
              message: "The transaction has been updated successfully.",
            });
          }
          this.currentPage = 1;
        })
        .catch((err) => {
          this.tableLoading = false;
          this.$message({
            type: "error",
            message: "Error fetching transaction.",
          });
        });
    },
    getDynamicObject() {
      _.forEach(this.metaData.fields, (field, key) => {
        if (field.dataType === "list") {
          this.filterData[key] = field.listValues;
        }
        // filter object
        _.forEach(this.metaData.filters, (filter) => {
          if (filter === key) {
            this.filters[key] = field;
            if (!this.filtersModel[key]) {
              this.$set(this.filtersModel, key, "");
            }
          }
        });
        // bulk update object
        _.forEach(this.metaData.bulkupdate_fields, (bulkUpdate) => {
          this.isBulkUpdate = true;
          if (bulkUpdate === key) {
            this.bulkUpdateFields[key] = field;
            if (!this.bulkUpdateModel[key]) {
              this.$set(this.bulkUpdateModel, key, "");
            }
          }
        });
      });
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.refreshTable();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.refreshTable();
    },
    refreshTable() {
      const startingIndex = (this.currentPage - 1) * this.pageSize;
      const currentIndex = this.currentPage * this.pageSize;
      const totalTransactions = this.filteredTransactions.length;
      this.pagedTransaction = this.filteredTransactions.slice(
        startingIndex,
        totalTransactions >= currentIndex ? currentIndex : totalTransactions
      );
    },
    applyFilter() {
      this.filteredTransactions = _.filter(this.finalTransactions, (ele) => {
        const flag = true;
        if (this.searchText) {
          const dataString = JSON.stringify(ele.data);
          if (
            !dataString.toLowerCase().includes(this.searchText.toLowerCase())
          ) {
            return false;
          }
        }

        // Loop through bulkUpdateFields for filter
        const bulkUpdateFields = Object.keys(this.bulkUpdateFields);
        for (let key in bulkUpdateFields) {
          const isFilteredStatusBlanked =
            !this.filtersModel[bulkUpdateFields[key]] ||
            this.filtersModel[bulkUpdateFields[key]] === "";
          const isStatusDoesntMatched =
            this.filtersModel[bulkUpdateFields[key]] !==
            ele.data[bulkUpdateFields[key]];

          if (isStatusDoesntMatched && !isFilteredStatusBlanked) {
            return false;
          }
        }

        if (this.filterDateRange) {
          const createdAt = moment(ele.createdAt, "DD-MM-YYYY");
          const filterDateFrom = moment(this.filterDateRange[0]);
          const filterDateTo = moment(this.filterDateRange[1]);
          const lowerDateRange = createdAt.isSameOrAfter(filterDateFrom);
          const upperDateRange = createdAt.isSameOrBefore(filterDateTo);
          if (!(lowerDateRange && upperDateRange)) {
            return false;
          }
        }
        return true;
      });
      this.refreshTable();
    },
    clearFilter() {
      _.forEach(this.filtersModel, (ele, key) => {
        this.filtersModel[key] = "";
      });
      this.filterDateRange = "";
      this.searchText = "";
      this.refreshTable();
    },
    handleClick(tab) {
      const index = parseInt(tab.index, 10);
      this.activeName = _.get(
        this.$store.state.transaction,
        `transactionsMeta[${index}].title`
      );
      this.metaData = JSON.parse(
        _.get(
          this.$store.state.transaction,
          `transactionsMeta[${index}].metaData`
        )
      );
      this.$store.commit(
        "SET_CURRENT_TYPE",
        _.get(this.$store.state.transaction, `transactionsMeta[${index}].type`)
      );
      this.bulkUpdateModel = {};
      this.bulkUpdateFields = {};
      this.filters = {};
      this.getTransactions(this.currentType);
      this.selectedTabIndex = index;
    },
    handleCurrentChange(row) {
      if (row) {
        const transaction = _.filter(this.editedTransactions, (ele) => {
          return ele.id === row.id;
        });
        if (transaction.length === 0) {
          this.editedTransactions.push(row);
        }
        // this.currentRow = row;
        this.$store.commit("SET_CURRENT_ROW", row);
        _.forEach(this.$refs.transactions[0].data, (ele) => {
          ele.edit = false;
        });
        row.edit = true;
      }
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows;
    },
    updateSelectedRows() {
      if (this.selectedRows.length <= 0) {
        this.$message({
          type: "error",
          message: "Please select transactions from table.",
        });
        return;
      }
      const payload = bulkUploadPayload(
        this.selectedRows,
        this.bulkUpdateModel
      );
      this.updateTransaction(payload, true);
    },
    updateSelectedRowsDelivery(statusToUpdate) {
      if (this.selectedRows.length <= 0) {
        this.$message({
          type: "error",
          message: "Please select transactions from table.",
        });
        return;
      }
      const payload = bulkUploadPayload(this.selectedRows, {
        name: statusToUpdate,
        value: this.bulkUpdateModel[statusToUpdate],
      });
      this.updateTransaction(payload);
    },
    updateTransaction(payload, saveAll = false) {
      this.tabLoading = true;
      this.$store
        .dispatch("UPDATE_TRANSACTIONS", {
          payload,
        })
        .then(() => {
          if (saveAll) {
            this.editedTransactions = [];
          }
          this.tabLoading = false;
          this.tableLoading = true;
          this.getTransactions(this.currentType, true);
        })
        .catch((err) => {
          this.tabLoading = false;
          this.$message({
            type: "error",
            message: "The transaction update failed.",
          });
        });
    },
    saveAll() {
      if (this.editedTransactions.length <= 0) {
        this.$message({
          type: "error",
          message: "Please select transactions from table.",
        });
        return;
      }
      const firstTransactionData = _.get(this.$refs.transactions[0].data);
      _.forEach(firstTransactionData, (ele) => {
        ele.edit = false;
      });
      const payload = _.map(this.editedTransactions, (row) => {
        if (row.data["verificationStatus"] === "REJECTED") {
          row.data["deliveryStatus"] = row.data["verificationStatus"];
        }
        return {
          data: JSON.stringify(row.data),
          whereClause: { id: row.id },
        };
      });
      this.updateTransaction(payload, true);
    },
    exportCSV() {
      let filteredTransactionsWithSummary = _.clone(this.filteredTransactions);
      for (let transactionKey in filteredTransactionsWithSummary) {
        if (_.has(this.metaData.fields, "orders.tableAggregations")) {
          let transaction =
            filteredTransactionsWithSummary[transactionKey].data;

          transaction.summary = this.formatAggregators(
            this.metaData.fields.orders.tableAggregations,
            transaction.orders
          );
        }
      }

      const ws_data = exportToCSV(
        this.currentTypeLabel,
        this.metaData.fields,
        filteredTransactionsWithSummary
      );

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, "Transactions");

      const filename = this.$exportFilename("transactions", "xlsx");
      XLSX.writeFile(wb, filename, {});
    },
    getProperDate(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY");
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("FETCH_TRANSACTIONS_META").catch((error) => {
      this.$notify.error({
        title: "Error",
        position: "bottom-right",
        message: `Error while fetching transactions metadata.`,
      });
    });
  },
});
</script>
<style>
.request-column .cell {
  text-overflow: clip;
}

.transaction-table .cell {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: normal !important;
}
</style>
<style scoped>
.sub-heading {
  font-size: 18px;
  border-top: 0;
  border-bottom: 0;
  margin-bottom: 10px 0;
  padding: 0 0 8px 0;
}
.el-table td,
.el-table th {
  padding: 5px 0;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.text-option-sub-title {
  font-weight: normal;
  font-size: 10pt;
}
.text-option-sub-title-red {
  font-weight: normal;
  font-size: 10pt;
  color: red;
}
/* .el-button {
  width: 100%;
  padding: 12px 3px;
} */

.el-pagination.is-background .el-pager li.active {
  background-color: #e4392b !important;
}

.block {
  display: -webkit-inline-box;
  min-width: 35.5px;
}

.total {
  color: #e4392b;
  font-size: 12px;
  vertical-align: sub;
  display: inline-block;
}

.block .total {
  color: #e4392b;
  font-size: 12px;
  vertical-align: sub;
}

.search-bar {
  transition-property: width;
  transition-duration: 0.5s;
  /* max-width: 500px; */
  margin-right: 10px;
}
</style>

<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <h3 style="line-height: 36px;">Scheduled posts</h3>
        <div>
          <el-button-group>
            <el-button
              :disabled="firstweek"
              icon="el-icon-arrow-left"
              @click="subtractFutureDates"
            />
            <el-button
              v-for="date in futureDates"
              :key="date.format('YYYY-MM-DD')"
              :type="isActive(date.format('YYYY-MM-DD'))"
              style="width: 80px;"
            >
              <router-link :to="{ path: '/subs/' + date.format('YYYY-MM-DD') }">
                {{ date.format("MMM DD") }}
                <el-badge
                  :is-dot="!!posts[date.format('YYYY-MM-DD')]"
                  style="margin-top:-10px;margin-left:-4px;"
                />
              </router-link>
            </el-button>
            <el-button icon="el-icon-arrow-right" @click="addFutureDates" />
          </el-button-group>
        </div>
      </div>

      <el-card>
        <div slot="header">
          <span style="line-height: 32px; font-size: 24px;">
            <strong>{{ currentDate | moment("MMMM DD") }}</strong>
            <br />
            <small>Sending {{ currentDate | fromHour(sendingTime) }}</small>
          </span>
          <div style="float: right;">
            <el-button type="primary" icon="el-icon-plus" @click="addCard">Add post</el-button>
            <el-button
              class="save button"
              type="primary"
              icon="el-icon-check"
              @click="saveChanges"
            >Save changes</el-button>
            <el-button
              :disabled="currentSlots.length ===0"
              class="button"
              :loading="testSubscriptionLoading"
              @click="testSubscription"
            >Send to test user</el-button>
          </div>
        </div>
        <el-row class="row-fluid">
          <el-popover
            v-for="(post, index) in currentSlots"
            :key="index"
            ref="popover"
            placement="bottom"
            trigger="hover"
          >
            <card
              slot="reference"
              ref="card"
              :style="{ opacity: index < numberOfPosts ? 1 : 0.25 }"
              :card="post"
              :index="index"
              :show-buttons="true"
              editable
              @imageSafeToSave="imageSafeToSave"
            />

            <el-button-group v-if="!past">
              <el-button
                :disabled="index === 0"
                type="danger"
                plain
                icon="el-icon-arrow-left"
                @click="moveLeft(index, $event)"
              />
              <el-button
                style="width:64px;"
                type="danger"
                icon="el-icon-delete"
                @click="removeCard(index)"
              />
              <el-button
                :disabled="index >= currentSlots.length - 1"
                type="danger"
                plain
                icon="el-icon-arrow-right"
                @click="moveRight(index)"
              />
            </el-button-group>
          </el-popover>
        </el-row>
      </el-card>
      <el-row>
        <el-col>
          <JSONEditor v-if="$store.state.showAdvanced" v-model="currentSlots" />
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <div slot="header" class="clearfix">
        <h3 style="line-height: 36px;">
          Posts History
          <small>
            Empty slots are automatically filled with news from that
            day.
          </small>
        </h3>
      </div>

      <el-card v-for="date in pastDates" :key="date">
        <div slot="header">
          <span style="line-height: 32px; font-size: 24px;">
            <strong>{{ date | moment("MMMM DD") }}</strong>
            <br />
            <small>Sent {{ date | fromHour(sendingTime) }}</small>
          </span>
        </div>
        <el-row class="row-fluid" style="padding: 16px;">
          <card
            v-for="(post, index) in filter(posts[date])"
            :key="index"
            :show-buttons="false"
            :index="index"
            :card="post"
          />
        </el-row>
      </el-card>
    </el-card>
  </div>
</template>
<script>
import _ from "lodash";
import Card from "@/components/Card";
import JSONEditor from "@/components/JSONEditor";

import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      posts: "posts",
      filler(state) {
        return state.filler.slice(
          0,
          5 - (state.posts[this.today] || []).length
        );
      }
    }),
    currentSlots: {
      get() {
        return this.$store.state.posts[this.currentDate] || [];
      },
      set(value) {
        this.$store.state.posts[this.currentDate] = value;
      }
    },
    numberOfPosts() {
      if (this.$store.state.modules["subscription"]) {
        return this.$store.state.modules["subscription"].numberOfPosts;
      } else {
        return 5;
      }
    },
    pastDates() {
      if (moment().hour() > this.sendingTime) {
        let dates = Object.keys(this.posts)
          .filter(date => moment(date).isSameOrBefore(moment().startOf("day")))
          .filter(date => this.posts[date])
          .reverse();

        return dates;
      } else {
        let dates = Object.keys(this.posts)
          .filter(date => moment(date).isBefore(moment().startOf("day")))
          .filter(date => this.posts[date])
          .reverse();

        return dates;
      }
    },
    today: () => moment().format("YYYY-MM-DD"),
    sendingTime() {
      return _.get(this, "$store.state.modules.subscription.cron", 0);
    },
    currentDate() {
      if (this.$route.params.date) {
        return this.$route.params.date;
      } else if (moment().hour() > this.sendingTime) {
        return moment()
          .add(1, "day")
          .format("YYYY-MM-DD");
      } else {
        return this.today;
      }
    },
    past() {
      return moment(this.currentDate).isBefore(moment(), "day");
    },
    lessThanFive() {
      return this.currentSlots.length < 5;
    },
    showSave() {
      return this.changes_made && this.valid;
      // return this.currentSlots.length >= 0 && this.changes_made && this.valid;
    },
    valid() {
      return this.currentSlots.every((post, index) => {
        let reference = this.$refs["card"][index];
        return reference && reference.valid;
      });
    }
  },
  components: {
    Card,
    JSONEditor
  },
  data() {
    let includeToday = moment().hour() < this.sendingTime;

    return {
      testSubscriptionLoading: false,
      safeToSave: true,
      futureDates: (includeToday
        ? [0, 1, 2, 3, 4, 5, 6]
        : [1, 2, 3, 4, 5, 6, 7]
      ).map(daysLater => moment().add(daysLater, "days")),
      firstweek: true,
      changes_made: false
    };
  },
  beforeMount() {
    if (Object.keys(this.$store.state.posts).length === 0) {
      this.$store.dispatch("FETCH_POSTS");
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.leaving, false);
    window.addEventListener("unload", this.leaving, false);
  },
  methods: {
    filter: _.filter,
    imageSafeToSave(checks) {
      if (checks) {
        this.safeToSave = true;
      } else {
        this.safeToSave = false;
      }
    },
    addCard() {
      this.$store.commit("ADD_CARD", {
        date: this.currentDate,
        card: {
          text: "",
          subtext: "",
          image: "",
          url: "",
          buttons: [
            {
              text: "Click me",
              event: "goto",
              data: ""
            }
          ]
        }
      });
    },
    editCard({ card, slot }) {
      this.$store.commit("EDIT_CARD", {
        date: this.currentDate,
        slot: slot,
        card: card
      });
      this.changes_made = true;
    },
    saveChanges() {
      if (!this.safeToSave) {
        this.$message({
          message: `Image URL is not acceptable, please make sure it is https secured`,
          type: "error"
        });
        return;
      }
      this.$store
        .dispatch("SAVE_CHANGES", this.currentDate)
        .then(() => {
          this.$message({
            message: `Changes saved for ${this.currentDate}`,
            type: "success"
          });
          this.changes_made = false;
        })
        .catch(() => {
          this.$message({
            message: `Oops, changes cannot be saved.`,
            type: "error"
          });
        });
    },
    testSubscription() {
      this.testSubscriptionLoading = true;
      this.$store
        .dispatch("TEST_SUBSCRIPTION", { date: this.currentDate })
        .then(isSuccessful => {
          this.testSubscriptionLoading = false;
          if (isSuccessful) {
            this.$message({
              type: "success",
              message: "Successfully sent message to test user"
            });
          } else {
            this.$message({
              type: "error",
              message: "Encountered error sending message to test user"
            });
          }
        })
        .catch(err => {
          this.testSubscriptionLoading = false;
          let message = "Encountered error sending message to test user.";
          const status = _.get(err, "response.status");
          switch (status) {
            case 400:
              message =
                "No test user is assigned. Please assign a test user on settings.";
              break;
            case 418:
              message = "No posts to send test users.";
              break;
            default:
          }

          this.$message({
            type: "error",
            message
          });
        });
    },
    moveLeft(index) {
      if (index > 0) {
        this.$refs["popover"][index].showPopper = false;
        this.$refs["popover"][index - 1].showPopper = true;

        this.$store.commit("SWAP", {
          date: this.currentDate,
          from: index,
          to: index - 1
        });
        this.changes_made = true;
      }
    },
    moveRight(index) {
      if (index < this.$refs["popover"].length) {
        this.$refs["popover"][index].showPopper = false;
        this.$refs["popover"][index + 1].showPopper = true;

        this.$store.commit("SWAP", {
          date: this.currentDate,
          from: index,
          to: index + 1
        });
        this.changes_made = true;
      }
    },
    removeCard(index) {
      this.$confirm("This will delete the card. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info"
      })
        .then(() => {
          this.$store.commit("REMOVE_CARD", {
            date: this.currentDate,
            slot: index
          });
          this.changes_made = true;
        })
        .catch(() => {
          this.$message({
            message: `Oops, Card cannot be removed.`,
            type: "error"
          });
        });
    },
    addFutureDates() {
      var previousLast = this.futureDates.slice(-1)[0];
      this.futureDates = [1, 2, 3, 4, 5, 6, 7].map(daysLater =>
        moment(previousLast).add(daysLater, "days")
      );
      this.firstweek = false;
    },
    subtractFutureDates() {
      var previousFirst = this.futureDates.slice(0)[0];
      this.futureDates = [7, 6, 5, 4, 3, 2, 1].map(daysLater =>
        moment(previousFirst).subtract(daysLater, "days")
      );
      if (previousFirst.isSameOrBefore(moment().add(8, "days"), "day")) {
        this.firstweek = true;
      }
    },
    leaving(event) {
      if (this.changes_made) {
        event = event || window.event;
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = "Don't Leave :(";
        return "Don't leave :(";
      }
    },
    isActive(date) {
      if (this.currentDate === date) {
        return "primary";
      } else {
        return "default";
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    if (this && this.changes_made) {
      this.$confirm(
        "You have unsaved changes. Leaving this page will lose your changes.",
        "Are you sure?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No, go back!",
          type: "warning"
        }
      ).then(() => {
        next();
      });
    } else {
      next();
    }
  }
};
</script>

<style scoped>
.row-fluid {
  white-space: nowrap;
  overflow: auto;
}

#cardPlus {
  display: inline-block;
  vertical-align: top;
}

.el-card {
  margin-bottom: 15px;
}

.save.button {
  font-size: 14px;
  color: #fff !important;
  background-color: #67c23a !important;
  border-color: #67c23a !important;
}

.save.button:hover {
  font-size: 14px;
  color: #67c23a !important;
  background-color: white !important;
  border: 1px solid #67c23a !important;
}

.button {
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
  background: #409eff !important;
  color: white !important;
  border-color: #409eff !important;
}

.button:hover {
  background: white !important;
  color: #409eff !important;
  border-color: #409eff !important;
}
</style>
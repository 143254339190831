import store from "@/store";
import _ from "lodash";

export function doesChatBelongsToCurrentAgent(
  agentEmail: string,
  user_id: string
): boolean {
  const queueChatsObject = store.state.livechat.queueChatObject;
  const queueChats = Object.values(queueChatsObject);
  const selectedChat = _.find(queueChats, { user_id });
  const agents = _.get(selectedChat, "agents", ["does not exist"]);
  const isRemoved = _.get(selectedChat, "isRemoved"); // when chat resolved, it will be remain with status isRemoved = true

  const belongsToCurrentAgent = agents.includes(agentEmail);
  return belongsToCurrentAgent && !isRemoved;
}

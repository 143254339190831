import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { MakerCheckerState } from "./types";
import _ from "lodash";
import { getDepartmentVerifiedChanges } from "@/helperMethods/makerChecker";

const getters: GetterTree<MakerCheckerState, RootState> = {
  getUserAuthentication(state, getters, rootState) {
    state.userAuthentication = _.get(rootState, "profile.app_metadata");
    return _.assign(state.userAuthentication, {});
  },
  makerCheckerObject(state, getters, rootState) {
    return _.assign(_.get(rootState, "modules.makerChecker", {}), {});
  },
  makerCheckerEnabled(state, getters, rootState) {
    const makerCheckerEnabled = _.get(
      rootState,
      "modules.makerChecker.enabled",
      false
    );
    return makerCheckerEnabled;
  },
  departments(state, getters, rootState) {
    const departments = _.get(state, "userAuthentication.departments", []);

    const editedDepartments = _.map(
      _.cloneDeep(departments),
      (v) => v && v.toLowerCase()
    );
    !editedDepartments.includes("general") && editedDepartments.push("general");

    return editedDepartments ? editedDepartments : [];
    // return departments ? [] : []; // Testing of authentication
  },
  formattedDepartments(state, getters, rootState) {
    const departments = getters.departments;
    let formattedDepartments: string[] = [];
    if (departments) {
      formattedDepartments = _.map(departments, (dept) => {
        return dept
          .split(" ")
          .map((word: string) => _.capitalize(word))
          .join(" ")
          .trim();
      });
    }
    return _.uniq(formattedDepartments);
  },
  pendingChangesList(state, getters, rootState) {
    const filtered = getDepartmentVerifiedChanges(
      state.pendingChanges,
      getters.departments
    );
    return filtered;
  },
  completedChangesList(state, getters, rootState) {
    const filtered = getDepartmentVerifiedChanges(
      state.completedChanges,
      getters.departments
    );
    return filtered;
  },

  showAdvanced(state, getters, rootState) {
    return rootState.showAdvanced;
  },
  mainType(state, getters, rootState) {
    return state.mainType;
  },
  department(state, getters, rootState) {
    return state.currentDepartment;
  },

  completedLoading(state, getters, rootState) {
    return state.completedLoading;
  },
  pendingLoading(state, getters, rootState) {
    return state.pendingLoading;
  },
};

export default getters;

<template>
  <section>
    <el-row style="padding: 0 10px" type="flex" justify="space-between">
      <div>
        <LivechatMonitorPopover
          v-if="getGraphQlWebsocketStatus() === 'connected'"
          :is-connecting="getGraphQlWebsocketStatus() === 'connecting'"
          :queued-chats="queueChatsArray"
        />

        <el-tooltip
          placement="bottom"
          content="When no agents are online, Livechat will stop accepting users into the queue"
          popper-class="agent-status-tooltip"
        >
          <el-dropdown trigger="click" @command="updateAgentStatus">
            <el-button
              :disabled="updatingAgentStatus"
              :class="{ 'status-button': agentStatusText === 'Online' }"
              :type="agentStatusText === 'Offline' ? 'danger' : ''"
              :plain="agentStatusText === 'Offline'"
              style="border-radius: 4px; margin-right: 8px"
              size="small"
            >
              {{ agentStatusText }}
              <i
                :class="{
                  'el-icon-loading': updatingAgentStatus,
                  'el-icon-arrow-down': !updatingAgentStatus,
                }"
                class="el-icon--right"
              ></i>
            </el-button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(status, index) in agentStatusesArray"
                :key="index"
                :command="status.value"
                :disabled="status.value === agentStatus"
              >
                {{ status.text }}
                <i
                  v-if="status.value === agentStatus"
                  class="el-icon-check"
                ></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-tooltip>

        <div style="display: inline-block" />

        <template v-if="!isShowAgentsStatusEnabled">
          <span class="agentDisplay" v-if="getOnlineAgents.length === 0"
            >No agents online</span
          >
          <small
            class="agentDisplay"
            :key="`agent-email-${agentIndex}`"
            v-for="(agent, agentIndex) in getOnlineAgents"
            v-html="
              `${agent}${agentIndex < getOnlineAgents.length - 1 ? ', ' : ''}`
            "
          ></small>
        </template>
      </div>

      <ServerStatus :graphQLWebsocketStatus="getGraphQlWebsocketStatus()" />
    </el-row>
    <el-tabs
      style="padding: 0 10px"
      v-model="currentTabName"
      :before-leave="handleTabChange"
    >
      <el-tab-pane name="queue" class="tabpanel" :lazy="true">
        <span slot="label">
          Queue
          <el-badge
            v-if="!fetchQueueChatLoading"
            class="mark"
            :value="queueBadgeNumber"
          />
        </span>

        <QueueChatsTab ref="queueChatsTab" />
      </el-tab-pane>

      <el-tab-pane name="resolved" class="tabpanel" :lazy="true">
        <span slot="label">
          Resolved
          <el-badge
            v-if="!fetchResolvedChatLoading"
            class="mark"
            :value="resolvedBadgeNumber"
          />
        </span>

        <ResolvedChatsTab />
      </el-tab-pane>

      <el-tab-pane
        name="monitor"
        class="tabpanel"
        v-if="displayMonitorTab"
        :lazy="true"
      >
        <span slot="label">
          Monitor
          <el-badge
            v-if="!fetchMonitorChatLoading"
            class="mark"
            :value="monitorBadgeNumber"
          />
        </span>

        <MonitorChatsTab ref="monitor-chats-tab" />
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import { notifyWebsocketError } from "@/helperMethods/notifyError";
import ResolvedChatsTab from "@/components/LiveChat/ResolvedChats/Index";
import MonitorChatsTab from "@/components/LiveChat/monitorChats/Index";
import QueueChatsTab from "@/components/LiveChat/QueueChatsTab";
import LivechatMonitorPopover from "./LivechatMonitorPopover/Index";
import ServerStatus from "./ServerStatus";
import {
  listenGraphQLWebsocket,
  GRAPHQL_WEBSOCKET_STATE,
  isGraphQLWebsocketConnected,
  isGraphQLWebsocketConnecting,
} from "@/store/api";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    ResolvedChatsTab,
    QueueChatsTab,
    MonitorChatsTab,
    LivechatMonitorPopover,
    ServerStatus,
  },
  data() {
    return {
      connecting: false,
      updatingAgentStatus: false,
      initializedSocketEvents: false,
      agentStatuses: {
        ONLINE: {
          value: "ONLINE",
          text: "Online",
        },
        OFFLINE: {
          value: "OFFLINE",
          text: "Offline",
        },
      },
      accessToken: "",
    };
  },
  computed: {
    ...mapGetters([
      "queueChatsArray",
      "activeTabName",
      "fetchQueueChatLoading",
      "fetchResolvedChatLoading",
      "fetchMonitorChatLoading",

      "queueBadgeNumber",
      "resolvedBadgeNumber",
      "monitorBadgeNumber",

      "dataCollectionFormEnabled",
      "getAgentStatus",
      "getSocketConnectionStatus",
      "getDisableMonitorTabOption",
      "getAdditionalAgentStatus",
      "getOnlineAgents",
    ]),
    displayMonitorTab() {
      return !this.getDisableMonitorTabOption;
    },
    agentStatusText() {
      const text = _.get(
        this.agentStatuses,
        `${this.getAgentStatus}.text`,
        "Offline"
      );
      return text;
    },
    agentStatusesArray() {
      return Object.values(this.agentStatuses);
    },
    agentStatus: {
      get() {
        return this.getAgentStatus;
      },
      set(status) {
        this.$store.commit("SET_AGENT_STATUS", status);
      },
    },
    currentTabName: {
      get() {
        return this.activeTabName;
      },
      set(newTabName) {
        this.$store.commit("SET_ACTIVE_TAB_NAME", newTabName);
      },
    },

    /**
     * @description Is show agents status settings enabled from module editor
     * @return {boolean}
     */
    isShowAgentsStatusEnabled() {
      return _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAgentsStatus",
        false
      );
    },
  },
  methods: {
    getGraphQlWebsocketStatus() {
      return this.getSocketConnectionStatus;
    },
    showAgentStatusNotification(newStatus) {
      const agentIsOnline = newStatus === "ONLINE";
      if (agentIsOnline) {
        this.$notify.success({
          message: "You are now online, let's start chatting!",
          title: "Success",
          showClose: true,
          position: "bottom-right",
        });
      } else {
        this.$notify.info({
          title: "Info",
          message: "You are offline, go online to start conversation.",
          showClose: true,
          position: "bottom-right",
        });
      }
    },
    updateAgentStatus(newAgentStatus) {
      if (this.updatingAgentStatus) {
        // Loading state, do nothing
        return;
      }

      this.updatingAgentStatus = true;
      this.$store
        .dispatch("CHANGE_APPEAR_ONLINE", {
          newStatus: newAgentStatus,
          previousStatus: this.getAgentStatus,
        })
        .catch((error) => {
          this.$notify.error({
            title: "Online Error",
            type: "error",
            message: "Encountered error going online",
            position: "bottom-right",
          });
        })
        .finally(() => (this.updatingAgentStatus = false));
    },
    handleTabChange(activeName, oldActiveName) {
      // Reset selected chat and filters
      this.$store.commit("SELECT_LIVE_CHAT", {
        chatId: null,
        type: "queue",
      });
      if (oldActiveName === "monitor" && this.$route.params.sessionId) {
        this.$router.replace("/livechat").catch(() => {});
      }
      this.$nextTick(() => {
        const monitorChatsTabHasInterval = _.get(
          this.$refs,
          "['monitor-chats-tab']['intervalFetchMonitorChats']",
          null
        );

        if (this.currentTabName === "monitor") {
          this.$refs["monitor-chats-tab"]?.startIntervalFetchMonitorChats();
        } else if (monitorChatsTabHasInterval) {
          clearInterval(
            this.$refs["monitor-chats-tab"].intervalFetchMonitorChats
          );
          this.$refs["monitor-chats-tab"].intervalFetchMonitorChats = null;
        }
      });
    },
    setSocketConnectionStatus(status) {
      this.$store.commit("SET_SOCKET_CONNECTION_STATUS", status);
    },
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
        position: "bottom-right",
      });
    },
  },
  mounted() {
    const onlyForMOM = this.$store.state.account === "MOM";
    this.agentStatuses = Object.assign(
      {},
      this.agentStatuses,
      this.getAdditionalAgentStatus
    );

    if (this.dataCollectionFormEnabled) {
      this.$store.dispatch("GET_ALL_FIELD_DATA").catch((error) => {
        this.$notify.error({
          title: "Error",
          message: "Encountered error fetching issue categories",
          position: "bottom-right",
        });
      });
    }

    if (isGraphQLWebsocketConnected()) {
      this.setSocketConnectionStatus("connected");
    } else if (isGraphQLWebsocketConnecting()) {
      this.setSocketConnectionStatus("connecting");
    } else {
      this.setSocketConnectionStatus("disconnected");
    }

    this.unsubConnectedWs = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.CONNECTED,
      () => {
        this.updateAgentStatus(this.getAgentStatus);
        this.setSocketConnectionStatus("connected");
      }
    );
    this.unsubReconnectedWs = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.RECONNECTED,
      () => {
        this.updateAgentStatus(this.getAgentStatus);
        this.setSocketConnectionStatus("connected");
      }
    );
    this.unsubConnectingWs = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.CONNECTING,
      () => {
        this.setSocketConnectionStatus("connecting");
      }
    );
    this.unsubErrorWs = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.ERROR,
      (error) => {
        this.setSocketConnectionStatus("disconnected");
        notifyWebsocketError(error);
      }
    );
    this.unsubDisconnectedWs = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.DISCONNECTED,
      () => {
        this.setSocketConnectionStatus("disconnected");
        notifyWebsocketError("Disconnected");
      }
    );

    const sessionId = this.$route.params.sessionId;
    if (sessionId) {
      this.$store.commit("SET_ACTIVE_TAB_NAME", "monitor");
    }
  },
  destroyed() {
    this.$store.commit("RESET_ALL_FILTERS");
    this.$store.commit("FLUSH_RESOLVED_CHATS_ARRAY");
    this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY");
    this.unsubErrorWs();
    this.unsubDisconnectedWs();
    this.unsubConnectedWs();
    this.unsubConnectingWs();
    this.unsubReconnectedWs();
  },
  watch: {
    getAgentStatus(newStatus, oldStatus) {
      this.showAgentStatusNotification(newStatus);
    },
  },
};
</script>

<style scoped>
.agentDisplay {
  font-size: 0.7em;
  padding: 0;
  margin-top: 0;
}

.el-tabs__content {
  overflow: hidden;
}

.tabpanel {
  overflow: hidden;
}

.tabpanel > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.livechat-container {
  min-height: 600px;
  height: 100%;
}

.status-button {
  /* line-height: 20px; */
  text-align: center;
  background: white !important;
  color: #409eff !important;
  border-color: #409eff !important;
}

.status-button:hover {
  background: #409eff !important;
  color: white !important;
  border-color: #409eff !important;
}
@media (min-width: 580px) and (max-width: 1024px) {
  .agent-status-tooltip {
    max-width: 45%;
  }

  .alert-messages {
    top: 10% !important;
    left: 53%;
  }
}
</style>

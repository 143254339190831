import * as _ from "lodash";
/**
 * Returns a simplified message object with only text key with string value.
 * @param {object} message
 */

export function simplifyMessage(message: any) {
  if (message.text) {
    return message;
  }
  if (message.data) {
    if (message.data.text) {
      return message.data;
    }

    if (message.data.content) {
      if (
        Array.isArray(message.data.content) &&
        message.data.content.length === 1
      ) {
        const templateMessagePayload = _.get(
          message,
          "data.content[0].attachment.payload"
        );
        if (templateMessagePayload) {
          if (templateMessagePayload.elements) {
            return {
              text: "[Multimedia Card]",
            };
          }
          return templateMessagePayload;
        }

        return message.data.content[0];
      }

      return {
        text: message.data.content
          .reduce(
            (text: any, card: any, index: any) =>
              (text += `${index + 1}. ${card.text}\n`),
            ""
          )
          .trim(),
      };
    }

    if (message.data.attachment) {
      return {
        text: "[Multimedia Card]",
      };
    }
  }
}

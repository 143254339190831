<template>
  <el-tooltip
    style="padding: 0; border: none"
    :content="errorMessage"
    placement="bottom"
  >
    <i
      :disabled="buttonDisabled"
      class="el-icon-s-promotion error-icon"
      @click="resendMessage()"
      style="
        color: #fff;
        background-color: #e4392b;
        border-radius: 50%;
        cursor: pointer;
        padding: 2px;
        margin: 0 5px 10px -10px;
      "
    />
  </el-tooltip>
</template>

<script>
export default {
  props: {
    resendMessage: Function,
    isDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    errorMessage: String,
  },
  computed: {
    buttonDisabled() {
      return this.isDisabled;
    },
  },
};
</script>

<style scoped>
.error-icon::before {
  font-size: 14px;
  width: 15px;
  height: 15px;
}
</style>

<template>
  <el-row>
    <el-autocomplete
      v-model="flowNext"
      :fetch-suggestions="getFlowSectionsTitle"
      placeholder="Target Flow Section"
    >
      <div slot="prepend" style="width: 40px">Flow ID</div>
    </el-autocomplete>

    <el-tooltip
      v-if="flowNeedsToLoad"
      content="Click on flow tab to load flows, or create a flow first"
      placement="right"
    >
      <i type="warning" class="el-icon-warning more-info warning-icon"></i>
    </el-tooltip>
    <el-tooltip
      v-else-if="flowContentExists"
      content="Selection is valid"
      placement="right"
    >
      <i type="success" class="el-icon-check more-info validation-icon"></i>
    </el-tooltip>
    <el-tooltip v-else :content="validationMessage" placement="right">
      <i
        type="danger"
        class="el-icon-close more-info wrong-validation-icon"
      ></i>
    </el-tooltip>
  </el-row>
</template>

<script>
import _ from "lodash";

export default {
  name: "EventNodeTypeStartflow",
  props: ["value"],
  data() {
    return {
      sectionContentExists: false,
      validationMessage: "",
    };
  },
  mounted() {
    if (this.value.data === null || typeof this.value.data === "string") {
      this.value.data = {
        id: "",
        value: "",
      };
    }
  },
  methods: {
    /**
     * @description el-autocomplete on fetchSuggestions event handler
     * @param {string} value
     * @param {Function} cb
     * @return {void}
     */
    getFlowSectionsTitle(value, cb) {
      const selected = _.filter(this.flowSectionsTitle, (idValueMap) => {
        return idValueMap.value.includes(value);
      });
      const result = selected.length > 0 ? selected : this.flowSectionsTitle;

      return cb(result);
    },
  },
  computed: {
    flowNeedsToLoad() {
      const flowContent = this.$store.getters.flowContent;
      const flowNeedsToLoad = _.isEmpty(flowContent);
      return flowNeedsToLoad;
    },
    flowContentExists() {
      const sectionId = _.get(this.value, "data.id");
      const flowName = _.get(this.value, "data.value");
      const sectionContentId = `${sectionId}_1`;

      const flowContent = this.$store.getters.flowContent;
      const exists = !!flowContent[sectionContentId];

      this.sectionContentExists = exists;
      if (!exists) {
        this.validationMessage = `${flowName}'s content does not exists. 
        Create a new flow or try saving the flow`;
      } else {
        this.validationMessage = "";
      }
      return exists;
    },
    flowNext: {
      get() {
        // const selected = _.find(this.flowSectionsTitle, { id: this.value.data.next });
        // return selected.value
        return _.get(this, "value.data.value", "");
      },
      set(value) {
        const selected = _.find(this.flowSectionsTitle, { value });
        if (selected) {
          this.value.data.id = selected.id;
          this.value.data.value = selected.value;
        } else {
          this.value.data = { id: "", value };
        }
      },
    },
    flowSectionsTitle() {
      const notRouterFlow = ({ title }) => {
        return title.toLowerCase() !== "router";
      };
      const getFlowSections = (flow) => {
        return flow.sections;
      };
      const getSectionTitle = ({ sectionId, title, flowId }) => {
        const flow = _.get(
          this,
          `$store.state.floweditor.flows[${flowId}]`,
          {}
        );
        return {
          id: sectionId,
          value: `${title} (${flow.title})`,
        };
      };

      const allFlows = Object.values(this.$store.state.floweditor.flows);
      const temp = _.chain(allFlows)
        .filter(notRouterFlow)
        .map(getFlowSections)
        .flattenDeep()
        .map(getSectionTitle)
        .value();

      return temp;
    },
  },
};
</script>
<style scoped>
.more-info {
  margin-left: 8px;
}
.warning-icon {
  color: #e6a23c;
}
.validation-icon {
  color: #67c23a;
}
.wrong-validation-icon {
  color: #f56c6c !important;
}
</style>

import { MutationTree } from "vuex/types";
import Vue from "vue";
import { BroadcastState, IBroadcast } from "./types";
import _ from "lodash";
import moment from "moment";

const mutations: MutationTree<BroadcastState> = {
  SET_BROADCASTS(state, { broadcasts }) {
    broadcasts.forEach((broadcast: IBroadcast) => {
      Vue.set(state.broadcastObjects, broadcast.timestamp, broadcast);
    });
  },
  SET_BROADCAST(state, { broadcast }) {
    const newTimestamp = moment(broadcast.date).format("x");

    Vue.set(state.broadcastObjects, newTimestamp, broadcast);
  },
  UNSET_BROADCAST(state, timestamp) {
    Vue.delete(state.broadcastObjects, timestamp);
  },

  SET_SELECTED_BROADCAST(state, broadcastTimestamp) {
    Vue.set(state, "selectedBroadcastId", broadcastTimestamp);

    const selectedBroadcast = _.get(
      state.broadcastObjects,
      `[${broadcastTimestamp}]`,
      null
    );
    Vue.set(state, "selectedBroadcast", selectedBroadcast);
  },
  CLEAR_SELECTED_BROADCAST(state, _) {
    Vue.set(state, "selectedBroadcastId", "");
    Vue.set(state, "selectedBroadcast", null);
  },
  CREATE_BROADCAST(state, { broadcast, timestamp }) {
    Vue.set(state.broadcastObjects, timestamp, broadcast);
  },

  DELETE_BROADCAST(state, timestamp) {
    Vue.delete(state, "selectedBroadcastId");
    Vue.delete(state, "selectedBroadcast");
    Vue.delete(state.broadcastObjects, timestamp);
  },
  UPDATE_BROADCAST(state, { timestamp, broadcast }) {
    console.log({ timestamp, broadcast });
    Vue.set(state.broadcastObjects, timestamp, broadcast);
  },
};

export default mutations;

<template>
  <div>
    <div
      class="transformations"
      style="display:flex;flex-direction:row;"
      :key="index"
      v-for="(transformation, index) in transformations"
    >
      <div style="display: flex; align-items: center; width: 13%;">
        <el-checkbox v-model="transformation.enabled">{{ transformation.displayName }}</el-checkbox>
      </div>
      <div style="width: 45%;justify-content: space-evenly;display: flex;">
        <el-input
          style="width: 48%;"
          placeholder="Expected Pattern"
          :disabled="!transformation.enabled"
          v-model="transformation.expectedPattern"
        ></el-input>
        <el-input
          style="width: 48%;"
          placeholder="Replacement"
          :disabled="!transformation.enabled"
          v-model="transformation.replacement"
        ></el-input>
      </div>
      <div style="display: flex; align-items: center;" v-if="transformation.enabled">
        <div
          v-if="!evaluateRegex(transformation.expectedPattern)"
          style="color:#fa5555; line-height:1;"
        >
          Invalid regular expression for expected pattern.
          <u
            style="cursor:pointer;"
            @click="fixRegex(transformation)"
          >Auto fix?</u>
        </div>
        <div
          v-else-if="!transformation.replacement"
          style="color:#fa5555; line-height:1;"
        >Replacement cannot be blank.</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  convertRegexStringToRegex,
  escapeSpecialCharacters
} from "@/helperMethods/import_export/transformations";
export default {
  name: "Transformations",
  props: ["transformations"],
  data() {
    return {};
  },
  methods: {
    validate() {
      return _.every(this.transformations, transformation => {
        if (!transformation.enabled) return true;

        const expectedPatternIsValid = this.evaluateRegex(
          transformation.expectedPattern
        );
        if (expectedPatternIsValid && transformation.replacement) return true;

        return false;
      });
    },
    evaluateRegex(expectedPattern) {
      if (!expectedPattern || typeof expectedPattern !== "string") return false;

      const isValidRegex = expectedPattern.match(
        /([^\/]*)(?:\/(.+)\/)(?![gimsuy]*[^gimsuy]+)/
      );
      if (isValidRegex === null) return false;

      const leadingCharactersBeforeRegexExist = !!isValidRegex[1];
      if (leadingCharactersBeforeRegexExist) return false;

      return true;
    },
    fixRegex(transformation) {
      const expectedPattern = transformation.expectedPattern;
      const regex = convertRegexStringToRegex(expectedPattern);
      if (regex) {
        transformation.expectedPattern = `/${regex.source}/${regex.flags}`;
      } else {
        transformation.expectedPattern = `/./i`;
      }
    }
  }
};
</script>

<style scoped>
.transformations + .transformations {
  margin-top: 10px;
}
</style>
import moment from "moment";
import { MessageBox } from "element-ui";

export function getStartDate() {
  return moment().subtract(1, "days").endOf("day");
}

export function getEndDate() {
  return moment().endOf("day");
}

export function getDefaultDateRange() {
  const startDate = getStartDate();
  const endDate = getEndDate();

  return [startDate, endDate];
}

export function isDateRangeValid(filter, maxDays = 7) {
  const { startDate, endDate } = filter;
  if (!startDate || !endDate) {
    return false;
  }
  return moment(endDate).diff(moment(startDate), "days") <= maxDays;
}

export function showDateRangeError() {
  MessageBox(
    "Please check if date range is correct. The maximum date range allowed is 7 days.",
    "Date range error",
    {
      confirmButtonText: "Close",
    }
  );
}

<template>
  <div>
    <span
      v-if="mode !== 'form'"
      style="margin-left:1.5em;margin-right:3.5em;color:#909399;fontsize:14px"
    >
      {{ setting.label || name }}
    </span>
    <el-select
      filterable
      v-model="value[name]"
      placeholder="Select"
      :name="setting.label || name"
      :clearable="setting.clearable"
      style="margin-bottom:1em; width: 360px;"
    >
      <el-option
        v-for="item in setting.options"
        :key="item.value"
        :label="item.label"
        :value="item.value ? item.value : item.label"
      >
        <span :style="{ float: 'left' }">{{ item.label }}</span>
        <span
          v-if="setting.displayValue"
          :style="{float: 'right', color: item.color? item.color: '#909399', fontSize: 13 + 'px', marginLeft: '1.5em'}"
        >{{ item.value? item.value: item.label }}</span>
      </el-option>
    </el-select>
    <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="right">
      <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
    </el-tooltip>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name", "mode"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
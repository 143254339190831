<template>
  <!-- Reject Confirmation dialog -->
  <el-dialog
    title="Reject Confirmation"
    :visible.sync="showRejectConfirmationForm"
    :before-close="handleDialogClose"
    center
  >
    <el-form
      ref="rejectConfirmationForm"
      :model="rejectConfirmationForm"
      label-width="100px"
      :rules="rejectConfirmationRule"
      v-loading="rejectConfirmationLoading"
    >
      <el-form-item label="To:" prop="to">
        {{
        rejectConfirmationForm.to
        }}
      </el-form-item>
      <el-form-item label="From:" prop="from">
        <span>
          <b>{{ rejectConfirmationForm.from }}</b>
        </span>
      </el-form-item>
      <el-form-item label="Cc:" prop="cc">
        <el-input
          v-model="rejectConfirmationForm.cc"
          type="text"
          placeholder="Put ';' between each email address"
        />
      </el-form-item>
      <el-form-item label="Comment:" prop="message">
        <el-input
          v-model="rejectConfirmationForm.message"
          type="textarea"
          placeholder="Type your comments here..."
          :autosize="{ minRows: 4, maxRows: 12 }"
        />
      </el-form-item>
      <el-form-item v-if="emailPreviewFetched" label="Preview:">
        <codemirror ref="emailPreviewer" :options="editorHTMLOptions" :value="emailPreview" />
        <!-- <pre style="width:100%;height:500px;overflow-y:auto">{{emailPreview}}</pre> -->
      </el-form-item>
      <el-form-item class="dialog buttons">
        <el-button v-if="false" @click="fetchEmailPreview">Show Email Preview</el-button>
        <el-button @click="showRejectConfirmationForm = false">Cancel</el-button>
        <el-button type="primary" @click="submitRejectForm('rejectConfirmationForm')">Confirm</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import JSONEditor from "@/components/JSONEditor";
import Bubble from "@/components/Bubble";
import QuickReplies from "@/components/QuickReplies";
import Card from "@/components/Card";
import { mapGetters } from "vuex";

export default {
  props: [
    "activeDepartment",
    "filteredDepartments",
    "multipleSelection",
    "specificChange",
    "activeTaskType",
    "filterActivated",
    "dataFilter",
    "editorOptions",
    "editorHTMLOptions",
    "dataUserFilter",
  ],
  data() {
    return {
      emailPreview: "",
      emailPreviewFetched: false,
      // Reject Confirmation
      showRejectConfirmationForm: false,
      rejectConfirmationLoading: false,
      selectedReject: {
        department: "",
        mainType: "",
      },
      rejectConfirmationForm: {
        from: "",
        to: "",
        cc: "",
        name: "",
        message: "",
      },
      rejectConfirmationRule: {
        message: [
          { required: true, message: "A comment for user is required" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "department",
      "makerCheckerObject",
      "departments",
      "mainType",
    ]),
    dialogNodesChosen() {
      if (this.multipleSelection) {
        const tempDialogNodeArray =
          _.chain(this.multipleSelection)
            .filter((selection) => {
              return selection.mainType === "dialog";
            })
            .value() || [];
        return tempDialogNodeArray.length > 0;
      } else {
        return false;
      }
    },
    makerCheckerConfiguration() {
      return _.assign(this.makerCheckerObject || {}, {
        filterActivated: this.filterActivated,
        buttonGroupWidth:
          document.getElementById("buttonGroup") &&
          document.getElementById("buttonGroup").offsetWidth,
      });
    },
  },
  methods: {
    typeFilter(type, department) {
      if (department === "all") {
        return [type.toLowerCase()];
      }
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        return _.chain(this.multipleSelection)
          .map((change) => {
            return change.mainType && change.mainType.toLowerCase();
          })
          .uniq()
          .value();
      }
    },
    departmentsFilter(type, department) {
      const userDepartments = this.departments || [];
      const lowerCaseUserDepartments = userDepartments.map((v) =>
        v.toLowerCase()
      );

      const activeDepartment = this.activeDepartment || "";
      const lowerCaseActiveDepartment = [activeDepartment].map((v) =>
        v.toLowerCase()
      );

      const allDeparmentsSelected =
        department === "all" ||
        !department ||
        lowerCaseActiveDepartment.includes("all departments");

      if (allDeparmentsSelected) {
        return userDepartments;
      }

      if (lowerCaseActiveDepartment) {
        const userValidatedDeparments = _.intersection(
          activeDepartment,
          userDepartments
        );
        return userValidatedDeparments;
      }
    },
    resolverDataHandover(type, department) {
      const result = {
        listOfChanges: this.dataFilter(type, department),
        types: this.typeFilter(type, department),
        departments: this.departmentsFilter(type, department),
      };
      return result;
    },
    submitRejectForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.showRejectConfirmationForm = false;
          // this.resetForm(formName);
          const { name } = this.rejectConfirmationForm;
          const { department, mainType } = this.selectedReject;
          if (department === "all") {
            this.reject(mainType, department);
          } else {
            this.reject();
          }
          return false;
        } else {
          console.log("Error submiting form!!");
          return false;
        }
      });
    },
    reject(type, department, date) {
      this.rejectConfirmationLoading = true;
      const { listOfChanges, types, departments } = this.resolverDataHandover(
        type,
        department
      );

      const payload = {
        type: types,
        date,
        departments,
        customListOfChanges: listOfChanges,
      };

      const isEmpty = listOfChanges.length === 0;
      if (isEmpty) {
        this.$notify.error({
          title: "Empty Change list",
          position: "bottom-right",
          message: "No changes were selected",
        });
        this.showRejectConfirmationForm = false;
        return;
      }

      this.$store
        .dispatch("REJECT_CHANGES", payload)
        .then((isRejected) => {
          this.rejectConfirmationLoading = false;
          if (isRejected) {
            this.showRejectConfirmationForm = false;
            this.$emit("fetchPendingChanges");
            this.$emit("fetchCompletedChanges");
            this.$emit("resetChangeSelection");
            this.$emit("closeApprove");
            if (
              this.makerCheckerConfiguration &&
              this.makerCheckerConfiguration.sendEmail
            ) {
              this.sendRejectionEmail(
                _.assign(this.rejectConfirmationForm, {
                  customListOfChanges: listOfChanges,
                })
              );
            }
          } else {
            this.$message({
              type: "error",
              message: "Encountered error rejecting changes.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error rejecting changes.",
          });

          this.rejectConfirmationLoading = false;
        });
    },

    sendRejectionEmail(payload) {
      this.$store
        .dispatch("SEND_REJECTION_EMAIL", payload)
        .then((sent) => {
          if (sent) {
            this.$message({
              type: "success",
              message:
                "Changes has been rejected successfully. Email has been sent.",
            });
          } else {
            this.$message({
              type: "error",
              message:
                "Failed to reject changes in server. No email was been sent.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message:
              "Encountered error sending rejection email. No email was sent.",
          });
        });
    },

    fetchEmailPreview() {
      this.emailPreviewFetched = false;
      const { department, mainType } = this.selectedReject;
      this.$store
        .dispatch(
          "FETCH_EMAIL_PREVIEW",
          _.assign(this.rejectConfirmationForm, {
            customListOfChanges: this.dataFilter(mainType, department),
          })
        )
        .then((preview) => {
          if (preview) {
            this.emailPreviewFetched = true;
            this.emailPreview = `<html><body>${preview.replace(
              "<br>",
              "\n"
            )}</body></html>`;
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error fetching email preview.",
          });
        });
    },
    handleDialogClose(done) {
      this.$confirm(
        "The change will not be rejected. Are you sure to close this dialog? "
      )
        .then((_) => {
          this.$emit("closeApprove");
          done();
        })
        .catch((_) => {});
    },
    setupRejectionConfirmationForm() {
      const { email, given_name, family_name } = this.$store.state.profile;
      this.rejectConfirmationForm = {
        name: `${given_name} ${family_name}`,
        from: email,
        cc: "",
        to: "",
        message: "",
      };
    },
  },
  mounted() {
    this.showRejectConfirmationForm = true;
    this.setupRejectionConfirmationForm();
    this.selectedReject = {
      mainType: this.mainType,
      department: this.department,
    };
    this.rejectConfirmationForm.to = this.dataUserFilter(
      this.mainType,
      this.department
    );
  },
};
</script>

<style></style>

import _ from "lodash";
export default class LocalStorageManager {
  public static getItem(name: string) {
    return window.localStorage.getItem(name);
  }

  public static setItem(name: string, value: any) {
    return window.localStorage.setItem(name, value);
  }

  public static removeItem(name: string) {
    return window.localStorage.removeItem(name);
  }

  public static getUnreadMessageList() {
    const unreadMessageList: string =
      window.localStorage.getItem("unreadMessageList") || "[]";
    return JSON.parse(unreadMessageList);
  }
  public static setUnreadMessageList(
    value: { chatId: string; unreadMessage: number }[]
  ) {
    const list: string =
      typeof value === "object" ? JSON.stringify(value) : "[]";
    return window.localStorage.setItem("unreadMessageList", list);
  }

  public static setUnreadMessageListUsingChatId(value: {
    chatId: string;
    unreadMessage: number;
  }) {
    const unreadMessageList = this.getUnreadMessageList();
    const targetUnreadMessageChat = _.find(unreadMessageList, {
      chatId: value.chatId,
    });
    if (targetUnreadMessageChat) {
      targetUnreadMessageChat.unreadMessage = value.unreadMessage;
    }
    this.setUnreadMessageList(unreadMessageList);
  }

  public static removeUnreadRecordUsingChatId(chatId: string) {
    let unreadMessageList = this.getUnreadMessageList();
    unreadMessageList = _.remove(
      unreadMessageList,
      (chat: { chatId: string; unreadMessage: number }) =>
        chatId === chat.chatId
    );
    this.setUnreadMessageList(unreadMessageList);
  }

  public static createUnreadMessageList() {
    window.localStorage.setItem("unreadMessageList", "[]");
  }
  public static removeUnreadMessageList() {
    window.localStorage.removeItem("unreadMessageList");
  }
}

<template>
  <el-card>
    <div slot="header" class="clearfix">
      <h3 style="line-height: 36px">
        Management Dashboard - Audit Log
        <!-- <small>{{ noOfItems }} logs shown</small> -->
      </h3>
      <div style="float: right">
        <el-input
          size="mini"
          v-model="search"
          placeholder="Search"
          prefix-icon="el-icon-search"
          clearable
        />
      </div>
    </div>
    <el-row type="flex" align="middle">
      <small style="font-weight: bold">Filter by date range</small>
      <el-date-picker
        v-model="searchDateRange"
        type="daterange"
        format="yyyy-MM-dd"
        style="width: 400px; margin-left: 15px; margin-bottom: 0"
        align="right"
        unlink-panels
        range-separator="To"
        start-placeholder="Start date"
        size="mini"
        end-placeholder="End date"
        :picker-options="pickerOptions"
        @change="handleDateRangeChange"
      ></el-date-picker>
      <el-checkbox v-model="enableRealtime" style="margin-left: 20px"
        >Enable Realtime Updates</el-checkbox
      >
    </el-row>
    <br />
    <div class="block">
      <el-pagination
        background
        small
        layout="prev, pager, next"
        :pageSize="pageSize"
        :current-page.sync="currentPage"
        :total="noOfItems"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
        @size-change="handleSizeChange"
        style="display: inline-block"
      ></el-pagination>
      <div style="display: inline-block">
        <span>{{ noOfItems }} records</span>
      </div>
    </div>
    <div>
      <el-table
        size="mini"
        v-loading="loading"
        element-loading-text="Loading audit logs.."
        :data="pagedAuditLogs"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <table class="flatten">
              <tr>
                <th>User Agent</th>
                <td>{{ props.row.user_agent }}</td>
              </tr>
              <tr>
                <th>User ID</th>
                <td>{{ props.row.user_id }}</td>
              </tr>
              <tr
                :key="`${props.row.id}-${key}`"
                v-for="(item, key) in flatten(props.row.data)"
              >
                <th>{{ key }}</th>
                <td>{{ item }}</td>
              </tr>
            </table>
          </template>
        </el-table-column>
        <el-table-column label="Time" min-width="150">
          <template slot-scope="scope">
            <strong>{{ scope.row.Timestamp | fromNow }}</strong>
            <br />
            <small>{{ scope.row.Timestamp | moment }}</small>
          </template>
        </el-table-column>
        <el-table-column label="Email" min-width="180">
          <template slot-scope="scope">{{ scope.row.user_email }}</template>
        </el-table-column>
        <el-table-column prop="event" label="Actions taken" min-width="220" />
        <el-table-column label="Origin" min-width="180">
          <template slot-scope="scope">{{ scope.row.origin }}</template>
        </el-table-column>
        <el-table-column label="IP Address" min-width="120">
          <template slot-scope="scope">{{ scope.row.ip_address }}</template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import gql from "graphql-tag";
import _ from "lodash";
import { defaultPickerOptions } from "@/helperMethods/util";
import flat from "flat";
export default {
  data() {
    return {
      search: "",
      loading: false,
      pagedAuditLogs: [],
      totalRecordCount: 0,
      searchDateRange: [],
      pageSize: 50,
      currentPage: 1,
      enableRealtime: true,
      pickerOptions: defaultPickerOptions,
    };
  },
  computed: {
    noOfItems() {
      return this.totalRecordCount;
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).local().format("D MMM YY h:mm A");
    },
  },
  watch: {
    // whenever question changes, this function will run
    search: _.debounce(function (newSearch, oldSearch) {
      this.currentPage = 1;
      this.getAuditLogs();
    }, 500),
    enableRealtime(newVal, oldVal) {
      if (newVal === true) {
        this.getAuditLogs();
        this.$apollo.subscriptions["auditLogChanged"].start();
      } else {
        this.$apollo.subscriptions["auditLogChanged"].stop();
      }
    },
  },
  methods: {
    flatten(value) {
      if (!value) {
        return "";
      }
      try {
        const json = JSON.parse(value);
        return flat(json);
      } catch (e) {
        return value;
      }
    },
    setDateTodayForFilteringByDefault() {
      const dateNow = new Date();
      this.searchDateRange = [dateNow, dateNow];
    },
    parseJSON(data) {
      try {
        const result = JSON.parse(data);
        if (result && Object.keys(result).length === 0) {
          return { meta: "No additional information" };
        }
        return result;
      } catch (error) {
        console.error("audit parseJSON failed");
        return data;
      }
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.getAuditLogs();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAuditLogs();
    },
    handleDateRangeChange() {
      this.currentPage = 1;
      this.getAuditLogs();
    },
    getAuditLogs() {
      this.loading = true;
      let startDate = "";
      let endDate = "";
      if (this.searchDateRange && this.searchDateRange.length > 0) {
        startDate = this.searchDateRange[0];
        endDate = this.searchDateRange[1];
      }
      this.$store
        .dispatch("FETCH_AUDIT_LOG_BY_PAGE", {
          page: this.currentPage,
          pageSize: this.pageSize,
          searchString: this.search,
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => {
          this.loading = false;
          this.pagedAuditLogs = _.get(response, "data.Bot.audit.rows", []);
          this.totalRecordCount = _.get(response, "data.Bot.audit.count", 0);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error fetching audit logs.`,
          });
        });
    },
  },
  apollo: {
    $subscribe: {
      auditLogChanged: {
        query: gql`
          subscription {
            auditLogChanged
          }
        `,
        // Result hook
        result(data) {
          const changedAuditLog = _.get(data, "data.auditLogChanged");

          if (changedAuditLog) {
            this.pagedAuditLogs = [data.data.auditLogChanged].concat(
              this.pagedAuditLogs
            );
            this.totalRecordCount = this.totalRecordCount + 1;
          }
        },
      },
    },
  },
  mounted() {
    this.setDateTodayForFilteringByDefault();
    this.getAuditLogs();
  },
};
</script>

<style scoped>
table.flatten {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table.flatten td,
table.flatten th {
  border: 1px solid #5a5e66;
  text-align: left;
  padding: 4px 10px;
  background-color: transparent;
}

table.flatten tr:nth-child(even) {
  background-color: #dddddd;
}
</style>

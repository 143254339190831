<template>
  <div>
    <div style="text-align: left; margin-bottom: 24px;" v-if="chart.title">
      <h2 style="margin-bottom: 10px;">{{chart.title}}</h2>
    </div>
    <div style="text-align: center;" v-else>
      <p>{{ chart.desc }}</p>
    </div>
    <div :class="{ 'default-faq' : chart.desc, 'default-dashboard': chart.title }">
      <LineChart
        v-if="chart.type == 'line'"
        :datasets="chartData"
        :labels="chartLabels"
        :colors="colors"
      />
      <BarChart
        v-else-if="chart.type == 'bar'"
        :datasets="chartData"
        :labels="chartLabels"
        :colors="colors"
      />
      <DoughnutChart
        v-else-if="chart.type == 'doughnut'"
        :datasets="chartData"
        :labels="chartLabels"
        :colors="colors"
        :category="category"
      />
      <RadarChart
        v-else-if="chart.type == 'radar'"
        :datasets="chartData"
        :labels="chartLabels"
        :colors="colors"
      />
      <NumberWidget v-else-if="chart.type == 'number'" :data="chartData" :label="chart.legend" />
      <Leaderboard v-else-if="chart.type == 'leaderboard'" :data="chartData" />
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/LineChart";
import BarChart from "@/components/BarChart";
import DoughnutChart from "@/components/DoughnutChart";
import RadarChart from "@/components/RadarChart";
import NumberWidget from "@/components/NumberWidget";
import Leaderboard from "@/components/Leaderboard";

export default {
  components: {
    LineChart,
    BarChart,
    DoughnutChart,
    RadarChart,
    NumberWidget,
    Leaderboard
  },
  props: {
    chart: {
      type: Object
    },
    category: {
      type: String
    }
  },
  data() {
    return {
      colors: ["#26B99A", " #FF9900 ", "#BDC3C7", "#26B99A", "#3498DB"]
    };
  },
  computed: {
    dataType() {
      if (Array.isArray(this.chart.data)) {
        return "array";
      }
      return typeof this.chart.data;
    },
    chartData() {
      if (this.dataType === "array") {
        if (this.chart.type === "leaderboard") {
          return this.chart.data;
        } else {
          return [
            {
              label: this.chart.legend,
              data: this.chart.data.map(v => {
                return v.value;
              })
            }
          ];
        }
      } else if (this.dataType === "object") {
        return [
          { label: this.chart.legend, data: Object.values(this.chart.data) }
        ];
      } else {
        return this.chart.data;
      }
    },
    chartLabels() {
      if (this.dataType === "array") {
        return this.chart.data.map(v => {
          return v.label;
        });
      } else if (this.dataType === "object") {
        return Object.keys(this.chart.data);
      }
      return "";
    }
  },
  methods: {
    getContainerSize(type) {
      if (type === "leaderboard") {
        return 12;
      } else if (type === "doughnut") {
        return 12;
      } else if (type === "line") {
        return 12;
      } else {
        return 6;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.default-faq {
  max-width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.default-dashboard {
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
}
</style>

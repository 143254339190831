<template>
  <codemirror :ref="ref" v-model="code" :options="settings" />
</template>
<script>
import codemirorEditorMixin from "@/mixins/codemirrorEditor";
export default {
  props: ["value"],
  mixins: [codemirorEditorMixin],
  data() {
    return {
      mode: "text/javascript",
      ref: "jsonEditor",
      fileList: [],
      uploadedContentList: {},
      dialogVisible: false,
      lr: "",
    };
  },
  computed: {
    code: {
      get: function () {
        return JSON.stringify(this.value, null, 4);
      },
      set: function (json) {
        try {
          let object = JSON.parse(json);
          this.$emit("input", object);
          this.$emit("valid");
        } catch (e) {
          this.$emit("invalid");
        }
      },
    },
    valid() {
      try {
        JSON.parse(this.code);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  methods: {
    undo() {
      this.$refs.jsonEditor.editor.undo();
    },
    redo() {
      this.$refs.jsonEditor.editor.redo();
    },
    test() {
      this.editorOptions.value = "Changed!";
    },
    beforeUpload(file) {
      const isCSV = file.type === "application/json";
      if (!isCSV) {
        this.$message.error("Uploaded file must be a JSON file!");
      }
      return isCSV;
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.handleRemove();
    },
    handleRemove() {
      this.fileList = [];
      this.uploadedContentList = {};
    },
    handleError(err) {},
    handleSuccess(res, file) {
      var contentList = [];
      var reader = new FileReader();
      reader.onload = ((vuecomponent) => {
        return function (e) {
          var data = e.target.result;
          vuecomponent.uploadedContentList = data;
        };
      })(this);
      reader.readAsText(file.raw);

      this.fileList = [...this.fileList, file];
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
      this.uploadedContentList = {};
    },

    upload() {
      if (this.lr === "left") {
        this.codemirror.editor().setValue(this.uploadedContentList);
      } else if (this.lr === "right") {
        this.codemirror.rightOriginal().setValue(this.uploadedContentList);
      }
      this.handleClose(true);
    },

    onCmReady(cm) {
      this.codemirror.editor().setValue(JSON.stringify(this.value, null, 4));
      this.codemirror
        .rightOriginal()
        .setValue(JSON.stringify(this.value, null, 4));
    },

    clear(lr) {
      if (lr === "left") {
        this.codemirror.editor().setValue("");
      } else if (lr === "right") {
        this.codemirror.rightOriginal().setValue("");
      }
    },
    save(lr) {
      var jsonString = "";
      if (lr === "left") {
        jsonString = JSON.parse(this.codemirror.editor().getValue());
      } else if (lr === "right") {
        jsonString = JSON.parse(this.codemirror.rightOriginal().getValue());
      }
      console.log(jsonString);
      var link = document.createElement("a");
      link.download = this.$store.state.brain + "_synonyms.json";
      link.href =
        "data:application/json," + JSON.stringify(jsonString, null, 4);
      link.click();
    },
  },
};
</script>

<style lang="scss">
@import "~codemirror/theme/monokai.css";
.vue-codemirror {
  border: 1px solid #e6ebf5 !important;
}
</style>

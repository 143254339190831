<template>
  <el-main>
    <el-dialog
      title="Select Train Topics"
      :visible.sync="selectTrainTopicsDialogVisible"
      :before-close="handleClose"
      :close-on-press-escape="false"
      width="50%"
      center
    >
      <el-table
        ref="multipleTable"
        :data="$store.state.faq.topics"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="Topic" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="currentSelection.length > 0"
          type="primary"
          @click="trainByTopics(), handleClose()"
        >Train Selected Topics</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
<script>
import gql from "graphql-tag";
import _ from "lodash";

export default {
  props: ["selectTrainTopicsDialogVisible"],
  data() {
    return {
      currentSelection: []
    };
  },
  methods: {
    setTrainingInProgress(inProgress) {
      this.$store.commit("SET_TRAINING_IN_PROGRESS", inProgress);
    },

    trainByTopics() {
      this.setTrainingInProgress(true);
      this.$store
        .dispatch("FAQ_TRAIN_BY_TOPICS", {
          selectedTopics: this.currentSelection
        })
        .then(returnResult => {
          if (_.get(this, "$apollo.queries.models")) {
            this.$apollo.queries.models.refresh();
          }
          this.$notify.success({
            title: "Train Success",
            position: "bottom-right",
            message: `Model per topic has been trained successfully`
          });

          const trainedTopicToModelIds = _.get(returnResult, "Elsa", {});
          if (_.isEmpty(trainedTopicToModelIds)) return;

          this.setModelNameToUUID(trainedTopicToModelIds);
          this.setUUIDToModelName(trainedTopicToModelIds);
        })
        .catch(e => {
          this.$notify.error({
            title: "Train Error",
            position: "bottom-right",
            message: "Training by topics failed."
          });
        })
        .finally(() => this.setTrainingInProgress(false));
    },

    handleClose() {
      this.$store.commit("SET_TOPIC_DIALOG_VISIBLE", false);
    },

    handleSelectionChange(val) {
      this.currentSelection = val;
    },

    setModelNameToUUID(topicToModelIds) {
      const copyModelNameToUUID = _.cloneDeep(
        _.get(this, "$store.state.modules.faq.Elsa.modelNameToUUID", {})
      );
      Object.assign(copyModelNameToUUID, topicToModelIds);

      this.$set(
        this.$store.state.modules.faq.Elsa,
        "modelNameToUUID",
        copyModelNameToUUID
      );
    },

    setUUIDToModelName(topicToModelIds) {
      const copyUUIDToModelName = _.cloneDeep(
        _.get(this, "$store.state.modules.faq.Elsa.UUIDToModelName", {})
      );
      const modelIdsToTopic = _.invert(topicToModelIds);
      Object.assign(copyUUIDToModelName, modelIdsToTopic);

      this.$set(
        this.$store.state.modules.faq.Elsa,
        "UUIDToModelName",
        copyUUIDToModelName
      );
    }
  },
  apollo: {
    models() {
      return {
        query: gql`
          query($modelName: String) {
            faqAPI {
              models: getModels(modelName: $modelName)
            }
          }
        `,
        variables() {
          return {
            modelName: this.$store.state.faq.currentViewingTopic
          };
        },
        fetchPolicy: "cache-and-network",
        update: data => {
          let models = _.get(data, "faqAPI.models", []);
          return models;
        }
      };
    }
  }
};
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{attrs:{"model":_vm.value,"rules":_vm.rules}},[(_vm.value.event)?_c('div',[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{nativeOn:{"click":function($event){_vm.value.event = null}}},[_vm._v("Actions")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.value.event))])],1),_c('el-form-item',{attrs:{"prop":"event"}},[_c('el-autocomplete',{staticClass:"autocomplete-list",attrs:{"fetch-suggestions":_vm.getEventNodes,"disabled":[
              'goto',
              'capture',
              'startflow',
              'callApi',
              '$display',
              'handover_router',
              'set' ].includes(_vm.value.event)},model:{value:(_vm.value.event),callback:function ($$v) {_vm.$set(_vm.value, "event", $$v)},expression:"value.event"}},[_c('div',{staticStyle:{"width":"40px"},attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("Action")])])],1),(_vm.eventComponentIsValid)?_c(_vm.eventComponent,{tag:"component",attrs:{"value":_vm.value,"previous-node":_vm.previousNode}}):_vm._e()],1):_c('div',{staticStyle:{"text-align":"center"}},[_vm._l((_vm.eventNodeTypes),function(eventNodeType,index){return [_c('el-button',{key:("event-node-selector-button-" + index),staticStyle:{"width":"200px","margin-bottom":"4px"},attrs:{"size":"medium","round":""},on:{"click":function($event){return _vm.setType(eventNodeType.name)}}},[_vm._v(_vm._s(eventNodeType.label))]),_c('br',{key:("event-node-selector-break-" + index)})]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Push, { PushNotification } from "push.js";
import { PushNotificationType } from './pushNotificationType';

const PUSH_PERMISSION_DENIED_ERROR = "PushError: permission request declined";
const PUSH_PERMISSION_ERROR_MESSAGE = "We need your permission to send live chat notifications";

export const pushNotification = (message: PushNotificationType, 
  timeout: number = 2000, 
  onClick: Function = () => {}, 
  onError: Function = () => {}): Promise<PushNotification | void> => {

  const createPushNotif = () => {
    return Push.create(message.title, {
      body: message.body,
      icon: message.icon,
      timeout: timeout,
      onClick,
      onError
    }).catch((error)=>{
      onError(error);
    });
  };

  if(!Push.Permission.has()){
    Push.Permission.request(()=>{
      return createPushNotif();
    }, () => {
      onError(PUSH_PERMISSION_DENIED_ERROR);
    });
  }
  return createPushNotif();
};

export const notifyPushNotificationError = ($notify: any, error?: string) => {
  $notify.error({
    position: "bottom-right",
    title: "Push Notification Error",
    message:
      `${PUSH_PERMISSION_ERROR_MESSAGE} Error: ${error}`,
  });
};

export const requestPushNotificationPermission = (acceptCb = () => {}, rejectCb = () => {}) => {
  if(!Push.Permission.has()){
    Push.Permission.request(()=>{
      acceptCb();
    }, () => {
      rejectCb();
    });
  }
};

export const isNotificationEnable = () => {
  return Push.Permission.has();
}
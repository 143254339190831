<template>
  <div>
    <APIEditor ref="api-editor" :editor-option="editorOption" />
  </div>
</template>
<script>
import _ from "lodash";
import APIEditor from "@/components/APIEditor/Index.vue";
export default {
  components: { APIEditor },
  props: [],
  data() {
    return {
      activeTab: "apieditor",
      editorOption: {
        tabSize: 2,
        mode: {
          name: "javascript",
          json: true,
        },
        lineNumbers: true,
        theme: "monokai",
        scrollbarStyle: "null",
        keyMap: "sublime",
      },
    };
  },
  computed: {},
  mounted() {
    this.$store.dispatch("FETCH_ALL_MAPPINGS", {
      brain: this.$store.state.brain,
    });
    this.$store.dispatch("FETCH_ALL_APIS", {
      brain: this.$store.state.brain,
    });
  },
  methods: {},
};
</script>

// @ts-nocheck
export default {
  computed: {
    centionFormMixin() {
      return {
        BASE_URL: {
          default: "https://cloud.cention.com",
          label: "Base Url",
          type: "string",
          required: true,
        },
        AREA_ID: {
          default: 271,
          label: "Area Id",
          type: "number",
          required: true,
        },
        NO_OF_CONTEXTUAL_MESSAGES: {
          type: "number",
          label: "No. of Contextual Messages",
          default: 20,
          min: 0,
          max: 100,
          detail:
            "Number of most recent messages to convey to Cention agents as context",
        },
        CONNECT_SUCCESS_CONTENT: {
          type: "autocomplete",
          default: "cention_connect_success",
          label: "Escalation Success Content",
          detail:
            "Content node to display to the user if escalation to Cention is successful",
          suggestions: this.getContentNodeSuggestions,
        },
        CONNECT_ERROR_CONTENT: {
          type: "autocomplete",
          default: "cention_connect_error",
          label: "Escalation Fail Content",
          detail:
            "Content node to display to the user if escalation to Cention fails",
          suggestions: this.getContentNodeSuggestions,
        },
        AGENT_UNAVAILABLE_CONTENT: {
          type: "autocomplete",
          default: "cention_agent_unavailable",
          label: "Agent Unavailable Content",
          detail:
            "Content node to display to the user if there's no available agents to serve them",
          suggestions: this.getContentNodeSuggestions,
        },
        QUEUE_FULL_CONTENT: {
          type: "autocomplete",
          default: "cention_queue_full",
          label: "Queue Full Content",
          detail: "Content node to display to the user if the queue is full",
          suggestions: this.getContentNodeSuggestions,
        },
        OUTSIDE_WORKING_HOURS: {
          type: "autocomplete",
          default: "cention_outside_working_hours",
          label: "Outside Working Hours Content",
          detail:
            "Content node to display to the user if escalation happens outside configured working hours",
          suggestions: this.getContentNodeSuggestions,
        },
        FINISH_CHAT_SESSION: {
          type: "autocomplete",
          default: "cention_finish_chat_session",
          label: "Finish Chat Session Content",
          detail:
            "Content node to display to the user if the chat session is ended",
          suggestions: this.getContentNodeSuggestions,
        },
      };
    },
  },
};

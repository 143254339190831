<template>
  <div>
    <span style="font-size:32px; font-weight: light;">
      <b>Content Flow</b>
    </span>
    <div style="margin-top: 20px;">
      <el-row>
        <el-col
          :span="12"
          style="height: 40px; display: flex; flex-direction: row; flex-direction: row; align-items: center;"
        >
          <div>
            <span>Currently showing intent:</span>
            <span
              style="color: #E4392B; margin-left: 5px;"
              id="selected-intent-text"
            >{{ selectedIntent }}</span>
          </div>
        </el-col>
        <el-col :span="12" style="text-align: right;">
          <div>
            <el-button id="toggle-intent-drawer-btn" @click="toggleIntentDrawer">Change intent</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div id="flowchart-canvas" style="margin-top: 20px;">
      <custom-flow
        @jumpMethod="jumpMethod"
        :sceneId="selectedIntent"
        :scene.sync="scene"
        :showDrawer.sync="showDrawer"
        @nodeClick="nodeClick"
        @nodeDelete="nodeDelete"
        @linkBreak="linkBreak"
        @linkAdded="linkAdded"
        @buttonAdded="buttonAdded"
        @buttonUpdated="buttonUpdated"
        @buttonDeleted="buttonDeleted"
        @canvasClick="canvasClick"
        @onDropNewNode="onCreateNode"
        @notification="notificationUp($event.title, $event.type, $event.auto)"
        @addErrors="addErrors($event)"
        @onChangeVersion="onChangeVersion"
        :height="800"
      />
    </div>
  </div>
</template>

<script>
import CustomFlow from "simple-flowchart";
import "simple-flowchart/dist/vue-flowchart.css";
// import CustomFlow from "@/components/CustomFlow/src/components/SimpleFlowchart.vue";
import {
  flowchartSetup,
  changeVersion
} from "@/helperMethods/simple_flowchart";
import _ from "lodash";
import {
  validateContent,
  validateButton,
  validateQuickReplies,
  validateLinks
} from "@/helperMethods/task_management/content";

export default {
  components: {
    CustomFlow
  },
  data() {
    return {
      notifications: [],
      errors: [],
      showDrawer: {
        left: false,
        right: false
      },
      scene: {
        startNodeTitle: "Conversation Start",
        centerX: 0,
        centerY: 0,
        scale: 1,
        nodes: [],
        links: []
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    jumpMethod(e) {
      this.$router.push("/editor");
      this.$store.commit("SET_EDITOR_ACTIVETAB", "flows");
      // alert("it will jump, don't worry" + e);
    },
    clearData() {
      this.scene.nodes = [];
      this.scene.links = [];
    },
    initData() {
      this.scene.centerX = 0;
      this.scene.centerY = 0;
      this.scene.nodes = [
        ...this.$store.state.mappedFlow.nodes.map(item => {
          const contentValid = validateContent(item.id);
          const buttonsValid = validateButton(item.id);
          const quickRepliesValid = validateQuickReplies(item.id);
          const linksValid = validateLinks(item.id);
          return {
            ...item,
            invalid: !!(
              contentValid |
              buttonsValid |
              quickRepliesValid |
              linksValid
            )
          };
        })
      ];
      this.scene.links = this.$store.state.mappedFlow.links.map(item => ({
        ...item,
        button: item.quickReply != null ? item.quickReply : item.button
      }));
    },
    toggleIntentDrawer() {
      this.$store.commit("TOGGLE_INTENT_SELECTOR", true);
    },
    exportFlow(json) {
      console.log(json);
    },
    addErrors(e) {
      console.log("error flow", e);
    },
    canvasClick(e) {
      console.log("canvas Click, event:", e);
    },
    notificationUp(title, type, auto) {
      const id = Math.max(0, ...this.notifications.map(item => item.id)) + 1;
      const notification = {
        id,
        status: true,
        title,
        type,
        auto
      };
      this.notifications.unshift(notification);
      if (this.notifications[0].auto) {
        this.notificationDown(id);
      }
    },
    notificationDown(id) {
      setTimeout(() => {
        this.clearNotification(id);
      }, 3000);
    },
    clearNotification(id) {
      const notification = this.notifications.find(item => item.id === id);
      notification.status = false;
      // this.notifications = this.notifications.filter((item) => item.id !== id);
    },
    onCreateNode({ x, y, nodeType, label }) {
      let maxID = Math.max(
        0,
        ...this.scene.nodes.map(link => {
          return link.id;
        })
      );
      this.scene.nodes.push({
        id: maxID + 1,
        x,
        y,
        type: nodeType,
        label: label,
        isStart: false,
        buttons: []
      });
    },
    nodeClick(id) {
      console.log("node click", id);
    },
    nodeDelete(id) {
      console.log("node delete", id);
    },
    linkBreak(id) {
      console.log("link break", id);
    },
    linkAdded(link) {
      console.log("new link added:", link);
    },
    buttonAdded(obj) {
      console.log("new button added:", obj);
    },
    buttonUpdated(obj) {
      console.log("button updated:", obj);
    },
    buttonDeleted(obj) {
      console.log("button deleted:", obj);
    },
    closingDrawer(done) {
      done();
      // this.$confirm('Are you sure you want to close this?')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
    onChangeVersion(version, id) {
      const flowsData = _.get(this.$store.state, "training.flows", []);
      const selectedIntent = this.$store.state.selectedIntent;
      const mappedFlowNodes = this.$store.state.mappedFlow.nodes;

      const result = changeVersion(
        version,
        id,
        selectedIntent.id,
        flowsData,
        mappedFlowNodes
      );

      this.$store.commit("SET_MAPPED_FLOW", result);
    }
  },
  watch: {
    "$store.state.mappedFlow": {
      handler: function(val) {
        // load new data after all data being cleared
        this.clearData();
        this.$nextTick(() => {
          this.initData();
        });
      },
      deep: true
    }
  },
  computed: {
    nodes() {
      return this.$store.state.nodes.content;
    },

    selectedIntent() {
      return _.get(this.$store.state.selectedIntent, "name", "")
        ? _.get(this.$store.state.selectedIntent, "name", "").toUpperCase()
        : "";
    }
  }
};
</script>

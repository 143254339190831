<template>
  <div style="font-size: 14px">
    <label style="position: relative; right: 10px">Server Status:</label>
    <el-tooltip
      v-if="graphQLWebsocketStatus === 'connected'"
      class="item"
      effect="dark"
      placement="bottom-end"
    >
      <div slot="content">
        <i
          class="el-icon-success"
          style="color: green; position: relative; right: 5px"
        ></i>
        <label>You are connected to the server, let's start chatting!</label>
      </div>
      <div style="display: inline-block">
        <i
          class="el-icon-success"
          style="color: green; position: relative; right: 5px"
        ></i>
        <label style="color: green; position: relative">Connected</label>
      </div>
    </el-tooltip>
    <el-tooltip
      v-else-if="graphQLWebsocketStatus === 'connecting'"
      class="item"
      effect="dark"
      placement="bottom-end"
    >
      <div slot="content">
        <i
          class="el-icon-warning"
          style="color: #eb9e05; position: relative; right: 5px"
        ></i>
        <label>We are attempting to connect you to the server.</label>
      </div>
      <div style="display: inline-block">
        <i
          class="el-icon-warning"
          style="color: #eb9e05; position: relative; right: 5px"
        ></i>
        <label style="color: #eb9e05; position: relative">Connecting...</label>
      </div>
    </el-tooltip>
    <el-tooltip v-else class="item" effect="dark" placement="bottom-end">
      <div slot="content">
        <i
          class="el-icon-error"
          style="color: red; position: relative; right: 5px"
        ></i>
        <label>
          Unable to connect to the server, please refresh this page and try
          again.
        </label>
      </div>
      <div style="display: inline-block">
        <i
          class="el-icon-error"
          style="color: red; position: relative; right: 5px"
        ></i>
        <label style="color: red; position: relative">Disconnected</label>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: ["graphQLWebsocketStatus"],
};
</script>

<template>
  <el-tooltip
    v-if="$store.getters.isShowAdvanced"
    effect="dark"
    content="Delete bot"
    placement="bottom"
  >
    <el-button
      circle
      size="small"
      class="hidden-sm-and-down"
      type="danger"
      :loading="deleting"
      :plain="true"
      icon="el-icon-delete"
      @click="deleteBot"
    />
  </el-tooltip>
</template>

<script>
export default {
  name: "BotDeletor",
  props: ["setStatus", "deleting"],
  methods: {
    deleteBot() {
      this.$confirm(
        "This will permanently delete the bot. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "danger",
        }
      )
        .then(() => {
          this.setStatus("deleting", true);
          this.$store
            .dispatch("DELETE_BOT")
            .then(() => {
              this.$message({
                type: "success",
                message: "Delete completed",
              });
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: "Delete failed: " + error.message,
              });
            })
            .finally(() => this.setStatus("deleting", false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <el-form-item prop="key">
      <el-input v-model="value.data.key" placeholder="key">
        <template slot="prepend">Key</template>
      </el-input>
    </el-form-item>
    <el-radio-group
      v-model="captureValueType"
      @change="toggleDataValue"
      style="margin-bottom: 22px"
    >
      <el-radio-button label="null" style="margin-right: 0px"
        >User Input</el-radio-button
      >
      <el-radio-button label="string" style="margin-right: 0px"
        >Text</el-radio-button
      >
      <el-radio-button label="number" style="margin-right: 0px"
        >Number</el-radio-button
      >
      <el-radio-button label="boolean" style="margin-right: 0px"
        >Boolean</el-radio-button
      >
    </el-radio-group>

    <el-form-item prop="value" v-if="captureValueType === 'string'">
      <el-input v-model="value.data.value" placeholder="value">
        <template slot="prepend">Value</template>
      </el-input>
    </el-form-item>

    <el-form-item prop="value" v-else-if="captureValueType === 'number'">
      <el-input v-model.number="value.data.value" placeholder="value">
        <template slot="prepend">Value</template>
      </el-input>
    </el-form-item>

    <el-row v-else-if="captureValueType === 'boolean'">
      <el-switch
        v-model="value.data.value"
        style="margin-top: 1.2em; margin-bottom: 0em"
        active-text="True"
        inactive-text="False"
      />
    </el-row>

    <br />
    <br />

    <EventNodeSelectorNext v-model="value" />
  </div>
</template>

<script>
import EventNodeSelectorNext from "../Next.vue";
import _ from "lodash";
export default {
  name: "EventNodeTypeCapture",
  components: {
    EventNodeSelectorNext,
  },
  props: ["value"],
  data() {
    return {
      captureValueType: "null",
    };
  },
  beforeMount() {
    if (this.value.data === null) {
      this.captureValueType = "null";
      this.value.data = {
        key: "",
        value: null,
        next: {
          event: "",
          data: "",
        },
      };
    } else if (typeof this.value.data === "string") {
      this.captureValueType = "string";
      this.value.data = {
        key: "",
        value: "",
        next: {
          event: "",
          data: "",
        },
      };
    }

    this.$nextTick(function () {
      const value = _.get(this, "value.data.value");
      if (value == null) {
        this.captureValueType = "null";
      } else {
        this.captureValueType = typeof value;
      }
    });
  },
  methods: {
    /**
     * @description On capture type radio button selected
     * @param {string} value
     * @return {void}
     */
    toggleDataValue(value) {
      switch (value) {
        case "null":
          this.value.data.value = null;
          this.$set(this.value.data, "value", null);
          break;
        case "boolean":
          this.$set(this.value.data, "value", true);
          break;
        case "number":
          this.$set(this.value.data, "value", 0);
          break;
        default:
          this.$set(this.value.data, "value", "");
      }
    },
  },
  watch: {
    value(newVal) {
      const value = _.get(newVal, "data.value");
      if (value == null) {
        this.captureValueType = "null";
      } else {
        this.captureValueType = typeof value;
      }
    },
  },
};
</script>

<template>
  <div>
    <el-tabs v-model="activeTab" @tab-click="switchTab">
      <el-tab-pane label="Workbench" name="workbench">
        <HomeTabWorkbench
          @onViewMore="onViewMore"
          @getTasks="getTasks"
          :task-loading="taskLoading"
          :overall-tasks="overallTasks"
          :taskData="taskData"
        />
      </el-tab-pane>
      <el-tab-pane v-if="makerCheckerObject.enabled" label="Tasks" name="tasks">
        <HomeTabTask
          v-if="activeTab === 'tasks'"
          :new-fetch-intent="fetchIntents"
          :test="taskLoading"
          :unique-count-changes-by-user="uniqueCountChangesByUser"
          :fetch-editor="fetchEditor"
          :fetch-entities="fetchEntities"
          :fetch-intents="fetchIntents"
          :fetch-dialogs="fetchDialogs"
          :overall-tasks="overallTasks"
          :home-active-left-pane="homeActiveLeftPane"
          :home-active-tab="activeTab"
          :home-task-main-type="taskMainType"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="makerCheckerObject.publish && makerCheckerObject.enabled"
        label="Publisher"
        name="publisher"
      >
        <HomeTabPublisher v-if="activeTab === 'publisher'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  checkRoles,
  getPermittedRoles,
  showModule,
} from "@/helperMethods/auth";
import { getDepartmentVerifiedChanges } from "@/helperMethods/makerChecker";
import { getOtherTasks } from "@/helperMethods/task_management";
// Share methods with hometask.vue

import HomeTabPublisher from "./TabPublisher/Index";
import HomeTabTask from "./TabTask/Index";
import HomeTabWorkbench from "./TabWorkbench/Index";

export default {
  components: {
    HomeTabTask,
    HomeTabPublisher,
    HomeTabWorkbench,
    // HomeChatSummary,
    // Ratings
  },
  data() {
    return {
      // Task column
      taskLoading: false,
      taskData: [],
      homeActiveLeftPane: "pending",
      taskMainType: "",

      activeTab: "workbench",
    };
  },
  computed: {
    ...mapGetters([
      "getUserAuthentication",
      "makerCheckerObject",
      "departments",
    ]),

    isAuthorizedToRead() {
      const { roles } = this.getUserAuthentication;
      const editorApproveRoles = getPermittedRoles("editor_approve");
      const editorRoles = getPermittedRoles("editor");
      const mergedRoles = _.uniq([...editorApproveRoles, ...editorRoles]);
      const isAuthorized = checkRoles(mergedRoles);
      const adminRole = roles && roles.includes("admin");

      return isAuthorized || adminRole;
    },
    overallTasks() {
      if (!this.isAuthorizedToRead) {
        return [];
      }

      let currentList = this.taskData;
      let selectedStatus =
        this.activeTab === "tasks" ? this.activeLeftPane : "pending";

      const defaultUserObject = {
        keys: [],
        values: [],
      };
      const { departments, permissions, roles } = this.getUserAuthentication;

      const editedDepartments = _.map(
        _.cloneDeep(departments),
        (v) => v && v.toLowerCase()
      );
      editedDepartments.push("general");
      // console.log("User's department: ", editedDepartments);

      let currentDepartmentFilteredList = getDepartmentVerifiedChanges(
        currentList,
        editedDepartments
      );

      const table = _.chain(currentDepartmentFilteredList)
        .filter((change) => {
          return selectedStatus === "pending"
            ? ["add", "modify", "delete"].includes(change.status)
            : ["approve", "reject"].includes(change.status);
        })
        .uniqBy((v) => {
          return selectedStatus === "pending"
            ? v.mainType
            : [v.mainType, v.last_modified].join();
        })
        .map((change) => {
          const { mainType, last_modified } = change;
          switch (mainType) {
            case "intent":
            case "example":
              return {
                type: "Intents",
                mainType: "intent",
                list:
                  this.fetchIntents(
                    currentList,
                    editedDepartments,
                    last_modified
                  ) || [],
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchIntents(
                      currentList,
                      editedDepartments,
                      last_modified
                    )
                  ) || defaultUserObject,
              };
            case "entity":
            case "value":
            case "pattern":
            case "synonym":
              return {
                type: "Entities",
                mainType: "entity",
                list:
                  this.fetchEntities(
                    currentList,
                    editedDepartments,
                    last_modified
                  ) || [],
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchEntities(
                      currentList,
                      editedDepartments,
                      last_modified
                    )
                  ) || defaultUserObject,
              };
            case "dialog":
              return {
                type: "Dialogs",
                mainType: "dialog",
                list:
                  this.fetchDialogs(
                    currentList,
                    editedDepartments,
                    last_modified
                  ) || [],
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchDialogs(
                      currentList,
                      editedDepartments,
                      last_modified
                    )
                  ) || defaultUserObject,
              };
            case "editor":
              return {
                type: "Editor",
                mainType: "editor",
                list:
                  this.fetchEditor(
                    currentList,
                    editedDepartments,
                    last_modified
                  ) || {},
                users:
                  this.uniqueCountChangesByUser(
                    this.fetchEditor(
                      currentList,
                      editedDepartments,
                      last_modified
                    )
                  ) || defaultUserObject,
              };
          }
        })
        .orderBy(["last_modified", "type"], ["desc", "asc"])
        .value();

      return table;
    },
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    showModule,
    getDepartmentVerifiedChanges,

    onViewMore(event) {
      (this.activeTab = event.activeTab),
        (this.homeActiveLeftPane = event.homeActiveLeftPane);
      this.taskMainType = event.taskMainType;
    },
    switchTab() {
      switch (this.activeTab) {
        case "workbench":
          this.getTasks();
          break;
      }
    },
    getTasks() {
      const makerCheckerEnabled =
        this.makerCheckerObject && this.makerCheckerObject.enabled;
      if (makerCheckerEnabled) {
        this.taskLoading = true;
        // This will call to changes table
        this.$store
          .dispatch("FETCH_CHANGES", { status: "pending" })
          .then((changes) => {
            this.taskLoading = false;
            this.taskData = changes;
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: "Encountered error. Please refresh and try again.",
            });
          });
      }
    },
    uniqueCountChangesByUser(changeBatch) {
      const uniqueUsers = _.chain(changeBatch)
        .cloneDeep()
        .countBy("modified_by")
        .value();

      return uniqueUsers;
    },
    fetchIntents(
      chosenChangeList,
      departments,
      last_modified,
      selectedStatus = "pending"
    ) {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            if (selectedStatus === "pending") {
              return true;
            } else {
              return change.last_modified === last_modified;
            }
          })
          .filter((change) => {
            if (change) {
              const type =
                change.type.includes("_") && change.type.split("_")[1];
              return (
                ["intent", "example"].includes(type) &&
                (selectedStatus === "pending"
                  ? ["add", "modify", "delete"].includes(change.status)
                  : ["approve", "reject"].includes(change.status))
              );
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
    fetchEntities(
      chosenChangeList,
      departments,
      last_modified,
      selectedStatus = "pending"
    ) {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            return selectedStatus === "pending"
              ? true
              : change.last_modified === last_modified;
          })
          .filter((change) => {
            if (change) {
              const type =
                change.type.includes("_") && change.type.split("_")[1];
              return (
                ["entity", "value", "synonym", "pattern"].includes(type) &&
                (selectedStatus === "pending"
                  ? ["add", "modify", "delete"].includes(change.status)
                  : ["approve", "reject"].includes(change.status))
              );
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
    fetchDialogs(
      chosenChangeList,
      departments,
      last_modified,
      selectedStatus = "pending"
    ) {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            return selectedStatus === "pending"
              ? true
              : change.last_modified === last_modified;
          })
          .filter((change) => {
            if (change) {
              const type =
                change.type.includes("_") && change.type.split("_")[1];
              return (
                ["dialog"].includes(type) &&
                (selectedStatus === "pending"
                  ? ["add", "modify", "delete"].includes(change.status)
                  : ["approve", "reject"].includes(change.status))
              );
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
    fetchEditor(
      chosenChangeList,
      departments,
      last_modified,
      selectedStatus = "pending"
    ) {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            return selectedStatus === "pending"
              ? true
              : change.last_modified === last_modified;
          })
          .filter((change) => {
            if (change) {
              const [source, type] =
                change.type.includes("_") && change.type.split("_");
              return (
                ["editor"].includes(source) &&
                ["content", "trigger", "form"].includes(type) &&
                (selectedStatus === "pending"
                  ? ["add", "modify", "delete"].includes(change.status)
                  : ["approve", "reject"].includes(change.status))
              );
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
  },
  mounted() {
    this.getTasks();

    if (this.$store.state.modules["task_management"].enabled) {
      getOtherTasks();
    }
  },
};
</script>

<style>
#taskTable {
  width: 100%;
  height: 100%;
}

#taskTable .el-table__body-wrapper {
  max-height: 450px !important;
}

.hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
  border-color: #ebeef5;
  margin-bottom: 1.5em;
  margin-left: 2em;
}

.bug-dialog {
  margin-top: 50px !important;
}
.approve-dialog-popup .el-dialog__body {
  margin-right: 3em;
  padding-bottom: 10px !important;
}
.button {
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
  background: #409eff !important;
  color: white !important;
  border-color: #409eff !important;
}

.button:hover {
  background: white !important;
  color: #409eff !important;
  border-color: #409eff !important;
}

.approve.button {
  color: #fff !important;
  background-color: #67c23a !important;
  border-color: #67c23a !important;
}

.approve.button:hover {
  color: #67c23a !important;
  background-color: white !important;
  border-color: #67c23a !important;
}

.dismiss.button {
  color: #fff !important;
  background-color: #909399 !important;
  border-color: #909399 !important;
}

.dismiss.button:hover {
  color: #909399 !important;
  background-color: white !important;
  border-color: #909399 !important;
}

.box {
  font-size: 20px;
  line-height: 20px;
  text-align: center;

  padding-top: 50px;
  padding-bottom: 50px;
  border: 2px solid #e4392b;
  background: white;
  color: #e4392b;
  width: 100%;

  transition: border 0.5s, background 0.5s;
}

.box:hover {
  background: #e4392b;
  color: white;
}

.tasks.approve {
  background-color: #67c23a;
}

.feedback.subheaders {
  color: #707070;
}

.followup {
  color: #707070;
  font-size: 14px;
  padding-bottom: 5px;
}

.dialog.buttons {
  text-align: right;
}
</style>

import { ActionTree } from "vuex";
import { graph, rest } from "@/store/api";
import { RootState } from "../../types";

import gql from "graphql-tag";
import _ from "lodash";
import { ApiEditorState } from "./types";

const actions: ActionTree<ApiEditorState, RootState> = {
  FETCH_ALL_APIS: ({ state, commit }) => {
    return graph
      .query({
        query: gql`
          query {
            Bot {
              apiSettings
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((payload) => {
        const data: any = _.cloneDeep(payload.data);
        console.warn("data", data);
        // console.log(apiArray);
        const apiListObject: any = {};
        if (
          Array.isArray(data.Bot.apiSettings) &&
          data.Bot.apiSettings.length > 0
        ) {
          data.Bot.apiSettings.forEach(function (element: any) {
            apiListObject[element[0]] = element[1];
          });
        }
        commit("SET_APIS", apiListObject);
        return apiListObject;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_ALL_MAPPINGS: ({ state, commit }) => {
    return graph
      .query({
        query: gql`
          query {
            Bot {
              apiMappings
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((payload) => {
        const data: any = _.cloneDeep(payload.data);
        // console.log(apiArray);
        const mappingListObject: any = {};
        if (
          Array.isArray(data.Bot.apiMappings) &&
          data.Bot.apiMappings.length > 0
        ) {
          data.Bot.apiMappings.forEach(function (element: any) {
            mappingListObject[element[0]] = element[1];
          });
        }
        commit("SET_API_MAPPINGS", mappingListObject);
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_API: ({ commit }, { apiName }) => {},
  CREATE_API: ({ commit }, { apiName }) => {
    // console.log(`Create API ${apiName}`);
    return graph
      .mutate({
        mutation: gql`
          mutation($apiName: String!) {
            apiEditorAPI {
              createApi(apiName: $apiName)
            }
          }
        `,
        variables: { apiName },
      })
      .then((response) => {
        console.log("CREATE_API Response: ", response);
        const data = _.get(response, "data.apiEditorAPI.createApi");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  CREATE_API_MAPPING: ({ commit }, { apiMappingName, api }) => {
    console.log(`Create API mapping ${apiMappingName}`);
    return graph
      .mutate({
        mutation: gql`
          mutation($apiMappingName: String!, $api: String!) {
            apiEditorAPI {
              createApiMapping(apiMappingName: $apiMappingName, api: $api)
            }
          }
        `,
        variables: { apiMappingName, api },
      })
      .then((response) => {
        console.log("CREATE_API_MAPPING Response: ", response);
        const data = _.get(response, "data.apiEditorAPI.createApiMapping");
        commit("CREATE_API_MAPPING", data);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  SAVE_API: ({ commit }, { apiName, apiObject }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($apiName: String!, $apiObject: JSON!) {
            apiEditorAPI {
              saveApi(apiName: $apiName, apiObject: $apiObject)
            }
          }
        `,
        variables: { apiName, apiObject },
      })
      .then((response) => {
        console.log("SAVE_API Response: ", response);
        const data = _.get(response, "data.apiEditorAPI.saveApi");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  SAVE_API_MAPPING: ({ commit }, { apiMappingName, mappingObject }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($apiMappingName: String!, $mappingObject: JSON!) {
            apiEditorAPI {
              saveApiMapping(
                apiMappingName: $apiMappingName
                mappingObject: $mappingObject
              )
            }
          }
        `,
        variables: { apiMappingName, mappingObject },
      })
      .then((response) => {
        console.log("SAVE_API_MAPPING Response: ", response);
        const data = _.get(response, "data.apiEditorAPI.saveApiMapping");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_API: ({ state, commit }, { apiName }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($apiName: String!) {
            apiEditorAPI {
              deleteApi(apiName: $apiName)
            }
          }
        `,
        variables: { apiName },
      })
      .then((response) => {
        console.log("DELETE_API Response: ", response);
        commit("DELETE_API", apiName);
        const data = _.get(response, "data.apiEditorAPI.deleteApi");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_API_MAPPING: ({ commit }, { apiMappingName }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($apiMappingName: String!) {
            apiEditorAPI {
              deleteApiMapping(apiMappingName: $apiMappingName)
            }
          }
        `,
        variables: { apiMappingName },
      })
      .then((response) => {
        console.log("DELETE_API_MAPPING Response: ", response);
        commit("DELETE_API_MAPPING", apiMappingName);
        const data = _.get(response, "data.apiEditorAPI.deleteApiMapping");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  PREVIEW_API_DATA: ({ commit }, { apiName, apiUrl, body, testData }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $apiName: String!
            $apiUrl: String!
            $body: String!
            $testData: JSON!
          ) {
            apiEditorAPI {
              previewApiData(
                apiName: $apiName
                apiUrl: $apiUrl
                body: $body
                testData: $testData
              )
            }
          }
        `,
        variables: { apiName, apiUrl, body, testData },
      })
      .then((response: any) => {
        return _.get(response, "data.apiEditorAPI.previewApiData");
      })
      .catch((err) => {
        throw err;
      });
  },
};
export default actions;

// @ts-nocheck
export default {
  mounted() {},
  computed: {
    departmentOptions() {
      const defaultDepartment = {
        label: "General",
        value: "00000000-0000-0000-0000-000000000000",
      };
      const userDefinedDepartments = _.map(this.departments, (department) => {
        return {
          label: department.name,
          value: department.id,
        };
      });
      return userDefinedDepartments.concat([defaultDepartment]);
    },
    whatsappNotificationGroupsOptions() {
      return _.map(
        this.whatsappEndpointGroups,
        (groupArr, whatsappEndpoint) => {
          const groupDepartments = _.map(groupArr, (group) => {
            return {
              label: group.subject,
              value: group.id,
              children: this.departmentOptions,
            };
          });
          const serverObj = _.find(
            this.whatsappEndpoints,
            (endpointObj) => endpointObj.url === whatsappEndpoint
          );
          if (serverObj) {
            return {
              label: serverObj.wa_id,
              value: whatsappEndpoint,
              children: groupDepartments,
            };
          }
        }
      );
    },
    whatsappEndpointGroups() {
      return this.$store.state.whatsapp.groups;
    },
    whatsappEndpoints() {
      return this.$store.state.whatsapp.servers;
    },
    telegramNotificationGroupsOptions() {
      const telegramGroupsString = _.get(
        this.$store.state.modules,
        "telegram.TELEGRAM_PUSH_NOTIFICATION_GROUPS"
      );
      if (!telegramGroupsString) {
        return [];
      }
      let telegramGroups = telegramGroupsString.split(";");
      telegramGroups = _.compact(telegramGroups);
      return _.map(telegramGroups, (group) => {
        return {
          label: group,
          value: group,
          children: this.departmentOptions,
        };
      });
    },
    handoverFormMixin(): any {
      return {
        ameyo: {
          default: false,
          label: "Use Ameyo Handover",
          type: "boolean",
        },
        facebook: {
          default: false,
          label: "Use Facebook Inbox Handover",
          type: "boolean",
        },
        facebook_inbox_app_id: {
          default: "",
          label: "FB Inbox app id",
          type: "string",
        },
        useEmail: {
          default: false,
          label: "Use Email Handover",
          type: "boolean",
          detail:
            "Email will be sent to the email address/es indicated under 'Email Address'",
        },
        zendesk: {
          default: false,
          label: "Use Zendesk Handover",
          type: "boolean",
        },
        zendesk_app_id: {
          default: "",
          label: "Zendesk app id",
          type: "string",
        },
        email: {
          default: "",
          label: "Email Address",
          type: "string",
        },
        bcc: {
          default: "",
          label: "BCC Email",
          type: "string",
          detail:
            "To add multiple emails, seperate them by ';' eg. email_1@mail.com;email_2@mail.com",
        },
        emailSubject: {
          default: "",
          label: "Email subject header",
          type: "string",
          detail: "Type your template subject header here...",
        },
        emailTemplate: {
          default: "",
          label: "Email Template",
          type: "textarea",
          detail:
            "Email template you wish to include at the start of the email",
          placeholder: "Type your template here...",
        },
        uccx: {
          default: false,
          label: "Use UCCX Social Miner Handover",
          type: "boolean",
          detail: "Enable this when you need UCCX interface.",
        },
        ignoreAgentAvailability: {
          default: false,
          label: "Ignore Agent Availability",
          type: "boolean",
          detail:
            "Allow users to enter the queue even if there's no agents online",
        },
        extractJWTPayloadTags: {
          default: false,
          label: "Extract JWT Payload to Tags",
          type: "boolean",
          detail: "Extract post login user information from token payload",
        },
        whatsappNotification: {
          default: false,
          label: "Enable WhatsApp Notifications",
          type: "boolean",
        },
        whatsappNotificationInterval: {
          default: 300,
          min: 1,
          label: "Notification Interval (in seconds)",
          type: "number",
        },
        whatsappNotificationChatAgeThreshold: {
          default: 1,
          label: "Notifications Max Chat Age Threshold",
          type: "number",
          min: 1,
          max: 7,
          detail: `Configures the max age of a chat for notifications. Notifications will only be sent for chats newer than the threshold`,
        },
        whatsappNotificationGroups: {
          default: [],
          label: "Notification Groups",
          type: "CascaderWithHeaders",
          options: this.whatsappNotificationGroupsOptions,
          props: { multiple: true },
          "collapse-tags": true,
          clearable: false,
          detail: `Maps departments to groups for WhatsApp notifications`,
          headers: ["WhatsApp Numbers", "WhatsApp Groups", "Departments"],
        },
        telegramNotification: {
          default: false,
          label: "Enable Telegram Notifications",
          type: "boolean",
        },
        telegramNotificationInterval: {
          default: 300,
          min: 1,
          label: "Telegram Notification Interval (in seconds)",
          type: "number",
        },
        telegramNotificationChatAgeThreshold: {
          default: 1,
          label: "Telegram Notifications Max Chat Age Threshold",
          type: "number",
          min: 1,
          max: 7,
          detail: `Configures the max age of a chat for notifications. Notifications will only be sent for chats newer than the threshold`,
        },
        telegramNotificationGroups: {
          default: [],
          label: "Telegram Notification Groups",
          type: "CascaderWithHeaders",
          options: this.telegramNotificationGroupsOptions,
          props: { multiple: true },
          "collapse-tags": true,
          clearable: false,
          detail: `Maps departments to groups for Telegram notifications`,
          headers: ["Telegram Groups", "Departments"],
        },
        livechat: {
          default: false,
          label: "Use KeyReply Live Chat Handover",
          type: "boolean",
        },
        disableMonitorTab: {
          default: false,
          label: "Disable Monitor tab in Live Chat page",
          type: "boolean",
        },
        chat_limit: {
          default: 0,
          min: 0,
          label: "Agent Chat Limit",
          type: "number",
        },
        averageHandlingTime: {
          default: 3,
          min: 1,
          label: "Time Needed for Each Session (in minutes)",
          type: "number",
        },
        chat_routing_mode: {
          label: "Chat Routing Mode",
          type: "select",
          detail:
            "Broadcast: All agents get notified of all relevant chats, and the agent has to click on Serve Request to start serving the chat. This is the default setting. Auto Assigned: Chats are evenly assigned amongst online agents, such that only one agent is notified of each incoming chat at any time.",
          default: "broadcast",
          options: [
            {
              label: "Broadcast",
              value: "broadcast",
            },
            {
              label: "Auto Assigned",
              value: "assignment",
            },
          ],
        },
        show_users_waiting_for_assignment: {
          default: true,
          label:
            "Show waiting users (All Other Chats) for auto assignment mode.",
          type: "boolean",
        },
        show_agent_join_leave_events: {
          default: true,
          label: "Show Agent Join/Leave events to the user",
          detail: "Users will be able to see agents join or leave the chat",
          type: "boolean",
        },
        chatTitle: {
          default: "",
          label: "Customized Chat Title (dashboard)",
          type: "string",
          detail:
            "Customize chat title in dashboard, wrap ${variable} from state.",
        },
        disablePostbackDuringLivechat: {
          default: false,
          label: "Disable all buttons & quick replies during livechat",
          type: "boolean",
        },
        cobrowse: {
          default: {
            enabled: false,
            apiKey: "",
            authToken: "",
          },
          type: "object",
          label: "Co-Browsing",
          detail: "Co-browse with user to provide additional support.",
          children: {
            enabled: {
              default: false,
              type: "boolean",
            },
            apiKey: {
              default: "",
              type: "string",
              label: "API Key",
              protected: true,
              detail: "API key from cobrowse.io",
            },
            authToken: {
              default: "",
              type: "string",
              label: "Auth Token",
              protected: true,
              detail: "Fallback/Default JWT token of Cobrowse account",
            },
            authTokens: {
              default: { "someone@email.com": "jwtToken" },
              type: "json",
              label: "Agent's Token",
              detail: "JWT token of each Agents",
            },
            promptTitle: {
              default: "Support Request",
              label: "Prompt Title",
              type: "string",
              detail: "Will shown as a title of request prompt on user side",
            },
            promptMessage: {
              default: "Allow support agent to use this web page with you?",
              label: "Prompt Label",
              type: "string",
              detail: "Will shown as a message of request prompt on user side",
            },
            startContentNode: {
              default: "handover_cobrowse_session_started",
              label: "Node when started",
              type: "string",
              detail: "The node_id bot will show when cobrowse started",
            },
            stopContentNode: {
              default: "handover_cobrowse_session_stopped",
              label: "Node when stopped",
              type: "string",
              detail: "The node_id bot will show when cobrowse stopped",
            },
          },
        },
        limitLivechatQueueableChats: {
          default: {
            enabled: false,
            max: 20,
          },
          type: "object",
          label: "Limit maximum number of chats that livechat able to queue.",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "Enable",
            },
            max: {
              default: 20,
              min: 0,
              type: "number",
              label: "Maximum number",
              detail: "The number of chats available to queue for livechat.",
            },
          },
        },
        handoverRouting: {
          type: "object",
          default: {},
          label: "Handover Routing",
          children: {
            byDepartment: {
              default: false,
              type: "boolean",
              label: "By Department",
              detail:
                "Livechat escalation to agent based on user selected department in state (userQueryTopic).",
            },
            // bySkill: {
            //   default: false,
            //   type: "boolean",
            //   label: "By Skill Set",
            //   detail: "Livechat escalation to agent based on skiis being tagged and conditions check from state variables.",
            // },
            // skillsLibrary: {
            //   default: [],
            //   type: "HandoverRoutingSkillsLibrary",
            //   label: "Skills library",
            //   detail: "Skills library to be tagged to user."
            // }
          },
        },
        predefinedTags: {
          type: "object",
          default: {},
          label: "Predefined Tags",
          detail: "When enable, agent can choose the tag from dropdown instead of typing",
          children: {
            enabled: {
              default: false,
              type: "boolean",
            },
            delimiter: {
              default: ">",
              type: "string",
              label: "Hierarchy delimiter",
              detail: "Symbol/Delimiter that will show in tag label (when tags include hierarchy)",
            },
            tags: {
              default: [],
              type: "json",
              label: "Tags",
              detail: "Tags that will show in dropdown",
            },
            validateDepartment: {
              type: "object",
              default: {
                enabled: false,
                level: 1,
              },
              label: "Department validation",
              children: {
                enabled: {
                  default: false,
                  type: "boolean",
                  label: "Validate with department",
                },
                level: {
                  min: 1,
                  default: 1,
                  type: "number",
                  label: "Department level on tags",
                },
              },
            },
          },
        },
        autoPopulateCustomTags: {
          type: "object",
          default: {},
          label: "Custom Tags",
          detail: "Ability to auto populate tags from state variable",
          children: {
            enabled: {
              default: false,
              type: "boolean",
            },
            keys: {
              default: "",
              type: "textarea",
              label: "Variables",
              detail:
                "Comma separated state keys to populated. Example: agentDisplayName,isPreview",
            },
          },
        },
        customUserProfile: {
          type: "object",
          default: {},
          label: "Custom User Profile Fields",
          detail: "Ability to add custom user profile field for livechat",
          children: {
            enabled: {
              default: false,
              type: "boolean",
            },
            fields: {
              default: {
                gender: {
                  type: "select",
                  label: "User's Gender",
                  default: "male",
                  options: [
                    {
                      label: "Male",
                      value: "male",
                    },
                    {
                      label: "Female",
                      value: "female",
                    },
                  ]
                },
                address: {
                  type: "string",
                  label: "User's Address",
                  default: "",
                },
              },
              type: "json",
              label: "Fields",
              detail: `Object of custom fields. Available types: string, textare, select, multiselect`,
            },
          },
        },
        livechatStatusMonitor: {
          type: "object",
          default: {},
          label: "Livechat Status Monitor",
          children: {
            showAgentsStatus: {
              default: false,
              type: "boolean",
              label: "Show all agents status",
              detail:
                "Show all agents connection including their status (eg ONLINE / OFFLINE).",
            },
            showAgentsWithUnresolvedChat: {
              default: false,
              type: "boolean",
              label: "Show all agents with unresolved chat",
              detail: "Show logged out agents with unresolved chat list table.",
            },
            showQueuedChatsCount: {
              default: false,
              type: "boolean",
              label: "Show queued chats count",
              detail: "Show today's total queued chats count.",
            },
            showAttendingChatsCount: {
              default: false,
              type: "boolean",
              label: "Show attending chats count",
              detail: "Show today's total attending chats count.",
            },
            showAbandonedChatsCount: {
              default: false,
              type: "boolean",
              label: "Show abandoned chats count",
              detail: "Show today's total abandoned chats count.",
            },
          },
        },
        suggestEmailHandover: {
          default: {},
          type: "object",
          label:
            "Suggest handover to email when estimated wait time exceeds threshold",
          children: {
            enabled: {
              default: false,
              type: "boolean",
            },
            threshold: {
              default: 60,
              min: 1,
              type: "number",
              label: "Threshold (in minutes)",
              detail:
                "When the number of minutes a user is expected to wait exceeds this threshold, an option to handover via email is presented to the user.",
            },
          },
        },
        conditionalRating: {
          default: false,
          type: "boolean",
          label: "Conditional Rating",
          detail:
            "After livechat ends, allow content node conditions to determine whether to send the rating content",
        },
        disableTextInputAfterSessionResolved: {
          default: false,
          type: "boolean",
          label: "Disable text input after livechat session resolved",
          detail: "Prevent users to input text after a live chat session",
        },
        disableLivechatQueue: {
          default: false,
          type: "boolean",
          label: "Disable users to queue for live chat",
          detail:
            "Enable to prevent users from queuing to a live chat session. Not to be used together with livechat assignment 'broadcast' mode",
        },
        useAgentAlias: {
          default: false,
          type: "boolean",
          label: "Use Agent alias",
          detail: "Changes the agent's name to use his/her alias",
        },
        disableLongWait: {
          default: false,
          type: "boolean",
          label: "Disable long wait",
          detail:
            "Enable to send automated message when user is not attended to",
        },
        additionalAgentStatus: {
          default: {},
          type: "json",
          label: "Additional agent status",
          detail: `Default Status will be online and offline, this is only for additional status 
          EG. {BREAK: { value: "BREAK", text: "Break" }, AWAY: { value: "AWAY", text: "Away" }}`,
        },
        dataCollectionForm: {
          default: {},
          type: "object",
          label: "Data collection form module",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail:
                "Enable if you wish for agent to collect end user information",
            },
            fieldDataURLs: {
              default: [],
              type: "json",
              label: "URLs of files to import data from",
              detail: `Requires name (in valid variable name format) and a delimiter-URL pair for each file i.e. [{"name": "nameOfData", "delimiter": ",", "url": "www.urlFile1.com"}, {"name": "otherName", "delimiter": "/\\r?\\n/g", "url": "www.urlFile2.com"}]`,
            },
            send_to_email: {
              default: "",
              type: "string",
              label: "Set email for form data to be sent to",
              detail: "Set email for form data to be sent to",
            },
          },
        },
        liveChatHelperActions: {
          default: {
            enabled: false,
            contentNodes: [],
          },
          type: "liveChatHelperActions",
        },
      };
    },
  },
};

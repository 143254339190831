<template>
  <div
    style="display: flex; flex-direction: column; justify-content:flex-end; margin: 0 16px 10px 16px;"
  >
    <el-card v-for="(item, index) in content" :key="index" :body-style="{ padding: '0px' }">
      <div>
        <div
          v-if="getCardImage(item)"
          class="image"
          style="background-position:center; background-size:cover; height: 180px;"
          :style="`background-image:url('${getCardImage(item)}')`"
        />
        <div style="padding: 8px; text-align:left;">
          <span v-text="item.text || item.title" />
          <div style="margin: 4px 0;">
            <span class="subtext">{{ item.subtext || item.subtitle }}</span>
          </div>
        </div>

        <div
          style="display:flex; flex-direction: column; justify-content: flex-end;padding: 0 10px;"
        >
          <div
            v-for="(button, index) in item.buttons"
            :key="index"
            style="text-align: center; display: block;"
          >
            <el-button
              disabled
              plain
              rel="noopener"
              size="small"
              type="primary"
              style="text-align:center; width: 100%; margin: 0 0 10px 0;"
              target="_blank"
              v-text="getText(button)"
            />
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["content"],
  methods: {
    getText(button) {
      const buttonText = _.get(button, "text", button.title); // Default is 'text', else facebook is 'title'
      return buttonText;
    },
    getCardImage(content) {
      const imageUrl = _.get(content, "image", content.image_url); // Default is 'image', else facebook is 'image_url'
      return imageUrl;
    }
  }
};
</script>

<template>
  <div>
    <div style="margin-bottom: 10px">Error Fields</div>
    <div
      style="display: flex; align-items: center; margin-bottom: 10px"
      v-for="(item, index) in fields"
      :key="index"
    >
      <el-input
        v-model="item.key"
        type="text"
        style="max-width: 30%"
        size="mini"
        placeholder="Input Error Field Key"
      />
      <el-input
        size="mini"
        style="max-width: 30%; margin-left: 10px"
        v-model="item.value"
        type="text"
        placeholder="Input Field Value"
      />
      <el-button
        class="el-icon-delete"
        size="mini"
        style="margin-left: 10px"
        type="text"
        @click="removeField(index)"
      />
    </div>
    <el-button
      size="mini"
      icon="el-icon-plus"
      plain
      type="primary"
      @click="addField"
      >New Error Field</el-button
    >
  </div>
</template>

<script>
export default {
  props: ["fields"],
  methods: {
    addField() {
      this.$emit("add-error-field");
    },
    removeField(index) {
      this.$emit("remove-error-field", index);
    },
  },
};
</script>

<template>
  <div class="quick-reply-container">
    <label class="quick-reply-text">{{ previewText }}</label>
  </div>
</template>

<script>
export default {
  name: "WhatsAppMessageTemplateQuickReply",
  components: {},
  props: {
    previewText: {
      type: String,
      default: "No Preview Available"
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.quick-reply-container {
  border-radius: 7.5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quick-reply-text {
  white-space: pre-wrap;
  color: #00a5f4;
}
</style>
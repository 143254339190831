<template>
  <section v-if="isHandoverByDepartmentEnabled">
    <el-divider></el-divider>
    <p>
      Department specific office hours configuration, department that did not have office hours added will fallback to the default office hours.
      <br />This option will only work when handover by department enabled.
    </p>
    <el-dropdown @command="onChange">
      <el-button size="mini" type="primary">
        Add Department Office Hour
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :key="`department-${department.id}`"
          :command="department"
          v-for="department in departments"
          style="text-transform: capitalize;"
        >{{ department.name }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <br />
    <br />

    <el-card
      shadow="never"
      style="margin-bottom:10px; max-width: 60%;"
      v-for="(departmentDatetime, departmentDatetimeKey) in value.departmentDatetimes"
      :key="`department-datetime-${departmentDatetimeKey}`"
      :body-style="{ padding: '10px' }"
    >
      <el-row
        style="align-items: center; justify-content:space-between; flex-direction:row; margin-bottom: 0;"
        type="flex"
      >
        <div>
          <span style="margin-right: 20px; text-transform: capitalize; font-size: 12px;">
            <strong>DEPARTMENT - &nbsp;</strong>
            {{ getDepartmentName(departmentDatetimeKey) }}
          </span>

          <el-time-picker
            v-model="value.departmentDatetimes[departmentDatetimeKey]"
            is-range
            size="medium"
            format="h:mm A"
            arrow-control
            value-format="h:mm a"
            range-separator="To"
            start-placeholder="Start time"
            end-placeholder="End time"
          />
        </div>

        <el-button
          @click.prevent="onDelete(departmentDatetimeKey)"
          type="text"
          icon="el-icon-delete"
          size="medium"
          circle
        />
      </el-row>
    </el-card>
  </section>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  name: "OfficeHourDepartmentDatetimes",
  props: ["setting", "value", "modules"],
  methods: {
    onDelete(departmentId) {
      this.$delete(this.value.departmentDatetimes, departmentId);
    },
    onChange(department) {
      this.$set(this.value.departmentDatetimes, department.id, [
        moment("2013-02-08 08:00:00").toDate(),
        moment("2013-02-08 19:00:00").toDate()
      ]);
    },
    getDepartmentName(departmentId) {
      const department = this.setting.departments.find(
        department => department.id === departmentId
      );
      return department.name;
    }
  },
  computed: {
    /**
     * @description Check if handover by department enabled
     * @return {boolean}
     */
    isHandoverByDepartmentEnabled() {
      return _.get(
        this.modules,
        "handover.handoverRouting.byDepartment",
        false
      );
    },

    /**
     * @description Departments list
     */
    departments() {
      const added = _.keys(this.value.departmentDatetimes);
      return this.setting.departments.filter(
        department => !_.includes(added, department.id)
      );
    }
  },
  beforeMount() {
    if (!this.value.departmentDatetimes) {
      this.$set(this.value, "departmentDatetimes", this.setting.default);
    }
  }
};
</script>
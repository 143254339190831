<template>
  <span style="margin-left: 10px;">
    <el-button
      :loading="isBusy"
      :disabled="isBusy"
      type="primary"
      size="mini"
      icon="el-icon-upload"
      @click.prevent="onImport"
      >Import CSV</el-button
    >

    <input
      :disabled="isBusy"
      id="transaction-import-file-selector"
      ref="transaction-import-file-selector"
      style="display: none;"
      type="file"
      accept=".csv"
      @change="onFileSelected"
    />
  </span>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import XLSX from "xlsx";
import _ from "lodash";
import { toCamelCase } from "@/filters/index";

export default Vue.extend({
  props: ["isBusy"],
  data() {
    return {
      transactions: []
    };
  },
  methods: {
    /**
     * @description Set table loading
     * @return {void}
     */
    setBusy(isBusy) {
      this.$emit("setBusy", isBusy);
    },

    /**
     * @description On fetch all transactions
     * @return {void}
     */
    onFetchTransactions() {
      this.$message({
        type: "success",
        message: "Successfully patched transactions."
      });

      this.setBusy(true);

      this.$store
        .dispatch("FETCH_TRANSACTIONS", {
          type: this.currentType
        })
        .then(response => {
          this.$store.commit("SET_TRASACTIONS", response);
        })
        .catch(err =>
          this.$message({
            type: "error",
            message: "Error fetching transaction."
          })
        )
        .finally(() => this.setBusy(false));
    },

    /**
     * @description On patch uploaded transactions
     * @return {void}
     */
    onUpdateTransactions() {
      this.setBusy(true);
      this.$store
        .dispatch("UPDATE_TRANSACTIONS", {
          payload: this.transactions
        })
        .then(response => this.onFetchTransactions())
        .catch(() =>
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Failed to patch uploaded transactions."
          })
        )
        .finally(() => {
          this.transactions = [];
          this.setBusy(false);
        });
    },

    /**
     * @description On file uploaded from user event handler
     * @return {void}
     */
    onFileSelected($event) {
      const uploadedFile = _.first($event.target.files);
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const contents = this.csvToJson(e.target.result);
        this.transactions = contents;
        this.$nextTick(() =>
          this.$confirm(
            `Are you sure to patch all ${this.transactions.length} transactions?`,
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel"
            }
          ).then(() => this.onUpdateTransactions())
        );
      };

      reader.readAsBinaryString(uploadedFile);
      this.$refs["transaction-import-file-selector"].value = null;
    },

    /**
     * @description Convert uploaded file content CSV to JSON
     * @return {Array<Transaction>}
     */
    csvToJson(data) {
      let result = {};

      const workbook = XLSX.read(data, {
        type: "binary"
      });
      workbook.SheetNames.forEach(sheetName => {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: this.currentTransactionMetaFields,
          raw: false // Prevent parsing date to float
        });
        if (roa.length) result[sheetName] = roa;
      });

      // Flatten multi sheets object structure to single dimension array
      result = _.chain(result)
        .values()
        .flatten()
        .value();

      // Remove first element from result (headers).
      result.splice(0, 1);

      // Map result into Transaction schema before patch the records
      result = result.map(item => {
        const transaction = {
          data: JSON.stringify(_.omit(item, ["ID", "Date"])), // Omit ID & Date, both fields are immutable.
          whereClause: {
            id: item["ID"]
          }
        };
        return transaction;
      });

      return result;
    },

    /**
     * @description On user pressed dom file input button event handler
     * @description Create mouse event
     * @return {void}
     */
    onImport() {
      if (!this.isBusy) {
        let elem = document.getElementById("transaction-import-file-selector");
        if (elem && document.createEvent) {
          let evt = document.createEvent("MouseEvents");
          evt.initEvent("click", true, false);
          elem.dispatchEvent(evt);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["currentType"]),
    /**
     * @description Get all available fields from current transaction meta data
     * @return {string[]}
     */
    currentTransactionMetaFields() {
      const fields = ["ID", "Date"];
      const allTransactionsMeta = this.$store.state.transaction
        .transactionsMeta;

      const selectedTransactionMeta = allTransactionsMeta.find(
        meta => meta.type === this.currentType
      );

      if (!selectedTransactionMeta) {
        return [];
      } else {
        const parsedTransactionMeta = JSON.parse(
          selectedTransactionMeta.metaData
        );

        const additionalFields = _.chain(parsedTransactionMeta)
          .get("fields", {})
          .keys()
          .value();

        fields.push(...additionalFields);
        return fields;
      }
    }
  }
});
</script>

import { graph } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { TransactionState } from "./types";
import gql from "graphql-tag";
import _ from "lodash";

const actions: ActionTree<TransactionState, RootState> = {
  FETCH_TRANSACTIONS_META: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            transactionAPI {
              metaTransactions
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const transactionsMeta = _.get(
          res,
          "data.transactionAPI.metaTransactions"
        );
        commit("SET_TRASACTIONS_META", transactionsMeta);
        return transactionsMeta;
      })
      .catch((err) => {
        throw err;
      });
  },
  FETCH_TRANSACTIONS: ({ commit }, { type }) => {
    return graph
      .query({
        query: gql`
          query($type: String!) {
            transactionAPI {
              transactions(type: $type)
            }
          }
        `,
        variables: { type },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const transactions = _.get(res, "data.transactionAPI.transactions");
        commit("SET_TRASACTIONS", transactions);
        return transactions;
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_TRANSACTION: ({ commit }, { data, whereClause }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($transaction: String!, $whereClause: JSON) {
            transactionAPI {
              updateTransaction(
                transaction: $transaction
                whereClause: $whereClause
              )
            }
          }
        `,
        variables: { transaction: data, whereClause },
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_TRANSACTIONS: ({ commit }, { payload }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($payload: [JSON]) {
            transactionAPI {
              updateTransactions(payload: $payload)
            }
          }
        `,
        variables: { payload },
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_STATE: ({ commit }, { payload }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($payload: [JSON]) {
            transactionAPI {
              updateState(payload: $payload)
            }
          }
        `,
        variables: { payload },
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_TRANSACTION_META: ({ commit }, payload) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($payload: JSON) {
            transactionAPI {
              deleteTransactionMeta(payload: $payload)
            }
          }
        `,
        variables: { payload },
      })
      .then(() => commit("DELETE_TRANSACTION_META", payload))
      .catch((err) => {
        throw err;
      });
  },
  CREATE_OR_UPDATE_TRANSACTION_META: ({ commit }, payload) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($payload: JSON) {
            transactionAPI {
              createOrUpdateTransactionMeta(payload: $payload)
            }
          }
        `,
        variables: { payload },
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default actions;

<template>
  <el-tabs type="border-card" value="examples" v-if="selectedIntent">
    <!-- Examples tab -->
    <el-tab-pane name="examples" label="Examples" :lazy="true">
      <FaqExamples
        :badge-for-qustions="badgeForQustions"
        :selected-languages="selectedLanguages"
        :selected-intent="selectedIntent"
        :delete-variation="deleteVariation"
        :threshold="autoClassifyThreshold"
        @save="save"
        @newVariation="newVariation"
        @deleteVariation="deleteVariation"
        @saveVariation="saveVariation"
      />
    </el-tab-pane>

    <!-- Answer tab -->
    <el-tab-pane name="answer" label="Answer" :lazy="true">
      <FaqAnswer
        :selected-intent="selectedIntent"
        @save="save"
        @isDirty="isDirty"
        @handleBlur="handleBlur"
        @toggleRequiredAnswerFlag="toggleRequiredAnswerFlag"
      />
    </el-tab-pane>
    
    <!-- Accuracy Tab -->
    <el-tab-pane name="accuracy" label="Accuracy" :lazy="true">
      <faq-accuracy
        :selectedIntent="selectedIntent"
        :threshold="autoClassifyThreshold"
      />
    </el-tab-pane>

    <!-- Data Visualization Tab -->
    <el-tab-pane name="datavis" label="Data Quality" :lazy="true">
      <el-tabs v-if="hasVisualizationData" value="flow" v-model="visualizationTabs">
        <el-tab-pane label="Data Quality" name="dataQuality">
          <DatasetQualityDiagram
            :data-set="visualizationData"
            :raw-selected-intent="selectedIntent"
          />
        </el-tab-pane>
        <el-tab-pane label="Intent Conflict" name="intentConflict">
          <IntentConflictDiagram
            :data-set="visualizationData"
            :raw-selected-intent="selectedIntent"
          />
        </el-tab-pane>
      </el-tabs>
      <div v-else>
        <el-alert
          v-if="this.$store.state.processingVisualization"
          title="Evaluating data quality..."
          type="success"
        ></el-alert>
        <el-button @click="$store.dispatch('FETCH_VISUALIZATION_DATA')">Evaluate Data</el-button>
      </div>
    </el-tab-pane>

    <!-- Entity tab -->
    <el-tab-pane name="entities" :lazy="true">
      <span slot="label">
        Entities
        <el-badge
          class="mark"
          :value="selectedIntent.entities ? selectedIntent.entities.length : 0"
        />
      </span>
      <FaqEntities :selected-intent="selectedIntent" @save="save" />
    </el-tab-pane>
    <el-tab-pane name="unclassified" :lazy="true">
      <span slot="label">
        Chat Log
        <el-badge
          class="mark"
          :value="filteredUnclassifiedTrainingData.length"
        />
      </span>
      <SelectedConversationalData
        :isPredicted="true"
        :isTrainingDatasetPage="true"
        :filtered-unclassified-training-data="filteredUnclassifiedTrainingData"
        :auto-classify-threshold="autoClassifyThreshold"
        :dataset="dataset"
        @newVariation="newVariation"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import FaqAnswer from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/SingleFaqQuestionDetail/FaqAnswer.vue";
import FaqExamples from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/SingleFaqQuestionDetail/FaqExamples.vue";
import FaqEntities from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/SingleFaqQuestionDetail/FaqEntities.vue";
import FaqAccuracy from "@/components/Faq/FaqTrainingDataset/FaqTrainingDatasetPage/SingleFaqQuestionDetail/FaqAccuracy.vue";
import SelectedConversationalData from "@/components/Faq/SelectedConversationalData.vue";
import DatasetQualityDiagram from "@/components/DataVisualization/DatasetQuality/Index.vue";
import IntentConflictDiagram from "@/components/DataVisualization/IntentConflict/Index.vue";
import gql from "graphql-tag";

export default Vue.extend({
  components: {
    FaqAnswer,
    FaqExamples,
    FaqEntities,
    FaqAccuracy,
    SelectedConversationalData,
    DatasetQualityDiagram,
    IntentConflictDiagram,
  },
  props: [
    "badgeForQustions",
    "save",
    "isDirty",
    "handleBlur",
    "saveVariation",
    "newVariation",
    "deleteVariation",
    "selectedLanguages",
  ],
  data() {
    return {
      answerProvided: false,
      autoClassifyThreshold: 0.75,
      visualizationShown: true,
      visualizationTabs: "dataQuality",
    };
  },
  methods: {
    toggleRequiredAnswerFlag(turnOn = false) {
      this.answerProvided = turnOn;
    },
    dataPatch(selectedIntent, variable, defaultValue = "") {
      if (!selectedIntent[variable]) {
        this.$set(selectedIntent, variable, defaultValue);
      }
      return selectedIntent;
    },
  },
  computed: {
    dataset() {
      return _.get(this.$store.state, "training.dataset", []);
    },
    filteredUnclassifiedTrainingData() {
      if (this.$store.state.training.all) {
        const intentName = this.selectedIntent.intentName;
        const currentTrainingData = this.$store.getters.currentTrainingData;

        const trainingDataByQuestion = _.filter(currentTrainingData, (q) => {
          const notArchived = q.archived == false;
          const isIntentPrediction = (intentName || q.prediction) ? q.prediction ? intentName === q.prediction : false : true;
          const aboveThresold = q.probability > this.autoClassifyThreshold;
          return notArchived && isIntentPrediction && aboveThresold;
        });

        return trainingDataByQuestion;
      } else {
        return [];
      }
    },
    visualizationData() {
      return _.get(this.$store.state, "visualizationData", {});
    },
    hasVisualizationData() {
      return !_.isEmpty(_.get(this.$store.state, "visualizationData"));
    },
    selectedIntent: {
      get() {
        let selectedIntent = _.find(
          this.dataset,
          (data) => data.id === this.$store.state.pages.faq.selectedIntentId
        );
        this.$set(selectedIntent, "entities", selectedIntent.entities || []);

        const patchedSelectedIntent = this.dataPatch(
          selectedIntent,
          "department",
          ["general"]
        );
        return patchedSelectedIntent;
      },
      set(value) {
        let pairIndex = _.findIndex(this.dataset, (row) => {
          return row.id === this.$store.state.pages.faq.selectedIntentId;
        });
        if (pairIndex !== -1) {
          this.$store.state.training.dataset[pairIndex] = value;
        }
      },
    },
  },

  // apollo: {
  //   dataset() {
  //     return {
  //       query: gql`
  //         query {
  //           faqAPI {
  //             dataset: getDataset
  //           }
  //         }
  //       `,
  //       update: data => {
  //         const dataset = get(data, "faqAPI.dataset");
  //         this.$set(this.$store.state.training, "dataset", dataset);
  //         const trainingDataset = this.$store.state.training.dataset;

  //         const result = _.map(trainingDataset, pair => {
  //           pair.tagInputVisible = false;
  //           pair.tagInputValue = "";
  //           pair.enabled = !!pair.enabled; // Cast to boolean type
  //           pair.tags = pair.tags || [];

  //           return pair;
  //         });

  //         return result;
  //       }
  //     };
  //   }
  // }
});
</script>

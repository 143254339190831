<template>
  <div v-if="contextArray.length > 0 && nodeForm.type !== 'folder'">
    <div class="node-sub-heading">Then set context:</div>

    <el-form-item style="border-bottom: 1px solid #ddd;">
      <el-col :sm="10" :xs="10"> Variable </el-col>
      <el-col :sm="10" :xs="10"> Value </el-col>
      <el-col :sm="2" :xs="2" />
    </el-form-item>

    <el-form-item v-for="(cont, index) in contextArray" :key="index">
      <el-col :span="10">
        <el-input
          v-model.trim="cont.key"
          :disabled="!authorized"
          style="margin-bottom:5px;"
          @blur="cont.key.trim() !== '' && updateInputInNode()"
        />
      </el-col>
      <el-col :span="10">
        <el-input
          v-model.trim="cont.value"
          :disabled="!authorized"
          style="margin-bottom:5px;"
          @blur="cont.key.trim() !== '' && updateInputInNode()"
        />
      </el-col>
      <el-col :span="2">
        <i class="el-icon-delete" @click="authorized && removeContext(index)" />
      </el-col>
    </el-form-item>

    <div style="padding:0 0 20px 0;cursor:pointer;">
      <span @click="authorized && addContext()">
        <el-button :disabled="!authorized" circle icon="el-icon-plus" />
        Add Context
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Context",
  props: {
    nodeForm: Object,
    authorized: Boolean
  },
  data() {
    return {
      contextArray: []
    };
  },
  computed: {},
  watch: {
    nodeForm: function(newVal, oldVal) {
      if (newVal.dialog_node !== oldVal.dialog_node) {
        this.setContextArray();
      }
    }
  },
  mounted() {
    this.setContextArray();
  },
  methods: {
    updateInputInNode() {
      this.$set(this.nodeForm, "contextArray", this.contextArray);
      this.$emit("updateNode-click", this.nodeForm);
    },
    addContext() {
      this.contextArray.push({
        key: "",
        value: ""
      });
      this.$set(this.nodeForm, "contextArray", this.contextArray);
    },
    removeContext(index) {
      if (index === 0 && this.contextArray.length === 1) {
        this.contextArray[0].key = "";
        this.contextArray[0].value = "";
      } else {
        this.contextArray.splice(index, 1);
      }
      this.$set(this.nodeForm, "contextArray", this.contextArray);
      this.$emit("updateNode-click", this.nodeForm);
    },
    setContextArray() {
      this.contextArray = [];
      if (
        this.nodeForm.context &&
        Object.keys(this.nodeForm.context).length > 0
      ) {
        Object.keys(this.nodeForm.context).forEach(ele => {
          this.contextArray.push({
            key: ele,
            value: this.nodeForm.context[ele]
          });
        });
      }

      // if no context add empty context
      if (this.contextArray.length == 0 && this.nodeForm.type === "standard") {
        this.contextArray.push({
          key: "",
          value: ""
        });
      }
      this.$set(this.nodeForm, "contextArray", this.contextArray);
    }
  }
};
</script>

<template>
  <el-row :gutter="20">
    <el-col :span="16">
      <el-input
        v-model="rpaCommand"
        :spellcheck="false"
        type="textarea"
        :rows="10"
        placeholder="type your instructions here using natural language. Use one action for each line of instruction."
      />
      <br />
      <codemirror ref="jsonEditor" :options="editorOptions" :value="rpaCode" />
    </el-col>
    <el-col :span="8">
      <el-card>
        <div slot="header" style="font-weight:light;">
          <h2>User Guide</h2>
        </div>

        <p>
          Lines that does not match a pattern will be treated as comment.
          <br />Some examples: <br />go to, navigate to, jump to => I.goto()
          <br />click, tap => I.click() <br />scroll to => I.see()
        </p>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      editorOptions: {
        mode: "text/javascript",
        theme: "monokai"
      },

      rpaCommand: `This is where you can write instructions for the chatbot to perform on the website. `
    };
  },
  computed: {
    rpaCode() {
      if (!this.rpaCommand) return ""; // sanity check for rpaCommand variable to avoid errors
      let lines = this.rpaCommand.split("\n");
      let code = lines
        .map(line => {
          if (/^\s*$/.test(line)) {
            return "";
          } else if (/go ?to/i.test(line)) {
            let matches = line.match(
              /((http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/
            );
            if (matches && matches.length > 0) {
              return `I.goto('${matches[0]}')`;
            } else {
              return `// Go to URL`;
            }
          } else if (/(click|tap)( on)? \b([\w ]+)/i.test(line)) {
            let matches = line.match(/(click|tap)( on)? \b([\w ]+)/i);
            if (matches) {
              return `I.click('${matches[3]}')`;
            } else {
              return `// Go to URL`;
            }
          } else {
            return "// " + line;
          }
        })
        .join("\n");

      return code;
    }
  }
};
</script>

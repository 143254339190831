import _ from "lodash";
import store from "@/store/index";
import moment from "moment";

import { getIdenticalTrigger, getWildCardTrigger } from "./trigger";

import { TaskManagementSetting } from "./type";
import { getContentNodeWithMissingDetails } from "./content";

// TODO: May remove if can get all data upon load
export function getOtherTasks(): {} {
  // check if task_management is enabled
  const taskManagementEnabled = _.get(
    store,
    "state.modules['task_management'].enabled",
    false
  );
  if (!taskManagementEnabled) {
    return []; // return empty object, do nothing
  }

  const pendingTaskKeys = getPendingTaskKeys();
  const tasks = pendingTaskKeys.map((key) => {
    return getTaskDetails(key);
  });

  return tasks;
}

function getPendingTaskKeys(): string[] {
  const taskManagementSettings: TaskManagementSetting = _.get(
    store,
    "state.modules['task_management']",
    {}
  );
  return Object.keys(
    _.pickBy(taskManagementSettings, (setting) => {
      return _.get(setting, "enabled");
    })
  );
}

function getTaskDetails(taskType: string): {} {
  if (taskType === "faq") {
    return getIntentsMissingDetails();
  } else if (taskType === "content") {
    return getContentNodeWithMissingDetails();
  } else if (taskType === "trigger") {
    const triggerDuplicateSettings: TaskManagementSetting = _.get(
      store,
      "state.modules['task_management'].trigger.checkForDuplicateTrigger",
      {}
    );
    if (triggerDuplicateSettings) {
      getIdenticalTrigger();
    }
    const triggerWildcardSettings: TaskManagementSetting = _.get(
      store,
      "state.modules['task_management'].trigger.checkForWildcardTrigger",
      {}
    );
    if (triggerWildcardSettings) {
      getWildCardTrigger();
    }
    return {};
  } else {
    return {};
  }
}

function getIntentsWithConflicts() {
  // call graphql function
  return {};
}

function getIntentsMissingDetails() {
  // call graphql function
  const missingIntentExpiry = _.get(
    store,
    "state.missingIntentExpiry",
    moment().subtract(1, "m").format("YYYY-MM-DD HH:mm:ss")
  );
  const currTime = moment();
  if (currTime.isBefore(moment(missingIntentExpiry))) {
    store.commit("SET_INTENT_MISSING_DETAILS", { missingIntentDetailAPI: [] });
    return [];
  }
  const userHasFAQPermissions = getUserPermissions("faq");

  if (userHasFAQPermissions) {
    store
      .dispatch("GET_INTENTS_MISSING_DETAILS")
      .then((missingIntent) => {
        if (missingIntent) {
          return missingIntent;
        }
      })
      .catch((err) => {});
  }
  return {};
}

export function getUserPermissions(moduleName: string): boolean {
  const accessControlModule = store.state.modules["access_control"];
  const userRoles =
    store.state.profile["https://login.keyreply.com/roles"] || [];
  const hasAccess = _.chain(accessControlModule.roles)
    .filter((role) => role && userRoles.includes(role["name"]))
    .map((role) => (role ? role["access"] : null))
    .some(moduleName)
    .value();

  return hasAccess;
}

<template>
  <el-main>
    <FaqNewIntent @handleBlur="handleBlur" @isDirty="isDirty" />
    <el-row :gutter="10">
      <el-pagination
        background
        small
        layout="prev, pager, next"
        :pageSize="pageSize"
        :current-page.sync="currentPage"
        :total="noOfItems"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
        @size-change="handleSizeChange"
        style="display: inline-block"
      ></el-pagination>
      <div style="display: inline-block">
        <span style="font-size: 10px; color: #909399"
          >{{ noOfItems }} {{ noOfItems === 1 ? "intent" : "intents" }}</span
        >
      </div>
    </el-row>

    <br />

    <el-table
      ref="dataset"
      style="width: 100%"
      size="small"
      :data="pagedDataset"
      :row-key="(row, index) => index"
      :expand-row-keys="expandedRows"
      :cell-style="lowVariationStyle"
      :row-style="customRowStyle"
      @sort-change="$emit('onSortChange', $event)"
      @cell-click="handleCellClick"
    >
      <!-- Intent name -->
      <el-table-column
        sortable
        header-align="center"
        label="Intent Name"
        column-key="intentName"
      >
        <template slot-scope="scope">
          <!-- new question -->
          <el-badge
            v-if="badgeForQustions[scope.row.id]"
            value="new"
            class="badge_item"
          >
            <div style="margin-left: 1em">{{ scope.row.intentName }}</div>
          </el-badge>

          <!-- display textbox when editing and row is selected -->
          <el-input
            :disabled="!isAuthorisedForChange(scope.row.department)"
            v-else-if="
              scope.row.edit &&
              scope.row.id === $store.state.pages.faq.selectedIntentId
            "
            v-model="scope.row.intentName"
            placeholder="Input a name for your intent"
            @keyup.enter.native="$emit('handleBlur', scope.row)"
            @change="$emit('isDirty')"
            @blur="$emit('handleBlur', scope.row)"
          />

          <!-- display default intent name-->
          <div
            v-else-if="!scope.row.id || scope.row.id === ''"
            class="table-cell"
          >
            <div style="margin-left: 1em">{{ defaultIntentNameValue }}</div>
          </div>

          <!-- warning tooltip for invalid intent -->
          <el-tooltip v-else-if="toShowWarning(scope.row)" placement="top">
            <div slot="content">
              {{ warningInvalidAnswerTooltipText(scope.row) }}
              <br v-if="isInvalidAnswer(scope.row) && needsSuggestedVariation(scope.row)" />
              {{ warningRequiresSuggestedTooltipText(scope.row) }}
            </div>
            <div style="margin-left: 1em">{{ scope.row.intentName }}</div>
          </el-tooltip>

          <!-- just basic intentName -->
          <div v-else style="margin-left: 1em">{{ scope.row.intentName }}</div>
        </template>
      </el-table-column>

      <!-- Variation length -->
      <el-table-column
        sortable
        column-key="examples"
        header-align="center"
        align="center"
        label="Examples"
        width="120"
      >
        <template slot-scope="props">
          <span>
            <!-- Intents with 0 variations should have an el-tooltip -->
            <el-tooltip
              v-if="variationsByLanguage(props.row) === 0"
              content="Please click to add examples"
              placement="top"
            >
              <span class="list-wording">{{
                variationsByLanguage(props.row)
              }}</span>
            </el-tooltip>
            <span v-else class="list-wording">{{
              variationsByLanguage(props.row) + " "
            }}</span>

            <el-tooltip
              v-if="containsUntestedVariations(props.row)"
              content="This intent has unevaluated examples 📙"
              placement="top"
            >
              <!-- orange, font-size:21px as it looks smaller with the same size -->
              <i
                class="el-icon-question list-icon"
                style="color: #e6a23c; font-size: 21px"
              />
            </el-tooltip>
            <el-tooltip
              v-else-if="isConflicted(props.row)"
              content="This intent has conflicting examples ❌"
              placement="top"
            >
              <!-- keyreply red -->
              <i class="el-icon-warning list-icon" style="color: #e4392b" />
            </el-tooltip>
            <el-tooltip
              v-else-if="
                props.row.variations &&
                props.row.variations.length > 0 &&
                !isConflicted(props.row)
              "
              content="This intent has no conflicting examples 👍🏻"
              placement="top"
            >
              <!-- green -->
              <i class="el-icon-success list-icon" style="color: #67c23a" />
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <!-- Enable -->
      <el-table-column
        header-align="center"
        label="Enabled"
        width="80"
        align="center"
      >
        <template slot-scope="props">
          <!-- For questions that cannot be enabled -->
          <el-tooltip
            v-if="props.row.intentName.trim().length === 0"
            content="Either question or answer cannot be blank"
            placement="top"
          >
            <el-switch
              v-model="props.row.enabled"
              :disabled="
                props.row.intentName.trim().length === 0 ||
                !isAuthorisedForChange(props.row.department)
              "
              active-color="#409EFF"
              inactive-color="#CCC9C9"
            ></el-switch>
          </el-tooltip>

          <!-- For questions that can be enabled -->
          <el-switch
            v-model="props.row.enabled"
            :disabled="!isAuthorisedForChange(props.row.department)"
            v-else
            active-color="#409EFF"
            inactive-color="#CCC9C9"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column header-align="center" width="48" align="center">
        <template slot-scope="props">
          <div>
            <el-tooltip content="Delete this question" placement="right">
              <el-button
                :disabled="!isAuthorisedForChange(props.row.department)"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="toggleDeleteDialogVisible(true, props.row)"
              />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="deleteDialogVisible === true && !selectedIntentIsEmpty"
      :delete-dialog-visible="deleteDialogVisible"
      :selected-intent="selectedIntent"
      @deleteQuestion="deleteQuestion"
      @toggleDeleteDialogVisible="toggleDeleteDialogVisible"
    />
  </el-main>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import DeleteDialog from "@/components/Faq/DeleteDialog";
import FaqNewIntent from "@/components/Faq/FaqNewIntent";
import { validateIntentVariations, validateIntentAction } from "@/helperMethods/faq/index";
import { faqConflictedMixin } from "@/mixins/faq";

export default Vue.extend({
  components: { DeleteDialog, FaqNewIntent },
  mixins: [faqConflictedMixin],
  props: [
    "visibleDataset",
    "expandedRows",
    "badgeForQustions",
    "selectedLanguages",
    "handleBlur",
    "isDirty",
  ],
  data() {
    return {
      defaultQuestionValue: "<This is a blank question>",
      defaultIntentNameValue: "<Empty intent name>",
      deleteDialogVisible: false,
      selectedIntent: {},

      // Pagination
      pageSize: 20,
      currentPage: 1,

      variationsRequiredSuggestedText: "Examples tab: Select at least one example to be suggested",
      invalidAnswerText: "Answer tab: Please ensure answer is valid",
    };
  },
  computed: {
    noOfItems() {
      return this.finalDataset.length;
    },
    showAdvanced() {
      return this.$store.state.showAdvanced;
    },
    selectedIntentIsEmpty() {
      return _.isEmpty(this.selectedIntent);
    },
    finalDataset() {
      _.forEach(this.visibleDataset, (intent, index) => {
        const isInvalid = validateIntentAction(intent.action);
        _.assign(intent, {
          invalid: isInvalid,
        });

        const isValidVariations = validateIntentVariations(intent.variations);
        _.assign(intent, {
          invalidVariations: isValidVariations,
        });
      });
      return this.visibleDataset;
    },
    pagedDataset() {
      const startingIndex = (this.currentPage - 1) * this.pageSize;
      const currentIndex = this.currentPage * this.pageSize;
      const totalDatasetSize = this.finalDataset.length;
      if (totalDatasetSize === 0) {
        return [];
      }

      let endingIndex = totalDatasetSize;
      if (totalDatasetSize >= currentIndex) {
        endingIndex = currentIndex;
      }
      return this.finalDataset.slice(startingIndex, endingIndex);
    },
  },
  methods: {
    togglePage(pageNo) {
      this.currentPage = pageNo;
    },
    handleSizeChange(size) {
      this.pageSize = size;
    },
    customRowStyle(row, rowIndex) {
      const style = { cursor: "pointer" };
      const currentRowId = _.get(row, "row.intentName");
      if (this.$store.state.pages.faq.selectedIntentId === currentRowId) {
        style.background = "#f3f6f9";
      }

      const isInvalidVariations = _.get(row, "row.invalidVariations");
      if (isInvalidVariations) {
        style.background = "#fffce3";
      }

      const isInvalid = _.get(row, "row.invalid");
      if (isInvalid) {
        style.background = "#fee";
      }

      return style;
    },
    isAuthorisedForChange(intentDepartment = ["general"]) {
      const userDepartment = this.$store.getters.userDepartment;

      const userIsAuthorised =
        _.intersection(userDepartment, intentDepartment).length > 0;
      const intentIsGeneral = intentDepartment.includes("general");

      const isAuthorised = userIsAuthorised || intentIsGeneral;
      return isAuthorised;
    },
    toShowWarning(intent) {
      return this.isInvalidAnswer(intent) || this.needsSuggestedVariation(intent);
    },
    isInvalidAnswer(intent) {
      return _.get(intent, "invalid", false);
    },
    needsSuggestedVariation(intent) {
      return _.get(intent, "invalidVariations", false);
    },
    warningRequiresSuggestedTooltipText(intent) {
      const showVariationsRequiredSuggestedText = this.needsSuggestedVariation(intent);
      return showVariationsRequiredSuggestedText ? this.variationsRequiredSuggestedText : "";
    },
    warningInvalidAnswerTooltipText(intent) {
      const showInvalidIntentAnswerText = this.isInvalidAnswer(intent);
      return showInvalidIntentAnswerText ? this.invalidAnswerText : "";
    },
    containsUntestedVariations(intent) {
      const variations = intent.variations;
      let untestedVariationExist = false;
      if (variations) {
        variations.map((variation) => {
          const intent_ranking = variation.intent_ranking;
          if (intent_ranking && intent_ranking.length === 0) {
            untestedVariationExist = true;
          }
          if (!intent_ranking) {
            untestedVariationExist = true;
          }
        });
      }
      return untestedVariationExist;
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === "Intent Name" || column.label === "Examples") {
        this.$emit("handleIntentEdit", row);
      }
    },
    variationsByLanguage(row) {
      const variations = row.variations;
      if (variations) {
        const variationsCount = variations.filter((variation) => {
          const selectedLanguages = this.selectedLanguages;
          if (selectedLanguages.length > 0) {
            return selectedLanguages.includes(variation.language);
          } else {
            return true;
          }
        }).length;
        return variationsCount;
      }
    },
    toggleDeleteDialogVisible(isVisible = true, selectedIntent = {}) {
      this.selectedIntent = selectedIntent;
      this.deleteDialogVisible = isVisible;
    },
    deleteQuestion(row) {
      this.$store.commit("DELETE_QUESTION", row.id);
      this.$emit("clearIntentView");

      // Reset the delete dialog variables
      this.selectedIntent = {};
      this.deleteDialogVisible = false;
    },
    lowVariationStyle({ row, columnIndex }) {
      if (
        columnIndex === 1 &&
        row &&
        row.variations &&
        row.variations.length < 2
      ) {
        return { color: "red", fontWeight: "bold" };
      }
    },
  },
});
</script>

<style scoped>
.badge_item {
  margin-top: 13px;
  margin-right: 50px;
}
.list-wording {
  vertical-align: middle;
}
.list-icon {
  font-size: 18px;
  vertical-align: middle;
}
.el-switch {
  height: auto;
}
</style>

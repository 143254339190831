<template>
  <el-form :model="user" :rules="rules" ref="visitorForm">
    <el-form-item
      v-for="(meta, key) in viewMeta.fields"
      style="margin-bottom: 0"
      :label="meta.displayValue"
      :key="key"
      :prop="key"
    >
      <el-switch
        v-if="meta.dataType === 'boolean'"
        v-model="user[key]"
        size="mini"
        :disabled="!meta.editable"
      ></el-switch>
      <el-input-number
        v-else-if="meta.dataType === 'number'"
        v-model="user[key]"
        size="mini"
        :disabled="!meta.editable"
        :min="meta.min"
        :max="meta.max"
      ></el-input-number>
      <el-select
        v-else-if="
          meta.dataType === 'list' &&
          meta.editable === true &&
          meta.multiSelect === true
        "
        v-model="user[key]"
        multiple
        filterable
        size="mini"
        :collapse-tags="true"
        style="width: 100%"
        :clearable="meta.closable"
        :placeholder="meta.displayValue"
      >
        <el-option
          v-for="tag in meta.listValues"
          :key="tag"
          :label="tag"
          :value="tag"
        />
      </el-select>
      <el-select
        v-else-if="meta.dataType === 'list' && meta.editable === true"
        v-model="user[key]"
        :collapse-tags="true"
        filterable
        size="mini"
        style="width: 100%"
        :clearable="meta.closable"
        :placeholder="meta.displayValue"
      >
        <el-option
          v-for="tag in meta.listValues"
          :key="tag"
          :label="tag"
          :value="tag"
        />
      </el-select>
      <el-input
        v-else
        v-model="user[key]"
        :disabled="!meta.editable"
        clearable
        size="mini"
        :type="meta.multiLine === true ? 'textarea' : 'text'"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="saveUserInfo" size="mini" type="primary"
        >Save</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  props: ["user", "viewMeta"],
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    saveUserInfo() {
      this.$refs["visitorForm"].validate((valid) => {
        if (valid) {
          const userId = this.user.RowKey;
          const pageId = this.user.PartitionKey;
          const stateData = {};
          _.forEach(this.viewMeta.fields, (field, key) => {
            if (field.editable === true) {
              stateData[key] = this.user[key];
            }
          });
          this.$store
            .dispatch("SAVE_USER_INFO", {
              userId: userId,
              pageId: pageId,
              statePayload: JSON.stringify(stateData),
            })
            .then((response) => {
              //this.loading = false;
              this.$message({
                type: "success",
                message: "User info updated.",
              });
            })
            .catch((err) => {
              // this.loading = false;
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: `Error updating user info.`,
              });
            });
        } else {
          return false;
        }
      });
    },
    generateValidationRules() {
      _.forEach(this.viewMeta.fields, (field, key) => {
        let prompt = "input";
        if (field.dataType === "list") {
          prompt = "select";
        }
        if (field.required === true) {
          const rule = {
            required: true,
            message: `Please ${prompt} ${field.displayValue}`,
            trigger: "blur",
          };
          this.rules[key] = [rule];
        }
      });
    },
  },
  mounted() {
    this.generateValidationRules();
  },
};
</script>

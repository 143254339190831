import _ from "lodash";

const operators = [
  {
    name: "<",
    value: "<",
  },
  {
    name: "=",
    value: "=",
  },
  {
    name: ">",
    value: ">",
  },
  {
    name: "not",
    value: "NOT",
  },
  {
    name: "includes",
    value: "INCLUDES",
  },
  {
    name: "not includes",
    value: "NOT INCLUDES",
  },
  {
    name: "included in",
    value: "INCLUDED IN",
  },
  {
    name: "not included in",
    value: "NOT INCLUDED IN",
  },
  {
    name: "exists",
    value: "EXISTS",
  },
  {
    name: "not exists",
    value: "NOT EXISTS",
  },
];

const properties = [
  {
    name: "General",
    options: [
      {
        name: "Sentiment",
        value: "entities.SENTIMENT",
      },
      {
        name: "Message Channel",
        value: "source",
      },
      {
        name: "App Source",
        value: "appSource",
      },
      {
        name: "Role",
        value: "role",
      },
    ],
  },
  {
    name: "Geography",
    options: [
      {
        name: "Language",
        value: "entities.LANGUAGE",
      },
      {
        name: "Country",
        value: "entities.COUNTRY",
      },
      {
        name: "Country Code",
        value: "entities.COUNTRY_CODE",
      },
    ],
  },
  {
    name: "Date Time",
    entities: ["date"],
    options: [
      // Start
      {
        name: "Date (D [1-31])",
        value: "entities.DATE_START",
      },
      {
        name: "Day (dddd [Sunday-Saturday])",
        value: "entities.DATE_DAY_START",
      },
      {
        name: "Month (M [1-12])",
        value: "entities.DATE_MONTH_START",
      },
      {
        name: "Year (YYYY [2020])",
        value: "entities.DATE_YEAR_START",
      },
      {
        name: "Hour (H [0-23])",
        value: "entities.DATE_HOUR_START",
      },
      {
        name: "Minute (m [0-59])",
        value: "entities.DATE_MINUTE_START",
      },
      {
        name: "Timestamp (ms)",
        value: "entities.DATE_TIMESTAMP_START",
      },
    ],
  },
  {
    name: "Duration - Date Time",
    entities: ["date"],
    options: [
      {
        // Monday, Tuesday
        name: "Date End (D [1-31])",
        value: "entities.DATE_END",
      },
      {
        name: "Day End (dddd [Sunday-Saturday])",
        value: "entities.DATE_DAY_END",
      },
      {
        name: "Month End (M [1-12])",
        value: "entities.DATE_MONTH_END",
      },
      {
        name: "Year End (YYYY [2020])",
        value: "entities.DATE_YEAR_END",
      },
      {
        name: "Hour End (H [0-23])",
        value: "entities.DATE_HOUR_END",
      },
      {
        name: "Minute End (m [0-59])",
        value: "entities.DATE_MINUTE_END",
      },
      {
        name: "Timestamp End (ms)",
        value: "entities.DATE_TIMESTAMP_END",
      },
      // Duration
      {
        name: "Duration in Years",
        value: "entities.DURATION_YEARS",
      },
      {
        name: "Duration in Months",
        value: "entities.DURATION_MONTHS",
      },
      {
        name: "Duration in Weeks",
        value: "entities.DURATION_WEEKS",
      },
      {
        name: "Duration in Days",
        value: "entities.DURATION_DAYS",
      },
      {
        name: "Duration in Hours",
        value: "entities.DURATION_HOURS",
      },
      {
        name: "Duration in Minutes",
        value: "entities.DURATION_MINUTES",
      },
    ],
  },
  {
    name: "Zendesk",
    options: [
      {
        name: "Department Name",
        value: "zendesk_channel.department.name",
      },
      {
        name: "User Agent",
        value: "zendesk_channel.user_agent",
      },
      {
        name: "Device Type",
        value: "zendesk_channel.device_type",
      },
      {
        name: "Platform",
        value: "zendesk_channel.platform",
      },
      {
        name: "Visitor Phone Number",
        value: "zendesk_channel.visitor.phone",
      },
      {
        name: "Visitor Email",
        value: "zendesk_channel.visitor.email",
      },
      {
        name: "Visitor Display Name",
        value: "zendesk_channel.visitor.display_name",
      },
    ],
  },
];

export const contentNodeConditionOptionsPropsMixin = {
  props: {
    conditionOperatorOptions: {
      type: Array,
      default() {
        return operators;
      },
    },
    conditionPropertyOptions: {
      type: Array,
      default() {
        return properties;
      },
    },
  },
};

export const contentNodeConditionOptionsDataMixin = {
  data() {
    return {
      conditionOperatorOptions: operators,
      conditionPropertyOptions: properties,
    };
  },
};

export const contentNodeConditionOptionsComputedMixin = {
  computed: {
    entity() {
      return (this as any).$store.state.nodes.entity;
    },
    conditionPropertyOptionsDynamic() {
      const propOpts = (this as any).conditionPropertyOptions || [];

      const { entity } = this as any;

      const entityKeys = Object.keys(entity);
      const filtered = propOpts.filter(
        (o) => o.entities === undefined || _.intersection(entityKeys, o.entities).length
      );

      return [
        ...filtered,
        ...(entityKeys.filter((entityKey) => entity[entityKey].type === "list").length
          ? [
              {
                name: "Entities",
                options: Object.keys(entity)
                  .filter((entityKey) => entity[entityKey].type === "list")
                  .map((mapKey) => ({
                    name: mapKey,
                    value: `entities_last.${mapKey}`,
                  })),
              },
            ]
          : []),
      ];
    },
  },
};

export const contentNodeConditionMethodMixin = {
  methods: {
    showEntitySelector(condition) {
      return (
        condition.property &&
        condition.property.includes("entit") &&
        ["INCLUDED IN", "="].includes(condition.operator) &&
        !!this.getEntityList(condition)
      );
    },
    getEntityList(condition) {
      const { property } = condition;
      if (property && property.includes("entit")) {
        const entityProperty = _.last(property.split("."));
        const entity = _.get(this, `entity.${entityProperty}.list`, null);
        return entity;
      }
      return null;
    },
    entityValues(condition) {
      const entityList = this.getEntityList(condition) || [];
      return _.map(entityList, (item, key) => {
        return { value: key, link: key };
      });
    },
    querySearch(condition) {
      const keywords = this.entityValues(condition) || [];

      return (queryString, cb) => {
        if (queryString) {
          const results = keywords.filter((keyword) => {
            return new RegExp(queryString).test(keyword.value);
          });
          cb(results);
        } else {
          cb([]);
        }
      };
    },
  },
};

<template>
  <div style="height: 100%">
    <el-row type="flex" justify="space-between" style="flex-flow: row wrap">
      <div>
        <el-button
          size="mini"
          type="primary"
          round
          plain
          icon="el-icon-plus"
          @click="dialogIntentVisible = true"
          >Add Intent</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-delete"
          round
          size="mini"
          :disabled="multipleSelection.length <= 0"
          @click="deleteIntent"
          >Delete</el-button
        >
      </div>

      <el-input
        v-model="query"
        size="mini"
        style="max-width: 350px"
        placeholder="Search for intent.."
        prefix-icon="el-icon-search"
      />
    </el-row>
    <!-- Add intent popup -->
    <el-dialog title="Add Intent" :visible.sync="dialogIntentVisible">
      <el-form
        ref="dynamicValidateForm"
        :rules="rules"
        :model="dynamicValidateForm"
        label-width="120px"
        class="demo-dynamic"
      >
        <el-form-item prop="intentName" label="Intent">
          <el-input
            v-model="dynamicValidateForm.intentName"
            placeholder="Add Intent name"
          />
        </el-form-item>
        <el-form-item prop="description" label="Permissions">
          <el-select
            v-model="dynamicValidateForm.description"
            style="width: 100%"
            multiple
            filterable
            placeholder="Select Permissions"
          >
            <el-option
              v-for="department in departments"
              :key="department"
              :label="department"
              :value="department"
            >
              <span style="float: left">{{ department }}</span>
              <span style="float: right; width: 20px" />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('dynamicValidateForm')"
            >Submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- intent delete cofirm popup -->
    <el-dialog title="Tips" :visible.sync="dialogDeleteVisible" width="30%">
      <span>Do you want to delete intent?</span>
      <p>You are about to delete intent along with associated examples.</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">Cancel</el-button>
        <el-button type="primary" @click="deleteIntentConfirm"
          >Confirm</el-button
        >
      </span>
    </el-dialog>

    <!-- intent edit popup -->
    <el-dialog title="Edit Intent" :visible.sync="dialogEditIntentVisible">
      <el-row>
        <el-col>
          <el-form
            ref="intentEditForm"
            :rules="rules"
            :model="intentEditForm"
            label-width="120px"
            class="demo-dynamic"
          >
            <el-form-item prop="intentNameEdited" label="Intent Name">
              <el-input
                v-model="intentEditForm.intentNameEdited"
                :disabled="!authorized(intentEditForm.description.join(','))"
                placeholder="Add Intent name"
                @blur="updateIntent('intentEditForm')"
              />
            </el-form-item>
            <el-form-item prop="description" label="Permissions">
              <el-select
                v-model="intentEditForm.description"
                style="width: 100%"
                multiple
                filterable
                :disabled="!authorized(intentEditForm.description.join(','))"
                placeholder="Select Permissions"
                @change="updateIntent('intentEditForm')"
              >
                <el-option
                  v-for="department in departments"
                  :key="department"
                  :label="department"
                  :value="department"
                >
                  <span style="float: left">{{ department }}</span>
                  <span style="float: right; width: 20px" />
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <hr />
      <el-row>
        <el-col :sm="5">
          <label>Add user examples</label>
        </el-col>
        <el-col :sm="19">
          <el-input
            v-model="exampleTitle"
            :disabled="!authorized(intentEditForm.description.join(','))"
            placeholder="Add user example for this intent"
          />
        </el-col>
        <el-col :sm="5">
          <div class="grid-content bg-purple" />
        </el-col>
        <el-col :sm="19">
          <el-button
            type="primary"
            round
            :disabled="exampleTitle === ''"
            @click="addExample"
            >Add Example</el-button
          >
        </el-col>
      </el-row>
      <el-row class="example-section">
        <el-row>
          <el-col v-if="multipleSelectionOfExample.length > 0">
            <el-button
              type="primary"
              icon="el-icon-delete"
              circle
              @click="deleteExample"
            />
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-table
              class="example-edit-loader"
              style="width: 10 0%"
              size="mini"
              :data="intentExamples"
              height="300"
              @selection-change="handleSelectionExample"
            >
              <el-table-column
                type="selection"
                min-width="55"
                :selectable="exampleSelectable"
              />
              <el-table-column label="User Examples">
                <template slot-scope="scope">
                  <el-input
                    v-if="scope.row.edit"
                    v-model="scope.row.new_text"
                    autofocus
                    @blur="handleBlur(scope.row)"
                  />
                  <div v-else>
                    {{ scope.row.text }}
                    <i
                      v-if="authorized(intentEditForm.description.join(','))"
                      class="el-icon-edit"
                      @click="scope.row.edit = true"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-row>
    </el-dialog>

    <!-- example delete cofirm popup -->
    <el-dialog
      title="Tips"
      :visible.sync="dialogDeleteExampleVisible"
      width="30%"
    >
      <span>Do you want to delete example?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteExampleVisible = false"
          >Cancel</el-button
        >
        <el-button type="primary" @click="deleteExampleConfirm"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <el-row style="height: 90%">
      <el-table
        v-if="intentList"
        ref="multipleTable"
        v-loading="loadingIntentSet"
        element-loading-text="Loading..."
        :data="intentList"
        size="mini"
        style="width: 100%; height: 100%; overflow-y: auto"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          min-width="55"
          :selectable="intentSelectable"
        />
        <el-table-column label="Intent" min-width="200">
          <template slot-scope="scope">
            <label
              class="intent_name"
              @click="editIntent(scope.row.intent, scope.row.description)"
              >#{{ scope.row.intent }}</label
            >
          </template>
        </el-table-column>
        <el-table-column
          property="description"
          label="Permissions"
          min-width="300"
        />
        <!-- <el-table-column label="Modified" min-width="300" show-overflow-tooltip>
            <template slot-scope="scope">

              <timeago :datetime="scope.row.updated" :auto-update="60"></timeago>
            </template>
        </el-table-column>-->
        <el-table-column label="Examples">
          <template slot-scope="scope">
            {{ getLength(scope.row.examples) }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    var checkIntentName = (rule, value, callback) => {
      let intentNamesArr = [...new Set(this.intentList.map((a) => a.intent))];
      if (!value) {
        return callback(new Error("Please input intent name"));
      } else if (intentNamesArr.includes(value) && this.updateId != value) {
        callback(
          new Error("Intent Name already exists, please enter another name")
        );
      } else {
        callback();
      }
    };
    return {
      query: "",
      multipleSelection: [],
      loadingIntentSet: false,
      dialogIntentVisible: false,
      dynamicValidateForm: {
        intentName: "",
        description: "General",
      },
      dialogDeleteVisible: false,
      dialogEditIntentVisible: false,
      exampleTitle: "",
      intentEditForm: {
        intentNameEdited: "",
        description: [],
      },
      updateId: "",
      intentExamples: [],
      multipleSelectionOfExample: [],
      dialogDeleteExampleVisible: false,
      rules: {
        intentName: [{ validator: checkIntentName, trigger: "change" }],
        intentNameEdited: [{ validator: checkIntentName, trigger: "change" }],
      },
    };
  },
  computed: {
    ...mapGetters(["watsonIntents"]),
    intentList() {
      let list = [];
      if (_.isEmpty(this.watsonIntents)) {
        return list;
      }

      for (let i = 0; i < this.watsonIntents.length; i++) {
        if (
          !this.watsonIntents[i].hasOwnProperty("description") ||
          this.watsonIntents[i].description == ""
        ) {
          this.$set(
            this.$store.state.watson.intents[i],
            "description",
            "General"
          );
        }
        if (this.query === "") {
          list.push(this.watsonIntents[i]);
        } else {
          if (
            JSON.stringify(this.watsonIntents[i])
              .toLowerCase()
              .includes(this.query.toLowerCase())
          ) {
            list.push(this.watsonIntents[i]);
          }
        }
      }
      return list;
    },
    departments() {
      const departments = this.$store.state.profile[
        "https://login.keyreply.com/appmetadata"
      ].departments;
      return departments ? departments : [];
    },
  },
  mounted() {
    this.getIntentList({ loading: true });
  },
  methods: {
    getLength(examples) {
      if (_.isEmpty(examples)) {
        return 0;
      }
      return examples.length;
    },
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
      });
    },
    intentSelectable(row, index) {
      return this.authorized(row.description);
    },
    exampleSelectable(row, index) {
      return this.authorized(this.intentEditForm.description.join(","));
    },
    authorized(description = "") {
      return (
        description.includes("General") ||
        _.intersection(this.departments, description.split(",")).length > 0
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.filter((row) =>
        this.authorized(row.description)
      );
      return this.multipleSelection;
    },
    getIntentList(options) {
      if (options && options.loading) {
        this.loadingIntentSet = true;
      }
      // this.$store
      //   .dispatch("FETCH_WATSON_WORKSPACE");
      return this.$store
        .dispatch("FETCH_WATSON_INTENT")
        .then(() => (this.loadingIntentSet = false))
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encounters error fetching watson intent",
          });
        });
    },
    async submitForm(formName) {
      let valid = true;
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dynamicValidateForm.description.length == 0) {
            this.dynamicValidateForm.description = ["General"];
          }
          let payload = {
            intent: this.dynamicValidateForm.intentName,
            description: this.dynamicValidateForm.description.join(","),
          };
          this.$store
            .dispatch("ADD_WATSON_INTENT", payload)
            .then((response) => {
              this.$message({
                message: "Saved",
                type: "success",
              });
              this.resetForm("dynamicValidateForm");
              this.getIntentList({ loading: true });
              this.dialogIntentVisible = false;
            })
            .catch((e) => {
              this.notifyError("Encountered error adding watson intent");
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    deleteIntent() {
      this.dialogDeleteVisible = true;
    },
    deleteIntentConfirm() {
      this.dialogDeleteVisible = false;
      this.$store
        .dispatch("DELETE_WATSON_INTENTS", this.multipleSelection)
        .then((response) => {
          this.$message({
            message: "Saved",
            type: "success",
          });
          this.getIntentList({ loading: true });
        })
        .catch((e) => {
          this.notifyError("Encountered error deleting watson intents");
        });
    },
    editIntent(name, description) {
      this.dialogEditIntentVisible = true;
      this.updateId = name;
      this.intentEditForm.intentNameEdited = name;
      this.intentEditForm.description = description.split(",");

      //assign particular example array
      if (this.intentList) {
        let index = this.intentList.findIndex(
          (value) => value.intent == this.updateId
        );
        const intentExamples = this.intentList[index]
          ? this.intentList[index]["examples"]
          : [];
        var result = intentExamples.map(function (el) {
          const o = Object.assign({}, el);
          o.edit = false;
          o.new_text = o.text;
          return o;
        });
        this.intentExamples = result;
      }
    },
    updateIntent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.intentEditForm.description.length === 0) {
            this.intentEditForm.description = ["General"];
          } else if (
            this.intentEditForm.description &&
            this.intentEditForm.description.length > 1 &&
            this.intentEditForm.description[0] === "General"
          ) {
            this.intentEditForm.description.splice(0, 1);
          }
          if (this.intentEditForm.intentNameEdited) {
            let currentIntent = _.find(this.watsonIntents, {
              intent: this.updateId,
            });
            let payload = {
              old_values: {
                ...currentIntent,
                intent: this.updateId,
              },
              new_values: {
                old_intent: this.updateId,
                new_intent: this.intentEditForm.intentNameEdited,
                new_description: this.intentEditForm.description.join(","),
              },

              // Throw the old payload in
            };
            this.$store
              .dispatch("UPDATE_WATSON_INTENT", payload)
              .then((response) => {
                this.$message({
                  message: "Saved",
                  type: "success",
                });
                this.getIntentList({ loading: false });
                this.updateId = this.intentEditForm.intentNameEdited;
              })
              .catch((e) => {
                this.notifyError("Encountered error updating watson intent");
              });
          }
        }
      });
    },
    addExample() {
      const self = this;
      var loader = this.$loading({
        target: ".el-dialog__body",
        fullscreen: false,
        text: "Loading...",
      });
      if (self.exampleTitle) {
        let payload = {
          intent: this.updateId,
          // add in description
          description: this.intentEditForm.description,
          text: this.exampleTitle,
        };
        self.$store
          .dispatch("ADD_WATSON_EXAMPLE", payload)
          .then((response) => {
            self.$message({
              message: "Saved",
              type: "success",
            });
            loader.close();
            self.exampleTitle = "";
            self.getIntentList({ loading: false }).then(() => {
              self.editIntent(
                self.intentEditForm.intentNameEdited,
                self.intentEditForm.description.join(",")
              );
            });
          })
          .catch((e) => {
            self.notifyError("Encountered error adding watson example");
          });
      }
    },
    handleSelectionExample(val) {
      this.multipleSelectionOfExample = val;
    },
    deleteExample() {
      this.dialogDeleteExampleVisible = true;
    },
    deleteExampleConfirm() {
      this.dialogDeleteExampleVisible = false;
      let currentIntent = _.find(this.watsonIntents, {
        intent: this.updateId,
      });
      const payload = {
        old_values: {
          // TODO: Check if pointing correctly
          ...currentIntent,
          intent: this.updateId,
        },
        new_values: {
          intent: this.updateId,
          description: currentIntent.description,
          examples: this.multipleSelectionOfExample,
        },
      };
      var loader = this.$loading({
        target: ".el-dialog__body",
        fullscreen: false,
        text: "Loading...",
      });
      this.$store
        .dispatch("DELETE_WATSON_EXAMPLES", payload)
        .then((response) => {
          loader.close();
          this.$message({
            message: "Saved",
            type: "success",
          });
          this.getIntentList({ loading: false }).then(() => {
            this.editIntent(
              this.intentEditForm.intentNameEdited,
              this.intentEditForm.description.join(",")
            );
          });
        })
        .catch((e) => {
          this.notifyError("Encountered error deleting watson examples");
        });
    },
    handleBlur(row) {
      row.edit = false;
      if (row) {
        const payload = {
          old_values: {
            ...row,
            intent: this.updateId,
          },
          new_values: {
            intent: this.updateId,
            text: row.text,
            description: this.intentEditForm.description,
            new_text: row.new_text,
          },
        };
        var loader = this.$loading({
          target: ".example-edit-loader",
          fullscreen: false,
          text: "Loading...",
        });
        this.$store
          .dispatch("UPDATE_WATSON_EXAMPLE", payload)
          .then((response) => {
            loader.close();
            this.$message({
              message: "Saved",
              type: "success",
            });
            this.getIntentList({ loading: false }).then(() => {
              this.editIntent(
                this.intentEditForm.intentNameEdited,
                this.intentEditForm.description.join(",")
              );
            });
          })
          .catch((e) => {
            this.notifyError("Encountered error updating watson example");
          });
      }
    },
  },
};
</script>

<style scoped>
.intent_name {
  cursor: pointer;
  color: #e4392b;
}
.example-section {
  margin-top: 50px;
}

.el-row--flex::after {
  content: "";
  width: 100%;
}
</style>

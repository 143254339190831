<template>
  <div>
    <!-- RESPONSE  -->
    <div v-if="nodeForm.type !== 'folder' && isMCRDisabled(nodeForm)" style="margin-bottom:22px;">
      <div class="node-sub-heading">And respond with:</div>
      <div v-for="(out, index) in nodeForm.output.generic" :key="index" class="response-container">
        <el-form-item>
          <el-select
            v-model="out.response_type"
            :disabled="!authorized"
            style="margin-bottom:5px;"
            placeholder="select"
            @change="setEdited('out.response_type', true)"
          >
            <el-option value="text">Text</el-option>
          </el-select>
          <el-button
            :disabled="!authorized"
            style="margin-left:10px;"
            @click="setEdited('output.generic', true);removeResponse(nodeForm, index)"
          >
            <i class="el-icon-delete" />
          </el-button>
          <div v-for="(val, index2) in out.values" :key="index2">
            <el-autocomplete
              v-if="!val.content"
              v-model.trim="val.text"
              :fetch-suggestions="searchContent"
              :disabled="!authorized"
              placeholder="Enter response variation 2"
              style="margin-bottom:5px;"
              @input="setEdited(`out.value.text[${index2}]`, true);"
              @blur="addResponseValue(nodeForm, index, index2, val.text)"
              @select="setEdited(`out.value.text[${index2}]`, true);addResponseValue(responseForm, index, index2, val.text)"
            >
              <el-button
                v-if="index2 !== out.values.length - 1"
                slot="append"
                icon="el-icon-minus"
                @click="setEdited('out.value.removed', true);removeResponseValue(nodeForm, index, index2)"
              />
            </el-autocomplete>
            <div style="display:inline-block;cursor:pointer;" @click="goToEditor(val.text)">
              <Bubble v-if="val.content" v-model="val.content" />
            </div>
            <el-button
              v-if="val.content && index2 !== out.values.length - 1"
              slot="append"
              style="background: #f5f7fa; margin-left: 5px;"
              icon="el-icon-minus"
              @click="setEdited('out.value.removed', true);removeResponseValue(nodeForm, index, index2)"
            />
          </div>
        </el-form-item>
      </div>
      <div style="padding:0 0 20px 0;cursor:pointer;">
        <span @click="authorized && addResponse(nodeForm, 'text')">
          <el-button :disabled="!authorized" circle icon="el-icon-plus" />Add response
        </span>
      </div>
      <!-- <el-dropdown trigger="click" @command="addResponse">
        <span class="el-dropdown-link">
          <el-button circle icon="el-icon-plus">
          </el-button>
          Add response
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="text">Text</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>-->
    </div>

    <div>
      <!-- MULTIPLE CONDITIONING RESPONSE  -->
      <div v-if="nodeForm.type !== 'folder' && isMCREnabled(nodeForm)" class="mcr-container">
        <div class="node-sub-heading">Respond with:</div>

        <el-form-item style="border-bottom: 1px solid #ddd;">
          <el-col :sm="2" :xs="2">No</el-col>
          <el-col :sm="10" :xs="10">If assistant recognizes</el-col>
          <el-col :sm="10" :xs="10">Respond with</el-col>
          <el-col :sm="1" :xs="1" />
          <el-col :sm="1" :xs="1" />
        </el-form-item>
        <el-form-item v-for="(response, index) in responseNodes" :key="index">
          <el-col :span="1">{{ index + 1 }}</el-col>
          <el-col :span="11">
            <el-autocomplete
              v-model="response.conditions"
              popper-class="mcr-autocomplete"
              :fetch-suggestions="searchIntent"
              :disabled="!authorized"
              placeholder="Enter a entity or intent"
              style="margin-bottom:5px;display:flex;"
              @input="setEdited('response.conditions', true);"
              @select="setEdited('response.conditions', true);$forceUpdate()"
              @blur="
                response.dialog_node !== '' && updateNode(response, null, true)
              "
            />
          </el-col>
          <el-col :span="9">
            <el-autocomplete
              v-if="
                response.output &&
                  (response.output.generic &&
                    response.output.generic[0] &&
                    response.output.generic[0].values[0])
              "
              v-model="response.output.generic[0].values[0].text"
              :fetch-suggestions="searchContent"
              :disabled="!authorized"
              placeholder="Enter response"
              style="margin-bottom:5px;display:flex;"
              @input="setEdited('response.output.generic[0].values[0].text', true);"
              @select="setEdited('response.output.generic[0].values[0].text', true);$forceUpdate()"
              @blur="
                response.dialog_node !== '' && updateNode(response, null, true)
              "
            />
            <el-autocomplete
              v-if="
                response.output &&
                    response.output.text && response.output.text.values[0]
              "
              v-model="response.output.text.values[0].text"
              :fetch-suggestions="searchContent"
              :disabled="!authorized"
              placeholder="Enter response"
              style="margin-bottom:5px;"
              @input="setEdited('response.output.text.values[0].text', true);"
              @select="setEdited('response.output.text.values[0].text', true);$forceUpdate()"
              @blur="
                response.dialog_node !== '' && updateNode(response, null, true)
              "
            />
          </el-col>
          <!-- <el-col :span="1">
            <i
              @click="authorized && openResponse(response, index + 1)"
              class="el-icon-setting"
              style="font-size: 18px"
            ></i>
          </el-col>-->
          <el-col :span="1">
            <i
              class="el-icon-delete"
              style="font-size: 18px"
              @click="authorized && setEdited('output.generic', true);deleteNode(response, index)"
            />
          </el-col>
        </el-form-item>

        <div style="padding:0 0 20px 0;">
          <span @click="addMcr(nodeForm)">
            <el-button :disabled="!authorized" circle icon="el-icon-plus" />Add Response
          </span>
        </div>
      </div>
    </div>

    <!-- RESPONSE NODE -DIALOGS -->
    <el-dialog :visible.sync="responseDialog" width="50%">
      <span slot="title" class="dialog-heading">Configure Response {{ responseIndex }}</span>
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="sub-title node-sub-heading">If assistant recognizes:</div>
          <el-input
            v-model.trim="responseForm.conditions"
            :disabled="!authorized"
            style="margin-bottom:5px;"
            @change="setEdited('responseForm.conditions', true);"
            @blur="updateNode(responseForm)"
          />
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <div class="sub-title node-sub-heading">Respond with:</div>
          <div
            v-for="(out, index) in responseForm.output.generic"
            :key="index"
            class="response-container"
          >
            <!-- <el-form-item> -->
            <el-select
              v-model="out.response_type"
              :disabled="!authorized"
              style="margin-bottom:5px;"
              placeholder="select"
              @change="setEdited('out.response_type', true);"
            >
              <el-option value="text">Text</el-option>
            </el-select>
            <el-button
              :disabled="!authorized"
              style="margin-left:10px;"
              @click="setEdited('out.value.text', true);removeResponse(responseForm, index)"
            >
              <i class="el-icon-delete" />
            </el-button>
            <div v-for="(val, index2) in out.values" :key="index2">
              <el-autocomplete
                v-if="!val.content"
                v-model.trim="val.text"
                :fetch-suggestions="searchContent"
                :disabled="!authorized"
                placeholder="Enter response variation"
                style="margin-bottom:5px;"
                @input="setEdited('out.value.text', true);"
                @select="setEdited('out.value.text', true);"
                @blur="addResponseValue(responseForm, index, index2, val.text)"
              >
                <el-button
                  v-if="index2 !== out.values.length - 1"
                  slot="append"
                  icon="el-icon-minus"
                  @click="setEdited('out.value', true);removeResponseValue(responseForm, index, index2)"
                />
              </el-autocomplete>
              <div style="display:inline-block;cursor:pointer;" @click="goToEditor(val.text)">
                <Bubble v-if="val.content" v-model="val.content" />
              </div>
              <el-button
                v-if="val.content && index2 !== out.values.length - 1"
                slot="append"
                style="background: #f5f7fa; margin-left: 5px;"
                icon="el-icon-minus"
                @click="setEdited('out.value', true);removeResponseValue(responseForm, index, index2)"
              />
            </div>
            <!-- </el-form-item> -->
          </div>

          <div style="padding:0 0 20px 0;cursor:pointer;">
            <span @click="authorized && addResponse(responseForm, 'text')">
              <el-button :disabled="!authorized" circle icon="el-icon-plus" />Add response
            </span>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="responseDialog = false">Close</el-button>
        <!-- <el-button
          type="primary"
          @click="responseDialog = false;updateResponseDialog(responseForm)"
        >Confirm</el-button>-->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Bubble from "@/components/Bubble";
import { setTimeout } from "timers";
import _isEqual from "lodash/isEqual";
import _ from "lodash";

export default {
  name: "Response",
  components: {
    Bubble
  },
  props: {
    nodeForm: Object,
    authorized: Boolean,
    searchIntent: Function,
    searchContent: Function,
    response_nodes: Array
  },
  data() {
    return {
      responseDialog: false,
      responseIndex: 0,
      responseForm: {
        output: {
          generic: []
        }
      },
      responseNodes: this.response_nodes
    };
  },
  computed: {},
  watch: {
    response_nodes: function(newVal, oldVal) {
      if (!_isEqual(newVal, oldVal)) {
        this.responseNodes = newVal;
        this.responseNodes.forEach(response => {
          const outputGeneric = _.get(response, "output.generic");
          const outputText = _.get(response, "output.text");
          const textLength = _.get(response, "output.text.values.length");

          if (response.output && !outputGeneric && !outputText) {
            console.log("No response:", response);
            const resObj = [
              {
                response_type: "text",
                values: [{ text: "" }]
              }
            ];
            this.$set(response, "output", { generic: resObj });
          } else if (textLength >= 0) {
            const resObj = [
              {
                response_type: "text",
                values: []
              }
            ];
            if (textLength === 0) {
              resObj[0].values.push({ text: "" });
            } else {
              response.output.text.values.forEach(val => {
                resObj[0].values.push({ text: val });
              });
            }
            this.$set(response, "output", { generic: resObj });
          }
          if (!response.conditions) {
            this.$set(response, "conditions", "");
          } else {
            this.$set(response, "conditions", response.conditions);
          }
        });
      }
    }
  },
  mounted() {},
  methods: {
    setEdited(nodeVariableType, isEdited) {
      this.$set(this.$store.state.watson, "isEditedObject", {
        [nodeVariableType]: isEdited
      });
    },
    isMCRDisabled(nodeForm) {
      const isDisabled = !_.get(nodeForm, "metadata._customization.mcr");
      return isDisabled;
    },
    isMCREnabled(nodeForm) {
      const isEnabled = _.get(nodeForm, "metadata._customization.mcr");
      return isEnabled;
    },
    removeResponse(form, index) {
      form.output.generic.splice(index, 1);
      this.$emit("updateNode-click", form);
    },
    addResponseValue(form, index, index2, val) {
      const self = this;
      setTimeout(function() {
        // filter out empty responses
        _.each(form.output.generic, ({ values }) => {
          _.each(values, (value, index) => {
            if (value.text === "") {
              values.splice(index, 1);
            }
          });
        });
        self.$emit("updateNode-click", form);
      }, 800);
    },
    removeResponseValue(form, index, index2) {
      form.output.generic[index].values.splice(index2, 1);
      this.$emit("updateNode-click", form);
    },
    goToEditor(val) {
      let id = val;
      this.$store.dispatch("SELECT_NODE", { type: "content", id });
      this.$router.push("/editor");
    },
    addResponse(form, type) {
      form.output.generic.push({
        values: [
          {
            text: ""
          }
        ],
        response_type: type,
        selection_policy: "sequential"
      });
    },
    /* GROUP - MCR */
    openResponse(response, index) {
      const self = this;
      this.responseDialog = true;
      this.responseIndex = index;
      this.responseForm = response;
      let genericArray = [];

      const genericArrayExists =
        this.responseForm.output &&
        this.responseForm.output.generic &&
        this.responseForm.output.generic.length > 0;
      if (genericArrayExists) {
        genericArray = this.responseForm.output.generic || [];
        genericArray.forEach(ele => {
          if (
            ele.values.length === 0 ||
            (ele.response_type === "text" &&
              ele.values[ele.values.length - 1].text !== "")
          ) {
            ele.values.push({
              text: ""
            });
          } else if (ele.response_type == "option") {
            ele.values.push({
              option: ""
            });
          }
          ele.values.forEach(val => {
            val["content"] = null;
            for (let con in self.$store.state.nodes.content) {
              if (con === val.text) {
                val["content"] = self.$store.state.nodes.content[con].content;
              }
            }
          });
        });
      } else if (
        this.responseForm.output &&
        this.responseForm.output.text &&
        this.responseForm.output.text.values &&
        this.responseForm.output.text.values.length > 0
      ) {
        genericArray = [
          {
            response_type: "text",
            values: []
          }
        ];
        this.responseForm.output.text.values.forEach(val => {
          genericArray[0].values.push({ text: val });
        });
        genericArray.forEach(ele => {
          if (
            ele.values.length === 0 ||
            (ele.response_type === "text" &&
              ele.values[ele.values.length - 1].text !== "")
          ) {
            ele.values.push({
              text: ""
            });
          } else if (ele.response_type == "option") {
            ele.values.push({
              option: ""
            });
          }
          ele.values.forEach(val => {
            val["content"] = null;
            for (let con in self.$store.state.nodes.content) {
              if (con === val.text) {
                val["content"] = self.$store.state.nodes.content[con].content;
              }
            }
          });
        });
      }
      this.$set(this.responseForm, "output", { generic: genericArray });
      this.$set(this.responseForm, "description", this.nodeForm.description);
    },
    updateNode(node, type = null, loader = null) {
      this.responseNodes.forEach(response => {
        const conditions = _.get(response, "conditions");
        if (conditions) {
          response.conditions = conditions.trim();
        }
        const genericValue = _.get(
          response,
          "output.generic[0].values[0].text"
        );
        const textValue = _.get(
          response,
          "response.output.text.values[0].text"
        );
        if (genericValue) {
          response.output.generic[0].values[0].text = genericValue.trim();
        }
        if (textValue) {
          response.output.text.values[0].text = textValue.trim();
        }
      });
      this.$emit("updateNode-click", node, type, loader);
    },
    addMcr(form) {
      this.$emit("addMcr-click", form);
    },
    deleteNode(node, index) {
      this.$emit("deleteNode-click", node, index);
    }
  }
};
</script>

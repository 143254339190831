<template>
  <div style="margin-bottom:2em; display:flex; align-items:center;">
    <span style="color:#909399; fontSize: 14px;">{{ setting.label || name }}</span>
    <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="right">
      <i class="el-icon-question" style="margin-left: 0.5em; color: #909399" />
    </el-tooltip>
    <br />

    <div style="margin-left: 10px;">
      <el-slider
        v-if="setting.slider===true"
        show-input
        v-model="value[name]"
        :disabled="setting.disabled ? true : false"
        :required="setting.required"
        :min="setting.min"
        :max="setting.max"
        :step="setting.step ? setting.step: 1"
        style="width: 720px; max-width: 100%;"
      />

      <el-input-number
        v-else
        v-model="value[name]"
        :disabled="setting.disabled ? true : false"
        :required="setting.required"
        :min="setting.min"
        :max="setting.max"
        :step="setting.step? setting.step: 1"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>
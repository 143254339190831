import { Vue } from "./bootstrap";
import Root from "@/Root.vue";
import router from "@/router";
import store from "@/store";

function main() {
  const vm = new Vue({
    router,
    store,
    render: (h) => h(Root as any),
  }).$mount("#app");

  const $keyreply = (window as any).$keyreply;
  if ($keyreply) {
    $keyreply.getVM = () => vm;
  }
  return vm;
}

main();

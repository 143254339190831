import { render, staticRenderFns } from "./SearchMessagesBar.vue?vue&type=template&id=3217b083&scoped=true&"
import script from "./SearchMessagesBar.vue?vue&type=script&lang=js&"
export * from "./SearchMessagesBar.vue?vue&type=script&lang=js&"
import style0 from "./SearchMessagesBar.vue?vue&type=style&index=0&id=3217b083&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3217b083",
  null
  
)

export default component.exports
<template>
  <el-select
    v-model="selectPlaceHolder"
    placeholder="Rollback"
    style="margin-right: 10px; max-width: 250px"
    loading-text="Loading snapshots..."
    :loading="isBusy"
    @change="clearSelection()"
    size="small"
    @visible-change="onVisibleChange"
  >
    <el-option-group
      v-for="(obj, date) in botSnapshotsForPast30Days"
      :key="date"
      :label="date"
    >
      <el-option
        v-for="(obj, index) in botSnapshotsForPast30Days[date]"
        :key="index"
        :label="obj.time + ' (' + obj.timeAgo + ')'"
        :value="obj.snapshot"
        @click.native="rollbackBot(obj.snapshot)"
      />
    </el-option-group>
  </el-select>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "RollbackSelector",
  props: ["setStatus", "reloadBot"],
  data() {
    return {
      selectPlaceHolder: "",
      isBusy: false,
    };
  },
  computed: {
    botSnapshotsForPast30Days() {
      const botSnapshotsFromState = _.get(
        this.$store,
        "state.botSnapshots",
        {}
      );
      const botSnapshotsForPast30Days = {};
      const currentDate = moment();
      Object.keys(botSnapshotsFromState).forEach((key) => {
        const date = moment(key, "Do MMM YY");
        const isWithinLast2Weeks = date
          .add(30, "days")
          .isSameOrAfter(currentDate);
        if (isWithinLast2Weeks) {
          botSnapshotsForPast30Days[key] = botSnapshotsFromState[key];
        }
      });
      return botSnapshotsForPast30Days;
    },
  },
  methods: {
    async onVisibleChange(isOpen) {
      if (isOpen && !this.isBusy) {
        this.isBusy = true;
        try {
          await this.$store.dispatch("FETCH_BOT_SNAPSHOTS", {
            brain: this.$store.state.brain,
          });
        } catch (error) {
          this.$message({
            type: "info",
            message: "Failed to fetch snapshots.",
          });
        }
        this.isBusy = false;
      }
    },
    clearSelection() {
      this.selectPlaceHolder = "";
    },
    rollbackBot(snapshotId) {
      this.$confirm(
        "Are you sure you want to rollback to this version?",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "warn",
            message: "Rollback in progress",
          });

          this.$store
            .dispatch("rollbackBot", {
              brain: this.$store.state.brain,
              snapshotId,
            })
            .then(this.reloadBot)
            .catch(() => {
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: `Failed to rollback.`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Rollback cancelled",
          });
        });
    },
  },
};
</script>

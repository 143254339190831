<template>
  <div>
    <el-row :gutter="20" v-for="(token, key, index) in tokens" :key="index">
      <el-col :span="10">
        <el-input size="mini" :value="key" type="text" :readonly="true" />
      </el-col>
      <el-col :span="10">
        <el-input
          v-model="tokens[key]"
          type="text"
          size="mini"
          placeholder="Input Test Value"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["tokens"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>

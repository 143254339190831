<template>
  <div class="predefined-filter--wrapper" v-show="enabled && filters.length">
    <h4>Predefined filters</h4>
    <el-tooltip
      v-for="filter in filters"
      :key="filter.name"
      effect="dark"
      :content="filter.description"
      placement="bottom"
    >
      <div
        class="filter"
        :style="getFilterStyles(filter)"
        @click="(e) => onFilterSelected(e, filter)"
      >
        {{ filter.name }}
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
  name: "PredefinedFilter",
  props: {
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    enabled() {
      return this.liveChatFilters?.enabled === true;
    },
    liveChatFilters() {
      const liveChatFiltersSettings = this.$store.state.modules?.core
        ?.liveChatFilters;
      return _.cloneDeep(liveChatFiltersSettings);
    },
    filters() {
      return this.liveChatFilters[this.type];
    },
  },
  methods: {
    getFilterStyles(filter) {
      return {
        color: this.getFilterColor(filter),
        backgroundColor: this.getFilterBackgroundColor(filter),
        ...filter?.extras,
      };
    },
    getFilterColor(filter) {
      return filter?.styles?.color || "#444444";
    },
    getFilterBackgroundColor(filter) {
      return filter?.styles?.backgroundColor || "#ffffff";
    },
    parseFilterValue(filter) {
      const filterObj = {};

      _.forEach(
        filter.conditions,
        (condition) => (filterObj[condition.key] = condition.value)
      );

      // parse date range
      let startDate, endDate;

      // FIXME: very simple parser
      if (filter?.dateRange?.type === "text") {
        let dateDiff;
        if (filter.dateRange.value.toLowerCase() === "yesterday") {
          dateDiff = 1;
        } else if (filter.dateRange.value.match(/last \d+ day?.$/gi)) {
          dateDiff = filter.dateRange.value.match(/\d+/g)[0];
        }
        startDate = moment().subtract(dateDiff, "days").startOf("day");
        endDate = moment().endOf("day");
      }

      return {
        ...filterObj,
        startDate,
        endDate,
      };
    },
    onFilterSelected(e, filter) {
      const parsedFilter = this.parseFilterValue(filter);
      this.$emit("selectedFilter", parsedFilter);
    },
  },
};
</script>

<style scoped lang="scss">
.predefined-filter--wrapper {
  margin: 15px 0 0 0;

  h4 {
    font-size: 10px;
    font-weight: normal;
    color: #666;
    margin-bottom: 5px;
  }

  .filter {
    font-size: 11px;
    padding: 2px 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #e6ebf5;
    display: inline-flex;
    border-radius: 3px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      border-color: #e4392b !important;
      color: #fff !important;
      background: #e4392b !important;
    }
  }
}
</style>

<template>
  <el-table
    :data="batchTestResult"
    style="width: 100%; min-height: 300px"
    size="mini"
    :row-class-name="correctContentNodeId"
  >
    <el-table-column prop="department" label="Department" min-width="120" />
    <el-table-column prop="utterance" label="Utterance" min-width="130" />
    <el-table-column
      prop="expectedIntent"
      label="Expected Intent"
      min-width="150"
    />
    <el-table-column label="Top Predicted Intent" min-width="120">
      <template slot-scope="scope">{{
        getTopPredictedIntent(scope.row)
      }}</template>
    </el-table-column>
    <el-table-column label="Expected Answer" min-width="150">
      <template slot-scope="scope">{{ getExpectedAnswer(scope.row) }}</template>
    </el-table-column>
    <el-table-column label="Predicted Answer" min-width="150">
      <template slot-scope="scope">
        {{ getAnswer(scope.row) }}
        <b>({{ getAnswerType(scope.row) }})</b>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import _ from "lodash";

export default {
  props: ["batchTestResult"],
  name: "BatchTestResultsContentNodeId",
  methods: {
    getTopPredictedIntent(row) {
      return (
        _.get(row, "intents[0].intent") || _.get(row, "intents[0].name", "")
      );
    },
    getExpectedAnswer(row) {
      if (row.expectedEventId) {
        return row.expectedEventId;
      } else {
        return row.expectedContentNodeId;
      }
    },
    getAnswerType(row) {
      if (row.expectedEventId) {
        const eventId = _.get(row, "eventId", "");
        return `Event`;
      } else {
        const contentNodeId = _.get(row, "contentNodeId", "");
        return `Content Node`;
      }
    },
    getAnswer(row) {
      if (row.expectedEventId) {
        const eventId = _.get(row, "eventId", "");
        return `${eventId}`;
      } else {
        const contentNodeId = _.get(row, "contentNodeId", "");
        return `${contentNodeId}`;
      }
    },
    isEventIdMatch(row) {
      const eventId = _.get(row, "eventId", "");
      return (
        row.expectedEventId &&
        row.expectedEventId.toLowerCase().trim() ===
          eventId.toLowerCase().trim()
      );
    },
    isContentNodeIdMatch(row) {
      const contentNodeId = _.get(row, "contentNodeId", "");
      return (
        row.expectedContentNodeId &&
        row.expectedContentNodeId.toLowerCase().trim() ===
          contentNodeId.toLowerCase().trim()
      );
    },
    isIntentMatch(row) {
      return this.getTopPredictedIntent(row) === row.expectedIntent;
    },
    correctContentNodeId({ row, column, rowIndex, columnIndex }) {
      if (
        (this.isEventIdMatch(row) && this.isIntentMatch(row)) ||
        (this.isContentNodeIdMatch(row) && this.isIntentMatch(row))
      ) {
        return "success-row";
      } else if (
        !row.expectedContentNodeId &&
        !row.expectedEventId &&
        this.isIntentMatch(row)
      ) {
        return "success-row";
      }
      return "warning-row";
    },
  },
};
</script>
<style scoped>
.el-table .warning-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

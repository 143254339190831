var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px"}},[_c('el-radio-group',{attrs:{"size":"mini"},on:{"change":_vm.updateDataType},model:{value:(_vm.sideEffect.data.type),callback:function ($$v) {_vm.$set(_vm.sideEffect.data, "type", $$v)},expression:"sideEffect.data.type"}},[_c('el-radio-button',{attrs:{"label":"string"}},[_vm._v("Text")]),_c('el-radio-button',{attrs:{"label":"number"}},[_vm._v("Number")]),_c('el-radio-button',{attrs:{"label":"boolean"}},[_vm._v("True/False")]),_c('el-radio-button',{attrs:{"label":"object"}},[_vm._v("Object")]),_c('el-radio-button',{attrs:{"label":"input"}},[_vm._v("Value from user")])],1)],1),(
      _vm.sideEffect.data.type === 'string' && typeof _vm.sideEffect.data.value === 'string'
    )?_c('div',{staticClass:"el-input el-input--mini el-input-group el-input-group--prepend",staticStyle:{"width":"60%","margin-bottom":"10px"}},[_c('div',{staticClass:"el-input-group__prepend"},[_vm._v("Value")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value"}],staticClass:"el-input__inner",attrs:{"autocomplete":"off","placeholder":"Input value"},domProps:{"value":(_vm.sideEffect.data.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sideEffect.data, "value", $event.target.value)}}})]):_vm._e(),(
      _vm.sideEffect.data.type === 'number' && typeof _vm.sideEffect.data.value === 'number'
    )?_c('div',{staticClass:"el-input el-input--mini el-input-group el-input-group--prepend",staticStyle:{"width":"60%","margin-bottom":"10px"}},[_c('div',{staticClass:"el-input-group__prepend"},[_vm._v("Value")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value",modifiers:{"number":true}}],staticClass:"el-input__inner",attrs:{"type":"number","autocomplete":"off","placeholder":"Input value"},domProps:{"value":(_vm.sideEffect.data.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sideEffect.data, "value", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),(
        _vm.sideEffect.data.type === 'boolean' && typeof _vm.sideEffect.data.value === 'boolean'
      )?_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value"}],attrs:{"type":"radio","name":"type-boolean"},domProps:{"value":true,"checked":_vm._q(_vm.sideEffect.data.value,true)},on:{"change":function($event){return _vm.$set(_vm.sideEffect.data, "value", true)}}}),_c('label',{staticStyle:{"margin-right":"20px"}},[_vm._v("True")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sideEffect.data.value),expression:"sideEffect.data.value"}],attrs:{"type":"radio","name":"type-boolean"},domProps:{"value":false,"checked":_vm._q(_vm.sideEffect.data.value,false)},on:{"change":function($event){return _vm.$set(_vm.sideEffect.data, "value", false)}}}),_c('label',[_vm._v("False")]),_c('br')]):_vm._e(),(
      _vm.sideEffect.data.type === 'object' && typeof _vm.sideEffect.data.value === 'object'
    )?_c('ul',{staticStyle:{"padding-left":"0"}},[_c('li',{staticStyle:{"list-style-type":"none"}},[_c('el-button',{staticStyle:{"margin-bottom":"5px"},attrs:{"icon":"el-icon-plus","type":"text","size":"mini"},on:{"click":_vm.addPair}},[_vm._v("Add Key/Value Pair")])],1),_vm._l((_vm.sideEffect.data.value),function(kv,index){return _c('li',{key:index,staticStyle:{"list-style-type":"none"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(kv.key),expression:"kv.key"}],staticStyle:{"max-width":"40%","margin-right":"5px"},attrs:{"type":"text","placeholder":"Insert key"},domProps:{"value":(kv.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(kv, "key", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(kv.value),expression:"kv.value"}],staticStyle:{"max-width":"40%","margin-right":"5px"},attrs:{"type":"text","placeholder":"Insert value"},domProps:{"value":(kv.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(kv, "value", $event.target.value)}}}),_c('el-button',{staticClass:"el-icon-delete",attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.removePair(index)}}})],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- Approval Confirmation dialog -->
  <el-dialog
    title="Approval Confirmation"
    :visible.sync="approveConfirmationVisible"
    @closed="handleDialogClose"
    width="50%"
    center
  >
    <p style="text-align: center; font-size: 16px">
      Do you wish to publish your changes now or on a future date?
      <span
        v-if="
          (selectedApprove && selectedApprove.mainType === 'dialog') ||
          dialogNodesChosen
        "
        style="font-size: 14px; font-style: italic; color: red"
      >
        <br />*
        <u>Dialog nodes</u> can only be published now due to dependency
        issues
      </span>
    </p>
    <el-form
      ref="approveConfirmationForm"
      v-loading="approveConfirmationLoading"
      :model="approveConfirmationForm"
      label-width="150px"
      :rules="approveConfirmationRule"
      element-loading-text="Creating new publish..."
    >
      <el-form-item label="Publish Name:" prop="publishName">
        <el-input
          v-model="approveConfirmationForm.publishName"
          type="text"
          placeholder="Publication's name as reference"
        />
      </el-form-item>
      <el-form-item label="Publish Date:" prop="publish_date">
        <el-date-picker
          v-model="approveConfirmationForm.publish_date"
          format="dd-MM-yyyy HH:mm"
          value-format="dd-MM-yyyy HH:mm:ss"
          style="width: 50%"
          type="datetime"
          placeholder="Select publish date and time"
          :picker-options="dateTimePicker"
        />
      </el-form-item>

      <el-form-item class="dialog buttons">
        <el-button size="mini" @click="publishChangesNow()">
          <i class="el-icon-check" /> Publish Now
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="
            (selectedApprove && selectedApprove.mainType === 'dialog') ||
            dialogNodesChosen
          "
          @click="submitApproveForm('approveConfirmationForm')"
        >
          <i class="el-icon-bell" /> Publish on date
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: [
    "activeDepartment",
    "filteredDepartments",
    "multipleSelection",
    "specificChange",
    "activeTaskType",
    "filterActivated",
    "dataFilter",
  ],
  data() {
    return {
      // Approve Confirmation
      approveConfirmationForm: { publish_date: "", publishName: "" },
      approveConfirmationVisible: false,
      approveConfirmationLoading: false,
      selectedApprove: {
        department: "",
        mainType: "",
      },
      approveConfirmationRule: {
        publish_date: [
          {
            required: true,
            message: "Please input date and time",
            trigger: "blur",
          },
        ],
      },
      dateTimePicker: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime();
        },
        shortcuts: [
          {
            text: "Tomorrow",
            onClick(picker) {
              const date = new moment().add(1, "day").toDate();
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week later",
            onClick(picker) {
              const date = new moment().add(1, "week").toDate();
              picker.$emit("pick", date);
            },
          },
          {
            text: "A month later",
            onClick(picker) {
              const date = new moment().add(1, "month").toDate();
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "department",
      "makerCheckerObject",
      "departments",
      "mainType",
    ]),
    dialogNodesChosen() {
      if (this.multipleSelection) {
        const tempDialogNodeArray =
          _.chain(this.multipleSelection)
            .filter((selection) => {
              return selection.mainType === "dialog";
            })
            .value() || [];
        return tempDialogNodeArray.length > 0;
      } else {
        return false;
      }
    },
    makerCheckerConfiguration() {
      return _.assign(this.makerCheckerObject || {}, {
        filterActivated: this.filterActivated,
        buttonGroupWidth:
          document.getElementById("buttonGroup") &&
          document.getElementById("buttonGroup").offsetWidth,
      });
    },
  },
  methods: {
    typeFilter(type, department) {
      if (department === "all") {
        return [type.toLowerCase()];
      }
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        return _.chain(this.multipleSelection)
          .map((change) => {
            return change.mainType && change.mainType.toLowerCase();
          })
          .uniq()
          .value();
      }
    },
    departmentsFilter(type, department) {
      const userDepartments = this.departments || [];
      const lowerCaseUserDepartments = userDepartments.map((v) =>
        v.toLowerCase()
      );

      const activeDepartment = this.activeDepartment || "";
      const lowerCaseActiveDepartment = [activeDepartment].map((v) =>
        v.toLowerCase()
      );

      const allDeparmentsSelected =
        department === "all" ||
        !department ||
        lowerCaseActiveDepartment.includes("all departments");

      if (allDeparmentsSelected) {
        return userDepartments;
      }

      if (lowerCaseActiveDepartment) {
        const userValidatedDeparments = _.intersection(
          activeDepartment,
          userDepartments
        );
        return userValidatedDeparments;
      }
    },
    resolverDataHandover(type, department) {
      const result = {
        listOfChanges: this.dataFilter(type, department),
        types: this.typeFilter(type, department),
        departments: this.departmentsFilter(type, department),
      };
      return result;
    },
    publishChangesNow() {
      const publishEnabled =
        this.makerCheckerConfiguration &&
        this.makerCheckerConfiguration.publish;
      if (publishEnabled) {
        this.approveConfirmationVisible = true;
        this.approveConfirmationLoading = true;
      }

      const { mainType: type, department } = this.selectedApprove;
      const { publish_date, publishName } = this.approveConfirmationForm;
      const { listOfChanges, types, departments } = this.resolverDataHandover(
        type,
        department
      );

      const payload = {
        publish_date,
        publishName,
        listOfChanges,
        types,
        departments,
      };
      this.$store
        .dispatch("CREATE_AND_COMPLETE_PUBLISH", payload)
        .then((isPublished) => {
          if (isPublished) {
            this.$message({
              type: "success",
              message: "Changes has been approved and published successfully",
            });
            this.$emit("fetchPendingChanges");
            this.$emit("fetchCompletedChanges");
            this.resetApprovalConfirmationForm();
            this.$emit("resetChangeSelection");
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$emit("closeApprove");
          } else {
            this.$message({
              type: "error",
              message: "Encountered error trying to approve changes",
            });
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$emit("closeApprove");
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error creating and publish content.",
          });
          this.approveConfirmationLoading = false;
          this.approveConfirmationVisible = false;
          this.$emit("closeApprove");
        });
    },
    publishChangesOnDate() {
      this.approveConfirmationVisible = true;
      this.approveConfirmationLoading = true;

      const { mainType: type, department } = this.selectedApprove;
      const { publish_date, publishName } = this.approveConfirmationForm;
      const { listOfChanges, types, departments } = this.resolverDataHandover(
        type,
        department
      );
      const payload = {
        publish_date,
        publishName,
        listOfChanges,
        types,
        departments,
      };

      this.$store
        .dispatch("CREATE_PUBLISH", payload)
        .then((isPublished) => {
          if (isPublished) {
            this.$message({
              type: "success",
              message:
                "Changes has been approved and added to publish queue successfully",
            });
            this.$emit("fetchPendingChanges");
            this.$emit("fetchCompletedChanges");
            this.resetApprovalConfirmationForm();
            this.$emit("resetChangeSelection");
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$emit("closeApprove");
          } else {
            this.$message({
              type: "error",
              message:
                "Encountered error trying to approve and create new publications",
            });
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$emit("closeApprove");
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error creating a publish.",
          });
          this.approveConfirmationLoading = false;
          this.approveConfirmationVisible = false;
          this.$emit("closeApprove");
        });
    },

    resetApprovalConfirmationForm() {
      this.approveConfirmationForm = { publish_date: "", publishName: "" };
    },

    submitApproveForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.publishChangesOnDate();
          return false;
        } else {
          console.log("Error submiting approval form!!");
          return false;
        }
      });
    },

    handleDialogClose() {
      this.$emit("closeApprove");
    },

    // openApproveConfirmation(mainType, department) {
    //   this.approveConfirmationVisible = true;
    //   this.selectedApprove = { mainType, department };
    // },

    // resetChangeSelection() {
    //   this.specificChange = {};
    //   this.collapseAll();
    //   this.activeDepartment = "";
    //   this.searchTerm = "";
    //   this.activeTaskType = [];
    // },
  },
  mounted() {
    this.approveConfirmationVisible = true;
    this.selectedApprove = {
      mainType: this.mainType,
      department: this.department,
    };
  },
};
</script>

<style></style>

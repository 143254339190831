<template>
  <el-card style="max-height: 68px; height: 68px; min-height: 68px; text-align:left;">
    <el-row>
      <el-col :sm="12">
        <h3
          style="text-align: left; font-size: 14px; font-weight: 500; line-height: 26px;"
        >Feedback to KeyReply</h3>
      </el-col>
      <el-col :sm="12" style="text-align: right;">
        <a rel="noopener" target="_blank" :href="serviceSupportURL">
          <el-button
            class="button"
            size="mini"
            style="margin-top: 0px; font-size: 12px;"
          >Leave a Feedback</el-button>
        </a>
      </el-col>
    </el-row>
    <h1></h1>
    <!-- form -->
    <!-- <p class="feedback subheaders">Have a few issues? Write to us through feedback form!</p> -->
    <!-- <el-button class="button" @click="showFeedbackForm = true">Leave a Feedback</el-button> -->
  </el-card>
</template>

<script>
export default {
  props: [],
  name: "Feedback",
  data() {
    return {
      serviceSupportURL:
        "https://keyreply.atlassian.net/servicedesk/customer/portals"
    };
  }
};
</script>
<template>
  <el-row type="flex" style="flex-flow: row wrap; align-items: start;">
    <span style="padding: 10px 15px 0 0;">
      <p style="line-height: 10px; margin: 0;">Min</p>
      <el-input-number size="mini" v-model="field.min" :disabled="!canEdit"></el-input-number>
    </span>

    <span style="padding: 10px 15px 0 0;">
      <p style="line-height: 10px; margin: 0;">Max</p>
      <el-input-number size="mini" v-model="field.max" :disabled="!canEdit"></el-input-number>
    </span>

    <el-checkbox v-model="field.visible" label="Visible" :disabled="!canEdit"></el-checkbox>
    <el-checkbox v-model="field.editable" label="Editable" :disabled="!canEdit"></el-checkbox>
  </el-row>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["field", "canEdit"],
});
</script>


<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="15">
        <el-card v-loading="loading" element-loading-text="Waiting for OpenAI..">
          <h2 class="titleHeading">OpenAI GPT3</h2>
          <el-collapse v-if="enableContext" v-model="activeCollapse" style="border:none">
            <el-collapse-item class="heading" title="Context" name="1">
              <el-input
                type="textarea"
                :rows="8"
                placeholder="Enter context here"
                v-model="context"
              ></el-input>
            </el-collapse-item>
          </el-collapse>
          <h5 class="heading">Input</h5>
          <el-input type="textarea" :rows="4" placeholder="Enter input here" v-model="input"></el-input>
          <h5 class="heading">Output</h5>
          <div v-for="(choice,index)  in outputChoices" :key="`choice-${index}`">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="OpenAI's output"
              :value="choice.text"
              readonly
            ></el-input>
          </div>
          <el-button class="button" @click="onSubmit">Submit</el-button>
        </el-card>
      </el-col>
      <el-col :span="9">
        <el-card>
          <h4 class="settingTitle">API Settings</h4>
          <el-form ref="form" label-width="110px">
            <el-form-item label="API Key">
              <el-input
                placeholder="Please input API key without bearer"
                v-model="API_KEY"
                type="password"
              ></el-input>
            </el-form-item>

            <el-tooltip effect="dark" :content="tooltipHints.temperature" placement="right">
              <el-form-item label="Temperature">
                <el-slider v-model="apiFields.temperature" :format-tooltip="format100"></el-slider>
              </el-form-item>
            </el-tooltip>

            <el-tooltip effect="dark" :content="tooltipHints.max_tokens" placement="right">
              <el-form-item label="Max Tokens">
                <el-input-number v-model="apiFields.max_tokens" :min="1"></el-input-number>
              </el-form-item>
            </el-tooltip>

            <el-tooltip effect="dark" :content="tooltipHints.stop" placement="right">
              <el-form-item label="Stop sequence">
                <el-input placeholder="Enter stop sequence" v-model="apiFields.stop"></el-input>
              </el-form-item>
            </el-tooltip>

            <el-tooltip effect="dark" :content="tooltipHints.n" placement="right">
              <el-form-item label="n">
                <el-input-number v-model="apiFields.n" :min="1"></el-input-number>
              </el-form-item>
            </el-tooltip>
          </el-form>
        </el-card>
        <el-card>
          <h4 class="settingTitle">Other Settings</h4>
          <el-tooltip effect="dark" :content="tooltipHints.enableContext" placement="right">
            <el-checkbox v-model="enableContext">Enable Context Field</el-checkbox>
          </el-tooltip>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      endpoint: "https://api.openai.com/v1/engines/davinci/completions",
      activeCollapse: "1",
      loading: false,
      tooltipHints: {
        temperature:
          "How creative output will be, 0 for repeatable pattern, 1 for most creative output",
        max_tokens: "How long response will be",
        stop:
          "Character(s) that will stop OpenAI from generating more output. Max 4 stop sequences",
        n: "Number of outputs",
        enableContext:
          "Does not make a difference, both context and input will be merged with \\n and submitted as prompt",
      },

      //input fields
      context: "",
      input: "",
      outputChoices: [""],
      API_KEY: "",
      enableContext: true,

      // gpt3 api settings
      apiFields: {
        prompt: "this is a test",
        max_tokens: "300",
        temperature: 10,
        stop: "\\n",
        n: 1,
      },
    };
  },
  methods: {
    format100(val) {
      return val / 100;
    },
    onSubmit() {
      if (this.enableContext) {
        this.apiFields.prompt = this.context + "\n" + this.input;
      } else {
        this.apiFields.prompt = this.input;
      }
      this.invokeGPT();
    },
    invokeGPT() {
      const requestData = {
        prompt: this.apiFields.prompt,
        max_tokens: this.apiFields.max_tokens,
        temperature: this.format100(this.apiFields.temperature),
        stop: this.apiFields.stop,
        n: this.apiFields.n,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.API_KEY}`,
        },
      };
      console.log(requestData);

      this.loading = true;
      axios
        .post(this.endpoint, requestData, config)
        .then((response) => {
          this.loading = false;
          // console.log(response.data);
          this.outputChoices = response.data.choices;
          this.$notify.success({
            title: "Success",
            message: "Output received",
            position: "bottom-right",
          });
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
          this.$notify.error({
            title: "Something went wrong",
            message: `${error.message}`,
            position: "bottom-right",
          });
        });
    },
  },
};
</script>

<style>
.titleHeading {
  margin: 0;
}

.settingTitle {
  margin-top: 0;
}

.heading {
  margin-top: 1em;
  margin-bottom: 3px;
}

.button {
  float: right;
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
<template>
  <el-dropdown
    :disabled="isBusy || isInviting || !isAttendingOrPending"
    size="mini"
    style="margin: auto 0"
    trigger="click"
    @command="onInviteAgent"
    @visible-change="onVisibleChange"
  >
    <el-button icon="el-icon-user" type="info" plain size="mini"
      >Invite agent</el-button
    >

    <el-dropdown-menu slot="dropdown">
      <template v-if="!isFetchingEligibleAgents">
        <el-dropdown-item v-if="agentsWithoutSelf.length === 0" disabled
          >No other agents online</el-dropdown-item
        >
        <el-dropdown-item
          v-for="agent in agentsWithoutSelf"
          :key="agent.email"
          :command="agent.email"
          :disabled="agent.isInChat"
        >
          {{ agent.email }}
          <el-badge
            class="mark"
            style="margin-top: 5px"
            :value="agent.handlingCount"
          />
        </el-dropdown-item>
      </template>
      <template v-else>
        <el-dropdown-item disabled>
          <div style="text-align: center">
            <i class="el-icon-loading"></i>
          </div>
        </el-dropdown-item>
      </template>
    </el-dropdown-menu>

    <el-dialog
      :visible.sync="isDialogOpened"
      id="inviteAgentDialog"
      title="Invite agent"
      width="50%"
    >
      <div width="100%">
        <div style="text-align: center">
          <h3>Leave a note</h3>
        </div>
        <el-input
          v-model="transferMemoInput"
          :rows="4"
          :disabled="isInviting || isBusy"
          :placeholder="`Start typing your note to ${selectedAgent}...`"
          type="textarea"
          resize="none"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="isInviting || isBusy"
          type="primary"
          @click="inviteAgent"
          >Confirm</el-button
        >
        <el-button
          :disabled="isInviting || isBusy"
          :loading="isInviting"
          @click="isDialogOpened = false"
          >Cancel</el-button
        >
      </span>
    </el-dialog>
  </el-dropdown>
</template>

<script>
import _ from "lodash";
import gql from "graphql-tag";
import { graph, rest } from "@/store/api";

export default {
  name: "ChatInteractionsInviteAgent",
  props: ["isBusy", "selectedChat", "chatsList"],
  data() {
    return {
      isInviting: false,
      isFetchingEligibleAgents: false,
      selectedAgent: null,
      isDialogOpened: false,
      transferMemoInput: null,
      availableAgents: [],
    };
  },
  watch: {
    isAttendingOrPending(status) {
      if (!status && document.getElementById("flashing-button")) {
        document.getElementById("flashing-button").className +=
          " notification-flashing";
      }
    },
  },
  computed: {
    /**
     * @description Chat is attending or pending
     * @return {return}
     */
    isAttendingOrPending() {
      const selectChatStatus = this.selectedChat.status;
      if (selectChatStatus === "attending" || selectChatStatus === "pending") {
        return true;
      }
      return false;
    },

    /**
     * @description Get agents list that available for invite
     * @return {void}
     */
    agentsWithoutSelf() {
      const currentAgentEmail = _.get(this.$store, "state.profile.email", null);
      const agentList = _.chain(this.$store)
        .get("getters.getOnlineAgents", [])
        .filter((agent) => agent !== currentAgentEmail)
        .filter((agent) => {
          if (!this.isHandoverByDepartmentEnabled || !this.userQueryTopic) {
            return true;
          } else {
            const isAgentInDepartment = this.availableAgents.find(
              (availableAgent) => {
                const agentDepartments = _.get(
                  availableAgent,
                  "agentMeta.app_metadata.departments",
                  []
                );
                const agentCanHandleAllDepartment = _.isEmpty(agentDepartments);
                const agentEmailMatched =
                  availableAgent.agentId.toLowerCase() === agent.toLowerCase();
                const agentInDepartment = _.includes(
                  agentDepartments,
                  this.userQueryTopic
                );
                return (
                  agentEmailMatched &&
                  (agentInDepartment || agentCanHandleAllDepartment)
                );
              }
            );
            return isAgentInDepartment;
          }
        })
        .map((agent) => {
          const agentWithHandlingCount = {
            email: agent,
            isInChat: _.includes(this.agentInChat, agent),
            handlingCount: this.agentHandlingCount[agent] || 0,
          };
          return agentWithHandlingCount;
        })
        .value();

      return agentList;
    },

    /**
     * @description Count agent handling
     * @return {number}
     */
    agentHandlingCount() {
      const agentAppearances = _.flatMap(this.chatsList, (chat) => {
        return chat.agents;
      });
      return _.countBy(agentAppearances);
    },

    /**
     * @description Get agents array in selected chat
     * @return {string[]}
     */
    agentInChat() {
      return _.get(this.selectedChat, "agents", []);
    },

    /**
     * @description Is handover by department enabled
     * @return {boolean}
     */
    isHandoverByDepartmentEnabled() {
      return _.get(
        this.$store,
        "state.modules.handover.handoverRouting.byDepartment",
        false
      );
    },

    /**
     * @description Get user selected topic from state
     * @return {string}
     */
    userQueryTopic() {
      return _.get(this.selectedChat, "stateVariables.userQueryTopic", null);
    },
  },
  methods: {
    /**
     * @description On dropdown visible changed
     * @param {boolean} isOpen
     * @return {void}
     */
    onVisibleChange(isOpen) {
      if (isOpen) {
        this.getEligibleAgentsToAssign();
      }
    },

    /**
     * @description On fetch eligibvle agents to assign
     * @return {void}
     */
    async getEligibleAgentsToAssign() {
      try {
        this.isFetchingEligibleAgents = true;
        const response = await graph.query({
          query: gql`
            query users {
              livechatAPI {
                getEligibleAgentsToAssign
              }
            }
          `,
          fetchPolicy: "network-only",
        });

        this.availableAgents = _.get(
          response,
          "data.livechatAPI.getEligibleAgentsToAssign",
          []
        );
        this.isFetchingEligibleAgents = false;
      } catch (error) {
        console.log("Error getEligibleAgentsToAssign", error);
        this.isFetchingEligibleAgents = false;
        this.$notify.error({
          title: "Error",
          message: "Error fetching available agents, please try again later.",
          position: "bottom-right",
        });
      }
    },

    /**
     * @description On select agent from dropdown handler
     * @return {void}
     */
    onInviteAgent(agentEmail) {
      this.selectedAgent = agentEmail;
      this.isDialogOpened = true;
    },

    /**
     * @description Invite agent request
     * @return {void}
     */
    async inviteAgent() {
      try {
        this.isInviting = true;

        const response = await this.$store.dispatch("LIVECHAT_INVITE_AGENT", {
          chat: this.selectedChat,
          invitee: this.selectedAgent,
          memoComment: this.transferMemoInput,
        });

        const isInvited = _.get(
          response,
          "data.livechatAPI.inviteAgent",
          false
        );

        if (!isInvited) {
          this.$notify.error({
            title: "Error",
            message: "Agent not available at this moment.",
            position: "bottom-right",
          });
        } else {
          this.$notify.success({
            title: "Success",
            message: `Livechat invitation has been sent to ${this.selectedAgent}.`,
            position: "bottom-right",
          });

          this.isDialogOpened = false;
          this.selectedAgent = null;
          this.transferMemoInput = null;
        }
        this.isInviting = false;
      } catch (error) {
        this.isInviting = false;
        this.$notify.error({
          title: "Error",
          message: "Agent not available at this moment.",
          position: "bottom-right",
        });
      }
    },
  },
};
</script>

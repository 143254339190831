<template>
  <div class="meta-dialog">
    <el-button
      @click.prevent="onDialogOpen"
      type="primary"
      size="mini"
      icon="el-icon-document-copy"
    >Manage Meta</el-button>

    <el-dialog
      :show-close="false"
      :before-close="beforeDialogClose"
      :visible.sync="isDialogOpen"
      title="Manage Meta"
    >
      <el-button
        @click.prevent="onMetaFormDialogOpen(null)"
        :disabled="isBusy"
        type="primary"
        size="mini"
        style="margin-bottom: 12px;"
        icon="el-icon-plus"
      >Add New Meta</el-button>

      <MetaForm
        @onUpdated="onUpdated"
        @onCreated="onCreated"
        :existing-meta-types="existingMetaTypes"
        :metas="metas"
        ref="meta-form"
      />

      <hr />
      <br />

      <el-row :gutter="12">
        <template v-if="!isMetaEmpty">
          <el-col
            v-for="(meta, index) in metas"
            :key="`meta-${index}`"
            :xs="24"
            :lg="6"
            :xl="6"
            :sm="12"
            :md="6"
          >
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ meta.title }}</span>
              </div>
              <el-button-group>
                <el-button @click.prevent="onMetaFormDialogOpen(meta)" size="mini" type="info">View</el-button>
                <el-button
                  v-if="$store.state.showAdvanced"
                  @click.prevent="onDeleteMeta(index)"
                  plain
                  type="danger"
                  size="mini"
                >Delete</el-button>
              </el-button-group>
            </el-card>
          </el-col>
        </template>
        <template v-else>
          <el-col :xs="24" style="padding: 20px; text-align:center;">
            <p>No meta available.</p>
          </el-col>
        </template>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="info" @click.prevent="onDialogClose">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import MetaForm from "./Form";
import { toCamelCase, deCamelCase } from "@/filters/index";
import _ from "lodash";
export default Vue.extend({
  data() {
    return {
      isBusy: false,
      isDialogOpen: false,
      metas: []
    };
  },
  computed: {
    /**
     * @description Get metas if empty or not.
     * @return {boolean}
     */
    isMetaEmpty() {
      return _.isEmpty(this.metas);
    },

    /**
     * @description Get all existing type
     * @description To validate for unique type of all meta data
     * @return {Array<string>}
     */
    existingMetaTypes() {
      return this.metas.map(meta => meta.type);
    }
  },
  methods: {
    /**
     * @description Handle on add new meta / edit existing meta
     * @return {void}
     */
    onMetaFormDialogOpen(meta = null) {
      this.$refs["meta-form"].onDialogOpen(meta);
    },

    /**
     * @description Handle meta delete event
     * @return {void}
     */
    onDeleteMeta(index) {
      this.$confirm("Are you sure to delete this meta data?", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      }).then(() => {
        this.$store.dispatch("DELETE_TRANSACTION_META", this.metas[index]);
        this.metas.splice(index, 1);
      });
    },

    /**
     * @description Handle dialog open.
     * @return {void}
     */
    onDialogOpen() {
      this.isBusy = true;
      let _metas = _.chain(this.$store.state.transaction.transactionsMeta)
        .cloneDeep()
        .map(item => this.reStructure(item))
        .value();

      this.metas = _metas;
      this.isBusy = false;
      this.isDialogOpen = true;
    },

    /**
     * @description Handle dialog close.
     * @return {void}
     */
    onDialogClose() {
      this.isDialogOpen = false;
    },

    /**
     * @description Handle before dialog close.
     * @description To persist dialog even if user press outside dialog
     * @return {void}
     */
    beforeDialogClose(done) {
      // if (!this.isExporting) {
      //   done();
      // }
    },

    /**
     * @description On transaction meta created
     * @return {void}
     */
    onCreated(meta) {
      this.$store.commit("APPEND_TRANSACTION_META", _.cloneDeep(meta));
      this.metas.push(this.reStructure(meta));
    },

    /**
     * @description On transaciton meta updated
     * @return {void}
     */
    onUpdated(meta) {
      this.$store.commit("REPLACE_TRANSACTION_META", _.cloneDeep(meta));
      const existingMetaIndex = this.metas.findIndex(
        item => item.id === meta.id
      );
      if (existingMetaIndex > -1) {
        this.metas[existingMetaIndex] = this.reStructure(meta);
        this.$forceUpdate();
      }
    },

    /**
     * @description Restructure transaction meta
     * @return {any}
     */
    reStructure(fetchedTransactionMeta) {
      const _item = fetchedTransactionMeta;
      const _metaData = JSON.parse(fetchedTransactionMeta.metaData);
      const _fields = _.chain(_metaData.fields)
        .values()
        .map((field, key) => {
          field.stateRefKey = toCamelCase(field.displayValue);
          return field;
        })
        .value();

      _item.fields = _fields;
      _item.extras = _.omit(_metaData, "fields");
      delete _item.metaData;
      return _item;
    }
  },
  components: {
    MetaForm
  }
});
</script>
import { MutationTree } from "vuex/types";

import { FaqState } from "./types";
import Vue from "vue";
import store from "@/store";
import _ from "lodash";
import { graph } from "@/store/api";
import gql from "graphql-tag";
import { updateApolloDataset } from "@/helperMethods/faq/util";

const mutations: MutationTree<FaqState> = {
  SET_ADD_NEW_INTENT(state, intent) {
    const clonedDataset = _.get(store, "state.training.dataset", []);
    clonedDataset.unshift(intent);
    Vue.set(store, "state.training.dataset", clonedDataset);
  },
  SET_ACTIVE_TAB_INDEX(state, tabIndex) {
    // 1 = FAQ training dataset
    // 2 = FAQ conversational dataset
    Vue.set(state, "activeTab", tabIndex);
  },
  SET_TRAINING_IN_PROGRESS(state, inProgress) {
    Vue.set(state, "trainingInProgress", inProgress);
  },
  SET_PUBLISHING_IN_PROGRESS(state, inProgress) {
    Vue.set(state, "publishingInProgress", inProgress);
  },
  SET_CURRENT_VIEWING_TOPIC(state, topic) {
    Vue.set(state, "currentViewingTopic", topic);
  },
  SET_TOPIC_DIALOG_VISIBLE(state, isVisible) {
    Vue.set(state, "selectTrainTopicsDialogVisible", isVisible);
  },
  SET_SEARCH_VALUE(state, newSearchValue) {
    Vue.set(state, "search", newSearchValue);
  },
  SET_SELECTED_TOPICS(state, selectedTopics) {
    // hp: should set to selected topics instead of topics.
    Vue.set(state, "selectedTopics", selectedTopics);
  },
  SET_TOPICS(state, newTopics) {
    Vue.set(state, "topics", newTopics);
  },
  SET_UPDATED_INTENT(state, { updatedIntent, action }) {
    const isCreate = action === "CREATE";
    const isDelete = action === "DELETE";
    const isModify = action === "MODIFY";
    const trainingDataset = store.state.training.dataset;
    const newTrainingDataset = _.cloneDeep(trainingDataset);

    if (isCreate) {
      const intentIndex = _.findIndex(trainingDataset, {
        id: updatedIntent.id,
      });
      const intentFound = intentIndex !== -1;
      if (intentFound) {
        return;
      }
      newTrainingDataset.push(updatedIntent);
    }

    const intentIndex = _.findIndex(trainingDataset, {
      id: updatedIntent.id,
    });
    const intentNotFound = intentIndex === -1;

    if (intentNotFound) {
      return;
    }

    if (isModify) {
      newTrainingDataset[intentIndex] = updatedIntent;
    } else if (isDelete) {
      newTrainingDataset.splice(intentIndex, 1);
    }

    Vue.set(store.state.training, "dataset", newTrainingDataset);

    // Updates all dataset query
    updateApolloDataset(newTrainingDataset);
  },
};

export default mutations;

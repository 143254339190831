<template>
  <div>
    <el-popover trigger="hover" title="Synonyms" placement="bottom" width="300">
      <el-badge slot="reference" type="warning" :value="value.synonym.length" :style="badgeStyle" />

      <small
        style="color: #BDBDBD; font-style: italic; "
      >List of words that match with the button text.</small>

      <div
        v-for="(synonym, index) in value.synonym"
        :key="index"
        style="display:flex; flex-direction: row; margin-bottom: 6px; align-items:center; margin-top: 10px;"
      >
        <el-input size="mini" type="text" v-model="value.synonym[index]" />
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          style="margin-left: 6px;"
          @click="value.synonym.splice(index, 1)"
        />
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    badgeStyle: {
      default: "position:absolute;right:104px;top:4px; cursor:pointer;"
    }
  }
};
</script>
<template>
  <div>
    <el-card>
      <h3 slot="header">Live Chat Helper Actions</h3>

      <div style="margin-bottom: 20px; margin-left: 5px">
        <p>
          <small>
            Create helper actions to send configured content node or api action
            to customer during live chat conversation. Helper actions deck will
            show up on the right side of the chat box in the live chat page.
          </small>
        </p>
      </div>

      <el-row :gutter="15">
        <el-col :span="8">
          <el-checkbox
            v-model="value.liveChatHelperActions.enabled"
            label="Enable"
            border
            style="margin-bottom: 15px"
          ></el-checkbox>

          <el-card shadow="always">
            <el-button
              v-for="(action, index) in value.liveChatHelperActions.actions"
              v-dragging="{
                item: action,
                list: value.liveChatHelperActions.actions,
                group: 'livechathelperactions',
              }"
              :key="`action-${index}`"
              type="primary"
              plain
              size="mini"
              style="
                width: 100%;
                position: relative;
                margin-right: 0;
                margin-bottom: 8px;
                margin-left: 0;
              "
            >
              {{ action.label }}
              <i
                class="el-icon-delete"
                @click="value.liveChatHelperActions.actions.splice(index, 1)"
                style="
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                "
              />
            </el-button>

            <el-divider />

            <el-popover
              v-model="isVisible"
              placement="top"
              title="Add new action"
              width="400"
              trigger="click"
              @after-leave="form = { event: 'goto', data: null, label: '' }"
            >
              <div style="margin-top: 20px; margin-bottom: 15px">
                <el-radio-group
                  @change="form.data = null"
                  size="mini"
                  v-model="form.event"
                >
                  <el-radio-button label="goto"></el-radio-button>
                  <el-radio-button label="callApi"></el-radio-button>
                </el-radio-group>
              </div>

              <el-input
                style="width: 100%; margin-bottom: 15px"
                v-model="form.label"
                size="mini"
                placeholder="Label"
              />

              <el-select
                v-model="form.data"
                filterable
                size="mini"
                style="width: 100%; margin-bottom: 15px"
              >
                <el-option
                  v-for="item in options"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div style="text-align: right">
                <el-button
                  @click="isVisible = false"
                  size="mini"
                  plain
                  type="danger"
                  >Cancel</el-button
                >
                <el-button @click="onSave" size="mini" type="success"
                  >Save</el-button
                >
              </div>

              <el-button
                slot="reference"
                size="mini"
                type="primary"
                style="width: 100%; position: relative"
              >
                Add
              </el-button>
            </el-popover>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "LiveChatHelperActions",
  props: ["setting", "value", "modules"],
  data() {
    return {
      isVisible: false,
      form: {
        event: "goto",
        data: null,
        label: "",
      },
    };
  },
  computed: {
    apiMappings() {
      return Object.keys(this.$store.state.apieditor.mappings);
    },
    contentNodes() {
      return Object.keys(this.$store.state.nodes.content);
    },
    options() {
      return this.form.event === "goto" ? this.contentNodes : this.apiMappings;
    },
  },
  methods: {
    onSave() {
      this.value.liveChatHelperActions.actions.push(this.form);
      this.isVisible = false;
    },
  },
  mounted() {
    if (this.apiMappings.length === 0) {
      this.$store.dispatch("FETCH_ALL_MAPPINGS", {
        brain: this.$store.state.brain,
      });
    }
  },
  beforeMount() {
    if (
      !this.value.liveChatHelperActions ||
      !this.value.liveChatHelperActions.actions
    ) {
      this.$set(this.value, "liveChatHelperActions", {
        enabled: false,
        actions: [],
      });
    }
  },
};
</script>
import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { BroadcastState } from "./types";
import _ from "lodash";

const getters: GetterTree<BroadcastState, RootState> = {
  selectedBroadcastId(state, getters, rootState) {
    return state.selectedBroadcastId;
  },
  selectedBroadcast(state, getters, rootState) {
    return state.selectedBroadcast;
  },
  broadcastObjects(state, getters, rootState) {
    return state.broadcastObjects;
  },
  broadcastArray(state, getters, rootState) {
    const BroadcastArray = Object.values(state.broadcastObjects);
    return BroadcastArray;
  },
  presetSegments(state, getters, rootState) {
    const segments = _.get(rootState, "modules.broadcast.presetSegments", {});

    return segments;
  },
  facebookTestUserIds(state, getters, rootState) {
    const stringTestUsersId = _.get(
      rootState,
      "modules.broadcast.testUsersId",
      ""
    );

    const arrayTestUsers: string[] = stringTestUsersId.split(";");
    if (Array.isArray(arrayTestUsers)) {
      const facebookTestUsers = arrayTestUsers.map((user) => {
        if (user) {
          const userIdArray = user.split(":");
          const variablesAreValid = userIdArray[0] && userIdArray[1];
          if (variablesAreValid) {
            const userObject = {
              label: userIdArray[0].trim(),
              value: userIdArray[1].trim(),
            };
            return userObject;
          }
        }
      });
      return _.compact(facebookTestUsers);
    } else {
      return [];
    }
  },
  broadcastTestPageId(state, getters, rootState) {
    const testPageId = _.get(rootState, "modules.broadcast.testPageId", "");

    return testPageId;
  },
  broadcastTags(state, getters, rootState) {
    const stringBroadcastTags: string = _.get(
      rootState,
      "modules.broadcast.broadcastTags",
      "NON_PROMOTIONAL_SUBSCRIPTION;"
    );
    const broadcastTags = stringBroadcastTags.split(";");

    return _.compact(broadcastTags);
  },
};

export default getters;

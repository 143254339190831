<template>
  <el-container style="height: 100%">
    <el-main style="padding: 10px; height: 100%">
      <el-row type="flex">
        <el-row type="flex" justify="space-between" style="flex-flow: row wrap">
          <div>
            <WatsonExport />
            <router-link
              v-if="
                $store.state.modules.evaluation.enabled &&
                checkRoles(getPermittedRoles('evaluation'))
              "
              to="/evaluation"
              style="margin-left: 1em"
            >
              <el-button type="info" size="mini">Evaluation</el-button>
            </router-link>
          </div>
        </el-row>

        <template v-if="showAdvanced">
          <el-tooltip
            effect="dark"
            content="Deploy Intents into production"
            placement="bottom"
          >
            <el-button
              style="margin-left: 1em"
              type="success"
              :loading="loading"
              :plain="true"
              size="mini"
              @click="publishIntents"
            >
              Publish Watson Intents
              <i class="el-icon-check" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="showAdvanced"
            effect="dark"
            content="Deploy Entities into production"
            placement="bottom"
          >
            <el-button
              style="margin-left: 1em"
              type="success"
              size="mini"
              :loading="loading"
              :plain="true"
              @click="publishEntities"
            >
              Publish Watson Entities
              <i class="el-icon-check" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="showAdvanced"
            effect="dark"
            content="Deploy Dialog Nodes into production"
            placement="bottom"
          >
            <el-button
              style="margin-left: 1em"
              type="success"
              size="mini"
              :loading="loading"
              :plain="true"
              @click="publishDialog_nodes"
            >
              Publish Watson Dialog nodes
              <i class="el-icon-check" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="showAdvanced"
            effect="dark"
            content="Deploy all DEV content into production"
            placement="bottom"
          >
            <el-button
              style="margin-left: 1em"
              type="danger"
              size="mini"
              :loading="loading"
              :plain="true"
              @click="publishContent"
            >
              Publish Watson's DEV Workspace
              <i class="el-icon-check" />
            </el-button>
          </el-tooltip>
        </template>
      </el-row>

      <div style="height: 100%">
        <el-tabs
          v-model="activeName"
          style="height: 100%"
          @tab-click="handleClick"
        >
          <el-tab-pane label="Intents" name="intents" :lazy="true">
            <WatsonIntent />
          </el-tab-pane>
          <el-tab-pane label="Entities" name="entities" :lazy="true">
            <WatsonEntities v-if="selectedTabIndex == 1" />
          </el-tab-pane>
          <el-tab-pane label="Dialog" name="dialog" :lazy="true">
            <WatsonDialog v-if="selectedTabIndex == 2" />
          </el-tab-pane>
          <el-tab-pane label="Active Intents" name="track" :lazy="true">
            <WatsonActiveIntents v-if="selectedTabIndex == 3" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
    <!-- Back to top -->
  </el-container>
</template>
<script>
import Vue from "vue";
import WatsonIntent from "@/components/WatsonIntent";
import WatsonEntities from "@/components/WatsonEntities";
import WatsonDialog from "@/components/WatsonDialog/WatsonDialog";
import WatsonActiveIntents from "@/components/WatsonActiveIntents";
import WatsonExport from "@/components/WatsonExport";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default Vue.extend({
  components: {
    WatsonIntent,
    WatsonEntities,
    WatsonDialog,
    WatsonActiveIntents,
    WatsonExport,
  },
  data() {
    return {
      activeName: "intents",
      selectedTabIndex: 0,
      loading: false,
    };
  },
  computed: {
    showAdvanced() {
      return this.$store.state.showAdvanced;
    },
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
      });
    },
    publishIntents() {
      this.$store
        .dispatch("PUBLISH_ALL_WATSON_INTENTS")
        .then((res) => {
          if (res) {
            this.loading = false;
            this.$message({
              type: "success",
              message:
                "All DEV watson intents have been published to PROD workspace",
            });
          }
        })
        .catch(() => {
          this.notifyError("Encountered error publishing all watson intents");
        });
    },
    publishEntities() {
      this.$store
        .dispatch("PUBLISH_ALL_WATSON_ENTITIES")
        .then((res) => {
          if (res) {
            this.loading = false;
            this.$message({
              type: "success",
              message:
                "All DEV watson entities have been published to PROD workspace",
            });
          }
        })
        .catch(() => {
          this.notifyError("Encountered error publishing all watson entities");
        });
    },
    publishDialog_nodes() {
      this.$store
        .dispatch("PUBLISH_ALL_WATSON_DIALOG_NODES")
        .then((res) => {
          if (res) {
            this.loading = false;
            this.$message({
              type: "success",
              message:
                "All DEV dialog nodes have been published to PROD workspace",
            });
          }
        })
        .catch(() => {
          this.notifyError(
            "Encountered error publishing all watson dialog nodes"
          );
        });
    },
    publishContent() {
      this.loading = true;
      this.$store
        .dispatch("PUBLISH_DEV_INTO_PROD")
        .then((res) => {
          if (res) {
            this.loading = false;
            this.$message({
              type: "success",
              message:
                "All DEV watson intents, entities & dialog nodes have been published to PROD workspace",
            });
          }
        })
        .catch(() => {
          this.notifyError(
            "Encountered error publishing all DEV watson intents, entities & dialog nodes"
          );
        });
    },
    handleClick(tab) {
      this.selectedTabIndex = tab.index;
    },
  },
});
</script>
<style scoped>
.el-row--flex::after {
  content: "";
  width: 100%;
}
</style>

<template>
  <div>
    <span style="margin-left:1.5em;margin-right: 3.5em; color:#909399; fontSize: 14px">
      {{ setting.label || name }}
      <el-tooltip v-if="setting.detail" effect="dark" :content="setting.detail" placement="right">
        <i class="el-icon-question" style="margin-left:0.5em; color: #909399" />
      </el-tooltip>
    </span>

    <el-time-picker
      v-model="value[name]"
      is-range
      format="h:mm A"
      arrow-control
      value-format="h:mm a"
      range-separator="To"
      start-placeholder="Start time"
      end-placeholder="End time"
    />
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  }
};
</script>